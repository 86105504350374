import React from "react";
import { AppShell } from "components/AppShell";
import { RouteObject } from "react-router";
import Events from "./tabs/Events";
import { Alerts } from "./tabs/Alerts";
import Alert from "app/pages/Connections/tabs/Alerts/Alert";
import NewAlert from "app/pages/Connections/tabs/Alerts/NewAlert";
import DataExportTab from "app/pages/Connections/tabs/DataExport";
import UpdateDataExportDestination from "app/pages/Connections/tabs/DataExport/UpdateDataExportDestination";
import NewDataExportDestination from "app/pages/Connections/tabs/DataExport/NewDataExportDestination";
import IntegrationsTab from "app/pages/Connections/tabs/Integrations";
import { CensusDashboard } from "app/pages/Connections/tabs/Integrations/CensusDashboard";
import { CensusDestinationTypeEnum } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { INVOICING_TAB_FEATURE_FLAG } from "app/pages/deprecated/GeneralSettings";
import InvoicingTab from "app/pages/Connections/tabs/Invoices";
import { APITokensAndWebhooks } from "./tabs/APITokensAndWebhooks";
import CustomFieldsTab from "app/pages/Connections/tabs/CustomFields/CustomFieldsTab";
import CustomFields from "app/pages/Connections/tabs/CustomFields";
import { useDataExportEnabled } from "app/lib/dataExport";

const Connections: React.FC<React.PropsWithChildren> = ({ children }) => {
  const invoicingEnabled = useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false);
  const dataExportEnabled = useDataExportEnabled();

  return (
    <AppShell
      title="Connections"
      headerProps={{
        tabs: tabs.filter((tab) => {
          if (tab.name === "Invoices" && !invoicingEnabled) {
            return false;
          }

          if (tab.name === "Data export" && !dataExportEnabled) {
            return false;
          }

          return true;
        }),
        basePath: "connections",
      }}
    >
      {children}
    </AppShell>
  );
};

const tabs = [
  {
    name: "Events",
    path: "events",
    element: (
      <Connections>
        <Events />
      </Connections>
    ),
  },
  {
    name: "Alerts",
    path: "alerts",
    children: [
      {
        index: true,
        element: (
          <Connections>
            <Alerts />
          </Connections>
        ),
      },
      { path: "new", element: <NewAlert /> },
      { path: ":id", element: <Alert /> },
    ],
  },
  {
    name: "Integrations",
    path: "integrations",
    children: [
      {
        index: true,
        element: (
          <Connections>
            <IntegrationsTab tabs={[]} />
          </Connections>
        ),
      },
      {
        path: "salesforce",
        children: [
          {
            index: true,
            element: (
              <CensusDashboard
                destinationType={CensusDestinationTypeEnum.Salesforce}
              />
            ),
          },
          {
            path: "incomplete",
            element: (
              <CensusDashboard
                destinationType={CensusDestinationTypeEnum.Salesforce}
                showIncompleteSyncs={true}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Data export",
    path: "data-export",
    children: [
      {
        index: true,
        element: (
          <Connections>
            <DataExportTab />
          </Connections>
        ),
      },
      {
        path: "new",
        element: <NewDataExportDestination />,
      },
      {
        path: ":destinationId",
        element: <UpdateDataExportDestination />,
      },
    ],
  },
  {
    name: "Invoices",
    path: "invoices",
    element: (
      <Connections>
        <InvoicingTab />
      </Connections>
    ),
  },
  {
    name: "Custom fields",
    path: "custom-fields",
    children: [
      {
        index: true,
        element: (
          <Connections>
            <CustomFieldsTab />
          </Connections>
        ),
      },
      {
        path: ":entity/:id",
        element: <CustomFields />,
      },
    ],
  },
  {
    name: "API tokens & webhooks",
    path: "api-tokens-webhooks",
    element: (
      <Connections>
        <APITokensAndWebhooks />
      </Connections>
    ),
  },
];

export const ConnectionsRoutes: RouteObject[] = [
  {
    path: "connections",
    children: [...tabs],
  },
];

import { AppShell } from "components/AppShell";
import React from "react";
import { UI_MODE, useUIMode } from "app/lib/useUIMode";
import { useRequiredParam } from "app/lib/routes/params";
import { useGetCustomerNameQuery } from "./queries.graphql";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/Button";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useCurrentUser } from "app/lib/auth";

const tabs = (mode: UI_MODE, removeTabs: Set<string>) => {
  const allTabs = {
    overview: {
      name: "Overview",
      path: "",
      exactMatch: true,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("overview"),
    },
    invoices: {
      name: "Invoices",
      path: `invoices`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("invoices"),
    },
    "commits-and-credits": {
      name:
        mode === "contracts-only"
          ? "Commits and credits"
          : "Contract commits and credits",
      path: `commits-and-credits`,
      enabledModes: ["contracts-only", "contracts-and-plans"],
      enabled: !removeTabs.has("commits-and-credits"),
    },
    credits: {
      name: mode === "plans-only" ? "Credits" : "Plan credits",
      path: `credits`,
      enabledModes: ["plans-only", "contracts-and-plans"],
      enabled: true,
    },
    "auto-topups": {
      name: "Auto topups",
      path: "auto-topups",
      enabledModes: ["plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("auto-topups"),
    },
    alerts: {
      name: "Alerts",
      path: `alerts`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("alerts"),
    },
    usage: {
      name: "Usage",
      path: `usage`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("usage"),
    },
    settings: {
      name: "Settings",
      path: `settings`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("settings"),
    },
    "audit-logs": {
      name: "Audit log",
      path: `audit-logs`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
      enabled: !removeTabs.has("audit-logs"),
    },
  };
  return tabPathsForMode[mode]
    .map((path) => allTabs[path])
    .filter((tab) => tab.enabled);
};
const tabPathsForMode = {
  "contracts-only": [
    "overview",
    "invoices",
    "commits-and-credits",
    "alerts",
    "usage",
    "settings",
    "audit-logs",
  ],
  "plans-only": [
    "overview",
    "invoices",
    "credits",
    "alerts",
    "auto-topups",
    "usage",
    "settings",
    "audit-logs",
  ],
  "contracts-and-plans": [
    "overview",
    "invoices",
    "commits-and-credits",
    "credits",
    "alerts",
    "auto-topups",
    "usage",
    "settings",
    "audit-logs",
  ],
  off: [],
} as const;

export const Customer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { mode } = useUIMode();
  const customerId = useRequiredParam("customerId");
  const { data, loading } = useGetCustomerNameQuery({
    variables: { customer_id: customerId },
  });

  const { isMetronomeAdmin } = useCurrentUser();
  const prepaidCreditsEnabled =
    !!useFeatureFlag("prepaid-credits", false) && isMetronomeAdmin;
  const removeTabs = new Set<string>();
  if (!prepaidCreditsEnabled) {
    removeTabs.add("auto-topups");
  }
  if (loading) {
    return (
      <AppShell
        title={{ state: "loading" as const }}
        headerProps={{
          breadcrumbs: Breadcrumbs.from({
            label: "Customers",
            routePath: "/customers",
          }),
        }}
      />
    );
  }

  if (!data || !data.Customer) {
    return (
      <AppShell title={{ state: "loading" }} hideHeader>
        <EmptyState
          mainText="Customer not found"
          actions={[
            <Button key={0} text="View customers" linkTo="/customers" />,
          ]}
          icon="usersX"
        />
      </AppShell>
    );
  }

  return (
    <AppShell
      title={data.Customer.name}
      headerProps={{
        tabs: tabs(mode, removeTabs),
        basePath: `customers/${customerId}`,
        breadcrumbs: Breadcrumbs.from({
          label: "Customers",
          routePath: "/customers",
        }),
      }}
    >
      {children}
    </AppShell>
  );
};

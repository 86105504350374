import React from "react";
import { Button } from "components/Button";
import Papa from "papaparse";

import {
  GetAuditLogsQuery,
  useGetAuditLogsLazyQuery,
} from "../../auditlogs.graphql";
import { DeprecatedPopup } from "components/deprecated/Popup";

const MAX_RESULTS = 1000;

function downloadResults(data: GetAuditLogsQuery) {
  const rows = [
    [
      "timestamp",
      "resource_type",
      "resource_id",
      "action",
      "status",
      "description",
      "actor",
      "actor_name",
      "actor_email",
    ],
    ...data.audit_logs.data.map((log) => [
      log.request_timestamp,
      log.resource_type === "client" ? "Metronome Account" : log.resource_type,
      log.resource_type === "client" ? "Metronome Account" : log.resource_id,
      log.action,
      log.status,
      log.description,
      log.actor.id,
      log.actor.name,
      log.actor.__typename === "User" ? log.actor.email : "",
    ]),
  ];

  const link = document.createElement("a");
  link.setAttribute(
    "href",
    encodeURI(`data:text/csv;charset=utf-8,${Papa.unparse(rows)}`),
  );
  link.setAttribute("download", "audit-logs.csv");
  document.body.appendChild(link);
  link.click();
}

export const DeprecatedDownloadAuditLogsButton: React.FC<{
  resourceID?: string;
  resourceType?: string;
  startingOn?: Date;
  endingBefore?: Date;
}> = (props) => {
  const [showTooManyResultsWarning, setShowTooManyResultsWarning] =
    React.useState(false);

  const [getAuditLogs, { loading, data }] = useGetAuditLogsLazyQuery({
    variables: {
      limit: MAX_RESULTS,
      starting_after: props.startingOn?.toISOString(),
      ending_before: props.endingBefore?.toISOString(),
      ...(props.resourceType && props.resourceID
        ? {
            resource_type: props.resourceType,
            resource_id: props.resourceID.toLowerCase(),
          }
        : {}),
    },
  });

  return (
    <>
      <DeprecatedPopup
        isOpen={showTooManyResultsWarning}
        title={`More than ${MAX_RESULTS.toLocaleString()} results returned`}
        actions={
          <>
            <Button
              theme="linkGray"
              onClick={() => setShowTooManyResultsWarning(false)}
              text="Cancel"
            />
            <Button
              theme="primary"
              onClick={() => {
                if (data) {
                  downloadResults(data);
                }
                setShowTooManyResultsWarning(false);
              }}
              text="Download"
            />
          </>
        }
        onRequestClose={() => setShowTooManyResultsWarning(false)}
      >
        Your filters returned more than {MAX_RESULTS.toLocaleString()} results.
        Your CSV will only contain the most recent{" "}
        {MAX_RESULTS.toLocaleString()} results.
      </DeprecatedPopup>
      <Button
        loading={loading}
        text="Export"
        disabled={loading || !!props.resourceID != !!props.resourceType}
        leadingIcon="share02"
        theme="secondary"
        onClick={async () => {
          const { data } = await getAuditLogs();
          if (!data) {
            return;
          }

          if (data.audit_logs.next_cursor) {
            setShowTooManyResultsWarning(true);
            return;
          }

          downloadResults(data);
        }}
      />
    </>
  );
};

import {
  InvoiceCorrectionContractLineItemPointer,
  InvoiceCorrectionLineItemPointer,
} from "types/generated-graphql/__types__";
import {
  ArrearsCorrectableLineItem,
  ContractCorrectableLineItem,
  CorrectableSubLineItem,
} from "./types";

export function lineItemPointerToString(
  pointer:
    | InvoiceCorrectionLineItemPointer
    | InvoiceCorrectionContractLineItemPointer,
): string {
  if ("product_id" in pointer) {
    return `${pointer.product_id}|${pointer.product_pricing_factor_id}|${
      pointer.group?.group_key ?? ""
    }|${pointer.group?.group_value ?? ""}`;
  } else {
    return [
      pointer.charge_id ?? "",
      pointer.commit_id ?? "",
      pointer.discount_id ?? "",
      pointer.usage_product_id ?? "",
      pointer.inclusive_start_date ?? "",
      pointer.exclusive_end_date ?? "",
    ].join("|");
  }
}
export function createLineItemPointer(
  lineItem: ArrearsCorrectableLineItem,
  subLineItem: CorrectableSubLineItem,
): InvoiceCorrectionLineItemPointer {
  return {
    product_id: lineItem.product.id,
    product_pricing_factor_id: subLineItem.pricing_factor.id,
    group:
      lineItem.__typename === "GroupedProductChargeLineItem" &&
      lineItem.group_value !== null
        ? {
            group_key: lineItem.group_value,
            group_value: lineItem.group_value,
          }
        : null,
  };
}

export function createContractLineItemPointer(
  lineItem: ContractCorrectableLineItem,
): InvoiceCorrectionContractLineItemPointer {
  if (lineItem.__typename === "ContractChargeLineItem") {
    return {
      charge_id: lineItem.charge.id,
    };
  }

  if (lineItem.__typename === "ContractCommitLineItem") {
    return {
      commit_id: lineItem.commit_union.id,
    };
  }

  if (lineItem.__typename === "ContractDiscountLineItem") {
    return {
      discount_id: lineItem.discount.id,
    };
  }

  if (lineItem.__typename === "ContractUsageLineItem") {
    return {
      usage_product_id: lineItem.product.id,
      inclusive_start_date: lineItem.inclusive_start_date,
      exclusive_end_date: lineItem.exclusive_end_date,
    };
  }

  if (
    lineItem.__typename === "ContractAWSRoyaltyLineItem" ||
    lineItem.__typename === "ContractGCPRoyaltyLineItem"
  ) {
    // New royalty amounts are computed based on other line items in the invoice
    // and do not require a pointer, but we don't want to throw an
    // error here so that we can continue to display the line item.
    return {};
  }

  lineItem satisfies never;
  throw new Error(`Unexpected line item type: ${(lineItem as any).__typename}`);
}

import React, { useEffect, useState } from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { GatedButton } from "components/GatedButton";
import {
  Body,
  DateInput,
  Headline,
  LoadingSpinner,
  Subtitle,
} from "design-system";
import { Button } from "components/Button";
import { useFeatureFlag } from "app/lib/launchdarkly";
import NotFoundPage from "app/pages/404";
import { INVOICING_TAB_FEATURE_FLAG } from "app/pages/deprecated/GeneralSettings";
import InvoiceFinalizationFlyout from "./InvoiceFinalizationFlyout";
import {
  InvoiceFinalizationTable,
  InvoiceFinalization,
} from "./InvoiceFinalizationTable";
import {
  useReleaseInvoicesForFinalizationMutation,
  useGetInvoiceFinalizationMetadataQuery,
  GetInvoiceFinalizationMetadataQuery,
  useGetBooksClosedDateQuery,
  useSetBooksClosedDateMutation,
  ReleaseInvoicesForFinalizationDocument,
  useGetInvoiceFinalizationProgressCountLazyQuery,
  useUpdateInvoiceFinalizationMetadataStatusMutation,
} from "./queries.graphql";
import {
  InvoiceExternalTypeEnum,
  InvoiceFinalizationInput,
  InvoiceFinalizationMetadataStatus,
} from "types/generated-graphql/__types__";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";

const getInvoiceFinalizations = (
  req: GetInvoiceFinalizationMetadataQuery,
): InvoiceFinalization[] => {
  const finalizations: InvoiceFinalization[] = [];
  req.invoice_finalization_metadata.forEach((item) => {
    if (item) {
      finalizations.push({
        id: item.id,
        cohort: item.invoice_cohort,
        finalizationDate: new Date(item.finalization_date),
        booksClosedDate: new Date(item.books_closed_date),
        invoicesFinalized: item.invoices_finalized,
        billingProviderInvoiceCount: item.billing_provider_invoice_count,
        actor: item.Creator,
        date: new Date(item.created_at),
        status: item.status,
      });
    }
  });
  return finalizations;
};

const isInvoiceFinalizationInput = (
  input: Partial<InvoiceFinalizationInput>,
): input is InvoiceFinalizationInput => {
  return (
    !!input.invoice_type &&
    !!input.finalization_date &&
    !!input.books_closed_date &&
    !!input.request_timestamp
  );
};

const InvoicingTab: React.FC = () => {
  const pushMessage = useSnackbar();
  const { environmentType } = useEnvironment();
  const invoicingTabEnabled = useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false);

  const {
    data: finalizationMetadataData,
    loading,
    refetch,
  } = useGetInvoiceFinalizationMetadataQuery();
  const [releaseInvoicesForFinalization] =
    useReleaseInvoicesForFinalizationMutation();
  const [setBooksClosedDateMutation, { loading: applyingBooksClosedDate }] =
    useSetBooksClosedDateMutation();
  const { data: booksClosedDateData, loading: booksClosedDateLoading } =
    useGetBooksClosedDateQuery({
      variables: { environment_type: environmentType },
    });
  const [getInvoiceFinalizationProgressCountQuery] =
    useGetInvoiceFinalizationProgressCountLazyQuery();
  const [updateInvoiceFinalizationMetadataStatus] =
    useUpdateInvoiceFinalizationMetadataStatusMutation();

  const [isRightPaneOpen, setIsRightPaneOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
  const [booksClosedDatePickerValue, setBooksClosedDatePickerValue] = useState<
    Date | undefined
  >();
  const [invoicesToFinalizeCount, setInvoicesToFinalizeCount] = useState(0);
  const [isReloading, setIsReloading] = useState(false);
  const [finalizationInProgress, setFinalizationInProgress] = useState(false);
  const [finalizationInput, setFinalizationInput] = useState<
    Partial<InvoiceFinalizationInput>
  >({});
  const [finalizationLogData, setFinalizationLogData] = useState<
    InvoiceFinalization[]
  >([]);

  const updateFinalizationInput = (
    newState: Partial<InvoiceFinalizationInput>,
  ) => {
    const updatedState = { ...finalizationInput, ...newState };
    setFinalizationInput(updatedState);
  };

  const onBooksClosedDateChange = async () => {
    if (booksClosedDatePickerValue) {
      try {
        const result = await setBooksClosedDateMutation({
          variables: {
            environment_type: environmentType,
            value: booksClosedDatePickerValue.toISOString(),
          },
        });

        if (result.data?.insert_ClientConfig_one?.value) {
          pushMessage({
            content: "NetSuite accounting period close date set.",
            type: "success",
          });
        }
        updateFinalizationInput({
          books_closed_date: booksClosedDatePickerValue.toISOString(),
        });
        setIsDatePickerModalOpen(false);
      } catch (e) {
        pushMessage({
          content: getUserFacingErrorMessage(
            e,
            "Failed to set NetSuite accounting period close date.",
          ),
          type: "error",
        });
      }
    }
  };

  const getFinalizationProgressCount = async (
    finalization: InvoiceFinalization,
  ) => {
    const invoices = await getInvoiceFinalizationProgressCountQuery({
      variables: {
        id: finalization.id,
      },
    });
    return invoices.data?.invoice_finalization_progress_count.count;
  };

  const getFinalizationPreviewCount = async (
    invoiceType: InvoiceExternalTypeEnum,
    finalizationDate: string,
  ) => {
    const invoices = await releaseInvoicesForFinalization({
      variables: {
        inputs: {
          invoice_type: invoiceType,
          finalization_date: new Date(finalizationDate).toISOString(),
          books_closed_date: new Date().toISOString(),
          request_timestamp: new Date().toISOString(),
        },
        preview: true,
      },
    });
    return (
      invoices.data?.release_invoices_for_finalization.invoices_finalized || 0
    );
  };

  useEffect(() => {
    if (booksClosedDateData?.ClientConfig) {
      const date =
        booksClosedDateData?.ClientConfig.length > 0 &&
        booksClosedDateData?.ClientConfig[0].value.length > 0
          ? dayjs
              .utc(booksClosedDateData?.ClientConfig[0].value)
              .startOf("day")
              .toDate()
          : undefined;
      updateFinalizationInput({
        books_closed_date: date?.toISOString(),
      });
      setBooksClosedDatePickerValue(date);
    }
  }, [booksClosedDateData]);

  useEffect(() => {
    const updateInvoiceToFinalizeCount = async () => {
      if (
        finalizationInput.invoice_type &&
        finalizationInput.finalization_date
      ) {
        const invoiceCount = await getFinalizationPreviewCount(
          finalizationInput.invoice_type,
          finalizationInput.finalization_date,
        );
        setInvoicesToFinalizeCount(invoiceCount);
      }
    };
    void updateInvoiceToFinalizeCount();
  }, [finalizationInput]);

  useEffect(() => {
    const fetchFinalizationLogData = async () => {
      if (finalizationMetadataData) {
        const finalizations = getInvoiceFinalizations(finalizationMetadataData);
        for (const finalization of finalizations) {
          if (
            finalization.status === InvoiceFinalizationMetadataStatus.Pending
          ) {
            const progressCount =
              await getFinalizationProgressCount(finalization);
            if (progressCount === finalization.billingProviderInvoiceCount) {
              // If there are no invoices left to finalize, set the status to Finalized
              void updateInvoiceFinalizationMetadataStatus({
                variables: {
                  id: finalization.id,
                  status: InvoiceFinalizationMetadataStatus.Finalized,
                },
              });
              void refetch();
            } else {
              // Update the current invoice count for pending invoices
              if (
                progressCount &&
                progressCount >
                  (finalization.currentBillingProviderInvoiceCount ?? 0)
              ) {
                finalization.currentBillingProviderInvoiceCount = progressCount;
              }
              setFinalizationInProgress(true);
            }
          }
        }
        setFinalizationLogData(finalizations);
      }
    };
    void fetchFinalizationLogData();
    const intervalId = setInterval(() => {
      void fetchFinalizationLogData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [finalizationMetadataData]);

  if (!invoicingTabEnabled) {
    return <NotFoundPage />;
  }
  return (
    <>
      <div className="flex items-end justify-between pb-12">
        <div className="flex flex-col">
          <Headline className="pb-4 text-deprecated-gray-900" level={6}>
            NetSuite accounting period close date
          </Headline>
          <Body level={2} className="text-gray-700">
            Set this date to the most recent NetSuite accounting period close
            date.
          </Body>
          <Subtitle level={4} className="flex items-center text-gray-500">
            Latest accounting period closed on
          </Subtitle>
          <div className="flex items-center gap-4">
            <DateInput
              value={
                finalizationInput.books_closed_date
                  ? new Date(finalizationInput.books_closed_date)
                  : undefined
              }
              onChange={() => {}}
              disabled={true}
              isUTC
            />
            <Button
              onClick={() => setIsDatePickerModalOpen(true)}
              disabled={finalizationInProgress}
              text={
                finalizationInProgress ? "Finalization in progress" : "Update"
              }
              theme="secondary"
              size="sm"
            />
          </div>
          <DeprecatedPopup
            isOpen={isDatePickerModalOpen}
            title="Period close date"
            onRequestClose={() => setIsDatePickerModalOpen(false)}
            actions={
              <>
                <Button
                  className="flex"
                  disabled={
                    booksClosedDateLoading ||
                    !finalizationInput.books_closed_date ||
                    applyingBooksClosedDate
                  }
                  onClick={onBooksClosedDateChange}
                  text="Save"
                  theme="primary"
                />
              </>
            }
          >
            <Subtitle level={4} className="flex items-center text-gray-500">
              Latest accounting period closed on
            </Subtitle>
            <DateInput
              value={booksClosedDatePickerValue}
              disabled={applyingBooksClosedDate || booksClosedDateLoading}
              onChange={setBooksClosedDatePickerValue}
              maxDate={new Date()}
              isUTC
            />
          </DeprecatedPopup>
        </div>

        <GatedButton
          doc={ReleaseInvoicesForFinalizationDocument}
          disabled={!finalizationInput.books_closed_date}
          onClick={() => {
            setIsRightPaneOpen(true);
          }}
          text="Finalize invoices"
          theme="primary"
        />
      </div>
      <div className="py-12">
        <InvoiceFinalizationTable
          loading={loading}
          data={finalizationLogData}
        />
        <InvoiceFinalizationFlyout
          isOpen={isRightPaneOpen}
          invoicesToFinalizeCount={invoicesToFinalizeCount}
          onSubmit={() => {
            setIsConfirmationModalOpen(true);
            updateFinalizationInput({
              request_timestamp: new Date().toISOString(),
            });
          }}
          finalizationInput={finalizationInput}
          updateFinalizationInput={updateFinalizationInput}
          onClose={() => setIsRightPaneOpen(false)}
        />
        <DeprecatedPopup
          isOpen={isConfirmationModalOpen}
          onRequestClose={() =>
            !isReloading && setIsConfirmationModalOpen(false)
          }
          actions={
            <>
              <Button
                onClick={async () => {
                  if (isInvoiceFinalizationInput(finalizationInput)) {
                    setIsReloading(true);
                    void releaseInvoicesForFinalization({
                      variables: {
                        inputs: finalizationInput,
                        preview: false,
                      },
                    });
                    setIsReloading(false);
                    setIsConfirmationModalOpen(false);
                    setIsRightPaneOpen(false);
                    setFinalizationInput({
                      books_closed_date: finalizationInput.books_closed_date,
                    });
                    void refetch();
                  }
                }}
                disabled={isReloading}
                text="Finalize"
                theme="primary"
              />
            </>
          }
          title="Confirm Finalization"
        >
          <Body level={2}>
            {`Are you sure you want to finalize ${invoicesToFinalizeCount} invoices?`}
          </Body>
          {isReloading && <LoadingSpinner />}
        </DeprecatedPopup>
      </div>
    </>
  );
};

export default InvoicingTab;

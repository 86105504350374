import React from "react";

import { FullScreenPage } from "components/AppShell";
import { Body, Headline, Subtitle, Icon, IconName } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedWizardFooter } from "components/deprecated/Wizard";
import { useNavigate } from "app/lib/useNavigate";
import styles from "./index.module.less";
import { MetronomeLogo } from "components/MetronomeLogo";
import { DeprecatedInternalLink } from "components/deprecated/Typography";

type InterstitialProps = {
  onClose: () => void;
  onContinue: () => void;
  existingPlanName?: string;
};

interface PlanWizardInfoBoxProps {
  icon: IconName;
  title: string;
  body: string;
}
const PlanWizardInfoBox: React.FC<PlanWizardInfoBoxProps> = (props) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.infoBoxIcon}>
        <Icon icon={props.icon} />
      </div>
      <div className={styles.infoBoxText}>
        <Subtitle level={1}>{props.title}</Subtitle>
        <Body level={1} className={styles.infoBoxBody}>
          {props.body}
        </Body>
      </div>
    </div>
  );
};
export const Interstitial: React.FC<InterstitialProps> = (props) => {
  const navigate = useNavigate();
  return (
    <FullScreenPage title="New plan">
      <div className={styles.container}>
        <DeprecatedInternalLink routePath="/" className={styles.logo}>
          <MetronomeLogo theme="greyscale" badgeOnly />
        </DeprecatedInternalLink>
        <IconButton
          onClick={props.onClose}
          className={styles.closeButton}
          theme="secondary"
          icon="xClose"
        />
        <div className={styles.icon}>
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>{`Welcome ${
          props.existingPlanName ? "back " : ""
        }to the Metronome plans creator`}</Headline>
        {props.existingPlanName ? (
          <Body className={styles.bodyText}>
            Continue building <b>{props.existingPlanName}</b>.<br />
            We'll save your progress at each step, so you don’t have to worry
            about completing your new plan in one sitting.
          </Body>
        ) : (
          <>
            <Body className={styles.bodyText}>
              A plan specifies what products a customer on that plan has access
              to, the prices that are paid, and the invoicing cadence. Once
              you've added a name and description, we’ll continually save your
              progress so you don’t have to worry about completing your new plan
              in one session.
            </Body>
            <hr className={styles.divider} />
            <PlanWizardInfoBox
              icon="documents"
              title="Set plan terms"
              body="Determine the plan’s length, how often customers are invoiced, and add any applicable pricing ramps."
            />
            <PlanWizardInfoBox
              icon="cart"
              title="Add products"
              body="View all your existing products and add the ones applicable to this plan."
            />
            <PlanWizardInfoBox
              icon="logoUsd"
              title="Price products"
              body="Add pricing to all the charges in each of your selected products."
            />
          </>
        )}
      </div>
      <DeprecatedWizardFooter
        onClickBack={() => navigate("/plans")}
        backLabel="Cancel"
        onClickContinue={props.onContinue}
        continueLabel="Continue"
      />
    </FullScreenPage>
  );
};

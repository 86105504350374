import React from "react";
import { InvoiceExternalTypeEnum } from "types/generated-graphql/__types__";
import { Caption, Select } from "design-system";
import { mapInvoiceExternalTypeToAlertFilterLabel } from "app/lib/alerts";

const NON_CONTRACT_INVOICE_OPTIONS = [
  InvoiceExternalTypeEnum.PlanArrears,
  InvoiceExternalTypeEnum.Scheduled,
  InvoiceExternalTypeEnum.CreditPurchase,
].map((type) => ({
  label: mapInvoiceExternalTypeToAlertFilterLabel(type),
  value: type,
}));

const CONTRACT_INVOICE_OPTIONS = [
  {
    label: mapInvoiceExternalTypeToAlertFilterLabel(
      InvoiceExternalTypeEnum.Usage,
    ),
    value: InvoiceExternalTypeEnum.Usage,
  },
];

export const InvoiceTypesFilters: React.FC<{
  invoiceTypes: InvoiceExternalTypeEnum[] | undefined;
  setInvoiceTypes: (newValue: InvoiceExternalTypeEnum[]) => void;
  contractsEnabled: boolean;
}> = ({ invoiceTypes, setInvoiceTypes, contractsEnabled }) => {
  return (
    <div className="pad flex items-center pr-12 pt-8">
      <Caption level={2} className="mr-8 text-deprecated-gray-dark">
        Invoice type is one of:
      </Caption>
      <Select
        placeholder="select one or more invoice types"
        options={[
          ...NON_CONTRACT_INVOICE_OPTIONS,
          ...(contractsEnabled ? CONTRACT_INVOICE_OPTIONS : []),
        ]}
        onChange={(v) => setInvoiceTypes(v as InvoiceExternalTypeEnum[])}
        multiSelect={true}
        value={invoiceTypes ?? []}
        clearable={true}
        className="flex-1"
      />
    </div>
  );
};

import React from "react";
import { DateInput, Select, NumericInput } from "design-system";
import { z } from "zod";

import { DeprecatedCreditInput } from "components/deprecated/Input";

import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { Schema, Refine } from "../../Schema";
import { InvoicePreview } from "./InvoicePreview";
import { useRootCtrl, RootCtrl } from "./RootCtrl";
import { AmountDistributionType } from "types/generated-graphql/__types__";
import { CreditType } from "app/types/credit-types";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";

const RecurringFields = z
  .object({
    type: z.literal("recurring"),
    frequency: z.enum(Schema.RECURRING_FREQUENCY),
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
    amountDistribution: z.enum(Schema.RECURRING_DISTRIBUTION),
    quantity: z.number().default(1),
    unitPrice: z.number(),
  })
  .superRefine(Refine.startDateAndEndDate);

const useRecurringCtrl = useRootCtrl.child(RecurringFields, {
  debugName: "RecurringSchedule",
  read(
    parent,
    frequency: Schema.Types.RecurringFrequency,
    timeframe: DefaultTimeframe,
  ) {
    const schedule = parent.get("schedule");

    if (!schedule || schedule.type !== "recurring") {
      return {
        startDate: timeframe.startingAt,
        endDate: timeframe.endingBefore,
        frequency,
        amountDistribution: AmountDistributionType.Divided,
      };
    }

    return {
      ...schedule,
      startDate: schedule.startDate || timeframe.startingAt,
      endDate: schedule.endDate || timeframe.endingBefore,
      frequency,
    };
  },
  write(self) {
    return { schedule: self.getUnvalidatedInputs() };
  },
});

export const RecurringSchedule: React.FC<{
  parent: RootCtrl;
  frequency: Schema.Types.RecurringFrequency;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useRecurringCtrl(props.parent, props.frequency, timeframe);
  const valid = ctrl.getValid();

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  return (
    <div className="col-span-3 grid grid-cols-3 gap-12">
      <DateInput
        {...ctrl.props.DateInput("startDate", {
          name: "Starting at",
        })}
      />
      <DateInput
        {...ctrl.props.DateInput("endDate", {
          name: "Ending before",
          minDate: ctrl.get("startDate"),
        })}
      />

      <Select
        {...ctrl.props.Select("amountDistribution", {
          name: "Distribution",
          placeholder: "Select",
          options: [
            {
              label: "Divided",
              value: AmountDistributionType.Divided,
            },
            {
              label: "Divided and rounded",
              value: AmountDistributionType.DividedRounded,
            },
            {
              label: "Each",
              value: AmountDistributionType.Each,
            },
          ],
        })}
      />

      <NumericInput
        {...ctrl.props.NumericInput("quantity", {
          name: "Quantity",
          placeholder: "1",
          prefix: "x",
        })}
      />
      <DeprecatedCreditInput
        {...ctrl.props.CreditInput("unitPrice", {
          name: "Unit price",
          placeholder: "123.45",
          creditType: props.creditType,
          allowZeroAmounts: true,
        })}
      />

      {valid ? (
        <div className="col-span-3">
          <InvoicePreview schedule={valid} creditType={props.creditType} />
        </div>
      ) : undefined}
    </div>
  );
};

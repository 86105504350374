import React, { useEffect, useMemo, useState } from "react";
import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { RateCardContext, RateProductEnum } from "./RateCardContext";
import { Column, Table } from "components/Table";
import { DatePicker } from "components/DatePicker";
import { Tooltip } from "components/Tooltip";
import { Icon } from "components/Icon";
import { dayjs } from "lib/dayjs";
import { UTC_TIMEZONE, getDateStringInUTC } from "lib/time";
import { twMerge } from "twMerge";
import { Toggle } from "components/Toggle";
import { RateInput } from "./RateInput";
import { IconButton } from "components/IconButton";
import { Dropdown, DropdownHR, DropdownItem } from "components/Dropdown";
import { v4 as uuid } from "uuid";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { utcToZonedTime } from "date-fns-tz";

type Props = {
  rates: Schema.Types.Rate[];
  type: RateProductEnum;
  title: string;
  allowEditCreditConversions?: boolean;
  disableChangingCreditTypes?: boolean;
};

export function RatesTable({
  rates,
  type,
  title,
  allowEditCreditConversions = true,
  disableChangingCreditTypes = false,
}: Props) {
  const {
    editRate,
    addRateOverride,
    removeRateOverride,
    removeProduct,
    conversionRateChange,
    customCreditTypes,
    fiatCreditType,
    setRateStartingAtDate,
    setRateEndingBeforeDate,
    productsMap,
    creditTypeConversions,
    loading,
    addCommitRate,
    removeCommitRate,
    patchProductRates,
  } = RateCardContext.useContainer();

  const prefillZero = useFeatureFlag("golden-demo-prefill-zero", false);

  useEffect(() => {
    // on load, prefill all rates to zero if the golden-demo-prefill-zero ff is enabled
    if (prefillZero) {
      rates.forEach((rate) => {
        if (!rate.isCommitPrice) {
          switch (type) {
            case "usageRates":
              editRate(type, {
                ...rate,
                price: {
                  type: "flat",
                  price: 0,
                },
              });
              break;
            case "subscriptionRates":
              editRate(type, {
                ...rate,
                price: {
                  type: "subscription",
                  quantity: 1,
                  price: 0,
                  isProrated: true,
                },
              });
              break;
            case "compositeRates":
              editRate(type, {
                ...rate,
                price: {
                  type: "percentage",
                  fraction: 5,
                  useListPrices: false,
                },
              });
              break;
          }
        }
      });
    }
  }, []);

  const [newRateId, setNewRateId] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (newRateId) {
      window.setTimeout(() => {
        const newRow = document.getElementById(newRateId);
        if (newRow) {
          const currClass = newRow.getAttribute("class");
          newRow.setAttribute("class", `${currClass} bg-gray-50`);
        }
      }, 100);

      window.setTimeout(() => {
        const newRow = document.getElementById(newRateId);
        if (newRow) {
          const currClass = newRow.getAttribute("class");
          newRow.setAttribute(
            "class",
            `${currClass} transition-all duration-1000 !bg-white`,
          );
        }
      }, 200);

      setNewRateId(undefined);
    }
  }, [newRateId]);

  const productRateColumns: Column<Schema.Types.Rate>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Product",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const disabled = !!row.isRemovedSubrate;
          const hasSubLabel =
            row.pricingGroupValues ||
            (row.price.type === "percentage" && row.price.useListPrices);
          return row.isCommitPrice ? (
            "Commit rate"
          ) : (
            <div className="flex flex-col">
              <div
                className={twMerge(
                  `text-sm font-medium text-core-slate`,
                  disabled && "!text-gray-400",
                  // Kind of a hack, but we hardcode leading-40px so that this column text has the
                  // same line-height as other columns, otherwise vertical alignment looks off.
                  !hasSubLabel && "leading-[40px]",
                )}
              >
                {productsMap.get(row.productId)?.current.name ?? ""}
              </div>
              {hasSubLabel && (
                <>
                  {row.pricingGroupValues && (
                    <div
                      className={twMerge(
                        `text-xs font-normal text-gray-600`,
                        disabled && "!text-gray-400",
                      )}
                    >
                      {row.pricingGroupValues
                        ? Object.values(row.pricingGroupValues).join(", ")
                        : ""}
                    </div>
                  )}
                  {row.price.type === "percentage" &&
                    row.price.useListPrices && (
                      <div
                        className={twMerge(
                          `text-xs font-normal text-gray-600`,
                          disabled && "!text-gray-400",
                        )}
                      >
                        Use list rates
                      </div>
                    )}
                </>
              )}
            </div>
          );
        },
        enableSorting: false,
        cellClassName: (row) => {
          return row.isCommitPrice ? "border-t-0 align-top" : "align-top";
        },
      },
      {
        id: "2",
        header: () => {
          return (
            <div className="flex items-center">
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                }}
                onDateApply={(date) => {
                  setRateStartingAtDate(type, date);
                }}
              />
              <span className="px-4">Starting at (UTC)</span>
              <Tooltip label="Set or update a product's rate with a time-based schedule. Use the starting/ending date pickers to define when a rate takes effect as well as schedule future-dated changes to the product's rate.">
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: (row) =>
          row.isCommitPrice ? "border-t-0 align-top " : "align-top !px-12",
        headerClassName: "!px-12",
        size: 220,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          if (row.isCommitPrice) {
            return "";
          }

          const date = row.startingAt
            ? dayjs.utc(row.startingAt).toDate()
            : undefined;
          const disabled = !!row.isRemovedSubrate;
          const text = date ? getDateStringInUTC(date) : "--";
          const openTo = date ? utcToZonedTime(date, UTC_TIMEZONE) : undefined;
          return (
            <div
              className={twMerge(
                "group flex items-center",
                disabled && "text-gray-400",
              )}
            >
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                  className: `invisible ${!disabled ? "group-hover:visible" : ""}`,
                }}
                disabled={disabled}
                value={openTo}
                onDateApply={(date) => {
                  if (date) {
                    editRate(type, {
                      ...row,
                      startingAt: date.toISOString(),
                    });
                  }
                }}
              />
              <span className="px-4">{text}</span>
            </div>
          );
        },
      },
      {
        id: "3",
        header: () => {
          return (
            <div className="flex items-center">
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                }}
                onDateApply={(date) => {
                  setRateEndingBeforeDate(type, date);
                }}
                allowClear
              />
              <span className="pl-4">Ending before (UTC)</span>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: (row) =>
          row.isCommitPrice
            ? "border-t-0 align-top !px-12"
            : "align-top !px-12",
        headerClassName: "!px-12",
        size: 220,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          if (row.isCommitPrice) {
            return "";
          }

          const date = row.endingBefore
            ? dayjs.utc(row.endingBefore).toDate()
            : undefined;
          const disabled = !!row.isRemovedSubrate;
          const text = date ? getDateStringInUTC(date) : "--";
          return (
            <div
              className={twMerge(
                "group flex items-center",
                disabled && "text-gray-400",
              )}
            >
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                  className: `invisible ${!disabled ? "group-hover:visible" : ""}`,
                }}
                disabled={disabled}
                openToDate={date}
                onDateApply={(date) => {
                  if (date) {
                    editRate(type, {
                      ...row,
                      endingBefore: date.toISOString(),
                    });
                  } else {
                    editRate(type, {
                      ...row,
                      endingBefore: undefined,
                    });
                  }
                }}
                allowClear
              />
              <span className="px-4">{text}</span>
            </div>
          );
        },
      },
      {
        id: "4",
        header: () => {
          return (
            <div className="flex items-center gap-4">
              Entitlement
              <Tooltip label="Entitlements determine if a customer is given access to a product by default. Entitlements are often applied to add-on products customers generally don’t receive by default.">
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: (row) =>
          row.isCommitPrice
            ? "border-t-0 align-top !px-12"
            : "align-top !px-12",
        headerClassName: "!px-12",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          if (row.isCommitPrice) {
            return "";
          }

          return (
            <Toggle
              disabled={!!row.isRemovedSubrate}
              toggled={row.entitled === "enable"}
              onChange={() => {
                const entitled =
                  row.entitled === "enable" ? "disable" : "enable";
                editRate(type, {
                  ...row,
                  entitled,
                });
              }}
              label=""
            />
          );
        },
      },
      {
        id: "5",
        header: "Rate",
        enableSorting: false,
        cellClassName: (row) =>
          row.isCommitPrice
            ? "border-t-0 align-top !pl-12 !pr-0"
            : "align-top !pl-12 !pr-0",
        headerClassName: "!px-12",
        size: 400,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          return (
            <RateInput
              disabled={!!row.isRemovedSubrate}
              rate={row}
              fiatCreditType={fiatCreditType}
              customCreditTypes={customCreditTypes}
              creditTypeConversions={creditTypeConversions}
              allowEditCreditConversions={allowEditCreditConversions}
              disableChangingCreditTypes={disableChangingCreditTypes}
              onChange={(rate) => {
                editRate(type, rate);
              }}
              key={row.id}
              onConversionRateChange={conversionRateChange}
            />
          );
        },
      },
      {
        id: "6",
        header: "",
        isDisplay: true,
        cellClassName: (row) =>
          row.isCommitPrice
            ? "border-t-0 align-top !px-12"
            : "align-top !px-12",
        headerClassName: "!px-12",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const disabled = !!row.isRemovedSubrate;
          const hasTiers = row.price.type === "tiered";
          const hasCommitRate = !!row.hasCommitRate || !!row.isCommitPrice;
          const isPercentageRate = row.price.type === "percentage";
          const isSubscriptionRate = row.price.type === "subscription";
          const shouldShowCommitRateControls =
            !isPercentageRate && !isSubscriptionRate;
          const useListPricesChecked =
            row.price.type === "percentage" ? row.price.useListPrices : false;
          return (
            <div className="flex flex-row gap-4">
              {!row.isOverrideRate ? (
                <Tooltip
                  disabled={disabled || row.isCommitPrice}
                  label="Add a rate change"
                >
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      const id = uuid();
                      setNewRateId(id);
                      addRateOverride(type, row, {
                        ...row,
                        id,
                        isOverrideRate: true,
                      });
                    }}
                    icon="calendarPlus02"
                    theme="tertiary"
                    className={row.isCommitPrice ? "invisible" : ""}
                  />
                </Tooltip>
              ) : (
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    removeRateOverride(type, row);
                  }}
                  icon="calendarMinus02"
                  theme="tertiary"
                  className={
                    row.isCommitPrice
                      ? "invisible"
                      : "text-deprecated-error-500"
                  }
                />
              )}
              <Dropdown
                buttonTheme="tertiary"
                icon="dotsVertical"
                headerContent="Rate options"
                hideChevron={true}
              >
                {type === "usageRates" && (
                  <DropdownItem
                    label={hasTiers ? "Remove tiers" : "Add tiers"}
                    value="tiers"
                    icon="dotpoints01"
                    disabled={!!row.isRemovedSubrate}
                    onClick={() => {
                      // toggles from flat to tiered and vice versa
                      let newPrice: Schema.Types.Rate["price"];
                      if (row.price.type === "flat") {
                        newPrice = {
                          type: "tiered",
                          tiers: [
                            {
                              lastUnit: 1000,
                              unitPrice: row.price.price,
                            },
                            {
                              unitPrice: undefined,
                            },
                          ],
                        };
                      } else if (row.price.type === "tiered") {
                        newPrice = {
                          type: "flat",
                          price: row.price.tiers[0].unitPrice,
                        };
                      } else {
                        return;
                      }

                      editRate(type, {
                        ...row,
                        price: newPrice,
                      });
                    }}
                  />
                )}
                {shouldShowCommitRateControls && (
                  <DropdownItem
                    label={
                      hasCommitRate ? "Remove commit rate" : "Add commit rate"
                    }
                    value="commitRate"
                    icon="coinsHand"
                    disabled={!!row.isRemovedSubrate}
                    onClick={() => {
                      if (hasCommitRate) {
                        // remove it
                        removeCommitRate(type, row);
                      } else {
                        // add it
                        addCommitRate(type, row, {
                          ...row,
                          isCommitPrice: true,
                          id: uuid(),
                        });
                      }

                      editRate(type, {
                        ...row,
                        hasCommitRate: !hasCommitRate,
                      });
                    }}
                  />
                )}
                {isPercentageRate && (
                  <DropdownItem
                    label={
                      useListPricesChecked
                        ? "Use contract rates"
                        : "Use list rates"
                    }
                    icon="sale02"
                    disabled={!!row.isRemovedSubrate}
                    value="useListPrices"
                    onClick={() => {
                      if (row.price.type === "percentage") {
                        editRate(type, {
                          ...row,
                          price: {
                            ...row.price,
                            useListPrices: !useListPricesChecked,
                          },
                        });
                      }
                    }}
                  />
                )}
                {(isPercentageRate ||
                  shouldShowCommitRateControls ||
                  type === "usageRates") && <DropdownHR />}
                {row.pricingGroupValues && !row.isCommitPrice && (
                  <DropdownItem
                    label={
                      disabled ? "Undo sub-rate removal" : "Remove sub-rate"
                    }
                    value={row.id}
                    icon={disabled ? "flipBackward" : "trash01"}
                    onClick={(data) => {
                      patchProductRates(type, row, {
                        isRemovedSubrate: !disabled,
                      });
                    }}
                  />
                )}
                {!row.isCommitPrice && (
                  <DropdownItem
                    label="Remove product"
                    value={row.productId}
                    icon="trash01"
                    onClick={(data) => {
                      removeProduct(data.value);
                    }}
                  />
                )}
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      fiatCreditType,
      customCreditTypes,
      productsMap,
      creditTypeConversions,
      newRateId,
      disableChangingCreditTypes,
    ],
  );
  return (
    <Table
      title={title}
      key={type}
      data={rates}
      columns={productRateColumns}
      loading={loading}
    />
  );
}

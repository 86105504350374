import { getRamps, getRampStartPeriods } from "app/lib/plans/ramps";
import {
  BlockConfiguration,
  CompositeCharge,
  CreditTypeConversion,
  DraftPlan,
  FlatFee,
  Price,
  PricedPricingFactor,
  PricedProduct,
} from "app/lib/plans/types";
import { CustomCreditType, FiatCreditType } from "app/types/credit-types";
import {
  ChargeTypeEnum_Enum,
  SeatPrice,
} from "types/generated-graphql/__types__";
import {
  isSkipRamp,
  pricingFactorStartPeriodSort,
} from "./components/PriceProductTable";

function replaceOrConcat<T>(
  list: T[],
  newItem: T,
  match: (x: T) => boolean,
): T[] {
  if (list.some(match)) {
    return list.map((x) => (match(x) ? newItem : x));
  } else {
    return list.concat([newItem]);
  }
}

/**
 * Finds and returns a priced product in a draft plan. If none exists, returns a new priced product.
 */
function getPricedProduct(
  draftPlanPricedProducts: PricedProduct[],
  productId: string,
) {
  return (
    draftPlanPricedProducts.find((pp) => pp.productId === productId) || {
      productId: productId,
      pricingFactors: [],
    }
  );
}

/**
 * Finds and returns a priced pricing factor in a priced product for a given ramp. If none exists, returns a new priced pricing factor.
 */
export function getProductPricingFactorForRamp(
  pricedProduct: PricedProduct,
  pricingFactorId: string,
  rampStartPeriod: number,
) {
  return (
    pricedProduct.pricingFactors.find(
      (pppf) =>
        pppf.pricingFactorId === pricingFactorId &&
        pppf.startPeriod === rampStartPeriod,
    ) || { pricingFactorId: pricingFactorId, startPeriod: rampStartPeriod }
  );
}

/**
 * Returns sorted priced pricing factors for a given pricing factor id.
 */
function getSortedPricingFactors(
  pricedProduct: PricedProduct,
  pricingFactorId: string,
) {
  return [...pricedProduct.pricingFactors]
    .filter((ppf) => ppf.pricingFactorId === pricingFactorId)
    .sort(pricingFactorStartPeriodSort);
}

/**
 * Returns priced pricing factors for ramps after `rampStartPeriod`.
 */
function getProductPricingFactorsForLaterRamps(
  sortedPricingFactors: PricedPricingFactor[],
  pricingFactorId: string,
  rampStartPeriod: number,
) {
  return sortedPricingFactors
    .map((pppf, index) => {
      return { pppf, rampIndex: index };
    })
    .filter(
      ({ pppf }) =>
        pppf.pricingFactorId === pricingFactorId &&
        (pppf.startPeriod ?? 0) > rampStartPeriod,
    );
}

/**
 * Returns updated priced product pricing factors for a given pricing factor id.
 */
function getUpdatedPricingFactors(
  pricedPricingFactors: PricedPricingFactor[],
  pricingFactorId: string,
  pricedProduct: PricedProduct,
) {
  return pricedPricingFactors.reduce((prev, curr) => {
    return replaceOrConcat(
      prev,
      curr,
      (pppf) =>
        pppf.pricingFactorId === pricingFactorId &&
        pppf.startPeriod === curr.startPeriod,
    );
  }, pricedProduct.pricingFactors);
}

export const getUpdateCreditTypeForProductRampMerged = (
  newCreditType: FiatCreditType | CustomCreditType | undefined,
  productId: string,
  draftPlan: DraftPlan,
): DraftPlan => {
  const definedPricedProducts = draftPlan.pricedProducts || [];
  const pricedProduct = definedPricedProducts.find(
    (pp) => pp.productId === productId,
  ) || {
    productId: productId,
    pricingFactors: [],
  };

  // Update all priced products that have a matching product id
  // Also, if this is a fiat currency, update all other priced products to have the same fiat currency
  let newPricedProducts = definedPricedProducts.map((pp) =>
    pp.productId === productId ||
    (pp.creditType &&
      pp.creditType.client_id === null &&
      newCreditType?.client_id === null)
      ? { ...pp, creditType: newCreditType }
      : pp,
  );

  // When a new pricing unit is selected, remove composite charge pricing factors whose credit type
  // no longer matches the composite charge credit type
  newPricedProducts = newPricedProducts.map((pp) => ({
    ...pp,
    pricingFactors: pp.pricingFactors.map((pf) => {
      return pf.compositeCharge
        ? {
            ...pf,
            compositeCharge: pf.compositeCharge.map((cc) => {
              // 1. Create a list of pricing factor IDs that have the same credit type as the composite charge
              const pricingFactorIdsWithValidCreditType = newPricedProducts
                // Since credit types are assigned at the product level, filter out products whose credit types
                // are not the same as the product of the composite charge
                .filter(
                  (product) => product.creditType?.id === pp.creditType?.id,
                )
                // We only need the pricing factor IDs
                .flatMap((product) =>
                  product.pricingFactors.map(
                    (pricingFactor) => pricingFactor.pricingFactorId,
                  ),
                );
              return {
                ...cc,
                // 2. Filter out IDs of any previously selected pricing factors that no longer match the
                //    composite charge's credit type
                pricingFactors: (cc.pricingFactors ?? []).filter((ccpf) =>
                  pricingFactorIdsWithValidCreditType.includes(ccpf.id),
                ),
              };
            }),
          }
        : pf;
    }),
  }));

  // If we didn't already have a matching priced product, ensure that one is there
  if (!newPricedProducts.some((pp) => pp.productId === productId)) {
    newPricedProducts.push({ ...pricedProduct, creditType: newCreditType });
  }

  // We should delete any minimums or conversions that no longer correspond to credit types used in the plan
  const allowedCreditTypeIds = new Set<string>(
    newPricedProducts.map((pp) => pp.creditType?.id ?? ""),
  );

  return {
    ...draftPlan,
    pricedProducts: newPricedProducts,
    creditTypeConversions: draftPlan.creditTypeConversions?.filter(
      (c) =>
        allowedCreditTypeIds.has(c.customCreditType.id) &&
        (newCreditType?.client_id !== null ||
          c.fiatCreditType.id === newCreditType.id),
    ),
    minimums: draftPlan.minimums?.filter(
      (m) => m.creditType && allowedCreditTypeIds.has(m.creditType.id),
    ),
  };
};

export const getUpdatePricedProductMerged = (
  newTiers: Array<Price>,
  volumePricing: boolean | undefined,
  tierResetFrequency: number | undefined,
  productId: string,
  pricingFactorId: string,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  const draftPlanPricedProducts = draftPlan.pricedProducts || [];

  const pricedProduct = draftPlanPricedProducts.find(
    (pp) => pp.productId === productId,
  ) || {
    productId: productId,
    pricingFactors: [],
  };

  const productPricingFactorForRamp = pricedProduct.pricingFactors.find(
    (pppf) =>
      pppf.pricingFactorId === pricingFactorId &&
      pppf.startPeriod === rampStartPeriod,
  ) || { pricingFactorId: pricingFactorId, startPeriod: rampStartPeriod };

  const updatedPricingFactors = replaceOrConcat(
    pricedProduct.pricingFactors,
    {
      ...productPricingFactorForRamp,
      prices: newTiers,
      chargeType: ChargeTypeEnum_Enum.Usage,
      volumePricing: volumePricing ?? productPricingFactorForRamp.volumePricing,
      // only standard usage tiering supports customized tier reset frequency
      tierResetFrequency: volumePricing ? undefined : tierResetFrequency,
    },
    (pppf) =>
      pppf.pricingFactorId === pricingFactorId &&
      pppf.startPeriod === rampStartPeriod,
  );

  return {
    ...draftPlan,
    pricedProducts: replaceOrConcat(
      draftPlanPricedProducts,
      { ...pricedProduct, pricingFactors: updatedPricingFactors },
      (pricedProduct) => pricedProduct.productId === productId,
    ),
  };
};

export const getUpdateSeatPriceMerged = (
  newSeatPrices: SeatPrice[],
  productId: string,
  pricingFactorId: string,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  const draftPlanPricedProducts = draftPlan.pricedProducts || [];

  const pricedProduct = draftPlanPricedProducts.find(
    (pp) => pp.productId === productId,
  ) || {
    productId: productId,
    pricingFactors: [],
  };

  const productPricingFactorForRamp = pricedProduct.pricingFactors.find(
    (pppf) =>
      pppf.pricingFactorId === pricingFactorId &&
      pppf.startPeriod === rampStartPeriod,
  ) || { pricingFactorId: pricingFactorId, startPeriod: rampStartPeriod };

  const updatedPricingFactors = replaceOrConcat(
    pricedProduct.pricingFactors,
    {
      ...productPricingFactorForRamp,
      seatPrices: newSeatPrices,
      chargeType: ChargeTypeEnum_Enum.Seat,
    },
    (pppf) =>
      pppf.pricingFactorId === pricingFactorId &&
      pppf.startPeriod === rampStartPeriod,
  );

  return {
    ...draftPlan,
    pricedProducts: replaceOrConcat(
      draftPlanPricedProducts,
      { ...pricedProduct, pricingFactors: updatedPricingFactors },
      (pricedProduct) => pricedProduct.productId === productId,
    ),
  };
};

export const getUpdateFlatFeeMerged = (
  newFees: FlatFee[],
  productId: string,
  pricingFactorId: string,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  const draftPlanPricedProducts = draftPlan.pricedProducts || [];
  const pricedProduct = getPricedProduct(draftPlanPricedProducts, productId);

  const productPricingFactorForRamp = getProductPricingFactorForRamp(
    pricedProduct,
    pricingFactorId,
    rampStartPeriod,
  );
  let updatedPPFs: PricedPricingFactor[] = [
    {
      ...productPricingFactorForRamp,
      flatFees: newFees,
      chargeType: ChargeTypeEnum_Enum.Flat,
      skipRamp: false,
    },
  ];

  // Collect and sort pricing factors for this ID
  const sortedPricingFactors = getSortedPricingFactors(
    pricedProduct,
    pricingFactorId,
  );

  // Only update ramps that are after this ramp
  const productPricingFactorsForLaterRamps =
    getProductPricingFactorsForLaterRamps(
      sortedPricingFactors,
      pricingFactorId,
      rampStartPeriod,
    );

  // Since we update all future ramps we need to project skip ramps
  const skipRampList = productPricingFactorsForLaterRamps.map(
    ({ pppf, rampIndex }) => {
      return isSkipRamp(
        sortedPricingFactors,
        pricingFactorId,
        pppf.startPeriod ?? 0,
        rampIndex,
        newFees[0].collectionInterval,
        newFees[0].collectionSchedule,
      );
    },
  );

  updatedPPFs = updatedPPFs.concat(
    productPricingFactorsForLaterRamps.map(({ pppf: ppf }, index) => ({
      ...ppf,
      flatFees: ppf.flatFees?.map((ff) => {
        return {
          ...ff,
          collectionSchedule: newFees[0].collectionSchedule,
          collectionInterval:
            newFees[0].collectionSchedule === "ADVANCE"
              ? newFees[0].collectionInterval ??
                ppf.flatFees?.[0]?.collectionInterval
              : undefined,
          isProrated:
            newFees[0].collectionSchedule === "ONE_TIME_ADVANCE"
              ? false
              : newFees[0].isProrated ?? false,
        };
      }),
      // Only set skip ramps when the collection interval is changing
      skipRamp: ["ONE_TIME_ADVANCE", "ADVANCE"].includes(
        newFees[0].collectionSchedule ?? "",
      )
        ? skipRampList[index]
        : undefined,
    })),
  );

  return {
    ...draftPlan,
    pricedProducts: replaceOrConcat(
      draftPlanPricedProducts,
      {
        ...pricedProduct,
        pricingFactors: getUpdatedPricingFactors(
          updatedPPFs,
          pricingFactorId,
          pricedProduct,
        ),
      },
      (pricedProduct) => pricedProduct.productId === productId,
    ),
  };
};

export const getUpdateBlockPricingMerged = (
  blockPricing: BlockConfiguration | undefined,
  productId: string,
  pricingFactorId: string,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  const draftPlanPricedProducts = draftPlan.pricedProducts || [];
  const pricedProduct = getPricedProduct(draftPlanPricedProducts, productId);

  const updatedPPFs = [
    {
      pppf: getProductPricingFactorForRamp(
        pricedProduct,
        pricingFactorId,
        rampStartPeriod,
      ),
    },
  ]
    .concat(
      getProductPricingFactorsForLaterRamps(
        getSortedPricingFactors(pricedProduct, pricingFactorId),
        pricingFactorId,
        rampStartPeriod,
      ),
    )
    .map(({ pppf: ppf }) => ({
      ...ppf,
      blockPricing,
    }));

  return {
    ...draftPlan,
    pricedProducts: replaceOrConcat(
      draftPlanPricedProducts,
      {
        ...pricedProduct,
        pricingFactors: getUpdatedPricingFactors(
          updatedPPFs,
          pricingFactorId,
          pricedProduct,
        ),
      },
      (pricedProduct) => pricedProduct.productId === productId,
    ),
  };
};

export const getUpdateCompositeChargeMerged = (
  compositeCharge: CompositeCharge[],
  productId: string,
  pricingFactorId: string,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  const draftPlanPricedProducts = draftPlan.pricedProducts || [];
  const pricedProduct = getPricedProduct(draftPlanPricedProducts, productId);

  const productPricingFactorForRamp = getProductPricingFactorForRamp(
    pricedProduct,
    pricingFactorId,
    rampStartPeriod,
  );
  let updatedPPFs: PricedPricingFactor[] = [
    {
      ...productPricingFactorForRamp,
      compositeCharge: compositeCharge,
      chargeType: ChargeTypeEnum_Enum.Composite,
      skipRamp: false,
    },
  ];

  // Collect and sort pricing factors for this ID
  const sortedPricingFactors = getSortedPricingFactors(
    pricedProduct,
    pricingFactorId,
  );

  // Only update ramps that are after this ramp
  const productPricingFactorsForLaterRamps =
    getProductPricingFactorsForLaterRamps(
      sortedPricingFactors,
      pricingFactorId,
      rampStartPeriod,
    );
  updatedPPFs = updatedPPFs.concat(
    productPricingFactorsForLaterRamps.map(({ pppf: ppf }) => ({
      ...ppf,
      compositeCharge: ppf.compositeCharge?.map((cc) => ({
        ...cc,
      })),
    })),
  );

  return {
    ...draftPlan,
    pricedProducts: replaceOrConcat(
      draftPlanPricedProducts,
      {
        ...pricedProduct,
        pricingFactors: getUpdatedPricingFactors(
          updatedPPFs,
          pricingFactorId,
          pricedProduct,
        ),
      },
      (pricedProduct) => pricedProduct.productId === productId,
    ),
  };
};

export const getUpdateCreditTypeForInvoiceMinimum = (
  newCreditType: FiatCreditType | CustomCreditType | undefined,
  rampStartPeriod: number,
  draftPlan: DraftPlan,
): DraftPlan => {
  // All invoice minimums should be the same credit type, so changing
  // one changes them all.
  const rampMinimum = (draftPlan.minimums ?? []).find(
    (min) => min.startPeriod === rampStartPeriod,
  );
  return {
    ...draftPlan,
    minimums: replaceOrConcat(
      draftPlan.minimums || [],
      {
        creditType: newCreditType,
        startPeriod: rampStartPeriod,
        value: rampMinimum?.value,
      },
      (min) => min.startPeriod === rampStartPeriod,
    ).map((min) => ({
      ...min,
      creditType: newCreditType,
    })),
  };
};

/**
 * Upsert a CreditTypeConversion into a DraftPlan
 * @param newConversion The updated credit type conversion
 * @param draftPlan The draft plan into which updated creditTypeConversions will be merged
 * @returns A DraftPlan with a list of creditTypeConversions, where newConversion was upserted into the list.
 */
export const getUpdateCreditTypeConversionMerged = (
  newConversion: CreditTypeConversion,
  draftPlan: DraftPlan,
): DraftPlan => {
  const definedCreditConversions = draftPlan.creditTypeConversions || [];
  return {
    ...draftPlan,
    creditTypeConversions: replaceOrConcat(
      definedCreditConversions,
      newConversion,
      (conv) =>
        conv.customCreditType.id === newConversion.customCreditType.id &&
        conv.fiatCreditType.id === newConversion.fiatCreditType.id &&
        conv.startPeriod === newConversion.startPeriod,
    ),
  };
};

export const getUpdateCreditTypeConversionFiatCurrency = (
  draftPlan: DraftPlan,
  newFiatCurrency: FiatCreditType,
): DraftPlan => {
  return {
    ...draftPlan,
    creditTypeConversions: draftPlan.creditTypeConversions?.map((ctc) => ({
      ...ctc,
      fiatCreditType: newFiatCurrency,
      toFiatConversionFactor: undefined,
    })),
  };
};

/**
 * Remove all CreditTypeConversions with startPeriod > 0 from a DraftPlan
 * @param draftPlan The draft plan that has too many creditTypeConversions
 * @returns A DraftPlan where the list of creditTypeConversions only includes ones with startPeriod = 0
 */
export const getRemoveRampedCreditTypeConversions = (
  draftPlan: DraftPlan,
): DraftPlan => {
  return {
    ...draftPlan,
    creditTypeConversions: (draftPlan.creditTypeConversions || []).filter(
      (conv) => conv.startPeriod === 0,
    ),
  };
};

/**
 * Insert CreditTypeConversions for all CustomCreditTypes for ramps after the first ramp.
 * @param draftPlan The draft plan that needs creditTypeConversions added for defined ramps.
 * @param customCreditTypes The list of credit types used in the plan that need overage prices
 * @param fiatCreditType The credit type that the customCreditTypes will be converted to.
 * @returns A DraftPlan where the list of creditTypeConversions includes one entry per customCreditType per ramp
 */
export const getAddRampedCreditTypeConversions = (
  draftPlan: DraftPlan,
  customCreditTypes: CustomCreditType[],
  fiatCreditType: FiatCreditType,
): DraftPlan => {
  const subsequentStartPeriods = getRampStartPeriods(getRamps(draftPlan)).slice(
    1,
  );
  return {
    ...draftPlan,
    creditTypeConversions: (draftPlan.creditTypeConversions ?? []).concat(
      customCreditTypes.flatMap((creditTypeToPrice) => {
        return subsequentStartPeriods.map((startPeriodToPrice) => {
          return {
            customCreditType: creditTypeToPrice,
            fiatCreditType: fiatCreditType,
            startPeriod: startPeriodToPrice,
            toFiatConversionFactor: undefined,
          };
        });
      }),
    ),
  };
};

export function reorderProduct(
  draftPlan: DraftPlan,
  productId: string,
  direction: "up" | "down",
): DraftPlan {
  const products = [...(draftPlan.selectedProductIds ?? [])];

  const currentIndex = products.indexOf(productId);
  if (currentIndex === -1) {
    throw new Error("Could not find product to re-order");
  }

  const newIndex = currentIndex + (direction === "up" ? -1 : 1);
  if (newIndex <= -1 || newIndex >= products.length) {
    return draftPlan;
  } else {
    products[currentIndex] = products[newIndex];
    products[newIndex] = productId;
    return {
      ...draftPlan,
      selectedProductIds: products,
    };
  }
}

import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { Environment } from "app/lib/environmentSwitcher/context";

const generateStripeRedirectUrl = (environmentPrefix: string) => {
  if (!process.env.STRIPE_REDIRECT_URI) {
    throw new Error("STRIPE_REDIRECT_URI not defined");
  }
  const uri = new URL(process.env.STRIPE_REDIRECT_URI);
  if (!!environmentPrefix) {
    uri.pathname = environmentPrefix + uri.pathname;
  }
  return uri.toString();
};

export const stripeRedirect = (
  environmentType: EnvironmentTypeEnum_Enum,
  environment: Environment,
) => {
  window.location.href = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
    environmentType === EnvironmentTypeEnum_Enum.Production
      ? process.env.STRIPE_LIVE_CLIENT_ID
      : process.env.STRIPE_TEST_CLIENT_ID
  }&scope=read_write&redirect_uri=${encodeURIComponent(
    generateStripeRedirectUrl(environment.urlSlug),
  )}`;
};

import { useRequiredParams } from "app/lib/routes/params";

import { DetailedCustomerPlanFragmentReader } from "app/pages/Contracts/lib/CustomerPlan";

import { useCustomerPlanByIdQuery } from "./data.graphql";

export type CustomerPlanReq =
  | {
      state: "loading";
      id: string;
    }
  | {
      state: "success";
      id: string;
      customerPlan: DetailedCustomerPlanFragmentReader;
    }
  | {
      state: "error";
      id: string;
      error: Error;
    }
  | {
      state: "not found";
      id: string;
    };

export function useCustomerPlanFromRoute(): CustomerPlanReq {
  const { customerId, customerPlanId } = useRequiredParams(
    "customerId",
    "customerPlanId",
  );

  const { data, loading, error } = useCustomerPlanByIdQuery({
    variables: { customerId, customerPlanId },
  });

  if (loading) {
    return { state: "loading", id: customerPlanId };
  }

  if (error) {
    return { state: "error", id: customerPlanId, error };
  }

  if (!data || !data.customer?.CustomerPlans[0]) {
    return { state: "not found", id: customerPlanId };
  }

  return {
    state: "success",
    id: customerPlanId,
    customerPlan: new DetailedCustomerPlanFragmentReader(
      data.customer.id,
      data.customer.CustomerPlans[0],
    ),
  };
}

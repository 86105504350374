import React from "react";
import { Badge } from "design-system";
import classNames from "classnames";

import {
  StatusPillsFieldsFragment,
  BaseStatusPillsFieldsFragment,
} from "./fragments.graphql";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  getMainInvoiceStatusBadgeContent,
  getBillingProviderStatusContent,
  getTooltipContent,
  getTrialContent,
} from "./helpers";

export const DeprecatedStatusPills: React.FC<{
  invoice: StatusPillsFieldsFragment;
  compact?: boolean;
  className?: string;
  light: boolean;
  /**
   * Set to true to right align the status pills
   */
  right?: boolean;
}> = ({ invoice, compact, className, light, right }) => {
  const contents: React.ReactNode[] = [];
  const type = light ? "light" : "dark";
  const { environmentType } = useEnvironment();

  if (invoice.status === "VOID") {
    return (
      <div
        className={classNames(
          "flex items-start",
          right && "items-end",
          className,
        )}
      >
        <Badge key="status" type={type} theme="error">
          VOID
        </Badge>
        {...getBillingProviderStatusContent(
          invoice,
          environmentType,
          light,
          compact,
        )}
      </div>
    );
  }

  contents.push(...getMainInvoiceStatusBadgeContent(invoice, light));
  contents.push(...getTooltipContent(invoice, compact));
  contents.push(...getTrialContent(invoice, light, compact));
  contents.push(
    ...getBillingProviderStatusContent(
      invoice,
      environmentType,
      light,
      compact,
    ),
  );

  return (
    <div
      className={classNames(
        "flex items-start",
        right && "items-end",
        className,
      )}
    >
      {contents}
    </div>
  );
};

export const DeprecatedEmbeddableDashboardStatusPills: React.FC<{
  invoice: BaseStatusPillsFieldsFragment;
  compact?: boolean;
  className?: string;
  light: boolean;
  /**
   * Set to true to right align the status pills
   */
  right?: boolean;
}> = ({ invoice, compact, className, light, right }) => {
  const contents: React.ReactNode[] = [];
  const type = light ? "light" : "dark";

  if (invoice.status === "VOID") {
    return (
      <div
        className={classNames(
          "flex items-start",
          right && "items-end",
          className,
        )}
      >
        <Badge key="status" type={type} theme="error">
          VOID
        </Badge>
      </div>
    );
  }

  contents.push(...getMainInvoiceStatusBadgeContent(invoice, light));
  contents.push(...getTooltipContent(invoice, compact));
  contents.push(...getTrialContent(invoice, light, compact));

  return (
    <div
      className={classNames(
        "flex items-start",
        right && "items-end",
        className,
      )}
    >
      {contents}
    </div>
  );
};

import React from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Button } from "components/Button";

interface Props {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmModal: React.FC<Props> = (props) => {
  return (
    <DeprecatedPopup
      title={props.title}
      isOpen
      onRequestClose={props.onCancel}
      actions={
        <div className="flex justify-end gap-12">
          <Button onClick={props.onCancel} text="Cancel" theme="secondary" />
          <Button onClick={props.onConfirm} text="Confirm" theme="secondary" />
        </div>
      }
    >
      {props.children}
    </DeprecatedPopup>
  );
};

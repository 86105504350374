import React from "react";
import { AvatarWithName } from "design-system";

import { dayjs } from "lib/date";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { useSearcher } from "app/lib/search/useSearcher";
import { EmptyState } from "components/EmptyState";
import { useContractRateCardsListQuery } from "./data.graphql";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { Table } from "components/Table";
import { Button } from "components/Button";
import { Timestamp } from "components/Timestamp";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "app/pages/Offering/constants";

export const RateCardsList: React.FC = () => {
  const req = useContractRateCardsListQuery();
  const [searchTerm, setSearchTerm] = React.useState("");

  const rateCards = (req.data?.contract_pricing.rate_cards ?? []).map((rc) => ({
    ...rc,
    __search__productNames: rc.products.flatMap((p) =>
      ProductListItem.getAllNames(p),
    ),
  }));

  const searcher = useSearcher(rateCards, {
    keys: ["name"],
    threshold: 0.1,
    includeScore: true,
  });

  const searchResults = searcher(searchTerm);

  if (req.error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading your rate cards"
        error={req.error}
      />
    );
  }

  return (
    <Table
      title="Rate card catalog"
      loading={req.loading}
      emptyState={
        searchTerm ? (
          <EmptyState
            icon="searchSm"
            mainText="No rate cards match your query"
            supportingText=""
          />
        ) : (
          <EmptyState
            icon="file05"
            mainText="No rate cards"
            supportingText="Rate cards provide a centralized vehicle to store and update pricing information for your products."
          />
        )
      }
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorFn: (row) => row.name,
        },
        {
          id: "productCount",
          header: "Product count",
          cell: (props) => props.getValue(),
          accessorFn: (row) => row.products.length,
        },
        {
          id: "user",
          header: "Created by",
          cell: (props) => (
            <AvatarWithName
              name={props.getValue().name}
              id={props.getValue().id}
              deprecated_at={props.getValue().deprecated_at}
            />
          ),
          accessorFn: (row) => row.Creator,
        },
        {
          id: "lastEdited",
          header: "Last edited (UTC)",
          cell: (props) => (
            <Timestamp dateTime={props.getValue()} dateOnly={true} />
          ),
          accessorFn: (row) => {
            return dayjs.utc(row.updated_at).toDate();
          },
        },
      ]}
      data={searchResults}
      searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
      topBarActions={[
        <Button
          text="Add"
          linkTo="/offering/rate-cards/new"
          leadingIcon="plus"
          theme="primary"
          key={1}
        />,
      ]}
      rowRoutePath={(row) => `/offering/rate-cards/${row.id}`}
      paginationOptions={{
        type: "clientSide",
        pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
      }}
      defaultSort={searchTerm ? [] : [{ id: "name", desc: false }]}
    />
  );
};

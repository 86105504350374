import React from "react";
import classNames from "classnames";

import { Breadcrumbs } from "app/lib/breadcrumbs";
import { useRequiredParam } from "app/lib/routes/params";
import { AppShell } from "components/AppShell";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { NotFoundPage } from "app/pages/404";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";

import { Contract } from "../../../lib/Contract";
import {
  ApolloResp,
  ApolloSuccess,
  isLoaded,
} from "app/pages/Contracts/lib/ApolloResp";
import { useFeatureFlag } from "app/lib/launchdarkly";

type MinReq = ApolloResp<{
  customer: { name: string; contract: Contract.NameFragment | null };
}>;

type RenderProps<Req extends MinReq> = ApolloSuccess<Req> & {
  contract: NonNullable<ApolloSuccess<Req>["customer"]["contract"]>;
};

interface Props<Req extends MinReq> {
  rootReq: Req;
  tabsAction?: React.ReactNode;
  disableContainerScroll?: boolean | ((props: RenderProps<Req>) => boolean);
  content: (props: RenderProps<Req>) => React.ReactNode;
}

function toRenderProps<Req extends MinReq>(req: Req): RenderProps<Req> {
  if (!isLoaded(req) || !req.customer.contract) {
    throw new Error("req must be loaded");
  }

  return {
    ...req,
    contract: req.customer.contract,
  };
}

export function CustomerContractLayout<Cust extends MinReq>({
  rootReq,
  tabsAction,
  disableContainerScroll,
  content,
}: Props<Cust>) {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");

  const editEnabled = useFeatureFlag<boolean>("allow-contract-editing", false);

  if (
    rootReq.state === "not found" ||
    // customer loaded but contract id is invalid or not found
    (isLoaded(rootReq) && !rootReq.customer.contract)
  ) {
    return <NotFoundPage />;
  }

  if (rootReq.state === "error") {
    return (
      <AppShell title="Unable to load contract">
        <ErrorEmptyState
          title="We were unable to load this contract"
          error={rootReq.error}
        />
      </AppShell>
    );
  }
  const routePath = `/customers/${customerId}/contracts/${contractId}`;

  // disable container scroll if displaying empty state for contract commits
  const scrollDisabled =
    typeof disableContainerScroll === "function"
      ? isLoaded(rootReq)
        ? disableContainerScroll(toRenderProps(rootReq))
        : true
      : !!disableContainerScroll;

  return (
    <AppShell
      title={
        rootReq.state === "loading" || !rootReq.customer.contract
          ? { state: "loading" }
          : Contract.getName(rootReq.customer.contract)
      }
      headerProps={{
        actions: tabsAction,
        tabs: [
          { name: "Contract overview", path: routePath, exactMatch: true },
          {
            name: "Invoices",
            path: `${routePath}/invoices`,
          },
          {
            name: "Commits and credits",
            path: `${routePath}/commits-and-credits`,
          },
          ...(editEnabled
            ? []
            : [
                {
                  name: "Lifecycle",
                  path: `${routePath}/lifecycle`,
                },
              ]),
        ],
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Customers",
            routePath: "/customers",
          },
          rootReq.state === "loading"
            ? { type: "loading", label: "" }
            : {
                label: rootReq.customer?.name ?? "",
                routePath: `/customers/${customerId}`,
              },
          {
            /* TODO change this to have a route path when we have a page that shows all contracts */
            label: "Contracts",
          },
        ),
      }}
    >
      <div
        className={classNames("max-w-[2400px]", "mb-12", {
          ["flex h-full flex-col overflow-hidden"]: scrollDisabled,
        })}
      >
        {isLoaded(rootReq) ? (
          content(toRenderProps(rootReq))
        ) : (
          <DeprecatedParagraphSkeleton
            numLines={6}
            className="w-4/5 xl:w-3/4"
          />
        )}
      </div>
    </AppShell>
  );
}

import React from "react";
import classNames from "classnames";
import { Dropdown, DropdownProps, DropdownItem } from "design-system";

export type { DropdownItem };

export interface OptionType {
  label: string;
  value: string;
  group: string;
  type: "single" | "multi";
}
export type FilterOptions = Record<
  string,
  {
    label: string;
    options: OptionType[];
  }
>;

interface FilterProps {
  value: readonly OptionType[];
  options: FilterOptions;
  onChange: (filters: OptionType[]) => void;
  onReset?: () => void;
  className?: string;
  showCurrentValue?: boolean;
  containerClassname?: string;
}

const includes = (options: readonly OptionType[], a: OptionType) =>
  options.some((b) => a.group === b.group && a.value === b.value);
const remove = (options: readonly OptionType[], a: OptionType) =>
  options.filter((b) => !(a.group === b.group && a.value === b.value));
const add = (options: readonly OptionType[], option: OptionType) =>
  options.concat(option);

/**
 * Dropdown pre-configured for use as a filter, without the state requirements of Filter
 */
export const DeprecatedFilterDropdown: React.FC<
  Omit<DropdownProps, "title" | "icon" | "action"> & {
    onReset?: () => void;
    title?: string;
  }
> = ({ onReset, title, ...dropdownProps }) => (
  <Dropdown
    title={title || "Filter"}
    icon="filter"
    action={
      onReset
        ? {
            label: "Reset",
            onClick: onReset,
          }
        : undefined
    }
    {...dropdownProps}
  />
);

export const DeprecatedFilter: React.FC<FilterProps> = ({
  value,
  options,
  onChange,
  onReset,
  className,
  showCurrentValue = false,
  containerClassname,
}) => {
  return (
    <DeprecatedFilterDropdown
      onReset={onReset}
      title={showCurrentValue ? value[0].label : undefined}
      className={classNames("ml-12", className)}
      containerClassname={containerClassname}
      menu={Object.entries(options).map(([, group]) => ({
        label: group.label,
        items: group.options.map(
          (option): DropdownItem =>
            option.type === "single"
              ? {
                  type: "button",
                  active: includes(value, option),
                  label: option.label,
                  onClick: () => {
                    if (!includes(value, option)) {
                      onChange([
                        ...value.filter((o) => o.group !== option.group),
                        option,
                      ]);
                    }
                  },
                }
              : {
                  type: "checkbox",
                  checked: includes(value, option),
                  label: option.label,
                  onClick: () => {
                    onChange(
                      includes(value, option)
                        ? remove(value, option)
                        : add(value, option),
                    );
                  },
                },
        ),
      }))}
    />
  );
};

import React from "react";
import { useCustomerPlan } from "../../context";
import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { DeprecatedInputSection } from "components/deprecated/Wizard/components/InputSection";
import { PlanQuery } from "app/lib/plans/queries.graphql";
import { DeprecatedPlanPreview } from "components/deprecated/PlanPreview";
import { BlockSkeleton } from "components/deprecated/Skeleton";
import { CreditType, CustomCreditType } from "app/types/credit-types";
import { CustomPricing as CustomPricingType } from "app/lib/customPricing";
import Decimal from "decimal.js";
import { OverageRateAdjustments } from "./components/OverageRateAdjustments";
import * as Sentry from "@sentry/react";

interface CustomPricingProps {
  selectedPlan: PlanQuery | null;
  creditTypes: CreditType[];
}

const isCustomCreditType = (ct: CreditType): ct is CustomCreditType =>
  ct.environment_type !== null && ct.client_id !== null;

export const CustomPricing: React.FC<CustomPricingProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();
  const creditTypeConversions =
    props.selectedPlan?.Plan_by_pk?.CreditTypeConversions ?? [];

  return (
    <DeprecatedWizardFullPage>
      <div className="mb-[40px]">
        <DeprecatedInputSection
          header="About price adjustments"
          subtitle="Price adjustments override the plan's default pricing. On an invoice, only the new resulting rates will appear."
        />
        {creditTypeConversions.length > 0 && (
          <OverageRateAdjustments
            conversions={creditTypeConversions.map((conversion) => {
              const customCreditType = conversion.CustomCreditType;
              if (isCustomCreditType(customCreditType)) {
                return {
                  customCreditType,
                  fiatCreditType: {
                    ...conversion.FiatCreditType,
                    client_id: null,
                    environment_type: null,
                  },
                  startPeriod: new Decimal(conversion.start_period).toNumber(),
                  toFiatConversionFactor: new Decimal(
                    conversion.to_fiat_conversion_factor,
                  ).toNumber(),
                };
              }
              Sentry.setContext("context", {
                conversion,
              });
              throw new Error(
                "Custom credit type in conversion does not have expected client ID and/or environment type set",
              );
            })}
            adjustments={customerPlan.creditTypeConversionAdjustments}
            handleCreditTypeConversionAdjustmentsChange={(adjustments) => {
              setCustomerPlan({
                ...customerPlan,
                creditTypeConversionAdjustments: adjustments,
              });
            }}
          />
        )}
        {props.selectedPlan?.Plan_by_pk ? (
          <DeprecatedPlanPreview
            plan={{
              ...props.selectedPlan.Plan_by_pk,
              // Override the trial spec from the plan with the one set by the client specifically for this customer
              TrialSpec: customerPlan.trialSpec
                ? {
                    id: "",
                    length_in_days: String(customerPlan.trialSpec.length ?? ""),
                    TrialSpecSpendingCaps:
                      customerPlan.trialSpec?.caps?.map((c) => ({
                        id: "",
                        CreditType: props.creditTypes.find(
                          (ct) => ct.id === c.creditTypeId,
                        ) ?? {
                          id: "",
                          name: "",
                          client_id: null,
                          environment_type: null,
                        },
                        amount: c.amount?.toString() ?? "",
                      })) ?? [],
                  }
                : null,
            }}
            collapsible={false}
            customPricing={customerPlan.customPricing}
            hidePlanBasics
            setCustomPricing={(customPricing: CustomPricingType) => {
              setCustomerPlan({
                ...customerPlan,
                customPricing: customPricing,
              });
            }}
            creditTypeConversionAdjustments={
              customerPlan.creditTypeConversionAdjustments ?? []
            }
          />
        ) : (
          <BlockSkeleton />
        )}
      </div>
    </DeprecatedWizardFullPage>
  );
};

import React from "react";
import { DocumentNode } from "graphql";
import { useAuthCheck } from "app/lib/useAuthCheck";
import { EmptyState } from "components/EmptyState";

interface AuthCheckProps extends React.PropsWithChildren {
  authDoc: DocumentNode | undefined;
  authVars?: any;
  notAllowedComponent?: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

export const DeprecatedAuthCheck: React.FC<AuthCheckProps> = ({
  children,
  authDoc,
  authVars,
  notAllowedComponent,
  loadingComponent,
}) => {
  const { loading, allowed } = useAuthCheck(authDoc, true, authVars);
  if (loading && loadingComponent !== undefined) {
    return loadingComponent;
  }

  if (allowed) {
    return <>{children}</>;
  } else {
    return notAllowedComponent === undefined ? (
      <EmptyState
        mainText="You do not have access to view this page"
        icon="lockKeyholeSquare"
      />
    ) : (
      notAllowedComponent
    );
  }
};

import React from "react";
import { DeprecatedAuditLogTable } from "components/deprecated/AuditLogTable";
import { useRequiredParam } from "app/lib/routes/params";

export const CustomerAuditLogs: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  return (
    <DeprecatedAuditLogTable resourceType="customer" resourceID={customerId} />
  );
};

import React, { useState } from "react";
import { dayjs } from "lib/dayjs";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";
import { Button } from "components/Button";
import { DateInput, Input } from "design-system";
import { Checkbox } from "design-system";
import {
  useCancelPlanMutation,
  useSuperEndCustomerPlanMutation,
  useLatestInvoiceFinalizationDateQuery,
} from "./queries.graphql";
import { addDays, subDays } from "date-fns";
import { getUtcEndDate } from "lib/time";
import { useSnackbar } from "components/deprecated/Snackbar";
import { CustomerPlansQuery } from "../../data/plans.graphql";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { planEndDateWarning } from "app/lib/plans/planPeriods";
import { ApolloCache } from "@apollo/client";
import { clearCustomerInvoicesFromCache } from "../../../Credits/lib/cache";
import { useNavigate } from "app/lib/useNavigate";

interface CancelPlanModalProps {
  onClose: () => void;
  customerPlan: CustomerPlansQuery["CustomerPlan"][0];
  customerId: string;
  customerName: string;
}

const CancelPlanModal: React.FC<CancelPlanModalProps> = ({
  onClose,
  customerPlan,
  customerId,
  customerName,
}) => {
  const navigate = useNavigate();
  const [cancelPlanMutation] = useCancelPlanMutation();
  const [superEndCustomerPlanMutation] = useSuperEndCustomerPlanMutation();
  const superEndPlanAllowed = useFeatureFlag("super-end-plan", false);
  const [currentStep, setCurrentStep] = useState<
    "date" | "warning" | "confirm" | "running"
  >("date");
  const [cancellationDate, setCancellationDate] = useState<Date>();
  const [confirmationText, setConfirmationText] = useState<string>();
  const [voidInvoices, setVoidInvoices] = useState<boolean>(false);
  const [voidStripeInvoices, setVoidStripeInvoices] = useState<boolean>(false);
  const pushMessage = useSnackbar();
  const startDate = new Date(customerPlan.start_date);
  const cancellationWarning = cancellationDate
    ? planEndDateWarning(
        customerPlan.Plan.billing_frequency,
        customerPlan.Plan.service_period_start_type,
        customerPlan.Plan.PricedProducts.map((pp) => {
          return {
            PricedProductPricingFactors: pp.PricedProductPricingFactors.map(
              (pppf) => {
                return {
                  startPeriod: Number(pppf.start_period),
                  productPricingFactor: {
                    id: pppf.ProductPricingFactor.id,
                    name: pppf.ProductPricingFactor.name,
                  },
                  flatFee: pppf.FlatFees?.length
                    ? {
                        collectionSchedule:
                          pppf.FlatFees?.[0]?.collection_schedule,
                        collectionInterval:
                          pppf.FlatFees?.[0]?.collection_interval,
                        isProrated: !!pppf.FlatFees?.[0]?.is_prorated,
                      }
                    : null,
                };
              },
            ),
          };
        }),
        startDate,
        cancellationDate,
        new Date(),
      )
    : undefined;

  const cancelPlanAction = async () => {
    if (!cancellationDate) {
      return;
    }

    setCurrentStep("running");

    const variables = {
      customer_id: customerId,
      customer_plan_id: customerPlan.id,
      cancellation_date: getUtcEndDate(cancellationDate).toISOString(),
    };

    function updateCache(cache: ApolloCache<unknown>) {
      cache.evict({ fieldName: "CustomerPlan" });
      /* Evict plan so plan customer count gets updated */
      if (customerPlan.Plan?.id) {
        cache.evict({
          id: `Plan:${customerPlan.Plan?.id}`,
        });
      }
      clearCustomerInvoicesFromCache(cache, customerId);
    }

    try {
      if (superEndPlanAllowed && voidInvoices) {
        await superEndCustomerPlanMutation({
          variables: {
            ...variables,
            void_stripe_invoices: voidStripeInvoices,
          },
          update: updateCache,
        });
      } else {
        await cancelPlanMutation({
          variables,
          update: updateCache,
        });
      }
      if (variables.cancellation_date === startDate.toISOString()) {
        // If the cancellation date equals the start date, we'll get
        // a Not found error when refetching the plan. To avoid hitting a 404 page,
        // redirect to the customer overview after plan cancellation.
        navigate(`/customers/${customerId}`);
      }

      pushMessage({ content: "Plan cancellation scheduled", type: "success" });
      onClose();
    } catch (e: any) {
      pushMessage({
        content: `Failed to end plan: ${e.message}`,
        type: "error",
      });
    }
  };

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      {currentStep === "date" ? (
        <Button
          key="primary"
          disabled={!cancellationDate}
          onClick={() =>
            setCurrentStep(cancellationWarning ? "warning" : "confirm")
          }
          text="Continue"
          theme="primary"
        />
      ) : currentStep === "warning" ? (
        <Button
          key="primary"
          onClick={() => setCurrentStep("confirm")}
          text="Continue"
          theme="primary"
        />
      ) : (
        <Button
          key="primary"
          disabled={
            // Convert consecutive whitespace to single space when comparing.
            // In case the customerName has multiple spaces, we want
            // "Daniel Chen" to still match with "Daniel  Chen"
            currentStep === "running" ||
            confirmationText?.trim().replace(/\s+/g, " ") !==
              customerName.trim().replace(/\s+/g, " ")
          }
          onClick={cancelPlanAction}
          text={currentStep === "running" ? "End plan..." : "End plan"}
          theme="primary"
        />
      )}
    </>
  );

  const { data: finalizedInvoiceData } = useLatestInvoiceFinalizationDateQuery({
    variables: {
      customer_id: customerId,
    },
  });
  const lastFinalizedInvoiceDate = finalizedInvoiceData?.customer
    ?.end_date_of_last_finalized_invoice
    ? new Date(
        finalizedInvoiceData?.customer?.end_date_of_last_finalized_invoice,
      )
    : dayjs.utc().subtract(10, "year").toDate();

  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={onClose}
      title="End plan"
    >
      {currentStep === "date" ? (
        <>
          <Body level={2}>
            What date should this plan end? Plan end date must be greater than
            or equal to the end date of the latest finalized invoice.
          </Body>
          <DateInput
            name="Date"
            onChange={setCancellationDate}
            value={cancellationDate}
            minDate={
              superEndPlanAllowed && voidInvoices
                ? undefined
                : subDays(lastFinalizedInvoiceDate, 1)
            } // the code that invokes the mutation automatically adds a day
            placeholder={new Date().toLocaleDateString()}
          />
          {cancellationDate && (
            <Body level={2}>
              {`Plan ends effective on ${addDays(
                cancellationDate,
                1,
              ).toLocaleDateString()} (12:00am UTC)`}
            </Body>
          )}
          {superEndPlanAllowed && (
            <>
              <Checkbox
                checked={voidInvoices}
                label="Void invoices"
                onChange={(e) => {
                  setVoidInvoices(e);
                  if (!e) {
                    setVoidStripeInvoices(false);
                  }
                }}
              />
              <Checkbox
                checked={voidStripeInvoices}
                label="Void stripe invoices"
                onChange={setVoidStripeInvoices}
                disabled={!voidInvoices}
              />
            </>
          )}
        </>
      ) : currentStep === "warning" && cancellationWarning ? (
        <>
          <Body level={2}>{cancellationWarning}</Body>
        </>
      ) : (
        <>
          <Body level={2}>
            Are you sure you want to end their plan? If so, enter the customer's
            name.
          </Body>
          <Input
            placeholder={customerName.trim()}
            name={`Enter "${customerName.trim()}"`}
            onChange={(v) => setConfirmationText(v)}
          />
        </>
      )}
    </DeprecatedPopup>
  );
};

export default CancelPlanModal;

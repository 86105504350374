import type { Decimal } from "decimal.js";

import {
  BillingFrequencyEnum_Enum,
  BillingProviderEnum_Enum,
  ChargeTypeEnum_Enum,
  CollectionScheduleEnum_Enum,
  CompositeChargeTypeEnum_Enum,
  RoundingBehaviorEnum_Enum,
  SeatPrice,
  ServicePeriodStartOnEnum_Enum,
} from "types/generated-graphql/__types__";
import {
  CreditType,
  CustomCreditType,
  FiatCreditType,
} from "app/types/credit-types";

export type { PlanDetailsFragment as PlanDetails } from "./planDetails.graphql";

export type BillingDayOfPeriod = "FIRST_OF_MONTH" | "START_OF_PLAN";
export type CollectionSchedule = "ARREARS" | "ADVANCE" | "ONE_TIME_ADVANCE";

export const billingDayOfPeriodToEnum = (
  val: BillingDayOfPeriod,
): ServicePeriodStartOnEnum_Enum => {
  switch (val) {
    case "FIRST_OF_MONTH":
      return ServicePeriodStartOnEnum_Enum.FirstOfMonth;
    case "START_OF_PLAN":
      return ServicePeriodStartOnEnum_Enum.StartOfPlan;
  }
};

export const collectionScheduleToEnum = (
  val: CollectionSchedule,
): CollectionScheduleEnum_Enum => {
  switch (val) {
    case "ARREARS":
      return CollectionScheduleEnum_Enum.Arrears;
    case "ADVANCE":
      return CollectionScheduleEnum_Enum.Advance;
    case "ONE_TIME_ADVANCE":
      return CollectionScheduleEnum_Enum.OneTimeAdvance;
  }
};

export type CreditTypeConversion = {
  customCreditType: CustomCreditType;
  fiatCreditType: FiatCreditType;
  startPeriod: number;
  toFiatConversionFactor?: number;
};
export type Minimum = {
  startPeriod: number;
  value?: string;
  creditType?: CreditType;
};
export type Price = {
  metricMinimum?: number;
  value?: string;
};
export type FlatFee = {
  metricMinimum?: number;
  value?: string;
  quantity?: Decimal;
  isProrated?: boolean | null;
  collectionSchedule?: CollectionSchedule;
  collectionInterval?: number;
};
export type CompositeCharge = {
  value?: string;
  compositeMinimum?: number;
  quantity?: Decimal;
  pricingFactors?: { id: string; name: string }[];
  type?: CompositeChargeTypeEnum_Enum;
};

export type BlockConfiguration = {
  size: number;
  roundingBehavior?: RoundingBehaviorEnum_Enum;
};
export type PricedPricingFactor = {
  pricingFactorId: string;
  startPeriod?: number;
  prices?: Array<Price>;
  seatPrices?: Array<SeatPrice>;
  flatFees?: Array<FlatFee>;
  skipRamp?: boolean;
  compositeCharge?: Array<CompositeCharge>;
  chargeType?: ChargeTypeEnum_Enum | null;
  blockPricing?: BlockConfiguration;
  volumePricing?: boolean;
  tierResetFrequency?: number;
};
export type PricedProduct = {
  id?: string;
  productId: string;
  creditType?: CreditType;
  pricingFactors: Array<PricedPricingFactor>;
};
export type PriceRamp = {
  durationInPeriods?: number;
};

type CustomerPlanInfo = {
  customerId: string;
  customerName: string;
  startDate?: Date;
  endDate?: Date | null;
  datesError?: string | null;
};

export type TrialSpecSpendingCap = {
  creditTypeId?: string;
  amount?: Decimal;
};
export type TrialSpec = {
  length?: number;
  caps?: TrialSpecSpendingCap[];
};

export type RecurringCreditGrant = {
  amountGranted?: string;
  amountGrantedCreditType?: CreditType;
  amountPaid?: string;
  amountPaidCreditType?: FiatCreditType;
  effectiveDuration?: number;
  name?: string;
  priority?: string;
  reason?: string;
  recurrence?: {
    duration?: number;
    interval?: number;
  } | null;
  sendInvoice?: boolean;
  productIds?: string[];
  requireProductIds?: boolean;
};

export type DraftPlan = {
  revision: number;
  id?: string;
  name?: string;
  description?: string;
  hasPriceRamps?: boolean;
  hasMinimums?: boolean;
  hasRecurringGrant?: boolean;
  recurringGrant?: RecurringCreditGrant | null;
  hasCustomer?: boolean;
  hasTrial?: boolean;
  customerPlanInfo?: CustomerPlanInfo | null;
  defaultLength?: number | null;
  billingFrequency?: BillingFrequencyEnum_Enum;
  billingProvider?: BillingProviderEnum_Enum | null;
  billingDayOfPeriod?: BillingDayOfPeriod;
  trialSpec?: TrialSpec | null;
  ramps?: Array<PriceRamp>;
  selectedProductIds?: Array<string>;
  originalProductIds?: Array<string>;
  pricedProducts?: Array<PricedProduct>;
  minimums?: Array<Minimum>;
  creditTypeConversions?: Array<CreditTypeConversion>;
  seatBillingFrequency?: BillingFrequencyEnum_Enum | null;

  // Only used for plan edits
  startingOn?: Date | null;
};

// TODO: add a full UI plan type here, not just for draft plans.

import Decimal from "decimal.js";

import {
  BillingFrequencyEnum_Enum,
  BillingProviderEnum_Enum,
  ServicePeriodStartOnEnum_Enum,
} from "types/generated-graphql/__types__";
import { billingFrequencyFriendlyName } from "app/lib/plans/planPeriods";
import { ActorFragment } from "app/lib/actor/fragments.graphql";
import {
  formatPlanLength,
  formatTrialSpec,
  collectRamps,
  findMinimumValue,
  castCreditTypes,
} from "components/deprecated/PlanPreview";
import { CustomPricing } from "app/lib/customPricing";

import { PlanDetailsFragment } from "app/lib/plans/planDetails.graphql";

import { DetailedCustomerPlanFragment } from "./fragments.graphql";
import { formatBillingProvider } from "app/lib/billingProvider/formatBillingProvider";

export class PlanDetailsFragmentReader {
  public readonly description: string;
  public readonly defaultLengthMonths: number | null;
  public readonly createdBy: ActorFragment | null;
  public readonly __fragment: PlanDetailsFragment;
  public readonly invoiceMinimums;

  private readonly billingFrequency: BillingFrequencyEnum_Enum;
  private readonly billingProvider: BillingProviderEnum_Enum | null;
  private readonly servicePeriodStartType: ServicePeriodStartOnEnum_Enum;
  private readonly trialSpec: DetailedCustomerPlanFragment["Plan"]["TrialSpec"];
  private readonly defaultPlanMonths?: number;

  constructor(fragment: PlanDetailsFragment, customPricing?: CustomPricing) {
    this.__fragment = fragment;

    this.description = fragment.description;
    this.defaultLengthMonths = fragment.default_length_months;
    this.billingFrequency = fragment.billing_frequency;
    this.billingProvider = fragment.billing_provider;
    this.servicePeriodStartType = fragment.service_period_start_type;
    this.createdBy = fragment.Actor;
    this.trialSpec = fragment.TrialSpec;
    this.defaultPlanMonths = fragment.default_length_months ?? undefined;

    // TODO: What amount of support for CustomPricing do we need?
    const { invoiceMinimums, ramps } = collectRamps(fragment, customPricing);

    this.invoiceMinimums = Object.values(invoiceMinimums).flatMap(
      (creditTypeMinimums) => {
        const creditType = creditTypeMinimums[0].CreditType;
        const rampMinimum = findMinimumValue(
          creditTypeMinimums,
          ramps[0].startPeriod,
        );

        if (!rampMinimum) {
          return [];
        }

        // Assumes that the creditTypeConversions are sorted in descending start_period order
        const rampMinimumCreditTypeConversion =
          fragment.CreditTypeConversions.find(
            (c) =>
              c.CustomCreditType.id === creditType.id &&
              Number(c.start_period) <= ramps[0].startPeriod,
          );

        return {
          creditType,
          rampMinimum: new Decimal(rampMinimum),
          conversion: rampMinimumCreditTypeConversion
            ? castCreditTypes(rampMinimumCreditTypeConversion)
            : undefined,
        };
      },
    );
  }

  printDefaultPlanLength() {
    return this.defaultPlanMonths
      ? formatPlanLength(this.defaultPlanMonths)
      : undefined;
  }

  printBillingFrequency() {
    return billingFrequencyFriendlyName(
      this.billingFrequency,
      this.servicePeriodStartType,
    );
  }

  printTrialInfo() {
    return this.trialSpec ? formatTrialSpec(this.trialSpec) : undefined;
  }

  printBillingProviderInfo() {
    return formatBillingProvider(this.billingProvider);
  }
}

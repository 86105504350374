import React from "react";
import { Badge } from "design-system";

import { useNow } from "lib/date";
import { Contract } from "app/pages/Contracts/lib/Contract";

export const ContractStatusBadge: React.FC<{
  contract: Contract.StatusFragment;
}> = ({ contract }) => {
  const now = useNow();
  const status = Contract.getStatus(contract, now);

  switch (status) {
    case "upcoming":
      return (
        <Badge theme="grey" type="light">
          UPCOMING
        </Badge>
      );

    case "active-soon":
      return (
        <Badge theme="primary" type="light">
          ACTIVE SOON
        </Badge>
      );

    case "active":
      return (
        <Badge theme="primary" type="dark">
          ACTIVE
        </Badge>
      );

    case "archived":
      return (
        <Badge theme="warning" type="dark">
          ARCHIVED
        </Badge>
      );

    case "ending-soon":
      return (
        <Badge theme="warning" type="dark">
          ENDING SOON
        </Badge>
      );

    case "recently-ended":
      return (
        <Badge theme="warning" type="dark">
          RECENTLY ENDED
        </Badge>
      );

    case "ended":
      return (
        <Badge theme="grey" type="dark">
          ENDED
        </Badge>
      );

    case "inactive (superseded)":
      return (
        <Badge theme="warning" type="dark">
          INACTIVE (SUPERSEDED)
        </Badge>
      );

    case "inactive (renewed)":
      return (
        <Badge theme="warning" type="dark">
          INACTIVE (RENEWED)
        </Badge>
      );

    default:
      status satisfies never;
      return null;
  }
};

import React from "react";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { PageHeaderBreadcrumbs } from "../Breadcrumbs";
import { IconButton } from "../IconButton";
import { HorizontalTabs, TabProps } from "../HorizontalTabs";
import { twMerge } from "../../twMerge";
import { useNavigate } from "app/lib/useNavigate";
import { Badge, BadgeProps } from "../Badge";

export interface PageHeaderProps {
  /** Used to navigate to previous pages */
  breadcrumbs?: Breadcrumbs;
  /** Page title */
  title: string | { state: "loading" } | { state: never };
  /** Buttons displayed inline with title at the right of the page */
  actions?: React.ReactNode;
  /** Function called when a title is edited.  If set, a pencil icon will be shown.  */
  editTitleAction?: () => void;
  /** Badge displayed next to the title */
  titleBadge?: BadgeProps;
  /** Tabs used to navigate within page */
  tabs?: TabProps[];
  /** Base path */
  basePath?: string;
}

/**
 * Page headers provide content and actions related to the current screen.
 * Designed to be used at the top of a page. Supports navigation via breadcrumbs and tabs, and arbitrary actions.
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbs,
  title,
  actions,
  editTitleAction,
  titleBadge,
  tabs,
  basePath,
}) => {
  const navigate = useNavigate();
  const backButtonBreadcrumb = breadcrumbs?.backBreadcrumb();

  let titleNode: React.ReactNode = null;
  if (typeof title === "string") {
    titleNode = <>{title}</>;
  } else if (title.state === "loading") {
    titleNode = (
      <div className="my-md h-xl w-[360px] animate-pulse rounded-lg bg-gray-200" />
    );
  } else {
    title satisfies never;
  }

  return (
    <div
      className={twMerge(
        "flex flex-col px-xl",
        tabs || breadcrumbs ? "pt-xl" : "h-7xl border-b",
        breadcrumbs && !tabs ? "border-b pb-lg" : "",
      )}
    >
      <div className="flex flex-1 items-center gap-8">
        {backButtonBreadcrumb && (
          <IconButton
            icon="flipBackward"
            linkTo={backButtonBreadcrumb.routePath}
            onClick={
              backButtonBreadcrumb.routePath ? undefined : () => navigate(-1)
            }
            theme="secondary"
            className="h-[50px] w-[50px] p-[15px]"
          />
        )}
        <div className="flex-1">
          {breadcrumbs && <PageHeaderBreadcrumbs breadcrumbs={breadcrumbs} />}
          <h5 className="flex items-center gap-md text-display-xs font-semibold text-core-slate">
            {titleNode}
            {editTitleAction && (
              <IconButton
                icon="pencil01"
                onClick={editTitleAction}
                theme="tertiary"
                size="sm"
              />
            )}
            {titleBadge && <Badge {...titleBadge} />}
          </h5>
        </div>
        {actions && (
          <div data-testid="header-action" className="flex min-h-4xl gap-md">
            {actions}
          </div>
        )}
      </div>
      {tabs && (
        <div className="mt-lg">
          <HorizontalTabs tabs={tabs} basePath={basePath} />
        </div>
      )}
    </div>
  );
};

import React from "react";
import styles from "./index.module.less";
import { MenuProps as ReactSelectMenuProps, components } from "react-select";
import classNames from "classnames";
import { DeprecatedStyledDatePicker } from "components/deprecated/StyledDatePicker";
import { Button } from "components/Button";

export type DateInterval = [Date | null, Date | null];

type MenuProps = ReactSelectMenuProps<any> & {
  isCustomSelected: boolean;
  selectedRange: DateInterval;
  setIsCustomSelected: (value: boolean) => void;
  onRangeChange: (range: DateInterval) => void;
};

export const DeprecatedMenu = (props: MenuProps) => {
  const [range, setRange] = React.useState<DateInterval>(props.selectedRange);

  return (
    <components.Menu
      {...props}
      className={classNames(props.className, styles.menu)}
    >
      <>
        {props.isCustomSelected ? (
          <div className={styles.customDatePicker}>
            <DeprecatedStyledDatePicker
              inline
              onChange={(date) => {
                if (date instanceof Date) {
                  throw new Error("Got a single date in range select mode");
                }
                if (date != null) {
                  setRange(date);
                }
              }}
              startDate={range[0]}
              endDate={range[1]}
              maxDate={new Date()}
              selectsRange
            />
          </div>
        ) : null}
        <div className={styles.menuList}>
          {props.children}
          {props.isCustomSelected ? (
            <div className="m-12 flex justify-end gap-12">
              <Button
                onClick={() => props.setIsCustomSelected(false)}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                disabled={!range.every((d) => !!d)}
                onClick={() => props.onRangeChange(range)}
                text="Apply"
                theme="primary"
              />
            </div>
          ) : null}
        </div>
      </>
    </components.Menu>
  );
};

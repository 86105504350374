import React, { useEffect } from "react";
import {
  DeprecatedWizardSplitPage,
  DeprecatedInputSection,
} from "components/deprecated/Wizard";
import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { BlockSkeleton } from "components/deprecated/Skeleton";
import { TermPreview } from "app/pages/Offering/tabs/Plans/PlanWizards/steps/PlanTerms/components/TermPreview";
import { AddPlanToCustomerDataQuery } from "../../queries.graphql";
import { PlanQuery } from "app/lib/plans/queries.graphql";
import { DeprecatedPlanLengthInput } from "components/deprecated/PlanLengthInput";
import { DeprecatedTrialSpecInputSection } from "components/deprecated/TrialSpecInput";
import { Decimal } from "decimal.js";
import {
  CreditType,
  CustomCreditType,
  FiatCreditType,
} from "app/types/credit-types";
import { Toggle } from "design-system";

interface SetPlanLengthProps {
  selectedPlan: PlanQuery | null;
  existingPlans: AddPlanToCustomerDataQuery["CustomerPlan"];
  customerName: string;
  creditTypes: CreditType[];
  lastFinalizedInvoiceDate?: Date;
}

export const planLengthIsDone = (plan: CustomerPlanInfo) => {
  return !!(
    plan.planDatesError === null &&
    (plan.trialSpec === null ||
      (plan.trialSpec &&
        plan.trialSpec.length !== undefined &&
        plan.trialSpec.length >= 0 &&
        (plan.trialSpec.caps ?? []).every(
          (cap) => cap.creditTypeId && cap.amount,
        )))
  );
};

export const SetPlanLength: React.FC<SetPlanLengthProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();
  const selectedPlan = props.selectedPlan?.Plan_by_pk;
  useEffect(() => {
    if (!selectedPlan) {
      return;
    }
    if (customerPlan.trialSpec === undefined) {
      const existingTrial = props.selectedPlan?.Plan_by_pk?.TrialSpec;
      setCustomerPlan({
        ...customerPlan,
        trialSpec: existingTrial
          ? {
              length: existingTrial.length_in_days
                ? Number(existingTrial.length_in_days)
                : undefined,
              caps: existingTrial.TrialSpecSpendingCaps.map((c) => ({
                amount: new Decimal(c.amount),
                creditTypeId: c.CreditType.id,
              })),
            }
          : null,
      });
    }
  }, [!!selectedPlan]);
  if (!selectedPlan) {
    return <BlockSkeleton />;
  }

  const uniqueStartPeriods = new Set<number>();
  const usedCreditTypeIds = new Set<string>();

  for (const minimum of selectedPlan.Minimums) {
    uniqueStartPeriods.add(Number(minimum.start_period));
    usedCreditTypeIds.add(minimum.CreditType.id);
  }
  for (const pricedProduct of selectedPlan.PricedProducts) {
    usedCreditTypeIds.add(pricedProduct.CreditType.id);
    for (const pricedProductPricingFactor of pricedProduct.PricedProductPricingFactors) {
      uniqueStartPeriods.add(Number(pricedProductPricingFactor.start_period));
    }
  }

  for (const conversion of selectedPlan.CreditTypeConversions) {
    usedCreditTypeIds.add(conversion.CustomCreditType.id);
    usedCreditTypeIds.add(conversion.FiatCreditType.id);
  }

  const rampStarts = Array.from(uniqueStartPeriods).sort((a, b) =>
    a < b ? -1 : 1,
  );

  const hasFixedTiers = selectedPlan.PricedProducts.some((pp) => {
    return pp.PricedProductPricingFactors.some((pppf) => {
      return pppf.FlatFees.length > 1;
    });
  });

  return (
    <DeprecatedWizardSplitPage
      preview={
        customerPlan.planDatesError === null && (
          <TermPreview
            planName={selectedPlan.name}
            planDescription={selectedPlan.description}
            hasCustomer={true}
            customerName={props.customerName}
            startDate={customerPlan.planStartDate}
            endDate={customerPlan.planEndDate}
            trialLength={customerPlan.trialSpec?.length}
            draft={
              customerPlan.planStartDate === undefined ||
              customerPlan.planEndDate === undefined
            }
            billingFrequency={selectedPlan.billing_frequency}
            billingAnchor={selectedPlan.service_period_start_type}
            ramps={rampStarts.map((rampStart) => ({ rampStart, temp: false }))}
          />
        )
      }
    >
      <DeprecatedInputSection
        header="Plan dates"
        subtitle="Determine the start and end dates for this plan."
      >
        <DeprecatedPlanLengthInput
          pricedProducts={selectedPlan.PricedProducts.map((pp) => {
            return {
              PricedProductPricingFactors: pp.PricedProductPricingFactors.map(
                (pppf) => {
                  return {
                    startPeriod: Number(pppf.start_period),
                    productPricingFactor: {
                      id: pppf.ProductPricingFactor.id,
                      name: pppf.ProductPricingFactor.name,
                    },
                    flatFee: pppf.FlatFees?.length
                      ? {
                          collectionSchedule:
                            pppf.FlatFees[0].collection_schedule,
                          collectionInterval:
                            pppf.FlatFees[0].collection_interval,
                          isProrated: !!pppf.FlatFees[0].is_prorated,
                        }
                      : null,
                  };
                },
              ),
            };
          })}
          existingPlansForCustomer={props.existingPlans}
          onChange={(v) =>
            setCustomerPlan({
              ...customerPlan,
              planStartDate: v.startDate,
              planEndDate: v.endDate,
              planDatesError: v.error,
            })
          }
          planBillingFrequency={selectedPlan.billing_frequency}
          planServicePeriodStartType={selectedPlan.service_period_start_type}
          planDefaultLength={selectedPlan.default_length_months ?? undefined}
          lastFinalizedInvoiceDate={props.lastFinalizedInvoiceDate}
          value={{
            startDate: customerPlan.planStartDate,
            endDate: customerPlan.planEndDate,
            error: customerPlan.planDatesError,
          }}
        />
      </DeprecatedInputSection>
      {!hasFixedTiers && (
        <DeprecatedInputSection
          header="Apply price adjustments"
          subtitle="Modify pricing to accomodate deals like discounts and negotiated rates. Price adjustments will apply to the life of a plan or a ramp. While the new rates will be reflected on the invoice, the adjustment from the list price will not be visible. When you enable price adjustments for a customer on a plan, you may not edit ramps, tiers or advance charge settings for the duration of the plan. Contact your Metronome representative if you have questions."
        >
          <Toggle
            checked={!!customerPlan.customPricing}
            label="Apply price adjustments"
            onChange={(checked) => {
              setCustomerPlan({
                ...customerPlan,
                customPricing: checked
                  ? {
                      pricedProductPricingFactorAdjustments: [],
                    }
                  : undefined,
              });
            }}
          />
        </DeprecatedInputSection>
      )}
      <DeprecatedTrialSpecInputSection
        creditTypes={props.creditTypes.filter(
          (ct) =>
            usedCreditTypeIds.has(ct.id) ||
            customerPlan.trialSpec?.caps?.some(
              (cap) => ct.id === cap.creditTypeId,
            ),
        )}
        showTrialToggle={true}
        disabled={customerPlan.trialSpec === null}
        trialSpec={customerPlan.trialSpec}
        setTrialSpec={(trialSpec) =>
          setCustomerPlan({ ...customerPlan, trialSpec: trialSpec })
        }
        conversions={selectedPlan.CreditTypeConversions.filter(
          (conversion) => conversion.start_period === "0",
        ).map((conversion) => ({
          customCreditType: conversion.CustomCreditType as CustomCreditType,
          fiatCreditType: conversion.FiatCreditType as FiatCreditType,
          startPeriod: 0,
          toFiatConversionFactor: Number(conversion.to_fiat_conversion_factor),
        }))}
      />
    </DeprecatedWizardSplitPage>
  );
};

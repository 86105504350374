// This hook is a thin wrapper around the useHistory hook from react-router-dom.
// It directly follows the same API of the useNavigate hook from react-router v6 but
// contains logic to handle sandbox.

import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  useNavigate as useNavigate_RR,
  NavigateOptions as NavigateOptions_RR,
} from "react-router-dom";

export interface NavigateOptions<State extends any = unknown>
  extends NavigateOptions_RR {
  state?: State;
}

export function useNavigate<State extends any>() {
  const navigate_RR = useNavigate_RR();
  const { prefixUrl } = useEnvironment();

  function navigate(delta: number): void;
  function navigate(routePath: string, options?: NavigateOptions<State>): void;
  function navigate(
    routePathOrDelta: string | number,
    options: NavigateOptions<State> = {},
  ) {
    if (typeof routePathOrDelta === "number") {
      navigate_RR(routePathOrDelta);
    } else {
      navigate_RR(prefixUrl(routePathOrDelta), options);
    }
  }

  return navigate;
}

import { useCreditGrants } from "hooks/useCreditGrants";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { renderDateRange } from "lib/time";
import { IssuedCreditGrant } from "app/pages/deprecated/Customer/tabs/Credits";
import {
  grantHasExpired,
  grantIsPending,
  grantIsVoid,
} from "app/pages/deprecated/Customer/tabs/Credits/components/CreditGrantList";
import React, { useMemo, useState } from "react";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { Icon } from "components/Icon";
import { ProgressBar, ProgressBarProps } from "components/ProgressBar";
import { Tooltip } from "components/Tooltip";
import { capitalizeFirstLetter } from "app/lib/utils";
import { renderCurrency } from "./CommitsAndCredits";
import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { type Dayjs, dayjs, useNow } from "lib/date";
import { Badge } from "components/Badge";

type CreditsProps = {
  customerId: string;
};

type BadgeInfo = {
  label: string;
  theme: "error" | "warning" | "gray";
};

type Grants = Pick<
  ProgressBarProps,
  "presentationTotal" | "total" | "values"
> & {
  tooltipContent: string;
  linkTo: {
    link: string;
    label: string;
  };
  badge?: BadgeInfo;
};

type FilterOptions = "all" | "active" | "expired" | "pending" | "voided";
const FilterOptions = {
  ALL: "all" as FilterOptions,
  ACTIVE: "active" as FilterOptions,
  EXPIRED: "expired" as FilterOptions,
  PENDING: "pending" as FilterOptions,
  VOIDED: "voided" as FilterOptions,
};
const filterOptionsArray: FilterOptions[] = [
  FilterOptions.ALL,
  FilterOptions.ACTIVE,
  FilterOptions.EXPIRED,
  FilterOptions.PENDING,
  FilterOptions.VOIDED,
];

const grantIsActive = (creditGrant: IssuedCreditGrant, now: Dayjs) => {
  return (
    dayjs(creditGrant.expiresBefore) > now &&
    dayjs(creditGrant.effectiveAt) <= now &&
    creditGrant.voidedAt === null
  );
};

type CreditGrantState = "active" | "expired" | "pending" | "voided" | undefined;

type CreditGrantInfo = IssuedCreditGrant & {
  state: CreditGrantState;
};

function getCreditGrantWithState(
  creditGrant: IssuedCreditGrant,
  now: Dayjs,
): CreditGrantInfo {
  if (grantIsVoid(creditGrant)) {
    return { ...creditGrant, state: "voided" };
  }
  if (grantHasExpired(creditGrant)) {
    return { ...creditGrant, state: "expired" };
  }
  if (grantIsActive(creditGrant, now)) {
    return { ...creditGrant, state: "active" };
  }
  if (grantIsPending(creditGrant)) {
    return { ...creditGrant, state: "pending" };
  }
  return { ...creditGrant, state: undefined };
}

function getBadgeInfo(state: CreditGrantState): BadgeInfo | undefined {
  switch (state) {
    case "expired":
      return { label: "Expired", theme: "error" };
    case "pending":
      return { label: "Pending", theme: "gray" };
    case "voided":
      return { label: "Voided", theme: "warning" };
    case "active":
    default:
      return undefined;
  }
}

// FOR PLANS-ONLY
export const Credits: React.FC<CreditsProps> = ({ customerId }) => {
  const now = useNow();
  const { data, loading, error } = useCreditGrants({
    customerId: customerId,
  });
  const { creditGrantsById } = data;
  const creditGrants = Object.values(creditGrantsById);
  const [filter, setFilter] = useState<FilterOptions[]>([
    FilterOptions.ACTIVE,
    FilterOptions.PENDING,
  ]);

  const allCredits = useMemo(() => {
    if (data && !loading) {
      const grantsWithState = creditGrants.map((cg) =>
        getCreditGrantWithState(cg, now),
      );

      let filteredCredits: CreditGrantInfo[];
      if (filter[0] === FilterOptions.ALL) {
        filteredCredits = grantsWithState;
      } else {
        filteredCredits = grantsWithState.filter(
          (cg) =>
            (filter.includes(FilterOptions.ACTIVE) && cg.state === "active") ||
            (filter.includes(FilterOptions.EXPIRED) &&
              cg.state === "expired") ||
            (filter.includes(FilterOptions.PENDING) &&
              cg.state === "pending") ||
            (filter.includes(FilterOptions.VOIDED) && cg.state === "voided"),
        );
      }

      const grantsForProgressBar: Grants[] = [];
      filteredCredits.forEach((cg) => {
        const amountConsumed = cg.ledger.consumed;
        const amountGranted = cg.ledger.totalGranted;
        const creditType = cg.ledger.creditType;

        grantsForProgressBar.push({
          tooltipContent: renderDateRange(
            cg.effectiveAt,
            cg.expiresBefore,
            {
              isUtc: true,
              excludeUtcLabel: true,
            },
            false,
          ),
          linkTo: {
            label: cg.name,
            link: `/customers/${customerId}/credits?view=grants`,
          },
          total: amountGranted.toNumber(),
          presentationTotal: renderCurrency(amountGranted, creditType),
          values: {
            label: "Consumed",
            value: amountConsumed.toNumber(),
            presentationValue: renderCurrency(amountConsumed, creditType),
          },
          badge: getBadgeInfo(cg.state),
        });
      });
      return grantsForProgressBar;
    }
    return [];
  }, [data, loading, filter]);

  const renderFilter = () => (
    <Dropdown
      key="credit grant dropdown"
      icon="filterLines"
      hideChevron={true}
      label="Status"
      children={filterOptionsArray.map((o, idx) => (
        <DropdownItem
          key={`${idx}-${o}`}
          label={capitalizeFirstLetter(o)}
          value={o}
          selected={filter.includes(o)}
          onClick={(meta) => {
            const val = meta.value as FilterOptions;
            if (filter.includes(val)) {
              setFilter(filter.filter((f) => f !== val));
            } else if (val === FilterOptions.ALL) {
              setFilter([FilterOptions.ALL]);
            } else {
              setFilter([
                ...filter.filter((f) => f !== FilterOptions.ALL),
                val,
              ]);
            }
          }}
          isCheckbox={true}
        />
      ))}
    />
  );

  if (!loading && data && creditGrants.length === 0) {
    return null;
  }

  if (error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading this customer's credit grants"
        error={error}
      />
    );
  }

  return (
    <Card
      title="Credits"
      loading={loading}
      headerActions={[
        <Button
          text="Credits"
          leadingIcon="arrowNarrowUpRight"
          theme="secondary"
          linkTo={`/customers/${customerId}/credits?view=grants`}
        />,
        renderFilter(),
      ]}
    >
      {allCredits.length === 0 ? (
        <div className="my-3xl flex w-full flex-col items-center text-gray-600">
          <h3 className="text-md font-semibold text-black">No credits</h3>
          {filter.length > 0 && (
            <div>This customer has no credits for the given filter</div>
          )}
        </div>
      ) : (
        <div className="flex max-h-[280px] flex-col gap-y-xl overflow-y-auto overflow-x-hidden">
          {allCredits.map((credit, idx) => (
            <ProgressBar
              key={idx}
              total={credit.total}
              presentationTotal={credit.presentationTotal}
              headerContent={[
                <div className="flex items-center gap-x-xs">
                  <DeprecatedInternalLink
                    routePath={credit.linkTo.link}
                    className="flex items-center"
                  >
                    <Icon
                      icon="arrowNarrowUpRight"
                      size={20}
                      className="mr-xs"
                    />
                    <h4 className="text-sm font-medium text-gray-500">
                      {credit.linkTo.label}
                    </h4>
                  </DeprecatedInternalLink>
                  <Tooltip
                    label="Effective date"
                    subLabel={credit.tooltipContent}
                  >
                    <Icon icon="infoCircle" size={15} />
                  </Tooltip>
                  {credit.badge && (
                    <Badge
                      label={credit.badge.label}
                      theme={credit.badge.theme}
                      size="sm"
                    />
                  )}
                </div>,
              ]}
              values={credit.values}
            />
          ))}
        </div>
      )}
    </Card>
  );
};

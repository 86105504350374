import React, { ReactNode } from "react";
import styles from "./index.module.less";
import { DeprecatedInputSection } from "components/deprecated/Wizard/components/InputSection";
import { motion } from "framer-motion";

interface WizardSplitPageProps extends React.PropsWithChildren {
  preview?: ReactNode;
}

const WizardAnimation: React.FC<React.PropsWithChildren> = ({ children }) => (
  <motion.div
    initial={{ opacity: 0.9, x: "100px" }}
    animate={{ opacity: 1, x: "0" }}
    transition={{ duration: 0.3, type: "tween" }}
  >
    {children}
  </motion.div>
);

export const DeprecatedWizardSplitPage: React.FC<WizardSplitPageProps> = (
  props,
) => {
  if (props.preview) {
    return (
      <WizardAnimation>
        <div className={styles.leftPane}>{props.children}</div>
        <div className={styles.divider} />{" "}
        {/** The divider element lets us have a border that's shorter than the pane height */}
        <div className={styles.rightPane}>{props.preview}</div>
      </WizardAnimation>
    );
  }
  return (
    <WizardAnimation>
      <div className={styles.singlePane}>{props.children}</div>
    </WizardAnimation>
  );
};

interface WizardFullPageProps extends React.PropsWithChildren {
  pageHeader?: {
    title: string;
    subtitle: string;
  };
}
export const DeprecatedWizardFullPage: React.FC<WizardFullPageProps> = (
  props,
) => {
  return (
    <WizardAnimation>
      <div className={styles.fullscreen}>
        {props.pageHeader && (
          <DeprecatedInputSection
            header={props.pageHeader.title}
            subtitle={props.pageHeader.subtitle}
            className={styles.pageHeader}
          />
        )}
        {props.children}
      </div>
    </WizardAnimation>
  );
};

import { Select, Input } from "design-system";
import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import { CustomField } from "app/pages/Connections/tabs/CustomFields/index";
import {
  useSetManagedFieldOnAlertMutation,
  useSetManagedFieldOnChargeMutation,
  useSetManagedFieldOnCommitMutation,
  useSetManagedFieldOnContractCreditMutation,
  useSetManagedFieldOnContractMutation,
  useSetManagedFieldOnContractProductMutation,
  useSetManagedFieldOnCreditGrantMutation,
  useSetManagedFieldOnCustomerMutation,
  useSetManagedFieldOnCustomerPlanMutation,
  useSetManagedFieldOnInvoiceMutation,
  useSetManagedFieldOnPlanMutation,
  useSetManagedFieldOnProductMutation,
  useSetManagedFieldOnProServiceMutation,
  useSetManagedFieldOnRateCardMutation,
  useSetManagedFieldOnScheduledChargeMutation,
  useSetManagedFieldOnBillableMetricMutation,
} from "app/pages/Connections/tabs/CustomFields/customFields.graphql";
import { MAX_CUSTOM_FIELD_VALUE_LENGTH } from "app/pages/Connections/tabs/CustomFields/components/CreateOrEditCustomFieldModal";
import React from "react";
import { useState } from "react";

interface SetCustomFieldModalProps {
  entity: string;
  id: string;
  onClose: () => void;
  isOpen: boolean;
  customFields: CustomField[];
}
export const SetCustomFieldModal: React.FC<SetCustomFieldModalProps> = ({
  onClose,
  entity,
  isOpen,
  customFields,
  id,
}) => {
  const [setCustomerCustomField, { loading: customerLoading }] =
    useSetManagedFieldOnCustomerMutation();
  const [setChargeCustomField, { loading: chargeLoading }] =
    useSetManagedFieldOnChargeMutation();
  const [setProductCustomField, { loading: productLoading }] =
    useSetManagedFieldOnProductMutation();
  const [setCreditGrantCustomField, { loading: creditGrantLoading }] =
    useSetManagedFieldOnCreditGrantMutation();
  const [setCustomerPlanCustomField, { loading: customerPlanLoading }] =
    useSetManagedFieldOnCustomerPlanMutation();
  const [setPlanCustomField, { loading: planLoading }] =
    useSetManagedFieldOnPlanMutation();
  const [setCommitCustomField, { loading: commitLoading }] =
    useSetManagedFieldOnCommitMutation();
  const [setContractCustomField, { loading: contractLoading }] =
    useSetManagedFieldOnContractMutation();
  const [setContractProductCustomField, { loading: contractProductLoading }] =
    useSetManagedFieldOnContractProductMutation();
  const [setRateCardCustomField, { loading: rateCardLoading }] =
    useSetManagedFieldOnRateCardMutation();
  const [setAlertCustomField, { loading: alertLoading }] =
    useSetManagedFieldOnAlertMutation();
  const [setInvoiceCustomField, { loading: invoiceLoading }] =
    useSetManagedFieldOnInvoiceMutation();
  const [setContractCreditCustomField, { loading: contractCreditLoading }] =
    useSetManagedFieldOnContractCreditMutation();
  const [setScheduledChargeCustomField, { loading: scheduledChargeLoading }] =
    useSetManagedFieldOnScheduledChargeMutation();
  const [setProServiceCustomField, { loading: proServiceLoading }] =
    useSetManagedFieldOnProServiceMutation();
  const [setBillableMetricCustomField, { loading: billableMetricLoading }] =
    useSetManagedFieldOnBillableMetricMutation();

  const [customFieldKey, setCustomFieldKey] = useState<
    | {
        id: string;
        value: string;
      }
    | undefined
  >(undefined);
  const [customFieldValue, setCustomFieldValue] = useState<string>("");
  const loading =
    customerLoading ||
    chargeLoading ||
    productLoading ||
    creditGrantLoading ||
    customerPlanLoading ||
    planLoading ||
    commitLoading ||
    contractLoading ||
    contractProductLoading ||
    rateCardLoading ||
    alertLoading ||
    invoiceLoading ||
    contractCreditLoading ||
    scheduledChargeLoading ||
    proServiceLoading ||
    billableMetricLoading;
  const pushMessage = useSnackbar();
  const disableSave =
    !customFieldKey ||
    !customFieldValue ||
    !!validateFieldValue(customFieldValue) ||
    loading;
  const closeModal = () => {
    setCustomFieldValue("");
    setCustomFieldKey(undefined);
    onClose();
  };

  const actionButtons = (
    <>
      <Button onClick={closeModal} text="Cancel" theme="linkGray" />
      <Button
        onClick={async () => {
          if (!customFieldValue || !customFieldKey) {
            throw new Error("Invalid custom field entry");
          }
          try {
            switch (entity) {
              case "customer":
                await setCustomerCustomField({
                  variables: {
                    customer_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "charge":
                await setChargeCustomField({
                  variables: {
                    ppf_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "product":
                await setProductCustomField({
                  variables: {
                    product_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "credit-grant":
                await setCreditGrantCustomField({
                  variables: {
                    credit_grant_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "customer-plan":
                await setCustomerPlanCustomField({
                  variables: {
                    customer_plan_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "plan":
                await setPlanCustomField({
                  variables: {
                    plan_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "commit":
                await setCommitCustomField({
                  variables: {
                    commit_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "contract":
                await setContractCustomField({
                  variables: {
                    contract_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "contract-product":
                await setContractProductCustomField({
                  variables: {
                    contract_product_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "rate-card":
                await setRateCardCustomField({
                  variables: {
                    rate_card_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "alert":
                await setAlertCustomField({
                  variables: {
                    alert_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "invoice":
                await setInvoiceCustomField({
                  variables: {
                    invoice_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "contract-credit":
                await setContractCreditCustomField({
                  variables: {
                    contract_credit_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "scheduled-charge":
                await setScheduledChargeCustomField({
                  variables: {
                    scheduled_charge_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "professional-service":
                await setProServiceCustomField({
                  variables: {
                    pro_service_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              case "billable-metric":
                await setBillableMetricCustomField({
                  variables: {
                    billable_metric_id: id,
                    key_id: customFieldKey.id,
                    value: customFieldValue,
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "ManagedFieldKey",
                    });
                  },
                });
                break;
              default:
                throw new Error(`Entity not supported: ${entity}`);
            }
          } catch (error) {
            const isDuplicateKeyError = getUserFacingErrorMessage(
              error,
            ).includes("Uniqueness violation");
            if (isDuplicateKeyError) {
              pushMessage({
                content:
                  "Custom field key is unique and this value already exists",
                type: "error",
                durationMS: 3000,
              });
            }
            return;
          }

          pushMessage({
            content: "Custom field value saved",
            type: "success",
            durationMS: 3000,
          });
          closeModal();
        }}
        loading={loading}
        disabled={disableSave}
        text="Save"
        theme="primary"
      />
    </>
  );

  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen={isOpen}
      onRequestClose={closeModal}
      title="Set custom field value"
      className="!w-[500px]"
    >
      <div className="flex flex-row py-12">
        <Select
          name="Field name (key)"
          placeholder="x__external_id"
          options={customFields.map((customField) => {
            return {
              label: customField.key.value,
              value: customField.key.id,
            };
          })}
          value={customFieldKey?.id ?? ""}
          onChange={(v) => {
            const customField = customFields.find((cf) => cf.key.id === v);
            if (customField) {
              setCustomFieldKey({
                id: customField.key.id,
                value: customField.key.value,
              });
              setCustomFieldValue(customField.value?.value ?? "");
            }
          }}
          className="w-[200px]"
        />
        <Input
          className="mt-2 mx-0 mb-4 w-[250px] p-0 px-12"
          placeholder="f7abd0cd-4a10-4db7-8636"
          value={customFieldValue}
          onChange={setCustomFieldValue}
          name="Field value"
          disabled={!customFieldKey}
          error={validateFieldValue(customFieldValue)}
        />
      </div>
    </DeprecatedPopup>
  );
};

function validateFieldValue(value: string): string | boolean {
  if (value.length > MAX_CUSTOM_FIELD_VALUE_LENGTH) {
    return `Field value cannot be longer than ${MAX_CUSTOM_FIELD_VALUE_LENGTH} characters`;
  }

  return false;
}

import React from "react";
import { FormReader } from "app/lib/FormController";

export interface Snapshot {
  initialJson: string | null;
  update: (ctrl: FormReader<any>) => void;
  clear: () => void;
}

export function useSnapshot(key: string): Snapshot {
  return React.useMemo((): Snapshot => {
    const initialJson = sessionStorage.getItem(key);

    // save snapshot on every change of the form
    function update(ctrl: FormReader<any>) {
      sessionStorage.setItem(key, JSON.stringify(ctrl.snapshot()));
    }

    function clear() {
      sessionStorage.removeItem(key);
    }

    return { initialJson, update, clear };
  }, [key]);
}

import { Select } from "design-system";
import { Button } from "components/Button";
import { displayCreditTypeName, USD_CREDIT_ID } from "app/lib/credits";
import React, { ReactElement, useState } from "react";
import DeprecatedNewPricingUnitModal from "./NewPricingUnitModal";
import { useCreditTypesQuery } from "./queries.graphql";
import { CreditType } from "app/types/credit-types";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import classnames from "classnames";
import { reportToSentry } from "app/lib/errors/sentry";

interface PricingUnitSelectorProps {
  onChange: (ct: CreditType) => void;
  selectedCreditTypeId?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  allowCreation?: boolean;
  allowedCreditTypeIds?: Set<string>;
  fiatOnly?: boolean;
  className?: string;
  showName?: boolean;
  placeholder?: string;
}

export const DeprecatedPricingUnitSelector: React.FC<
  PricingUnitSelectorProps
> = ({
  onChange,
  selectedCreditTypeId,
  disabled,
  autoFocus,
  allowCreation,
  allowedCreditTypeIds,
  fiatOnly,
  className,
  showName,
  placeholder,
}) => {
  const { environmentType } = useEnvironment();
  const { data, loading } = useCreditTypesQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [newPricingUnitModalActive, setNewPricingUnitModalActive] =
    useState(false);

  if (allowCreation && fiatOnly) {
    reportToSentry(
      new Error(
        "PricingUnitSelector should not have both allowCreation and fiatOnly",
      ),
    );
  }

  const creditTypes = [...(data?.CreditType ?? [])]
    .filter((c) => !fiatOnly || c.client_id === null)
    .sort((a, b) =>
      a.id === USD_CREDIT_ID
        ? -1
        : b.id === USD_CREDIT_ID
          ? 1
          : (a.name ?? "--").localeCompare(b.name ?? "--"),
    );

  return (
    <>
      <Select
        name={showName === false ? "" : "Pricing unit"}
        className={classnames("w-full", className, {
          ["min-w-[200px]"]: allowCreation,
        })}
        onChange={(v) => {
          const ct = creditTypes.find((ct) => ct.id === v);
          if (ct) {
            onChange(ct);
          }
        }}
        options={
          loading
            ? []
            : [
                {
                  label: "currency",
                  options: creditTypes
                    .filter((creditType) => creditType.client_id === null)
                    .map((creditType) => ({
                      label: displayCreditTypeName(creditType),
                      value: creditType.id,
                    })),
                },
                {
                  label: "custom pricing unit",
                  options: creditTypes
                    .filter((creditType) => creditType.client_id !== null)
                    .map((creditType) => ({
                      label: displayCreditTypeName(creditType) as
                        | string
                        | ReactElement,
                      value: creditType.id,
                      disabled:
                        allowedCreditTypeIds &&
                        !allowedCreditTypeIds.has(creditType.id),
                    }))
                    .concat(
                      allowCreation
                        ? [
                            {
                              disabled: false,
                              value: "new",
                              label: (
                                <Button
                                  className="w-full"
                                  onClick={() => {
                                    setNewPricingUnitModalActive(true);
                                  }}
                                  text="New pricing unit"
                                  theme="primary"
                                  leadingIcon="plus"
                                />
                              ),
                            },
                          ]
                        : [],
                    ),
                },
              ]
        }
        value={selectedCreditTypeId ?? ""}
        placeholder={placeholder ?? "Select"}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {newPricingUnitModalActive ? (
        <DeprecatedNewPricingUnitModal
          onClose={(ct) => {
            setNewPricingUnitModalActive(false);
            if (ct) {
              onChange(ct);
            }
          }}
          creditTypes={creditTypes}
        />
      ) : null}
    </>
  );
};

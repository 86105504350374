/* f6d5123c069a9d7bf81169bc14b24c269378b382
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerListQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  order_by: Array<Types.Customer_Order_By> | Types.Customer_Order_By;
  filter: Types.Customer_Bool_Exp;
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
}>;


export type CustomerListQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number, estimated: boolean } }>, Customer_aggregate: { __typename?: 'Customer_aggregate', aggregate: { __typename?: 'Customer_aggregate_fields', count: number } | null }, Customer: Array<{ __typename?: 'Customer', id: string }> };

export type SearchCustomersQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  query: Types.Scalars['String'];
}>;


export type SearchCustomersQuery = { __typename?: 'Query', anyCustomer: Array<{ __typename?: 'Customer', id: string }>, searchCustomers: Array<{ __typename?: 'Customer', id: string }> };

export type CustomerOverviewFieldsFragment = { __typename?: 'Customer', id: string, name: string, archived_at: string | null, created_at: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }>, CustomerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, value: string }>, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider: Types.BillingProviderEnum_Enum, billing_provider_customer_id: string }>, currentPlan: Array<{ __typename?: 'CustomerPlan', id: string, cancellation_date: string | null }>, latestPastPlan: Array<{ __typename?: 'CustomerPlan', id: string, cancellation_date: string | null }> };

export type CustomerOverviewDetailsQueryVariables = Types.Exact<{
  customer_ids: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  date?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  order_by: Array<Types.Customer_Order_By> | Types.Customer_Order_By;
}>;


export type CustomerOverviewDetailsQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, name: string, archived_at: string | null, created_at: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }>, CustomerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, value: string }>, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider: Types.BillingProviderEnum_Enum, billing_provider_customer_id: string }>, currentPlan: Array<{ __typename?: 'CustomerPlan', id: string, cancellation_date: string | null }>, latestPastPlan: Array<{ __typename?: 'CustomerPlan', id: string, cancellation_date: string | null }> }> };

export const CustomerOverviewFieldsFragmentDoc = gql`
    fragment CustomerOverviewFields on Customer {
  id
  name
  archived_at
  created_at
  CustomerIngestAliases {
    ingest_alias
    __environment_type: environment_type
  }
  CustomerConfigs(where: {key: {_eq: "stripe_collection_method"}}) {
    id
    value
  }
  BillingProviderCustomers(
    where: {billing_provider: {_in: [STRIPE, AWS_MARKETPLACE]}}
  ) {
    billing_provider
    billing_provider_customer_id
  }
  currentPlan: CustomerPlans(
    where: {deleted_at: {_is_null: true}, start_date: {_lte: $date}, _or: [{cancellation_date: {_is_null: true}}, {cancellation_date: {_gte: $date}}]}
  ) {
    id
    cancellation_date
  }
  latestPastPlan: CustomerPlans(
    where: {deleted_at: {_is_null: true}, cancellation_date: {_lt: $date}}
    limit: 1
    order_by: {cancellation_date: desc}
  ) {
    id
    cancellation_date
  }
  __environment_type: environment_type
}
    `;
export const CustomerListDocument = gql`
    query CustomerList($environment_type: EnvironmentTypeEnum_enum!, $order_by: [Customer_order_by!]!, $filter: Customer_bool_exp!, $offset: Int!, $limit: Int!) {
  Client {
    id
    customer_count(environment_type: $environment_type) {
      count
      estimated
    }
  }
  Customer_aggregate(where: $filter) {
    aggregate {
      count
    }
  }
  Customer(limit: $limit, offset: $offset, order_by: $order_by, where: $filter) {
    id
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerListQuery__
 *
 * To run a query within a React component, call `useCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerListQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      order_by: // value for 'order_by'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCustomerListQuery(baseOptions: Apollo.QueryHookOptions<CustomerListQuery, CustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerListQuery, CustomerListQueryVariables>(CustomerListDocument, options);
      }
export function useCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerListQuery, CustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerListQuery, CustomerListQueryVariables>(CustomerListDocument, options);
        }
export type CustomerListQueryHookResult = ReturnType<typeof useCustomerListQuery>;
export type CustomerListLazyQueryHookResult = ReturnType<typeof useCustomerListLazyQuery>;
export type CustomerListQueryResult = Apollo.QueryResult<CustomerListQuery, CustomerListQueryVariables>;
export const SearchCustomersDocument = gql`
    query SearchCustomers($environment_type: EnvironmentTypeEnum_enum!, $query: String!) {
  anyCustomer: Customer(
    where: {environment_type: {_eq: $environment_type}}
    limit: 1
  ) {
    id
    __environment_type: environment_type
  }
  searchCustomers(environment_type: $environment_type, query: $query) {
    id
    __environment_type: environment_type
  }
}
    `;

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchCustomersQuery(baseOptions: Apollo.QueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
      }
export function useSearchCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
        }
export type SearchCustomersQueryHookResult = ReturnType<typeof useSearchCustomersQuery>;
export type SearchCustomersLazyQueryHookResult = ReturnType<typeof useSearchCustomersLazyQuery>;
export type SearchCustomersQueryResult = Apollo.QueryResult<SearchCustomersQuery, SearchCustomersQueryVariables>;
export const CustomerOverviewDetailsDocument = gql`
    query CustomerOverviewDetails($customer_ids: [uuid!]!, $date: timestamptz, $order_by: [Customer_order_by!]!) {
  Customer(where: {id: {_in: $customer_ids}}, order_by: $order_by) {
    id
    name
    ...CustomerOverviewFields
    __environment_type: environment_type
  }
}
    ${CustomerOverviewFieldsFragmentDoc}`;

/**
 * __useCustomerOverviewDetailsQuery__
 *
 * To run a query within a React component, call `useCustomerOverviewDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOverviewDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOverviewDetailsQuery({
 *   variables: {
 *      customer_ids: // value for 'customer_ids'
 *      date: // value for 'date'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useCustomerOverviewDetailsQuery(baseOptions: Apollo.QueryHookOptions<CustomerOverviewDetailsQuery, CustomerOverviewDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerOverviewDetailsQuery, CustomerOverviewDetailsQueryVariables>(CustomerOverviewDetailsDocument, options);
      }
export function useCustomerOverviewDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerOverviewDetailsQuery, CustomerOverviewDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerOverviewDetailsQuery, CustomerOverviewDetailsQueryVariables>(CustomerOverviewDetailsDocument, options);
        }
export type CustomerOverviewDetailsQueryHookResult = ReturnType<typeof useCustomerOverviewDetailsQuery>;
export type CustomerOverviewDetailsLazyQueryHookResult = ReturnType<typeof useCustomerOverviewDetailsLazyQuery>;
export type CustomerOverviewDetailsQueryResult = Apollo.QueryResult<CustomerOverviewDetailsQuery, CustomerOverviewDetailsQueryVariables>;
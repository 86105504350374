import React, { useState } from "react";
import { StripeSettingsToggleProps } from ".";
import { Toggle } from "components/Toggle";

export const SetEffectiveAtDateToggle: React.FC<StripeSettingsToggleProps> = ({
  isChecked,
  handleToggleOnChange,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <Toggle
      toggled={isCheckedValue}
      label="Align effective_at with last day of period"
      onChange={(meta: { toggled: boolean }) =>
        handleToggleOnChange(
          meta.toggled,
          "set_effective_at_date_to_inclusive_period_end",
          setIsCheckedValue,
        )
      }
      supportingText="Shows up as Invoice Date in Stripe PDF"
    />
  );
};

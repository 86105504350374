import React from "react";
import {
  components,
  OptionProps as ReactSelectOptionProps,
} from "react-select";

import { RelativeDateRanges } from "../../index";

type OptionTypeBase = {
  label: React.ReactNode | string;
  value: string;
};

type OptionProps<T extends OptionTypeBase> = ReactSelectOptionProps<T> & {
  setSelectedOption: (value: RelativeDateRanges | "custom") => void;
};

export function DeprecatedOption<T extends OptionTypeBase>(
  props: OptionProps<T>,
) {
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: (e) => {
          if (props.data.value === "custom") {
            props.setSelectedOption("custom");
            e.preventDefault();
            return;
          }
          props.selectOption(props.data);
        },
      }}
    />
  );
}

import React, { useState } from "react";
import { useGetCustomerSettingsInfoQuery } from "app/pages/deprecated/Customer/tabs/Settings/sections/Identifiers/queries.graphql";
import { startOfHour } from "date-fns";
import { Metadata } from "app/pages/Customer/tabs/Overview/Metadata";
import { Tooltip } from "design-system";
import ArchiveCustomerModal from "app/lib/customers/components/ArchiveCustomerModal";
import { Card } from "components/Card";
import { GatedButton } from "components/GatedButton";
import { ArchiveCustomerDocument } from "app/lib/customers/components/ArchiveCustomerModal/queries.graphql";

export const CustomerManagementSection: React.FC<{
  customerID: string;
}> = ({ customerID }) => {
  const [customerArchiveModalOpen, setCustomerArchiveModalOpen] =
    useState(false);

  const {
    data: customerSettingsInfoData,
    loading: customerSettingsInfoLoading,
  } = useGetCustomerSettingsInfoQuery({
    variables: {
      customer_id: customerID,
      date: startOfHour(new Date()).toISOString(),
    },
  });

  const canArchive =
    (customerSettingsInfoData?.Customer?.active_customer_plans.length || 0) ===
      0 && customerSettingsInfoData?.Customer?.archived_at === null;
  const archiveDisabledReason =
    customerSettingsInfoData?.Customer?.archived_at !== null
      ? "Customer already archived"
      : "Customers with plans cannot be archived";

  const showLoadingState =
    customerSettingsInfoLoading || !customerSettingsInfoData?.Customer;

  return (
    <div>
      {customerArchiveModalOpen && customerSettingsInfoData?.Customer && (
        <ArchiveCustomerModal
          customerId={customerSettingsInfoData.Customer.id}
          customerName={customerSettingsInfoData.Customer.name}
          onClose={() => {
            setCustomerArchiveModalOpen(false);
          }}
        />
      )}
      <Card
        wrapContents={false}
        title="Customer management"
        loading={showLoadingState}
        headerActions={[
          <Tooltip content={archiveDisabledReason} disabled={canArchive}>
            <GatedButton
              className="mr-12"
              disabled={!canArchive}
              onClick={() => {
                setCustomerArchiveModalOpen(true);
              }}
              leadingIcon="userX01"
              doc={ArchiveCustomerDocument}
              text="Archive customer"
              theme="linkDestructive"
            />
          </Tooltip>,
        ]}
      >
        <Metadata allowCustomerNameEditing hideIntegrations />
      </Card>
    </div>
  );
};

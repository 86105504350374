import React from "react";
import { Badge } from "design-system";
import pluralize from "pluralize";

import { Dayjs, toDayjs, printDate } from "lib/date";
import { PlanDetailsFragmentReader } from "./PlanDetailsFragmentReader";
import { ContractOrPlan } from "../ContractOrPlan";
import {
  CustomerPlanFragment,
  DetailedCustomerPlanFragment,
} from "./fragments.graphql";
import { CustomPricing, unserializeCustomPricing } from "app/lib/customPricing";
import { formatTrialSpec } from "components/deprecated/PlanPreview";

class CustomerPlanFragmentReader {
  public readonly id: string;
  public readonly startingAt: Dayjs;
  public readonly endingBefore?: Dayjs;
  public readonly planId: string;
  public readonly name: string;
  public readonly dateRange: {
    start: Dayjs;
    end: Dayjs | undefined;
  };

  public readonly netPaymentTermDays?: number;
  public readonly routePath: string;

  constructor(
    customerId: string,
    protected readonly fragment: CustomerPlanFragment,
  ) {
    this.id = fragment.id;
    this.startingAt = toDayjs(fragment.start_date);
    this.endingBefore = fragment.cancellation_date
      ? toDayjs(fragment.cancellation_date)
      : undefined;

    this.planId = fragment.Plan.id;
    this.name = fragment.Plan.name;
    this.routePath = `/customers/${customerId}/plans/${this.id}`;
    this.dateRange = {
      start: toDayjs(fragment.start_date),
      end: fragment.cancellation_date
        ? toDayjs(fragment.cancellation_date)
        : undefined,
    };
    this.netPaymentTermDays = fragment.net_payment_terms_days ?? undefined;
  }

  renderStartDate() {
    return printDate(this.startingAt);
  }

  renderExpiryDate() {
    return this.endingBefore ? printDate(this.endingBefore) : "--";
  }

  renderStatusPill(now: Dayjs): React.ReactNode {
    const status = ContractOrPlan.getStatus(this.fragment, now);
    switch (status) {
      case "upcoming":
      case "active-soon":
        return (
          <Badge theme="grey" type="dark">
            {status.toUpperCase()}
          </Badge>
        );
      case "active":
      case "ending-soon":
        return (
          <Badge theme="primary" type="dark">
            ACTIVE
          </Badge>
        );
      case "recently-ended":
      case "ended":
        return (
          <Badge theme="warning" type="dark">
            INACTIVE
          </Badge>
        );
    }
  }

  printNetPaymentTerms() {
    return this.netPaymentTermDays !== undefined
      ? pluralize("day", this.netPaymentTermDays, true)
      : undefined;
  }
}

export class DetailedCustomerPlanFragmentReader extends CustomerPlanFragmentReader {
  public readonly plan: PlanDetailsFragmentReader;
  public readonly customPricing?: CustomPricing;
  public readonly trialSpec?: DetailedCustomerPlanFragment["trial_spec"];
  public readonly creditTypeConversionAdjustments?: DetailedCustomerPlanFragment["CreditTypeConversionAdjustments"];

  constructor(
    customerId: string,
    public readonly fragment: DetailedCustomerPlanFragment,
  ) {
    super(customerId, fragment);
    this.customPricing = unserializeCustomPricing(
      fragment.CustomPricings.find((cp) => cp.plan_id === fragment.Plan.id),
    );
    this.plan = new PlanDetailsFragmentReader(
      fragment.Plan,
      this.customPricing,
    );
    this.trialSpec = fragment.trial_spec;
    this.creditTypeConversionAdjustments =
      fragment.CreditTypeConversionAdjustments;
  }

  printTrialInfo() {
    return this.trialSpec
      ? formatTrialSpec(this.trialSpec)
      : this.plan.printTrialInfo();
  }
}

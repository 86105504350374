import React from "react";
import styles from "./index.module.less";
import { Tooltip } from "design-system";

export const DeprecatedWarningDot: React.FC<{ message: string }> = ({
  message,
}) => {
  return (
    <Tooltip content={message}>
      <span className={styles.warningDot} />
    </Tooltip>
  );
};

import React from "react";

import styles from "./index.module.less";

import { Button } from "components/Button";

interface WizardFooterProps {
  onClickBack: () => void;
  backDisabled?: boolean;
  backLabel: string;

  onClickContinue: () => void;
  continueDisabled?: boolean;
  continueLabel: string;
  continueLoading?: boolean;
}

export const DeprecatedWizardFooter: React.FC<WizardFooterProps> = (props) => {
  return (
    <div className={styles.footer}>
      <Button
        className={styles.back}
        disabled={props.backDisabled}
        onClick={props.onClickBack}
        text={props.backLabel}
        theme="linkGray"
      />
      <Button
        disabled={props.continueDisabled}
        onClick={props.onClickContinue}
        loading={props.continueLoading}
        text={props.continueLabel}
        theme="primary"
      />
    </div>
  );
};

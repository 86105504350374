import React from "react";

import * as Apollo from "@apollo/client";
import { isGqlNotFoundError } from "app/lib/errors/errorHandling";

/**
 * A type which represents the possible states of an Apollo query result.
 */
export type ApolloResp<D> =
  | { state: "error"; error: Error }
  | { state: "not found" }
  | ({ state: "loading" } & { [key in keyof D]: undefined })
  | ({ state: "success" } & D);

export type ApolloSuccess<R extends ApolloResp<unknown>> = R extends {
  state: "success";
}
  ? R
  : never;

export function isLoaded<Req extends ApolloResp<unknown>>(
  req: Req,
): req is ApolloSuccess<Req> {
  return req.state === "success";
}

/**
 * Convert a standard Apollo QueryResult into a more useful type which
 * can be reduced with basic TS type narrowing. The returned type will
 * always have a `state` property with one of the following values:
 *
 * - `"error"`: The query failed with an error. The `error` property
 *   will contain the error.
 * - `"not found"`: Either the resolver responded with a NotFound error
 *   or the query returned no data for one of the `nonOptionalDataKeys`.
 * - `"loading"`: The query is still loading.
 * - `"success"`: The query succeeded and the data is available.
 *
 * When a query is in the `"success"` state, the returned object will
 * contain all the data from the query, plus the `state` property. Additionally,
 * the `nonOptionalDataKeys` will be checked for existence and made
 * non-nullable so that we can be confident that the data actually loaded
 * correctly.
 */
export function useApolloResp<D, K extends keyof D>(
  result: Apollo.QueryResult<D, any>,
): ApolloResp<D & { [key in K]: NonNullable<D[key]> }> {
  return React.useMemo(() => {
    if (result.loading) {
      return {
        state: "loading",
      };
    }

    if (result.error && !isGqlNotFoundError(result.error)) {
      return {
        state: "error",
        error: result.error,
      };
    }

    if (
      result.error ||
      !result.data ||
      Object.values(result.data).some((v) => v == null)
    ) {
      return {
        state: "not found",
      };
    }

    return {
      state: "success",
      ...result.data,
    };
  }, [result]);
}

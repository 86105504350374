import React from "react";
import { BillingSchedule } from "../../components/BillingSchedule";
import { DateInput, NumericInput, Select, Subtitle } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import {
  PrepaidCommitAccessScheduleController,
  CommitFlyoverController,
  PrepaidCommitTermsController,
  usePrepaidCommitAccessScheduleController,
  usePrepaidCommitAccessScheduleItemController,
  usePrepaidCommitTermsController,
} from ".";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "app/lib/credits";
import { v4 as uuid } from "uuid";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { DateSequence } from "../../lib/DateSequence";
import { ExternalCommitType } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { CreditType } from "app/types/credit-types";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";

const PrepaidCommitAccessScheduleItem: React.FC<{
  id: string;
  parent: PrepaidCommitAccessScheduleController;
  action: React.ReactNode;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePrepaidCommitAccessScheduleItemController(
    props.parent,
    props.id,
    timeframe,
  );

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "amount",
  );

  return (
    <>
      <DateInput
        {...ctrl.props.DateInput("date", {
          name: "Starting at",
        })}
      />
      <DateInput
        {...ctrl.props.DateInput("endDate", {
          name: "Ending before",
          minDate: ctrl.get("date"),
        })}
      />
      <div className="flex gap-12">
        <div className="w-full">
          <DeprecatedCreditInput
            {...ctrl.props.CreditInput("amount", {
              placeholder: "123.45",
              name: "Amount",
              creditType: props.creditType,
            })}
          />
        </div>
        {props.action}
      </div>
    </>
  );
};

const PrepaidCommitAccessSchedule: React.FC<{
  parent: PrepaidCommitTermsController;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  asCredit: boolean;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePrepaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = ctrl.get("accessSchedule") ?? [];
  const seq = DateSequence.create(
    accessSchedule.map((i) =>
      i.date && i.endDate ? [i.date, i.endDate] : i.date,
    ),
    timeframe,
  );
  const creditType = findCreditType(
    props.parent.get("accessScheduleCreditTypeId") ?? USD_CREDIT_TYPE.id,
    [...props.fiatCreditTypes, ...props.customCreditTypes],
  );
  return (
    <div className="flex flex-col gap-12">
      <Subtitle level={4}>Access schedule</Subtitle>
      <div className="grid grid-cols-3 gap-12">
        <Select
          name="Pricing unit"
          value={props.parent.get("accessScheduleCreditTypeId")}
          placeholder=""
          onChange={(value) => {
            props.parent.update({
              accessScheduleCreditTypeId: value,
            });
            // since credits don't have a billing schedule, but it is a required field,
            // we update it to the same credit_type as the accessSchedule
            if (props.asCredit) {
              props.parent.update({
                billingScheduleCreditTypeId: value,
              });
            }
          }}
          options={[
            {
              label: "Currency",
              options: props.fiatCreditTypes.map((ct) => ({
                label: displayCreditTypeName(ct),
                value: ct.id,
              })),
            },
            {
              label: "Custom pricing unit",
              options: props.customCreditTypes.map((ct) => ({
                label: displayCreditTypeName(ct),
                value: ct.id,
              })),
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-3 gap-12">
        {accessSchedule.map((item, i) => (
          <DateSequence.Provider seq={seq} index={i} key={item.id}>
            <PrepaidCommitAccessScheduleItem
              id={item.id}
              parent={ctrl}
              action={
                <IconButton
                  onClick={() => {
                    ctrl.update({
                      accessSchedule: accessSchedule.filter(
                        (i) => i.id !== item.id,
                      ),
                    });
                  }}
                  className="mt-[16px]"
                  disabled={accessSchedule.length === 1}
                  theme="tertiary"
                  icon="xCircle"
                />
              }
              creditType={creditType}
            />
          </DateSequence.Provider>
        ))}
      </div>
      <div>
        <Button
          onClick={() => {
            ctrl.update({
              accessSchedule: [
                ...accessSchedule,
                {
                  id: uuid(),
                },
              ],
            });
          }}
          text="Add another segment"
          theme="secondary"
          leadingIcon="plus"
        />
      </div>
    </div>
  );
};

export const PrepaidCommitTerms: React.FC<{
  parent: CommitFlyoverController;
  level: "customer" | "contract";
  asCredit: boolean;
  contracts?: { id: string; name?: string | null }[];
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  defaultCreditType: CreditType;
}> = (props) => {
  const showCPUWork = useFeatureFlag<boolean>("contract-cpus", false);
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const externalType = props.asCredit
    ? ExternalCommitType.Credit
    : ExternalCommitType.Commit;
  const ctrl = usePrepaidCommitTermsController(
    props.parent,
    props.level,
    externalType,
    showCPUWork ? props.defaultCreditType : USD_CREDIT_TYPE,
  );
  const disallowFreeCommits =
    !!nonGAContractFeaturesEnabled?.includes("BLOCK_FREE_COMMITS");
  return (
    <div className="flex flex-col gap-[32px]">
      <div>
        <PrepaidCommitAccessSchedule
          parent={ctrl}
          fiatCreditTypes={
            showCurrencyWork ? props.fiatCreditTypes : [USD_CREDIT_TYPE]
          }
          customCreditTypes={showCPUWork ? props.customCreditTypes : []}
          asCredit={props.asCredit}
        />
      </div>
      {!props.asCredit && (
        <>
          <div className="flex flex-col gap-12">
            <Subtitle className="col-span-3">Invoice schedule</Subtitle>
            <BillingSchedule
              parent={ctrl}
              mode="invoice"
              fiatCreditTypes={
                showCurrencyWork ? props.fiatCreditTypes : [USD_CREDIT_TYPE]
              }
              allowEmpty={!disallowFreeCommits}
            />
          </div>
          {props.level === "customer" && (
            <Select
              {...ctrl.props.Select("invoiceContractId", {
                name: "Invoice contract",
                placeholder: "Select",
                tooltip:
                  "Select the contract that will be used to invoice the customer for this commit. Not needed for free commits.",
                disabled: ctrl.get("billingScheduleFrequency") === "none",
                loading: !props.contracts,
                options:
                  props.contracts?.map((c) => ({
                    value: c.id,
                    label: c.name ?? c.id,
                  })) ?? [],
              })}
            />
          )}
        </>
      )}
      <div className="flex flex-col gap-12">
        <Subtitle>Priority</Subtitle>
        <NumericInput
          {...ctrl.props.NumericInput("priority", {
            name: "Priority",
            tooltip:
              "Dictates the order that commits and credits are applied. Commits/credits with a lower priority will be applied first.",
            placeholder: "100",
          })}
        />
      </div>
      {props.level === "contract" && !props.asCredit && (
        <div className="flex flex-col gap-12">
          <Subtitle>Rollover terms</Subtitle>
          <div className="grid grid-cols-3 gap-12">
            <NumericInput
              {...ctrl.props.NumericInput("rolloverFraction", {
                name: "Rollover amount (optional)",
                tooltip: `If this contract is renewed, up to this percent of the total ${props.asCredit ? "credit" : "commitment"} will roll over to the new contract.`,
                placeholder: "0.0",
                suffix: "%",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

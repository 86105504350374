import React from "react";
import { Tooltip, Icon, AvatarWithName, Body } from "design-system";
import classNames from "classnames";

import styles from "./index.module.less";
import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { DeprecatedCards } from "components/deprecated/Cards";
import { renderDate } from "lib/time";
import { AddPlanToCustomerDataQuery } from "../../queries.graphql";

export const selectingPlanIsDone = (customerPlan: CustomerPlanInfo) => {
  return !!customerPlan.Plan;
};

interface SelectPlanProps {
  plans: AddPlanToCustomerDataQuery["plans"];
}
export const SelectPlan: React.FC<SelectPlanProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();

  return (
    <DeprecatedWizardFullPage>
      <DeprecatedCards
        loading={false}
        cards={props.plans.map((plan) => {
          const isSelected = customerPlan.Plan?.id === plan.id;
          return {
            onClick: () => {
              setCustomerPlan({
                ...customerPlan,
                Plan: { id: plan.id, name: plan.name },
                trialSpec: undefined,
                planPreviewed: false,
                finalPlanPreviewed: false,
                customPricing: undefined,
              });
            },
            id: plan.id,
            title: plan.name,
            subtitle: <Body level={2}>{plan.id}</Body>,
            content: plan.description,
            badge: isSelected ? (
              <Icon icon="checkmarkCircle" className={styles.selected} />
            ) : undefined,
            statistics: [
              {
                icon: "people",
                value: plan.customer_count ?? 0,
                label: "customer",
              },
              {
                icon: "cart",
                value: plan.PricedProducts_aggregate.aggregate?.count ?? 0,
                label: "product",
              },
            ],
            footer:
              plan.Actor != null ? (
                <Tooltip
                  content={
                    <>
                      Created by {plan.Actor.name}
                      <br />
                      {renderDate(new Date(plan.created_at), {
                        isUtc: false,
                      })}
                    </>
                  }
                >
                  <AvatarWithName {...plan.Actor} />
                </Tooltip>
              ) : undefined,
            className: classNames(styles.planCard, {
              [styles.selected]: isSelected,
            }),
          };
        })}
      />
    </DeprecatedWizardFullPage>
  );
};

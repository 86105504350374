import React, { useEffect } from "react";
import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { DeprecatedInputSection } from "components/deprecated/Wizard/components/InputSection";
import { PlanQuery } from "app/lib/plans/queries.graphql";
import { DeprecatedPlanPreview } from "components/deprecated/PlanPreview";
import { BlockSkeleton } from "components/deprecated/Skeleton";
import { CreditType } from "app/types/credit-types";

export const previewingFinalPlanIsDone = (
  customerPlan: CustomerPlanInfo,
): boolean => {
  return !!(customerPlan.Plan && customerPlan.finalPlanPreviewed);
};

interface PreviewFinalPlanProps {
  selectedPlan: PlanQuery | null;
  creditTypes: CreditType[];
}

export const PreviewFinalPlan: React.FC<PreviewFinalPlanProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();

  useEffect(() => {
    setCustomerPlan({ ...customerPlan, finalPlanPreviewed: true });
  }, [customerPlan.Plan]);

  return (
    <DeprecatedWizardFullPage>
      <DeprecatedInputSection
        header="Preview your new plan"
        subtitle="Congrats! You’ve successfully created a new plan. Take a look and verify all terms, products, and pricing. If anything needs to be changed, just head back to that section and make edits."
      />
      {props.selectedPlan?.Plan_by_pk ? (
        <DeprecatedPlanPreview
          plan={{
            ...props.selectedPlan.Plan_by_pk,
            // Override the trial spec from the plan with the one set by the client specifically for this customer
            TrialSpec: customerPlan.trialSpec
              ? {
                  id: "",
                  length_in_days: String(customerPlan.trialSpec?.length ?? ""),
                  TrialSpecSpendingCaps:
                    customerPlan.trialSpec?.caps?.map((c) => ({
                      id: "",
                      CreditType: props.creditTypes.find(
                        (ct) => ct.id === c.creditTypeId,
                      ) ?? {
                        id: "",
                        name: "",
                        client_id: null,
                        environment_type: null,
                      },
                      amount: c.amount?.toString() ?? "",
                    })) ?? [],
                }
              : null,
          }}
          customPricing={customerPlan.customPricing}
          collapsible={false}
          creditTypeConversionAdjustments={
            customerPlan.creditTypeConversionAdjustments
          }
        />
      ) : (
        <BlockSkeleton />
      )}
    </DeprecatedWizardFullPage>
  );
};

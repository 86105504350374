import React from "react";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { useFetchAlertCustomerCountQuery } from "app/pages/deprecated/Customer/tabs/Alerts/queries.graphql";
import pluralize from "pluralize";
import { Icon } from "design-system/components/Icon";
import { Subtitle } from "design-system";

const AlertsCustomerCell: React.FC<{ alertId: string }> = ({ alertId }) => {
  const { data, loading, error } = useFetchAlertCustomerCountQuery({
    variables: { alert_id: alertId },
  });

  if (loading) {
    return <DeprecatedTextSkeleton />;
  }
  if (error) {
    return <span>-</span>;
  }
  const alert = data?.Alert_by_pk;

  if (!alert) {
    return <span>-</span>;
  }

  return (
    <div className="flex items-center">
      {alert.Customer ? (
        <>
          <Icon icon="personCircle" className=" mr-4 text-base text-gray-600" />
          <Subtitle level={3} className="text-gray-600">
            {alert.Customer.name}
          </Subtitle>
        </>
      ) : alert.Plan ? (
        <>
          <Icon icon="receipt" className="mr-4 text-base text-gray-600" />
          <Subtitle level={3} className="text-gray-600">
            {alert.Plan.name} (
            {pluralize("customer", alert.customer_count ?? 0, true)})
          </Subtitle>
        </>
      ) : (
        <>
          <Icon icon="peopleCircle" className="mr-4 text-base text-gray-600" />
          <Subtitle level={3} className="text-gray-600">
            All customers (
            {pluralize("customer", alert.customer_count ?? 0, true)})
          </Subtitle>
        </>
      )}
    </div>
  );
};

export default AlertsCustomerCell;

import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";

export const formatBillingProvider = (
  billingProvider: BillingProviderEnum_Enum | undefined | null,
): string => {
  switch (billingProvider) {
    case undefined:
    case null:
      return "--";
    case BillingProviderEnum_Enum.Netsuite:
      return "NetSuite";
    case BillingProviderEnum_Enum.Stripe:
      return "Stripe";
    case BillingProviderEnum_Enum.Custom:
      return "Custom";
    case BillingProviderEnum_Enum.AwsMarketplace:
      return "AWS Marketplace";
    case BillingProviderEnum_Enum.Azure:
      return "Azure";
    case BillingProviderEnum_Enum.Gcp:
      return "GCP";
    case BillingProviderEnum_Enum.Manual:
      return "Manual";
    case BillingProviderEnum_Enum.QuickbooksOnline:
      return "QuickBooks Online";
    case BillingProviderEnum_Enum.Tackle:
      return "Tackle";
    case BillingProviderEnum_Enum.Workday:
      return "Workday";
    default:
      billingProvider satisfies never;
      return "--";
  }
};

import React from "react";
import classNames from "classnames";

import { DeprecatedTable, TableProps } from "../Table";
export type InPanelTableProps<T extends object> = Omit<
  TableProps<T>,
  "noBottomBorder"
> & {
  panelHeader?: boolean;
};

/**
 * A table that knows how to render correctly at the bottom of a panel's content
 */
export function DeprecatedInPanelTable<T extends object>(
  props: InPanelTableProps<T>,
) {
  const expectPagination = props.manualPagination
    ? props.manualPagination.pageCount > 1
    : props.data.length > (props.maxPageSize ?? 10);

  const expectBasicPagination = !!props.basicPagination;

  return (
    <DeprecatedTable<T>
      {...props}
      theadClassName={
        props.panelHeader
          ? "text-deprecated-gray-darkest bg-deprecated-gray-lightest rounded-t-large"
          : undefined
      }
      containerClassName={classNames(
        props.containerClassName,
        "px-12",
        expectPagination && "mb-8",
        expectBasicPagination && "!mb-8",
        !expectBasicPagination && !expectPagination && "!mb-0",
      )}
      basicPagination={
        expectBasicPagination
          ? {
              ...props.basicPagination,
              className: classNames(props.basicPagination?.className, "mt-8"),
            }
          : undefined
      }
      noBottomBorder={!expectPagination && !expectBasicPagination}
    />
  );
}

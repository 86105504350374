import React from "react";
import { Body, Headline, Subtitle } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { DeprecatedTablePanel } from "components/deprecated/TablePanel";
import { printDateTime, toDayjs, useNow } from "lib/date";
import { Schema } from "../../Schema";
import styles from "./index.module.less";
import { useGetProductsForRateCardQuery } from "./data.graphql";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
interface OverrideProps {
  rateCardId: string;
  overrides: Schema.Types.Override[];
  onRequestClose: () => void;
}

export const ViewOverridesFlyover: React.FC<OverrideProps> = ({
  rateCardId,
  overrides,
  onRequestClose,
}) => {
  const now = useNow();
  const rateCard = useGetProductsForRateCardQuery({
    variables: { rateCardId: rateCardId },
  });
  const products = rateCard.data?.contract_pricing.rate_card.products;

  const renderStartingAt = (row: Schema.Types.Override) => (
    <Subtitle level={4} className={styles.styledSubtitle}>
      {row.startingAt ? printDateTime(toDayjs(row.startingAt)) : "--"}
    </Subtitle>
  );

  const renderEndingBefore = (row: Schema.Types.Override) => (
    <Subtitle level={4} className={styles.styledSubtitle}>
      {row.endingBefore ? printDateTime(toDayjs(row.endingBefore)) : "--"}
    </Subtitle>
  );

  const renderPriority = (row: Schema.Types.Override) => {
    if (row.rate?.type === "multiplier" && row.rate.priority) {
      return (
        <Subtitle level={4} className={styles.styledSubtitle}>
          {row.rate.priority}
        </Subtitle>
      );
    }
    return (
      <Subtitle level={4} className={styles.styledSubtitle}>
        --
      </Subtitle>
    );
  };

  const renderOverrideType = (row: Schema.Types.Override) => {
    if (row.rate?.type === "multiplier") {
      return (
        <Subtitle level={4} className={styles.styledSubtitle}>
          {" "}
          {row.rate.multiplier} Multiplier
        </Subtitle>
      );
    }
    if (row.rate?.type === "overwrite") {
      return (
        <Subtitle level={4} className={styles.styledSubtitle}>
          Overwrite
        </Subtitle>
      );
    }
    return (
      <Subtitle level={4} className={styles.styledSubtitle}>
        --
      </Subtitle>
    );
  };

  const renderProduct = (row: Schema.Types.Override) => {
    if (row.productId) {
      const product = products?.find((p) => p.id === row.productId);
      const productName = product
        ? ProductListItem.getName(product, now)
        : row.productId;

      return (
        <Subtitle level={4} className={styles.styledSubtitle}>
          {productName}
        </Subtitle>
      );
    }
    return (
      <Subtitle level={4} className={styles.styledSubtitle}>
        --
      </Subtitle>
    );
  };

  const renderProductTags = (row: Schema.Types.Override) => {
    if (row.tags && row.tags.length > 0) {
      const tags = row.tags.map((tag) => `${tag}`).join(", ");
      return (
        <Subtitle level={4} className={styles.styledSubtitle}>
          {tags}
        </Subtitle>
      );
    }
    return (
      <Subtitle level={4} className={styles.styledSubtitle}>
        --
      </Subtitle>
    );
  };

  return (
    <DeprecatedRightPane
      size="xl"
      isOpen
      onRequestClose={onRequestClose}
      contentClassName="!p-0"
    >
      <header className="flex items-center border-b border-deprecated-gray-100 bg-gray-50 px-12 py-8">
        <Headline level={6} className="grow">
          Contract Overrides
        </Headline>
        <IconButton
          className="m-0"
          onClick={() => onRequestClose()}
          theme="secondary"
          icon="xClose"
        />
      </header>
      <div className="p-12">
        <div>
          <Body level={2} className="text-gray-600">
            All pending overrides
          </Body>
        </div>
        <DeprecatedTablePanel
          title="Overrides"
          data={overrides}
          columns={[
            {
              id: "type",
              header: "type",
              render: renderOverrideType,
            },
            {
              id: "product",
              header: "Product",
              render: renderProduct,
            },
            {
              id: "product_tags",
              header: "Product tags",
              render: renderProductTags,
            },
            {
              id: "starting_at",
              header: "Starting at",
              render: renderStartingAt,
            },
            {
              id: "ending_before",
              header: "Ending before",
              render: renderEndingBefore,
            },
            {
              id: "priority",
              header: "Priority",
              render: renderPriority,
            },
          ]}
        />
      </div>
    </DeprecatedRightPane>
  );
};

import { parseIntegrationCustomerSettings } from "app/lib/billingProvider/billingProviderSettings";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useGetBillingProvidersSettingsQuery } from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import React from "react";
import { BadgeGroup } from "components/BadgeGroup";
import { Button } from "components/Button";
import { CopyableID } from "components/CopyableID";
import { Icon } from "components/Icon";
import { LoadingBlob } from "components/LoadingBlob";
import { Tooltip } from "components/Tooltip";

type IntegrationProps = {
  customerId: string;
};

export const Integrations: React.FC<IntegrationProps> = ({ customerId }) => {
  const { environmentType } = useEnvironment();
  const { data, loading } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: customerId,
      environment_type: environmentType,
    },
  });

  if (loading || !data) {
    return <LoadingBlob />;
  }
  const customerSettingsInfo = parseIntegrationCustomerSettings(data);

  const awsMultiSettings = customerSettingsInfo.awsMultiSettings;
  const azureMultiSettings = customerSettingsInfo.azureMultiSettings;
  const stripeSettings =
    customerSettingsInfo.stripeSettings?.find(
      (settings) => settings.billingProviderCustomerID,
    ) ?? {};
  const salesforceConnectionSettings =
    customerSettingsInfo.salesforceConnectionSettings;
  const netSuiteConnectionSettings =
    customerSettingsInfo.netSuiteConnectionSettings;

  const noIntegrations =
    awsMultiSettings.length === 0 &&
    azureMultiSettings.length === 0 &&
    Object.keys(stripeSettings).length === 0 &&
    Object.keys(salesforceConnectionSettings).length === 0 &&
    Object.keys(netSuiteConnectionSettings).length === 0;
  return (
    <div className="flex flex-col gap-y-md">
      <h3 className="text-md font-semibold text-gray-800">
        Connected integrations
      </h3>
      {noIntegrations && (
        <div className="flex flex-col gap-y-lg">
          <span className="text-sm font-normal">No integrations</span>
          <Button
            theme="linkGray"
            text="Settings"
            leadingIcon="settings01"
            linkTo={`/customers/${customerId}/settings`}
          />
        </div>
      )}
      {awsMultiSettings.map(
        (aws, idx) =>
          aws.customerId && (
            <div>
              <Tooltip key={`aws-${idx}`} label="AWS customer ID">
                <BadgeGroup
                  mainLabel={<CopyableID id={aws.customerId} />}
                  badgeLabel={
                    <Icon
                      viewBox="0 0 32 32"
                      icon="aws"
                      showRaw={true}
                      size={16}
                    />
                  }
                />
              </Tooltip>
            </div>
          ),
      )}
      {azureMultiSettings.map(
        (azure, idx) =>
          azure.subscriptionId && (
            <div>
              <Tooltip key={`azure-${idx}`} label="Azure subscription ID">
                <BadgeGroup
                  key={`azure-${idx}`}
                  mainLabel={<CopyableID id={azure.subscriptionId} />}
                  badgeLabel={
                    <Icon
                      viewBox="0 0 32 32"
                      icon="microsoft"
                      showRaw={true}
                      size={16}
                    />
                  }
                />
              </Tooltip>
            </div>
          ),
      )}
      {stripeSettings?.customerId && (
        <div>
          <Tooltip label="Stripe customer ID">
            <BadgeGroup
              mainLabel={<CopyableID id={stripeSettings.customerId} />}
              badgeLabel={
                <Icon
                  viewBox="0 0 32 32"
                  icon="stripe"
                  showRaw={true}
                  size={16}
                />
              }
            />
          </Tooltip>
        </div>
      )}
      {salesforceConnectionSettings?.salesforceAccountId && (
        <div>
          <Tooltip label="Salesforce account ID">
            <BadgeGroup
              mainLabel={
                <CopyableID
                  id={salesforceConnectionSettings.salesforceAccountId}
                />
              }
              badgeLabel={
                <Icon
                  viewBox="0 0 273 191"
                  icon="salesforce"
                  showRaw={true}
                  size={16}
                />
              }
            />
          </Tooltip>
        </div>
      )}
      {netSuiteConnectionSettings?.netSuiteCustomerId && (
        <div>
          <Tooltip label="Netsuite customer ID">
            <BadgeGroup
              mainLabel={
                <CopyableID
                  id={netSuiteConnectionSettings.netSuiteCustomerId}
                />
              }
              badgeLabel={
                <Icon
                  viewBox="0 0 32 32"
                  icon="netsuite"
                  showRaw={true}
                  size={16}
                />
              }
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

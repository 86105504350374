import Decimal from "decimal.js";
import React, { useEffect } from "react";

import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { getRamps, getRampStartPeriod } from "app/lib/plans/ramps";
import { collectionScheduleToEnum } from "app/lib/plans/types";
import { useDraftPlan } from "../../../../context";
import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import { isSkipRamp, PriceProductTable } from "../PriceProductTable";
import { ChargeTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ProductWithPricedProduct } from "../CompositeChargePane";

interface PriceRampPageProps {
  productId: string;
  rampIndex: number;
  data: CreatePlanDataQuery;
  hasCustomPricing?: boolean;
  editing?: boolean;
}

export const PriceRampPage: React.FC<PriceRampPageProps> = (props) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();

  const ramps = getRamps(draftPlan);
  const rampStartPeriod = getRampStartPeriod(props.rampIndex, ramps);
  const refs = Object.fromEntries(
    Array.from(draftPlan.selectedProductIds ?? []).map((productId) => {
      return [productId, React.createRef<HTMLDivElement>()];
    }),
  );

  /* This will pre-fill the next ramp (if it isn't the first ramp). It does not pre-fill all ramps at once but each time an additional ramp is added to the draft plan */
  useEffect(() => {
    if (props.rampIndex > 0) {
      // We want to use the previous ramp's duration
      const previousRampStartPeriod =
        rampStartPeriod - (ramps[props.rampIndex - 1].durationInPeriods ?? 0);
      let newPricedProducts,
        newMinimums = undefined;
      const ppfsToPrefill = Object.fromEntries(
        (draftPlan.pricedProducts ?? []).map((pricedProduct) => {
          const thisRampPPFs = pricedProduct.pricingFactors.filter(
            (pf) => pf.startPeriod === rampStartPeriod,
          );
          const previousRampPPFs = pricedProduct.pricingFactors.filter(
            (pf) => pf.startPeriod === previousRampStartPeriod,
          );
          const pricedProductFactorsToPrefill = previousRampPPFs.filter(
            (previousRampPPF) =>
              thisRampPPFs.find(
                (thisRampPPF) =>
                  thisRampPPF.pricingFactorId ===
                  previousRampPPF.pricingFactorId,
              ) === undefined,
          );
          return [pricedProduct.productId, pricedProductFactorsToPrefill];
        }),
      );
      if (
        Object.values(ppfsToPrefill).some(
          (prefillList) => prefillList.length > 0,
        )
      ) {
        newPricedProducts = (draftPlan.pricedProducts ?? []).map(
          (pricedProduct) => {
            return {
              ...pricedProduct,
              pricingFactors: pricedProduct.pricingFactors.concat(
                ppfsToPrefill[pricedProduct.productId].map((ppf) => {
                  const skipRamp = isSkipRamp(
                    [...pricedProduct.pricingFactors, ppf],
                    ppf.pricingFactorId,
                    rampStartPeriod,
                    props.rampIndex,
                    ppf.flatFees?.[0]?.collectionInterval,
                    ppf.flatFees?.[0]?.collectionSchedule,
                  );
                  return {
                    ...ppf,
                    compositeCharge: ppf.compositeCharge
                      ? ppf.compositeCharge.map((cc) => ({ ...cc }))
                      : undefined,
                    flatFees: ppf.flatFees ? [...ppf.flatFees] : undefined,
                    prices: ppf.prices
                      ? ppf.prices.map((p) => ({ ...p }))
                      : undefined,
                    seatPrices: ppf.seatPrices,
                    startPeriod: rampStartPeriod,
                    skipRamp: skipRamp,
                  };
                }),
              ),
            };
          },
        );
      }

      setDraftPlan({
        ...draftPlan,
        pricedProducts: newPricedProducts ?? draftPlan.pricedProducts,
        minimums: newMinimums ?? draftPlan.minimums,
      });
    }
  }, []);

  useEffect(() => {
    refs[props.productId]?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [props.productId]);

  const products: ProductWithPricedProduct[] = (
    draftPlan.selectedProductIds ?? []
  ).map((productId) => {
    const productData = props.data.products.find((p) => p.id === productId);
    if (!productData) {
      throw new Error(`Couldn't find product with id: ${productId}`);
    }
    return {
      productData,
      pricedProduct: draftPlan.pricedProducts?.find(
        (pp) => pp.productId === productId,
      ) || {
        productId: productId,
        pricingFactors: [],
      },
    };
  });

  useEffect(() => {
    // Pre-fill fixed fee quantities for 1st ramp when creating new plan
    if (
      props.rampIndex === 0 &&
      !(draftPlan.pricedProducts && draftPlan.pricedProducts?.length > 0)
    ) {
      const newPricedProducts = products.map((p) => ({
        productId: p.productData?.id || "",
        pricingFactors:
          p.productData?.ProductPricingFactors.filter(
            (ppf) => !ppf.BillableMetric,
          ).map((ppf) => ({
            flatFees:
              ppf.charge_type_enum === ChargeTypeEnum_Enum.Flat
                ? [
                    {
                      value: undefined,
                      metricMinimum: 0,
                      quantity: new Decimal(1),
                      isProrated: false,
                      collectionSchedule: collectionScheduleToEnum("ARREARS"),
                      collectionInterval: 1,
                    },
                  ]
                : undefined,
            compositeCharge:
              ppf.charge_type_enum === ChargeTypeEnum_Enum.Composite
                ? [
                    {
                      value: undefined,
                      compositeMinimum: 0,
                      quantity: new Decimal(0),
                      pricingFactors: [] as { id: string; name: string }[],
                    },
                  ]
                : undefined,
            pricingFactorId: ppf.id,
            startPeriod: 0,
            chargeType: ppf.charge_type_enum,
          })) ?? [],
      }));
      setDraftPlan({
        ...draftPlan,
        pricedProducts: newPricedProducts,
      });
    }
  }, []);

  // findIndex() returns -1 if the element is not found
  const firstWithoutCreditType = products.findIndex(
    (product) => product.pricedProduct?.creditType === undefined,
  );

  return (
    <DeprecatedWizardFullPage>
      {products.map((product, i) => {
        if (!product.productData) {
          return null;
        }
        return (
          <PriceProductTable
            key={`ramp${props.rampIndex}product${product.productData.id}`}
            rampStart={rampStartPeriod}
            rampDuration={ramps[props.rampIndex].durationInPeriods}
            rampIndex={props.rampIndex}
            ramps={ramps}
            product={product.productData}
            pricedProduct={product.pricedProduct}
            creditTypes={props.data.CreditType}
            ref={refs[product.productData.id]}
            autoFocus={i === firstWithoutCreditType}
            hasCustomPricing={props.hasCustomPricing}
            allProducts={products}
            index={i}
            editing={props.editing}
          />
        );
      })}
    </DeprecatedWizardFullPage>
  );
};

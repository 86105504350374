import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useNavigate, NavigateOptions } from "app/lib/useNavigate";

import { useRequiredParam } from "app/lib/routes/params";
import {
  useMinimumContractFromRoute,
  CustomerContractLayout,
} from "../CustomerContractLayout";

import {
  useRecentContractInvoicesQuery,
  RecentContractInvoicesQueryHookResult,
} from "./data.graphql";
import { InvoicesLayout } from "../../../components/Invoices";
import { reportToSentry } from "app/lib/errors/sentry";

const getInvoices = (req: RecentContractInvoicesQueryHookResult) =>
  req.data?.Customer_by_pk?.mri_invoices.invoices ?? [];

export const CustomerContractInvoices: React.FC = () => {
  const navigate = useNavigate();
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const req = useMinimumContractFromRoute();

  const invoicesReq = useRecentContractInvoicesQuery({
    variables: {
      customerId,
      contracts: {
        include: [contractId],
      },
    },
  });

  const activeInvoiceId = useParams<{ invoiceId: string }>().invoiceId;
  const [searchParams] = useSearchParams();
  function setActiveInvoiceId(invoiceId?: string, navOpts?: NavigateOptions) {
    navigate(
      `/customers/${customerId}/contracts/${contractId}/invoices${
        invoiceId ? `/${invoiceId}` : ""
      }?${searchParams.toString()}`,
      navOpts,
    );
  }

  React.useEffect(() => {
    const invoices = getInvoices(invoicesReq);
    if (invoices.length >= 190) {
      reportToSentry(
        new Error(
          `Contract has >= 190 invoices, consider raising MAX_PAGE on mri_invoices and limit on mri_invoices query`,
        ),
      );
    }
    if (!activeInvoiceId && invoices.length > 0) {
      setActiveInvoiceId(invoices[0].id, {
        replace: true,
      });
    }
  }, [invoicesReq, activeInvoiceId]);

  return (
    <CustomerContractLayout
      rootReq={req}
      disableContainerScroll
      content={() => (
        <InvoicesLayout
          type="contract"
          loading={invoicesReq.loading}
          error={invoicesReq.error}
          invoices={getInvoices(invoicesReq)}
          activeInvoiceId={activeInvoiceId}
          setActiveInvoiceId={setActiveInvoiceId}
          customerId={customerId}
        />
      )}
    />
  );
};

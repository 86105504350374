import React from "react";
import { Subtitle } from "design-system";

export const DeprecatedNoSubLineItems: React.FC<{
  showChargesWithZeroUsage: boolean;
}> = ({ showChargesWithZeroUsage }) => {
  return (
    <div className="pb-24 text-center">
      <Subtitle level={3}>
        {showChargesWithZeroUsage
          ? "No charges for this product"
          : "No charges with usage for this product"}
      </Subtitle>
    </div>
  );
};

import React, { useState } from "react";
import { Badge, Caption, Headline } from "design-system";
import {
  DeprecatedParagraphSkeleton,
  DeprecatedTextSkeleton,
} from "components/deprecated/Skeleton";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { CopyableID } from "components/CopyableID";
import {
  getUserFacingErrorMessage,
  isGqlNotFoundError,
} from "app/lib/errors/errorHandling";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { dayjs, printDateTime, useNow } from "lib/date";
import { useSearchParam } from "app/lib/routes/useSearchParam";

import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";

import { SendMatchingUsageEventButton } from "./SendMatchingUsageEventButton";

import {
  useContractProductFlyoverQuery,
  ContractProductFlyover__ProductFragment as ProductFragment,
  useArchiveProductListItemMutation,
  ContractProductFlyoverQuery,
  ArchiveProductListItemDocument,
} from "./data.graphql";
import { DeprecatedDefinitionDisplay } from "components/deprecated/BillableMetricsDefinition";
import { isArrayOfStrings } from "app/pages/Contracts/lib/array";
import { PricingGroupValues } from "app/pages/Contracts/lib/PricingGroupValues";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { EditProductDocument } from "../CreateAndEditProductModal";
import {
  MenuItemProps,
  DeprecatedPopoverMenu,
} from "components/deprecated/PopoverMenu";
import { ConfirmModal } from "app/pages/Contracts/components/ConfirmModal";
import { useSnackbar } from "components/deprecated/Snackbar";
import { reportToSentry } from "app/lib/errors/sentry";
import { ApolloQueryResult } from "@apollo/client";
import { useFeatureFlag } from "app/lib/launchdarkly";
import pluralize from "pluralize";
import { RoundingMethod } from "types/generated-graphql/__types__";
import { renderDate } from "lib/time";
import { IconButton } from "components/IconButton";
import { ButtonGroup } from "components/ButtonGroup";
import { Table } from "components/Table";

interface ContentProps {
  loading?: boolean;
  error?: unknown;
  product?: ProductFragment;
  refetch: () => Promise<ApolloQueryResult<ContractProductFlyoverQuery>>;
  pricingGroupValues?: PricingGroupValues;
  effectiveAt: dayjs.Dayjs;
  isUpdate: boolean;
  onRequestClose: () => void;
  setProduct2Id?: (id: string) => void;
  className?: string;
  editProduct?: (productId: string) => void;
}

const ContractProductDetailContent: React.FC<ContentProps> = ({
  loading,
  error,
  product,
  refetch,
  pricingGroupValues,
  effectiveAt,
  isUpdate,
  onRequestClose,
  setProduct2Id,
  editProduct,
}) => {
  const [metricContentToDisplay, setMetricContentToDisplay] = useState<
    "definition" | "example"
  >("definition");
  const canEditProduct = !!useAuthCheck(EditProductDocument, true).allowed;
  const canArchiveProduct = !!useAuthCheck(ArchiveProductListItemDocument, true)
    .allowed;

  const [flyover, setFlyover] = React.useState<
    { type: "confirmArchive" } | undefined
  >();

  const [archiveProductRequest, archiveProductResult] =
    useArchiveProductListItemMutation();

  const pushMessage = useSnackbar();

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const excludeFreeUsageEnabled = nonGAContractFeaturesEnabled?.includes(
    "COMPOSITE_EXCLUDE_FREE_USAGE",
  );

  if (error) {
    return isGqlNotFoundError(error) ? (
      <ErrorEmptyState title="We couldn't find this product" error={null} />
    ) : (
      <ErrorEmptyState
        title="We ran into an issue loading this product"
        error={error}
      />
    );
  }

  if (loading || !product) {
    return (
      <>
        <header className="flex h-[48px] items-center bg-gray-50 px-12">
          <DeprecatedTextSkeleton width="66%" />
        </header>
        <DeprecatedParagraphSkeleton className="p-12" />
      </>
    );
  }

  const pricingGroupKey = ProductListItem.getPricingGroupKey(
    product,
    effectiveAt,
  );

  const quantityConversion = ProductListItem.getQuantityConversion(
    product,
    effectiveAt,
  );

  const quantityRounding = ProductListItem.getQuantityRounding(
    product,
    effectiveAt,
  );

  const presentationGroupKey = ProductListItem.getPresentationGroupKey(
    product,
    effectiveAt,
  );

  const tags = ProductListItem.getTags(product, effectiveAt);
  const nsItemId = ProductListItem.getNetsuiteInternalItemId(
    product,
    effectiveAt,
  );
  const nsOverageId = ProductListItem.getNetsuiteOverageItemId(
    product,
    effectiveAt,
  );
  const excludeFreeUsage = ProductListItem.getExcludeFreeUsage(
    product,
    effectiveAt,
  );

  const compositeProducts =
    product.__typename === "CompositeProductListItem"
      ? ProductListItem.getCurrent(product, "composite_products", effectiveAt)
      : undefined;

  const compositeTags =
    product.__typename === "CompositeProductListItem"
      ? ProductListItem.getCurrent(product, "composite_tags", effectiveAt)
      : undefined;

  const billableMetric =
    product.__typename === "UsageProductListItem"
      ? ProductListItem.getCurrent(product, "billable_metric", effectiveAt)
      : undefined;

  const regularTextClass = "text-right font-normal text-deprecated-gray-dark";

  const metadataRows = [
    {
      label: "product_id",
      value: <CopyableID id={product.id} />,
      id: "product_id",
    },
    {
      label: "product_type",
      value: (
        <div className={regularTextClass}>
          {ProductListItem.printType(product)}
        </div>
      ),
      id: "product_type",
    },
    {
      label: "created_at",
      value: (
        <div className={regularTextClass}>
          {renderDate(new Date(product.initial.created_at), {
            isUtc: false,
          })}
        </div>
      ),
      id: "created_at",
    },
  ];

  if (pricingGroupKey) {
    metadataRows.push({
      label: "pricing_group_key",
      value: (
        <div className={regularTextClass}>{pricingGroupKey.join(", ")}</div>
      ),
      id: "pricing_group_key",
    });
  }

  if (presentationGroupKey) {
    metadataRows.push({
      label: "presentation_group_key",
      value: (
        <div className={regularTextClass}>
          {presentationGroupKey.join(", ")}
        </div>
      ),
      id: "presentation_group_key",
    });
  }

  if (quantityConversion) {
    metadataRows.push({
      label: "quantity_conversion",
      value: (
        <div className={regularTextClass}>
          {!!quantityConversion.name
            ? `${quantityConversion.name} (${quantityConversion.operation} by ${quantityConversion.conversion_factor})`
            : `${quantityConversion.operation} by ${quantityConversion.conversion_factor}`}
        </div>
      ),
      id: "quantity_conversion",
    });
  }

  const getRoundingMethod = (method: RoundingMethod) => {
    switch (method) {
      case RoundingMethod.Ceiling:
        return "Round up";
      case RoundingMethod.Floor:
        return "Round down";
      case RoundingMethod.HalfUp:
        return "Round half up";
    }
  };

  if (quantityRounding) {
    metadataRows.push({
      label: "quantity_rounding",
      value: (
        <div className={regularTextClass}>
          {getRoundingMethod(quantityRounding.rounding_method)} to{" "}
          {quantityRounding.decimal_places} decimal{" "}
          {pluralize("place", quantityRounding.decimal_places)}
        </div>
      ),
      id: "quantity_rounding",
    });
  }

  if (nsItemId) {
    metadataRows.push({
      label: "netsuite_internal_item_id",
      value: <CopyableID id={nsItemId} />,
      id: "netsuite_internal_item_id",
    });
  }

  if (nsOverageId) {
    metadataRows.push({
      label: "netsuite_overage_item_id",
      value: <CopyableID id={nsOverageId} />,
      id: "netsuite_overage_item_id",
    });
  }

  if (excludeFreeUsage !== undefined && excludeFreeUsageEnabled) {
    metadataRows.push({
      label: "exclude_free_usage",
      value: <CopyableID id={excludeFreeUsage.toString()} />,
      id: "exclude_free_usage",
    });
  }
  const dropdownItems: MenuItemProps[] = [];
  dropdownItems.push({
    content: "Manage custom fields...",
    routePath: `/connections/custom-fields/contract-product/${product.id}`,
  });
  if (editProduct) {
    dropdownItems.push(
      gatedAction(canEditProduct, {
        content: "Edit product...",
        onClick: () => {
          editProduct(product.id);
          onRequestClose();
        },
      }),
    );
  }
  if (!product.archived_at) {
    dropdownItems.push(
      gatedAction(canArchiveProduct, {
        content: "Archive product...",
        onClick: () => {
          setFlyover({ type: "confirmArchive" });
        },
      }),
    );
  }

  return (
    <>
      <header className="flex items-center bg-gray-50 px-12 py-8">
        {/* left */}
        <div className="grow">
          {isUpdate && (
            <Caption
              level={2}
              className="mb-8"
              children={`Effective ${printDateTime(effectiveAt)}`}
            />
          )}
          <Headline level={6} className="grow">
            {ProductListItem.getName(product, effectiveAt)}
          </Headline>
          {product.archived_at && (
            <Badge theme="error" type="dark">
              ARCHIVED
            </Badge>
          )}
          {pricingGroupValues && (
            <PricingGroupValues.Badges
              pricingGroupValues={pricingGroupValues}
              theme="grey"
              type="light"
            />
          )}
        </div>

        {/* right */}
        <div className="flex items-center gap-8">
          <DeprecatedPopoverMenu
            className="mr-12"
            positions={["bottom"]}
            align="end"
            options={dropdownItems}
          >
            {(onClick) => (
              <IconButton
                icon="dotsVertical"
                theme="secondary"
                onClick={onClick}
              />
            )}
          </DeprecatedPopoverMenu>
          <IconButton
            className="grow-0"
            icon="xClose"
            onClick={onRequestClose}
            theme="secondary"
          />
        </div>
      </header>

      <div className="p-12">
        <Table
          title="Metadata"
          data={metadataRows}
          columns={[
            {
              id: "key",
              header: "Key",
              cell: (props) => props.getValue(),
              accessorFn: (row) => row.label,
            },
            {
              id: "value",
              header: "Value",
              cell: (props) => props.getValue(),
              accessorFn: (row) => row.value,
              enableSorting: false,
            },
          ]}
        />
      </div>
      {flyover?.type === "confirmArchive" && (
        <ConfirmModal
          title="Archive product"
          loading={archiveProductResult.loading}
          onCancel={() => {
            setFlyover(undefined);
          }}
          onConfirm={() => {
            void archiveProductRequest({
              variables: {
                product_id: product.id,
                archived_at: new Date().toISOString(),
              },
            })
              .then(
                () => {
                  refetch().catch(console.error);
                  pushMessage({
                    type: "success",
                    content: "Product archived",
                  });
                },
                (error) => {
                  const msg = getUserFacingErrorMessage(error);
                  pushMessage({
                    type: "error",
                    content: `Failed to archive product: ${msg}`,
                  });
                  reportToSentry(error ?? archiveProductResult.data);
                },
              )
              .finally(() => {
                setFlyover(undefined);
              });
          }}
        >
          <p>
            Any current rate cards associated with this product will continue to
            function as normal. However, it will no longer be available as an
            option for newly created rates. Are you sure you want to archive
            this product?
          </p>
        </ConfirmModal>
      )}
      {tags.length > 0 ? (
        <div className="p-12">
          <Table
            title="Product tags"
            data={tags.map((tag) => ({ id: tag, tag }))}
            columns={[
              {
                id: "name",
                header: "Name",
                cell: (props) => props.getValue(),
                accessorFn: (row) => row.tag.toString(),
                enableSorting: false,
              },
            ]}
          />
        </div>
      ) : null}
      {compositeProducts?.length ? (
        <div className="p-12">
          <Table
            title="Associated products"
            data={compositeProducts}
            rowOnClick={(row) => {
              if (setProduct2Id) {
                setProduct2Id(row.id);
              }
            }}
            columns={[
              {
                id: "name",
                header: "Name",
                cell: (props) => props.getValue(),
                accessorFn: (row) => ProductListItem.getName(row, effectiveAt),
                enableSorting: false,
              },
              {
                id: "type",
                header: "Type",
                cell: (props) => props.getValue(),
                accessorFn: (row) => ProductListItem.printType(row),
                enableSorting: false,
              },
            ]}
          />
        </div>
      ) : null}
      {compositeTags?.length ? (
        <div className="p-12">
          <Table
            title="Associated tags"
            data={compositeTags.map((tag) => ({ id: tag, tag }))}
            columns={[
              {
                id: "name",
                header: "Name",
                cell: (props) => props.getValue(),
                accessorFn: (row) => row.tag.toString(),
                enableSorting: false,
              },
            ]}
          />
        </div>
      ) : null}
      {billableMetric && (
        <div className="p-12">
          <div className="mb-8 flex flex-row">
            <div className="grow">
              <Headline level={6}>{billableMetric.name}</Headline>
              <CopyableID id={billableMetric.id} label="billable metric ID" />
            </div>
            {!billableMetric.sql && (
              <ButtonGroup
                buttons={[
                  {
                    onClick: () => {
                      setMetricContentToDisplay("definition");
                    },
                    text: "Definition",
                    isActive: metricContentToDisplay === "definition",
                  },
                  {
                    onClick: () => {
                      setMetricContentToDisplay("example");
                    },
                    text: "Example",
                    isActive: metricContentToDisplay === "example",
                  },
                ]}
              />
            )}
          </div>
          {metricContentToDisplay === "definition" ? (
            billableMetric.sql ? (
              <DeprecatedDefinitionDisplay rawSql={billableMetric.sql} />
            ) : (
              <DeprecatedDefinitionDisplay
                aggregate={billableMetric.aggregate}
                aggregateKeys={
                  isArrayOfStrings(billableMetric.aggregate_keys)
                    ? billableMetric.aggregate_keys
                    : undefined
                }
                filter={billableMetric.filter}
                groupKeys={(
                  billableMetric.group_keys as (string | string[])[]
                )?.map((groupKey) =>
                  typeof groupKey === "string"
                    ? new Set<string>([groupKey])
                    : new Set<string>(groupKey),
                )}
              />
            )
          ) : null}
          {!billableMetric.sql && (
            <SendMatchingUsageEventButton
              showCustomizationControls={metricContentToDisplay === "example"}
              billableMetric={billableMetric}
            />
          )}
        </div>
      )}
    </>
  );
};

interface ProductDetails {
  loading?: boolean;
  error?: unknown;
  data?: ContentProps["product"];
  effectiveAt: dayjs.Dayjs;
  isUpdate: boolean;
  refetch: () => Promise<ApolloQueryResult<ContractProductFlyoverQuery>>;
  pricingGroupValues?: PricingGroupValues;
}

interface DetailProps {
  product: ProductDetails;
  product2?: ProductDetails;
  onRequestClose: ContentProps["onRequestClose"];
  setProduct2Id: (id: string | null) => void;
  editProduct?: (productId: string) => void;
}

const ContractProductDetail: React.FC<DetailProps> = ({
  product,
  product2,
  onRequestClose,
  setProduct2Id,
  editProduct,
}) => {
  return (
    <DeprecatedRightPane
      size="lg"
      isOpen
      onRequestClose={onRequestClose}
      contentClassName="!p-0"
      secondPane={
        product2 ? (
          <ContractProductDetailContent
            loading={product2.loading}
            error={product2.error}
            product={product2.data}
            refetch={product2.refetch}
            effectiveAt={product2.effectiveAt}
            isUpdate={product2.isUpdate}
            onRequestClose={() => setProduct2Id(null)}
            editProduct={editProduct}
          />
        ) : undefined
      }
    >
      <ContractProductDetailContent
        loading={product.loading}
        error={product.error}
        product={product.data}
        refetch={product.refetch}
        effectiveAt={product.effectiveAt}
        isUpdate={product.isUpdate}
        onRequestClose={onRequestClose}
        setProduct2Id={setProduct2Id}
        editProduct={editProduct}
      />
    </DeprecatedRightPane>
  );
};

interface Props {
  productId: string;
  pricingGroupValues?: PricingGroupValues;
  onRequestClose: () => void;
  editProduct?: (productId: string) => void;
}

export const ContractProductFlyover: React.FC<Props> = ({
  productId,
  pricingGroupValues,
  onRequestClose,
  editProduct,
}) => {
  const req = useContractProductFlyoverQuery({
    variables: {
      id: productId,
    },
  });

  const [product2Id, setProduct2Id] = React.useState<string | null>(null);
  const product2 = useContractProductFlyoverQuery({
    skip: !product2Id,
    variables: {
      id: `${product2Id}`,
    },
  });

  const [searchQuery] = useSearchParam("effectiveAt");
  const now = useNow();

  return (
    <ContractProductDetail
      product={{
        loading: req.loading,
        error: req.error,
        data: req.data?.products_and_rate_cards.product,
        effectiveAt: searchQuery ? dayjs.utc(searchQuery) : now,
        isUpdate: searchQuery ? true : false,
        refetch: req.refetch,
        pricingGroupValues,
      }}
      product2={
        product2Id && product2
          ? {
              loading: product2.loading,
              error: product2.error,
              data: product2.data?.products_and_rate_cards.product,
              effectiveAt: searchQuery ? dayjs.utc(searchQuery) : now,
              isUpdate: searchQuery ? true : false,
              refetch: product2.refetch,
            }
          : undefined
      }
      onRequestClose={onRequestClose}
      setProduct2Id={setProduct2Id}
      editProduct={editProduct}
    />
  );
};

import React, { Dispatch, SetStateAction, useState } from "react";
import { SideSheet } from "components/SideSheet";
import { renderDate } from "lib/time";
import { Button } from "components/Button";
import { useSetupAzureMarketplaceConfigMutation } from "./queries.graphql";
import { useSnackbar } from "components/deprecated/Snackbar";
import { TextInput } from "components/Input";
import { handleCopyClick } from "app/pages/deprecated/GeneralSettings/hooks/copyToken";
import { Link } from "react-router-dom";

interface AzureSideSheetProps {
  onClose: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  connectedOn?: Date;
  azureClientID?: string;
  azureTenantID?: string;
  isConfigured: boolean;
}

export const AzureSideSheet = ({
  onClose,
  isOpen,
  connectedOn,
  azureClientID,
  azureTenantID,
  isConfigured,
}: AzureSideSheetProps) => {
  const pushMessage = useSnackbar();
  const [newAzureTenantId, setNewAzureTenantId] = useState(azureTenantID ?? "");
  const [newAzureClientId, setNewAzureClientId] = useState(azureClientID ?? "");
  const [azureClientSecret, setAzureClientSecret] = useState("");
  const [copiedTokenID, setCopiedTokenID] = useState<string | null>(null);
  const [setupAzureMarketplaceConfigMutation, { loading }] =
    useSetupAzureMarketplaceConfigMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    });

  const setupAzureMarketplaceConfigMutationAction = async (
    azureTenantId: string,
    azureClientId: string,
    azureClientSecret: string,
  ) => {
    try {
      if (!azureTenantId || !azureClientId || !azureClientSecret) {
        return;
      }
      await setupAzureMarketplaceConfigMutation({
        variables: {
          tenant_id: azureTenantId,
          client_id: azureClientId,
          raw_client_secret: azureClientSecret,
        },
      });
      pushMessage({
        content: "Azure Marketplace has been enabled",
        type: "success",
      });
      onClose(false);
    } catch (e: any) {
      pushMessage({
        content: e.message,
        type: "error",
      });
    }
  };

  const disableSave =
    !newAzureTenantId || !newAzureClientId || !azureClientSecret || loading;

  const trailingActionButtons:
    | [React.ReactElement, React.ReactElement]
    | undefined = [
    <Button
      key="save"
      text="Save changes"
      disabled={disableSave}
      onClick={async () => {
        await setupAzureMarketplaceConfigMutationAction(
          newAzureTenantId,
          newAzureClientId,
          azureClientSecret,
        );
      }}
    />,
    <Button
      key="cancel"
      text="Cancel"
      theme="secondary"
      onClick={() => {
        onClose(false);
      }}
    />,
  ];

  const displayText = isConfigured ? (
    "Updating the Azure Marketplace configuration will fail validation if any subscriptions that were created with the previous configuration do not have access to the new credentials."
  ) : (
    <>
      To configure Azure you’ll need to find your Microsoft tenant (directory)
      and application (client) IDs. Learn more{" "}
      <Link
        target="_blank"
        to="https://docs.metronome.com/invoice-customers/solutions/marketplaces/invoice-azure/#set-up-authentication-for-metronome-to-meter-on-your-behalf"
        style={{ textDecoration: "underline" }}
      >
        here
      </Link>
      .
    </>
  );

  const title = isConfigured
    ? "Manage Azure Marketplace"
    : "Enable Azure Marketplace";

  return (
    <SideSheet
      title={title}
      onClose={() => onClose(false)}
      isOpen={isOpen}
      supportingText={
        connectedOn
          ? `Connected on: ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`
          : ""
      }
      trailingActions={trailingActionButtons}
    >
      <div className="flex flex-shrink-0 flex-grow basis-0 flex-col items-start gap-[24px] self-stretch">
        <div className="mt-4">{displayText}</div>
        <div className="flex flex-col gap-sm self-stretch">
          <TextInput
            disabled={false}
            placeholder="Enter Azure client ID"
            value={newAzureClientId}
            label="ID"
            onChange={(meta: { value: string }) =>
              setNewAzureClientId(meta.value)
            }
            variantOptions={
              isConfigured
                ? {
                    button: {
                      leadingIcon: "copy01",
                      disabled: !newAzureClientId,
                      onClick: async () => {
                        await handleCopyClick(
                          newAzureClientId,
                          setCopiedTokenID,
                        );
                      },
                      text:
                        copiedTokenID === newAzureClientId ? "Copied" : "Copy",
                      theme: "secondary",
                    },
                    variant: "button",
                  }
                : undefined
            }
            fullWidth={true}
          />
        </div>
        <div className="flex flex-col gap-sm self-stretch">
          <TextInput
            disabled={false}
            placeholder="Enter Azure tenant ID"
            value={newAzureTenantId}
            label="Tenant ID"
            onChange={(meta: { value: string }) =>
              setNewAzureTenantId(meta.value)
            }
            variantOptions={
              isConfigured
                ? {
                    button: {
                      leadingIcon: "copy01",
                      disabled: !newAzureTenantId,
                      onClick: async () => {
                        await handleCopyClick(
                          newAzureTenantId,
                          setCopiedTokenID,
                        );
                      },
                      text:
                        copiedTokenID === newAzureTenantId ? "Copied" : "Copy",
                      theme: "secondary",
                    },
                    variant: "button",
                  }
                : undefined
            }
            fullWidth={true}
          />
        </div>
        <div className="flex flex-col gap-sm self-stretch">
          <TextInput
            disabled={false}
            value={azureClientSecret}
            label="Client Secret"
            placeholder="Enter Azure client secret"
            onChange={(meta: { value: string }) =>
              setAzureClientSecret(meta.value)
            }
            type="password"
            fullWidth={true}
          />
        </div>
      </div>
    </SideSheet>
  );
};

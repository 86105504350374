import React from "react";
import classNames from "classnames";
import { Caption } from "design-system";

export interface Definition {
  label: string;
  className?: string;
  badge?: React.ReactNode;
  /**
   * If falsy, the definition will not be rendered
   */
  value: React.ReactNode;
}

interface Props {
  list: Definition[];
}

export const DeprecatedDefinitionList: React.FC<Props> = ({ list }) => {
  return (
    <>
      {list.flatMap((def, i) => {
        if (!def.value) {
          return [];
        }

        return (
          <div key={`${def.label}+${i}`} className={classNames(def.className)}>
            <Caption
              level={2}
              className="whitespace-nowrap uppercase leading-3 text-deprecated-gray-300"
            >
              {def.label}
            </Caption>
            <div className="flex flex-row items-center gap-8 whitespace-nowrap align-middle">
              <div className="text-xs leading-2">{def.value}</div>
              {def.badge}
            </div>
          </div>
        );
      })}
    </>
  );
};

import * as React from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";
import { Button } from "components/Button";

import styles from "./index.module.less";

type RevisionConflictModalProps = {
  close: () => void;
  saveNew: () => void;
  overwrite: () => void;
};

export const RevisionConflictModal: React.FC<RevisionConflictModalProps> = (
  props,
) => {
  return (
    <DeprecatedPopup
      className={styles.popup}
      isOpen
      title="Conflicting changes"
      onRequestClose={props.close}
      actions={
        <>
          <Button onClick={props.close} text="Cancel" theme="linkGray" />
          <Button
            onClick={props.saveNew}
            text="Save as new draft"
            theme="secondary"
          />
          <Button
            onClick={props.overwrite}
            text="Overwrite changes"
            theme="primary"
          />
        </>
      }
    >
      <Body>
        This plan has been modified in another browser session. Would you like
        to overwrite those changes or save your changes as a new draft plan?
      </Body>
    </DeprecatedPopup>
  );
};

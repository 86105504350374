import React, { useState, useEffect, useMemo } from "react";
import { useRequiredParam } from "app/lib/routes/params";
import { isBefore } from "date-fns";
import { useApolloClient, ApolloClient, ApolloError } from "@apollo/client";

import classnames from "classnames";
import styles from "./index.module.less";
import { DeprecatedPageHeader } from "components/deprecated/PageHeader";
import { Subtitle } from "design-system";
import { DeprecatedGraph } from "components/deprecated/Graph";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";

import {
  useListMetricsOnCustomerPlansQuery,
  useMetricUsageQuery,
  ListMetricsOnCustomerPlansQuery,
  MetricUsageQuery,
  MetricUsageQueryVariables,
  MetricUsageDocument,
} from "./usage.graphql";
import { idToGraphColor } from "app/lib/idToColor";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  DeprecatedRelativeDateRangeSelector,
  DateRange,
} from "components/deprecated/RelativeDateRangeSelector";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { filterInvoiceByType } from "app/lib/invoices/typeGuard";
import { dayjs } from "lib/dayjs";
import { EmptyState } from "components/EmptyState";
import {
  DeprecatedFilter,
  OptionType,
  FilterOptions,
} from "components/deprecated/Filter";
import { removeEmpty } from "app/lib/util";
import { UI_MODE, useUIMode } from "app/lib/useUIMode";
import { reportToSentry } from "app/lib/errors/sentry";

const SQL_BM_BATCH_SIZE = 10;
const SQL_BM_WAIT_MS = 500;

type UsageProductUpdate = {
  effective_at: string | null;
  created_at?: string;
  billable_metric: {
    typename?: "BillableMetric";
    id: string;
  } | null;
};

const contractMetricsToBillableMetrics = (
  d?: ListMetricsOnCustomerPlansQuery,
) => {
  return d?.Customer_by_pk?.contracts.flatMap((contract) => {
    const contractStart = dayjs(contract.starting_at);
    const contractEnd = contract.ending_before
      ? dayjs(contract.ending_before)
      : undefined;

    // Billable metric is relevant if:
    // a. it is effective after the contract start and before the contract end, or
    // b. it directly precedes the first update is effective after the contract start, or
    // c. it is the only billable metric, or
    // d. it is the last update
    const selectRelevantBillableMetrics = (...bms: UsageProductUpdate[]) => {
      return bms
        .sort(
          (a, b) =>
            dayjs(a.effective_at || a.created_at).unix() -
            dayjs(b.effective_at || b.created_at).unix(),
        )
        .filter((u) => u.billable_metric)
        .filter((u, index, filteredBms) => {
          const nextUpdate = filteredBms[index + 1];
          if (!nextUpdate) return true;
          if (!nextUpdate.billable_metric) return true;

          if (contractEnd) {
            if (dayjs(u.effective_at).isAfter(contractEnd)) return false;
          }

          const nextUpdateStart = dayjs(nextUpdate.effective_at);
          return (
            dayjs(u.effective_at).isSameOrAfter(contractStart) ||
            dayjs(nextUpdateStart).isSameOrAfter(contractStart)
          );
        })
        .map((u) => u.billable_metric?.id);
    };

    return contract.rate_card?.products.flatMap((product) => {
      if (product.__typename === "UsageProductListItem") {
        return selectRelevantBillableMetrics(
          product.initial,
          ...product.updates,
        );
      }
      if (product.__typename === "CompositeProductListItem") {
        return [
          ...(product.initial.composite_products ?? []).flatMap((cp) => {
            if (cp.__typename === "UsageProductListItem") {
              return selectRelevantBillableMetrics(cp.initial, ...cp.updates);
            }
          }),
          ...product.updates.flatMap((cp) => {
            return cp.composite_products?.flatMap((cp) => {
              if (cp.__typename === "UsageProductListItem") {
                return selectRelevantBillableMetrics(cp.initial, ...cp.updates);
              }
            });
          }),
        ];
      }
    });
  });
};

type PlanMetrics = {
  billable_metrics: string[];
  seat_metrics: string[];
};

const customerPlansToPlanMetrics = (
  data?: ListMetricsOnCustomerPlansQuery,
): PlanMetrics => {
  const planMetrics: PlanMetrics = { billable_metrics: [], seat_metrics: [] };
  if (!data || !data.Customer_by_pk?.CustomerPlans) {
    return planMetrics;
  }
  for (const customerPlan of data.Customer_by_pk.CustomerPlans) {
    for (const pricedProducts of customerPlan.Plan.PricedProducts) {
      for (const factor of pricedProducts.Product.ProductPricingFactors) {
        if (factor.BillableMetric && !factor.BillableMetric.deleted_at) {
          planMetrics.billable_metrics.push(factor.BillableMetric.id);
        }
        if (factor.seat_metric && !factor.seat_metric.deleted_at) {
          planMetrics.seat_metrics.push(factor.seat_metric.id);
        }
      }
    }
  }
  return planMetrics;
};

const getUsageFilterOptions = (mode: UI_MODE): FilterOptions => ({
  usage_filter: {
    label: "Usage filter",
    options: [
      {
        label: "All",
        value: "all",
        group: "usage_filter",
        type: "single",
      },
      {
        label: "Non-zero usage",
        value: "non_zero_usage",
        group: "usage_filter",
        type: "single",
      },
      {
        label: `Assigned to ${mode === "plans-only" ? "plan" : mode === "contracts-only" ? "contract" : "plan / contract"}`,
        value: "assigned",
        group: "usage_filter",
        type: "single",
      },
    ],
  },
});

type BMDatum = {
  id: string;
  name: string;
  usage_breakdown: {
    next_page: string | null;
    data: Array<{
      value: string | null;
      starting_on: string;
      ending_before: string;
    }>;
  };
};

const bmKey = (bmId: string, selectedDateRange: DateRange | undefined) =>
  [
    bmId,
    selectedDateRange?.inclusiveStart.toISOString() ?? "",
    selectedDateRange?.exclusiveEnd.toISOString() ?? "",
  ].join(";");

const queryMetricUsageProgressively = async (
  client: ApolloClient<object>,
  customerId: string,
  billableMetrics: string[],
  selectedDateRange: DateRange,
  setResultData: React.Dispatch<React.SetStateAction<Record<string, BMDatum>>>,
  setSQLError: React.Dispatch<React.SetStateAction<ApolloError | undefined>>,
) => {
  const batch = billableMetrics.slice(0, SQL_BM_BATCH_SIZE);
  const remainder = billableMetrics.slice(SQL_BM_BATCH_SIZE);

  const {
    data: { BillableMetric: billableMetricsData },
    error,
  } = await client.query<MetricUsageQuery, MetricUsageQueryVariables>({
    query: MetricUsageDocument,
    variables: {
      start_date: selectedDateRange.inclusiveStart.toISOString(),
      end_date: selectedDateRange.exclusiveEnd.toISOString(),
      customer_id: customerId,
      billable_metrics: batch,
      seat_metrics: [],
      fetch_seat_metrics: false,
      limit: 100, // at most 90 days are requested at a time
    },
  });
  if (error) {
    reportToSentry(error);
    setSQLError((oldError) => oldError || error);
    return;
  }
  if (billableMetricsData.some((bmData) => bmData.usage_breakdown.next_page)) {
    reportToSentry(
      new Error(
        `Received unexpected next_page for query with BMs: ${billableMetrics.join(", ")}`,
      ),
    );
  }
  const newData = Object.fromEntries(
    billableMetricsData.map((bmData) => [
      bmKey(bmData.id, selectedDateRange),
      bmData,
    ]),
  );
  setResultData((bmData) => ({ ...bmData, ...newData }));

  if (remainder.length) {
    await new Promise((res) => setTimeout(res, SQL_BM_WAIT_MS));
    await queryMetricUsageProgressively(
      client,
      customerId,
      remainder,
      selectedDateRange,
      setResultData,
      setSQLError,
    );
  }
};

const useMetricUsageLoader = (
  customerId: string,
  billableMetricsToFetch: { id: string; sql: string | null }[],
  seatMetricsToFetch: string[],
  selectedDateRange: DateRange | undefined,
) => {
  const basicBillableMetrics = billableMetricsToFetch.filter((bm) => !bm.sql);
  const {
    data: {
      BillableMetric: basicBMDataArray = [],
      seat_metrics: { metrics: seatMetricsData = [] } = {},
    } = {},
    loading: metricsLoading,
    refetch: basicRefetch,
    error: basicError,
  } = useMetricUsageQuery({
    variables: {
      start_date: selectedDateRange?.inclusiveStart.toISOString() ?? "",
      end_date: selectedDateRange?.exclusiveEnd.toISOString() ?? "",
      customer_id: customerId,
      billable_metrics: basicBillableMetrics.map((bm) => bm.id),
      seat_metrics: seatMetricsToFetch,
      fetch_seat_metrics: seatMetricsToFetch.length > 0,
    },
    skip:
      !selectedDateRange ||
      (!basicBillableMetrics.length && !seatMetricsToFetch.length),
  });
  const basicBMData = Object.fromEntries(
    basicBMDataArray.map((bm) => [bmKey(bm.id, selectedDateRange), bm]),
  );

  const client = useApolloClient();
  const [sqlRefetch, setSQLRefetch] = useState(0);
  const [sqlBMData, setSQLBMData] = useState<Record<string, BMDatum>>({});
  const [sqlError, setSQLError] = useState<ApolloError | undefined>();
  const sqlBillableMetrics = useMemo(
    () => billableMetricsToFetch.filter((bm) => bm.sql).map((bm) => bm.id),
    [billableMetricsToFetch],
  );
  useEffect(() => {
    if (sqlBillableMetrics.length && selectedDateRange) {
      // fire and forget this call
      void queryMetricUsageProgressively(
        client,
        customerId,
        sqlBillableMetrics,
        selectedDateRange,
        setSQLBMData,
        setSQLError,
      );
    }
  }, [sqlBillableMetrics, selectedDateRange, sqlRefetch]);

  const fullRefetch = () => {
    void basicRefetch();
    setSQLRefetch((oldRefetch) => oldRefetch + 1);
  };

  const bmData = { ...basicBMData, ...sqlBMData };
  return {
    bmData,
    seatMetricsData,
    metricsLoading,
    error: basicError || sqlError,
    refetch: fullRefetch,
  };
};

export const Usage: React.FC = () => {
  const { mode } = useUIMode();
  const usageFilterOptions = getUsageFilterOptions(mode);
  const compoundMetrics = useFeatureFlag(
    "compound-metric-configuration",
    {} as Record<string, string[]>,
  );
  const { environmentType } = useEnvironment();
  const customerId = useRequiredParam("customerId");

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >();
  const [usageFilter, setUsageFilter] = useState<OptionType[]>([
    usageFilterOptions.usage_filter.options[2],
  ]);

  const { data: metricsData, loading: planQueryLoading } =
    useListMetricsOnCustomerPlansQuery({
      variables: {
        customer_id: customerId,
        environment_type: environmentType,
        start_date: selectedDateRange?.inclusiveStart.toISOString() ?? "",
        end_date: selectedDateRange?.exclusiveEnd.toISOString() ?? "",
      },
      skip: !selectedDateRange,
    });

  const contractBillableMetrics = contractMetricsToBillableMetrics(metricsData);
  const planMetrics = customerPlansToPlanMetrics(metricsData);

  const allBillableMetrics =
    metricsData?.billable_metrics.map((bm) => bm.id) ?? [];
  const allSeatMetrics =
    metricsData?.seat_metrics.metrics.map((sm) => sm.id) ?? [];

  const bmInfo = Object.fromEntries(
    metricsData?.billable_metrics.map((bm) => [bm.id, bm]) ?? [],
  );

  const planAndContractBillableMetrics = removeEmpty([
    ...(planMetrics.billable_metrics ?? []),
    ...(contractBillableMetrics ?? []),
  ]);
  const planSeatMetrics = planMetrics.seat_metrics ?? [];

  const hasPlanOrContractMetrics = planAndContractBillableMetrics.length > 0;

  // Default usage filter to "Assigned to contract", and reset to "All" if
  // it turns out there are no assigned metrics. This is better than the other
  // way around since it prevents fetching all metrics before the usage filter
  // is updated.
  useEffect(() => {
    if (
      !planQueryLoading &&
      selectedDateRange != null &&
      !hasPlanOrContractMetrics
    ) {
      setUsageFilter([usageFilterOptions.usage_filter.options[0]]);
    }
  }, [hasPlanOrContractMetrics, planQueryLoading, selectedDateRange]);

  const fetchAllMetrics = ["all", "non_zero_usage"].includes(
    usageFilter[0].value,
  );

  const billableMetricsToFetch = useMemo(
    () =>
      Array.from(
        new Set(
          fetchAllMetrics ? allBillableMetrics : planAndContractBillableMetrics,
        ),
      )
        // filter out compound metrics because they don't support daily granularity
        .filter(
          (bmId) =>
            bmId && bmInfo[bmId] && compoundMetrics && !compoundMetrics[bmId],
        )
        .map((bmId) => bmInfo[bmId]),
    [fetchAllMetrics, metricsData],
  );

  const seatMetricsToFetch = useMemo(
    () =>
      Array.from(new Set(fetchAllMetrics ? allSeatMetrics : planSeatMetrics)),
    [fetchAllMetrics, metricsData],
  );

  const { bmData, seatMetricsData, metricsLoading, error, refetch } =
    useMetricUsageLoader(
      customerId,
      billableMetricsToFetch,
      seatMetricsToFetch,
      selectedDateRange,
    );

  const loading = planQueryLoading || metricsLoading;

  // Refetch usage every 60 seconds. This is so changes show up in
  // pseudo-realtime, enabling (at the very least) better demos.
  useEffect(() => {
    const interval = setInterval(refetch, 60000);
    return () => clearInterval(interval);
  }, []);

  // Because we load the usage and metrics as part of one query, as the user changes the start/end
  // date, we "refetch" all the metrics. This causes the UI to revert back into the skeleton loading
  // state. In order to make this less disruptive for the user, we store a "high water mark" of the
  // number of metrics we're displaying, so we can show the correct number of skeleton graphs instead
  // of just reverting back to 1 as you change the date range
  const [numberOfMetrics, setNumberOfMetrics] = useState(0);
  useEffect(() => {
    setNumberOfMetrics(
      Math.max(
        numberOfMetrics,
        billableMetricsToFetch.length + seatMetricsToFetch.length,
      ),
    );
  }, [billableMetricsToFetch, seatMetricsToFetch]);

  const filteredSeatMetrics =
    usageFilter[0].value === "non_zero_usage"
      ? seatMetricsData.filter((m) =>
          // NOTE: This is a paginated API but this query seems capable of returning at
          // least a year's worth of daily metrics so we probably don't need to support
          // pagination for now.
          m.usage.data.some((d) => parseInt(d.value) !== 0),
        )
      : seatMetricsData;

  const graphProps: MetricsGraphProps[] = [];
  for (const bm of billableMetricsToFetch) {
    const metric = bmData[bmKey(bm.id, selectedDateRange)];
    if (!metric) {
      graphProps.push({
        key: bm.id,
        metricId: bm.id,
        title: bm.name,
        data: [],
        loading: true,
      });
      continue;
    }
    if (
      usageFilter[0].value === "non_zero_usage" &&
      metric.usage_breakdown.data.every((d) => Number(d.value) === 0)
    ) {
      continue;
    }
    graphProps.push({
      key: metric.id,
      metricId: metric.id,
      title: metric.name,
      data: metric.usage_breakdown.data.map((d) => ({
        start_date: new Date(d.starting_on),
        value: Number(d.value),
      })),
    });
  }
  for (const metric of filteredSeatMetrics) {
    graphProps.push({
      key: metric.id,
      metricId: metric.id,
      title: metric.name,
      data: metric.usage.data.map((d) => ({
        start_date: new Date(d.inclusive_start_date),
        value: Number(d.value),
      })),
    });
  }
  graphProps.sort((a, b) => a.title.localeCompare(b.title));

  const newServicePeriodRefMarker = (
    metricsData?.Customer_by_pk?.invoices.invoices ?? []
  )
    .filter(filterInvoiceByType("ArrearsInvoice"))
    .map((i) => ({
      date: new Date(i.inclusive_start_date),
      label: "New service period",
    }));

  return (
    <>
      <DeprecatedPageHeader
        title="Usage"
        type="secondary"
        action={
          <div className="flex flex-row items-center gap-12">
            <DeprecatedFilter
              options={usageFilterOptions}
              value={usageFilter}
              onChange={setUsageFilter}
              className="w-[250px] py-[6px] [&>div.mr-8]:text-deprecated-gray-900 [&>span]:text-deprecated-gray-900"
              showCurrentValue={!loading}
            />
            <DeprecatedRelativeDateRangeSelector
              defaultValue="30d"
              onChange={setSelectedDateRange}
              utc
            />
          </div>
        }
      />
      <div className={styles.container}>
        {loading ? (
          [...Array(Math.max(1, numberOfMetrics))].map((_, i) => (
            <div key={i} className={styles.metric}>
              <DeprecatedTextSkeleton />
              <div className={styles.chart}>
                <DeprecatedGraph key={i} loading />
              </div>
            </div>
          ))
        ) : error ? (
          <div className="my-32">
            <EmptyState
              icon="barLineChart"
              mainText="We ran into an issue loading usage"
              supportingText="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
            />
          </div>
        ) : graphProps.length === 0 ? (
          <div className="my-32">
            <EmptyState
              icon="barChart08"
              mainText="No usage found. Try changing the filter or date range."
              supportingText=""
            />
          </div>
        ) : (
          graphProps.map((props) => (
            <MetricsGraph
              {...props}
              referenceMarkers={newServicePeriodRefMarker}
              fullWidth={graphProps.length <= 2}
            />
          ))
        )}
      </div>
    </>
  );
};

type MetricsGraphProps = {
  key?: string;
  metricId: string;
  title: string;
  data: Array<{
    start_date: Date;
    value: number | null;
  }>;
  referenceMarkers?: Array<{
    date: Date;
    label: string;
  }>;
  fullWidth?: boolean;
  loading?: boolean;
};

const MetricsGraph: React.FC<MetricsGraphProps> = (props) => {
  return (
    <div
      key={props.metricId}
      className={classnames(styles.metric, {
        [styles.fullWidthMetric]: props.fullWidth,
      })}
    >
      <Subtitle level={1}>{props.title}</Subtitle>
      <div className={styles.chart}>
        <DeprecatedGraph
          loading={props.loading}
          referenceMarkers={props.referenceMarkers}
          lines={
            props.loading
              ? []
              : [
                  {
                    name: props.title,
                    color: idToGraphColor(props.metricId || ""),
                    data: (props.data || [])
                      .map((d) => ({
                        date: d.start_date,
                        value: Number(d.value),
                      }))
                      .filter(({ date }) => isBefore(date, new Date())),
                  },
                ]
          }
          isUTC
        />
      </div>
    </div>
  );
};

// YOU PROBABLY DON'T WANT TO USE THIS COMPONENT DIRECTLY
// This component is a thin wrapper around react-datepicker that injects our styles. It's used
// in higher level components like DateInput and RelativeDateRangeSelector. In almost all cases you should
// be using those components instead of this one.

import React from "react";
import formatDate from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import styles from "./index.module.less";

import { Subtitle } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { twMerge } from "tailwind-merge";

type StyledDatePickerProps = Omit<
  ReactDatePickerProps,
  "weekDayClassName" | "dayClassName" | "formatWeekDay" | "renderCustomHeader"
>;

export const DeprecatedStyledDatePicker: React.FC<StyledDatePickerProps> = (
  props,
) => {
  return (
    <div className={twMerge("oldDatePicker", styles.datepicker)}>
      <ReactDatePicker
        {...props}
        weekDayClassName={() => styles.weekdayLabel}
        dayClassName={() => styles.day}
        formatWeekDay={(weekDay) => weekDay.slice(0, 1)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          changeMonth,
          changeYear,
        }) => (
          <div className={styles.header}>
            <Subtitle level={3}>{formatDate(date, "MMMM yyyy")}</Subtitle>
            <div className={styles.monthButtons}>
              <IconButton
                className={styles.monthButton}
                onClick={decreaseMonth}
                theme="tertiary"
                icon="chevronLeft"
                size="sm"
              />
              <Button
                className={styles.monthButton}
                onClick={() => {
                  changeMonth(getMonth(new Date()));
                  changeYear(getYear(new Date()));
                }}
                text="•"
                theme="linkGray"
                size="sm"
              />
              <IconButton
                className={styles.monthButton}
                onClick={increaseMonth}
                theme="tertiary"
                icon="chevronRight"
                size="sm"
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

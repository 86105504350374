import React, { useState } from "react";
import { Popover } from "react-tiny-popover-next";
import { DropdownItem } from "components/Dropdown";
import { Icon } from "components/Icon";
import { TagsInput } from "components/Input/TagsInput";

type EventTypeInputDropdownProps = {
  value: string[];
  onChange: (value: string[]) => void;
  filterType: "in" | "not_in" | "accept_all";
  onFilterTypeChange: (filterType: "in" | "not_in" | "accept_all") => void;
  placeholder?: string;
  disabled?: boolean;
};

export const EventTypeInputDropdown: React.FC<EventTypeInputDropdownProps> = ({
  value,
  onChange,
  filterType,
  onFilterTypeChange,
  placeholder,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterTypeText = {
    in: "In",
    not_in: "Not in",
    accept_all: "Accept all (not filtering)",
  };

  return (
    <div className="w-full">
      <Popover
        positions={["bottom"]}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        align="end"
        content={
          <div className="mt-[5px] max-h-[450px] min-h-[50px] overflow-y-scroll rounded-md border border-gray-200 bg-white shadow-lg">
            <div className="flex flex-col overflow-y-auto">
              {["in", "not_in", "accept_all"].map((type) => (
                <DropdownItem
                  key={type}
                  value={type}
                  label={filterTypeText[type as keyof typeof filterTypeText]}
                  selected={filterType === type}
                  onClick={() => {
                    onFilterTypeChange(type as "in" | "not_in" | "accept_all");
                    setIsOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        }
      >
        <div>
          <label className="mb-sm text-sm text-black">Event type</label>
          <div className="relative">
            <div>
              <TagsInput
                value={value}
                onChange={({ value }) => onChange(value)}
                placeholder={placeholder}
                disabled={disabled || filterType === "accept_all"}
                fullWidth
              />
            </div>
            <div
              className="px-3 absolute inset-y-0 right-[10px] flex cursor-pointer items-center"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span className="mr-8">{filterTypeText[filterType]}</span>
              <Icon
                icon={isOpen ? "chevronUp" : "chevronDown"}
                size={16}
                className="text-gray-500"
              />
            </div>
          </div>
          <div className="mt-sm text-sm text-gray-600">
            This is how Metronome will filter events
          </div>
        </div>
      </Popover>
    </div>
  );
};

EventTypeInputDropdown.displayName = "EventTypeInputDropdown";

import React from "react";
import { Tooltip } from "../Tooltip";
import { Icon } from "../Icon";
import { Badge } from "../Badge";
import { Chart } from "../Chart";
import { CardContext } from "./CardContext";
import { twMerge } from "twMerge";
import { LoadingBlob } from "../LoadingBlob";
import Decimal from "decimal.js";
import { RoundedCurrency } from "app/lib/credits";
import { CreditType } from "app/types/credit-types";

function metricIcon(deltaType: MetricDeltaType, deltaPercentValue: Decimal) {
  if (deltaPercentValue.eq(0)) {
    return "arrowRight";
  }

  if (deltaType === "arrow") {
    return deltaPercentValue.lt(0) ? "arrowDown" : "arrowUp";
  } else {
    return deltaPercentValue.lt(0) ? "trendDown01" : "trendUp01";
  }
}

interface CardProps {
  /** Default: bottom - If a Chart is present, this will orient the position of the Chart. */
  chartPosition?: "bottom" | "end";
  /**
   * Default: full - If a Chart is present, this will resize the Chart appropriately;
   *  mini on the end or bottom and full on the bottom  */
  chartSize?: "mini" | "full";
  /** Children should always be of Card.Chart */
  children?: React.ReactNode;
  /** An optional array of metrics to render in the Card, with label, value, tooltip and more */
  metrics?: MetricProps[];
  /** If provided, the title will render at the top of the card, left of any actions */
  title?: React.ReactNode;
  /** Default: true - If set to false, the card will be full width of the container */
  wrapContents?: boolean;
  /** If provided, a header will be added to the card with actions */
  headerActions?: React.ReactNode[];
  /** If provided, a footer will be added to the card with actions */
  footerActions?: React.ReactNode[];
  /** Show loading animation in place of content */
  loading?: boolean;
  /**
   * Render a Card with a green gradient background and white text to highlight important information
   * on a page
   * */
  variant?: "overview";
  /** Allow the contents of the card to fill the height of the parent container */
  fullHeight?: boolean;
  /**
   * If provided, adds custom class name to the card.
   * This is mostly useful for things like setting a max-width on the card.
   */
  className?: string;
}

interface CardSubComponents {
  /** The Chart component will act as a sub-component for the Card */
  Chart: typeof Chart;
}

type MetricDeltaType = "arrow" | "trend";
type MetricProps = {
  /** The label for Metric, rendered top / left of the Metric */
  label?: React.ReactNode;
  /** The Metric value, a number expressed in a Decimal.js Decimal type. If undefined, renders "--" */
  value: Decimal | undefined;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Optional context for a given Metric, this will render a small icon that will show a Tooltip on hover */
  tooltipContent?: string;
  /** Optional context for a given Metric Delta, this will render a Tooltip with a main label on hover for the Badge.*/
  badgeTooltipLabel?: string;
  /** Optional context for a given Metric Delta, this will render a Tooltip with a sub label on hover for the Badge.*/
  badgeTooltipSubLabel?: string;
  /** Optional className for the value, to style the value differently */
  valueClassName?: string;
  /** Optional - if provided, the Metric will treat the value as a currency and render it as such */
  creditType?: CreditType;
} & (
  | {}
  | {
      /** Optionally render a positive or negative change in the value, styled with success or error respectively */
      deltaType: MetricDeltaType;
      /** Default - gray; Choose one of 3 color options for the Metric delta Badge */
      deltaColor: "success" | "error" | "gray";
      /** Optional value change to be rendered in a Badge */
      deltaPercentValue: Decimal | null;
      /** Label that renders before the deltaPercentValue itself */
      deltaLabel?: React.ReactNode;
    }
);

export const Metric: React.FC<MetricProps> = (props) => {
  const {
    value,
    label,
    className,
    tooltipContent,
    badgeTooltipLabel,
    badgeTooltipSubLabel,
    creditType,
  } = props;
  return (
    <div className={className}>
      {label && (
        <div
          className={twMerge(
            "mb-md",
            tooltipContent && "flex items-center gap-x-xs",
          )}
        >
          <div className="flex items-center gap-x-xs text-sm font-medium text-gray-600">
            {label}
          </div>
          {tooltipContent && (
            <Tooltip label={tooltipContent}>
              <Icon icon="helpCircle" size={14} />
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex items-center">
        <h4
          className={`text-display-sm ${twMerge("font-semibold text-core-slate", props.valueClassName)}`}
        >
          {value !== undefined ? (
            // if the value exists, render it with the appropriate formatting
            creditType ? (
              <RoundedCurrency
                amount={value}
                creditType={creditType}
                hideSuffix
                abbreviateLargeNumbers
              />
            ) : (
              value.toNumber().toLocaleString()
            )
          ) : (
            // when the value doesn't exist, render --
            "--"
          )}
        </h4>
        {"deltaType" in props ? (
          <Badge
            className="ml-xl"
            label={
              <div className="flex items-center">
                {props.deltaLabel}
                {props.deltaPercentValue == null
                  ? "--"
                  : props.deltaPercentValue
                      .toDecimalPlaces(2)
                      .toNumber()
                      .toLocaleString() + "%"}
              </div>
            }
            theme={props.deltaColor}
            icon={
              props.deltaPercentValue == null
                ? undefined
                : metricIcon(props.deltaType, props.deltaPercentValue)
            }
            tooltipLabel={badgeTooltipLabel}
            tooltipSubLabel={badgeTooltipSubLabel}
          />
        ) : null}
      </div>
    </div>
  );
};

/**
 * !! PARTIAL IMPLEMENTATION !!
 *  Cards are useful for presenting key data points or numbers to users.
 *  They’re commonly used in dashboards to quickly summarise changes in data.
 */
export const Card: React.FC<CardProps> & CardSubComponents = ({
  children,
  title,
  metrics,
  wrapContents = true,
  headerActions,
  footerActions,
  loading,
  variant,
  fullHeight,
  className,
}) => {
  return (
    <div
      className={twMerge(
        wrapContents ? "inline-block" : "block",
        variant === "overview" && "flex",
        fullHeight && "h-full",
        className,
      )}
    >
      {(title || headerActions) && (
        <div className="flex items-center rounded-t-xl border border-b-0 border-gray-200 px-xl py-lg text-md font-semibold text-black">
          {title && <div className="flex flex-grow items-center">{title}</div>}
          {headerActions && (
            <div className="flex flex-row-reverse justify-between gap-x-lg self-stretch">
              {headerActions.map((action, i) => (
                <div className="self-center" key={i}>
                  {action}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div
        className={twMerge(
          "border border-gray-200 px-xl py-3xl",
          variant === "overview" &&
            "bg-gradient-to-tr from-core-jade-green to-core-dark-green text-core-light-mint",
          !title && "rounded-xl",
          footerActions ? "rounded-b-none" : "rounded-b-xl shadow-xs",
          fullHeight && (title ? "h-[calc(100%_-_50px)]" : "h-full"), // 50px is the height of the title bar
        )}
      >
        {metrics && (
          <div className="mb-lg flex flex-wrap gap-x-5xl gap-y-md">
            {metrics.map((metric, idx) => {
              if (loading) {
                return <LoadingBlob key={idx} />;
              }
              return <Metric key={idx} {...metric} />;
            })}
          </div>
        )}
        {/* Leaving in placeholder for CardContext; will come into play for
        next Card/Chart changes. See GET-4811 for more */}
        <CardContext.Provider value={1322}>
          {loading ? <LoadingBlob className="h-2xl w-[150px]" /> : children}
        </CardContext.Provider>
      </div>
      {footerActions && variant !== "overview" && (
        <div className="flex flex-col items-center self-stretch rounded-b-xl border border-t-0 border-gray-200">
          <div className="flex flex-row-reverse justify-between self-stretch p-xl">
            {footerActions.map((action, i) => (
              <div className="self-center" key={i}>
                {action}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Card.Chart = Chart;

Card.displayName = "Card";

import React, { useState } from "react";
import { Label, NumericInput, Select, Toggle } from "design-system";

import type { CreateContractCtrl } from "../../ContractCreate";
import { Section } from "../../components/Section";
import {
  ContractIntegrations,
  getIntegrationsDescription,
} from "../ContractIntegrations";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useGetBillableMetricsQuery } from "./data.graphql";
import {
  BillingProviderEnum_Enum,
  ContractScheduledChargesOnUsageInvoices,
} from "types/generated-graphql/__types__";
import { Dropdown, DropdownHeader, DropdownItem } from "components/Dropdown";
import {
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import {
  AWSMarketplaceCustomerSettings,
  AzureMarketplaceCustomerSettings,
  billingProviderToName,
  CustomerIntegrationSettingsInfo,
  MultiBPCustomerSettings,
  parseIntegrationCustomerSettings,
  StripeCustomerSettings,
} from "app/lib/billingProvider/billingProviderSettings";
import AWSMarketplaceSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/AWSMarketplaceSettingsModal";
import AzureMarketplaceSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/AzureMarketplaceSettingsModal";
import { Tooltip } from "components/Tooltip";
import { stripeCanBeSetUp } from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider";
import StripeSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/StripeSettingsModal";

interface Props {
  ctrl: CreateContractCtrl;
  customerID: string;
  options?: {
    salesforceEnabled?: boolean;
    netsuiteEnabled?: boolean;
    scheduledChargesOnUsageInvoicesEnabled?: boolean;
  };
}

type NewBillingProviderConfigOptionProps = {
  label: string;
  value: BillingProviderEnum_Enum;
  onAddNew: () => void;
};

export function onConnectAzure(
  props: Props,
  azureSettingsForContracts: AzureMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  },
) {
  props.ctrl.get("billingProviderConfiguration")
    ?.billing_provider_configuration_id ===
  azureSettingsForContracts.customerBillingProviderConfigurationID
    ? props.ctrl.update({
        billingProvider: undefined,
        billingProviderConfiguration: undefined,
      })
    : props.ctrl.update({
        billingProvider: BillingProviderEnum_Enum.Azure,
        billingProviderConfiguration: {
          billing_provider_configuration_id:
            azureSettingsForContracts.customerBillingProviderConfigurationID,
          configuration: {
            azure_subscription_id: azureSettingsForContracts.subscriptionId,
          },
        },
      });
}

export function onConnectAWS(
  props: Props,
  awsSettingsForContracts: AWSMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  },
) {
  props.ctrl.get("billingProviderConfiguration")
    ?.billing_provider_configuration_id ===
  awsSettingsForContracts.customerBillingProviderConfigurationID
    ? props.ctrl.update({
        billingProvider: undefined,
        billingProviderConfiguration: undefined,
      })
    : props.ctrl.update({
        billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
        billingProviderConfiguration: {
          billing_provider_configuration_id:
            awsSettingsForContracts.customerBillingProviderConfigurationID,
          configuration: {
            aws_customer_id: awsSettingsForContracts.customerId,
            aws_product_code: awsSettingsForContracts.productCode,
            aws_region: awsSettingsForContracts.region,
            aws_is_subscription_product:
              awsSettingsForContracts.isSubscriptionProduct,
          },
        },
      });
}

export const ContractBillingConfigurationSection: React.FC<Props> = (props) => {
  const { environmentType } = useEnvironment();
  const [tags, setTags] = useState<Array<string>>([]);
  const [tagSearch, setTagSearch] = useState("");

  const { data: billableMetricsData, loading: billableMetricsLoading } =
    useGetBillableMetricsQuery({
      variables: {
        environment_type: environmentType,
      },
    });
  const groupKeys = Array.from(
    new Set(
      billableMetricsData?.billable_metrics.flatMap((bm) => {
        const keys = bm.group_keys as Array<string | string[]>;
        return keys?.flat();
      }),
    ),
  ).filter((key) => key);

  const {
    salesforceEnabled,
    netsuiteEnabled,
    scheduledChargesOnUsageInvoicesEnabled,
  } = props.options ?? {};

  const { data: clientConfig } = useGetClientConfigQuery();
  const clientHasDeltaStreamEnabled =
    clientConfig?.is_delta_stream_enabled ?? false;

  const {
    data: billingProviderSettingsData,
    loading: billingProviderSettingsLoading,
  } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: props.customerID,
      environment_type: environmentType,
    },
  });

  let billingProviderSettings: CustomerIntegrationSettingsInfo | undefined;
  let stripeMultiSettings: MultiBPCustomerSettings<StripeCustomerSettings> = [];

  if (billingProviderSettingsData) {
    billingProviderSettings = parseIntegrationCustomerSettings(
      billingProviderSettingsData,
    );
    stripeMultiSettings = billingProviderSettings?.stripeSettings ?? [];
  }

  const stripeContractsSettings = stripeMultiSettings.filter(
    (s) => !!s.customerBillingProviderConfigurationID,
  );

  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
    useState(false);
  const [
    azureMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
  ] = useState(false);
  const [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false);

  const azureMultiSettings = billingProviderSettings?.azureMultiSettings ?? [];
  const awsMultiSettings = billingProviderSettings?.awsMultiSettings ?? [];

  // NOTE: if an integration is connected to a contract, there's a customerBillingProviderConfigurationID for it
  const existingBillingProviderConfigOptions = [
    // NOTE: stripe doesn't support multiple configs per customer, but marketplaces do.
    // We're still doing this in case that changes for Stripe/for consistency
    ...azureMultiSettings.map((azureSettingsForContracts) => ({
      label: `Azure`,
      subtext: `Subscription ID: ${azureSettingsForContracts.subscriptionId}`,
      value:
        azureSettingsForContracts.customerBillingProviderConfigurationID ??
        BillingProviderEnum_Enum.Azure,
      isConnected: true,
      onConnectExisting: () => {
        onConnectAzure(props, azureSettingsForContracts);
      },
    })),
    ...awsMultiSettings.map((awsSettingsForContracts) => ({
      label: `AWS`,
      subtext: `Customer ID: ${awsSettingsForContracts.customerId}, Product Code: ${awsSettingsForContracts.productCode}, Region: ${awsSettingsForContracts.region}`,
      value:
        awsSettingsForContracts.customerBillingProviderConfigurationID ??
        BillingProviderEnum_Enum.AwsMarketplace,
      isConnected: true,
      onConnectExisting: () => {
        onConnectAWS(props, awsSettingsForContracts);
      },
    })),
    ...stripeContractsSettings?.map((stripeSettingsForContracts) => ({
      label: `Stripe`,
      subtext: `Customer ID: ${stripeSettingsForContracts.customerId}, Collection Method: ${stripeSettingsForContracts.collectionMethod}`,
      value:
        stripeSettingsForContracts.customerBillingProviderConfigurationID ??
        BillingProviderEnum_Enum.Stripe,
      isConnected: true,
      onConnectExisting: () => {
        props.ctrl.update({
          billingProvider: BillingProviderEnum_Enum.Stripe,
          billingProviderConfiguration: {
            billing_provider_configuration_id:
              stripeSettingsForContracts.customerBillingProviderConfigurationID,
            configuration: {
              stripe_customer_id: stripeSettingsForContracts.customerId,
              stripe_collection_method:
                stripeSettingsForContracts.collectionMethod,
            },
          },
        });
      },
    })),
  ];

  const newBillingProviderConfigOptions = [
    // AWS and Azure Marketplaces are always available to connect to new
    // contracts via multi-bp-on-contracts functionality, provided they are
    // set up at the client level
    billingProviderSettings?.clientHasAzure
      ? {
          label: `Azure`,
          value: BillingProviderEnum_Enum.Azure,
          onAddNew: () => {
            setAzureMarketplaceSettingsModalOpen(true);
          },
        }
      : undefined,
    billingProviderSettings?.clientHasAws
      ? {
          label: `AWS`,
          value: BillingProviderEnum_Enum.AwsMarketplace,
          onAddNew: () => {
            setAWSMarketplaceSettingsModalOpen(true);
          },
        }
      : undefined,
    stripeCanBeSetUp(billingProviderSettings)
      ? {
          label: "Stripe",
          value: BillingProviderEnum_Enum.Stripe,
          onAddNew: () => {
            setStripeSettingsModalOpen(true);
          },
        }
      : undefined,
    ,
  ].filter(
    (
      v: NewBillingProviderConfigOptionProps | undefined,
    ): v is NewBillingProviderConfigOptionProps => !!v,
  );

  const loading = billableMetricsLoading || billingProviderSettingsLoading;

  const selectedBillingProvider = props.ctrl.get("billingProvider");

  const noNewOrExistingBillingProviders =
    !existingBillingProviderConfigOptions?.length &&
    !newBillingProviderConfigOptions?.length;
  const wrapNoNewOrExistingBillingProvidersTooltip = (
    children: React.ReactNode,
  ) => {
    return noNewOrExistingBillingProviders ? (
      <Tooltip label="No billing providers available. Set up billing providers in Connections > Integrations">
        {children}
      </Tooltip>
    ) : (
      children
    );
  };

  return (
    <Section
      title="Billing configuration"
      className="flex max-w-[1000px] flex-col gap-24 py-12"
      description="Set up billing and invoicing terms."
    >
      {scheduledChargesOnUsageInvoicesEnabled && (
        <>
          <div className="text-gray-700 -mb-12 text-sm">
            <span className="font-medium">
              Consolidate scheduled and usage charges
            </span>
          </div>
          <Toggle
            {...props.ctrl.props.Toggle("scheduledChargesOnUsageInvoices", {
              label:
                "When checked, scheduled charges will appear on the usage invoice if issued on the same day.",
            })}
            onChange={(e) => {
              if (e) {
                props.ctrl.update({
                  scheduledChargesOnUsageInvoices:
                    ContractScheduledChargesOnUsageInvoices.All,
                });
              } else {
                props.ctrl.update({
                  scheduledChargesOnUsageInvoices: undefined,
                });
              }
            }}
          />
        </>
      )}

      <div className="text-gray-700 -mb-12 text-sm">
        <span className="font-medium">
          Net payment terms and billing provider:
        </span>{" "}
        Set how long a customer has to remit payment and where to invoice.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <NumericInput
          {...props.ctrl.props.NumericInput("netPaymentTermsDays", {
            placeholder: "30 days",
            name: "Net payment terms (optional)",
          })}
        />
        {!clientHasDeltaStreamEnabled &&
          wrapNoNewOrExistingBillingProvidersTooltip(
            <div className="flex flex-col">
              <Label>Billing provider (optional)</Label>
              <Dropdown
                label={
                  (selectedBillingProvider &&
                    billingProviderToName[selectedBillingProvider]) ||
                  "Connect a billing provider"
                }
                buttonSize="sm"
                disabled={noNewOrExistingBillingProviders}
              >
                {existingBillingProviderConfigOptions?.length ? (
                  <DropdownHeader text="Connected billing provider" />
                ) : undefined}
                {existingBillingProviderConfigOptions
                  .filter(
                    (config) => "isConnected" in config && config.isConnected,
                  )
                  .map(
                    ({ label, value, subtext, onConnectExisting }, index) => (
                      <DropdownItem
                        key={"connected-bp-" + index}
                        label={label}
                        subtext={subtext}
                        value={value}
                        selected={
                          (props.ctrl.get("billingProviderConfiguration")
                            ?.billing_provider_configuration_id ??
                            props.ctrl.get("billingProvider")) === value
                        }
                        onClick={onConnectExisting}
                      />
                    ),
                  )}

                {newBillingProviderConfigOptions?.length ? (
                  <DropdownHeader text="Add new billing provider" />
                ) : undefined}
                {newBillingProviderConfigOptions.map(
                  ({ label, value, onAddNew }, index) => (
                    <DropdownItem
                      key={"new-bp-" + index}
                      label={label}
                      value={value}
                      onClick={onAddNew}
                    />
                  ),
                )}
              </Dropdown>
            </div>,
          )}
      </div>

      {/* Setup Integration Modals */}
      {awsMarketplaceSettingsModalOpen && (
        <AWSMarketplaceSettingsModal
          onClose={(result) => {
            setAWSMarketplaceSettingsModalOpen(false);
            result?.isSuccess &&
              props.ctrl.update({
                billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
              });
          }}
          plansAndOrContracts="contracts_only"
          customerId={props.customerID}
        />
      )}

      {azureMarketplaceSettingsModalOpen && (
        <AzureMarketplaceSettingsModal
          onClose={(result) => {
            setAzureMarketplaceSettingsModalOpen(false);
            result?.isSuccess &&
              props.ctrl.update({
                billingProvider: BillingProviderEnum_Enum.Azure,
              });
          }}
          plansAndOrContracts="contracts_only"
          customerID={props.customerID}
        />
      )}

      {stripeSettingsModalOpen && (
        <StripeSettingsModal
          onClose={(_) => {
            setStripeSettingsModalOpen(false);
          }}
          edit={false}
          customerId={props.customerID}
          stripeCustomerID=""
          stripeCollectionMethod=""
          hasBillingProviderOnPlan={
            !!billingProviderSettings?.hasBillingProviderOnPlan
          }
          hasStripeOnPlan={
            !!billingProviderSettings?.hasStripeBillingProviderCustomer
          }
          hasStripeOnContract={
            !!billingProviderSettings?.hasStripeBillingProviderConfiguration
          }
          hasStripeBillingProviderToken={
            !!billingProviderSettings?.hasStripeBillingProviderToken
          }
          hasStripeBillingProviderDeliveryMethod={
            !!billingProviderSettings?.hasStripeBillingProviderDeliveryMethod
          }
        />
      )}

      {(salesforceEnabled || netsuiteEnabled) && (
        <>
          <div className="text-gray-700 -mb-12 text-sm">
            <span className="font-medium">Integrations:</span>{" "}
            {getIntegrationsDescription({ salesforceEnabled, netsuiteEnabled })}
          </div>

          <div className="grid grid-cols-3 gap-12">
            <ContractIntegrations
              ctrl={props.ctrl}
              options={{
                salesforceEnabled,
                netsuiteEnabled,
              }}
            />
          </div>
        </>
      )}

      <div className="text-gray-700 -mb-12 text-sm">
        <span className="font-medium">Usage filter (optional):</span> For
        customers with overlapping contracts, you can determine how to route
        usage to the correct contract by selecting a group key/value pair.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <Select
          {...props.ctrl.props.Select("usageFilterGroupKey", {
            placeholder: "",
            name: "Group key",
            loading,
            disabled: loading,
            menuPlacement: "top",
            options: [
              {
                label: "None",
                value: "",
              },
            ].concat(
              groupKeys.map((k) => ({
                label: k,
                value: k,
              })),
            ),
          })}
        />
        <Select
          __internalComponentOverrides={{
            DropdownIndicator: () => null,
            Menu: () => null,
          }}
          placeholder="Type a group value and press enter"
          name="Group values"
          className="col-span-2"
          disabled={!props.ctrl.get("usageFilterGroupKey")}
          options={tags
            .map((t) => ({ label: t, value: t }))
            .concat(tagSearch ? [{ label: tagSearch, value: tagSearch }] : [])
            .concat(
              props.ctrl
                .get("usageFilterGroupValues")
                ?.split(",")
                .filter((v) => v !== "")
                .map((v) => ({
                  label: v,
                  value: v,
                })) ?? [],
            )}
          onSearch={setTagSearch}
          value={
            tags.length > 0
              ? tags
              : props.ctrl.get("usageFilterGroupValues")?.split(",") ?? []
          }
          multiSelect
          onChange={(v) => {
            setTags(v);
            props.ctrl.update({
              usageFilterGroupValues: v.join(","),
            });
          }}
        />
      </div>
    </Section>
  );
};

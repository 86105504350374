import React, { useState } from "react";

import { useSeatMetricsQuery } from "./queries.graphql";
import { BillableMetricTypeEnum } from "types/generated-graphql/__types__";

import { AvatarWithName } from "design-system";
import { EmptyState } from "components/EmptyState";
import {
  DeprecatedFilter,
  FilterOptions,
  OptionType,
} from "components/deprecated/Filter";
import { Table } from "components/Table";
import { useActions } from "app/lib/billableMetrics/actions";
import { useNavigate } from "app/lib/useNavigate";
import { InsertBillableMetricDocument } from "app/pages/Offering/tabs/BillableMetrics/NewBillableMetric/queries.graphql";
import MetricBadge from "components/MetricBadge";
import { GatedButton } from "components/GatedButton";
import { ButtonGroup, ButtonProps } from "components/ButtonGroup";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { dayjs } from "lib/date";
import { Timestamp } from "components/Timestamp";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

interface SeatsTableProps extends React.PropsWithChildren {
  titleButtons: ButtonProps[];
}

const NUM_ROWS = 15;

export const SeatsTable: React.FC<SeatsTableProps> = (props) => {
  const navigate = useNavigate();
  const seatsEnabled = useFeatureFlag<boolean>("seats", false);

  if (!seatsEnabled) {
    navigate("/offering/billable-metrics", { replace: true });
  }

  const statusOptions: OptionType[] = [
    {
      label: "Active",
      value: BillableMetricTypeEnum.Active,
      group: "status",
      type: "multi",
    },
    {
      label: "Archived",
      value: BillableMetricTypeEnum.Archived,
      group: "status",
      type: "multi",
    },
  ];

  const filterOptions: FilterOptions = {
    status: {
      label: "Billable Metric Status",
      options: statusOptions,
    },
  };

  const [filters, setFilters] = useState<OptionType[]>([statusOptions[0]]);

  const resetFilters = () => {
    setFilters([statusOptions[0]]);
  };

  const { data, loading } = useSeatMetricsQuery({
    variables: {
      types: filters
        .filter((f) => f.group === "status")
        .map((f) => f.value as BillableMetricTypeEnum),
      limit: NUM_ROWS,
    },
  });

  const allMetrics = data?.seat_metrics?.metrics || [];

  const { getDropdownMenu, archiveModal, copyMetricModal } = useActions();

  const table = (
    <Table
      title={<ButtonGroup buttons={props.titleButtons} />}
      loading={loading}
      rowRoutePath={(row) => `/offering/billable-metrics/seats/${row.id}`}
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorFn: (m) => (
            <span>
              <MetricBadge deletedAt={m.deleted_at} />
              {m.name}
            </span>
          ),
          enableSorting: false,
        },
        {
          id: "user",
          header: "User",
          cell: (props) => props.getValue(),
          accessorFn: (m) => m.Creator && <AvatarWithName {...m.Creator} />,
          enableSorting: false,
        },
        {
          id: "last_edited",
          header: "Last edited (UTC)",
          cell: (props) => (
            <Timestamp dateTime={props.getValue()} dateOnly={true} />
          ),
          accessorFn: (m) => dayjs.utc(m.created_at).toDate(),
        },
        {
          id: "actions",
          header: "",
          cell: (props) => props.getValue(),
          accessorFn: (row) =>
            getDropdownMenu({ ...row, metricType: "billable" }),
          enableSorting: false,
        },
      ]}
      data={allMetrics}
      paginationOptions={{
        type: "clientSide",
        pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
      }}
      topBarActions={[
        <div className="flex flex-row items-center" key={0}>
          <DeprecatedFilter
            value={filters}
            options={filterOptions}
            onChange={setFilters}
            onReset={resetFilters}
          />
          <GatedButton
            doc={InsertBillableMetricDocument}
            onClick={() => navigate("/offering/billable-metrics/new")}
            className="ml-12"
            text="Add"
            theme="primary"
            leadingIcon="plus"
            size="sm"
          />
        </div>,
      ]}
      emptyState={<EmptyState mainText="No Seats" icon="barLineChart" />}
    />
  );

  return (
    <>
      {archiveModal}
      {copyMetricModal}
      {table}
    </>
  );
};

import React from "react";
import { Input, DateInput } from "design-system";

import type { CreateAmendmentCtrl } from "../AmendmentCreate";
import { Section } from "../components/Section";
import { Contract } from "app/pages/Contracts/lib/Contract";

interface Props {
  ctrl: CreateAmendmentCtrl;
  contract: Contract.NameFragment & {
    starting_at: string;
    ending_before: string | null;
  };
}

export const AmendmentDetails: React.FC<Props> = (props) => {
  return (
    <Section title="Contract details" className="flex flex-col gap-24 py-12">
      <div className="grid w-3/5 grid-cols-3 gap-12">
        <Input
          name="Contract name"
          readOnly
          value={Contract.getName(props.contract)}
          placeholder=""
          className="col-span-2"
        />
        <DateInput
          {...props.ctrl.props.DateInput("effectiveAt", {
            name: "Amendment effective at",
            tooltip:
              "Inclusive start time of the amendment. Time is always Midnight UTC.",
            minDate: props.contract.starting_at,
          })}
        />
      </div>
    </Section>
  );
};

import React, { useMemo } from "react";
import {
  DeprecatedRelativeDateRangeSelector,
  DateRange,
} from "components/deprecated/RelativeDateRangeSelector";
import { Select, Option } from "design-system";
import { IconButton } from "components/IconButton";
import { FilterState, filterToQueryString } from "../../lib/query";
import { twMerge } from "twMerge";
import { useLocation, useNavigate } from "react-router-dom";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  useSearchCustomersQuery,
  SearchCustomersQuery,
  useBillableMetricsQuery,
  CustomerByIdQueryResult,
} from "../../queries.graphql";
import Fuse from "fuse.js";
import useDebounce from "app/lib/debounce";

type RefObject = React.RefObject<{
  refetch: () => void;
}>;

interface EventFiltersProps {
  filters: FilterState;
  selectedCustomerResults: CustomerByIdQueryResult;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | null>>;
  graphRef: RefObject;
  tableRef: RefObject;
}

// When a user types in a string we want to return a list of relevant customers AND if the string doesn't match the
// ingest alias of any of those customers we want to let the user filter by that string as an ingest alias. This
// function handles taking the result back from a search and returning the relevant options.
function getCustomerOptionsFromSearchResults(
  query: string,
  data: SearchCustomersQuery,
  selectedCustomerID?: string,
): Option[] {
  const seenIngestAliases = new Set<string>();

  for (const customer of data.searchCustomers) {
    if (customer.id === selectedCustomerID) {
      continue;
    }
    for (const alias of customer.CustomerIngestAliases) {
      seenIngestAliases.add(alias.ingest_alias);
    }
  }

  return [
    ...data.searchCustomers
      .filter((customer) => customer.id !== selectedCustomerID)
      .map((c) => ({
        label: c.name,
        value: `customer_id:${c.id}`,
      }))
      .slice(0, 5),
    ...(seenIngestAliases.has(query) || !query
      ? []
      : [
          {
            label: `Customer ID: ${query}`,
            value: `ingest_alias:${query}`,
          },
        ]),
  ];
}

export const EventFilters: React.FC<EventFiltersProps> = ({
  filters,
  selectedCustomerResults,
  setDateRange,
  tableRef,
  graphRef,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { environmentType } = useEnvironment();

  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 500);

  const billableMetricData = useBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const searchCustomerResults = useSearchCustomersQuery({
    variables: {
      query: debouncedSearch,
      environment_type: environmentType,
    },
    skip: !debouncedSearch,
  });

  const bmFuse = useMemo(() => {
    return new Fuse(billableMetricData.data?.billable_metrics ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "name", weight: 1.0 },
      ],
      threshold: 0.3,
    });
  }, [billableMetricData.data?.billable_metrics]);
  // End data loading

  // This is the bare minimum set of options - It only includes options which the user already selected (thus need to be
  // shown. We'll add more options here depending on search results.
  const customerOptions: Option[] = (filters.ingestAliases || [])
    .map((alias) => ({
      label: `Customer ID: ${alias}`,
      value: `ingest_alias:${alias}`,
    }))
    .concat(
      filters.customerID
        ? [
            {
              label: selectedCustomerResults.loading
                ? search
                : selectedCustomerResults.data?.Customer_by_pk?.name ??
                  filters.customerID,
              value: `customer_id:${filters.customerID}`,
            },
          ]
        : [],
    );

  const transactionIDOptions: Option[] = (filters.transactionIDs || []).map(
    (txnID) => ({
      label: `Transaction ID: ${txnID}`,
      value: `txn_id:${txnID}`,
    }),
  );

  const billableMetricOptions: Option[] = filters.billableMetrics?.length
    ? filters.billableMetrics.map((bm) => ({
        label:
          billableMetricData.data?.billable_metrics.find((b) => b.id === bm)
            ?.name ?? "",
        value: `billable-metric:${bm}`,
      }))
    : [];

  if (!searchCustomerResults.loading) {
    if (searchCustomerResults.data) {
      customerOptions.push(
        ...getCustomerOptionsFromSearchResults(
          search,
          searchCustomerResults.data,
          filters.customerID,
        ),
      );
    }

    if (search) {
      transactionIDOptions.push({
        label: `Transaction ID: ${search}`,
        value: `txn_id:${search}`,
      });

      billableMetricOptions.push(
        ...bmFuse
          .search(search)
          .filter((bm) => !(filters.billableMetrics ?? []).includes(bm.item.id))
          .map((result) => ({
            label: result.item.name,
            value: `billable-metric:${result.item.id}`,
          })),
      );
    }
  }

  const options = [
    {
      label: "Customers",
      options: customerOptions,
    },
    {
      label: "Billable Metrics",
      options: billableMetricOptions,
    },
    {
      label: "Duplicates",
      options: [
        {
          label: "Only duplicates",
          value: "duplicates:true",
        },
        {
          label: "Only non duplicates",
          value: "duplicates:false",
        },
      ].filter((o) => {
        // If the user currently isn't filtering by duplicates then only show the options once they start
        // to type so the results don't jump around
        if (filters.duplicates === undefined) {
          return !!search;
        }
        return o.value === `duplicates:${filters.duplicates}`;
      }),
    },
    {
      label: "Transaction ID",
      options: transactionIDOptions,
    },
  ];

  return (
    <div className={twMerge("mx-0 mb-8 flex items-center gap-8")}>
      <Select
        className="w-full"
        multiSelect
        placeholder="Search by customer, duplicates, billable metric or transaction ID"
        onSearch={(v) => {
          setSearch(v);
          if (!v) {
            return;
          }
        }}
        loading={searchCustomerResults.loading}
        options={options}
        value={[
          ...(filters.billableMetrics || []).map(
            (txnID) => `billable-metric:${txnID}`,
          ),
          ...(filters.transactionIDs || []).map((txnID) => `txn_id:${txnID}`),
          ...(filters.customerID ? [`customer_id:${filters.customerID}`] : []),
          ...(filters.ingestAliases || []).map(
            (alias) => `ingest_alias:${alias}`,
          ),
          ...(typeof filters.duplicates != undefined
            ? [`duplicates:${filters.duplicates}`]
            : []),
        ]}
        onChange={(selectedOptions) => {
          let filters: FilterState = {};
          selectedOptions.forEach((option) => {
            const [part, ...rest] = option.split(":");
            const id = rest.join(":");
            if (part === "txn_id") {
              filters.transactionIDs = [id];
            } else if (part === "ingest_alias") {
              filters.ingestAliases = [id];
            } else if (part === "customer_id") {
              filters.customerID = id;
            } else if (part === "duplicates") {
              filters.duplicates = id === "true";
            } else if (part === "billable-metric") {
              filters.billableMetrics = [id];
            }
          });
          navigate(`${location.pathname}?${filterToQueryString(filters)}`, {
            replace: true,
          });
        }}
      />
      <DeprecatedRelativeDateRangeSelector
        utc={true}
        onChange={(range) => {
          setDateRange(range);
          const newFilters: FilterState = {
            ...filters,
            startingOn: range.inclusiveStart,
            endingBefore: range.exclusiveEnd,
          };
          navigate(`${location.pathname}?${filterToQueryString(newFilters)}`, {
            replace: true,
          });
        }}
        defaultValue={
          filters.startingOn && filters.endingBefore
            ? {
                inclusiveStart: filters.startingOn,
                exclusiveEnd: filters.endingBefore,
              }
            : "30d"
        }
      />
      <IconButton
        onClick={() => {
          graphRef.current?.refetch();
          tableRef.current?.refetch();
        }}
        theme="secondary"
        icon="refreshCw01"
        size="sm"
      />
    </div>
  );
};

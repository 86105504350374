import React from "react";
import { DeprecatedCopy } from "components/deprecated/Copy";

import { Icon, Tooltip } from "design-system";

type CopyableIDProps = {
  id: string;
  label: string;
  hideID?: true;
  className?: string;
};

export const DeprecatedCopyableID: React.FC<CopyableIDProps> = (props) => {
  return (
    <DeprecatedCopy text={props.id} className={props.className}>
      <button className="bg-transparent inline-flex cursor-copy appearance-none rounded-none border-none font-mono text-xs text-deprecated-gray-700 hover:text-deprecated-primary-500 hover:outline-none focus:text-deprecated-primary-500 focus:outline-none active:text-deprecated-primary-600">
        <Tooltip content={`Copy ${props.label}`}>
          <div className="inline-flex items-center text-left">
            <Icon className="mr-4" icon="copy" />
            {props.hideID ? null : props.id}
          </div>
        </Tooltip>
      </button>
    </DeprecatedCopy>
  );
};

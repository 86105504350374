import {
  ManagedEntityEnum_Enum,
  ManagedVendorEnum_Enum,
} from "types/generated-graphql/__types__";
import { EntityMapping } from "./index";

type AllowedVendorMapping = Pick<EntityMapping, "vendor" | "vendor_entity"> & {
  allowed_vendor_fields?: Array<string>; // allow freeform input if omitted (e.g. metadata)
  supported_entities: Array<ManagedEntityEnum_Enum>;
  allowed_custom_fields?: Array<string>; // allow any existing custom field if omitted (e.g. metadata)
};
export const ALLOWED_VENDOR_MAPPINGS: Record<
  ManagedVendorEnum_Enum,
  Array<AllowedVendorMapping>
> = {
  [ManagedVendorEnum_Enum.Stripe]: [
    {
      vendor: ManagedVendorEnum_Enum.Stripe,
      vendor_entity: "invoiceitem.price",
      allowed_vendor_fields: ["product"],
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
        ManagedEntityEnum_Enum.ContractProduct,
      ],
      allowed_custom_fields: ["STRIPE_PRODUCT_ID"],
    },
    {
      vendor: ManagedVendorEnum_Enum.Stripe,
      vendor_entity: "invoiceitem.metadata",
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
        ManagedEntityEnum_Enum.CreditGrant,
        ManagedEntityEnum_Enum.ContractProduct,
        ManagedEntityEnum_Enum.ContractCredit,
        ManagedEntityEnum_Enum.Commit,
      ],
    },
    {
      vendor: ManagedVendorEnum_Enum.Stripe,
      vendor_entity: "invoice.metadata",
      supported_entities: [
        ManagedEntityEnum_Enum.Customer,
        ManagedEntityEnum_Enum.Contract,
        ManagedEntityEnum_Enum.RateCard,
        // TODO(WKF-231): not yet supported
        // ManagedEntityEnum_Enum.Plan,
      ],
    },
    {
      vendor: ManagedVendorEnum_Enum.Stripe,
      vendor_entity: "invoice.custom_fields",
      supported_entities: [
        ManagedEntityEnum_Enum.CustomerPlan,
        ManagedEntityEnum_Enum.Contract,
        ManagedEntityEnum_Enum.RateCard,
      ],
    },
  ],
  // TODO(WKF-214): review and concretize these mappings
  // these are just placeholders for now
  // focusing on Stripe first for POC
  [ManagedVendorEnum_Enum.Chargebee]: [
    {
      vendor: ManagedVendorEnum_Enum.Chargebee,
      vendor_entity: "Subscription",
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
      ],
    },
  ],
  [ManagedVendorEnum_Enum.NetSuite]: [
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "SalesOrder",
      allowed_vendor_fields: ["internal_entity_id"],
      supported_entities: [ManagedEntityEnum_Enum.Customer],
    },
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "SalesOrder.Items",
      allowed_vendor_fields: [
        "internal_id",
        "credit_grant_internal_id",
        "sku_type",
      ],
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
        ManagedEntityEnum_Enum.CreditGrant,
      ],
    },
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "CreditMemo",
      allowed_vendor_fields: ["internal_entity_id"],
      supported_entities: [ManagedEntityEnum_Enum.Customer],
    },
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "CreditMemo.Items",
      allowed_vendor_fields: ["internal_id"],
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
        ManagedEntityEnum_Enum.CreditGrant,
      ],
    },
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "Invoice",
      allowed_vendor_fields: ["internal_entity_id"],
      supported_entities: [ManagedEntityEnum_Enum.Customer],
    },
    {
      vendor: ManagedVendorEnum_Enum.NetSuite,
      vendor_entity: "Invoice.Items",
      allowed_vendor_fields: ["internal_id"],
      supported_entities: [
        ManagedEntityEnum_Enum.Product,
        ManagedEntityEnum_Enum.Charge,
        ManagedEntityEnum_Enum.CreditGrant,
      ],
    },
  ],
};

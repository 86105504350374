import React from "react";

import { useEnvironment } from "app/lib/environmentSwitcher/context";

import { useCustomerByIngestAliasQuery } from "../../queries.graphql";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { Link, useLocation } from "react-router-dom";

import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { HelpCircleTooltip } from "design-system";
import { CopyableID } from "components/CopyableID";
import { Icon } from "components/Icon";
import { Tooltip } from "components/Tooltip";

export const CustomerCell: React.FC<{ ingest_alias: string }> = ({
  ingest_alias,
}) => {
  const location = useLocation();
  const { environmentType } = useEnvironment();

  const ingestAliasIsValidUUID =
    /^[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}$/i.test(
      ingest_alias,
    );

  const { data, loading } = useCustomerByIngestAliasQuery({
    variables: {
      environment_type: environmentType,
      // Hasura doesn't support casting, so it's not possible to compare a string that "might" be a UUID (like ingest_alias) against
      // a UUID column (postgres will error if the input doesn't conform to the UUID shape). As such, we check the format of the string
      // first, and if it matches the UUID format, we can use it to filter by customer ID. Otherwise, we just look for ingest alias
      // matches.
      orConditions: [
        {
          CustomerIngestAliases: {
            ingest_alias: {
              _eq: ingest_alias,
            },
          },
        },
        ...(ingestAliasIsValidUUID
          ? [
              {
                id: {
                  _eq: ingest_alias,
                },
              },
            ]
          : []),
      ],
    },
  });

  const matchedCustomer = data?.Customer?.length == 1 ? data.Customer[0] : null;

  const params = new URLSearchParams(location.search);
  if (matchedCustomer) {
    params.set("customerID", matchedCustomer.id);
  } else {
    params.set("ingestAlias", ingest_alias);
  }
  const filterLink = `${location.pathname}?${params.toString()}`;

  if (loading) {
    return <DeprecatedTextSkeleton />;
  }

  return (
    <div className="flex items-center justify-center">
      <CopyableID id={ingest_alias} label="customer ID" hideID />
      <Tooltip label="Filter by customer ID">
        <Link
          to={filterLink}
          className="cursor-pointer no-underline [&:focus]:underline [&:hover]:underline"
        >
          {matchedCustomer ? matchedCustomer.name : ingest_alias}
        </Link>
      </Tooltip>
      {matchedCustomer ? (
        <Tooltip label="View customer">
          <DeprecatedInternalLink
            className="ml-4 block"
            routePath={`/customers/${matchedCustomer.id}`}
          >
            <Icon icon="arrowNarrowUpRight" />
          </DeprecatedInternalLink>
        </Tooltip>
      ) : (
        <HelpCircleTooltip content="The customer_id sent on this event does not match a customer or ingest alias in Metronome" />
      )}
    </div>
  );
};

import React from "react";
import { twMerge } from "../../twMerge";
import { DocumentNode } from "graphql";
import { useAuthCheck } from "app/lib/useAuthCheck";
import { Tooltip } from "../Tooltip";
import { Link } from "react-router-dom";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useRoutePathMatches } from "app/lib/routes/useRoutePathMatches";

/**
 * Renders horizontal tabs used for navigation within a page
 * Note that the tabs support two forms of urls, absolute or relative
 * If relative urls are used, basePath should be provided to make the navigation work
 */
export const HorizontalTabs: React.FC<{
  tabs: Omit<TabProps, "basePath">[];
  classNames?: string;
  basePath?: string;
}> = ({ tabs, classNames, basePath }) => {
  return (
    <div
      className={twMerge(
        "flex gap-xs rounded-lg border border-gray-200 bg-gray-50 p-xs",
        classNames,
        "flex-row", // enforce horizontal layout
      )}
    >
      {tabs.map((tab, i) => (
        <Tab key={`${tab.path}-${i}`} {...tab} basePath={basePath} />
      ))}
    </div>
  );
};

export type TabProps = {
  /** The label shown on the tab */
  name: string;
  /** The path to navigate to when the tab is clicked, if this is a relative path, provide the basePath as well */
  path: string;
  /** If provided, the tab will not be shown if the user does not have access to the query */
  authDocument?: DocumentNode;
  /** If provided, the tab will not be shown if the user does not have access to the query vars in combination with the authDocument above */
  authVariables?: any;
  /** If provided, the tab will be rendered but not clickable and a tooltip will be shown */
  disabled?: boolean;
  /** The tooltip to show when the tab is disabled, defaults to "This page doesn't exist yet" if not specified */
  disabledTooltip?: string;
  /** Custom styling for the tab */
  classNames?: string;
  /** Use this if the path is a relative path, so that navigation between tabs under the same base works */
  basePath?: string;
  /** If true, the tab will only be active if the path matches exactly */
  exactMatch?: boolean;
};

const Tab: React.FC<TabProps> = ({
  name,
  path,
  authDocument,
  authVariables,
  disabled,
  disabledTooltip,
  classNames,
  basePath,
  exactMatch,
}) => {
  const { prefixUrl } = useEnvironment();
  const match = useRoutePathMatches(
    basePath ? [`/${basePath}/${path}`] : [path],
    exactMatch ?? false,
  );

  const { allowed } = useAuthCheck(authDocument, true, authVariables);

  if (!allowed) {
    return null;
  }

  if (disabled) {
    return (
      <Tooltip label={disabledTooltip ?? "This page doesn't exist yet"}>
        <div
          className={twMerge(
            "block cursor-not-allowed rounded-md bg-none p-md text-sm font-semibold text-gray-400",
            classNames,
          )}
        >
          {name}
        </div>
      </Tooltip>
    );
  }

  return (
    <Link
      to={basePath ? prefixUrl(`/${basePath}/${path}`) : prefixUrl(path)}
      className={twMerge(
        "block rounded-md p-md text-sm font-semibold text-gray-500 ring-deprecated-gray-100 focus:ring-2",
        match
          ? "bg-white text-black shadow-sm"
          : "hover:bg-white hover:text-black hover:shadow-sm",
        classNames,
      )}
    >
      {name}
    </Link>
  );
};

import React from "react";

import styles from "./index.module.less";
import { Body } from "design-system";
import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useDeleteNetSuiteCustomerIdMutation } from "./queries.graphql";

type NetSuiteConnectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
};

export const DeleteNetSuiteConnectionModal: React.FC<
  NetSuiteConnectionModalProps
> = ({ isOpen, onClose, customerId }) => {
  const pushMessage = useSnackbar();
  const [deleteNetSuiteCustomerIdMutation, { loading }] =
    useDeleteNetSuiteCustomerIdMutation();

  return (
    <DeprecatedPopup
      isOpen={isOpen}
      onRequestClose={onClose}
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={async () => {
              await deleteNetSuiteCustomerIdMutation({
                variables: {
                  customer_id: customerId,
                },
                update: (cache) => {
                  cache.evict({
                    fieldName: "CustomerConfig",
                  });
                },
              });
              pushMessage({
                content: "NetSuite customer ID deleted",
                type: "success",
              });
              onClose();
            }}
            loading={loading}
            disabled={loading}
            text="Yes, delete customer ID"
            theme="linkDestructive"
          />
        </>
      }
      title="Are you sure you want to delete this NetSuite customer ID?"
      className={styles.netSuiteConnectionModal}
    >
      <Body level={1} className={styles.description}>
        Deleting the NetSuite customer ID will prevent further uploads of
        Metronome data to NetSuite for this customer.
      </Body>
    </DeprecatedPopup>
  );
};

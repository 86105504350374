import React from "react";
import { Modal } from "components/Modal";

export interface ContractOverlapConfirmationModalProps {
  onSave: () => Promise<void>;
  onClose: () => void;
}

export const ContractOverlapConfirmationModal: React.FC<
  ContractOverlapConfirmationModalProps
> = ({ onSave, onClose }) => {
  return (
    <Modal
      showCloseButton={true}
      title="Confirm contract overlap"
      isOpen={true}
      headerLayout="horizontalLeft"
      buttonLayout="alignedRight"
      overlayClassName="z-toast bg-gray-950/[0.7]"
      modalButtons={[
        {
          key: "confirm",
          type: "button",
          props: {
            text: "Confirm",
            theme: "primary",
            onClick: async () => {
              await onSave();
              onClose();
            },
          },
        },
        {
          key: "cancel",
          type: "button",
          props: {
            text: "Cancel",
            theme: "secondary",
            onClick: () => {
              onClose();
            },
          },
        },
      ]}
      size="lg"
      onClose={onClose}
    >
      <div className="text-sm text-gray-600">
        <div>
          This contract overlaps with other existing contracts for this
          customer. Are you sure you want to proceed?
        </div>
      </div>
    </Modal>
  );
};

/* ec75a3ac397942560525d0639a3d806672d9b677
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRateCardProductsQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
}>;


export type GetRateCardProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

type ProductListItemType_CompositeProductListItem_Fragment = { __typename: 'CompositeProductListItem' };

type ProductListItemType_FixedProductListItem_Fragment = { __typename: 'FixedProductListItem' };

type ProductListItemType_ProServiceProductListItem_Fragment = { __typename: 'ProServiceProductListItem' };

type ProductListItemType_SubscriptionProductListItem_Fragment = { __typename: 'SubscriptionProductListItem' };

type ProductListItemType_UsageProductListItem_Fragment = { __typename: 'UsageProductListItem' };

export type ProductListItemTypeFragment = ProductListItemType_CompositeProductListItem_Fragment | ProductListItemType_FixedProductListItem_Fragment | ProductListItemType_ProServiceProductListItem_Fragment | ProductListItemType_SubscriptionProductListItem_Fragment | ProductListItemType_UsageProductListItem_Fragment;

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

export type GetRateScheduleQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
  startingAt?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  endingBefore?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  limit: Types.Scalars['numeric'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  selectors?: Types.InputMaybe<Array<Types.RateScheduleSelector> | Types.RateScheduleSelector>;
  truncate?: Types.InputMaybe<Types.Scalars['Boolean']>;
  tryProductOrderSort?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetRateScheduleQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, rate_schedule: { __typename?: 'RateSchedule', next_page: string | null, using_product_order: boolean, scalar_segments: Array<import("types/generated-graphql/__types__").ScalarRateScheduleSegment>, products_on_segments: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }>, credit_types_on_segments: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> } } } };

type RateScheduleTable__ProductInfo_CompositeProductListItem_Fragment = { __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type RateScheduleTable__ProductInfo_FixedProductListItem_Fragment = { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type RateScheduleTable__ProductInfo_ProServiceProductListItem_Fragment = { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type RateScheduleTable__ProductInfo_SubscriptionProductListItem_Fragment = { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type RateScheduleTable__ProductInfo_UsageProductListItem_Fragment = { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type RateScheduleTable__ProductInfoFragment = RateScheduleTable__ProductInfo_CompositeProductListItem_Fragment | RateScheduleTable__ProductInfo_FixedProductListItem_Fragment | RateScheduleTable__ProductInfo_ProServiceProductListItem_Fragment | RateScheduleTable__ProductInfo_SubscriptionProductListItem_Fragment | RateScheduleTable__ProductInfo_UsageProductListItem_Fragment;

export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ProductListItemTypeFragmentDoc = gql`
    fragment ProductListItemType on ProductListItem {
  __typename
}
    `;
export const RateScheduleTable__ProductInfoFragmentDoc = gql`
    fragment RateScheduleTable__ProductInfo on ProductListItem {
  ...ProductListItemId
  ...ProductListItemName
  ...ProductListItemType
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTypeFragmentDoc}`;
export const GetRateCardProductsDocument = gql`
    query GetRateCardProducts($rateCardId: uuid!) {
  contract_pricing {
    rate_card(id: $rateCardId) {
      id
      products {
        ...ProductListItemId
        ...ProductListItemName
        ...ProductListItemType
        ...ProductListItemTags
      }
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTypeFragmentDoc}
${ProductListItemTagsFragmentDoc}`;

/**
 * __useGetRateCardProductsQuery__
 *
 * To run a query within a React component, call `useGetRateCardProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateCardProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateCardProductsQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *   },
 * });
 */
export function useGetRateCardProductsQuery(baseOptions: Apollo.QueryHookOptions<GetRateCardProductsQuery, GetRateCardProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateCardProductsQuery, GetRateCardProductsQueryVariables>(GetRateCardProductsDocument, options);
      }
export function useGetRateCardProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateCardProductsQuery, GetRateCardProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateCardProductsQuery, GetRateCardProductsQueryVariables>(GetRateCardProductsDocument, options);
        }
export type GetRateCardProductsQueryHookResult = ReturnType<typeof useGetRateCardProductsQuery>;
export type GetRateCardProductsLazyQueryHookResult = ReturnType<typeof useGetRateCardProductsLazyQuery>;
export type GetRateCardProductsQueryResult = Apollo.QueryResult<GetRateCardProductsQuery, GetRateCardProductsQueryVariables>;
export const GetRateScheduleDocument = gql`
    query GetRateSchedule($rateCardId: uuid!, $startingAt: timestamptz, $endingBefore: timestamptz, $limit: numeric!, $cursor: String, $selectors: [RateScheduleSelector!], $truncate: Boolean, $tryProductOrderSort: Boolean) {
  contract_pricing {
    rate_card(id: $rateCardId) {
      id
      rate_schedule(
        starting_at: $startingAt
        ending_before: $endingBefore
        limit: $limit
        cursor: $cursor
        selectors: $selectors
        truncate: $truncate
        try_product_order_sort: $tryProductOrderSort
      ) {
        next_page
        using_product_order
        scalar_segments
        products_on_segments {
          ...RateScheduleTable__ProductInfo
        }
        credit_types_on_segments {
          id
          name
          client_id
          environment_type
          __environment_type: environment_type
        }
      }
    }
  }
}
    ${RateScheduleTable__ProductInfoFragmentDoc}`;

/**
 * __useGetRateScheduleQuery__
 *
 * To run a query within a React component, call `useGetRateScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateScheduleQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *      startingAt: // value for 'startingAt'
 *      endingBefore: // value for 'endingBefore'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      selectors: // value for 'selectors'
 *      truncate: // value for 'truncate'
 *      tryProductOrderSort: // value for 'tryProductOrderSort'
 *   },
 * });
 */
export function useGetRateScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetRateScheduleQuery, GetRateScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateScheduleQuery, GetRateScheduleQueryVariables>(GetRateScheduleDocument, options);
      }
export function useGetRateScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateScheduleQuery, GetRateScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateScheduleQuery, GetRateScheduleQueryVariables>(GetRateScheduleDocument, options);
        }
export type GetRateScheduleQueryHookResult = ReturnType<typeof useGetRateScheduleQuery>;
export type GetRateScheduleLazyQueryHookResult = ReturnType<typeof useGetRateScheduleLazyQuery>;
export type GetRateScheduleQueryResult = Apollo.QueryResult<GetRateScheduleQuery, GetRateScheduleQueryVariables>;
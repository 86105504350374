import React from "react";
import { Badge } from "components/Badge";

export default function MetricBadge({
  deletedAt,
}: {
  deletedAt: string | null;
}) {
  if (deletedAt !== null) {
    return <Badge label="Archived" theme="warning" className="mr-8" />;
  }

  return null;
}

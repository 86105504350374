import React from "react";
import { DeprecatedTable } from "components/deprecated/Table";
import Decimal from "decimal.js";
import classnames from "classnames";
import styles from "./index.module.less";
import { RoundedCurrency, displayCostBasis } from "app/lib/credits";
import { renderDate } from "lib/time";
import { CreditType } from "app/types/credit-types";
import { Ledger, LedgerEntry } from "../..";
import { Badge } from "design-system";
import { Caption, Sidenote } from "design-system";

type CreditLedgerDateProps = {
  ledgerEntry: LedgerEntry;
};
const CreditLedgerDate: React.FC<CreditLedgerDateProps> = ({ ledgerEntry }) => {
  const date = renderDate(
    new Date(
      !ledgerEntry.grant.voidedAt
        ? ledgerEntry.effectiveAt
        : ledgerEntry.grant.voidedAt,
    ),
    {
      isUtc: true,
      excludeUtcLabel: true,
    },
  );

  const badgeDateInfo = (caption: string) => {
    return (
      <div className={styles.ledgerBadge}>
        <Caption level={1} className={styles.ledgerBadgeCaption}>
          {caption}
        </Caption>
        &nbsp;
        <Sidenote className={styles.ledgerBadgeDate}>({date})</Sidenote>
      </div>
    );
  };

  switch (ledgerEntry.__typename) {
    case "MRI_CreditExpirationCreditLedgerEntry":
      return (
        <Badge theme="error" type="light" className={styles.expiryBadge}>
          {badgeDateInfo("Expired")}
        </Badge>
      );
    case "MRI_PendingChargeCreditLedgerEntry":
      return (
        <Badge theme="warning" type="light" className={styles.pendingBadge}>
          {badgeDateInfo("Pending")}
        </Badge>
      );
    case "MRI_PendingCreditExpirationCreditLedgerEntry":
      return (
        <Badge theme="error" type="light" className={styles.expiryBadge}>
          {badgeDateInfo("Will expire")}
        </Badge>
      );
    case "MRI_CreditGrantCreditLedgerEntry":
      if (ledgerEntry.grant.voidedAt) {
        return (
          <Badge theme="warning" type="light" className={styles.voidedBadge}>
            {badgeDateInfo("Voided")}
          </Badge>
        );
      }
      if (ledgerEntry.effectiveAt > new Date()) {
        return (
          <Badge theme="warning" type="light" className={styles.pendingBadge}>
            {badgeDateInfo("Pending")}
          </Badge>
        );
      }
      return <>{date}</>;
    case "MRI_CreditDeductionCreditLedgerEntry":
    default:
      if (ledgerEntry.effectiveAt > new Date()) {
        return (
          <Badge theme="warning" type="light" className={styles.pendingBadge}>
            {badgeDateInfo("Pending")}
          </Badge>
        );
      }
      return <>{date}</>;
  }
};

// Using <RoundedCurrency />, but formatting and styling in the accounting way
const AccountingFormattedCurrency: React.FC<{
  amount: Decimal;
  creditType: CreditType;
  voided: boolean;
}> = ({ amount, creditType, voided }) => {
  return (
    <span
      className={classnames({
        [styles.positive]: amount.greaterThan(0),
        [styles.negative]: amount.lessThan(0),
        [styles.voided]: voided,
      })}
    >
      <RoundedCurrency amount={amount} creditType={creditType} />
    </span>
  );
};

type CreditLedgerProps = {
  loading: boolean;
  ledger: Ledger;
  columnsToHide?: Set<string>;
  noBottomBorder?: boolean;
};
const CreditLedger: React.FC<CreditLedgerProps> = ({
  loading,
  ledger,
  columnsToHide,
  noBottomBorder,
}) => {
  return (
    <DeprecatedTable
      loading={loading}
      maxPageSize={10}
      data={ledger.ledgerEntries}
      noBottomBorder={noBottomBorder}
      columns={[
        {
          header: "Amount",
          id: "amount",
          render: (entry: LedgerEntry) => {
            const amount = new Decimal(entry.amount);
            return (
              <AccountingFormattedCurrency
                amount={amount}
                creditType={ledger.creditType}
                voided={!!entry.grant.voidedAt}
              />
            );
          },
          align: "left" as const,
        },
        {
          header: "Balance",
          id: "balance",
          render: (entry: LedgerEntry) => (
            <RoundedCurrency
              amount={new Decimal(entry.runningBalance)}
              creditType={ledger.creditType}
            />
          ),
          align: "left" as const,
        },
        {
          header: "Cost basis",
          id: "cost_basis",
          render: (entry: LedgerEntry) => (
            <span className={!!entry.grant.voidedAt ? styles.voided : ""}>
              {displayCostBasis(
                new Decimal(entry.grant.costBasis),
                entry.creditType,
                entry.grant.AmountPaidCreditType,
              )}
            </span>
          ),
          align: "left" as const,
        },
        {
          header: "Grant name",
          id: "grant_name",
          render: (entry: LedgerEntry) => {
            return (
              <span className={!!entry.grant.voidedAt ? styles.voided : ""}>
                {entry.grant.name}
              </span>
            );
          },
          align: "left" as const,
        },
        {
          header: "Reason",
          id: "reason",
          render: (entry: LedgerEntry) => {
            if (!!entry.grant.voidedAt) {
              return (
                <span className={styles.voided}>
                  {`Grant voided by: ${entry.grant.Voider?.name}`}
                </span>
              );
            } else {
              return <span>{entry.memo}</span>;
            }
          },
          align: "left" as const,
        },
        {
          header: "Date",
          id: "date",
          render: (entry: LedgerEntry) => (
            <CreditLedgerDate ledgerEntry={entry} />
          ),
          align: "right" as "right",
        },
      ].filter((col) => !columnsToHide?.has(col.id))}
    />
  );
};

export default CreditLedger;

import { ApolloError } from "@apollo/client";
import { Headline } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import React from "react";
import {
  MenuItemProps,
  DeprecatedPopoverMenu,
} from "components/deprecated/PopoverMenu";
import { Table } from "components/Table";
import { CopyableID } from "components/CopyableID";

interface Props {
  onRequestClose: () => void;
  metadataRows: Map<string, string | string[]>;
  title: string;
  instanceType: string;
  error?: ApolloError;
  loading?: boolean;
  options?: {
    removeEmptyValueRows?: boolean;
  };
  children?: React.ReactNode;
  dropdownMenuItems?: MenuItemProps[];
}

export const DeprecatedFlyoverWithMetadataTable: React.FC<Props> = ({
  onRequestClose,
  metadataRows,
  title,
  instanceType,
  error,
  loading,
  options,
  children,
  dropdownMenuItems,
}) => {
  const prepareMetadataForRows = (): {
    key: string;
    value: string | string[];
    id: string;
  }[] => {
    const rows = Array.from(metadataRows).map(([key, value]) => ({
      key,
      value,
      id: key,
    }));
    if (rows.length === 0) {
      return [];
    }
    if (options?.removeEmptyValueRows) {
      if (typeof rows[0].value === "string") {
        return rows.filter((row) => row.value !== "");
      } else if (Array.isArray(rows[0].value)) {
        return rows.filter((row) => row.value.length > 0);
      }
    }
    return rows;
  };

  const metadataRowsPrepared = loading ? [] : prepareMetadataForRows();

  return (
    <DeprecatedRightPane
      isOpen
      onRequestClose={onRequestClose}
      size="lg"
      contentClassName="!p-0"
    >
      <header className="flex items-center bg-gray-50 px-12 py-8">
        {/* left */}
        <div className="grow">
          <Headline level={6} className="grow">
            {title}
          </Headline>
        </div>

        {/* right */}
        <div className="flex items-center">
          {dropdownMenuItems && dropdownMenuItems.length > 0 && (
            <DeprecatedPopoverMenu
              className="mr-12"
              positions={["bottom"]}
              align="end"
              options={dropdownMenuItems}
            >
              {(onClick) => (
                <IconButton
                  onClick={onClick}
                  theme="secondary"
                  icon="dotsVertical"
                />
              )}
            </DeprecatedPopoverMenu>
          )}
          <IconButton
            className="grow-0"
            onClick={onRequestClose}
            theme="secondary"
            icon="xClose"
          />
        </div>
      </header>
      <div className="p-12">
        {error && (
          <ErrorEmptyState
            title={`We were unable to load metadata for this ${instanceType}`}
            error={error}
          />
        )}
        <Table
          title="Metadata"
          data={metadataRowsPrepared}
          loading={loading}
          columns={[
            {
              id: "key",
              header: "Key",
              cell: (props) => props.getValue(),
              accessorFn: (row) => row.key,
            },
            {
              id: "value",
              header: "Value",
              cell: (props) => props.getValue(),
              accessorFn: (row) => {
                if (Array.isArray(row.value)) {
                  return (
                    <>
                      {row.value.map((value) => (
                        <div className="flex flex-row justify-end">
                          <CopyableID id={value} />
                        </div>
                      ))}
                    </>
                  );
                }
                return (
                  <div className="flex flex-row justify-end">
                    <CopyableID id={row.value} />
                  </div>
                );
              },
              enableSorting: false,
            },
          ]}
        />
        {children}
      </div>
    </DeprecatedRightPane>
  );
};

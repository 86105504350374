import React from "react";

import { useDisableStripeMutation } from "./queries.graphql";
import { Body } from "design-system";

import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";

interface DisableStripeModalProps {
  onClose: () => void;
  customerId: string;
}

export const DisableStripeModal: React.FC<DisableStripeModalProps> = ({
  onClose,
  customerId,
}) => {
  const [deleteStripeSettings] = useDisableStripeMutation({
    update(cache) {
      cache.evict({
        fieldName: "BillingProviderCustomer",
      });
      cache.evict({
        fieldName: "CustomerConfig",
      });
    },
  });
  return (
    <DeprecatedPopup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={async () => {
              await deleteStripeSettings({
                variables: {
                  customer_id: customerId,
                },
              });
              onClose();
            }}
            text="Yes, disable Stripe"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to disable Stripe for this customer?"
    >
      <Body level={2}>
        Disabling this Stripe configuration will prevent you from invoicing this
        customer.
      </Body>
    </DeprecatedPopup>
  );
};

import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { ProductsQuery } from "./data.graphql";
import { v4 as uuid } from "uuid";
import { dayjs } from "lib/dayjs";
import { FiatCreditType } from "app/types/credit-types";

type Products = ProductsQuery["contract_pricing"]["products"];
export type ProductListItem = Products[0];

export type DimensionalProduct =
  Schema.Types.UnifiedRateCardInput["dimensionalProducts"][0];

export function getDefaultRates(
  currentUsageRates: Schema.Types.Rate[],
  currentSubscriptionRates: Schema.Types.Rate[],
  currentCompositeRates: Schema.Types.Rate[],
  nonDimensionalProducts: ProductListItem[],
  dimensionalProducts: DimensionalProduct[],
  fiatCreditType: FiatCreditType,
): {
  usageRates: Schema.Types.Rate[];
  subscriptionRates: Schema.Types.Rate[];
  compositeRates: Schema.Types.Rate[];
} {
  const nonDimensionalRateMap = nonDimensionalProducts.reduce(
    (acc, p) => {
      if (p.__typename === "UsageProductListItem") {
        acc.usageProductRates.set(p.id, {
          entitled: "enable",
          id: uuid(),
          creditType: fiatCreditType,
          price: {
            type: "flat",
            price: undefined,
          },
          productId: p.id,
          startingAt: dayjs().utc().startOf("month").toISOString(),
        });
      } else if (p.__typename === "SubscriptionProductListItem") {
        acc.subscripionProductRates.set(p.id, {
          entitled: "enable",
          id: uuid(),
          creditType: fiatCreditType,
          price: {
            type: "subscription",
            price: undefined,
            isProrated: true,
            quantity: 1,
          },
          productId: p.id,
          startingAt: dayjs().utc().startOf("month").toISOString(),
        });
      } else if (p.__typename === "CompositeProductListItem") {
        acc.compositeProductRates.set(p.id, {
          entitled: "enable",
          id: uuid(),
          creditType: fiatCreditType,
          price: {
            type: "percentage",
            fraction: undefined,
            useListPrices: false,
          },
          productId: p.id,
          startingAt: dayjs().utc().startOf("month").toISOString(),
        });
      } else {
        throw new Error(`Unsupported product type: ${p.__typename}`);
      }

      return acc;
    },
    {
      usageProductRates: new Map<string, Schema.Types.Rate>(),
      subscripionProductRates: new Map<string, Schema.Types.Rate>(),
      compositeProductRates: new Map<string, Schema.Types.Rate>(),
    },
  );

  // get the rate combinations for the dimensional products
  const dimensionalRatesMap = dimensionalProducts.reduce((acc, p) => {
    // pre-populate the rates for the dimensional products
    const pricingGroupMap = p.pricingGroupKeyValues.reduce(
      (acc, key) => {
        acc[key.key] = key.values;
        return acc;
      },
      {} as Record<string, string[]>,
    );

    function generateCombinations(options: Record<string, string[]>) {
      const keys = Object.keys(options);

      return keys.reduce(
        (acc, key) => {
          const values = options[key];
          if (acc.length === 0) {
            return values.map((value) => ({ [key]: value }));
          }

          return acc.flatMap((currentCombination) =>
            values.map((value) => ({ ...currentCombination, [key]: value })),
          );
        },
        [] as Record<string, string>[],
      );
    }

    const combinations = generateCombinations(pricingGroupMap);

    const newRates: Schema.Types.Rate[] = [];
    for (const pricingGroupValues of combinations) {
      newRates.push({
        entitled: "enable",
        id: uuid(),
        creditType: fiatCreditType,
        price: {
          type: "flat",
          price: undefined,
        },
        productId: p.id,
        pricingGroupValues,
        startingAt: dayjs().utc().startOf("month").toISOString(),
      });
    }
    acc.set(p.id, newRates);
    return acc;
  }, new Map<string, Schema.Types.Rate[]>());

  const newUsageRates = [
    Array.from(nonDimensionalRateMap.usageProductRates.values()),
    Array.from(dimensionalRatesMap.values()).flat(),
  ].flat();

  newUsageRates.forEach((rate) => {
    // try and find a rate from the current which matches completely, if so, then use that
    // rates values
    const currentRate = currentUsageRates.find((r) => {
      if (r.productId !== rate.productId) {
        return false;
      }

      if (r.pricingGroupValues && rate.pricingGroupValues) {
        return Object.entries(r.pricingGroupValues).every(
          ([key, value]) => rate.pricingGroupValues?.[key] === value,
        );
      }

      return true;
    });

    if (currentRate) {
      Object.assign(rate, currentRate);
    }
  });

  const newSubscriptionRates: Schema.Types.Rate[] = Array.from(
    nonDimensionalRateMap.subscripionProductRates.values(),
  ).map((rate) => {
    const currentRate = currentSubscriptionRates.find(
      (r) => r.productId === rate.productId,
    );

    if (currentRate) {
      return currentRate;
    }

    return rate;
  });
  const newCompositeRates: Schema.Types.Rate[] = Array.from(
    nonDimensionalRateMap.compositeProductRates.values(),
  ).map((rate) => {
    const currentRate = currentCompositeRates.find(
      (r) => r.productId === rate.productId,
    );

    if (currentRate) {
      return currentRate;
    }

    return rate;
  });
  return {
    usageRates: newUsageRates,
    subscriptionRates: newSubscriptionRates,
    compositeRates: newCompositeRates,
  };
}

export function categorizeProducts(
  selectedProducts: string[],
  productsMap: Map<string, ProductListItem>,
) {
  return selectedProducts.reduce<{
    dimensionalProducts: DimensionalProduct[];
    nonDimensionalProducts: ProductListItem[];
  }>(
    (acc, p) => {
      const product = productsMap.get(p);
      if (!product) {
        return acc;
      }

      if (
        product?.__typename === "UsageProductListItem" &&
        Array.isArray(product.current.pricing_group_key)
      ) {
        acc.dimensionalProducts.push({
          id: product.id,
          name: product.current.name,
          pricingGroupKeyValues: product.current.pricing_group_key.map(
            (key) => ({
              key,
              values: [],
            }),
          ),
        });
      } else {
        acc.nonDimensionalProducts.push(product);
      }

      return acc;
    },
    { dimensionalProducts: [], nonDimensionalProducts: [] },
  );
}

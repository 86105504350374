import React, { useEffect, useState } from "react";

import { InsertPlanDocument } from "components/deprecated/CopyAssetToEnvironmentModal/data/queries.graphql";
import {
  MenuItemProps,
  DeprecatedPopoverMenu,
} from "components/deprecated/PopoverMenu";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useCopyPlan } from "app/lib/plans/hooks";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { EditPlanDocument } from "app/pages/Offering/tabs/Plans/PlanWizards/data/queries.graphql";
import { DraftPlan, Plan } from "app/pages/Offering/tabs/Plans/PlansContainer";
import { useCustomerCountForPlanQuery } from "app/pages/Offering/tabs/Plans/queries.graphql";
import { ArchivePlanDocument } from "../ArchivePlanModal/queries.graphql";
import { useNavigate } from "app/lib/useNavigate";
import { Tooltip } from "design-system";
import { IconButton } from "components/IconButton";
import { useEnvironment } from "app/lib/environmentSwitcher/context";

interface ActionMenuProps {
  plan: Plan;
  setPlanToArchive: (plan: Plan) => void;
  setPlanToCopy: (plan: Plan) => void;
}

interface DraftPlanActionMenuProps {
  plan: DraftPlan;
  setPlanToDelete: (plan: DraftPlan) => void;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  plan,
  setPlanToCopy,
  setPlanToArchive,
}) => {
  const { copyPlan } = useCopyPlan();
  const [customerCount, setCustomerCount] = useState<number | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const { environments, environment } = useEnvironment();

  const copyAssetsFF = useFeatureFlag<string[]>(
    "copy-assets-to-environment",
    [],
  );
  const copyPlanToEnvironmentEnabled = copyAssetsFF?.includes("PLAN");

  const canCreatePlan = !!useAuthCheck(InsertPlanDocument, true).allowed;
  const canEditPlan = !!useAuthCheck(EditPlanDocument, true).allowed;
  const canArchivePlan = !!useAuthCheck(ArchivePlanDocument, true).allowed;

  const { data } = useCustomerCountForPlanQuery({
    variables: { plan_id: plan.id },
  });

  // Update customer count once the query has loaded data
  useEffect(() => {
    if (data) {
      setCustomerCount(data?.Plan_by_pk?.customer_count ?? undefined);
    }
  }, [data]);

  const planActions = (plan: Plan, customerCount: number | undefined) => {
    const actions: MenuItemProps[] = [
      gatedAction(canCreatePlan, {
        content: "Duplicate plan...",
        onClick: async () => copyPlan(plan.id),
      }),
    ];

    if (copyPlanToEnvironmentEnabled && environments.length > 1) {
      const text =
        environments.length === 2
          ? `Copy to ${environments.filter((e) => e !== environment)[0].name}`
          : "Copy to another environment...";
      actions.push({
        content: text,
        onClick: () => setPlanToCopy(plan),
      });
    }

    actions.push({
      content: "Manage custom fields...",
      onClick: () => navigate(`/custom-fields/plan/${plan.id}`),
    });

    actions.push(
      gatedAction(canEditPlan, {
        content: "Edit plan...",
        onClick: () => navigate(`/offering/plans/edit/${plan.id}`),
      }),
    );
    if (plan.deprecated_at === null) {
      actions.push(
        gatedAction(canArchivePlan, {
          disabled: customerCount === undefined || customerCount > 0,
          content:
            customerCount === 0 ? (
              "Archive plan..."
            ) : (
              <Tooltip content="Plans in use cannot be archived">
                Archive plan...
              </Tooltip>
            ),
          onClick: () => setPlanToArchive(plan),
        }),
      );
    }

    return actions;
  };

  return (
    <DeprecatedPopoverMenu
      positions={["bottom", "top"]}
      align="end"
      options={planActions(plan, customerCount)}
    >
      {(onClick) => (
        <IconButton
          onClick={onClick}
          theme="tertiary"
          icon="dotsVertical"
          size="sm"
        />
      )}
    </DeprecatedPopoverMenu>
  );
};

export const DraftPlanActionMenu: React.FC<DraftPlanActionMenuProps> = ({
  plan,
  setPlanToDelete,
}) => {
  const { copyDraftPlan } = useCopyPlan();

  const canCreatePlan = !!useAuthCheck(InsertPlanDocument, true).allowed;

  const planActions = (plan: DraftPlan) => {
    return [
      gatedAction(canCreatePlan, {
        content: "Delete plan",
        onClick: () => setPlanToDelete(plan),
      }),
      gatedAction(canCreatePlan, {
        content: "Duplicate plan",
        onClick: async () => copyDraftPlan(plan),
      }),
    ];
  };
  return (
    <DeprecatedPopoverMenu
      positions={["bottom", "top"]}
      align="end"
      options={planActions(plan)}
    >
      {(onClick) => (
        <IconButton
          onClick={onClick}
          theme="tertiary"
          icon="dotsVertical"
          size="sm"
        />
      )}
    </DeprecatedPopoverMenu>
  );
};

import React from "react";
import { Badge, HelpCircleTooltip, Icon, Themes, Tooltip } from "design-system";
import { dayjs } from "lib/dayjs";
import {
  BaseStatusPillsFieldsFragment,
  StatusPillsFieldsFragment,
} from "./fragments.graphql";
import {
  EnvironmentTypeEnum_Enum,
  InvoiceExternalStatusEnum_Enum,
} from "types/generated-graphql/__types__";

export const getMainInvoiceStatusBadgeContent = (
  invoice: BaseStatusPillsFieldsFragment,
  light?: boolean,
) => {
  let theme: Themes | undefined;
  let copy: string | undefined;
  let type: "light" | "dark" = light ? "light" : "dark";

  const contents: React.ReactNode[] = [];

  if (invoice.__typename === "CreditPurchaseInvoice") {
    type = "dark";
    if (dayjs(invoice.issued_at).isBefore(new Date())) {
      theme = "success";
      copy = "ISSUED";
    } else {
      theme = "grey";
      copy = "SCHEDULED";
    }
  } else {
    switch (invoice.status) {
      case "DRAFT": {
        theme = "grey";
        copy = "DRAFT";
        break;
      }
      case "FINALIZED": {
        theme = "success";
        copy = invoice.on_hold ? "ON HOLD" : "FINALIZED";
        break;
      }
      case "PENDING_FINALIZATION": {
        theme = "primary";
        copy = "PENDING FINALIZATION";
        break;
      }
      case "FINALIZED_IN_OLD_SYSTEM": {
        // This status is "internal", the resolvers should never return it. That said, to satisfy the type checker, we need to handle it.
        throw new Error(
          "Received finalized invoice from old system, which should not be exposed to callers ever",
        );
      }
      default: {
        console.warn("Unknown invoice status", invoice.status);
        break;
      }
    }
  }
  if (theme && copy) {
    contents.push(
      <Badge key="status" type={type} theme={theme}>
        {copy}
      </Badge>,
    );
  }

  return contents;
};

export const getTooltipContent = (
  invoice: BaseStatusPillsFieldsFragment,
  compact?: boolean,
) => {
  const contents: React.ReactNode[] = [];
  if (
    invoice.__typename === "CreditPurchaseInvoice" &&
    !compact &&
    dayjs(invoice.issued_at).isAfter(new Date())
  ) {
    contents.push(
      <HelpCircleTooltip
        key="help"
        content="This invoice will be issued on the scheduled date."
      />,
    );
  }
  return contents;
};

export const getTrialContent = (
  invoice: BaseStatusPillsFieldsFragment,
  light?: boolean,
  compact?: boolean,
) => {
  const contents: React.ReactNode[] = [];
  const type = light ? "light" : "dark";

  if (invoice.__typename === "ArrearsInvoice" && invoice.is_trial) {
    // NOTE: we do not need to override light styles as this is only
    // for contract invoices and they are always dark
    contents.push(
      <Badge
        className="ml-[6px] bg-badge-2 text-white"
        theme="success"
        type={type}
        key="trial"
      >
        {compact ? "TRIAL" : "TRIAL PERIOD"}
      </Badge>,
    );
  }

  return contents;
};

const getStripeLink = (
  billing_provider_invoice_id: string | null,
  external_status: InvoiceExternalStatusEnum_Enum,
  environmentType: EnvironmentTypeEnum_Enum,
) => {
  if (
    !billing_provider_invoice_id ||
    external_status === InvoiceExternalStatusEnum_Enum.Deleted
  ) {
    return null;
  }
  return (
    <a
      className="ml-4"
      href={`https://dashboard.stripe.com${
        environmentType !== EnvironmentTypeEnum_Enum.Production ? "/test" : ""
      }/invoices/${billing_provider_invoice_id}`}
      target="_blank"
    >
      <Tooltip content="Go to Invoice">
        <Icon icon="open"></Icon>
      </Tooltip>
    </a>
  );
};

const getErrorTooltip = (billing_provider_error: string | null) => {
  if (!billing_provider_error) {
    return null;
  }
  return (
    <Tooltip content={billing_provider_error}>
      <Icon className="ml-4" icon="helpCircle"></Icon>
    </Tooltip>
  );
};

export const getBillingProviderStatusContent = (
  invoice: StatusPillsFieldsFragment,
  environmentType: EnvironmentTypeEnum_Enum,
  light?: boolean,
  compact?: boolean,
) => {
  const contents: React.ReactNode[] = [];
  const type = light ? "light" : "dark";

  if (
    invoice.status !== "DRAFT" &&
    !(
      invoice.__typename === "ParentInvoice" ||
      invoice.__typename === "AdHocPlanInvoice"
    ) &&
    invoice.billing_provider_invoice &&
    !compact
  ) {
    let badgeTheme: Themes;
    let copy: string = invoice.billing_provider_invoice.external_status;

    switch (invoice.billing_provider_invoice.external_status) {
      case "DELETED":
      case "UNCOLLECTIBLE":
      case "VOID":
      case "PAYMENT_FAILED": {
        badgeTheme = "error";
        break;
      }
      case "INVALID_REQUEST_ERROR": {
        badgeTheme = "error";
        copy = "FAILED";
        break;
      }
      case "PAID": {
        badgeTheme = "success";
        break;
      }
      case "DRAFT":
      case "SKIPPED":
      case "FINALIZED":
      case "SENT":
      case "QUEUED": {
        badgeTheme = "grey";
        break;
      }
      case undefined: {
        badgeTheme = "grey";
        copy = "CREATED";
        break;
      }
      default: {
        console.warn(
          `Unknown external status: ${invoice.billing_provider_invoice.external_status}`,
        );
        badgeTheme = "grey";
      }
    }
    contents.push(
      <Badge
        theme={badgeTheme}
        type={type}
        className="ml-4 flex flex-row items-center"
        key="external"
      >
        {invoice.billing_provider_invoice.billing_provider ===
        "QUICKBOOKS_ONLINE"
          ? "QUICKBOOKS"
          : invoice.billing_provider_invoice.billing_provider}
        : {copy}
        {invoice.billing_provider_invoice.billing_provider === "STRIPE" &&
          getStripeLink(
            invoice.billing_provider_invoice.billing_provider_invoice_id,
            invoice.billing_provider_invoice.external_status,
            environmentType,
          )}
        {getErrorTooltip(
          invoice.billing_provider_invoice.billing_provider_error,
        )}
      </Badge>,
    );
  }

  return contents;
};

import React, { useEffect, useState } from "react";

import { startOfHour, isBefore as isDateBefore, addMonths } from "date-fns";

import {
  useCustomerListQuery,
  useSearchCustomersQuery,
  CustomerListQuery,
  useCustomerOverviewDetailsQuery,
  CustomerOverviewDetailsQuery,
} from "app/pages/deprecated/Overview/data/overview.graphql";
import {
  Order_By,
  Customer_Order_By,
  Customer_Bool_Exp,
} from "types/generated-graphql/__types__";
import {
  CUSTOMER_BILLING_CONFIG_FILTER_MAP,
  CUSTOMER_PLAN_STATUS,
  CUSTOMER_PLAN_STATUS_CONFIG_FILTER_MAP,
} from "app/pages/deprecated/Overview/data/types";
import { EmptyState } from "components/EmptyState";
import {
  DeprecatedTable,
  SortFunctions,
  TableProps,
  SortRule,
} from "components/deprecated/Table";
import { Badge } from "design-system";
import { IconButton } from "components/IconButton";
import { renderDate, renderDateTimeInUTC } from "lib/time";
import {
  ALL_BILLING_CONFIG_FILTERS,
  BillingConfigFilters,
} from "app/pages/deprecated/Overview/filters";
import { DeprecatedWarningDot } from "components/deprecated/WarningDot";
import { useActions } from "app/lib/customers/actions";
import { dayjs } from "lib/dayjs";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import useDebounce from "app/lib/debounce";
import { ApolloError, NetworkStatus } from "@apollo/client";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";
import { InsertCustomerDocument } from "app/pages/Customer/NewCustomer/newCustomer.graphql";
import { GatedButton } from "components/GatedButton";

type Customer = CustomerListQuery["Customer"][0];
type CustomerDetail = CustomerOverviewDetailsQuery["Customer"][0];

type PlanState =
  | {
      state: "active";
      expiringSoon: boolean;
      expireDate: Date | null;
    }
  | {
      state: "none";
    }
  | {
      state: "expired";
      expireDate: Date;
    }
  | {
      state: "loading";
    };

// Given a customer, return/parse the plan into usable information
function planState(customer: CustomerDetail): PlanState {
  const currentPlan = customer.currentPlan[0];
  const latestPastPlan = customer.latestPastPlan[0];

  if (!currentPlan && !latestPastPlan) {
    return { state: "none" };
  }

  if (currentPlan) {
    const expiringDate = currentPlan.cancellation_date
      ? dayjs.utc(currentPlan.cancellation_date).subtract(1, "day").toDate()
      : null;
    return {
      state: "active",
      expireDate: expiringDate,
      expiringSoon: expiringDate
        ? isDateBefore(expiringDate, addMonths(new Date(), 2))
        : false,
    };
  }

  const expireDate =
    latestPastPlan && latestPastPlan.cancellation_date
      ? latestPastPlan.cancellation_date
      : null;

  if (expireDate) {
    return {
      state: "expired",
      expireDate: dayjs.utc(expireDate).subtract(1, "day").toDate(),
    };
  }

  return {
    state: "none",
  };
}

interface PlanInfoCellProps {
  customer: CustomerDetail;
}

const planCellStyles = "flex items-center justify-end";

const PlanInfoCell: React.FC<PlanInfoCellProps> = ({ customer }) => {
  const plan = planState(customer);
  if (plan.state === "none") {
    return <div className={planCellStyles}>No plan</div>;
  } else if (plan.state === "loading") {
    return <DeprecatedTextSkeleton className="m-0" />;
  } else if (plan.state === "expired") {
    return (
      <div className={planCellStyles}>
        <Badge theme="grey" type="light">
          Expired
        </Badge>
        <span className="ml-8">
          {renderDate(plan.expireDate, {
            isUtc: true,
          })}
        </span>
      </div>
    );
  } else {
    return (
      <div className={planCellStyles}>
        {plan.expiringSoon && (
          <DeprecatedWarningDot message="Plan expires soon." />
        )}
        <span className="ml-8">
          {plan.expireDate
            ? `Expiry: ${renderDate(plan.expireDate, {
                isUtc: true,
              })}`
            : "Recurring"}
        </span>
      </div>
    );
  }
};

function customerStatusFilter(
  c: CustomerDetail,
  allowedStatus: string,
): boolean {
  switch (allowedStatus) {
    case "expired_plan":
      return !c.currentPlan.length && c.latestPastPlan.length > 0;
    case "plan_expires_30d":
      return (
        c.currentPlan.length > 0 &&
        c.currentPlan[0].cancellation_date != null &&
        dayjs
          .utc()
          .add(30, "day")
          .isSameOrAfter(dayjs.utc(c.currentPlan[0].cancellation_date))
      );
    case "no_plan":
      return !c.currentPlan.length && !c.latestPastPlan.length;
    case "all":
    default:
      return true;
  }
}

function billingConfigFilter(
  c: CustomerDetail,
  billingConfigFilters: BillingConfigFilters[],
) {
  if (c.BillingProviderCustomers.length === 0) {
    return billingConfigFilters.includes(BillingConfigFilters.None);
  } else if (
    c.BillingProviderCustomers[0].billing_provider === "AWS_MARKETPLACE"
  ) {
    return billingConfigFilters.includes(BillingConfigFilters.AwsMarketplace);
  } else if (
    c.CustomerConfigs.some(
      (cc) => cc.value === BillingConfigFilters.SendInvoice,
    )
  ) {
    return billingConfigFilters.includes(BillingConfigFilters.SendInvoice);
  } else {
    return billingConfigFilters.includes(
      BillingConfigFilters.ChargeAutomatically,
    );
  }
}

interface CustomerTableProps {
  customerStatus?: string;
  billingConfigFilters: BillingConfigFilters[];
  searchQuery?: string;
  planId?: string;
}

interface CustomerSortRule {
  id: "name" | "plan";
  desc?: boolean;
}

const isCustomerSortRule = (rule: SortRule): rule is CustomerSortRule => {
  return rule.id === "name" || rule.id === "plan";
};

const PAGE_SIZE = 20;
const DEFAULT_SORT = { id: "name" as const };
const SEARCH_DEBOUNCE_DELAY = 300; // milliseconds

const sortingRuleToOrderBy = (rule: CustomerSortRule): Customer_Order_By[] => {
  switch (rule.id) {
    case "plan":
      return [
        {
          CustomerPlans_aggregate: {
            max: {
              cancellation_date: rule.desc
                ? Order_By.AscNullsLast
                : Order_By.DescNullsFirst,
            },
          },
        },
        {
          CustomerPlans_aggregate: {
            count: rule.desc ? Order_By.Asc : Order_By.Desc,
          },
        },
        { name: rule.desc ? Order_By.Desc : Order_By.Asc },
      ];
    case "name":
    default:
      return [{ name: rule.desc ? Order_By.Desc : Order_By.Asc }];
  }
};

// Helper for splitting this page's query either to CustomerList or SearchCustomers,
// but returning data in the same format.
const useCustomersQueryHelper = (
  searchQuery: string,
  sortState: CustomerSortRule,
  pageIndex: number,
  customerFilter: Customer_Bool_Exp,
  planId?: string,
): {
  customerIds: string[];
  matchingCustomers: number;
  anyCustomer: boolean;
  customersLoading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  networkStatus: NetworkStatus;
} => {
  const { environmentType } = useEnvironment();

  const searchCustomersResponse = useSearchCustomersQuery({
    variables: {
      query: searchQuery,
      environment_type: environmentType,
    },
    skip: !searchQuery,
    notifyOnNetworkStatusChange: true,
  });

  const date = startOfHour(new Date()).toISOString();
  const filter = planId
    ? {
        _and: [
          customerFilter,
          {
            CustomerPlans: {
              _and: [
                {
                  Plan: {
                    id: {
                      _eq: planId,
                    },
                  },
                },
                {
                  deleted_at: { _is_null: true },
                  _or: [
                    { cancellation_date: { _is_null: true } },
                    { cancellation_date: { _gt: date } },
                  ],
                },
              ],
            },
          },
        ],
      }
    : customerFilter;

  const customerListResponse = useCustomerListQuery({
    variables: {
      order_by: sortingRuleToOrderBy(sortState),
      filter: filter,
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * pageIndex,
      environment_type: environmentType,
    },
    skip: !!searchQuery,
    notifyOnNetworkStatusChange: true,
  });

  if (searchQuery) {
    const { data, loading, error, refetch, networkStatus } =
      searchCustomersResponse;
    return {
      customerIds: data?.searchCustomers.map((customer) => customer.id) || [],
      matchingCustomers: data?.searchCustomers.length || 0,
      anyCustomer: !!data?.anyCustomer,
      customersLoading: loading,
      error,
      refetch: refetch,
      networkStatus: networkStatus,
    };
  } else {
    const { data, loading, error, refetch, networkStatus } =
      customerListResponse;
    return {
      customerIds: data?.Customer.map((customer) => customer.id) || [],
      matchingCustomers: data?.Customer_aggregate.aggregate?.count || 0,
      anyCustomer: (data?.Client[0]?.customer_count.count || 0) > 0,
      customersLoading: loading,
      error,
      refetch: refetch,
      networkStatus: networkStatus,
    };
  }
};

export const CustomerTable: React.FC<CustomerTableProps> = ({
  customerStatus,
  billingConfigFilters,
  planId,
  searchQuery,
}) => {
  const { getActions, archiveModal } = useActions();
  const { environmentType } = useEnvironment();
  const debouncedSearchQuery = useDebounce(
    searchQuery ?? "",
    SEARCH_DEBOUNCE_DELAY,
  );

  const [sortState, setSortState] = useState<CustomerSortRule>(DEFAULT_SORT);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [numRetry, setNumRetry] = useState<number>(0);

  useEffect(() => {
    // Reset to page 0 if any sorting/filtering/searching changed
    setPageIndex(0);
  }, [customerStatus, billingConfigFilters, sortState, environmentType]);

  let customerFilter: Customer_Bool_Exp = ALL_BILLING_CONFIG_FILTERS.every(
    (filter) => billingConfigFilters.includes(filter),
  )
    ? { environment_type: { _eq: environmentType } }
    : {
        _and: [
          { environment_type: { _eq: environmentType } },
          {
            _or: billingConfigFilters.map(
              (bcf) => CUSTOMER_BILLING_CONFIG_FILTER_MAP[bcf],
            ),
          },
        ],
      };
  if (
    customerStatus &&
    customerStatus in CUSTOMER_PLAN_STATUS_CONFIG_FILTER_MAP
  ) {
    customerFilter = {
      _and: [
        customerFilter,
        CUSTOMER_PLAN_STATUS_CONFIG_FILTER_MAP[
          customerStatus as CUSTOMER_PLAN_STATUS
        ](startOfHour(new Date()).toISOString()),
      ],
    };
  } else {
    // dont show archived in the default
    customerFilter = {
      _and: [customerFilter, { archived_at: { _is_null: true } }],
    };
  }

  const {
    customerIds,
    matchingCustomers,
    anyCustomer,
    customersLoading,
    error,
    refetch,
    networkStatus,
  } = useCustomersQueryHelper(
    debouncedSearchQuery,
    sortState,
    pageIndex,
    customerFilter,
    planId,
  );

  useEffect(() => {
    if (error && numRetry === 0) {
      refetch();
      setNumRetry(numRetry + 1);
    }
  }, [error, numRetry]);

  /*
    TODO(GET-1757): A better version of this implementation would happen on the backend. Hasura offers
    flexible WHERE clauses which at the time of writing this we did not want to implement in a resolver
  */
  const customerDetailsResponse = useCustomerOverviewDetailsQuery({
    variables: {
      customer_ids: customerIds,
      date: startOfHour(new Date()).toISOString(),
      order_by: sortingRuleToOrderBy(sortState),
    },
    skip: customerIds.length === 0,
    notifyOnNetworkStatusChange: true,
  });
  const customersData = customerDetailsResponse.data?.Customer ?? [];
  const isLoading =
    customersLoading ||
    customerDetailsResponse.loading ||
    networkStatus === NetworkStatus.refetch;

  // NOTE: We still filter here if we did a searchCustomers query, we won't have already passed
  // this filtering information down.
  const customers = debouncedSearchQuery
    ? customersData.filter(
        (c) =>
          customerStatusFilter(c, customerStatus ?? "") &&
          billingConfigFilter(c, billingConfigFilters),
      )
    : customersData;

  // If we did a search, we got all the search results back unpaginated so let the table control
  // paginating the results internally. Otherwise we want to specify on{Sort,Page}Changed handlers
  // so that we can handle any sorts or page navigations with new queries from this component.
  const paginationArgs: Pick<
    TableProps<Customer>,
    "onSortChanged" | "manualPagination" | "pageIndex"
  > = debouncedSearchQuery
    ? {}
    : {
        onSortChanged: (sortRules) => {
          const rule = sortRules[0];
          if (rule && isCustomerSortRule(rule)) {
            setSortState(rule);
          } else {
            setSortState(DEFAULT_SORT);
          }
        },
        manualPagination: {
          onPageChanged: ({ newPageIndex }) => setPageIndex(newPageIndex),
          pageCount: Math.ceil(matchingCustomers / PAGE_SIZE),
          numItems: matchingCustomers,
        },
        pageIndex,
      };

  return (
    <>
      {archiveModal}
      {error && networkStatus !== NetworkStatus.refetch && numRetry > 0 ? (
        <EmptyState
          icon="helpCircle"
          mainText="We ran into an issue loading your customers"
          supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      ) : !isLoading && !anyCustomer ? (
        <EmptyState
          icon="briefcase01"
          mainText="You haven't added any customers to Metronome&nbsp;yet."
          supportingText="Start by adding your first customer to begin tracking customer information in Metronome."
          actions={[
            <GatedButton
              doc={InsertCustomerDocument}
              linkTo="/customers/new"
              text="Add new customer"
              theme="primary"
              leadingIcon="plus"
            />,
          ]}
        />
      ) : !isLoading && !matchingCustomers ? (
        <EmptyState
          icon="briefcase01"
          mainText="No matching customers found."
          supportingText={
            planId
              ? "This plan has no customers."
              : `Try a different ${
                  debouncedSearchQuery.length ? "search term" : "filter"
                }.`
          }
        />
      ) : (
        <DeprecatedTable
          loading={isLoading || (error && numRetry === 0)}
          defaultSortBy={[sortState]}
          rowRoutePath={(c) => `/customers/${c.id}`}
          maxPageSize={PAGE_SIZE}
          getRowTheme={(c) =>
            planState(c).state === "active" ? "enabled" : "disabled"
          }
          {...paginationArgs}
          data={customers}
          columns={[
            {
              header: "Customer",
              id: "name",
              render: (c) => (
                <span>
                  {c.archived_at !== null && (
                    <Badge theme="warning" type="dark" className="mr-4">
                      ARCHIVED
                    </Badge>
                  )}
                  {c.name}
                </span>
              ),
              sortable: true,
              comparator: SortFunctions.String((c) => c.name),
              textWrappable: true,
            },
            {
              header: "Plan summary",
              id: "plan",
              align: "right",
              sortable: true,
              comparator: SortFunctions.Date((c) => {
                const state = planState(c);
                return "expireDate" in state && state.expireDate
                  ? state.expireDate
                  : undefined;
              }),
              render: (c) => <PlanInfoCell customer={c} />,
            },
            {
              id: "created_at",
              header: "Date created (UTC)",
              align: "right",
              render: (c) => (
                <span
                  title={renderDateTimeInUTC(new Date(c.created_at), false)}
                >
                  {renderDate(new Date(c.created_at), {
                    isUtc: true,
                    excludeUtcLabel: true,
                  })}
                </span>
              ),
            },
            {
              header: "",
              id: "option",
              align: "right",
              sortable: false,
              render: (c) => {
                const actions = getActions(
                  c,
                  planState(c).state !== "none" &&
                    planState(c).state !== "expired",
                );
                return actions.length > 0 ? (
                  <DeprecatedPopoverMenu
                    positions={["bottom"]}
                    align="end"
                    options={actions}
                  >
                    {(onClick) => (
                      <div onClick={onClick}>
                        <IconButton
                          theme="tertiary"
                          icon="dotsVertical"
                          size="sm"
                        />
                      </div>
                    )}
                  </DeprecatedPopoverMenu>
                ) : null;
              },
            },
          ]}
        />
      )}
    </>
  );
};

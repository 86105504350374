import React, { useState } from "react";
import { StripeSettingsToggleProps } from ".";
import { Toggle } from "components/Toggle";

export const LeaveInvoicesInDraftToggle: React.FC<
  StripeSettingsToggleProps
> = ({ isChecked, handleToggleOnChange }) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <Toggle
      toggled={isCheckedValue}
      label="Leave invoices as drafts"
      onChange={(meta: { toggled: boolean }) =>
        handleToggleOnChange(
          meta.toggled,
          "leave_invoices_in_draft",
          setIsCheckedValue,
        )
      }
      supportingText="You will have to manually advance them before collection"
    />
  );
};

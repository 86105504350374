import { renderDate } from "lib/time";
import Decimal from "decimal.js";

import Papa from "papaparse";
import {
  displayCreditsInCurrencyWithoutRounding,
  displayCreditTypeName,
} from "app/lib/credits";
import { DownloadCsvFieldsFragment } from "./fragments.graphql";
import { SubLineItemFieldsFragment } from "components/deprecated/Invoice/components/LineItem/fragments.graphql";
import {
  CreditLineItem,
  MinimumLineItem,
  TierChargeLineItem,
} from "types/generated-graphql/__types__";

function getSubLineItem(
  subLineItem: SubLineItemFieldsFragment,
  lineItemName: string,
): DownloadCsvLineItem {
  return {
    name: `${lineItemName} - ${subLineItem.display_name}`,
    quantity: "quantity" in subLineItem ? subLineItem.quantity : "1",
    unitPrice:
      "unit_price" in subLineItem && subLineItem.unit_price
        ? displayCreditsInCurrencyWithoutRounding(
            new Decimal(subLineItem.unit_price),
            subLineItem.credit_type,
            true,
          )
        : "-",
    total: displayCreditsInCurrencyWithoutRounding(
      new Decimal(subLineItem.total),
      subLineItem.credit_type,
      true,
    ),
    creditType: displayCreditTypeName(subLineItem.credit_type),
  };
}

function getTieredSubLineItem(
  tier: TierChargeLineItem,
  lineItemName: string,
  subLineItemName: string,
): DownloadCsvLineItem {
  return {
    name: `${lineItemName} - ${subLineItemName}, ${tier.display_name}`,
    quantity: tier.quantity,
    unitPrice: displayCreditsInCurrencyWithoutRounding(
      new Decimal(tier.tier_price),
      tier.credit_type,
      true,
    ),
    total: displayCreditsInCurrencyWithoutRounding(
      new Decimal(tier.total),
      tier.credit_type,
      true,
    ),
    creditType: displayCreditTypeName(tier.credit_type),
  };
}

export function convertSubLineItemsToLineItems(
  invoice: DownloadCsvFieldsFragment,
): DownloadCsvLineItem[] {
  return invoice.line_items.flatMap((lineItem) => {
    if ("sub_line_items" in lineItem) {
      return (lineItem.sub_line_items as SubLineItemFieldsFragment[]).flatMap(
        (subLineItem) => {
          if ("tiers" in subLineItem) {
            return (subLineItem.tiers as TierChargeLineItem[]).flatMap(
              (tier) => {
                return getTieredSubLineItem(
                  tier,
                  lineItem.display_name,
                  subLineItem.display_name,
                );
              },
            );
          }
          return getSubLineItem(subLineItem, lineItem.display_name);
        },
      );
    } else {
      let displayName = lineItem.display_name;
      let pricingGroupValues: Record<string, string> | undefined;
      let presentationGroupValues: Record<string, string | null> | undefined;
      if (lineItem.__typename === "CreditLineItem") {
        displayName =
          `Grant applied: ` + (lineItem as CreditLineItem).credit_grant.name;
      } else if (lineItem.__typename === "MinimumLineItem") {
        displayName = `Invoice minimum (${displayCreditsInCurrencyWithoutRounding(
          new Decimal((lineItem as MinimumLineItem).minimum),
          lineItem.credit_type,
          true,
        )})`;
      } else if (lineItem.__typename === "ContractUsageLineItem") {
        pricingGroupValues = lineItem.pricing_group_values
          ? Object.fromEntries(
              lineItem.pricing_group_values.map(({ name, value }) => [
                name,
                value,
              ]),
            )
          : undefined;

        presentationGroupValues = lineItem.presentation_group_values
          ? Object.fromEntries(
              lineItem.presentation_group_values.map(({ name, value }) => [
                name,
                value,
              ]),
            )
          : undefined;
      }
      return {
        name: displayName,
        quantity:
          "quantity" in lineItem && lineItem.quantity
            ? String(lineItem.quantity)
            : "1",
        unitPrice:
          "unit_price" in lineItem && lineItem.unit_price
            ? displayCreditsInCurrencyWithoutRounding(
                new Decimal(String(lineItem.unit_price)),
                lineItem.credit_type,
                true,
              )
            : "-",
        total: displayCreditsInCurrencyWithoutRounding(
          new Decimal(lineItem.total),
          lineItem.credit_type,
          true,
        ),
        creditType: displayCreditTypeName(lineItem.credit_type),
        pricingGroupValues,
        presentationGroupValues,
      } satisfies DownloadCsvLineItem;
    }
  });
}

export interface DownloadCsvLineItem {
  name: string;
  quantity: string;
  unitPrice: string;
  total: string;
  creditType: string;
  pricingGroupValues?: Record<string, string>;
  presentationGroupValues?: Record<string, string | null>;
}

export function downloadCSV(
  customerName: string,
  invoice: DownloadCsvFieldsFragment,
  invoiceEndDate: Date,
  showChargesWithZeroUsage: boolean,
) {
  const lineItems = convertSubLineItemsToLineItems(invoice) || [];
  const hasPricingGroupValues = lineItems.some(
    (item) => item.pricingGroupValues,
  );

  const hasPresentationGroupValues = lineItems.some(
    (item) => item.presentationGroupValues,
  );

  const headers = ["Name", "Quantity", "Unit Price", "Total", "Credit Type"];
  if (hasPricingGroupValues) {
    headers.push("Pricing Group Values");
  }

  if (hasPresentationGroupValues) {
    headers.push("Presentation Group Values");
  }

  const rows = [
    headers,
    ...lineItems
      .filter((item) => showChargesWithZeroUsage || item.quantity !== "0")
      .map((item) => {
        const rowData = [
          item.name,
          item.quantity,
          item.unitPrice,
          item.total,
          item.creditType,
        ];

        if (hasPricingGroupValues) {
          rowData.push(
            item.pricingGroupValues
              ? JSON.stringify(item.pricingGroupValues)
              : "",
          );

          if (hasPresentationGroupValues) {
            rowData.push(
              item.presentationGroupValues
                ? JSON.stringify(item.presentationGroupValues)
                : "",
            );
          }
        }

        return rowData;
      }),
    [
      "Total",
      null,
      null,
      displayCreditsInCurrencyWithoutRounding(
        new Decimal(invoice.total),
        invoice.credit_type,
        true,
      ),
      displayCreditTypeName(invoice.credit_type),
    ],
  ];

  const link = document.createElement("a");
  link.setAttribute(
    "href",
    encodeURI(`data:text/csv;charset=utf-8,${Papa.unparse(rows)}`),
  );
  link.setAttribute(
    "download",
    `${customerName} ${renderDate(invoiceEndDate, {
      isUtc: true,
    })} ${displayCreditTypeName(invoice.credit_type)}.csv`,
  );
  document.body.appendChild(link); // Required for FF
  link.click();
}

import React from "react";
import Papa from "papaparse";
import { Tooltip } from "design-system";
import { Button } from "components/Button";
import { useEventsLazyQuery } from "app/pages/Connections/tabs/Events/queries.graphql";
import { useEnvironment } from "app/lib/environmentSwitcher/context";

type CSVDownloadButtonProps = {
  startingAfter: Date;
  endingBefore: Date;
  ingest_aliases?: string[];
  disabled?: boolean;
  duplicates?: boolean;
  billableMetricIDs?: string[];
};

// Max currently allowed by the resolver, but could maybe raise this in the future
const LIMIT = 1000;

export const CSVDownloadButton: React.FC<CSVDownloadButtonProps> = (props) => {
  const { environmentType } = useEnvironment();

  // Apollo hooks will sometimes refetch data as props change etc, even though they're "lazy"
  // To work around this, we just use a state variable to track whether we're currently downloading
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [download, { loading }] = useEventsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (resp) => {
      if (!isDownloading) {
        return;
      }
      const rows = [
        [
          "timestamp",
          "transaction_id",
          "customer_id",
          "event_type",
          "properties",
        ],
        ...(resp?.mri_events ?? []).map(
          ({
            timestamp,
            transaction_id,
            customer_id,
            event_type,
            properties,
          }) => [
            timestamp,
            transaction_id,
            customer_id,
            event_type,
            JSON.stringify(properties),
          ],
        ),
      ];
      const csvContent = Papa.unparse(rows);
      const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvContent,
      )}`;
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `metronome-events.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    },
  });

  return (
    <div className="flex items-center justify-between gap-8">
      <Tooltip content={`Download the latest ${LIMIT.toLocaleString()} events`}>
        <Button
          disabled={props.disabled}
          loading={loading && isDownloading}
          onClick={async () => {
            setIsDownloading(true);
            await download({
              variables: {
                limit: LIMIT,
                starting_after: props.startingAfter.toISOString(),
                ending_before: props.endingBefore.toISOString(),
                ingest_aliases: props.ingest_aliases,
                environment_type: environmentType,
                duplicates: props.duplicates,
                billable_metric_ids: props.billableMetricIDs,
              },
            });
          }}
          text="CSV"
          theme="secondary"
          leadingIcon="download02"
        />
      </Tooltip>
    </div>
  );
};

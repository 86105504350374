/* 6de44f96a653d4b6ffa1a4631f30d58ecb7ccd4f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientCustomerGrowthQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientCustomerGrowthQuery = { __typename?: 'Query', client_new_customers: { __typename?: 'NewCustomers', ending_before: string, total: string, by_day: Array<{ __typename?: 'NewCustomers_ByDay', date: string, count: string }> } | null, Client: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number } }> };


export const ClientCustomerGrowthDocument = gql`
    query ClientCustomerGrowth {
  client_new_customers {
    ending_before
    total
    by_day {
      date
      count
    }
  }
  Client {
    id
    customer_count(is_archived: false) {
      count
    }
  }
}
    `;

/**
 * __useClientCustomerGrowthQuery__
 *
 * To run a query within a React component, call `useClientCustomerGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCustomerGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCustomerGrowthQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientCustomerGrowthQuery(baseOptions?: Apollo.QueryHookOptions<ClientCustomerGrowthQuery, ClientCustomerGrowthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientCustomerGrowthQuery, ClientCustomerGrowthQueryVariables>(ClientCustomerGrowthDocument, options);
      }
export function useClientCustomerGrowthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientCustomerGrowthQuery, ClientCustomerGrowthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientCustomerGrowthQuery, ClientCustomerGrowthQueryVariables>(ClientCustomerGrowthDocument, options);
        }
export type ClientCustomerGrowthQueryHookResult = ReturnType<typeof useClientCustomerGrowthQuery>;
export type ClientCustomerGrowthLazyQueryHookResult = ReturnType<typeof useClientCustomerGrowthLazyQuery>;
export type ClientCustomerGrowthQueryResult = Apollo.QueryResult<ClientCustomerGrowthQuery, ClientCustomerGrowthQueryVariables>;
import React, { useState } from "react";
import { useRequiredParam } from "app/lib/routes/params";
import { useNavigate } from "app/lib/useNavigate";
import { useProductQuery } from "./data/productQueries.graphql";
import NotFoundPage from "app/pages/404";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import {
  AvatarWithName,
  Body,
  Caption,
  Sidenote,
  Subtitle,
  Badge,
  Icon,
} from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedGroupInfo } from "components/deprecated/GroupInfo";
import { DeprecatedCards } from "components/deprecated/Cards";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";
import ArchiveProductModal from "./components/ArchiveProductModal";
import { Tooltip } from "design-system";
import { ChargeTypeEnum_Enum } from "types/generated-graphql/__types__";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { ArchiveProductDocument } from "app/pages/Offering/tabs/Products/components/ArchiveProductModal/queries.graphql";
import { EditProductDocument } from "app/pages/Offering/tabs/Products/EditProduct/data/queries.graphql";
import { ProductContainer } from "./ProductContainer";
import { DeprecatedPanel } from "components/deprecated/Panel";
import { DeprecatedDefinitionList } from "components/deprecated/DefinitionList";
import { useContractsEnabled } from "app/lib/contracts/useContractsEnabled";

const ProductView: React.FC = () => {
  const navigate = useNavigate();
  const product_id = useRequiredParam("id");
  const { data, loading, error } = useProductQuery({
    variables: { product_id },
  });
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const product = data?.Product;

  const canArchiveProduct = !!useAuthCheck(ArchiveProductDocument, true)
    .allowed;
  const canEditProduct = !!useAuthCheck(EditProductDocument, true).allowed;
  const contractsEnabled = useContractsEnabled();

  if (loading) {
    return (
      <ProductContainer product="loading">
        <div className="pt-12">
          <DeprecatedTextSkeleton />
          <DeprecatedTextSkeleton />
          <DeprecatedTextSkeleton />
          <DeprecatedTextSkeleton />
        </div>
      </ProductContainer>
    );
  }

  if (error) {
    return <ProductContainer error={error} />;
  }

  if (!product) {
    return <NotFoundPage />;
  }

  const actions =
    product.deprecated_at === null
      ? [
          {
            content: "Manage custom fields...",
            onClick: () => navigate(`/custom-fields/product/${product.id}`),
          },
          gatedAction(canEditProduct, {
            content: "Edit product...",
            onClick: () =>
              navigate(`/offering/plans/products/${product.id}/edit`),
          }),
          gatedAction(canArchiveProduct, {
            disabled: product.active_plan_count > 0,
            content:
              product.active_plan_count === 0 ? (
                "Archive product..."
              ) : (
                <Tooltip content="Products in use cannot be archived">
                  Archive product...
                </Tooltip>
              ),
            onClick: () => setArchiveModalOpen(true),
          }),
        ]
      : undefined;

  return (
    <ProductContainer
      product={product}
      action={
        actions && (
          <DeprecatedPopoverMenu
            positions={["bottom"]}
            align="end"
            options={actions}
          >
            {(onClick) => (
              <IconButton
                onClick={onClick}
                theme="secondary"
                icon="dotsVertical"
              />
            )}
          </DeprecatedPopoverMenu>
        )
      }
    >
      {archiveModalOpen && (
        <ArchiveProductModal
          onClose={() => setArchiveModalOpen(false)}
          productId={product.id}
          productName={product.name}
        />
      )}
      <div className="pt-12">
        {contractsEnabled ? (
          <DeprecatedPanel title="Product details" contentClassName="p-12">
            <Body level={2} className="w-1/2 min-w-[500px]">
              {product.description}
            </Body>
            {product.Actor && (
              <div className="mb-24 mt-12 flex items-center justify-start gap-8">
                <AvatarWithName {...product.Actor} />
                <DeprecatedCopyableID id={product.id} label="product ID" />
              </div>
            )}
            <div className="grid grid-cols-4">
              <DeprecatedDefinitionList
                list={[
                  {
                    label: "Product grouping",
                    value: product.group_key
                      ? `Usage-based charges are grouped by ${product.group_key}`
                      : "None",
                  },
                ]}
              ></DeprecatedDefinitionList>
            </div>
          </DeprecatedPanel>
        ) : (
          <>
            {product.Actor && (
              <div className="mb-12 flex content-center gap-12">
                <AvatarWithName {...product.Actor} />
                <DeprecatedCopyableID id={product.id} label="product ID" />
              </div>
            )}
            <Body level={2} className="mb-8 max-w-[600px]">
              {product.description}
            </Body>
            <Subtitle
              level={1}
              className=" border-y border-y-deprecated-gray-100 px-0 py-8"
            >
              Charges
              {product.group_key && (
                <Sidenote>
                  {" "}
                  (Usage-based charges are grouped by{" "}
                  <DeprecatedGroupInfo groupKey={product.group_key} />)
                </Sidenote>
              )}
            </Subtitle>
          </>
        )}
        <DeprecatedCards
          gap={contractsEnabled ? "24" : "12"}
          cards={product.ProductPricingFactors.map((pf) => ({
            title: (
              <>
                <DeprecatedCopyableID hideID label="charge ID" id={pf.id} />
                {pf.name}
              </>
            ),
            actions: [
              {
                onClick: () => navigate(`/custom-fields/charge/${pf.id}`),
                content: "Manage custom fields...",
              },
            ],
            content: (
              <>
                <Caption className="mt-4 flex items-center">
                  <Icon icon="analytics" className="mr-4" /> Billable Metric
                </Caption>
                <div className="mx-0 my-4 text-sm font-medium leading-1">
                  {pf.BillableMetric?.name ?? pf.seat_metric?.name ?? "N/A"}
                </div>
                {pf.BillableMetric && (
                  <DeprecatedCopyableID
                    label="billable metric ID"
                    id={pf.BillableMetric.id}
                  />
                )}
                {pf.seat_metric && (
                  <DeprecatedCopyableID
                    label="seat metric ID"
                    id={pf.seat_metric.id}
                  />
                )}
              </>
            ),
            badge:
              pf.charge_type_enum === ChargeTypeEnum_Enum.Usage ? (
                <Badge theme="primary" type="dark" className="uppercase">
                  Usage-based
                </Badge>
              ) : pf.charge_type_enum === ChargeTypeEnum_Enum.Flat ? (
                <Badge theme="grey" type="dark" className="uppercase">
                  Fixed
                </Badge>
              ) : pf.charge_type_enum === ChargeTypeEnum_Enum.Seat ? (
                <Badge theme="warning" type="dark" className="uppercase">
                  Seat
                </Badge>
              ) : (
                <Badge
                  theme="success"
                  type="dark"
                  className="bg-deprecated-success-700 uppercase"
                >
                  Composite
                </Badge>
              ),
          }))}
        />
      </div>
    </ProductContainer>
  );
};

export default ProductView;

import Decimal from "decimal.js";

import { creditsFromInput } from "app/lib/credits";
import {
  getRamps,
  getRampStartPeriod,
  getRampStartPeriods,
} from "app/lib/plans/ramps";
import { DraftPlan, Price } from "app/lib/plans/types";
import { CreditType } from "app/types/credit-types";
import { ChargeTypeEnum_Enum } from "types/generated-graphql/__types__";
import { CreatePlanDataQuery } from "../../data/queries.graphql";

export function customPricingUnitsForPlan(plan: DraftPlan): Set<string> {
  const customPricingUnits = new Set<string>();
  (plan.pricedProducts ?? []).forEach((pricedProduct) => {
    if (pricedProduct.creditType && pricedProduct.creditType.client_id) {
      customPricingUnits.add(pricedProduct.creditType.id);
    }
  });
  return customPricingUnits;
}

export const isProductRampPricingDone = (
  productsData: CreatePlanDataQuery,
  draftPlan: DraftPlan,
  productId: string,
  rampIndex: number,
) => {
  const allRamps = draftPlan.ramps ? draftPlan.ramps.concat([{}]) : [{}];
  if (!draftPlan.selectedProductIds || !draftPlan.pricedProducts) {
    return false;
  }

  const thisPricedProduct = draftPlan.pricedProducts.find(
    (pricedProduct) => pricedProduct.productId === productId,
  );
  if (!thisPricedProduct || !thisPricedProduct.creditType?.id) {
    return false;
  }

  const rampStartPeriod = getRampStartPeriod(rampIndex, allRamps);
  const thisRampPricedProductFactors = thisPricedProduct.pricingFactors.filter(
    (pf) => pf.startPeriod === rampStartPeriod,
  );

  if (
    thisRampPricedProductFactors.length !==
    productsData.products.find((product) => product.id === productId)
      ?.ProductPricingFactors.length
  ) {
    return false;
  }

  const areTiersDone = (prices: Price[]) => {
    return prices.length === 1
      ? prices[0].value !== undefined
      : prices.every((price, i) => {
          if (price.value === undefined || price.value === "") {
            return false;
          }
          if (i < prices.length) {
            if (price.metricMinimum === undefined) {
              return false;
            }
            if (i === 0) {
              return price.metricMinimum === 0;
            }
            const prevMetricMin = prices[i - 1].metricMinimum;
            if (prevMetricMin === undefined) {
              return false;
            }
            return price.metricMinimum > prevMetricMin;
          } else {
            return true;
          }
        });
  };

  const pricingFactorsAreDone = thisRampPricedProductFactors.every((ppf) => {
    if (ppf.chargeType === ChargeTypeEnum_Enum.Flat) {
      if (ppf.flatFees) {
        const definedFlatFeePrices = ppf.flatFees?.map((ff) => {
          return { metricMinimum: ff.metricMinimum, value: ff.value };
        });
        if (!definedFlatFeePrices) {
          return false;
        }
        if (!areTiersDone(definedFlatFeePrices)) {
          return false;
        }
        return (
          ppf.flatFees?.[0]?.quantity &&
          new Decimal(ppf.flatFees[0].quantity).greaterThanOrEqualTo(0)
        );
      }
    }
    if (ppf.chargeType === ChargeTypeEnum_Enum.Seat) {
      const definedSeatPrices = ppf.seatPrices;
      if (!definedSeatPrices) {
        return false;
      }
      return (
        definedSeatPrices?.[0]?.initial_quantity &&
        new Decimal(definedSeatPrices[0].initial_quantity).greaterThanOrEqualTo(
          0,
        ) &&
        new Decimal(definedSeatPrices[0].initial_quantity).isInteger() &&
        definedSeatPrices[0]?.value &&
        new Decimal(definedSeatPrices[0].value).greaterThanOrEqualTo(0)
      );
    }
    if (ppf.chargeType === ChargeTypeEnum_Enum.Usage) {
      const definedUsagePrices = ppf.prices;
      if (!definedUsagePrices) {
        return false;
      }
      return areTiersDone(definedUsagePrices);
    }
    if (ppf.chargeType === ChargeTypeEnum_Enum.Composite) {
      const definedCompositeCharge = ppf.compositeCharge;
      return (
        definedCompositeCharge &&
        // Tiered composite charges are not supported
        definedCompositeCharge.length === 1 &&
        definedCompositeCharge[0].quantity != null &&
        (new Decimal(definedCompositeCharge[0].quantity).eq(0) ||
          new Decimal(definedCompositeCharge[0].quantity).eq(1)) &&
        definedCompositeCharge[0].value != null &&
        !new Decimal(definedCompositeCharge[0].value).lessThan(-1) &&
        definedCompositeCharge[0].compositeMinimum === 0 &&
        definedCompositeCharge[0].pricingFactors?.length &&
        definedCompositeCharge[0].type !== undefined
      );
    }
  });
  return pricingFactorsAreDone;
};

export const isRampPricingDone = (
  productsData: CreatePlanDataQuery,
  draftPlan: DraftPlan,
  rampIndex: number,
) => {
  if (!draftPlan.selectedProductIds) {
    return false;
  }

  if (draftPlan.hasMinimums) {
    if (
      !isInvoiceMinimumDone(
        getRampStartPeriod(rampIndex, draftPlan.ramps || []),
        draftPlan,
      )
    ) {
      return false;
    }
  }

  return Array.from(draftPlan.selectedProductIds).every((productId) =>
    isProductRampPricingDone(productsData, draftPlan, productId, rampIndex),
  );
};

export const isAllProductPricingDone = (
  productsData: CreatePlanDataQuery,
  draftPlan: DraftPlan,
) => {
  if (
    !draftPlan.selectedProductIds ||
    !draftPlan.pricedProducts ||
    draftPlan.selectedProductIds.length !== draftPlan.pricedProducts.length
  ) {
    return false;
  }

  const allRamps = draftPlan.ramps ? draftPlan.ramps.concat([{}]) : [{}];
  const allRampsAreDone = allRamps.every((_, i) =>
    isRampPricingDone(productsData, draftPlan, i),
  );
  if (!allRampsAreDone) {
    return false;
  }

  if (draftPlan.hasMinimums) {
    const invoiceMinimums = draftPlan.minimums;
    if (!invoiceMinimums || invoiceMinimums.length !== allRamps.length) {
      return false;
    }
    return allRamps.every((_, i) => {
      const rampStartPeriod = getRampStartPeriod(i, allRamps);
      if (!isInvoiceMinimumDone(rampStartPeriod, draftPlan)) {
        return false;
      }
    });
  }

  if (
    draftPlan.pricedProducts.some(
      (pp) => pp.creditType && pp.creditType.client_id !== null,
    )
  ) {
    if (!isOveragePricingDone(draftPlan)) {
      return false;
    }
  }

  return true;
};

export const isInvoiceMinimumDone = (
  rampStartPeriod: number,
  draftPlan: DraftPlan,
) => {
  if (!draftPlan.hasMinimums) {
    return true;
  }

  const minimum = draftPlan.minimums?.find(
    (min) =>
      min.startPeriod === rampStartPeriod &&
      min.value !== undefined &&
      min.value !== "",
  );

  if (!minimum || !minimum.value || !minimum.creditType) {
    return false;
  }

  return creditsFromInput(
    minimum.value,
    minimum.creditType,
  ).greaterThanOrEqualTo(
    getInvoiceMinimumFloor(draftPlan, rampStartPeriod, minimum.creditType),
  );
};

export const getInvoiceMinimumFloor = (
  draftPlan: DraftPlan,
  rampStartPeriod: number,
  creditType?: CreditType,
) => {
  let minimumFloor = new Decimal(0);

  if (!creditType) {
    return minimumFloor;
  }

  const flatFeesForRamp = draftPlan.pricedProducts
    ?.filter((pp) => pp.creditType?.id === creditType.id)
    .flatMap((pp) =>
      pp.pricingFactors.filter(
        // We only care about:
        //   1. flat fees, that are
        //   2. for this ramp, and
        //   3. are collected each billing period.
        // This way we don't prevent someone from creating a minimum that's
        // less than, say, a $100,000 one-time charge at contract start.
        (pf) =>
          pf.startPeriod === rampStartPeriod &&
          pf.chargeType === ChargeTypeEnum_Enum.Flat &&
          (pf.flatFees?.[0]?.collectionSchedule === "ARREARS" ||
            (pf.flatFees?.[0]?.collectionSchedule === "ADVANCE" &&
              pf.flatFees?.[0]?.collectionInterval === 1)),
      ),
    );
  const flatFeesAsNumbers = flatFeesForRamp?.flatMap((fee) =>
    fee.flatFees
      ? creditsFromInput(fee.flatFees[0].value, creditType)
      : new Decimal(0),
  );
  minimumFloor = minimumFloor.add(
    flatFeesAsNumbers?.reduce((acc, curr) => acc.add(curr), new Decimal(0)) ||
      new Decimal(0),
  );

  return minimumFloor;
};

export const isOveragePricingDone = (draftPlan: DraftPlan) => {
  const allCustomCreditTypes = customPricingUnitsForPlan(draftPlan);

  if (allCustomCreditTypes.size === 0) {
    return true;
  }

  if (!draftPlan.creditTypeConversions) {
    return false;
  }

  const distinctConversionStartPeriods = new Set<number>(
    draftPlan.creditTypeConversions?.map((conv) => conv.startPeriod),
  );

  // The double-every loop below does the heavy lifting of making sure we
  // have the right set of conversions for all the ramps. This check is
  // part checksum, and part making sure we won't write extra conversions.
  if (distinctConversionStartPeriods.size > 1) {
    // Assume that if any conversion has a startPeriod > 0, they all should.
    // Check that:
    // number of conversions  =  the number of custom credit types
    //                           x number of distinct start periods
    if (
      draftPlan.creditTypeConversions.length !==
      allCustomCreditTypes.size * getRamps(draftPlan).length
    ) {
      return false;
    }
  }

  return Array.from(allCustomCreditTypes.values()).every((ct) => {
    let rampStartPeriods: number[];
    if (distinctConversionStartPeriods.size > 1) {
      rampStartPeriods = getRampStartPeriods(getRamps(draftPlan));
    } else {
      rampStartPeriods = [0];
    }
    return rampStartPeriods.every((sp) => {
      return !!draftPlan.creditTypeConversions?.find(
        (conv) =>
          conv.startPeriod === sp &&
          conv.customCreditType.id === ct &&
          conv.toFiatConversionFactor !== undefined,
      );
    });
  });
};

import React from "react";
import Decimal from "decimal.js";
import { Icon, Tooltip } from "design-system";
import styles from "./index.module.less";

export const TieredOverride: React.FC<{
  tiers: { size?: Decimal; multiplier: Decimal }[];
}> = ({ tiers }) => {
  let prevEnd = new Decimal(0);
  const tierDescriptions = tiers.map((tier, index) => {
    const startUnit = prevEnd;

    const endUnit =
      tier.size !== undefined
        ? new Decimal(tier.size).plus(startUnit)
        : undefined;

    prevEnd = endUnit || prevEnd;
    const range = endUnit ? `(>${startUnit} - ${endUnit})` : `(>${startUnit})`;

    return `Tier ${index + 1} ${range}\n${tier.multiplier.toString()} multiplier`;
  });

  // Tooltip content combines all tier descriptions
  const tooltipContent = (
    <div>
      {tierDescriptions.map((desc, idx) => (
        <React.Fragment key={idx}>
          {desc.split("\n").map((line, lineIdx) => (
            <div key={lineIdx}>{line}</div>
          ))}
          {idx < tierDescriptions.length - 1 ? <br /> : null}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="flex items-center justify-end gap-4">
      <Tooltip inline={true} content={tooltipContent}>
        <Icon icon="listCircle" className={styles.tieredRateIcon} />
      </Tooltip>
      <p>Tiered Multiplier</p>
    </div>
  );
};

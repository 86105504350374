import { useRequiredParam } from "app/lib/routes/params";

import { useApolloResp } from "../../../lib/ApolloResp";
import { useMinimalContractFromRouteQuery } from "./data.graphql";

export function useMinimumContractFromRoute() {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");

  return useApolloResp(
    useMinimalContractFromRouteQuery({ variables: { customerId, contractId } }),
  );
}

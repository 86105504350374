import { Select, Input, Sidenote } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import React, { useEffect } from "react";
import { ManagedEntityEnum_Enum } from "types/generated-graphql/__types__";
import { filterFieldNames } from "app/lib/alerts";

export type CustomFieldFilter = {
  entity: ManagedEntityEnum_Enum | "Contract";
  key: string;
  value: string;
};

export const CustomFieldAdvancedFilters: React.FC<{
  advancedFilters: CustomFieldFilter[];
  setAdvancedFilters: (newValue: CustomFieldFilter[]) => void;
  availableCustomFieldKeys: string[];
  entityType: ManagedEntityEnum_Enum;
}> = ({
  advancedFilters,
  setAdvancedFilters,
  availableCustomFieldKeys,
  entityType,
}) => {
  useEffect(() => {
    if (advancedFilters.length === 0 && availableCustomFieldKeys.length > 0) {
      // Add default empty filter on open
      setAdvancedFilters(
        advancedFilters.concat({ key: "", value: "", entity: entityType }),
      );
    }
  }, []);
  if (availableCustomFieldKeys.length === 0) {
    return (
      <Sidenote className="align-right ml-[20px] mr-8 mt-12 whitespace-nowrap pb-8">
        {`Create a custom field on the ${entityType} entity to use advanced filters.`}
      </Sidenote>
    );
  }
  const titleText = `Filter by ${filterFieldNames[entityType].toLowerCase()} custom field:`;
  return (
    <div className="w-min">
      {advancedFilters.length === 0 && (
        <span className="flex flex-nowrap justify-end">
          <Sidenote className="align-right ml-[20px] mr-8 mt-12 whitespace-nowrap pb-8">
            {titleText}
          </Sidenote>
        </span>
      )}
      {advancedFilters.map((advancedFilter, index) => {
        const rowText = index === 0 ? titleText : "and:";
        return (
          <span className="flex flex-nowrap justify-end">
            <Sidenote className="align-right ml-[20px] mr-8 mt-12 whitespace-nowrap pb-8">
              {rowText}
            </Sidenote>
            <span className="ml-12 mt-4 inline-flex items-center">
              <Select
                placeholder="Select custom_field key"
                options={
                  availableCustomFieldKeys
                    ? availableCustomFieldKeys.map((key) => {
                        return {
                          label: key,
                          value: key,
                        };
                      })
                    : []
                }
                onChange={(val) => {
                  setAdvancedFilters([
                    ...advancedFilters.slice(0, index),
                    {
                      ...advancedFilters[index],
                      key: val ?? "",
                    },
                    ...advancedFilters.slice(index + 1),
                  ]);
                }}
                value={advancedFilter.key ?? ""}
                className="mr-8 w-[200px]"
              />
              <Select
                options={[{ label: "is", value: "is" }]}
                placeholder="is"
                value="is"
                onChange={() => {}}
                className="mr-8 w-[60px]"
                __internalComponentOverrides={{
                  DropdownIndicator: () => null,
                }}
              />
              <Input
                placeholder="Enter a custom_field value"
                onChange={(val) => {
                  setAdvancedFilters([
                    ...advancedFilters.slice(0, index),
                    {
                      ...advancedFilters[index],
                      value: val ?? "",
                    },
                    ...advancedFilters.slice(index + 1),
                  ]);
                }}
                className="mr-8 w-[185px]"
                value={advancedFilter.value ?? ""}
              />
              <IconButton
                className="mr-8"
                onClick={() => {
                  setAdvancedFilters([
                    ...advancedFilters.slice(0, index),
                    ...advancedFilters.slice(index + 1),
                  ]);
                }}
                theme="secondary"
                icon="xCircle"
              />
            </span>
          </span>
        );
      })}
      <div className="mr-[93px] mt-[2px] text-right">
        <Button
          className="mt-4"
          onClick={() => {
            setAdvancedFilters(
              advancedFilters.concat({
                key: "",
                value: "",
                entity: entityType,
              }),
            );
          }}
          text={`Add ${advancedFilters.length > 0 && " another"}`}
          theme="secondary"
          leadingIcon="plus"
        />
      </div>
    </div>
  );
};

import React, { useState } from "react";

import { Input } from "design-system";
import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";

import type { CreditType, CustomCreditType } from "app/types/credit-types";
import { displayCreditTypeName } from "app/lib/credits";
import {
  useInsertCreditTypeMutation,
  CreditTypesDocument,
  CreditTypesQuery,
  CreditTypesQueryVariables,
} from "./queries.graphql";
import { useEnvironment } from "app/lib/environmentSwitcher/context";

type NewPricingUnitProps = {
  onClose: (credit_type?: CustomCreditType) => void;
  creditTypes: CreditType[];
};

const NO_NAME_ERROR_MESSAGE =
  "You must specify a name for the new pricing unit.";
const NAME_ALREADY_IN_USE_MESSAGE = "This pricing unit name already exists.";

const DeprecatedNewPricingUnitModal: React.FC<NewPricingUnitProps> = ({
  onClose,
  creditTypes,
}) => {
  const { environmentType } = useEnvironment();
  const [insertCreditTypeMutation, results] = useInsertCreditTypeMutation();
  const [name, setName] = useState<string>();
  const nameErrorMessage =
    name === ""
      ? NO_NAME_ERROR_MESSAGE
      : name && creditTypes.map(displayCreditTypeName).includes(name)
        ? NAME_ALREADY_IN_USE_MESSAGE
        : "";

  const save = async () => {
    if (name) {
      const result = await insertCreditTypeMutation({
        variables: {
          name,
        },
        update(cache, { data }) {
          if (data?.insert_CreditType_one) {
            const existing = cache.readQuery<CreditTypesQuery>({
              query: CreditTypesDocument,
              variables: {
                environment_type: environmentType,
              },
            });
            cache.writeQuery<CreditTypesQuery, CreditTypesQueryVariables>({
              data: {
                CreditType: [
                  ...(existing?.CreditType || []),
                  data?.insert_CreditType_one,
                ],
              },
              query: CreditTypesDocument,
              variables: {
                environment_type: environmentType,
              },
            });
          }
        },
      });
      const creditType = result.data?.insert_CreditType_one;
      if (creditType) {
        // We know that the returned credit type will always have a client_id,
        // so this cast is safe.
        onClose(creditType as CustomCreditType);
      }
    }
  };

  const actionButtons = (
    <Button
      key="primary"
      disabled={!name || !!nameErrorMessage || results.loading}
      onClick={save}
      loading={results.loading}
      text="Save"
      theme="primary"
    />
  );

  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen
      onRequestClose={onClose}
      title="Create pricing unit"
    >
      <Body level={2}>
        Add a new custom pricing unit. The name of your pricing unit will appear
        on Metronome invoices.
      </Body>
      <div>
        <Input
          name="Pricing unit name"
          placeholder="Enter name"
          value={name ?? ""}
          success={!!name}
          error={nameErrorMessage}
          autoFocus
          onKeyDown={async (e) => {
            if (e.key === "Enter" && name && !nameErrorMessage) {
              await save();
            }
          }}
          onChange={setName}
        />
      </div>
    </DeprecatedPopup>
  );
};

export default DeprecatedNewPricingUnitModal;

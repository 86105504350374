import React, { useState, useContext, useRef } from "react";
import { DeprecatedSnackStack, Message as MessageWithID } from "./SnackStack";

type Message = Omit<MessageWithID, "id">;

type SnackbarContext = (message: Message) => void;

// initialize this with null as the context becuase we need to pass something.
const Context = React.createContext<SnackbarContext>(null as any);

export const DeprecatedSnackbarProvider: React.FC<React.PropsWithChildren> = (
  props,
) => {
  const messagesRef = useRef<MessageWithID[]>([]);
  const [messages, setMessages] = useState(messagesRef.current);

  function removeMessage(id: string) {
    messagesRef.current = messagesRef.current.filter((m) => m.id !== id);
    setMessages(messagesRef.current);
  }

  const pushMessage = (message: Message) => {
    const id = Math.random().toString(16);
    messagesRef.current = messagesRef.current.concat({
      ...message,
      id,
    });
    setMessages(messagesRef.current);

    setTimeout(() => removeMessage(id), message.durationMS || 5000);
  };

  return (
    <Context.Provider value={pushMessage}>
      {props.children}
      <DeprecatedSnackStack messages={messages} />
    </Context.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(Context);
};

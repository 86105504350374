import { EmptyState } from "components/EmptyState";
import { Select } from "design-system";
import { BlockSkeleton } from "components/deprecated/Skeleton";
import { DeprecatedTabs } from "components/deprecated/Tabs";
import { Body, Caption, Label, Subtitle } from "design-system";

import Decimal from "decimal.js";
import {
  displayCreditTypeName,
  RoundedCurrency,
  USD_CREDIT_TYPE,
} from "app/lib/credits";
import { useSearchParam } from "app/lib/routes/useSearchParam";
import React, { useEffect, useState } from "react";
import { CreditType, FiatCreditType } from "app/types/credit-types";

import { GetAllCreditGrantsAndLedgersQuery } from "./data/credit-overview.graphql";
import {
  CreditGrantList,
  CreditGrantListSkeleton,
} from "./components/CreditGrantList";
import CreditLedger from "./components/CreditLedger";
import { IssueCreditGrantPane } from "./components/IssueCreditGrantPane";
import { IssueCreditGrantDocument } from "./components/IssueCreditGrantPane/queries.graphql";
import { MigrateCreditGrantsDocument } from "./components/MigrateCreditGrantsModal/queries.graphql";
import { GatedButton } from "components/GatedButton";
import classNames from "classnames";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import {
  BillingProviderEnum_Enum,
  RolloverSettings,
} from "types/generated-graphql/__types__";
import { useFeatureFlag } from "app/lib/launchdarkly";
import MigrateCreditGrantsModal from "./components/MigrateCreditGrantsModal";
import { Tooltip } from "components/Tooltip";
import { useCreditGrants } from "hooks/useCreditGrants";

export type IssuedCreditGrant = {
  id: string;
  costBasis: string;
  createdAt: Date;
  effectiveAt: Date;
  expiresBefore: Date;
  name: string;
  priority: string;
  reason: string | null;
  billingProvider: BillingProviderEnum_Enum | null;
  amountGranted: string;
  amountPaid: string;
  creatorId?: string;
  creatorName?: string;
  amountGrantedCreditType: CreditType;
  amountPaidCreditType: FiatCreditType;
  ledger: Ledger;
  voidedAt: Date | null;
  creditGrantType: string | null;
  products:
    | {
        id: string;
        name: string;
      }[]
    | null;
  invoice: {
    issued_at: Date;
  } | null;
  rolloverSettings?: RolloverSettings;
  rolledOverFromId?: string;
};

type GraphqlCreditGrant = NonNullable<
  GetAllCreditGrantsAndLedgersQuery["customer"]
>["CreditGrants"][0];

type GraphqlLedger = NonNullable<
  GetAllCreditGrantsAndLedgersQuery["customer"]
>["ledgers"][0];

export type Ledger = {
  creditType: CreditType;
  availableBalance: Decimal;
  consumed: Decimal;
  expired: Decimal;
  totalGranted: Decimal;
  ledgerEntries: LedgerEntry[];
};

export type LedgerEntry = {
  __typename: NonNullable<
    GetAllCreditGrantsAndLedgersQuery["customer"]
  >["ledgers"][0]["ledger_entries"][0]["__typename"];
  creditType: CreditType;
  grant: {
    id: string;
    name: string;
    costBasis: string;
    AmountPaidCreditType: CreditType;
    voidedAt: string | null;
    Voider: {
      id: string | null;
      name: string | null;
    } | null;
  };
  amount: string;
  runningBalance: string;
  effectiveAt: Date;
  memo: string;
};

type CreditMetricProps = {
  isPrimary: boolean;
  label: string;
  amount: Decimal;
  creditType: CreditType;
};
const CreditMetric: React.FC<CreditMetricProps> = ({
  isPrimary,
  label,
  amount,
  creditType,
}) => {
  return (
    <div className={isPrimary ? "mr-24" : "mr-12 mt-[6px]"}>
      <Caption level={1} className="mb-4 uppercase">
        {label}
      </Caption>
      <span
        className={classNames(
          "mr-4 inline-block font-medium text-default-font",
          isPrimary ? "text-xl leading-4" : "text-base leading-3",
          isPrimary ? "text-success-700 " : "text-gray-800",
        )}
      >
        <RoundedCurrency creditType={creditType} amount={amount} hideSuffix />
      </span>
      <Body
        level={2}
        className={
          isPrimary
            ? "text-success-700 mb-0 inline-block"
            : "mb-0 inline-block text-gray-800"
        }
      >
        {displayCreditTypeName(creditType)}
      </Body>
    </div>
  );
};

type CreditMetricSkeletonProps = {
  isPrimary: boolean;
  label: string;
};
const CreditMetricSkeleton: React.FC<CreditMetricSkeletonProps> = ({
  isPrimary,
  label,
}) => {
  return (
    <div className={isPrimary ? "mr-24" : "mr-12 mt-[6px]"}>
      <Caption level={1} className="mb-4 uppercase">
        {label}
      </Caption>
      <div className={isPrimary ? "h-32" : "h-[26px]"}>
        <BlockSkeleton />
      </div>
    </div>
  );
};

type CreditTypeSelectProps = {
  creditTypes: CreditType[];
  selectedCreditType: CreditType;
  onChange: (creditType: CreditType) => void;
};
const CreditTypeSelect: React.FC<CreditTypeSelectProps> = ({
  creditTypes,
  selectedCreditType,
  onChange,
}) => {
  const [selectedCreditTypeId, setSelectedCreditTypeId] = useState<string>(
    selectedCreditType.id,
  );

  useEffect(
    () => setSelectedCreditTypeId(selectedCreditType.id),
    [selectedCreditType],
  );

  return (
    <div className="mr-8 flex items-center">
      <Subtitle
        level={4}
        className="text-gray-700 mr-8 font-default font-normal not-italic"
      >
        Pricing unit:
      </Subtitle>
      <Select
        className="w-[136px]"
        onChange={(creditTypeId) => {
          setSelectedCreditTypeId(creditTypeId);
          const selectedCreditType = creditTypes.find(
            (ct) => ct.id === creditTypeId,
          );
          if (selectedCreditType) {
            onChange(selectedCreditType);
          }
        }}
        value={selectedCreditTypeId}
        placeholder="Choose pricing unit"
        options={creditTypes.map((ct, idx) => ({
          label: displayCreditTypeName(ct),
          value: ct.id,
        }))}
      />
    </div>
  );
};

export const parseLedger = (
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date,
  grantId?: string,
): Ledger => {
  let pendingCharges = new Decimal(0);
  let pendingGrants = new Decimal(0);
  let consumed = new Decimal(0);
  let expired = new Decimal(0);
  let totalGranted = new Decimal(0);
  let runningBalance = new Decimal(0);
  let rolledOver = new Decimal(0);

  const formattedEntries = ledger.ledger_entries
    .filter((le) => (grantId ? le.credit_grant.id === grantId : true))
    .filter(
      (le) =>
        !le.credit_grant.voided_at ||
        le.__typename === "MRI_CreditGrantCreditLedgerEntry",
    )
    .map((le) => {
      const amount = new Decimal(le.amount);
      if (!le.credit_grant.voided_at) {
        switch (le.__typename) {
          case "MRI_CreditGrantCreditLedgerEntry":
          case "MRI_PendingRolloverCreditGrantLedgerEntry":
          case "MRI_RolloverCreditGrantLedgerEntry":
            totalGranted = totalGranted.add(amount.abs());
            if (new Date(le.effective_at) > availableBalanceCutoffDate) {
              pendingGrants = pendingGrants.add(amount.abs());
            }
            break;
          case "MRI_PendingRolloverDeductionLedgerEntry":
          case "MRI_RolloverDeductionLedgerEntry":
            rolledOver = rolledOver.plus(amount.abs());
            break;
          case "MRI_PendingChargeCreditLedgerEntry":
            // We don't use the absolute value here because pending charges
            // could be positive (refunds) or negative (deductions).
            // We subtract so that the common case of deductions causes
            // pendingCharges to increase.
            pendingCharges = pendingCharges.sub(amount);
            break;
          case "MRI_CreditExpirationCreditLedgerEntry":
            expired = expired.add(amount.abs());
            break;
          case "MRI_CreditDeductionCreditLedgerEntry":
            // As with pending charges, we don't use the absolute value here.
            consumed = consumed.sub(amount);
            break;
          case "MRI_PendingCreditExpirationCreditLedgerEntry":
            // Nothing to do here. Just adding this type for completeness
            break;
          default:
            le satisfies never;
        }
        runningBalance = runningBalance.add(le.amount);
      }
      return {
        __typename: le.__typename,
        creditType: ledger.credit_type,
        grant: {
          ...le.credit_grant,
          costBasis: le.credit_grant.cost_basis,
          voidedAt: le.credit_grant.voided_at,
          Voider: le.credit_grant.Voider
            ? {
                id: le.credit_grant.Voider?.id,
                name: le.credit_grant.Voider?.name,
              }
            : null,
        },
        amount: le.amount,
        runningBalance: runningBalance.toString(),
        effectiveAt: !le.credit_grant.voided_at
          ? new Date(le.effective_at)
          : new Date(le.credit_grant.voided_at),
        memo: le.memo,
      };
    });

  formattedEntries.sort((a, b) => {
    return a.effectiveAt < b.effectiveAt ? 1 : -1;
  });

  return {
    creditType: ledger.credit_type,
    availableBalance: totalGranted
      .sub(pendingGrants)
      .sub(consumed)
      .sub(expired)
      .sub(rolledOver)
      .sub(pendingCharges),
    consumed: consumed.add(pendingCharges),
    expired: expired,
    // Rollover amount needs to be subtracted from total grant to stop the rolled over portion of a
    // grant from being double counted when a rollover grant gets created.
    totalGranted: totalGranted.sub(rolledOver),
    ledgerEntries: formattedEntries,
  };
};

export const parseCreditGrant = (
  cg: GraphqlCreditGrant,
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date,
): IssuedCreditGrant => {
  return {
    ...cg,
    amountGranted: cg.amount_granted,
    amountPaid: cg.amount_paid,
    costBasis: cg.cost_basis,
    createdAt: new Date(cg.created_at),
    effectiveAt: new Date(cg.effective_at),
    expiresBefore: new Date(cg.expires_before),
    creatorId: cg.Creator?.id,
    creatorName: cg.Creator?.name,
    billingProvider: cg.billing_provider,
    amountGrantedCreditType: cg.AmountGrantedCreditType,
    amountPaidCreditType: cg.AmountPaidCreditType as FiatCreditType,
    ledger: parseLedger(ledger, availableBalanceCutoffDate, cg.id),
    voidedAt: cg.voided_at ? new Date(cg.voided_at) : null,
    invoice: cg.invoice
      ? {
          issued_at: new Date(cg.invoice.issued_at),
        }
      : null,
    creditGrantType: cg.credit_grant_type ?? null,
    rolloverSettings: cg.rollover_settings ?? undefined,
    rolledOverFromId: cg.rolled_over_from?.id,
  };
};

type CreditTabsProps = {
  activeTab: string;
  setActiveTab: (tabName: string) => void;
};
const CreditTabs: React.FC<CreditTabsProps> = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <hr className="bg-gray-100 -ml-12 -mr-12 h-[1px] border-0 border-none p-0 pl-12 pr-12" />
      <div className="flex h-[48px] items-center">
        <Label className="mr-4 font-normal text-gray-600">View:</Label>
        <DeprecatedTabs
          className="h-32 !self-auto"
          tabs={[
            {
              name: "Ledger",
              icon: "list",
              onClick: () => setActiveTab("ledger"),
              active: !activeTab || activeTab === "ledger",
            },
            {
              name: "Grants",
              icon: "cash",
              onClick: () => setActiveTab("grants"),
              active: activeTab === "grants",
            },
          ]}
          type="secondary"
        />
      </div>
      <hr className="bg-gray-100 -ml-12 -mr-12 h-[1px] border-0 border-none p-0 pl-12 pr-12" />
    </>
  );
};

type CreditGrantsTabSkeletonProps = {
  activeTab: string;
  setActiveTab: (tabName: string) => void;
  issueGrantButton: React.ReactNode;
};
const CreditGrantsTabSkeleton: React.FC<CreditGrantsTabSkeletonProps> = ({
  activeTab,
  setActiveTab,
  issueGrantButton,
}) => {
  return (
    <>
      <div className="-mt-12 flex h-[72px] items-center justify-between">
        <div className="flex">
          <CreditMetricSkeleton isPrimary={true} label="Available Balance" />
          <CreditMetricSkeleton isPrimary={false} label="Consumed" />
          <CreditMetricSkeleton isPrimary={false} label="Expired" />
          <CreditMetricSkeleton isPrimary={false} label="Total Granted" />
        </div>
        <div className="flex items-center">{issueGrantButton}</div>
      </div>
      <CreditTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === "grants" ? (
        <CreditGrantListSkeleton />
      ) : (
        <CreditLedger
          loading={true}
          ledger={{
            creditType: USD_CREDIT_TYPE,
            availableBalance: new Decimal(0),
            consumed: new Decimal(0),
            expired: new Decimal(0),
            totalGranted: new Decimal(0),
            ledgerEntries: [],
          }}
        />
      )}
    </>
  );
};

export const CreditsTabContent: React.FC<{ customerId: string }> = ({
  customerId,
}) => {
  const [activeTabParam, setActiveTabParam] = useSearchParam("view");
  const [creditGrantPaneIsOpen, setCreditGrantPaneIsOpen] =
    useState<boolean>(false);
  const [migrateCreditGrantsModalIsOpen, setMigrateCreditGrantsModalIsOpen] =
    useState<boolean>(false);
  const [selectedCreditType, setSelectedCreditType] = useState<CreditType>();
  const { data, loading, error } = useCreditGrants({ customerId: customerId });
  const { creditGrantsById, creditTypesById, formattedLedgersByCreditTypeId } =
    data;

  const activeTab = activeTabParam || "ledger";

  const displayCreditGrantMigrationButtonVariation = useFeatureFlag<{
    [key: string]: string;
  }>("display-credit-grant-migration-button", {});
  const nonVoidedUSDGrants =
    data?.customer?.CreditGrants.filter(
      (cg) =>
        cg.voided_at === null &&
        cg.AmountGrantedCreditType.id === USD_CREDIT_TYPE.id,
    ) ?? [];
  const grantsAlreadyMigrated = nonVoidedUSDGrants.flatMap((cg) =>
    cg.managed_fields.filter(
      (mf) =>
        mf.ManagedFieldKey.key === "rollover_to_contracts" &&
        mf.value === "true",
    ),
  );
  const hasPendingDeductions = formattedLedgersByCreditTypeId[
    USD_CREDIT_TYPE.id
  ]?.ledgerEntries.some(
    (le) => le.__typename === "MRI_PendingChargeCreditLedgerEntry",
  );
  const shouldDisplayCreditGrantMigrationButton = !!(
    displayCreditGrantMigrationButtonVariation &&
    displayCreditGrantMigrationButtonVariation["free_credits_product_id"] &&
    displayCreditGrantMigrationButtonVariation["prepaid_credits_product_id"] &&
    selectedCreditType?.id === USD_CREDIT_TYPE.id
  );
  const shouldEnableCreditGrantMigrationButton =
    shouldDisplayCreditGrantMigrationButton &&
    nonVoidedUSDGrants.length > grantsAlreadyMigrated.length &&
    !hasPendingDeductions;

  function setActiveTab(tabName: string) {
    setActiveTabParam(tabName === "ledger" ? null : tabName);
  }

  const sortedCreditTypes = Object.values(creditTypesById).sort((a, b) =>
    displayCreditTypeName(a).localeCompare(displayCreditTypeName(b)),
  );

  React.useEffect(() => {
    if (!selectedCreditType && sortedCreditTypes.length > 0) {
      setSelectedCreditType(sortedCreditTypes[0]);
    }
  }, [selectedCreditType, sortedCreditTypes]);

  // If we have a selectedCreditType but no ledger then the client just submitted their
  // first grant in this credit type and we are waiting for the query to be updated. So
  // we return an empty ledger in this case so we don't flash the EmptyState
  const currentLedger = selectedCreditType
    ? formattedLedgersByCreditTypeId[selectedCreditType.id] || {
        creditType: selectedCreditType,
        availableBalance: new Decimal(0),
        consumed: new Decimal(0),
        expired: new Decimal(0),
        totalGranted: new Decimal(0),
        ledgerEntries: [],
      }
    : null;

  const issueGrantButton = (
    <GatedButton
      className="h-[34px]"
      onClick={() => setCreditGrantPaneIsOpen(true)}
      doc={IssueCreditGrantDocument}
      text="Issue grant"
      theme="primary"
    />
  );

  let migrateCreditGrantsButton = (
    <GatedButton
      className="mr-12 h-[34px]"
      doc={MigrateCreditGrantsDocument}
      theme="primary"
      disabled={!shouldEnableCreditGrantMigrationButton}
      onClick={() => setMigrateCreditGrantsModalIsOpen(true)}
      text="Migrate Credit Grants"
    />
  );

  if (hasPendingDeductions) {
    migrateCreditGrantsButton = (
      <Tooltip label="You cannot migrate credit grants when there are pending deductions.">
        {migrateCreditGrantsButton}
      </Tooltip>
    );
  }

  const openedPane = creditGrantPaneIsOpen ? (
    <>
      <IssueCreditGrantPane
        customerId={customerId}
        onCreditGrantIssued={(creditGrant) =>
          setSelectedCreditType(creditGrant.amountGrantedCreditType)
        }
        onClose={() => {
          setCreditGrantPaneIsOpen(false);
        }}
      />
    </>
  ) : null;
  const openedModal =
    migrateCreditGrantsModalIsOpen &&
    shouldDisplayCreditGrantMigrationButton ? (
      <>
        <MigrateCreditGrantsModal
          customerId={customerId}
          freeCreditsProductId={
            displayCreditGrantMigrationButtonVariation[
              "free_credits_product_id"
            ]
          }
          prepaidCreditsProductId={
            displayCreditGrantMigrationButtonVariation[
              "prepaid_credits_product_id"
            ]
          }
          onClose={() => {
            setMigrateCreditGrantsModalIsOpen(false);
          }}
        />
      </>
    ) : null;

  if (loading) {
    return (
      <>
        {openedPane}
        <CreditGrantsTabSkeleton
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          issueGrantButton={issueGrantButton}
        />
      </>
    );
  }

  if (error) {
    return (
      <>
        {openedPane}
        <ErrorEmptyState
          title="We ran into an error loading this customer's credit grants"
          error={error}
        />
      </>
    );
  }

  if (!selectedCreditType || !currentLedger) {
    return (
      <>
        {openedPane}
        <EmptyState
          icon="bankNote03"
          mainText="This customer has not been issued a grant"
          supportingText="Once you issue a credit grant, you'll see their details here."
          actions={[issueGrantButton]}
        />
      </>
    );
  }

  return (
    <>
      {openedPane}
      {openedModal}
      <div className="-mt-12 flex h-[72px] items-center justify-between">
        <div className="flex">
          <CreditMetric
            isPrimary={true}
            label="Available Balance"
            amount={currentLedger?.availableBalance ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Consumed"
            amount={currentLedger?.consumed ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Expired"
            amount={currentLedger?.expired ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Total Granted"
            amount={currentLedger?.totalGranted ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
        </div>
        <div className="flex items-center">
          {sortedCreditTypes.length > 1 && (
            <CreditTypeSelect
              creditTypes={sortedCreditTypes}
              selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
              onChange={setSelectedCreditType}
            />
          )}
          {shouldDisplayCreditGrantMigrationButton
            ? migrateCreditGrantsButton
            : ""}
          {issueGrantButton}
        </div>
      </div>
      <CreditTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === "grants" ? (
        <CreditGrantList
          issuedCreditGrants={Object.values(creditGrantsById)}
          selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
          customerId={customerId}
        />
      ) : (
        <CreditLedger loading={false} ledger={currentLedger} />
      )}
    </>
  );
};

import React, { useState } from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";
import { Button } from "components/Button";
import { useSnackbar } from "components/deprecated/Snackbar";
import { GetAllUsersQuery } from "app/pages/Settings/tabs/Team/queries.graphql";
import { ArchiveUserDocument, useArchiveUserMutation } from "./queries.graphql";
import { Tooltip } from "design-system";

import styles from "./index.module.less";
import { UserAuthTypeEnum_Enum } from "types/generated-graphql/__types__";
import { GatedIconButton } from "components/GatedButton";

type User = GetAllUsersQuery["all_users"][0];

interface ArchiveUserModalProps {
  onClose: () => void;
  user: User;
}

const ArchiveUserModal: React.FC<ArchiveUserModalProps> = ({
  onClose,
  user,
}) => {
  const [archiveUser, archiveUserResult] = useArchiveUserMutation();
  const pushMessage = useSnackbar();

  return (
    <DeprecatedPopup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={archiveUserResult.loading}
            loading={archiveUserResult.loading}
            onClick={async () => {
              if (user.id) {
                await archiveUser({
                  variables: {
                    id: user.id,
                  },
                });
                pushMessage({
                  content: "User deactivated",
                  type: "success",
                });
              }
              onClose();
            }}
            text="Deactivate account"
            theme="linkDestructive"
          />
        </>
      }
      isOpen
      onRequestClose={onClose}
      title="Are you sure you want to deactivate this account?"
    >
      <Body level={2}>
        Deactivating {user.name}’s account will cause them to immediately lose
        access to Metronome.
      </Body>
    </DeprecatedPopup>
  );
};

export const ArchiveUserButton: React.FC<{
  user: User;
  disabled?: boolean;
}> = ({ user, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <div className={styles.archiveButton}>
      {isModalOpen && (
        <ArchiveUserModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          user={user}
        />
      )}
      <Tooltip
        content={
          user.auth_type === UserAuthTypeEnum_Enum.Saml
            ? "User can not be deactivated due to login through single sign on"
            : "Deactivate account"
        }
      >
        <GatedIconButton
          doc={ArchiveUserDocument}
          onClick={() => setIsModalOpen(true)}
          disabled={disabled}
          theme="tertiary"
          icon="userMinus02"
          size="sm"
        />
      </Tooltip>
    </div>
  );
};

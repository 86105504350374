import React from "react";

type ImpersonationApprovalScreenProps = {
  onApprove: () => void;
  onDeny: () => void;
  email: string;
};

import { Button } from "components/Button";

export const ImpersonationApprovalScreen: React.FC<
  ImpersonationApprovalScreenProps
> = (props) => {
  return (
    <div className="mx-auto my-0 flex h-svh w-svw max-w-[400px] flex-grow flex-col justify-center bg-white px-[40px] py-0 text-center font-default text-[24px]">
      <p>Continue as</p>
      <p>
        <b>{props.email}</b>?
      </p>
      <div className="mt-3xl flex justify-center gap-16">
        <Button onClick={props.onApprove} text="Log in" theme="primary" />
        <Button onClick={props.onDeny} text="Log out" theme="secondary" />
      </div>
    </div>
  );
};

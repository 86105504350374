import "./style/index.css";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { RootErrorBoundary } from "app/lib/errors/RootErrorBoundry";

import { AuthProvider, useCurrentUser } from "app/lib/auth";
import { LDProvider } from "app/lib/launchdarkly";
import { ApolloProvider } from "lib/apollo";
import { DeprecatedSnackbarProvider } from "components/deprecated/Snackbar";
import { DeprecatedTipsProvider } from "components/deprecated/Tips";
import { NowProvider } from "lib/date";

import { AppRoutes } from "app/routes";
import { EnvironmentProvider } from "app/lib/environmentSwitcher/context";
import { DesignSystemConfigProvider } from "app/lib/designSystem/DesignSystemConfigProvider";
import * as KoalaSDK from "@getkoala/browser";

import Decimal from "decimal.js";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";
import { EmptyState } from "components/EmptyState";
Decimal.set({ precision: 1000 });

Sentry.init({
  dsn: "https://1b831451b63645d9a5d9d21a4d6f8599@o431438.ingest.sentry.io/5386884",
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.BUILD_SHA1,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      beforeNavigate: (context) => {
        return {
          ...context,
          name: location.pathname
            // replace UUIDs with the string <id>
            .replace(
              /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g,
              "<id>",
            ),
        };
      },
      tracingOrigins: [process.env.GRAPHQL_URL || ""],
    }),
  ],
  // We have so few users for now so YOLO - Will need to drop this if we start hitting limits
  tracesSampleRate: 1.0,
});

const SentryContextAdder: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return <>{children}</>;
};

const KoalaSDKAdder: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useCurrentUser();

  async function identifyUser(email: string) {
    if (!!process.env.KOALA_PROJECT_ID) {
      const ko = await KoalaSDK.load({
        project: process.env.KOALA_PROJECT_ID,
      });
      if (ko) {
        await ko.identify(email);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      identifyUser(user.email)
        .then(() => {})
        .catch(() => {});
    }
  }, [user]);

  return <>{children}</>;
};

const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { error, loading } = useCurrentUser();

  if (loading) {
    return <div></div>;
  }

  if (error) {
    let mainText = "An error occurred";
    let supportingText = error.message;
    const errorCauses = error.graphQLErrors.map((e) => e.extensions?.cause);
    if (errorCauses.includes("RBAC_ROLE_MULTIPLE")) {
      mainText = "Your role was misconfigured";
      supportingText = "Please contact your administrator";
    }

    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center bg-white">
        <EmptyState
          icon="alertCircle"
          mainText={mainText}
          supportingText={supportingText}
        />
      </div>
    );
  }

  return children;
};

const focusInput = () => {
  const searchInput = document.querySelectorAll("input[type=search]");
  // This will focus the search bar when user presses `CMD + k` or `/`
  // If there is more than one input element of type search on the page (currently doesn't exist), don't do anything
  if (searchInput.length === 1) {
    (searchInput[0] as HTMLElement).focus();
  }
};
const unfocusInput = () => {
  const searchInput = document.querySelectorAll("input[type=search]");
  if (
    searchInput.length === 1 &&
    document.activeElement === (searchInput[0] as HTMLElement)
  ) {
    (searchInput[0] as HTMLElement).blur();
  }
};
const onKeyDown = (event: KeyboardEvent) => {
  const pressedCmdK = event.metaKey && event.key === "k";
  if (pressedCmdK) {
    focusInput();
  }
};
const onKeyUp = (event: KeyboardEvent) => {
  const pressedForwardSlash = !event.metaKey && event.key === "/";
  const pressedEsc = !event.metaKey && event.key === "Escape";
  if (event.target) {
    const isAlreadyFocusedOnAnInput = ["input", "textarea"].includes(
      (event.target as HTMLElement).tagName.toLowerCase(),
    );
    if (pressedForwardSlash && !isAlreadyFocusedOnAnInput) {
      focusInput();
    } else if (pressedEsc) {
      unfocusInput();
    }
  }
};

type Props = {
  graphqlURL: string;
  auth0_domain: string;
  auth0_client_id: string;
  gatekeeperURL: string;
  useMetronomeSystemHeaders: boolean;
};

const App: React.FC<Props> = (props) => {
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
  });

  const router = React.useMemo(
    () =>
      createBrowserRouter(
        [
          {
            ErrorBoundary: RootErrorBoundary,
            children: AppRoutes,
            element: (
              <AuthProvider
                domain={props.auth0_domain}
                clientID={props.auth0_client_id}
                redirectURI={window.location.origin}
                gatekeeperURL={props.gatekeeperURL}
              >
                <EnvironmentProvider>
                  <DesignSystemConfigProvider>
                    <ApolloProvider
                      graphqlURI={props.graphqlURL}
                      useMetronomeSystemHeaders={
                        props.useMetronomeSystemHeaders
                      }
                    >
                      <UserProvider>
                        <KoalaSDKAdder>
                          <SentryContextAdder>
                            <NowProvider>
                              <LDProvider>
                                <DeprecatedSnackbarProvider>
                                  <DeprecatedTipsProvider>
                                    <Outlet />
                                  </DeprecatedTipsProvider>
                                </DeprecatedSnackbarProvider>
                              </LDProvider>
                            </NowProvider>
                          </SentryContextAdder>
                        </KoalaSDKAdder>
                      </UserProvider>
                    </ApolloProvider>
                  </DesignSystemConfigProvider>
                </EnvironmentProvider>
              </AuthProvider>
            ),
          },
        ],
        {
          future: {
            v7_normalizeFormMethod: true,
          },
        },
      ),
    [],
  );

  return <RouterProvider router={router}></RouterProvider>;
};

const WrappedApp = Sentry.withProfiler(App);

if (
  !process.env.GRAPHQL_URL ||
  !process.env.AUTH0_DOMAIN ||
  !process.env.AUTH0_CLIENT_ID ||
  !process.env.GATEKEEPER_URL
) {
  throw new Error("Missing config");
}

const rootNode = document.getElementById("root");
if (!rootNode) {
  throw new Error("Cannot find root node");
}

createRoot(rootNode).render(
  <EmbeddableDashboardContext.Provider
    initialState={{ isEmbeddableDashboard: false }}
  >
    <WrappedApp
      auth0_client_id={process.env.AUTH0_CLIENT_ID}
      auth0_domain={process.env.AUTH0_DOMAIN}
      graphqlURL={process.env.GRAPHQL_URL}
      gatekeeperURL={process.env.GATEKEEPER_URL}
      useMetronomeSystemHeaders={
        process.env.USE_METRONOME_SYSTEM_HEADERS === "1"
      }
    />
  </EmbeddableDashboardContext.Provider>,
);

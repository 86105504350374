import React, { useMemo, useState } from "react";
import { DraftPlan, RecurringCreditGrant } from "app/lib/plans/types";
import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { Body, Headline, Label, Select, Subtitle } from "design-system";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { DeprecatedPricingUnitSelector } from "components/deprecated/PricingUnitSelector";
import {
  displayCostBasis,
  RoundedCurrency,
  USD_CREDIT_TYPE,
} from "app/lib/credits";
import { DeprecatedToggleButtons } from "components/deprecated/ToggleButtons";
import { HelpCircleTooltip, Input, NumericInput, Toggle } from "design-system";
import { useDraftPlan } from "../../../../context";
import Decimal from "decimal.js";
import { FiatCreditType } from "app/types/credit-types";
import pluralize from "pluralize";
import { SetRecurrenceDurationModal } from "../SetRecurrenceDurationModal";
import { isRecurringCreditGrantValid } from "app/lib/plans/draftPlan";
import { CreatePlanDataQuery } from "app/pages/Offering/tabs/Plans/PlanWizards/data/queries.graphql";

interface AddCreditGrantPageProps {
  data: CreatePlanDataQuery;
}

export function addRecurringCreditGrantIsDone(draftPlan: DraftPlan) {
  return !!(
    draftPlan.recurringGrant &&
    isRecurringCreditGrantValid(draftPlan.recurringGrant)
  );
}

function displayCostBasisForRecurringGrant(
  recurringGrant: RecurringCreditGrant,
) {
  const {
    amountGranted,
    amountGrantedCreditType,
    amountPaid,
    amountPaidCreditType,
  } = recurringGrant;
  if (
    amountGranted &&
    amountGrantedCreditType &&
    amountPaid &&
    amountPaidCreditType
  ) {
    return displayCostBasis(
      new Decimal(amountPaid).div(amountGranted),
      amountGrantedCreditType,
      amountPaidCreditType,
    );
  }
  return "";
}

export const AddCreditGrantPage: React.FC<AddCreditGrantPageProps> = (
  props,
) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const requireProductIds =
    draftPlan.recurringGrant?.requireProductIds || false;
  const selectedProductIds = draftPlan.selectedProductIds || [];
  const products = useMemo(
    () =>
      (props.data?.products || []).filter((p) =>
        selectedProductIds.includes(p.id),
      ),
    selectedProductIds,
  );

  const [
    isSetRecurrenceDurationModalOpen,
    setIsSetRecurrenceDurationModalOpen,
  ] = useState<boolean>(false);
  return (
    <DeprecatedWizardFullPage
      pageHeader={{
        title: "",
        subtitle:
          "Add a single or recurring grant to a plan. When a customer is added to the plan, they will automatically receive the grant.",
      }}
    >
      {isSetRecurrenceDurationModalOpen ? (
        <SetRecurrenceDurationModal
          onClose={(value) => {
            setDraftPlan({
              ...draftPlan,
              recurringGrant: {
                ...draftPlan.recurringGrant,
                recurrence: {
                  ...draftPlan.recurringGrant?.recurrence,
                  duration: value,
                },
              },
            });
            setIsSetRecurrenceDurationModalOpen(false);
          }}
          initialValue={draftPlan.recurringGrant?.recurrence?.duration}
        />
      ) : null}
      <div className="rounded-large bg-white">
        <div className="bg-deprecated-primary-lightest px-12 py-8">
          <Headline level={5} className="text-deprecated-primary-dark">
            Credit grant
          </Headline>
        </div>
        <div className="flex flex-col gap-12 p-12">
          <div className="flex gap-12">
            <Input
              name="Grant name"
              placeholder="Enter name"
              className="!w-[200px]"
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: { ...draftPlan.recurringGrant, name: v },
                })
              }
              value={draftPlan.recurringGrant?.name ?? ""}
            />
            <Input
              name="Reason (optional)"
              placeholder="Enter reason"
              className="w-[450px]"
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: { ...draftPlan.recurringGrant, reason: v },
                })
              }
              value={draftPlan.recurringGrant?.reason ?? ""}
            />
          </div>
          <div>
            <Toggle
              className="!m-0"
              checked={requireProductIds}
              onChange={(val) => {
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    requireProductIds: val,
                  },
                });
              }}
              label={
                <Label className="flex items-center">
                  Product-specific credit
                  <HelpCircleTooltip content="Does this credit grant apply to only a specific product or products? (By default, a credit grant applies to all products.)" />
                </Label>
              }
            />
            {requireProductIds ? (
              <>
                <div className="flex items-center pt-12">
                  <Subtitle level={4} className="text-deprecated-gray-medium">
                    Credit grant applies to
                  </Subtitle>
                  <HelpCircleTooltip content="The order in which you select products here will determine the order in which credits apply to line items on the invoice" />
                </div>
                <Select
                  multiSelect
                  placeholder="Select products"
                  className="block !w-[664px]"
                  options={products.map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onChange={(productIds) => {
                    setDraftPlan({
                      ...draftPlan,
                      recurringGrant: {
                        ...draftPlan.recurringGrant,
                        productIds,
                      },
                    });
                  }}
                  value={draftPlan.recurringGrant?.productIds || []}
                />
              </>
            ) : null}
          </div>
          <div>
            <DeprecatedPricingUnitSelector
              allowCreation={false}
              className="block !w-[200px]"
              onChange={(ct) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    amountGrantedCreditType: ct,
                  },
                })
              }
              selectedCreditTypeId={
                draftPlan.recurringGrant?.amountGrantedCreditType?.id
              }
            />
          </div>
          <div>
            <DeprecatedCreditInput
              placeholder="123.45"
              creditType={
                draftPlan.recurringGrant?.amountGrantedCreditType ??
                USD_CREDIT_TYPE
              }
              disabled={!draftPlan.recurringGrant?.amountGrantedCreditType}
              name="Amount of credit granted"
              className="!w-[200px]"
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    amountGranted: v === null ? undefined : String(v),
                  },
                })
              }
              initialValue={draftPlan.recurringGrant?.amountGranted}
            />
          </div>
          <div className="flex items-end gap-8">
            <DeprecatedCreditInput
              disabled={!draftPlan.recurringGrant?.amountPaidCreditType}
              placeholder="123.45"
              creditType={
                draftPlan.recurringGrant?.amountPaidCreditType ??
                USD_CREDIT_TYPE
              }
              name="Customer cost"
              className="!w-[200px]"
              allowZeroAmounts={true}
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    amountPaid: v === null ? undefined : String(v),
                  },
                })
              }
              initialValue={draftPlan.recurringGrant?.amountPaid}
            />
            <div className="flex items-center gap-8">
              <DeprecatedPricingUnitSelector
                fiatOnly={true}
                className="!m-0 !w-[100px]"
                onChange={(ct) =>
                  setDraftPlan({
                    ...draftPlan,
                    recurringGrant: {
                      ...draftPlan.recurringGrant,
                      amountPaidCreditType: ct as FiatCreditType,
                    },
                  })
                }
                selectedCreditTypeId={
                  draftPlan.recurringGrant?.amountPaidCreditType?.id
                }
                showName={false}
              />
              <Body level={2} className="mb-0 text-deprecated-gray-medium">
                {draftPlan.recurringGrant
                  ? displayCostBasisForRecurringGrant(draftPlan.recurringGrant)
                  : ""}
              </Body>
            </div>
          </div>
          <div>
            <Subtitle level={4} className="flex">
              Is this a one-time grant or a recurring grant?
              <HelpCircleTooltip content="When a customer starts this plan they can be issued a single grant or multiple on a recurring basis." />
            </Subtitle>
            <div className="pt-12">
              <DeprecatedToggleButtons
                className="!m-0"
                buttonProps={[
                  { value: "false", label: "One-time grant" },
                  { value: "true", label: "Recurring grant" },
                ]}
                defaultButtonProps={{
                  useGreyBackground: true,
                  onChange(v) {
                    setDraftPlan({
                      ...draftPlan,
                      recurringGrant: {
                        ...draftPlan.recurringGrant,
                        recurrence: v === "true" ? {} : null,
                      },
                    });
                  },
                }}
                value={
                  draftPlan.recurringGrant?.recurrence === undefined
                    ? undefined
                    : draftPlan.recurringGrant.recurrence
                      ? "true"
                      : "false"
                }
              />
            </div>
          </div>
          <div className="flex items-end gap-12">
            {draftPlan.recurringGrant?.recurrence ? (
              <>
                <div className="flex">
                  <NumericInput
                    placeholder="1"
                    name="Recurs every..."
                    suffix={pluralize(
                      "billing period",
                      draftPlan.recurringGrant?.recurrence?.interval ?? 1,
                    )}
                    onChange={(v) =>
                      setDraftPlan({
                        ...draftPlan,
                        recurringGrant: {
                          ...draftPlan.recurringGrant,
                          recurrence: {
                            ...draftPlan.recurringGrant?.recurrence,
                            interval: v ?? undefined,
                          },
                        },
                      })
                    }
                    value={draftPlan.recurringGrant?.recurrence?.interval}
                  />
                </div>
                <div className="flex">
                  <Select
                    className="!m-0 !w-[220px]"
                    options={[
                      { label: "End of plan", value: "end_of_plan" },
                      ...(draftPlan.recurringGrant.recurrence.duration
                        ? [
                            {
                              label: `${pluralize(
                                "grant",
                                draftPlan.recurringGrant.recurrence.duration,
                                true,
                              )} ${
                                draftPlan.recurringGrant.recurrence.duration ===
                                1
                                  ? "has"
                                  : "have"
                              } been issued`,
                              value: String(
                                draftPlan.recurringGrant.recurrence.duration,
                              ),
                            },
                          ]
                        : []),
                      { label: "Custom", value: "custom" },
                    ]}
                    onChange={(v) => {
                      if (v === "custom") {
                        setIsSetRecurrenceDurationModalOpen(true);
                      } else if (v === "end_of_plan") {
                        setDraftPlan({
                          ...draftPlan,
                          recurringGrant: {
                            ...draftPlan.recurringGrant,
                            recurrence: {
                              ...draftPlan.recurringGrant?.recurrence,
                              duration: undefined,
                            },
                          },
                        });
                      }
                    }}
                    value={
                      draftPlan.recurringGrant.recurrence.duration
                        ? String(draftPlan.recurringGrant.recurrence.duration)
                        : "end_of_plan"
                    }
                    placeholder="Select..."
                    name="Issue grants until..."
                  />
                </div>
              </>
            ) : null}
            <div>
              <NumericInput
                onChange={(v) => {
                  setDraftPlan({
                    ...draftPlan,
                    recurringGrant: {
                      ...draftPlan.recurringGrant,
                      effectiveDuration: v ?? undefined,
                    },
                  });
                }}
                value={draftPlan.recurringGrant?.effectiveDuration}
                placeholder="1"
                suffix={pluralize(
                  "month",
                  draftPlan.recurringGrant?.effectiveDuration ?? 1,
                )}
                name={
                  draftPlan.recurringGrant?.recurrence
                    ? "Each grant expires after..."
                    : "Grant expires in..."
                }
              />
            </div>
          </div>
          <div>
            <Subtitle level={4} className="flex">
              Would you like to invoice the customer for this credit grant?
              <HelpCircleTooltip content="Invoice the customer for the amount paid for this grant. This will be a separate invoice issued on the day the grant is created." />
            </Subtitle>
            <Toggle
              className="!m-0 pt-12"
              checked={draftPlan.recurringGrant?.sendInvoice ?? false}
              label={
                <>
                  Invoice customer for amount paid{" "}
                  {draftPlan.recurringGrant?.amountPaid == null ||
                  draftPlan.recurringGrant.amountPaidCreditType == null ? (
                    "--"
                  ) : (
                    <RoundedCurrency
                      amount={new Decimal(draftPlan.recurringGrant.amountPaid)}
                      creditType={draftPlan.recurringGrant.amountPaidCreditType}
                    />
                  )}
                </>
              }
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    sendInvoice: v,
                  },
                })
              }
            />
          </div>
          <div>
            <NumericInput
              className="!w-[200px]"
              placeholder="Enter priority"
              name="Priority"
              tooltip="Grant priority determines the order credits get consumed. Grants at the same priority are then consumed by earlier expiry and balance amount."
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  recurringGrant: {
                    ...draftPlan.recurringGrant,
                    priority: String(v),
                  },
                })
              }
              value={
                draftPlan.recurringGrant?.priority
                  ? Number(draftPlan.recurringGrant?.priority)
                  : undefined
              }
            />
          </div>
        </div>
      </div>
    </DeprecatedWizardFullPage>
  );
};

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  GetClientConfigDocument,
  GetClientConfigQuery,
  useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
  useGetClientConfigQuery,
  useGetCustomersWithStripeEnabledCountQuery,
  useDualWriteClientConfigMutation,
  DualWriteClientConfigMutationVariables,
} from "./queries.graphql";
import { Button } from "components/Button";
import { SideSheet, SideSheetProps } from "components/SideSheet";
import { useSnackbar } from "components/deprecated/Snackbar";
import { FirstDeleteStep } from "./FirstDeleteStep";
import { renderDate } from "lib/time";
import { StripeInvoiceSettings } from "./StripeSettings";
import { useContractsEnabled } from "app/lib/contracts/useContractsEnabled";
import { Tooltip } from "design-system";
import {
  BillingProviderDeliveryMethod_Enum,
  BillingProviderEnum_Enum,
} from "types/generated-graphql/__types__";
import { reportToSentry } from "app/lib/errors/sentry";

interface StripeSettingsSideSheetProps {
  setStripeSettingsSlideSheetIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  connectedOn?: Date;
  tokenID: string;
  hasStripeOnContractsSetUp: boolean;
}

export interface StripeSettingsDataType {
  leave_invoices_in_draft?: boolean;
  skip_zero_dollar_invoices?: boolean;
  export_invoice_sub_line_items?: boolean;
  include_zero_quantity_sub_line_items?: boolean;
  stripe_invoice_quantity_always_string?: boolean;
  invoice_days_until_due?: string;
  set_effective_at_date_to_inclusive_period_end?: boolean;
}

// set default values
// Must stay in sync with billing-provider-invoicer/src/lib/integrationOptions.ts
export const DEFAULT_STRIPE_SETTINGS: Readonly<StripeSettingsDataType> = {
  leave_invoices_in_draft: true,
  skip_zero_dollar_invoices: false,
  export_invoice_sub_line_items: false,
  include_zero_quantity_sub_line_items: true,
  stripe_invoice_quantity_always_string: false,
  invoice_days_until_due: "",
  set_effective_at_date_to_inclusive_period_end: false,
};

/**
 * Utility class for parsing and comparing stripe settings from different sources
 */
export class StripeSettings {
  settings: StripeSettingsDataType = {};
  settingsExist = false;

  constructor(
    stripeSettingsData: StripeSettingsDataType,
    settingsExist = false,
  ) {
    this.settings = stripeSettingsData;
    this.settingsExist = settingsExist;
  }

  static fromClientConfig(
    clientConfigData: GetClientConfigQuery["ClientConfig"],
  ) {
    const settingsExist = clientConfigData.length > 0;
    return new StripeSettings(
      StripeSettings.parseEntries(clientConfigData),
      settingsExist,
    );
  }

  static fromDeliveryMethod(
    deliveryMethod: GetClientConfigQuery["list_delivery_methods"],
  ) {
    const stripeDeliveryMethod = deliveryMethod?.delivery_methods.find(
      (dm) =>
        dm.delivery_method ===
          BillingProviderDeliveryMethod_Enum.DirectToBillingProvider &&
        dm.billing_provider === BillingProviderEnum_Enum.Stripe,
    );

    const stripeDeliveryMethodConfig =
      stripeDeliveryMethod?.delivery_method_configuration ?? {};

    const settingsExist =
      Object.keys(stripeDeliveryMethodConfig).filter(
        (k) => k !== "stripe_account_id",
      ).length > 0; // stripe_account_id is always required

    return new StripeSettings(
      StripeSettings.parseEntries(
        Object.entries(stripeDeliveryMethodConfig).map(([key, value]) => ({
          key,
          value: value.toString().toLowerCase(),
        })),
      ),
      settingsExist,
    );
  }

  private static parseEntries(
    keyValues: {
      key: string;
      value: string | number | boolean;
    }[],
  ) {
    const updatedClientConfigDataMap = { ...DEFAULT_STRIPE_SETTINGS };

    keyValues.forEach(({ key, value }) => {
      switch (key) {
        case "leave_invoices_in_draft":
          updatedClientConfigDataMap[key] = value === "true";
          break;
        case "skip_zero_dollar_invoices":
          updatedClientConfigDataMap[key] = value === "true";
          break;
        case "export_invoice_sub_line_items":
          updatedClientConfigDataMap[key] = value === "true";
          break;
        case "include_zero_quantity_sub_line_items":
          updatedClientConfigDataMap[key] = value === "true";
          break;
        case "stripe_invoice_quantity_always_string":
          updatedClientConfigDataMap[key] = value === "true";
          break;
        case "invoice_days_until_due":
          updatedClientConfigDataMap[key] = value.toString();
          break;
        case "set_effective_at_date_to_inclusive_period_end":
          updatedClientConfigDataMap[key] = value === "true";
          break;
      }
    });

    return updatedClientConfigDataMap;
  }

  eq(other: StripeSettings) {
    return (
      this.settings.leave_invoices_in_draft ===
        other.settings.leave_invoices_in_draft &&
      this.settings.skip_zero_dollar_invoices ===
        other.settings.skip_zero_dollar_invoices &&
      this.settings.export_invoice_sub_line_items ===
        other.settings.export_invoice_sub_line_items &&
      this.settings.include_zero_quantity_sub_line_items ===
        other.settings.include_zero_quantity_sub_line_items &&
      this.settings.stripe_invoice_quantity_always_string ===
        other.settings.stripe_invoice_quantity_always_string &&
      this.settings.invoice_days_until_due ===
        other.settings.invoice_days_until_due &&
      this.settings.set_effective_at_date_to_inclusive_period_end ===
        other.settings.set_effective_at_date_to_inclusive_period_end
    );
  }
}

/**
 * This component handles reading and updating settings for stripe.
 * For reads, it manages data from both "ClientConfig" (plans) and
 * billing_provider_delivery_method (contracts). Since this configuration
 * is duplicated, we check to make sure the data is consistent and if it's not,
 * we fall back to the plans configuration since it's more likely to be correct.
 */
export const StripeSettingsSideSheet: React.FC<
  StripeSettingsSideSheetProps
> = ({
  setStripeSettingsSlideSheetIsOpen,
  isOpen,
  connectedOn,
  tokenID,
  hasStripeOnContractsSetUp,
}) => {
  const pushMessage = useSnackbar();
  const [disableStripeClicked, setDisableStripeClicked] = useState(false);
  const [hasPassedFirstDeleteStep, setHasPassedFirstDeleteStep] =
    useState(false);
  const [disableStripeButtonIsDisabled, setDisableStripeButtonIsDisabled] =
    useState(false);
  const [shouldSaveStripeChanges, setShouldSaveStripeChanges] = useState(false);
  const [stripeSettingsData, setStripeSettingsData] =
    useState<StripeSettingsDataType>({});

  const { environmentType } = useEnvironment();

  const { data: clientConfigData, error: clientConfigError } =
    useGetClientConfigQuery({
      variables: { environment_type: environmentType },
    });

  const setInitalConfigData = () => {
    let updatedClientConfigDataMap: StripeSettingsDataType = {};

    if (clientConfigData) {
      const settingsFromClientConfig = StripeSettings.fromClientConfig(
        clientConfigData.ClientConfig,
      );

      const settingsFromDeliveryMethod = StripeSettings.fromDeliveryMethod(
        clientConfigData.list_delivery_methods,
      );

      if (
        settingsFromClientConfig.settingsExist &&
        settingsFromDeliveryMethod.settingsExist
      ) {
        if (settingsFromClientConfig.eq(settingsFromDeliveryMethod)) {
          updatedClientConfigDataMap = settingsFromDeliveryMethod.settings;
        } else {
          reportToSentry(
            `Settings from client config and delivery method do not match`,
            {
              clientID: clientConfigData.Client[0].id,
              settingsFromClientConfig: settingsFromClientConfig.settings,
              settingsFromDeliveryMethod: settingsFromDeliveryMethod.settings,
            },
          );
          // default to client config settings if there's a mismatch
          updatedClientConfigDataMap = settingsFromClientConfig.settings;
        }
      } else if (settingsFromClientConfig.settingsExist) {
        updatedClientConfigDataMap = settingsFromClientConfig.settings;
      } else if (settingsFromDeliveryMethod.settingsExist) {
        updatedClientConfigDataMap = settingsFromDeliveryMethod.settings;
      } else {
        updatedClientConfigDataMap = DEFAULT_STRIPE_SETTINGS;
      }
    }

    setStripeSettingsData(updatedClientConfigDataMap);
  };

  const { data: customerCount } = useGetCustomersWithStripeEnabledCountQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const [
    deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
  ] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(
    {
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    },
  );

  const numCustomers = Number(
    customerCount?.Customer_aggregate.aggregate?.count || "0",
  );

  const deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction =
    async () => {
      try {
        await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation();
        pushMessage({
          content: "Stripe has been disabled",
          type: "success",
        });
      } catch (e) {
        pushMessage({
          content: "Failed to disable Stripe",
          type: "error",
        });
        throw e;
      }
    };

  const [
    dualWriteClientConfig,
    {
      loading: dualWriteClientConfigLoading,
      error: dualWriteClientConfigError,
    },
  ] = useDualWriteClientConfigMutation();

  useEffect(() => {
    if (clientConfigData) {
      setInitalConfigData();
    }
  }, [clientConfigData]);

  const handleCancelClick = () => {
    setHasPassedFirstDeleteStep(false);
    setDisableStripeClicked(false);
  };

  const handleDeleteStripe = async () => {
    await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction();
    setStripeSettingsSlideSheetIsOpen(false);
  };

  const renderDeleteStep = () => {
    if (!hasPassedFirstDeleteStep) {
      return (
        <FirstDeleteStep
          numCustomers={numCustomers}
          setHasPassedFirstDeleteStep={setHasPassedFirstDeleteStep}
          setDisableStripeButtonIsDisabled={setDisableStripeButtonIsDisabled}
          disableStripeButtonIsDisabled={disableStripeButtonIsDisabled}
        />
      );
    } else {
      return <>Are you sure you want to disable Stripe?</>;
    }
  };

  const hasContractsEnabled = useContractsEnabled();
  const failedSnackbarMessage = "Failed to save changes. Please try again.";

  const updateClientConfig = async (
    variables: DualWriteClientConfigMutationVariables,
  ) => {
    const { data } = await dualWriteClientConfig({
      variables,
      refetchQueries: [
        {
          query: GetClientConfigDocument,
          variables: { environment_type: environmentType },
        },
      ],
    });
    if (dualWriteClientConfigError) {
      pushMessage({
        content: failedSnackbarMessage,
        type: "error",
      });
    } else {
      if (!data?.update_client_billing_provider_configurations.success) {
        pushMessage({
          content: failedSnackbarMessage,
          type: "error",
        });
      } else {
        setStripeSettingsSlideSheetIsOpen(false);
        pushMessage({
          content: "Changes saved successfully",
          type: "success",
        });
      }
    }
  };

  const saveStripeSettings = async () => {
    if (!shouldSaveStripeChanges) return;

    if (
      stripeSettingsData.leave_invoices_in_draft === undefined ||
      stripeSettingsData.skip_zero_dollar_invoices === undefined ||
      stripeSettingsData.export_invoice_sub_line_items === undefined ||
      stripeSettingsData.include_zero_quantity_sub_line_items === undefined ||
      stripeSettingsData.stripe_invoice_quantity_always_string === undefined ||
      stripeSettingsData.set_effective_at_date_to_inclusive_period_end ===
        undefined
    ) {
      pushMessage({
        content: failedSnackbarMessage,
        type: "error",
      });
      return;
    }

    const variables = {
      leave_invoices_in_draft_value:
        stripeSettingsData.leave_invoices_in_draft.toString(),
      skip_zero_dollar_invoices_value:
        stripeSettingsData.skip_zero_dollar_invoices.toString(),
      export_invoice_sub_line_items_value:
        stripeSettingsData.export_invoice_sub_line_items.toString(),
      include_zero_quantity_sub_line_items_value:
        stripeSettingsData.include_zero_quantity_sub_line_items.toString(),
      stripe_invoice_quantity_always_string_value:
        stripeSettingsData.stripe_invoice_quantity_always_string.toString(),
      set_effective_at_date_to_inclusive_period_end_value:
        stripeSettingsData.set_effective_at_date_to_inclusive_period_end.toString(),
      invoice_days_until_due_value:
        stripeSettingsData.invoice_days_until_due || "",
    };

    /* 
      If export_invoice_sub_line_items is false and contracts are not enabled,
      we should not allow include_zero_quantity_sub_line_items
      and stripe_invoice_quantity_always_string to be true 
    */
    if (
      variables.export_invoice_sub_line_items_value === "false" &&
      !hasContractsEnabled
    ) {
      variables.include_zero_quantity_sub_line_items_value = "false";
      variables.stripe_invoice_quantity_always_string_value = "false";
    }

    // confirm the type is what the resolver expects
    const updates: { [key: string]: string | number | boolean } = {
      ...stripeSettingsData,
    };

    if (!updates.invoice_days_until_due) {
      delete updates.invoice_days_until_due;
    }

    // If invoice_days_until_due is not an empty string, we save the value
    if (!!variables.invoice_days_until_due_value) {
      await updateClientConfig({ configuration: updates });
    } else {
      // If invoice_days_until_due is an empty string, we delete the value so we don't store rows with empty strings
      await updateClientConfig({
        configuration: updates,
        configKeysToRemove: ["invoice_days_until_due"],
      });
    }
  };

  let props: Pick<
    SideSheetProps,
    "title" | "leadingAction" | "trailingActions"
  >;
  let children: React.ReactNode;

  if (disableStripeClicked) {
    props = {
      title: "Disable Stripe",
      trailingActions: [
        <Button
          className={
            !hasPassedFirstDeleteStep && disableStripeButtonIsDisabled
              ? "text-error-200"
              : "text-error-600"
          }
          text="Disable Stripe"
          theme="tertiary"
          leadingIcon="xSquare"
          disabled={disableStripeButtonIsDisabled}
          onClick={async () =>
            !hasPassedFirstDeleteStep
              ? setHasPassedFirstDeleteStep(true)
              : await handleDeleteStripe()
          }
        />,
        <Button
          text="Cancel"
          onClick={() => handleCancelClick()}
          theme="tertiary"
        />,
      ],
    };
    children = renderDeleteStep();
  } else {
    props = {
      title: "Manage Stripe",
      leadingAction: hasStripeOnContractsSetUp ? (
        <Tooltip content="Contact your Metronome representative to disable account-level Stripe configuration.">
          <Button
            text="Disable"
            leadingIcon="xSquare"
            theme="tertiary"
            onClick={() => {}}
            disabled
          />
        </Tooltip>
      ) : (
        <Button
          text="Disable"
          leadingIcon="xSquare"
          theme="tertiary"
          className="text-error-600"
          onClick={() => setDisableStripeClicked(true)}
        />
      ),
      trailingActions: [
        <Button
          text="Save changes"
          onClick={async () => await saveStripeSettings()}
          disabled={!shouldSaveStripeChanges || dualWriteClientConfigLoading}
          loading={dualWriteClientConfigLoading}
        />,
        <Button
          text="Cancel"
          theme="secondary"
          onClick={() => setStripeSettingsSlideSheetIsOpen(false)}
        />,
      ],
    };
    children = (
      <StripeInvoiceSettings
        tokenID={tokenID}
        setShouldSaveStripeChanges={setShouldSaveStripeChanges}
        stripeSettingsData={stripeSettingsData}
        setStripeSettingsData={setStripeSettingsData}
        errorLoadingData={clientConfigError}
      />
    );
  }

  return (
    <SideSheet
      supportingText={
        connectedOn &&
        `Connected on ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`
      }
      isOpen={isOpen}
      onClose={() => setStripeSettingsSlideSheetIsOpen(false)}
      {...props}
    >
      {children}
    </SideSheet>
  );
};

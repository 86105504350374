import React, { useState } from "react";
import { SideSheet } from "components/SideSheet";
import { renderDate } from "lib/time";
import { Button } from "components/Button";
import { useSetupAwsMarketplaceCredentialsMutation } from "./queries.graphql";
import { useSnackbar } from "components/deprecated/Snackbar";
import { TextInput } from "components/Input";
import { handleCopyClick } from "app/pages/deprecated/GeneralSettings/hooks/copyToken";
import { BadgeGroup } from "components/BadgeGroup";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useCurrentUser } from "app/lib/auth";
import {
  EnvironmentTypeEnum_Enum,
  BillingProviderEnum_Enum,
} from "types/generated-graphql/__types__";
import { v5 as uuidV5 } from "uuid";
import { useDocsLink } from "app/lib/docs-link";
import { Link } from "react-router-dom";

const ROLE_CREATE_LINK =
  "https://console.aws.amazon.com/iam/home#/roles/create?step=type&roleType=crossAccount";
const MARKETPLACE_ACCOUNT_ID = "916227654331";

interface AWSSideSheetProps {
  onClose: () => void;
  isOpen: boolean;
  connectedOn?: Date;
  awsIAMRoleARN?: string;
  awsExternalID?: string;
}

function deterministicallyGenerateAWSExternalID(
  clientID: string,
  environmentType: EnvironmentTypeEnum_Enum,
) {
  const billingProvider = BillingProviderEnum_Enum.AwsMarketplace;
  return uuidV5(`${billingProvider}|${environmentType}`, clientID);
}

export const AWSSideSheet = ({
  onClose,
  isOpen,
  connectedOn,
  awsIAMRoleARN,
  awsExternalID: existingAWSExternalID,
}: AWSSideSheetProps) => {
  const { clientID, loading: currentUserLoading } = useCurrentUser();
  const { environment } = useEnvironment();
  const environmentType = environment.type;
  const pushMessage = useSnackbar();
  const [newAWSIAMRoleARN, setNewAWSIAMRoleARN] = useState(awsIAMRoleARN ?? "");
  const [setupAWSMarketplaceCredentialsMutation, { loading }] =
    useSetupAwsMarketplaceCredentialsMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    });

  const AWSDocsLink = useDocsLink({
    contractsPath: "invoice-customers/solutions/marketplaces/invoice-aws/",
    plansPath: "invoicing/how-invoicing-works/invoicing-with-aws-marketplace/",
  });

  const AWSIAMDocsLink = useDocsLink({
    contractsPath:
      "invoice-customers/solutions/marketplaces/invoice-aws/#set-up-iam-credentials-for-metronome-to-meter-on-your-behalf",
    plansPath:
      "invoicing/how-invoicing-works/invoicing-with-aws-marketplace/#set-up-iam-credentials",
  });

  const setupAWSMarketplaceCredentials = async (
    awsExternalID: string,
    awsIAMRoleARN: string,
  ) => {
    try {
      if (!awsExternalID || !awsIAMRoleARN) {
        return;
      }
      await setupAWSMarketplaceCredentialsMutation({
        variables: {
          external_id: awsExternalID,
          iam_role_arn: newAWSIAMRoleARN,
        },
      });
      pushMessage({
        content: "AWS Marketplace has been enabled",
        type: "success",
      });
      onClose();
    } catch (e: any) {
      pushMessage({
        content: e.message,
        type: "error",
      });
    }
  };

  const authLoading = !clientID || !environmentType;
  const disableSave =
    !newAWSIAMRoleARN || loading || currentUserLoading || authLoading;

  const generatedAWSExternalID = authLoading
    ? ""
    : deterministicallyGenerateAWSExternalID(clientID, environmentType);

  // If AWS is already setup, use the external ID in the DB, otherwise generate one
  const awsExternalID = existingAWSExternalID || generatedAWSExternalID;

  const trailingActionButtons:
    | [React.ReactElement, React.ReactElement]
    | undefined = [
    <Button
      key="save"
      text="Save changes"
      disabled={disableSave}
      onClick={async () => {
        await setupAWSMarketplaceCredentials(awsExternalID, newAWSIAMRoleARN);
      }}
    />,
    <Button key="cancel" text="Cancel" theme="secondary" onClick={onClose} />,
  ];

  return (
    <SideSheet
      title="Connect AWS Marketplace"
      onClose={onClose}
      isOpen={isOpen}
      supportingText={
        connectedOn
          ? `Connected on: ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`
          : "The AWS marketplace enables qualified partners to market and sell their software to AWS customers."
      }
      trailingActions={trailingActionButtons}
    >
      <div className="mb-3xl">
        <h4 className="text-sm font-semibold text-black">Get started</h4>
        <div className="text-sm text-gray-600">
          To set up AWS marketplace you’ll first need to create your marketplace
          listing inside AWS.{" "}
          <Link to={AWSDocsLink} target="_blank">
            <u>Learn more</u>
          </Link>{" "}
          about setting up your listing.
        </div>
      </div>
      <div className="mb-3xl">
        <h4 className="mb-lg text-sm font-semibold text-black">
          1. Give Metronome metering permissions
        </h4>
        <div className="mb-lg text-sm text-gray-600">
          Create an IAM role that allows Metronome to meter on your behalf.
          Navigate to the{" "}
          <Link to={ROLE_CREATE_LINK} target="_blank">
            <u>role creator</u>
          </Link>{" "}
          and follow these{" "}
          <Link to={AWSIAMDocsLink} target="_blank">
            <u>directions</u>
          </Link>
          . Use the External ID and Metronome's AWS account ID below when
          configuring the new role.
        </div>
        <BadgeGroup
          mainLabel="Copy External ID"
          className="mb-md"
          badgeLabel={awsExternalID}
          onClick={async () => {
            await handleCopyClick(awsExternalID, (_) => {});
            pushMessage({
              content: "Copied External ID",
              type: "success",
            });
          }}
          icon="copy01"
        ></BadgeGroup>
        <BadgeGroup
          mainLabel="Copy AWS account ID"
          badgeLabel={MARKETPLACE_ACCOUNT_ID}
          onClick={async () => {
            await handleCopyClick(MARKETPLACE_ACCOUNT_ID, (_) => {});
            pushMessage({
              content: "Copied AWS account ID",
              type: "success",
            });
          }}
          icon="copy01"
        ></BadgeGroup>
      </div>
      <div className="mb-3xl">
        <h4 className="mb-lg text-sm font-semibold text-black">
          2. Add your AWS IAM Role ARN
        </h4>
        <div className="mb-lg text-sm text-gray-600">
          Once you’ve created your new role, enter the role ARN to complete the
          setup.
        </div>
        <TextInput
          disabled={false}
          placeholder="Enter AWS IAM Role ARN"
          value={newAWSIAMRoleARN}
          label="AWS IAM Role ARN"
          onChange={(meta: { value: string }) =>
            setNewAWSIAMRoleARN(meta.value)
          }
          fullWidth={true}
        />
      </div>
    </SideSheet>
  );
};

import { NumericInput, Select } from "design-system";
import { FormController } from "app/lib/FormController/FormController";
import React from "react";
import { RoundingMethod } from "types/generated-graphql/__types__";
import { Schema } from "../Schema";
import { z } from "zod";

interface Props {
  parent: FormController<any>;
}

const useRootCtrl = FormController.makeChildHook(
  z.object({ quantityRounding: Schema.QuantityRounding }),
  {
    read: (parent) => parent.get("quantityRounding"),
    write: (child) => ({ quantityRounding: child.getUnvalidatedInputs() }),
    debugName: "QuantityRounding",
  },
);

const useQuantityRounding = useRootCtrl.child(Schema.QuantityRounding, {
  read: (parent) => parent.get("quantityRounding"),
  write: (child) => ({ quantityRounding: child.getUnvalidatedInputs() }),
  debugName: "QuantityRounding",
});

export const QuantityRounding: React.FC<Props> = ({ parent }) => {
  const ctrl = useQuantityRounding(parent);
  return (
    <div className="ml-32 grid gap-12">
      <Select
        {...ctrl.props.Select("roundingMethod", {
          name: "Rounding method",
          placeholder: "Select a method",
          options: [
            { label: "Round half up", value: RoundingMethod.HalfUp },
            { label: "Round up", value: RoundingMethod.Ceiling },
            { label: "Round down", value: RoundingMethod.Floor },
          ],
        })}
      />
      <NumericInput
        {...ctrl.props.NumericInput("decimalPlaces", {
          name: "Decimal places",
          placeholder: "Enter decimal place to round to",
          min: 0,
          tooltip:
            "The number of decimal places to round to. Enter 0 to round to the nearest integer.",
        })}
      />
    </div>
  );
};

import React from "react";

import { Tooltip } from "design-system";
import styles from "./index.module.less";

interface Props extends React.PropsWithChildren {
  searchText: string;
}

export const DeprecatedSearchTooltip: React.FC<Props> = ({
  searchText,
  children,
}) => {
  return (
    <Tooltip
      content={
        <div className={styles.content}>
          <span>Search {searchText}</span>
          <span>⌘K or /</span>
        </div>
      }
      position={["bottom"]}
    >
      {children}
    </Tooltip>
  );
};

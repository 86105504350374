import React, { useState } from "react";
import { Button } from "components/Button";
import { Takeover } from "components/Takeover";
import { useNavigate } from "app/lib/useNavigate";
import { SectionLabel } from "components/SectionLabel";
import { Icon } from "components/Icon";
import { ReactComponent as FeatureIcon } from "./FeatureIcon.svg";
import { Checkbox } from "components/Checkbox";
import { useUIMode } from "app/lib/useUIMode";

export const shouldShowPreviewPage = () =>
  localStorage.getItem("show-billable-metric-create-preview") === null;

interface BillableMetricCreateLandingPageProps {
  setShowPreviewPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BillableMetricCreateLandingPage: React.FC<
  BillableMetricCreateLandingPageProps
> = ({ setShowPreviewPage }) => {
  const navigate = useNavigate();
  const { mode } = useUIMode();

  const [dontShowPreviewAgainClicked, setDontShowPreviewAgainClicked] =
    useState(false);

  const handleContinueClick = () => {
    if (dontShowPreviewAgainClicked)
      localStorage.setItem("show-billable-metric-create-preview", "false");
    setShowPreviewPage(false);
  };

  return (
    <Takeover
      maxContainerWidth="max-w-[616px]"
      isOpen={true}
      onClose={() => navigate("/offering/billable-metrics")}
      title="Create a billable metric"
      footerTrailingButtons={[
        <Checkbox
          size="sm"
          className="mr-[12px] flex flex-row items-center"
          label="Don't show again"
          checked={dontShowPreviewAgainClicked}
          onClick={(meta: { checked: boolean }) =>
            setDontShowPreviewAgainClicked(meta.checked)
          }
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleContinueClick}
          text="Continue"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            navigate("/offering/billable-metrics");
          }}
          theme="secondary"
        />
      }
      children={
        <div className="flex flex-col">
          <div className="relative">
            <FeatureIcon className="absolute left-[45%] top-[30px]" />
            <div className="mb-2xl mt-[160px] text-center">
              <div className="mb-2 text-xl font-semibold text-core-slate">
                Welcome to the Metronome billable metric creator
              </div>
              <div className="text-md font-normal text-gray-600">
                A billable metric determines how to filter and aggregate your
                usage events. They can be attached to one or many products.
              </div>
            </div>
          </div>
          <div className="ml-24 inline-flex w-[560px] flex-col items-start justify-items-center gap-[24px]	">
            {mode === "plans-only" ? (
              <>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <SectionLabel
                      title="Filter your events"
                      subtitle="Choose which events will match this billable metric using point-and-click filters."
                      subtitleClassName="text-gray-600"
                    />
                  </div>
                </div>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <SectionLabel
                      title="Aggregate your events"
                      subtitle="Aggregate using count, sum, max, unique, or create a seat metric."
                      subtitleClassName="text-gray-600"
                    />
                  </div>
                </div>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <SectionLabel
                      title="Add group keys"
                      subtitle="Adding group keys allows you to customize invoice presentation. For example, grouping by org_id will allow you to display the spend by org_id on an invoice."
                      subtitleClassName="text-gray-600"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <div className="flex justify-between">
                      <div className="flex-row">
                        <div className="text-sm font-semibold text-core-slate">
                          Select a metric type
                        </div>
                        <div className="text-sm font-normal text-gray-600">
                          Use templatized basic filters to build simple
                          aggregations, or write a complex query using SQL.{" "}
                          <a
                            href="https://docs.metronome.com/connect-metronome/create-billable-metrics/#2-identify-desired-scale-and-latency"
                            className="underline"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Learn more about these options.
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <SectionLabel
                      title="Using basic filters"
                      subtitle="Filter events by event type and property values. Then, aggregate using count, sum, max, unique, or create a seat metric."
                      subtitleClassName="text-gray-600"
                    />
                  </div>
                </div>
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                  <Icon
                    icon="dot"
                    className="mr-[16px] shrink-0 items-center self-start"
                  />
                  <div className="gap-0.5 inline-flex flex-col items-start justify-center">
                    <SectionLabel
                      title="Using SQL"
                      subtitle="Leverage SQL syntax including math, case-when statements, and nested queries to write a query returning one value per group. Groups can be used downstream as pricing or presentation group keys."
                      subtitleClassName="text-gray-600"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
    ></Takeover>
  );
};

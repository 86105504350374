import React, { useContext } from "react";
import type { CreditTypeConversion, TrialSpec } from "app/lib/plans/types";
import { CustomPricing } from "app/lib/customPricing";

export interface CustomerPlanInfo {
  Plan?: {
    id: string;
    name: string;
  };
  planPreviewed?: boolean;
  planStartDate?: Date;
  planEndDate?: Date | null;
  planDatesError?: string | null;
  netPaymentTermsDays?: number | null;
  stripeConfig?: {
    valid?: boolean;
    billingProviderCustomerId?: string;
    collectionMethod?: "send_invoice" | "charge_automatically";
  };
  trialSpec?: TrialSpec | null;
  customPricing?: CustomPricing;
  planBillingViewed?: boolean;
  finalPlanPreviewed?: boolean;
  creditTypeConversionAdjustments?: CreditTypeConversion[] | undefined;
}
type AddPlanToCustomerContext = {
  state: CustomerPlanInfo;
  setState: (state: CustomerPlanInfo) => void;
};

export const Context = React.createContext<AddPlanToCustomerContext>(
  null as any,
);

export const useCustomerPlan = () => {
  const { state: customerPlan, setState: setCustomerPlan } =
    useContext(Context);
  return [customerPlan, setCustomerPlan] as const;
};

import { useRequiredParam } from "app/lib/routes/params";
import { useUIMode } from "app/lib/useUIMode";
import React from "react";
import { Credits } from "./Credits";
import { CommitsAndCredits } from "./CommitsAndCredits";

export const CommitsAndCreditsSection: React.FC = () => {
  const customerId = useRequiredParam("customerId");

  const { mode } = useUIMode();
  if (mode === "plans-only") {
    return <Credits customerId={customerId} />;
  } else {
    return <CommitsAndCredits customerId={customerId} />;
  }
};

import React from "react";
import Decimal from "decimal.js";

import { DeprecatedTablePanel } from "components/deprecated/TablePanel";
import {
  USD_CREDIT_TYPE,
  displayCreditsInCurrencyWithoutRounding,
} from "app/lib/credits";

import { DeprecatedTable, TableProps } from "components/deprecated/Table";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { Schema } from "../../Schema";
import { useNow } from "lib/date";

import pluralize from "pluralize";
import { Pricing } from "../types";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";
import { CommitType } from "types/generated-graphql/__types__";
import { CreditType } from "app/types/credit-types";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";

interface Props {
  title?: string;
  rateRows: Array<Schema.Types.CommitFlyoverRoot>;
  pricing: Pricing;
  asCredit: boolean;
  creditTypes: CreditType[];
  onSelectRow?: (row: Schema.Types.CommitFlyoverRoot) => void;
  className?: string;
  controls?: React.ReactNode;
}

export const CommitsTable: React.FC<Props> = (props) => {
  const now = useNow();

  const getProductName = (productId: string) => {
    const product = props.pricing.products.find(
      (product) => product.id === productId,
    );
    if (!product) {
      throw new Error(`Product ${productId} not found`);
    }
    return ProductListItem.getName(product, now);
  };

  const getTotalCommit = (
    commit: Schema.Types.CommitFlyoverRoot["commit"],
  ): Decimal =>
    commit.accessSchedule.reduce(
      (total, item) => total.plus(item.amount),
      new Decimal(0),
    );

  const getTotalCost = (
    commit: Schema.Types.PrepaidCommit | Schema.Types.PostpaidCommit,
  ): Decimal => {
    if (commit.type === CommitType.Postpaid) {
      return commit.billingSchedule.reduce(
        (total, item) =>
          total.plus(new Decimal(item.quantity).mul(item.unitPrice)),
        new Decimal(0),
      );
    }
    switch (commit.billingSchedule.type) {
      case "fixed":
        return commit.billingSchedule.items.reduce(
          (total, item) =>
            total.plus(new Decimal(item.quantity).mul(item.unitPrice)),
          new Decimal(0),
        );
      case "recurring":
        const scheduleItems = RecurringSchedule.resolve({
          startDate: new Date(commit.billingSchedule.startDate),
          endDate: new Date(commit.billingSchedule.endDate),
          frequency: commit.billingSchedule.frequency,
          amountDistribution: commit.billingSchedule.amountDistribution,
          unitPrice: new Decimal(commit.billingSchedule.unitPrice),
          quantity: new Decimal(commit.billingSchedule.quantity),
        }).items;
        return scheduleItems.reduce((total, item) => {
          return total.plus(item.amount);
        }, new Decimal(0));
    }
  };

  const getNumInvoices = (
    commit: Schema.Types.PrepaidCommit | Schema.Types.PostpaidCommit,
  ): number => {
    if (commit.type === CommitType.Postpaid) {
      return commit.billingSchedule.length;
    }
    switch (commit.billingSchedule.type) {
      case "fixed":
        return commit.billingSchedule.items.length;
      case "recurring":
        return RecurringSchedule.resolve({
          startDate: new Date(commit.billingSchedule.startDate),
          endDate: new Date(commit.billingSchedule.endDate),
          frequency: commit.billingSchedule.frequency,
          amountDistribution: commit.billingSchedule.amountDistribution,
          unitPrice: new Decimal(commit.billingSchedule.unitPrice),
          quantity: new Decimal(commit.billingSchedule.quantity),
        }).items.length;
    }
  };

  const tableProps: TableProps<Schema.Types.CommitFlyoverRoot> = {
    onRowClick: props.onSelectRow
      ? (row) => {
          if (props.onSelectRow) {
            props.onSelectRow(row);
          }
        }
      : undefined,
    noPageReset: true,
    data: props.rateRows,
    columns: [
      {
        id: "commit",
        header: props.asCredit ? "Credit" : "Commit",
        cellClassName: "w-[200px]",
        render(row) {
          return row.name ? row.name : getProductName(row.productId);
        },
      },
      {
        id: "type",
        header: "Type",
        render(row) {
          if (props.asCredit) {
            return "Credit";
          }
          return row.type.charAt(0) + row.type.slice(1).toLowerCase();
        },
      },
      {
        id: "totalCommit",
        header: "Total " + (props.asCredit ? "credit" : "commit"),
        align: "right",
        cellClassName: "w-[175px]",
        render(row) {
          return displayCreditsInCurrencyWithoutRounding(
            getTotalCommit(row.commit),
            findCreditType(
              row.commit.accessScheduleCreditTypeId,
              props.creditTypes,
            ),
          );
        },
      },
      {
        id: "accessSchedule",
        header: "Access schedule",
        align: "right",
        cellClassName: "w-[150px]",
        render(row) {
          return `${row.commit.accessSchedule.length} ${pluralize(
            "segment",
            row.commit.accessSchedule.length,
          )}`;
        },
      },
      ...(props.asCredit
        ? []
        : [
            {
              id: "totalCost",
              header: "Total cost",
              align: "right" as const,
              cellClassName: "w-[175px]",
              render(row: Schema.Types.CommitFlyoverRoot) {
                return displayCreditsInCurrencyWithoutRounding(
                  getTotalCost(row.commit),
                  findCreditType(
                    row.commit.type === CommitType.Postpaid
                      ? row.commit.accessScheduleCreditTypeId
                      : row.commit.billingScheduleCreditTypeId ??
                          USD_CREDIT_TYPE.id,
                    props.creditTypes,
                  ),
                );
              },
            },
            {
              id: "billingSchedule",
              header: "Billing schedule",
              align: "right" as const,
              cellClassName: "w-[150px]",
              render(row: Schema.Types.CommitFlyoverRoot) {
                return `${getNumInvoices(row.commit)} ${pluralize(
                  "invoice",
                  getNumInvoices(row.commit),
                )}`;
              },
            },
          ]),
    ],
  };

  return props.title !== undefined ? (
    <DeprecatedTablePanel<Schema.Types.CommitFlyoverRoot>
      title={props.title}
      className={props.className}
      controls={props.controls}
      {...tableProps}
    />
  ) : (
    <DeprecatedTable<Schema.Types.CommitFlyoverRoot> {...tableProps} />
  );
};

import React from "react";
import { twMerge } from "twMerge";

const SIZE_VARIANTS = {
  sm: "h-2xl w-[36px]",
  md: "h-3xl w-[44px]",
};

export type ToggleProps = {
  /** Indicates whether or not Toggle is toggled */
  toggled?: boolean;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Indicates whether or not Toggle is disabled */
  disabled?: boolean;
  /** The label for the Toggle. */
  label?: string;
  /** Callback triggered when the state of the Toggle changes */
  onChange?: (meta: { toggled: boolean }) => void;
  /** Default - "md"; sm = 20px | md = 24px */
  size?: "sm" | "md";
  /** Optional text for a Toggle that sits below the lable. Use this to add context for a particular option */
  supportingText?: string;
};

/**
 * Toggles (also known as “switches”) is a UI control that has two mutually-exclusive states, such as ON and OFF.
 * The design and functionality of this component is based on a physical switch that allows users to turn
 * things ON or OFF. Toggles are a great visual cue for settings, especially on mobile, because they take
 * less screen estate (in comparison with two radio buttons).
 *  */
export const Toggle: React.FC<ToggleProps> = ({
  toggled,
  className,
  disabled,
  label,
  onChange,
  size = "md",
  supportingText,
}) => {
  const labelClassnames = twMerge(
    "flex items-start",
    disabled && "cursor-default",
    className,
  );

  const wrapperClassnames = twMerge(
    "focus-within:ring-gray-400/[.14] relative block cursor-pointer bg-gray-200 focus-within:ring-4",
    toggled &&
      !disabled &&
      "bg-core-jade-green focus-within:ring-core-jade-green/[.24]",
    disabled && "cursor-auto",
    size === "sm" ? "rounded-[40px]" : "rounded-[48px]",
    SIZE_VARIANTS[size],
  );

  const inputClassnames = twMerge(
    "cursor-pointer peer absolute h-full opacity-0 z-[2]",
    disabled && "bg-gray-50 cursor-auto",
  );

  return (
    <label className={labelClassnames}>
      <div className={wrapperClassnames}>
        <input
          className={inputClassnames}
          type="checkbox"
          onChange={(e) => onChange?.({ toggled: e.currentTarget.checked })}
          disabled={disabled}
          checked={toggled}
        />
        <div
          className={twMerge(
            "absolute left-xxs top-xxs rounded-full bg-white shadow-sm transition-transform duration-200 peer-checked:translate-x-full",
            size === "sm" ? "h-xl w-xl" : "h-2xl w-2xl",
          )}
        />
      </div>
      <div
        className={twMerge(
          "ml-md font-medium text-gray-600",
          size === "md" ? "text-md" : "text-sm",
        )}
      >
        <span className={disabled ? undefined : "cursor-pointer"}>{label}</span>
        {supportingText && <div className="font-normal">{supportingText}</div>}
      </div>
    </label>
  );
};

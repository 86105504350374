import React, { useContext } from "react";
import { DraftPlan } from "app/lib/plans/types";

interface DraftPlanContextType {
  draftPlan: DraftPlan;
  setDraftPlan: (draftPlan: DraftPlan) => void;
}

export const DraftPlanContext =
  React.createContext<DraftPlanContextType | null>(null);

export const useDraftPlan = (): DraftPlanContextType => {
  const ctx = useContext(DraftPlanContext);
  if (ctx === null) {
    throw new Error(
      "Cannot useDraftPlan in a context that doesn't have a draft plan",
    );
  }
  return ctx;
};

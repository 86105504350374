import React, { useState } from "react";
import { useNavigate } from "app/lib/useNavigate";
import { useRequiredParam } from "app/lib/routes/params";
import NotFoundPage from "app/pages/404";
import { LoadingSpinner } from "design-system";
import { useProductEditingInfoQuery } from "./data/queries.graphql";
import { EditInterstitial } from "./components/EditInterstitial";
import ProductEditForm from "./components/EditForm";
import { AppShell } from "components/AppShell";

const EditProduct: React.FC = () => {
  const navigate = useNavigate();
  const productId = useRequiredParam("id");

  const { data: productData, loading: productLoading } =
    useProductEditingInfoQuery({
      variables: { product_id: productId },
    });
  const product = productData?.Product_by_pk;

  const [passedLandingPage, setPassedLandingPage] = useState<boolean>(false);

  if (!passedLandingPage) {
    return (
      <EditInterstitial
        onClose={() => navigate(`/offering/plans/products/${productId}`)}
        onContinue={() => setPassedLandingPage(true)}
        productId={productId}
      />
    );
  }

  if (productLoading) {
    return (
      <AppShell title="Edit product">
        <LoadingSpinner />
      </AppShell>
    );
  }

  if (!product) {
    return <NotFoundPage />;
  }

  return <ProductEditForm product={product} />;
};

export default EditProduct;

import React, { useMemo, useState } from "react";
import { EditContractCtrl } from "app/pages/Contracts/Customer/Contracts/Create/EditContract";
import Decimal from "decimal.js";
import { IconButton } from "components/IconButton";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { TextInput } from "components/Input";
import { Schema } from "../Schema";

interface EditDetailsProps {
  ctrl: EditContractCtrl;
}

export const EditDetails: React.FC<EditDetailsProps> = ({ ctrl }) => {
  const mutableCommits: Schema.Types.MutableCommit[] =
    (ctrl.get("mutableCommits") as Schema.Types.MutableCommit[]) ?? [];
  const mutableScheduledCharges: Schema.Types.MutableScheduledCharge[] =
    (ctrl.get(
      "mutableScheduledCharges",
    ) as Schema.Types.MutableScheduledCharge[]) ?? [];

  const [showCommitModal, setShowCommitModal] = useState(false);
  const [showScheduledChargeModal, setShowScheduledChargeModal] =
    useState(false);

  const [isRolloverCommit, setIsRolloverCommit] = useState<boolean>(false);
  const [commitId, setCommitId] = useState<string>();
  const [chargeId, setChargeId] = useState<string>();
  const [rolloverFraction, setRolloverFraction] = useState<string | null>(null);
  const [netsuiteId, setNetsuiteId] = useState<string | null>(null);

  const clearAllAndClose = () => {
    setCommitId(undefined);
    setChargeId(undefined);
    setIsRolloverCommit(false);
    setRolloverFraction(null);
    setNetsuiteId(null);
    setShowCommitModal(false);
    setShowScheduledChargeModal(false);
  };

  // rollover_fraction validtion and messaging
  const rolloverError = useMemo(() => {
    if (rolloverFraction) {
      // remove non-numbers and allow only 1 decimal
      const fractionCheck = rolloverFraction
        .replace(/[^\d.]/g, "")
        .replace(/(\..*?)\./g, ".");
      if (rolloverFraction !== fractionCheck) {
        setRolloverFraction(fractionCheck);
      }

      if (fractionCheck.length > 1) {
        if (new Decimal(fractionCheck).greaterThan(100)) {
          return "Number must be less than or equal to 100";
        }
        if (new Decimal(fractionCheck).lessThan(0)) {
          return "Number must be greater than or equal to 0";
        }
      }
    }
    return undefined;
  }, [rolloverFraction]);

  const editContract = () => {
    if (!commitId || !chargeId) {
      clearAllAndClose();
    }

    if (!!commitId) {
      const updatedCommit = {
        netsuite_sales_order_id: netsuiteId,
        rollover_fraction: rolloverFraction,
      };
      let mutatedCommits = [...(ctrl.get("mutableCommits") ?? [])];
      const mutatedCommitIndex = mutatedCommits?.findIndex(
        (commit) => commit.commit_id === commitId,
      );

      if (mutatedCommitIndex !== -1) {
        mutatedCommits[mutatedCommitIndex] = {
          ...mutatedCommits[mutatedCommitIndex],
          ...updatedCommit,
        };
      }

      ctrl.update({
        mutableCommits: [...mutatedCommits],
      });
    } else if (!!chargeId) {
      const updatedCharge = {
        netsuite_sales_order_id: netsuiteId,
      };
      const mutatedCharges = [...(ctrl.get("mutableScheduledCharges") ?? [])];
      const mutatedChargeIndex = mutatedCharges?.findIndex(
        (charge) => charge.scheduled_charge_id === chargeId,
      );

      if (mutatedChargeIndex !== -1) {
        mutatedCharges[mutatedChargeIndex] = {
          ...mutatedCharges[mutatedChargeIndex],
          ...updatedCharge,
        };
      }

      ctrl.update({
        mutableScheduledCharges: [...mutatedCharges],
      });
    }

    clearAllAndClose();
  };

  return (
    <div className="flex flex-col gap-y-lg pl-lg">
      <div className="text-lg font-semibold">Edit details</div>

      <div>
        <div className="mb-3xl flex items-start gap-x-8">
          {mutableCommits.map((commit, index) => (
            <div
              className="text-sm text-gray-600"
              key={`${commit.commit_id}-${index}`}
            >
              <div className="flex items-center gap-x-sm text-sm font-semibold">
                <div>{commit.name}</div>
                <IconButton
                  icon="pencil01"
                  size="sm"
                  theme="linkGray"
                  onClick={() => {
                    setCommitId(commit.commit_id);
                    setIsRolloverCommit(commit.isRolloverCommit);
                    if (!commit.isRolloverCommit) {
                      setRolloverFraction(commit.rollover_fraction);
                    }
                    setNetsuiteId(commit.netsuite_sales_order_id);
                    setShowCommitModal(true);
                  }}
                />
              </div>
              {!commit.isRolloverCommit && commit.rollover_fraction && (
                <div>{commit.rollover_fraction}% rollover</div>
              )}
              <div>{commit.netsuite_sales_order_id}</div>
            </div>
          ))}
        </div>

        <div className="mb-3xl flex items-start">
          {mutableScheduledCharges.map((charge, index) => (
            <div
              className="text-sm text-gray-600"
              key={`${charge.scheduled_charge_id}-${index}`}
            >
              <div className="flex items-center gap-x-sm text-sm font-semibold">
                <div>{charge.name}</div>
                <IconButton
                  icon="pencil01"
                  theme="linkGray"
                  size="sm"
                  onClick={() => {
                    setChargeId(charge.scheduled_charge_id);
                    setNetsuiteId(charge.netsuite_sales_order_id);
                    setShowScheduledChargeModal(true);
                  }}
                />
              </div>
              <div>{charge.netsuite_sales_order_id}</div>
            </div>
          ))}
        </div>
      </div>

      {showCommitModal && (
        <Modal
          isOpen={showCommitModal}
          onClose={() => {
            clearAllAndClose();
          }}
          icon="checkCircle"
          title="Edit commit"
          supportingText="Modify rollover terms and sales order ID."
          modalButtons={[
            <Button
              size="lg"
              showFullWidth={true}
              text="Confirm"
              onClick={() => {
                if (!commitId) {
                  clearAllAndClose();
                  return;
                }
                editContract();
              }}
            />,
            <Button
              size="lg"
              showFullWidth={true}
              text="Cancel"
              onClick={clearAllAndClose}
              theme="secondary"
            />,
          ]}
        >
          {!isRolloverCommit && (
            <TextInput
              label="Rollover amount (optional)"
              placeholder="0.0"
              variantOptions={{
                variant: "icon",
                icon: "percent03",
              }}
              value={rolloverFraction ?? undefined}
              isInvalid={!!rolloverError}
              hintText={rolloverError}
              onBlur={(meta) => {
                if (!/^[\d.]+$/.test(meta.value)) {
                  setRolloverFraction(null);
                }
              }}
              onChange={(meta) =>
                setRolloverFraction(meta.value === "" ? null : meta.value)
              }
            />
          )}

          <TextInput
            label="NetSuite Sales Order ID (optional)"
            placeholder="123ab4f5b-fe67-8910-1c23-0a48e894a213"
            value={netsuiteId ?? undefined}
            onChange={(meta) =>
              setNetsuiteId(meta.value === "" ? null : meta.value)
            }
          />
        </Modal>
      )}

      {showScheduledChargeModal && (
        <Modal
          isOpen={showScheduledChargeModal}
          onClose={clearAllAndClose}
          icon="checkCircle"
          title="Edit scheduled charge"
          supportingText="Modify sales order ID."
          modalButtons={[
            <Button
              size="lg"
              showFullWidth={true}
              text="Confirm"
              onClick={() => {
                if (!chargeId) {
                  clearAllAndClose();
                  return;
                }
                editContract();
              }}
            />,
            <Button
              size="lg"
              showFullWidth={true}
              text="Cancel"
              onClick={clearAllAndClose}
              theme="secondary"
            />,
          ]}
        >
          <TextInput
            label="NetSuite Sales Order ID (optional)"
            placeholder="123ab4f5b-fe67-8910-1c23-0a48e894a213"
            value={netsuiteId ?? undefined}
            onChange={(meta) =>
              setNetsuiteId(meta.value === "" ? null : meta.value)
            }
          />
        </Modal>
      )}
    </div>
  );
};

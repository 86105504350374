import React, { useState } from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { NumericInput } from "design-system";
import { Button } from "components/Button";
import { useSnackbar } from "components/deprecated/Snackbar";

import { useEditNetPaymentTermsMutation } from "./queries.graphql";

interface EditNetPaymentTermsModalProps {
  onClose: () => void;
  customerPlanId: string;
  initialNetPaymentTerms: number | null;
}

export const EditNetPaymentTermsModal: React.FC<
  EditNetPaymentTermsModalProps
> = ({ onClose, customerPlanId, initialNetPaymentTerms }) => {
  const [editNPT, results] = useEditNetPaymentTermsMutation();
  const [netPaymentTerms, setNetPaymentTerms] = useState<number | null>(
    initialNetPaymentTerms,
  );
  const pushMessage = useSnackbar();

  const valid =
    !Number.isNaN(netPaymentTerms) &&
    (netPaymentTerms === null || netPaymentTerms >= 0);

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        key="primary"
        loading={results.loading}
        disabled={results.loading || !valid}
        onClick={async () => {
          await editNPT({
            variables: {
              customer_plan_id: customerPlanId,
              net_payment_terms: netPaymentTerms,
            },
          });
          pushMessage({
            content: "Net payment terms updated",
            type: "success",
          });
          onClose();
        }}
        text="Save"
        theme="primary"
      />
    </>
  );
  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={onClose}
      title="Edit net payment terms"
    >
      <NumericInput
        name="Net payment terms"
        onChange={setNetPaymentTerms}
        value={netPaymentTerms ?? undefined}
        placeholder="30"
        min={0}
        precision={0}
        success={valid}
        error={
          !valid
            ? "Net payment terms must be greater than or equal to zero"
            : false
        }
      />
    </DeprecatedPopup>
  );
};

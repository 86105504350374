import React from "react";
import { Input, DateInput, Select } from "design-system";
import {
  ContractUsageInvoiceBillingCycleAnchorEnum,
  TransitionTypeEnum,
} from "types/generated-graphql/__types__";

import { ContractPricing } from "app/pages/Contracts/lib/ContractPricing";
import type { CreateContractCtrl } from "../ContractCreate";
import { Section } from "../components/Section";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { printDate, toDayjs } from "lib/date";
import { ContractCreate_ExistingContractFragment } from "../data.graphql";
import { ContractUsageInvoiceScheduleFrequencyEnum } from "types/generated-graphql/__types__";

type Pricing = ContractPricing.Types.RateCardIds &
  ContractPricing.Types.RateCardNames;

interface Props {
  ctrl: CreateContractCtrl;
  pricing: Pricing;
  existingContracts: ContractCreate_ExistingContractFragment[];
  options?: {
    supersedeEnabled?: boolean;
    customBillingCycleDateEnabled?: boolean;
  };
}

function contractNamePlaceholder(props: Props) {
  const rateCardId = props.ctrl.get("rateCardId");
  if (!rateCardId) {
    return "Enter name or choose a rate card";
  }

  const rateCard = rateCardId
    ? ContractPricing.getRateCard(props.pricing, rateCardId)
    : null;
  return Contract.makeName(
    props.ctrl.get("startingAt") || printDate(toDayjs(new Date())),
    null,
    rateCard,
  );
}

function getMinDateForBillingAnchorDate(props: Props) {
  const startingDate = props.ctrl.get("startingAt");
  if (!startingDate) {
    return undefined;
  }
  let subtractMonths = 0;
  switch (props.ctrl.get("usageInvoiceFrequency")) {
    case ContractUsageInvoiceScheduleFrequencyEnum.Monthly:
      subtractMonths = 1;
      break;
    case ContractUsageInvoiceScheduleFrequencyEnum.Quarterly:
      subtractMonths = 3;
      break;
    case ContractUsageInvoiceScheduleFrequencyEnum.Annual:
      subtractMonths = 12;
      break;
  }
  return printDate(toDayjs(startingDate).subtract(subtractMonths, "month"));
}

export const ContractDetailsSection: React.FC<Props> = (props) => {
  const rateCards = ContractPricing.getRateCards(props.pricing);

  return (
    <Section
      title="Contract details"
      className="flex max-w-[1000px] flex-col gap-24 py-12"
    >
      <div className="grid grid-cols-4 gap-12">
        <Input
          {...props.ctrl.props.Input("name", {
            placeholder: contractNamePlaceholder(props),
            name:
              "Contract name" +
              (props.ctrl.get("rateCardId") ? " (optional)" : ""),
          })}
        />

        <Select
          {...props.ctrl.props.Select("rateCardId", {
            placeholder: "Select a rate card",
            name: "Rate card",
            options: [
              {
                label: "None",
                value: "",
              },
              ...rateCards.map((r) => ({
                label: r.name,
                value: r.id,
              })),
            ],
            map(update) {
              return {
                ...update,
                // clear the overrides whenever the rate card changes
                overrides: [],
              };
            },
          })}
        />

        <DateInput
          {...props.ctrl.props.DateInput("startingAt", {
            name: "Starting at",
            tooltip: "Inclusive starting time of the contract.",
          })}
        />

        <DateInput
          {...props.ctrl.props.DateInput("endingBefore", {
            name: "Ending before (optional)",
            tooltip: "Exclusive end time of the contract.",
            minDate: props.ctrl.get("startingAt"),
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-12">
        <Select
          {...props.ctrl.props.Select("usageInvoiceFrequency", {
            placeholder: "Select a usage statement frequency",
            name: "Usage statement frequency",
            options: [
              {
                label: "Monthly",
                value: ContractUsageInvoiceScheduleFrequencyEnum.Monthly,
              },
              {
                label: "Quarterly",
                value: ContractUsageInvoiceScheduleFrequencyEnum.Quarterly,
              },
              {
                label: "Annual",
                value: ContractUsageInvoiceScheduleFrequencyEnum.Annual,
              },
            ],
          })}
        />

        <Select
          {...props.ctrl.props.Select("billingCycleAnchor", {
            placeholder: "Select usage statement day",
            name: "Usage statement day",
            options: [
              {
                label: "First of month",
                value: ContractUsageInvoiceBillingCycleAnchorEnum.FirstOfMonth,
              },
              {
                label: "Contract start",
                value: ContractUsageInvoiceBillingCycleAnchorEnum.ContractStart,
              },
              ...(props.options?.customBillingCycleDateEnabled
                ? [
                    {
                      label: "Custom date",
                      value:
                        ContractUsageInvoiceBillingCycleAnchorEnum.CustomDate,
                    },
                  ]
                : []),
            ],
          })}
        />
        {props.options?.customBillingCycleDateEnabled &&
          props.ctrl.get("billingCycleAnchor") ===
            ContractUsageInvoiceBillingCycleAnchorEnum.CustomDate && (
            <DateInput
              {...props.ctrl.props.DateInput("billingCycleAnchorDate", {
                name: "Custom billing start date",
                tooltip:
                  "Set a billing anchor date to align future billing cycles. Usage statements will follow a selected cadence starting on this date.",
                maxDate: props.ctrl.get("startingAt"),
                minDate: getMinDateForBillingAnchorDate(props),
              })}
            />
          )}

        <Select
          {...props.ctrl.props.Select("multiplierOverridePrioritization", {
            placeholder: "Lowest multiplier",
            name: "Override prioritization scheme",
            options: [
              {
                label: "Lowest multiplier",
                value: "LOWEST_MULTIPLIER",
              },
              {
                label: "Explicit",
                value: "EXPLICIT",
              },
            ],
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-12">
        <Select
          {...props.ctrl.props.Select("transitionType", {
            name: "Contract transition type",
            disabled: !props.existingContracts.length,
            options: [
              {
                label: "None",
                value: "",
              },
              {
                label: "Renewal",
                value: TransitionTypeEnum.Renewal,
              },
              ...(props.options?.supersedeEnabled
                ? [
                    {
                      label: "Supersede",
                      value: TransitionTypeEnum.Supersede,
                    },
                  ]
                : []),
            ],
            placeholder: props.existingContracts.length
              ? "Select"
              : "No contracts to transition from",
            map: (update) =>
              update.transitionType
                ? update
                : {
                    ...update,
                    transitionPreviousContractId: undefined,
                  },
          })}
        />

        <Select
          {...props.ctrl.props.Select("transitionPreviousContractId", {
            name: "Previous contract",
            disabled:
              !props.existingContracts.length ||
              !props.ctrl.get("transitionType"),
            options: props.existingContracts.map((c) => ({
              label: Contract.getName(c),
              value: c.id,
            })),
            placeholder: "Search by name or ID",
            map(update) {
              if (
                props.ctrl.userUpdated("rateCardId") ||
                props.ctrl.userUpdated("overrides")
              ) {
                return update;
              }

              const selected = props.existingContracts.find(
                (c) => c.id === update.transitionPreviousContractId,
              );
              if (!selected?.rate_card) {
                return update;
              }

              return {
                ...update,
                rateCardId: selected.rate_card.id,
              };
            },
          })}
        />
      </div>
    </Section>
  );
};

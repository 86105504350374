import React, { useState } from "react";
import { useRequiredParam } from "app/lib/routes/params";
import { AppShell } from "components/AppShell";
import styles from "./index.module.less";
import { Caption, Subtitle } from "design-system";
import { GetAlertQuery, useGetAlertQuery } from "./queries.graphql";
import { Badge } from "design-system";
import { EmptyState } from "components/EmptyState";
import pluralize from "pluralize";
import NotFoundPage from "app/pages/404";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { AvatarWithName } from "design-system";
import {
  generateAlertFilterStrings,
  generateAlertPolicyString,
  useHasAccessToAlerts,
} from "app/lib/alerts";
import { ArchiveAlertModal } from "./components/ArchiveAlertModal";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { AlertTypeEnum_Enum } from "types/generated-graphql/__types__";
import { twMerge } from "twMerge";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { Breadcrumbs } from "app/lib/breadcrumbs";

type AlertDetailProps = {
  alertLoading: boolean;
  alert?: GetAlertQuery["alert"];
};

const AlertDetail: React.FC<AlertDetailProps> = ({ alertLoading, alert }) => {
  if (alertLoading || !alert) {
    return (
      <>
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
      </>
    );
  }
  const alertFilters = generateAlertFilterStrings(
    alert.filters,
    alert.alert_type ===
      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
  );
  return (
    <div className={styles.alert}>
      {alert?.Creator && <AvatarWithName {...alert.Creator} />}
      {alert && (
        <div className="mt-8">
          <DeprecatedCopyableID id={alert.id} label="alert ID" />
        </div>
      )}
      <div className={styles.alertDetails}>
        <div>
          <Caption>Alert Policy</Caption>
          {alert?.threshold && alert?.CreditType && (
            <Badge theme="primary" type="light" className={styles.policyBadge}>
              {generateAlertPolicyString(
                alert.threshold,
                alert.CreditType,
                alert.alert_type,
                alert.BillableMetric?.name,
              )}
            </Badge>
          )}
        </div>
        {alertFilters.length > 0 && (
          <div>
            <Caption>Advanced filters</Caption>
            {alertFilters.map((filter, idx) => {
              return (
                <span key={filter}>
                  <Badge
                    theme="primary"
                    type="light"
                    className="font-inter !w-max-content mb-4 max-w-[300px] font-normal text-deprecated-primary-600"
                  >
                    {filter}
                  </Badge>
                  {idx !== alertFilters.length - 1 && (
                    <span className="ml-8 mr-8 text-xxs font-normal text-gray-600">
                      OR
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        )}
        <div>
          <Caption>Customers</Caption>
          {alert?.Customer ? (
            <Subtitle level={3}>{alert.Customer.name}</Subtitle>
          ) : alert?.Plan ? (
            <Subtitle level={3}>
              {alert.Plan.name} (
              {pluralize("customer", alert.customer_count ?? 0, true)})
            </Subtitle>
          ) : (
            <Subtitle level={3}>
              All customers (
              {pluralize("customer", alert?.customer_count ?? 0, true)})
            </Subtitle>
          )}
        </div>
      </div>
    </div>
  );
};

const Alert: React.FC = () => {
  const alertsEnabled = useHasAccessToAlerts();
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const alertId = useRequiredParam("id");
  const {
    data: alertData,
    loading: alertLoading,
    error: alertError,
  } = useGetAlertQuery({
    variables: {
      id: alertId,
    },
  });

  const alert = alertData?.alert;
  const loading = alertLoading || alertsEnabled === undefined;

  if (!alertsEnabled || alert?.archived_at) {
    return <NotFoundPage />;
  }

  if (alertError) {
    const errorContent = (
      <EmptyState
        icon="bell03"
        mainText="We ran into an issue loading this alert"
        supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
      />
    );
    return <AppShell title="Error">{errorContent}</AppShell>;
  }

  if (!alert && !loading) {
    return <NotFoundPage />;
  }

  return (
    <>
      {showArchiveModal && (
        <ArchiveAlertModal
          alertName={alert?.name ?? ""}
          alertId={alertId}
          onClose={() => setShowArchiveModal(false)}
        />
      )}
      <AppShell
        title={!alert ? { state: "loading" } : alert.name}
        headerProps={{
          breadcrumbs: Breadcrumbs.from(
            {
              label: "Connections",
              routePath: "/connections/events",
            },
            {
              label: "Alerts",
              routePath: "/connections/alerts",
            },
          ),
          actions: [
            <Dropdown icon="dotsVertical" buttonSize="sm">
              <DropdownItem
                label="Manage custom fields..."
                value="manage-custom-fields"
                linkTo={`/custom-fields/alert/${alertId}`}
              />
              <DropdownItem
                label="Archive alert..."
                value="archive-alert"
                onClick={() => {
                  setShowArchiveModal(true);
                }}
              />
            </Dropdown>,
          ],
        }}
      >
        <div className={twMerge(styles.content, "pt-0")}>
          <AlertDetail alert={alert} alertLoading={loading} />
        </div>
      </AppShell>
    </>
  );
};

export default Alert;

import React from "react";

import { AppShell } from "components/AppShell";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";

import { useNavigate } from "app/lib/useNavigate";
import { useSearchParam } from "app/lib/routes/useSearchParam";
import { useRequiredParam } from "app/lib/routes/params";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";

import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import NotFoundPage from "app/pages/404";

import { CommitCard, parseCommitTab } from "./CommitCard";
import { useAsyncAllCommitUsage } from "../Usage";

import { useAllCommitsQuery } from "./data.graphql";
import { findCommit } from "./findCommit";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { dayjs } from "lib/dayjs";
export const CustomerCommitOverview: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const commitId = useRequiredParam("commitId");

  const navigate = useNavigate();
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);

  const req = useApolloResp(
    useAllCommitsQuery({
      variables: { customerId },
    }),
  );
  const asyncUsage = useAsyncAllCommitUsage({
    customerId,
  });

  let pageContent: React.ReactNode = null;
  let pageTitle: string | { state: "loading" } = { state: "loading" };
  if (req.state === "error") {
    pageTitle = "Error";
    pageContent = (
      <ErrorEmptyState
        title="We ran into an error loading this customer"
        error={req.error}
      />
    );
  } else if (req.state === "loading") {
    pageContent = (
      <DeprecatedParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />
    );
  } else if (req.state === "not found") {
    return <NotFoundPage />;
  } else {
    const commit = findCommit(req.customer, commitId);
    if (!commit) {
      return <NotFoundPage />;
    } else {
      pageTitle = Commit.getName(commit, dayjs());
      const routePath = `/customers/${customerId}/commits-and-credits/${commitId}`;
      pageContent = (
        <CommitCard
          commit={commit}
          activeTab={activeTabParam || "ledger"}
          asyncUsage={asyncUsage}
          onTabChange={(tab) => {
            navigate(`${routePath}?tab=${tab}`, {
              replace: true,
            });
          }}
        />
      );
    }
  }

  return (
    <AppShell
      title={pageTitle}
      headerProps={{
        breadcrumbs:
          req.state === "success" || req.state === "loading"
            ? Breadcrumbs.from(
                {
                  label: "Customers",
                  routePath: "/customers",
                },
                req.state === "loading"
                  ? { type: "loading", label: "" }
                  : {
                      label: req.customer.name,
                      routePath: `/customers/${customerId}`,
                    },
                {
                  label: "Contract commits and credits",
                  routePath: `/customers/${customerId}/commits-and-credits`,
                },
              )
            : undefined,
      }}
    >
      {pageContent}
    </AppShell>
  );
};

import React from "react";
import { Icon, Label, Toggle } from "design-system";
import Decimal from "decimal.js";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { displayCreditsInCurrencyWithoutRounding } from "app/lib/credits";
import { CreditTypeConversion } from "app/lib/plans/types";
import { FiatCreditType } from "app/types/credit-types";

interface OverageRateAdjustmentsProps {
  conversions: CreditTypeConversion[];
  adjustments: CreditTypeConversion[] | undefined;
  handleCreditTypeConversionAdjustmentsChange: (
    adjustments: CreditTypeConversion[] | undefined,
  ) => void;
}

export const OverageRateAdjustments: React.FC<OverageRateAdjustmentsProps> = (
  props,
) => {
  const {
    conversions,
    adjustments,
    handleCreditTypeConversionAdjustmentsChange,
  } = props;

  // Get the fiat credit type.
  const fiatCreditType = conversions[0].fiatCreditType;

  // Get the credit type IDs in ascending order from the credit type conversions.
  const sortedConversions = conversions.toSorted((l, r) =>
    new Decimal(l.startPeriod).minus(new Decimal(r.startPeriod)).toNumber(),
  );
  const customCreditTypeIds = sortedConversions
    .map((conversion) => conversion.customCreditType.id)
    .filter((element, index, arr) => arr.indexOf(element) === index);

  // Create a map from credit type to adjustment.
  const adjustmentsMap: Map<string, CreditTypeConversion> = adjustments
    ? adjustments.reduce((map, adjustment) => {
        map.set(adjustment.customCreditType.id, adjustment);
        return map;
      }, new Map<string, CreditTypeConversion>())
    : new Map<string, CreditTypeConversion>();

  // We show a warning that adjustments apply to all ramps if a user has defined ramp-specific overage rates.
  const hasRamps = conversions.some((conversion) => conversion.startPeriod > 0);

  return (
    <div className="mb-[24px] flex flex-col gap-12">
      {customCreditTypeIds.map((creditTypeId) => (
        <OverageRateAdjustment
          key={creditTypeId}
          fiatCreditType={fiatCreditType}
          conversion={
            sortedConversions.filter(
              (conversion) => conversion.customCreditType.id == creditTypeId,
            )[0]
          }
          adjustment={adjustmentsMap.get(creditTypeId)}
          handleCreditTypeConversionAdjustmentChange={(adjustment) => {
            if (adjustment == undefined) {
              handleCreditTypeConversionAdjustmentsChange(
                adjustments?.filter(
                  (adjustment) =>
                    adjustment.customCreditType.id != creditTypeId,
                ),
              );
            } else if (adjustments == undefined) {
              handleCreditTypeConversionAdjustmentsChange([adjustment]);
            } else {
              handleCreditTypeConversionAdjustmentsChange(
                adjustments
                  .filter(
                    (adjustment) =>
                      adjustment.customCreditType.id != creditTypeId,
                  )
                  .concat([adjustment]),
              );
            }
          }}
          hasRamps={hasRamps}
        />
      ))}
    </div>
  );
};

interface OverageRateAdjustmentProps {
  fiatCreditType: FiatCreditType;
  conversion: CreditTypeConversion;
  adjustment: CreditTypeConversion | undefined;
  handleCreditTypeConversionAdjustmentChange: (
    adjustment: CreditTypeConversion | undefined,
  ) => void;
  hasRamps: boolean;
}

const OverageRateAdjustment: React.FC<OverageRateAdjustmentProps> = (props) => {
  const {
    fiatCreditType,
    conversion,
    adjustment,
    handleCreditTypeConversionAdjustmentChange,
    hasRamps,
  } = props;
  const defaultFiatConversion: number = conversion.toFiatConversionFactor ?? 0;

  return (
    <div className="flex flex-col items-start">
      <Toggle
        label={
          <Label>
            Override the overage rate for{" "}
            <strong>{conversion.customCreditType.name}</strong> units (
            {displayCreditsInCurrencyWithoutRounding(
              new Decimal(defaultFiatConversion),
              fiatCreditType,
              true,
            )}
            )
          </Label>
        }
        checked={adjustment != undefined}
        onChange={(checked) => {
          if (!checked) {
            handleCreditTypeConversionAdjustmentChange(undefined);
          } else {
            handleCreditTypeConversionAdjustmentChange({
              startPeriod: 0,
              customCreditType: { ...conversion.customCreditType },
              fiatCreditType: { ...fiatCreditType },
              toFiatConversionFactor: defaultFiatConversion,
            });
          }
        }}
      />
      {adjustment !== undefined ? (
        <div className="flex flex-row items-center gap-12">
          <DeprecatedCreditInput
            creditType={props.fiatCreditType}
            initialValue={
              adjustment.toFiatConversionFactor
                ? new Decimal(adjustment.toFiatConversionFactor).toString()
                : ""
            }
            placeholder="123.45"
            allowZeroAmounts
            onChange={(v) => {
              if (v != adjustment.toFiatConversionFactor) {
                handleCreditTypeConversionAdjustmentChange({
                  ...adjustment,
                  toFiatConversionFactor: v ? v : undefined,
                });
              }
            }}
          />
          {hasRamps && (
            <div className="flex flex-row items-center gap-4">
              <Icon icon="warning" className="text-warning-600" />
              <Label className="text-warning-600">
                Overage rate will apply to all pricing ramps.
              </Label>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

import React from "react";
import { AvatarWithName, Caption } from "design-system";

import {
  RoundedCurrency,
  displayCreditsInCurrencyWithoutRounding,
} from "app/lib/credits";
import { DeprecatedPanel } from "components/deprecated/Panel";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { DeprecatedDefinitionList } from "components/deprecated/DefinitionList";

import { printDateRange, useNow } from "lib/date";
import { DetailedCustomerPlanFragmentReader } from "../../lib/CustomerPlan";
import { useFeatureFlag } from "app/lib/launchdarkly";
import Decimal from "decimal.js";

interface Props {
  customerPlan: DetailedCustomerPlanFragmentReader;
}

export const CustomerPlanDetailsPanel: React.FC<Props> = ({ customerPlan }) => {
  const canSetBillingProvider = useFeatureFlag(
    "set-billing-provider-on-plan",
    false,
  );

  const now = useNow();

  const adjustmentDefinitions =
    customerPlan.creditTypeConversionAdjustments?.map((adjustment) => ({
      label: `Overage rate for ${adjustment.CustomCreditType.name}`,
      value: displayCreditsInCurrencyWithoutRounding(
        new Decimal(adjustment.to_fiat_conversion_factor),
        {
          ...adjustment.FiatCreditType,
          client_id: null,
          environment_type: null,
        },
        true,
      ),
    })) ?? [];
  return (
    <DeprecatedPanel
      title="Plan details"
      className="my-12"
      contentClassName="p-12 gap-12 flex flex-col"
    >
      <p className="text-xs">{customerPlan.plan.description}</p>

      {customerPlan.plan.createdBy ? (
        <div className="flex flex-col gap-8">
          <AvatarWithName {...customerPlan.plan.createdBy} />
          <Caption level={2} className="uppercase">
            Plan ID
          </Caption>
          <DeprecatedCopyableID id={customerPlan.planId} label="plan ID" />
        </div>
      ) : null}

      <div className="flex gap-[36px] overflow-x-auto">
        <DeprecatedDefinitionList
          list={[
            {
              label: "Effective dates",
              value: printDateRange(
                now,
                customerPlan.dateRange.start,
                customerPlan.dateRange.end,
              ),
              badge: customerPlan.renderStatusPill(now),
            },
            {
              label: "Default plan length",
              value: customerPlan.plan.printDefaultPlanLength(),
            },
            {
              label: "Plan interval",
              value: customerPlan.plan.printBillingFrequency(),
            },
            {
              label: "Trial",
              value: customerPlan.printTrialInfo(),
            },
            {
              label: "Customer plan ID",
              value: (
                <DeprecatedCopyableID
                  id={customerPlan.id}
                  label="Customer plan ID"
                />
              ),
            },
            canSetBillingProvider
              ? {
                  label: "Billing Provider",
                  value: customerPlan.plan.printBillingProviderInfo(),
                }
              : {
                  label: "",
                  value: "",
                },
            ...customerPlan.plan.invoiceMinimums.map(
              ({ rampMinimum, creditType }, _, list) => ({
                label:
                  list.length > 1
                    ? `Invoice minimum (${creditType.name})`
                    : "Invoice minimum",
                value: (
                  <RoundedCurrency
                    amount={rampMinimum}
                    creditType={creditType}
                  />
                ),
              }),
            ),
            {
              label: "Net payment terms",
              value: customerPlan.printNetPaymentTerms(),
            },
          ].concat(adjustmentDefinitions)}
        />
      </div>
    </DeprecatedPanel>
  );
};

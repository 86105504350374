import React from "react";
import { useUpdateIngestAliasesMutation } from "app/pages/deprecated/Customer/tabs/Settings/sections/Identifiers/queries.graphql";

import { DeprecatedPopConfirm } from "../PopConfirm";
import { IconButton } from "components/IconButton";

type DeleteIngestAliasButtonProps = {
  customerId: string;
  ingestAlias: string;
  currentAliases: string[];
};
export const DeprecatedDeleteIngestAliasButton: React.FC<
  DeleteIngestAliasButtonProps
> = ({ customerId, ingestAlias, currentAliases }) => {
  const [setIngestAliases, setIngestAliasResults] =
    useUpdateIngestAliasesMutation();

  return (
    <DeprecatedPopConfirm
      content={
        <>
          Delete <b>{ingestAlias}</b>? Events with this ID will no longer be
          associated with this customer. This will not change finalized
          invoices.
        </>
      }
      onConfirm={async () => {
        await setIngestAliases({
          variables: {
            customer_id: customerId,
            ingest_aliases: currentAliases.filter(
              (alias) => alias !== ingestAlias,
            ),
          },
        });
      }}
      type="error"
    >
      <IconButton
        loading={setIngestAliasResults.loading}
        disabled={setIngestAliasResults.loading}
        theme="tertiary"
        icon="trash03"
        size="sm"
        className="hover:bg-transparent"
      />
    </DeprecatedPopConfirm>
  );
};

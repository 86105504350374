import React from "react";
import { twMerge } from "twMerge";

interface LoadingBlobProps {
  className?: string;
}

export const LoadingBlob: React.FC<LoadingBlobProps> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "my-sm flex h-sm w-full min-w-[50px] max-w-[300px] animate-pulse rounded-sm bg-gray-200 px-3xl py-xl align-middle",
        className,
      )}
    ></div>
  );
};

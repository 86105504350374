import React from "react";
import Decimal from "decimal.js";
import { Badge } from "design-system";

import { DeprecatedInPanelTable } from "components/deprecated/TablePanel";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { TinyErrorState } from "app/lib/errors/TinyErrorState";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import { useNow, toDayjs, printDate, isInRange } from "lib/date";

import { ColWidths } from "../../../lib/ColWidths";
import type { TabProps } from "./CommitCard";
import { RoundedCurrency } from "app/lib/credits";
import { CopyableID } from "components/CopyableID";

export const TabSegments: React.FC<TabProps> = ({ commit, asyncUsage }) => {
  const now = useNow();

  return (
    <DeprecatedInPanelTable
      data={commit.access_schedule.schedule_items}
      theadClassName="bg-deprecated-gray-lightest"
      columns={[
        {
          id: "name",
          header: "Segment",
          render: (row) => (
            <div className="flex-column">
              <div className="flex">
                {printDate(toDayjs(row.date))}
                {isInRange(now, toDayjs(row.date), toDayjs(row.end_date)) ? (
                  <Badge theme="primary" type="light" className="ml-8">
                    ACTIVE
                  </Badge>
                ) : null}
              </div>
              <CopyableID id={row.id} className="text-xs" />
            </div>
          ),
        },
        {
          id: "start",
          header: "Effective start",
          align: "right",
          cellClassName: ColWidths.DATE,
          render: (row) => printDate(toDayjs(row.date)),
        },
        {
          id: "end",
          header: "Effective end",
          align: "right",
          cellClassName: ColWidths.DATE,
          render: (row) => printDate(toDayjs(row.end_date)),
        },
        {
          id: "amt",
          header: "Amount committed",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={new Decimal(row.amount)}
              creditType={commit.access_schedule.credit_type}
            />
          ),
        },
        {
          id: "consumed",
          header: "Amount consumed",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => {
            if (!asyncUsage) {
              return null;
            }

            if (asyncUsage.loading) {
              return <DeprecatedTextSkeleton />;
            }

            if (asyncUsage.error) {
              return (
                <TinyErrorState
                  message={`Unable to load usage: ${getUserFacingErrorMessage(
                    asyncUsage.error,
                  )}`}
                />
              );
            }

            const { used } = asyncUsage.forCommitAccessSegment(
              commit.id,
              row.id,
            );

            return (
              <RoundedCurrency
                amount={used}
                creditType={commit.access_schedule.credit_type}
              />
            );
          },
        },
      ]}
    />
  );
};

import { useNavigate } from "app/lib/useNavigate";
import { useSnackbar } from "components/deprecated/Snackbar";
import { planToDraftPlan, serializeDraftPlan } from "./draftPlan";
import {
  useInsertDraftPlanMutation,
  usePlanLazyQuery,
} from "./queries.graphql";
import { DraftPlan } from "./types";

export const useCopyPlan = () => {
  const [insertDraftPlan] = useInsertDraftPlanMutation({
    update(cache) {
      cache.evict({
        fieldName: "DraftPlan",
      });
    },
  });

  const pushMessage = useSnackbar();
  const navigate = useNavigate();

  const copyDraftPlan = async (draftPlan: DraftPlan) => {
    try {
      const result = await insertDraftPlan({
        variables: {
          data: {
            ...serializeDraftPlan({
              ...draftPlan,
              name: `${draftPlan.name} (copy)`,
              revision: 0,
            }),
          },
        },
      });

      const id = result.data?.insert_DraftPlan_one?.id;
      if (!id) {
        throw new Error("Error inserting duplicate plan");
      }
      navigate(`/offering/plans/new/${id}`, {
        state: { copy: true },
      });
    } catch (err) {
      pushMessage({
        content: "Error duplicating plan",
        type: "error",
      });
    }
  };

  const [detailedPlanQuery] = usePlanLazyQuery({
    onCompleted: async ({ Plan_by_pk: detailedPlan }) => {
      if (!detailedPlan) {
        throw new Error("Error finding selected plan");
      }

      await copyDraftPlan({
        ...planToDraftPlan(detailedPlan),
        revision: 0,
      });
    },
  });

  const copyPlan = async (planId: string) => {
    await detailedPlanQuery({
      variables: { id: planId },
    });
  };

  return { copyDraftPlan, copyPlan };
};

import React from "react";
import Fuse from "fuse.js";
import { get } from "app/lib/typeHelpers/object";

interface SearcherConfig<T extends object> extends Fuse.IFuseOptions<T> {
  uuidKeys?: Array<keyof T>;
}

function makeSearcher<T extends object>(items: T[], config: SearcherConfig<T>) {
  const index = new Fuse(items, config);

  return (query: string) => {
    if (!query) {
      return items;
    }

    const uuidKeys = config.uuidKeys ?? ["id"];
    const uuidMatches = items.filter((item) =>
      uuidKeys.some((key) => get(item, key) === query),
    );

    // if the query matches any uuid in the items then limit the results to those items
    if (uuidMatches.length) {
      return uuidMatches;
    }

    const results = index.search(query);

    return results.map((match) => match.item);
  };
}

export function useSearcher<T extends object>(
  items: T[],
  config: Fuse.FuseOptionKey<T>[] | SearcherConfig<T>,
) {
  return React.useMemo(() => {
    const prevResult: { input?: string; results?: T[] } = {};

    const searcher = makeSearcher(
      items,
      Array.isArray(config)
        ? {
            keys: config,
          }
        : config,
    );
    return (query: string) => {
      if (prevResult.results && prevResult.input === query) {
        return prevResult.results;
      }

      const results = searcher(query);
      prevResult.input = query;
      prevResult.results = results;
      return results;
    };
  }, [items, JSON.stringify(config)]);
}

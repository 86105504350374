import React, { useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./index.module.less";
import { DraftPlan } from "app/lib/plans/types";
import { removeProductsFromDraftPlan } from "app/lib/plans/draftPlan";
import { useDraftPlan } from "../../context";
import {
  DeprecatedWizardFullPage,
  WizardSection,
} from "components/deprecated/Wizard";
import { DeprecatedCards } from "components/deprecated/Cards";
import { CreatePlanDataQuery } from "../../data/queries.graphql";
import { renderDate } from "lib/time";
import {
  AvatarWithName,
  Body,
  Headline,
  Icon,
  Input,
  Tooltip,
} from "design-system";
import { Button } from "components/Button";
import Fuse from "fuse.js";
import pluralize from "pluralize";
import { ProductDetails } from "./components/ProductDetails";
import { annualSeatsConfigIsDone, AnnualSeatsPage } from "./AnnualSeatsPage";
import { ChargeTypeEnum_Enum } from "types/generated-graphql/__types__";

const addingProductsIsDone = (draftPlan: DraftPlan) => {
  return !!(
    draftPlan.selectedProductIds && draftPlan.selectedProductIds.length
  );
};

interface AddProductsPageProps {
  data: CreatePlanDataQuery;
}
const AddProductsPage: React.FC<AddProductsPageProps> = (props) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [previewedProduct, setPreviewedProduct] = useState<string | null>(null);

  const products = props.data?.products || [];

  const fuse = useMemo(() => {
    return new Fuse(products, {
      keys: ["name"],
      threshold: 0.4,
    });
  }, [products]);
  const filteredProducts = searchQuery
    ? fuse.search(searchQuery).map((match) => match.item)
    : products;

  const toggleSelect = (id: string) => {
    if (draftPlan.selectedProductIds) {
      if (draftPlan.selectedProductIds.includes(id)) {
        setDraftPlan(removeProductsFromDraftPlan(draftPlan, [id]));
      } else {
        setDraftPlan({
          ...draftPlan,
          selectedProductIds: [...draftPlan.selectedProductIds, id],
        });
      }
    } else {
      setDraftPlan({
        ...draftPlan,
        selectedProductIds: [id],
      });
    }
  };

  const selectedProductCount = draftPlan.selectedProductIds?.length ?? 0;

  return (
    <DeprecatedWizardFullPage>
      {previewedProduct && (
        <ProductDetails
          onRequestClose={() => {
            setPreviewedProduct(null);
          }}
          productId={previewedProduct}
        />
      )}
      <div className={styles.header}>
        <div className={styles.left}>
          <Headline level={6}>Select products</Headline>
          <Body level={1}>
            Products are the driving factor to pricing all fees associated with
            your plan. Select the corresponding products to this plan.
          </Body>
        </div>
        <div
          className={classNames(styles.right, {
            [styles.selected]: selectedProductCount > 0,
          })}
        >
          <Headline
            level={4}
            className={classNames({
              [styles.selected]: selectedProductCount > 0,
            })}
          >
            {String(selectedProductCount)}
          </Headline>
          <div className={styles.productCountLabel}>
            <Icon
              icon="cart"
              className={classNames(styles.productCountIcon, {
                [styles.selected]: selectedProductCount > 0,
              })}
            />
            <Headline
              level={6}
              className={classNames({
                [styles.selected]: selectedProductCount > 0,
              })}
            >
              {pluralize("Product", selectedProductCount)}
            </Headline>
          </div>
          <Input
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={setSearchQuery}
            leftIcon="search"
            className={styles.searchBar}
          />
        </div>
      </div>
      <DeprecatedCards
        cards={filteredProducts.map((product) => {
          const isSelected =
            draftPlan.selectedProductIds &&
            draftPlan.selectedProductIds.includes(product.id);
          return {
            onClick: () => toggleSelect(product.id),
            id: product.id,
            title: product.name,
            badge: (
              <>
                <div className={styles.previewButton}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setPreviewedProduct(product.id);
                    }}
                    text="Preview"
                    theme="linkGray"
                    leadingIcon="eye"
                  />
                </div>
                <Icon
                  icon="checkmarkCircle"
                  className={classNames(styles.icon, {
                    [styles.selected]: isSelected,
                  })}
                />
              </>
            ),
            content: product.description,
            statistics: [
              {
                icon: "pricetag",
                value: product.ProductPricingFactors.length ?? 0,
                label: "charge",
              },
              {
                icon: "receipt",
                value: product.active_plan_count,
                label: "plan",
              },
            ],
            className: classNames(styles.productCard, {
              [styles.selected]: isSelected,
            }),
            footer:
              product.Actor != null ? (
                <Tooltip
                  content={
                    <>
                      Created by {product.Actor.name}
                      <br />
                      {renderDate(new Date(product.created_at), {
                        isUtc: false,
                      })}
                    </>
                  }
                >
                  <AvatarWithName {...product.Actor} />
                </Tooltip>
              ) : undefined,
          };
        })}
      />
    </DeprecatedWizardFullPage>
  );
};

export const addProductsSection: (
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
  allowAnnualSeats: boolean,
  editing: boolean,
) => WizardSection = (draftPlan, data, allowAnnualSeats, editing) => ({
  title: "Add products",
  isDone:
    addingProductsIsDone(draftPlan) &&
    (!allowAnnualSeats || annualSeatsConfigIsDone(draftPlan)),
  icon: "cart",
  subStepGroups: [
    {
      isDone:
        addingProductsIsDone(draftPlan) &&
        (!allowAnnualSeats || annualSeatsConfigIsDone(draftPlan)),
      subSteps: [
        {
          header: "Add products to this plan",
          title: "Select products",
          component: <AddProductsPage data={data} />,
          isDone: addingProductsIsDone(draftPlan),
        },
        ...(showAnnualSeatsConfigPage(draftPlan, data, allowAnnualSeats)
          ? [
              {
                header: "Seats invoice frequency",
                title: "Seats invoice frequency",
                component: <AnnualSeatsPage editing={editing} />,
                isDone: annualSeatsConfigIsDone(draftPlan),
              },
            ]
          : []),
      ],
    },
  ],
});

function showAnnualSeatsConfigPage(
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
  allowAnnualSeats: boolean,
) {
  return (
    allowAnnualSeats &&
    draftPlan.selectedProductIds &&
    draftPlan.selectedProductIds.some((id) =>
      data.products
        .find((p) => p.id === id)
        ?.ProductPricingFactors.some(
          (ppf) => ppf.charge_type_enum === ChargeTypeEnum_Enum.Seat,
        ),
    )
  );
}

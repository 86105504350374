import React from "react";

import { useNow, printDate, Dayjs } from "lib/date";
import { ContractTerms } from "app/pages/Contracts/lib/ContractTerms";
import { useInvoiceStatusPillsForId } from "app/pages/Contracts/lib/Invoice";
import {
  DiscountTermFlyoverFragment,
  ProServiceTermFlyoverFragment,
  ScheduledChargeTermFlyoverFragment,
} from "./data.graphql";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { DeprecatedFlyoverWithMetadataTable } from "components/deprecated/FlyoverWithMetadataTable";
import { Table } from "components/Table";

interface Props<TermFragment extends { __typename?: string }> {
  term: TermFragment;
  onRequestClose: () => void;
}

export const DiscountTermFlyover: React.FC<
  Props<DiscountTermFlyoverFragment>
> = (props) => {
  const now = useNow();

  const billingSchedule = ContractTerms.getBillingSchedule(props.term);

  return (
    <DeprecatedFlyoverWithMetadataTable
      instanceType="discount"
      onRequestClose={props.onRequestClose}
      title={ContractTerms.getName(props.term, now)}
      metadataRows={
        new Map([
          ["id", props.term.id],
          ["product_id", props.term.product.id],
          ["charge_type", "Discount"],
          ["netsuite_sales_order_id", props.term.netsuite_sales_order_id ?? ""],
        ])
      }
      options={{
        removeEmptyValueRows: true,
      }}
      children={
        billingSchedule && (
          <BillingScheduleTable billingSchedule={billingSchedule} />
        )
      }
    />
  );
};

export const ScheduledChargeTermFlyover: React.FC<
  Props<ScheduledChargeTermFlyoverFragment>
> = (props) => {
  const now = useNow();

  const billingSchedule = ContractTerms.getBillingSchedule(props.term);

  return (
    <DeprecatedFlyoverWithMetadataTable
      instanceType="scheduled charge"
      onRequestClose={props.onRequestClose}
      title={ContractTerms.getName(props.term, now)}
      metadataRows={
        new Map([
          ["id", props.term.id],
          ["product_id", props.term.product.id],
          ["charge_type", "Scheduled Charge"],
          ["netsuite_sales_order_id", props.term.netsuite_sales_order_id ?? ""],
        ])
      }
      options={{
        removeEmptyValueRows: true,
      }}
      children={
        billingSchedule && (
          <BillingScheduleTable billingSchedule={billingSchedule} />
        )
      }
      dropdownMenuItems={[
        {
          content: "Manage custom fields...",
          routePath: `/custom-fields/scheduled-charge/${props.term.id}`,
        },
      ]}
    />
  );
};

export const ProServiceTermFlyover: React.FC<
  Props<ProServiceTermFlyoverFragment>
> = (props) => {
  const now = useNow();

  return (
    <DeprecatedFlyoverWithMetadataTable
      instanceType="pro service"
      onRequestClose={props.onRequestClose}
      title={ContractTerms.getName(props.term, now)}
      metadataRows={
        new Map([
          ["id", props.term.id],
          ["description", props.term.description ?? ""],
          // TODO: Remove conditional after making `product` required in MRI
          ["product_id", props.term.product?.id ?? ""],
          ["quantity", props.term.quantity],
          ["unit_price", props.term.unit_price],
          // TODO: Remove conditional after making `max_amount` required in MRI
          ["max_amount", props.term.max_amount ?? ""],
          ["netsuite_sales_order_id", props.term.netsuite_sales_order_id ?? ""],
        ])
      }
      options={{
        removeEmptyValueRows: true,
      }}
      dropdownMenuItems={[
        {
          content: "Manage custom fields...",
          routePath: `/custom-fields/professional-service/${props.term.id}`,
        },
      ]}
    />
  );
};
export const ResellerRoyaltyTermFlyover: React.FC<
  Props<Contract.ResellerRoyaltyState>
> = (props) => {
  const now = useNow();
  const applicableProductTags =
    "applicable_product_tags" in props.term
      ? props.term.applicable_product_tags.map((tag) => {
          return {
            id: tag,
            tag,
          };
        })
      : [];

  const rows = new Map([
    ["reseller_type", ContractTerms.getType(props.term)],
    ["netsuite_reseller_id", props.term.netsuite_reseller_id],
  ]);

  if (
    props.term.__typename === "AWSRoyalty" ||
    props.term.__typename === "AWSProServiceRoyalty"
  ) {
    rows.set("aws_account_number", props.term.aws_account_number ?? "");
    rows.set("aws_payer_reference_id", props.term.aws_payer_reference_id ?? "");
    rows.set("aws_offer_id", props.term.aws_offer_id ?? "");
  }
  if (
    props.term.__typename === "GCPRoyalty" ||
    props.term.__typename === "GCPProServiceRoyalty"
  ) {
    rows.set("gcp_account_id", props.term.gcp_account_id ?? "");
    rows.set("gcp_offer_id", props.term.gcp_offer_id ?? "");
  }

  return (
    <DeprecatedFlyoverWithMetadataTable
      instanceType="term"
      onRequestClose={props.onRequestClose}
      title={ContractTerms.getName(props.term, now)}
      metadataRows={rows}
      options={{
        removeEmptyValueRows: true,
      }}
    >
      {applicableProductTags.length > 0 ? (
        <div className="py-12">
          <Table
            title="Product tags"
            data={applicableProductTags}
            columns={[
              {
                id: "tag",
                header: "Name",
                cell: (props) => props.getValue(),
                accessorFn: (row) => row.tag.toString(),
                enableSorting: false,
              },
            ]}
          />
        </div>
      ) : null}
    </DeprecatedFlyoverWithMetadataTable>
  );
};

const BillingScheduleTable = ({
  billingSchedule,
}: {
  billingSchedule: {
    id: string;
    date: Dayjs;
  }[];
}) => {
  const statusPillsForIds = useInvoiceStatusPillsForId(
    billingSchedule.map(({ id }) => id),
  );
  return (
    <div className="mt-12">
      <Table
        title="Billing schedule"
        data={billingSchedule}
        columns={[
          {
            id: "date",
            header: "Date",
            cell: (props) => props.getValue(),
            accessorFn: (row) => printDate(row.date),
            enableSorting: false,
          },
          {
            id: "status",
            header: "Status",
            cell: (props) => statusPillsForIds(props.getValue()),
            accessorFn: (row) => row.id,
            enableSorting: false,
          },
        ]}
      />
    </div>
  );
};

/* ca3ed6ff5ef94309752e2947598e836e99f211d8
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerNameQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type GetCustomerNameQuery = { __typename?: 'Query', Customer: { __typename?: 'Customer', id: string, name: string } | null };

export type UpdateCustomerNameMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
}>;


export type UpdateCustomerNameMutation = { __typename?: 'Mutation', set_customer_name: { __typename?: 'Customer', id: string, name: string } };


export const GetCustomerNameDocument = gql`
    query GetCustomerName($customer_id: uuid!) {
  Customer: Customer_by_pk(id: $customer_id) {
    id
    name
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomerNameQuery__
 *
 * To run a query within a React component, call `useGetCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerNameQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useGetCustomerNameQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerNameQuery, GetCustomerNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerNameQuery, GetCustomerNameQueryVariables>(GetCustomerNameDocument, options);
      }
export function useGetCustomerNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerNameQuery, GetCustomerNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerNameQuery, GetCustomerNameQueryVariables>(GetCustomerNameDocument, options);
        }
export type GetCustomerNameQueryHookResult = ReturnType<typeof useGetCustomerNameQuery>;
export type GetCustomerNameLazyQueryHookResult = ReturnType<typeof useGetCustomerNameLazyQuery>;
export type GetCustomerNameQueryResult = Apollo.QueryResult<GetCustomerNameQuery, GetCustomerNameQueryVariables>;
export const UpdateCustomerNameDocument = gql`
    mutation UpdateCustomerName($customer_id: uuid!, $name: String!) {
  set_customer_name(id: $customer_id, name: $name) {
    id
    name
    __environment_type: environment_type
  }
}
    `;
export type UpdateCustomerNameMutationFn = Apollo.MutationFunction<UpdateCustomerNameMutation, UpdateCustomerNameMutationVariables>;

/**
 * __useUpdateCustomerNameMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerNameMutation, { data, loading, error }] = useUpdateCustomerNameMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCustomerNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerNameMutation, UpdateCustomerNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerNameMutation, UpdateCustomerNameMutationVariables>(UpdateCustomerNameDocument, options);
      }
export type UpdateCustomerNameMutationHookResult = ReturnType<typeof useUpdateCustomerNameMutation>;
export type UpdateCustomerNameMutationResult = Apollo.MutationResult<UpdateCustomerNameMutation>;
export type UpdateCustomerNameMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerNameMutation, UpdateCustomerNameMutationVariables>;
import React, { useCallback } from "react";
import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { CreditType } from "app/types/credit-types";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { CurrencyInput } from "./CurrencyInput";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onChange: (rate: Schema.Types.Rate) => void;
  onConversionRateChange: (
    conversion: Schema.Types.CreditTypeConversion,
  ) => void;
  allowEditCreditConversions: boolean;
  disableChangingCreditTypes: boolean;
}

export const RateFlat: React.FC<Props> = ({
  rate,
  fiatCreditType,
  customCreditTypes,
  creditTypeConversions,
  onChange,
  onConversionRateChange,
  disabled,
  allowEditCreditConversions,
  disableChangingCreditTypes,
}) => {
  const currentPrice =
    rate.price.type === "flat" ? rate.price.price : undefined;
  const onPriceChange = useCallback(
    (price: number | undefined) => {
      if (price !== currentPrice) {
        onChange({
          ...rate,
          price: {
            type: "flat",
            price,
          },
        });
      }
    },
    [onChange, rate],
  );
  return (
    <CurrencyInput
      disabled={disabled}
      creditTypeConversions={creditTypeConversions}
      customCreditTypes={customCreditTypes}
      price={currentPrice}
      rateCreditType={rate.creditType ?? USD_CREDIT_TYPE}
      rateCardFiatCreditType={fiatCreditType}
      onCreditTypeChange={(creditType, conversion) => {
        onChange({
          ...rate,
          creditType,
        });

        if (conversion) {
          onConversionRateChange(conversion);
        }
      }}
      onPriceChange={onPriceChange}
      allowEditCreditConversions={allowEditCreditConversions}
      disableChangingCreditTypes={disableChangingCreditTypes}
    />
  );
};

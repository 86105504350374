import React from "react";
import { Modal } from "components/Modal";

export interface AdjustmentConfirmationModalProps {
  adjustmentMultiplier: number;
  onSave: () => void;
  onClose: () => void;
}

export const AdjustmentConfirmationModal: React.FC<
  AdjustmentConfirmationModalProps
> = ({ adjustmentMultiplier, onSave, onClose }) => {
  return (
    <Modal
      showCloseButton={true}
      title="Confirm adjustment multiplier"
      isOpen={true}
      headerLayout="horizontalLeft"
      buttonLayout="alignedRight"
      // Using z-toast here is a bit of a hack because the <RightPane> component which
      // this overlays is using the same z-index as a modal (z-modal). This is a quick
      // temporary solution until we swap out the <RightPane> component with the t10
      // <SideSheet> component.
      overlayClassName="z-toast bg-gray-950/[0.7]"
      modalButtons={[
        {
          key: "confirm",
          type: "button",
          props: {
            text: "Confirm",
            theme: "primary",
            onClick: () => {
              onSave();
              onClose();
            },
          },
        },
        {
          key: "cancel",
          type: "button",
          props: {
            text: "Cancel",
            theme: "secondary",
            onClick: () => {
              onClose();
            },
          },
        },
      ]}
      size="lg"
      onClose={onClose}
    >
      <div className="text-sm text-gray-600">
        <div>
          Your adjustment multiplier is set to{" "}
          <span className="font-bold">
            {adjustmentMultiplier === 1
              ? "make no change to the price"
              : "increase the price"}
          </span>
          . Click "Confirm" to save this adjustment or "Cancel" to make changes.
        </div>
      </div>
    </Modal>
  );
};

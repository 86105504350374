import React, { Dispatch, SetStateAction, useState } from "react";
import { TextInput } from "components/Input";
import pluralize from "pluralize";

interface FirstDeleteStepProps {
  numCustomers: number;
  setHasPassedFirstDeleteStep: Dispatch<SetStateAction<boolean>>;
  setDisableStripeButtonIsDisabled: Dispatch<SetStateAction<boolean>>;
  disableStripeButtonIsDisabled: boolean;
}

export const FirstDeleteStep = ({
  numCustomers,
  setHasPassedFirstDeleteStep,
  setDisableStripeButtonIsDisabled,
  disableStripeButtonIsDisabled,
}: FirstDeleteStepProps) => {
  const [
    numCustomersStripeSettingsToDelete,
    setNumCustomersStripeSettingsToDelete,
  ] = useState("");

  const numCustomersStripeSettingsToDeleteValueIsValid = (value: string) => {
    return value === `${numCustomers} ${pluralize("customer", numCustomers)}`;
  };

  const customerLabel = numCustomers > 1 ? "customers" : "customer";

  const handleShowHintText = (): string | undefined => {
    return numCustomersStripeSettingsToDelete.length > 0 &&
      disableStripeButtonIsDisabled
      ? `Enter ${numCustomers} ${customerLabel}`
      : undefined;
  };

  const handleOnKeyDown = (meta: { key: string; value: string }) => {
    if (meta.key === "Enter" && meta.value.length > 0) {
      if (disableStripeButtonIsDisabled) {
        return;
      }
      setHasPassedFirstDeleteStep(true);
    }
    return;
  };

  const isValidInputValue = numCustomersStripeSettingsToDeleteValueIsValid(
    numCustomersStripeSettingsToDelete,
  );

  return (
    <div className="flex flex-col justify-center gap-24">
      <div className="leading-tight text-sm">
        Disabling Stripe will prevent you from sending invoices via Stripe to
        all your customers. There {numCustomers === 1 ? "is" : "are"} currently{" "}
        {numCustomers} {pluralize("customer", numCustomers)} connected to
        Stripe.
      </div>
      {numCustomers > 0 && (
        <TextInput
          placeholder={`${numCustomers} ${customerLabel}`}
          label="Enter the number of customers impacted."
          value={numCustomersStripeSettingsToDelete || ""}
          onChange={(meta: { value: string }) => {
            setNumCustomersStripeSettingsToDelete(meta.value);
            setDisableStripeButtonIsDisabled(!isValidInputValue);
          }}
          isInvalid={disableStripeButtonIsDisabled}
          hintText={handleShowHintText()}
          onKeyDown={(meta: { key: string; value: string }) => {
            handleOnKeyDown(meta);
          }}
        />
      )}
    </div>
  );
};

import React from "react";
import { ReactComponent as MicrosoftLogo } from "icons/microsoft.svg";
import { ReactComponent as AWSMarketPlaceLogo } from "icons/aws.svg";
import { ReactComponent as GCPMarketplaceLogo } from "icons/gcp.svg";
import { ReactComponent as SalesForceLogo } from "icons/salesforce.svg";
import { ReactComponent as StripeLogo } from "icons/stripe.svg";
import { ReactComponent as NetsuiteLogo } from "icons/netsuite.svg";
import { ReactComponent as WorkdayLogo } from "icons/workday.svg";
import { ReactComponent as QuickBooksOnlineLogo } from "icons/quickbooks.svg";
import { Badge } from "components/Badge";
import { Tooltip } from "components/Tooltip";
import { BadgeGroup } from "components/BadgeGroup";
import { CopyableID } from "components/CopyableID";
import { DeprecatedStatusLight } from "components/deprecated/StatusLight";

export interface IntegrationDetailProps {
  integrationName: string;
  supportingText: string;
  isActive?: boolean;
  displayedSettings?: Record<
    string,
    | string
    | {
        value: string;
        hasBadgeLabel: true;
      }
  >;
  titleComponent?: React.ReactNode;
}

const renderSVG = (integrationName: string) => {
  switch (integrationName) {
    case "Azure Marketplace":
      return <MicrosoftLogo />;
    case "AWS Marketplace":
      return <AWSMarketPlaceLogo />;
    case "GCP Marketplace":
      return <GCPMarketplaceLogo />;
    case "Salesforce":
      return (
        <div className="h-[32px] w-[32px] pt-8">
          <SalesForceLogo />
        </div>
      );
    case "Stripe":
      return <StripeLogo />;
    case "NetSuite":
      return <NetsuiteLogo />;
    case "Workday":
      return <WorkdayLogo />;
    case "Quickbooks Online":
      return <QuickBooksOnlineLogo />;
    default:
      return null;
  }
};

export const IntegrationDetails: React.FC<IntegrationDetailProps> = ({
  integrationName,
  supportingText,
  isActive,
  displayedSettings,
  titleComponent,
}) => {
  return (
    <div className="flex gap-lg">
      <div className="float-left flex items-center">
        {renderSVG(integrationName)}
      </div>
      <div className="float-right flex flex-col justify-between">
        <div className="flex items-center text-md font-medium leading-5 text-core-slate">
          {titleComponent ? (
            <div className="flex items-center">
              {titleComponent}
              {isActive !== undefined && (
                <div className="ml-8">
                  <DeprecatedStatusLight status={isActive ? "on" : "off"} />
                </div>
              )}
            </div>
          ) : (
            <Tooltip
              disabled={isActive === undefined}
              label={isActive ? "Active" : "Inactive"}
            >
              <div className="flex items-center">
                <span>{integrationName}</span>
                {isActive !== undefined && (
                  <div className="ml-8">
                    <DeprecatedStatusLight status={isActive ? "on" : "off"} />
                  </div>
                )}
              </div>
            </Tooltip>
          )}
        </div>
        <div className="text-xs font-normal leading-[18px]">
          {supportingText}
        </div>
        {displayedSettings && (
          <div className="mt-[8px] grid gap-y-[6px] text-xs font-normal leading-[18px]">
            {Object.entries(displayedSettings).map(([key, value], index) => (
              <Tooltip key={`integration-details-config-${index}`} label={key}>
                {typeof value === "object" && value.hasBadgeLabel === true ? (
                  <BadgeGroup badgeLabel={key} mainLabel={value.value} />
                ) : (
                  <Badge label={<CopyableID id={value.toString()} />} />
                )}
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

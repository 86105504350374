import React from "react";
import Decimal from "decimal.js";
import classNames from "classnames";
import { twMerge } from "twMerge";

interface Props {
  progress: Decimal;
  total: Decimal;
  isExpired?: boolean;
  progressColor?: string;
}

export const ProgressBar: React.FC<Props> = (props) => {
  const percent = props.progress
    .div(props.total)
    .mul(100)
    .abs()
    .toNearest(1, Decimal.ROUND_UP)
    .round()
    .clamp(0, 100);

  const fillColor = "bg-deprecated-primary-50";
  const progressColor = props.progressColor ?? "bg-deprecated-primary-500";

  return (
    <div
      className={twMerge(
        "border-primary-large relative w-full border-collapse overflow-clip rounded-large border",
        fillColor,
      )}
    >
      <div
        className={classNames(
          "h-8 overflow-hidden rounded-r-large text-center",
          props.isExpired
            ? "bg-gray-400"
            : props.progress.isPositive()
              ? progressColor
              : "bg-deprecated-error-500",
        )}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};

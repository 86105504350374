import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { FormController } from "app/lib/FormController";
import React, { useEffect, useMemo } from "react";

import { Body, DateInput, Input, Select, Toggle } from "design-system";

import { Button } from "components/Button";

import { useNow } from "lib/date";
import { ProductListItem } from "../../lib/ProductListItem";
import {
  useEditProductMutation,
  useListBillableMetricsQuery,
  useProductQuery,
  useProductsQuery,
} from "./data.graphql";
import { Schema } from "../Schema";
import { useFeatureFlag } from "app/lib/launchdarkly";
import Fuse from "fuse.js";
import { fuseParams } from "./CreateProductModal";
import { QuantityConversion } from "./QuantityConversion";
import { QuantityRounding } from "./QuantityRounding";
import { RoundingMethod } from "types/generated-graphql/__types__";

const useEditProductController = FormController.createHook(
  Schema.EditProductInput,
);

interface EditProductModalProps {
  onClose: () => void;
  editProductId?: string;
}

export const EditProductModal: React.FC<EditProductModalProps> = ({
  onClose,
  editProductId,
}) => {
  const { environmentType } = useEnvironment();
  const now = useNow();

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const netsuiteEnabled = nonGAContractFeaturesEnabled?.includes("NETSUITE");
  const refundableProductsEnabled = nonGAContractFeaturesEnabled?.includes(
    "REFUNDABLE_PRODUCTS",
  );

  const excludeFreeUsageEnabled = nonGAContractFeaturesEnabled?.includes(
    "COMPOSITE_EXCLUDE_FREE_USAGE",
  );

  const pushMessage = useSnackbar();

  const [editProductMutation, editProductResult] = useEditProductMutation();

  // Get list of usage products for compositeProductIds selection
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useProductsQuery();
  const allProducts = productsData?.contract_pricing.products ?? [];
  const usageProducts = allProducts.flatMap((p) =>
    p.__typename === "UsageProductListItem" ||
    p.__typename === "SubscriptionProductListItem"
      ? { id: p.id, name: ProductListItem.getName(p, now) }
      : [],
  );
  const tagsFromDb = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ];

  // Get billable metrics for usage product creation
  const {
    data: billableMetricsData,
    loading: billableMetricsLoading,
    error: billableMetricsError,
  } = useListBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  // Get product to edit
  const {
    data: editProductData,
    loading: editProductLoading,
    error: editProductError,
    refetch: refetchEditProduct,
  } = useProductQuery({
    variables: {
      productId: editProductId ?? "",
    },
    skip: !editProductId,
  });
  const editProduct = editProductData?.contract_pricing.product;

  // once a product is created with a max bm, we don't allow changing it
  const isMaxBillableMetric =
    editProduct?.__typename === "UsageProductListItem" &&
    editProduct.current.billable_metric.aggregate === "max";

  // We currently only support count, sum and max billable metrics
  // Note: we disallow changing from a count/sum to a max billable metric
  const countSumAndMaxBillableMetrics = [
    ...(billableMetricsData?.billable_metrics ?? [])
      .filter((bm) =>
        isMaxBillableMetric
          ? // we disallow changing a max metric once set, so just grab the one original bm
            bm.id === editProduct.current.billable_metric.id
          : ["count", "sum"].includes(bm.aggregate),
      )
      .filter((bm) => !bm.sql)
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  ];

  const ctrl = useEditProductController();
  const productType = ctrl.get("type");

  const [search, setSearch] = React.useState("");
  const tagFuse = useMemo(() => {
    return new Fuse(tagsFromDb, fuseParams);
  }, [tagsFromDb]);
  const existingTagValues = new Set(
    (search ? tagFuse.search(search).map((r) => r.item) : tagsFromDb).concat(
      ctrl.state.fields["tags"].value ?? [],
    ),
  ); // we need the existing tag values in the options for them to render properly
  const tagOptions = Array.from(existingTagValues);
  if (search && !existingTagValues.has(search)) {
    tagOptions.unshift(search);
  }

  useEffect(() => {
    if (editProduct) {
      const baseFields = {
        name: editProduct.current.name,
        tags: editProduct.current.tags,
        netSuiteInternalItemId:
          editProduct.current.netsuite_internal_item_id ?? undefined,
        isRefundable: editProduct.current.is_refundable,
      };

      switch (editProduct.__typename) {
        case "CompositeProductListItem":
          ctrl.update({
            ...baseFields,
            type: "composite",
            netSuiteOverageItemId:
              editProduct.current.netsuite_overage_item_id ?? undefined,
            compositeProductIds: editProduct.current.composite_products?.map(
              (p) => p.id,
            ),
            compositeTags: editProduct.current.composite_tags,
            excludeFreeUsage: editProduct.current.exclude_free_usage,
          });
          break;
        case "UsageProductListItem":
          ctrl.update({
            ...baseFields,
            type: "usage",
            netSuiteOverageItemId:
              editProduct.current.netsuite_overage_item_id ?? undefined,
            billableMetricId: editProduct.current.billable_metric.id,
            quantityConversion: editProduct.current.quantity_conversion
              ? {
                  ...editProduct.current.quantity_conversion,
                  conversionFactor: Number(
                    editProduct.current.quantity_conversion?.conversion_factor,
                  ).valueOf(),
                  name:
                    editProduct.current.quantity_conversion.name ?? undefined,
                }
              : undefined,
            quantityRounding: editProduct.current.quantity_rounding
              ? {
                  decimalPlaces:
                    editProduct.current.quantity_rounding.decimal_places,
                  roundingMethod:
                    editProduct.current.quantity_rounding.rounding_method,
                }
              : undefined,
          });
          break;
        case "SubscriptionProductListItem":
          ctrl.update({
            ...baseFields,
            type: "subscription",
            netSuiteOverageItemId:
              editProduct.current.netsuite_overage_item_id ?? undefined,
          });
          break;
        case "FixedProductListItem":
          ctrl.update({
            ...baseFields,
            type: "fixed",
          });
          break;
        case "ProServiceProductListItem":
          ctrl.update({
            ...baseFields,
            tags: undefined, // Tags are not supported for proService products
            type: "proService",
          });
          break;
      }
    }
  }, [editProductId, editProductData]);

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    function hasSameItems(a: string[], b: string[]) {
      const [setA, setB] = [new Set(a), new Set(b)];
      if (setA.size !== setB.size) {
        return false;
      }
      for (const item of setA) {
        if (!setB.has(item)) {
          return false;
        }
      }
      return true;
    }
    function getUpdatedInput<T>(original: T, current: T): T | undefined {
      if (Array.isArray(original) && Array.isArray(current)) {
        return hasSameItems(original, current) ? undefined : current;
      }
      return original === current ? undefined : current;
    }

    if (editProduct) {
      const original = editProduct.current;
      const originalCompositeProductIds =
        original.__typename === "CompositeProductListItemState"
          ? original.composite_products?.map((p) => p.id)
          : [];

      let quantity_conversion = undefined;
      let quantity_rounding = undefined;
      if (original.__typename === "UsageProductListItemState") {
        if (!valid.quantityConversion && original.quantity_conversion) {
          quantity_conversion = null;
        } else if (
          valid.quantityConversion &&
          (!original.quantity_conversion ||
            valid.quantityConversion.conversionFactor?.toString() !==
              original.quantity_conversion.conversion_factor ||
            valid.quantityConversion.operation !==
              original.quantity_conversion.operation ||
            valid.quantityConversion.name !== original.quantity_conversion.name)
        ) {
          quantity_conversion = {
            conversion_factor:
              valid.quantityConversion.conversionFactor?.toString(),
            operation: valid.quantityConversion.operation,
            name: valid.quantityConversion.name,
          };
        }
        if (!valid.quantityRounding && original.quantity_rounding) {
          quantity_rounding = null;
        } else if (
          valid.quantityRounding &&
          (!original.quantity_rounding ||
            valid.quantityRounding.decimalPlaces !==
              original.quantity_rounding.decimal_places ||
            valid.quantityRounding.roundingMethod !==
              original.quantity_rounding.rounding_method)
        ) {
          quantity_rounding = {
            decimal_places: valid.quantityRounding.decimalPlaces,
            rounding_method: valid.quantityRounding.roundingMethod,
          };
        }
      }

      try {
        const editProductResult = await editProductMutation({
          variables: {
            id: editProduct.id,
            effective_at: valid.startingAt,
            name: getUpdatedInput(original.name, valid.name?.trim()),
            tags: getUpdatedInput(original.tags, valid.tags) || [],
            is_refundable: getUpdatedInput(
              original.is_refundable,
              valid.isRefundable,
            ),
            netsuite_internal_item_id: getUpdatedInput(
              original.netsuite_internal_item_id,
              valid.netSuiteInternalItemId?.trim(),
            ),
            netsuite_overage_item_id:
              original.__typename === "CompositeProductListItemState" ||
              original.__typename === "UsageProductListItemState"
                ? getUpdatedInput(
                    original.netsuite_overage_item_id,
                    valid.netSuiteOverageItemId?.trim(),
                  )
                : undefined,
            billable_metric_id:
              original.__typename === "UsageProductListItemState"
                ? getUpdatedInput(
                    original.billable_metric.id,
                    valid.billableMetricId,
                  )
                : undefined,
            quantity_conversion,
            quantity_rounding,
            composite_product_ids:
              original.__typename === "CompositeProductListItemState"
                ? getUpdatedInput(
                    originalCompositeProductIds,
                    valid.compositeProductIds,
                  )
                : undefined,
            composite_tags:
              original.__typename === "CompositeProductListItemState"
                ? getUpdatedInput(original.composite_tags, valid.compositeTags)
                : undefined,
            exclude_free_usage:
              original.__typename === "CompositeProductListItemState"
                ? getUpdatedInput(
                    original.exclude_free_usage,
                    valid.excludeFreeUsage,
                  )
                : undefined,
          },
          update(cache) {
            cache.evict({ fieldName: "contract_pricing" });
          },
        });

        if (editProductResult.data?.update_product_list_item.id) {
          pushMessage({
            content: "Successfully updated product",
            type: "success",
          });
        }
        if (editProductId) {
          await refetchEditProduct({ productId: editProductId });
        }

        onClose();
      } catch (e) {
        pushMessage({
          content: `Failed to update product: ${e}`,
          type: "error",
        });
      }
    }
  });

  if (editProductError) {
    return (
      <ErrorEmptyState
        title="Could not fetch product"
        error={editProductError}
      />
    );
  }
  if (billableMetricsError) {
    return (
      <ErrorEmptyState
        title="Could not fetch billable metrics"
        error={billableMetricsError}
      />
    );
  }
  if (productsError) {
    return (
      <ErrorEmptyState title="Could not fetch products" error={productsError} />
    );
  }

  return (
    <DeprecatedPopup
      isOpen
      onRequestClose={onClose}
      title="Edit product"
      className="max-h-screen overflow-auto"
      size="lg"
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={!ctrl.appearsValid() || editProductResult.loading}
            loading={editProductResult.loading}
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          Determine when this edit will take effect and make the necessary
          updates to this product.
        </Body>
        <div className="grid gap-12">
          <DateInput
            {...ctrl.props.DateInput("startingAt", {
              name: "Starting at",
              tooltip:
                "Timestamp representing when the update should go into effect. It will be set to UTC midnight of the selected date.",
            })}
          />
          <Input
            {...ctrl.props.Input("name", {
              name: "Name",
              placeholder: "My product",
              disabled: editProductLoading,
            })}
          />
          <Select
            {...ctrl.props.MultiSelect("tags", {
              name: "Tags",
              placeholder: "Add tags",
              loading: productsLoading,
              onSearch: (option) => {
                setSearch(option);
              },
              options: tagOptions.map((tag) => ({ label: tag, value: tag })),
              disabled: productType === "proService",
              tooltip:
                productType === "proService"
                  ? "Tags are not supported for professional service products"
                  : undefined,
            })}
          />

          {netsuiteEnabled && (
            <Input
              {...ctrl.props.Input("netSuiteInternalItemId", {
                name: "NetSuite internal item ID",
                placeholder: "Enter ID",
                disabled: editProductLoading,
              })}
            />
          )}

          <Select
            {...ctrl.props.Select("type", {
              name: "Product type",
              placeholder: "Select a product type",
              options: [
                { label: "Usage", value: "usage" },
                { label: "Subscription", value: "subscription" },
                { label: "Fixed", value: "fixed" },
                { label: "Composite", value: "composite" },
                { label: "Professional service", value: "proService" },
              ],
              tooltip:
                "You cannot change the product type once it has been created",
              disabled: !!editProductId,
            })}
          />

          {productType === "composite" && (
            <>
              <Select
                {...ctrl.props.MultiSelect("compositeProductIds", {
                  name: "Associated products",
                  placeholder: "Search by name or ID",
                  loading: productsLoading,
                  options: usageProducts.map((p) => ({
                    label: p.name,
                    value: p.id,
                  })),
                  tooltip:
                    "Composite products can be composed of usage products only",
                  disabled: editProductLoading,
                })}
              />
              <Select
                {...ctrl.props.MultiSelect("compositeTags", {
                  name: "Associated tags",
                  placeholder: "Search by name",
                  loading: productsLoading,
                  options: tagsFromDb.map((p) => ({
                    label: p,
                    value: p,
                  })),
                  disabled: editProductLoading,
                })}
              />
            </>
          )}

          {productType === "usage" && (
            <Select
              {...ctrl.props.Select("billableMetricId", {
                name: "Billable metric",
                placeholder: "Search by name or ID",
                loading: billableMetricsLoading,
                options: countSumAndMaxBillableMetrics.map((bm) => ({
                  label: bm.name,
                  value: bm.id,
                })),
                tooltip:
                  "Contract usage products must use a count, sum or max billable metric. Once a max billable metric is set on a product, it cannot be changed",
                disabled: editProductLoading || isMaxBillableMetric,
              })}
            />
          )}
          {netsuiteEnabled &&
            (productType === "usage" ||
              productType === "composite" ||
              productType === "subscription") && (
              <Input
                {...ctrl.props.Input("netSuiteOverageItemId", {
                  name: "NetSuite overage item ID",
                  placeholder: "Enter ID",
                  disabled: editProductLoading,
                })}
              />
            )}

          {productType === "usage" && (
            <>
              <Toggle
                {...ctrl.props.Toggle("quantityConversion", {
                  label: "Convert quantity",
                })}
                checked={!!ctrl.get("quantityConversion")}
                tooltip="You can specify a conversion factor which will apply to the quantity before pricing."
                onChange={(e) => {
                  if (e) {
                    ctrl.update({
                      quantityConversion: {},
                    });
                  } else {
                    ctrl.update({
                      quantityConversion: undefined,
                    });
                  }
                }}
              />
              {ctrl.get("quantityConversion") && (
                <QuantityConversion parent={ctrl} />
              )}
              <Toggle
                {...ctrl.props.Toggle("quantityRounding", {
                  label: "Round quantity",
                })}
                tooltip="You can specify a rounding configuration which will apply to the quantity before pricing."
                checked={!!ctrl.get("quantityRounding")}
                onChange={(e) => {
                  if (e) {
                    ctrl.update({
                      quantityRounding: {
                        roundingMethod: RoundingMethod.HalfUp,
                      },
                    });
                  } else {
                    ctrl.update({
                      quantityRounding: undefined,
                    });
                  }
                }}
              />
              {ctrl.get("quantityRounding") && (
                <QuantityRounding parent={ctrl} />
              )}
            </>
          )}

          {excludeFreeUsageEnabled && productType === "composite" && (
            <Toggle
              {...ctrl.props.Toggle("excludeFreeUsage", {
                label: "Exclude free usage",
              })}
            />
          )}

          {refundableProductsEnabled && (
            <Toggle
              {...ctrl.props.Toggle("isRefundable", {
                label: "Refundable",
                disabled: editProductLoading,
              })}
            />
          )}
        </div>
      </form>
    </DeprecatedPopup>
  );
};

/* 4970033c9623d734985b60c7c9d93ae134e7e201
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

export type GetContractsQueryVariables = Types.Exact<{
  customerId: Types.Scalars['uuid'];
}>;


export type GetContractsQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, contracts: Array<{ __typename: 'Contract', id: string, name: string | null }> } | null };

export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const GetProductsDocument = gql`
    query GetProducts {
  contract_pricing {
    products {
      __typename
      ...ProductListItemId
      ...ProductListItemName
      ...ProductListItemTags
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTagsFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetContractsDocument = gql`
    query GetContracts($customerId: uuid!) {
  customer: Customer_by_pk(id: $customerId) {
    id
    contracts {
      __typename
      id
      name
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
      }
export function useGetContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
import React from "react";
import { Badge } from "components/Badge";
import { useNow, printDate, toDayjs } from "lib/date";
import { ContractOrPlan } from "../../lib/ContractOrPlan";
import { Customer } from "../../lib/Customer";
import { CustomerStatusFragment } from "app/pages/Contracts/lib/ContractOrPlan/ContractOrPlan";
import { BadgeGroup } from "components/BadgeGroup";
import { useUIMode } from "app/lib/useUIMode";

type PorcByStatus = {
  "active-soon": CustomerStatusFragment[];
  active: CustomerStatusFragment[];
  "recently-ended": CustomerStatusFragment[];
};

interface Props {
  customer: Customer.StatusFragment;
}

const type = (porc: CustomerStatusFragment) =>
  porc.__typename === "CustomerContractStatus" ? "Contract" : "Plan";

const BadgeList: React.FC<React.PropsWithChildren> = ({ children }) => (
  <span className="space-x-8">{children}</span>
);

function renderDate(date: string | null): string {
  return date ? printDate(toDayjs(date)) : "";
}

/**
 * Renders a badge for each active contract and plan. If there are no active
 * contracts or plans, but one will start in the next 30 days then describe
 * that, otherwise describe the plans or contracts which ended in the last
 * 30 days. Finally, if none of these conditions match it will render a badge
 * that says "No active plan or contract".
 */
export const CustomerStatus: React.FC<Props> = ({ customer }) => {
  const now = useNow();
  const { mode } = useUIMode();

  const porcByStatus = React.useMemo((): PorcByStatus => {
    return Customer.getStatuses(customer, now);
  }, [customer, now]);

  if (porcByStatus.active.length) {
    const activePlans = porcByStatus.active.filter(
      (p) => p.__typename === "CustomerPlanStatus",
    );
    const activeContracts = porcByStatus.active.filter(
      (p) => p.__typename === "CustomerContractStatus",
    );
    return (
      <BadgeList>
        {activeContracts.length > 0 ? (
          !activeContracts[0].ending_before ||
          porcByStatus.active.length > 1 ? (
            <Badge
              label="Contract active"
              theme={
                ContractOrPlan.getCustomerStatus(activeContracts[0], now) ===
                "ending-soon"
                  ? "warning"
                  : "success"
              }
            />
          ) : (
            <BadgeGroup
              badgeLabel="Contract"
              mainLabel={renderDate(activeContracts[0].ending_before)}
              theme={
                ContractOrPlan.getCustomerStatus(activeContracts[0], now) ===
                "ending-soon"
                  ? "warning"
                  : "success"
              }
            />
          )
        ) : undefined}

        {activePlans.length > 0 ? (
          !activePlans[0].ending_before || porcByStatus.active.length > 1 ? (
            <Badge
              label="Plan active"
              theme={
                ContractOrPlan.getCustomerStatus(activePlans[0], now) ===
                "ending-soon"
                  ? "warning"
                  : "success"
              }
            />
          ) : (
            <BadgeGroup
              badgeLabel="Plan"
              mainLabel={renderDate(activePlans[0].ending_before)}
              theme={
                ContractOrPlan.getCustomerStatus(activePlans[0], now) ===
                "ending-soon"
                  ? "warning"
                  : "success"
              }
            />
          )
        ) : undefined}
      </BadgeList>
    );
  }

  if (porcByStatus["active-soon"].length) {
    return (
      <BadgeList>
        {porcByStatus["active-soon"].map((porc, idx) => (
          <Badge
            label={type(porc) + " starting: " + renderDate(porc.starting_at)}
            theme="gray"
          />
        ))}
      </BadgeList>
    );
  }

  if (porcByStatus["recently-ended"].length) {
    return (
      <BadgeList>
        {porcByStatus["recently-ended"].map((porc, idx) => (
          <Badge
            label={type(porc) + " ended: " + renderDate(porc.ending_before)}
            theme="gray"
          />
        ))}
      </BadgeList>
    );
  }

  return (
    <BadgeList>
      <Badge
        label={
          mode === "contracts-only"
            ? "No contract"
            : mode === "plans-only"
              ? "No plan"
              : "No contract/plan"
        }
        theme="gray"
      />
    </BadgeList>
  );
};

import React from "react";
import { Table } from "components/Table";
import {
  generateAlertPolicyGroupKeyFilterString,
  generateAlertPolicyInvoiceTypesFilterString,
  generateAlertPolicyString,
} from "app/lib/alerts";
import { Badge } from "components/Badge";
import {
  AlertTypeEnum_Enum,
  CustomerAlertCompositeStatusEnum,
  EnvironmentTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import { type Column } from "components/Table";

type Alert = {
  __typename?: "Alert";
  id: string;
  name: string;
  threshold: string;
  alert_type: AlertTypeEnum_Enum;
  filters: object | null;
  customer_status?: CustomerAlertCompositeStatusEnum;
  Customer: {
    __typename?: "Customer";
    id: string;
    name: string;
  } | null;
  Plan: {
    __typename?: "Plan";
    id: string;
    name: string;
  } | null;
  CreditType: {
    __typename?: "CreditType";
    id: string;
    name: string;
    client_id: string | null;
    environment_type: EnvironmentTypeEnum_Enum | null;
  };
  BillableMetric: {
    __typename?: "BillableMetric";
    id: string;
    name: string;
  } | null;
};

interface Props {
  loading: boolean;
  alertsData: Array<Alert>;
  emptyState: React.ReactElement;
  topBarActions?: React.ReactElement[];
  searchOptions?: { showSearch?: boolean; onSearch?: (v: string) => void };
  additionalColumns?: Column<Alert>[];
}

export const AlertsTable: React.FC<Props> = ({
  loading,
  alertsData,
  emptyState,
  topBarActions,
  searchOptions,
  additionalColumns,
}) => {
  return (
    <Table
      loading={loading}
      emptyState={emptyState}
      rowRoutePath={(row) => `/connections/alerts/${row.id}`}
      columns={[
        {
          id: "name",
          header: "Alert",
          cell: (props) => props.getValue(),
          accessorKey: "name",
        },
        {
          id: "policy",
          header: "Policy",
          cell: (props) => props.getValue(),
          accessorFn: (row) => {
            const alertPolicyString = generateAlertPolicyString(
              row.threshold,
              row.CreditType,
              row.alert_type,
              row.BillableMetric?.name,
            );
            const groupKeyFilterString =
              generateAlertPolicyGroupKeyFilterString(row.filters);
            const invoiceTypesFilterString =
              generateAlertPolicyInvoiceTypesFilterString(row.filters);

            return (
              <div className="flex flex-col items-start gap-4">
                <Badge label={alertPolicyString} theme="indigo" />
                {groupKeyFilterString && (
                  <Badge label={groupKeyFilterString} theme="azure-blue" />
                )}
                {invoiceTypesFilterString && (
                  <Badge label={invoiceTypesFilterString} theme="azure-blue" />
                )}
              </div>
            );
          },
        },
        {
          id: "customers",
          header: "Customers",
          cell: (props) => {
            const { label } = props.getValue();
            return <Badge label={label} />;
          },
          accessorFn: (row) => {
            if (row.Customer) {
              return { label: row.Customer.name };
            }
            if (row.Plan) {
              return {
                label: `Plan: ${row.Plan.name}`,
              };
            } else {
              return { label: "All" };
            }
          },
        },
        ...(additionalColumns ?? []),
      ]}
      searchOptions={searchOptions}
      data={alertsData ?? []}
      paginationOptions={{ type: "clientSide" }}
      topBarActions={topBarActions}
    />
  );
};

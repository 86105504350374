/* 3fbdb1db4ef812cff284f721c31986128cfe94a4
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeNameMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type ChangeNameMutation = { __typename?: 'Mutation', update_name: { __typename?: 'User', id: string, name: string } };


export const ChangeNameDocument = gql`
    mutation ChangeName($name: String!) {
  update_name(name: $name) {
    id
    name
  }
}
    `;
export type ChangeNameMutationFn = Apollo.MutationFunction<ChangeNameMutation, ChangeNameMutationVariables>;

/**
 * __useChangeNameMutation__
 *
 * To run a mutation, you first call `useChangeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNameMutation, { data, loading, error }] = useChangeNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useChangeNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeNameMutation, ChangeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeNameMutation, ChangeNameMutationVariables>(ChangeNameDocument, options);
      }
export type ChangeNameMutationHookResult = ReturnType<typeof useChangeNameMutation>;
export type ChangeNameMutationResult = Apollo.MutationResult<ChangeNameMutation>;
export type ChangeNameMutationOptions = Apollo.BaseMutationOptions<ChangeNameMutation, ChangeNameMutationVariables>;
import React from "react";
import {
  DeprecatedPlanPreview,
  PlanPreviewInfo,
} from "components/deprecated/PlanPreview";
import { PlanDetails } from "app/lib/plans/types";
import styles from "./index.module.less";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { FetchCustomPricingQuery } from "../../../data/queries.graphql";
import { unserializeCustomPricing } from "app/lib/customPricing";

type PlanPreviewModalProps = {
  plan: PlanPreviewInfo;
  previousPlan: PlanDetails;
  customPricing: FetchCustomPricingQuery["CustomPricing"][0] | undefined;
  onClose: () => void;
};

export const PlanPreviewModal: React.FC<PlanPreviewModalProps> = ({
  plan,
  previousPlan,
  customPricing,
  onClose,
}) => {
  return (
    <DeprecatedPopup
      title={customPricing?.CustomerPlan.Customer.name || ""}
      isOpen={!!customPricing}
      actions={<></>}
      size="auto"
      onRequestClose={onClose}
      className={styles.planPreviewModal}
    >
      <div className={styles.content}>
        <DeprecatedPlanPreview
          customPricing={unserializeCustomPricing(customPricing)}
          plan={plan}
          previousPlan={previousPlan}
          collapsible={false}
        />
      </div>
    </DeprecatedPopup>
  );
};

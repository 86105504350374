import React from "react";
import { DocumentNode } from "graphql";

import { reportToSentry } from "app/lib/errors/sentry";
import { GatedButton } from "components/GatedButton";
import { EmptyState } from "components/EmptyState";

interface Props {
  /**
   * The top-level error message, suggest something like:
   * "We ran into an issue loading this page"
   */
  title: string;
  error: unknown;

  buttonText?: string;
  buttonAuthDoc?: DocumentNode;
  buttonRoutePath?: string;
}

export const ErrorEmptyState: React.FC<Props> = (props) => {
  React.useEffect(() => {
    reportToSentry(props.error);
  }, [props.error]);

  return (
    <EmptyState
      icon="alertCircle"
      mainText={props.title}
      supportingText={`Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support.`}
      actions={
        props.buttonText
          ? [
              <GatedButton
                doc={props.buttonAuthDoc}
                text={props.buttonText}
                theme="primary"
                linkTo={props.buttonRoutePath}
              />,
            ]
          : []
      }
    />
  );
};

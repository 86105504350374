import React from "react";
import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { TextInput } from "components/Input";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { CurrencyInput } from "./CurrencyInput";
import { CreditType } from "app/types/credit-types";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onChange: (rate: Schema.Types.Rate) => void;
  onConversionRateChange: (
    conversion: Schema.Types.CreditTypeConversion,
  ) => void;
  allowEditCreditConversions: boolean;
  disableChangingCreditTypes: boolean;
}

export const RateSubscription: React.FC<Props> = ({
  onChange,
  rate,
  onConversionRateChange,
  fiatCreditType,
  customCreditTypes,
  creditTypeConversions,
  disabled,
  allowEditCreditConversions,
  disableChangingCreditTypes,
}) => {
  const currentPrice =
    rate.price.type === "subscription" ? rate.price.price : undefined;
  const currentQuantity =
    rate.price.type === "subscription" ? rate.price.quantity : 1;
  const isProrated =
    rate.price.type === "subscription" ? rate.price.isProrated : false;
  return (
    <div className="row flex gap-4">
      <TextInput
        disabled={disabled}
        value={currentQuantity.toString()}
        disableClear
        className="w-[80px]"
        type="text"
        onChange={({ value }) => {
          onChange({
            ...rate,
            price: {
              type: "subscription",
              price: currentPrice,
              quantity: Number(value),
              isProrated,
            },
          });
        }}
      />
      <CurrencyInput
        disabled={disabled}
        price={currentPrice}
        onPriceChange={(price) => {
          onChange({
            ...rate,
            price: {
              type: "subscription",
              price,
              quantity: currentQuantity,
              isProrated,
            },
          });
        }}
        creditTypeConversions={creditTypeConversions}
        customCreditTypes={customCreditTypes}
        onCreditTypeChange={(creditType, conversion) => {
          onChange({
            ...rate,
            creditType,
          });

          if (conversion) {
            onConversionRateChange(conversion);
          }
        }}
        rateCardFiatCreditType={fiatCreditType}
        rateCreditType={rate.creditType ?? USD_CREDIT_TYPE}
        allowEditCreditConversions={allowEditCreditConversions}
        disableChangingCreditTypes={disableChangingCreditTypes}
      />
    </div>
  );
};

import React from "react";
import classnames from "classnames";
import styles from "./index.module.less";
import { Tooltip } from "design-system";
import { Deprecated_Button } from "../../../design-system/components/Button";

interface DefaultButtonProps<T extends string> {
  onChange?: (v: T) => void;
  subLabel?: string;
  disabled?: boolean;
  useGreyBackground?: boolean;
}

interface ToggleButtonProps<T extends string> extends DefaultButtonProps<T> {
  value: T;
  label: string;
  selected?: boolean;
  tooltipContent?: string;
}

function ToggleButton<T extends string>(
  props: ToggleButtonProps<T> & { light?: boolean },
) {
  const selected = props.value !== undefined && props.selected;
  const button = (
    <Deprecated_Button
      className={classnames(styles.button, {
        [styles.selected]: selected,
        [styles.light]: props.light,
        [styles.disabled]: props.disabled,
        [styles.greyButton]:
          props.useGreyBackground || (!selected && props.light),
      })}
      theme="primary"
      type="fill"
      onClick={() => props.onChange && props.onChange(props.value)}
      disabled={!!props.disabled}
    >
      <p className={styles.mainLabel}>{props.label}</p>
      {props.subLabel && <p className={styles.subLabel}>{props.subLabel}</p>}
    </Deprecated_Button>
  );
  return props.tooltipContent ? (
    <Tooltip content={props.tooltipContent}>{button}</Tooltip>
  ) : (
    button
  );
}

interface ToggleButtonsProps<T extends string> {
  buttonProps: ToggleButtonProps<T>[];
  value: T | undefined;
  light?: boolean;
  defaultButtonProps?: DefaultButtonProps<T>;
  className?: string;
}

export function DeprecatedToggleButtons<T extends string>(
  props: ToggleButtonsProps<T>,
) {
  return (
    <div className={classnames(styles.toggleButtons, props.className)}>
      {props.buttonProps.map((buttonProps, i) => {
        const active = props.value === buttonProps.value;

        return (
          <ToggleButton
            key={i}
            selected={active}
            light={props.light}
            {...props.defaultButtonProps}
            {...buttonProps}
          />
        );
      })}
    </div>
  );
}

import React, { useCallback, useMemo } from "react";
import { SectionHeader } from "components/SectionHeader";
import { TextInput } from "components/Input";
import { Button } from "components/Button";
import { Column, Table } from "components/Table";
import { EmptyState } from "components/EmptyState";
import { RateCardContext } from "./RateCardContext";
import { Checkbox } from "components/Checkbox";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import { Timestamp } from "components/Timestamp";
import { DatePicker } from "components/DatePicker";
import { IconButton } from "components/IconButton";
import { ProductListItem } from "./contextUtils";
import { Badge } from "components/Badge";
import { getBadgeLabel, getBadgeTheme } from "./utils";
import { dayjs } from "lib/dayjs";

interface ProductType {
  billableMetric?: {
    id: string;
    aggregate?: BillingMetricAggregateEnum_Enum;
    sql: string | null;
    name: string;
  };
  pricingGroupKeys: null | string[];
  typeName: string;
}

interface ProductRow {
  id: string;
  product: {
    product: ProductListItem;
  };
  type?: ProductType;
  lastEdited: Date;
}

export const Step1: React.FC = () => {
  const {
    products,
    loading,
    productDataError,
    selectedProducts,
    setSelectedProducts,
    name,
    setName,
    description,
    setDescription,
    aliases,
    setAliases,
  } = RateCardContext.useContainer();

  const isProductHeaderChecked = selectedProducts.length === products.length;

  const isProductChecked = (productItem: ProductListItem): boolean => {
    return (
      isProductHeaderChecked ||
      selectedProducts.includes(productItem.id) ||
      false
    );
  };

  const handleProductClick = (product: ProductListItem) => {
    if (isProductChecked(product)) {
      setSelectedProducts(selectedProducts.filter((p) => p !== product.id));
    } else {
      setSelectedProducts([...selectedProducts, product.id]);
    }
  };

  const productTableColumns: Column<ProductRow>[] = useMemo(
    () => [
      {
        id: "1",
        accessorKey: "product.product.current.name",
        cell: ({ row }) => {
          const {
            product: { product },
            type,
          } = row.original;
          const supportingText = type?.pricingGroupKeys?.length
            ? `Dimensional pricing keys: ${type?.pricingGroupKeys.join(", ")}`
            : undefined;
          return (
            <Checkbox
              label={product.current.name}
              checked={isProductChecked(product)}
              supportingText={supportingText}
              onClick={() => handleProductClick(product)}
            />
          );
        },
        header: () => {
          return (
            <div className="h-11 flex items-center gap-lg">
              <Checkbox
                label=""
                checked={isProductHeaderChecked}
                onClick={(meta: { checked: boolean }) => {
                  if (meta.checked) {
                    setSelectedProducts(products.map((p) => p.id));
                  } else {
                    setSelectedProducts([]);
                  }
                }}
                size="md"
              />
              <div>Product</div>
            </div>
          );
        },
        enableSorting: false,
      },
      {
        id: "2",
        accessorKey: "type.typeName",
        cell: ({ row }) => {
          const typeName = row.original.type?.typeName;
          const badgeLabel = getBadgeLabel(typeName);
          const badgeTheme = getBadgeTheme(badgeLabel);

          return <Badge label={badgeLabel} theme={badgeTheme} />;
        },
        header: "Type",
        enableSorting: false,
      },
      {
        id: "3",
        accessorKey: "lastEdited",
        cell: (props: { getValue: () => Date }) => {
          // toDo: [PENG-893] Add date rendering logic
          return <Timestamp dateTime={props.getValue()} />;
        },
        header: () => {
          return <div className="flex">Last edited (UTC)</div>;
        },
        enableSorting: false,
      },
    ],
    [selectedProducts, isProductHeaderChecked, products],
  );

  const getProductLastEditedDate = (product: ProductListItem) => {
    if (!product.updates.length) {
      return new Date(product.current.created_at);
    }

    const lastEditedDates = product.updates.map(
      (update) => new Date(update.created_at),
    );

    return lastEditedDates.reduce((a, b) => (a > b ? a : b));
  };

  const allProducts = useMemo(() => {
    if (products) {
      return products.map((p) => {
        return {
          id: p.id,
          product: {
            product: p,
          },
          type: {
            billableMetric:
              "billable_metric" in p.current
                ? p.current.billable_metric
                : undefined,
            pricingGroupKeys:
              "pricing_group_key" in p.current
                ? p.current.pricing_group_key
                : null,
            typeName: p.__typename,
          },
          lastEdited: getProductLastEditedDate(p),
        };
      });
    } else {
      return [];
    }
  }, [products]);

  const handleRateCardNameUpdate = useCallback(
    (meta: { value: string }) => {
      setName(meta.value.trim());
    },
    [setName],
  );

  const handleRateCardDescriptionUpdate = useCallback(
    (meta: { value: string }) => {
      setDescription(meta.value.trim());
    },
    [setDescription],
  );

  const handleAddAliasClick = useCallback(() => {
    setAliases([...aliases, { name: "" }]);
  }, [aliases, setAliases]);

  return (
    <div className="flex flex-col items-start gap-[40px]">
      <div className="display flex flex-col gap-[24px] self-stretch">
        <TextInput
          fullWidth
          label="Name"
          helpText="Enter an internal name for your rate card"
          placeholder="Rate card name"
          value={name}
          onChange={handleRateCardNameUpdate}
        />
        <TextInput
          fullWidth
          label="Description (optional)"
          helpText="Enter an internal description for your rate"
          placeholder="Enter description"
          value={description}
          onChange={handleRateCardDescriptionUpdate}
        />
      </div>
      <div className="flex flex-col gap-lg">
        <SectionHeader
          title="Add aliases (optional)"
          subtitle="Easily reference this rate card when provisioning a contract by adding a rate card alias."
          bottomBorder={false}
        />
        {aliases.map((alias, index) => {
          return (
            <div className="flex flex-row gap-lg self-stretch" key={index}>
              <TextInput
                placeholder="Enter alias"
                value={alias.name}
                label="Alias"
                fullWidth
                onChange={(meta: { value: string }) => {
                  setAliases(
                    aliases.map((a, i) =>
                      i === index ? { ...a, name: meta.value } : a,
                    ),
                  );
                }}
              />
              <div className="flex gap-lg self-end">
                <DatePicker
                  text="Starting at"
                  value={alias.startingAt as unknown as Date}
                  onDateApply={(date) => {
                    const startingAt = date
                      ? dayjs.utc(date).startOf("day").toDate()
                      : undefined;
                    setAliases(
                      aliases.map((a, i) =>
                        i === index
                          ? { ...a, startingAt: startingAt?.toISOString() }
                          : a,
                      ),
                    );
                  }}
                />
                <DatePicker
                  text="Ending before"
                  value={alias.endingBefore as unknown as Date}
                  onDateApply={(date) => {
                    const endingBefore = date
                      ? dayjs.utc(date).startOf("day").toDate()
                      : undefined;
                    setAliases(
                      aliases.map((a, i) =>
                        i === index
                          ? { ...a, endingBefore: endingBefore?.toISOString() }
                          : a,
                      ),
                    );
                  }}
                />
                <IconButton
                  icon="trash01"
                  theme="secondary"
                  onClick={() => {
                    setAliases(aliases.filter((a, i) => i !== index));
                  }}
                />
              </div>
            </div>
          );
        })}
        <div className="flex flex-col">
          <Button
            text="Add alias"
            leadingIcon="plus"
            onClick={handleAddAliasClick}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-12">
        <SectionHeader
          title="Products"
          subtitle="Select products to add to this rate card. Next, you'll be asked to rate these products."
          bottomBorder={false}
        />
        <div className="w-full">
          <Table
            title="Products"
            columns={productTableColumns}
            data={allProducts}
            searchOptions={{
              // TODO(ekaragiannis) - figure out how to get client side searching to work
              showSearch: true,
            }}
            loading={loading}
            emptyState={
              productDataError && (
                <EmptyState
                  icon="alertCircle"
                  mainText="There was a problem loading your products"
                  supportingText="Try refreshing the form to load your products"
                />
              )
            }
            paginationOptions={{
              type: "clientSide",
              pageSize: 5,
              minimumPageSize: 5,
            }}
          />
        </div>
      </div>
    </div>
  );
};

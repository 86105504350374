import React from "react";
import { DocumentNode } from "graphql";

import { ProductQuery } from "./data/productQueries.graphql";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { AppShell } from "components/AppShell";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { useUIMode } from "app/lib/useUIMode";

interface ProductContainerProps extends React.PropsWithChildren {
  product?: "loading" | ProductQuery["Product"];
  error?: unknown;
  action?: React.ReactNode;
  authDoc?: DocumentNode;
}

export const ProductContainer: React.FC<ProductContainerProps> = (props) => {
  const { mode } = useUIMode();

  if (props.error) {
    return (
      <AppShell title="Error" hideHeader>
        <ErrorEmptyState
          error={props.error}
          title="We ran into an issue loading this product"
          buttonText="View play overview"
          buttonRoutePath="/products"
        />
      </AppShell>
    );
  }

  return (
    <AppShell
      title={
        (props.product !== "loading" && props.product?.name) || {
          state: "loading",
        }
      }
      headerProps={{
        actions: [props.action],
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Offering",
            routePath: `/offering/${mode === "plans-only" ? "plans" : "rate-cards"}`,
          },
          mode !== "plans-only" && {
            label: "Plans",
            routePath: "/offering/plans",
          },
          {
            label: "Products",
            routePath:
              mode === "plans-only"
                ? "/offering/products"
                : "/offering/plans/products",
          },
        ),
      }}
    >
      {props.children}
    </AppShell>
  );
};

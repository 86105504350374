import React from "react";
import Decimal from "decimal.js";
import { useRequiredParam } from "app/lib/routes/params";
import { useGetAutoTopupsSettingsQuery } from "./queries.graphql";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/Button";
import { LoadingBlob } from "components/LoadingBlob";
import { Card } from "components/Card";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { displayCreditsInCurrencyWithoutRounding } from "app/lib/credits";
import { Table } from "components/Table";

export const AutoTopups: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { data, loading } = useGetAutoTopupsSettingsQuery({
    variables: {
      customer_id: customerId,
    },
  });

  if (loading) {
    return <LoadingBlob />;
  }

  const emptyState = (
    <EmptyState
      icon="bell03"
      mainText="This customer doesn't have recharge settings yet."
      supportingText="Setup recharge settings using the Metronome API.  This will auto recharge the customer's account when their balance falls below a certain threshold."
      actions={[
        <Button
          linkTo="https://docs.metronome.com/api/prepaid-credits/"
          isExternalLink
          text="Learn more"
          theme="tertiary"
          leadingIcon="linkExternal01"
        />,
      ]}
    />
  );

  return data?.Customer_by_pk?.recharge_settings &&
    (data?.Customer_by_pk?.recharge_settings?.length ?? 0) > 0
    ? data.Customer_by_pk.recharge_settings.map((rechargeSetting) => (
        <div className="flex max-w-[1280px] flex-col gap-y-md">
          <h2 className="text-2xl font-semibold text-gray-800">
            Auto Recharge Settings: {rechargeSetting.name}
          </h2>
          <div className="flex w-full flex-row gap-x-lg">
            <Card
              wrapContents={false}
              title="Auto Recharge Settings"
              loading={loading}
              className="h-full w-[50%]"
            >
              <div className="flex flex-col gap-y-3xl">
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Enabled
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {rechargeSetting.enabled ? "Yes" : "No"}
                  </div>
                </div>
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Grant duration
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {rechargeSetting.grant_duration} months
                  </div>
                </div>
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Grant priority
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {rechargeSetting.priority}
                  </div>
                </div>
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Grant reason
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {rechargeSetting.reason}
                  </div>
                </div>
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Recharge amount
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {displayCreditsInCurrencyWithoutRounding(
                      new Decimal(rechargeSetting.auto_recharge_to_amount),
                      USD_CREDIT_TYPE,
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-y-lg">
                  <h3 className="text-md font-semibold text-gray-800">
                    Recharge threshold
                  </h3>
                  <div className="flex flex-row items-center text-xs">
                    {displayCreditsInCurrencyWithoutRounding(
                      new Decimal(rechargeSetting.auto_recharge_threshold),
                      USD_CREDIT_TYPE,
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <div className="flex w-[50%] flex-col gap-y-md">
              <Card
                wrapContents={false}
                title="Recharge status"
                loading={loading}
                className="w-full"
              >
                <div className="flex flex-col gap-y-3xl">
                  <div className="flex flex-col gap-y-lg">
                    <h3 className="text-md font-semibold text-gray-800">
                      Last stripe status response
                    </h3>
                    <div className="text-xs, flex flex-row items-center p-md text-xs text-gray-600">
                      {JSON.stringify(
                        rechargeSetting.status_metadata ?? {},
                        null,
                        2,
                      )}
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                wrapContents={false}
                title="Billing provider"
                loading={loading}
                className="w-full"
              >
                <div className="flex flex-col gap-y-3xl">
                  <div className="flex flex-col gap-y-lg">
                    <h3 className="text-md font-semibold text-gray-800">
                      Billing provider
                    </h3>
                    <div className="flex flex-row items-center text-xs">
                      {rechargeSetting.billing_provider}
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-lg">
                    <h3 className="text-md font-semibold text-gray-800">
                      Stripe config
                    </h3>
                    <div className="text-xs, flex flex-row items-center p-md text-xs text-gray-600">
                      {JSON.stringify(
                        rechargeSetting.billing_provider_config ?? {},
                        null,
                        2,
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <Table
            title="Recharge history (last 500 max)"
            loading={loading}
            columns={[
              {
                id: "created_at",
                header: "Created at",
                cell: (props) => props.getValue(),
                accessorKey: "created_at",
              },
              {
                id: "status",
                header: "Status",
                cell: (props) => props.getValue(),
                accessorKey: "status",
              },
              {
                id: "evaluation_id",
                header: "Evaluation id",
                cell: (props) => props.getValue(),
                accessorKey: "evaluation_id",
              },
            ]}
            data={rechargeSetting.recharge_alert_history}
          />
        </div>
      ))
    : emptyState;
};

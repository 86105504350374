/* 769d400a69981cdc07edd8b75078e6654a5b2417
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAzureMarketplaceToCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  azure_subscription_id: Types.Scalars['String'];
}>;


export type AddAzureMarketplaceToCustomerMutation = { __typename?: 'Mutation', add_azure_marketplace_to_customer: { __typename?: 'AddMarketplaceToCustomerMutationResponse', billingProviderCustomer: { __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }, customerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, key: string, value: string }> } };

export type InsertCustomerBillingProviderConfigurationMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  configuration: Types.Scalars['FlatKeyValueMap'];
}>;


export type InsertCustomerBillingProviderConfigurationMutation = { __typename?: 'Mutation', insert_customer_billing_provider_configuration: { __typename?: 'InsertCustomerBillingProviderConfigurationResult', id: string, configuration: { [key: string]: string | number | boolean } } };


export const AddAzureMarketplaceToCustomerDocument = gql`
    mutation AddAzureMarketplaceToCustomer($customer_id: uuid!, $azure_subscription_id: String!) {
  add_azure_marketplace_to_customer(
    customer_id: $customer_id
    azure_subscription_id: $azure_subscription_id
  ) {
    billingProviderCustomer {
      billing_provider_customer_id
    }
    customerConfigs {
      id
      key
      value
    }
  }
}
    `;
export type AddAzureMarketplaceToCustomerMutationFn = Apollo.MutationFunction<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>;

/**
 * __useAddAzureMarketplaceToCustomerMutation__
 *
 * To run a mutation, you first call `useAddAzureMarketplaceToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAzureMarketplaceToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAzureMarketplaceToCustomerMutation, { data, loading, error }] = useAddAzureMarketplaceToCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      azure_subscription_id: // value for 'azure_subscription_id'
 *   },
 * });
 */
export function useAddAzureMarketplaceToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>(AddAzureMarketplaceToCustomerDocument, options);
      }
export type AddAzureMarketplaceToCustomerMutationHookResult = ReturnType<typeof useAddAzureMarketplaceToCustomerMutation>;
export type AddAzureMarketplaceToCustomerMutationResult = Apollo.MutationResult<AddAzureMarketplaceToCustomerMutation>;
export type AddAzureMarketplaceToCustomerMutationOptions = Apollo.BaseMutationOptions<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>;
export const InsertCustomerBillingProviderConfigurationDocument = gql`
    mutation InsertCustomerBillingProviderConfiguration($customer_id: uuid!, $configuration: FlatKeyValueMap!) {
  insert_customer_billing_provider_configuration(
    customer_billing_provider_configuration_input: {billing_provider: AZURE, customer_id: $customer_id, configuration: $configuration, delivery_method: DIRECT_TO_BILLING_PROVIDER}
  ) {
    id
    configuration
  }
}
    `;
export type InsertCustomerBillingProviderConfigurationMutationFn = Apollo.MutationFunction<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>;

/**
 * __useInsertCustomerBillingProviderConfigurationMutation__
 *
 * To run a mutation, you first call `useInsertCustomerBillingProviderConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomerBillingProviderConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomerBillingProviderConfigurationMutation, { data, loading, error }] = useInsertCustomerBillingProviderConfigurationMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useInsertCustomerBillingProviderConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>(InsertCustomerBillingProviderConfigurationDocument, options);
      }
export type InsertCustomerBillingProviderConfigurationMutationHookResult = ReturnType<typeof useInsertCustomerBillingProviderConfigurationMutation>;
export type InsertCustomerBillingProviderConfigurationMutationResult = Apollo.MutationResult<InsertCustomerBillingProviderConfigurationMutation>;
export type InsertCustomerBillingProviderConfigurationMutationOptions = Apollo.BaseMutationOptions<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>;
import React, { useState } from "react";

import { useNavigate } from "app/lib/useNavigate";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { useNow, toISOString, Dayjs } from "lib/date";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import {
  getUserFacingErrorMessage,
  UserFacingError,
} from "app/lib/errors/errorHandling";

import {
  CommitDetailsFragment,
  useContractPricingAndCreditTypesQuery,
  useContractToEditQuery,
  useEditContractMutation,
} from "./data.graphql";
import { Schema } from "./Schema";
import { FooterBar } from "./components/FooterBar";
import { CommitsSection } from "./Sections/Commits";
import { RatesSection } from "./Sections/RateCard";
import { AdditionalTermsSection } from "./Sections/AdditionalTerms";
import { ContractPricing } from "app/pages/Contracts/lib/ContractPricing";
import { DefaultTimeframe } from "./lib/DefaultTimeframe";
import { getEditMutationVars } from "./convertFormToMutationVars";
import { useFlyovers, useSnapshot, Snapshot } from "./lib/SharedContext";
import { useRequiredParam } from "app/lib/routes/params";

import { useFeatureFlag } from "app/lib/launchdarkly";
import { describeOverride } from "app/pages/Contracts/lib/Override";
import { CreditsSection } from "./Sections/Commits/CreditsSection";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { EditDetails } from "./components/EditDetails";
import Decimal from "decimal.js";
import { deepEqual } from "fast-equals";
import { ScheduledCharge } from "types/generated-graphql/__types__";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { Commit } from "app/pages/Contracts/lib/Commit";
import NotFoundPage from "app/pages/404";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";
import { useSnackbar } from "components/deprecated/Snackbar";
import { AppShell } from "components/AppShell";

export type EditContractCtrl = ReturnType<typeof useEditContractCtrl>;
const useEditContractCtrl = FormController.createHook(
  Schema.EditContractInput,
  {
    init(snapshot: Snapshot) {
      return FormController.parseJsonSnapshot(
        Schema.EditContractInput,
        snapshot.initialJson,
      );
    },
  },
);

const formatCommits = (commits: CommitDetailsFragment[], now: Dayjs) => {
  const ok = commits.map((commit) => {
    // RFs come in as decimal numbers, but the UI renders them as %
    let newCommit: Schema.Types.MutableCommit = {
      commit_id: commit.id,
      name: Commit.getName(commit, now),
      netsuite_sales_order_id: commit.netsuite_sales_order_id,
      // if rolled_over_from has a value, then this is a rollover commit
      isRolloverCommit: !!commit.rolled_over_from,
      rollover_fraction: commit.rollover_fraction
        ? new Decimal(commit.rollover_fraction).times(100).toString()
        : null,
    };
    return newCommit;
  });
  return ok;
};

const formatCharges = (charges: ScheduledCharge[], now: Dayjs) => {
  return charges.map((charge) => {
    let newCharge: Schema.Types.MutableScheduledCharge = {
      scheduled_charge_id: charge.id,
      name: ProductListItem.getName(charge.product, now),
      netsuite_sales_order_id: charge.netsuite_sales_order_id,
    };
    return newCharge;
  });
};

export const EditContract: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const now = useNow();
  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  const pricingAndCreditTypesReq = useContractPricingAndCreditTypesQuery();

  const canEditContract = useFeatureFlag<boolean>(
    "allow-contract-editing",
    false,
  );
  const showEditContractUI = useFeatureFlag<boolean>(
    "contract-editing-ui",
    false,
  );
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );

  const contractReq = useContractToEditQuery({
    variables: {
      contract_id: contractId,
      customer_id: customerId,
    },
  });

  const snapshot = useSnapshot(
    `contract-create-snapshot:${customerId}:${contractId}`,
  );
  const ctrl = useEditContractCtrl(snapshot);
  const [prevCtrlFields] = useState(ctrl.state.fields);
  React.useEffect(() => {
    snapshot.update(ctrl);
  }, [ctrl]);

  React.useEffect(() => {
    if (!contractReq.loading) {
      const commits =
        contractReq.data?.Customer_by_pk?.contract?.v2_fields?.commits_union;
      const charges = contractReq.data?.Customer_by_pk?.contract?.v2_fields
        ?.scheduled_charges as ScheduledCharge[];
      ctrl.update({
        mutableCommits: commits ? formatCommits(commits, now) : [],
        mutableScheduledCharges: charges ? formatCharges(charges, now) : [],
      });
    }
  }, [contractReq]);

  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    pricingAndCreditTypesReq.data?.CreditType ?? [],
  );

  const contractFromReq = contractReq.data?.Customer_by_pk?.contract;
  const { flyoverElement, setFlyover } = useFlyovers({
    ctrl,
    allProducts: pricingAndCreditTypesReq?.data?.contract_pricing.products
      ? pricingAndCreditTypesReq.data.contract_pricing.products
      : [],
    contract: contractFromReq || undefined,
    options: {
      netsuiteEnabled: nonGAContractFeaturesEnabled?.includes("NETSUITE"),
    },
  });

  const [editContractMutation, editContractMutationResult] =
    useEditContractMutation();

  const cancel = () => {
    snapshot.clear();
    navigate(
      Contract.getRoutePath({
        id: contractId,
        customer: { id: customerId },
      }),
    );
  };

  const loading = pricingAndCreditTypesReq.loading || contractReq.loading;
  const error = pricingAndCreditTypesReq.error;
  const pricing = pricingAndCreditTypesReq.data?.contract_pricing;
  const contract = contractReq.data?.Customer_by_pk?.contract;
  const rateCard =
    pricing && contract?.rate_card
      ? ContractPricing.getRateCard(pricing, contract.rate_card.id)
      : undefined;

  React.useEffect(() => {
    if (!contract) {
      return;
    }
  }, [contract]);

  const submitForm = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      if (editContractMutationResult.loading || !pricing) {
        return;
      }
      const result = await editContractMutation({
        variables: getEditMutationVars(customerId, contractId, valid),
        update(cache) {
          cache.evict({ id: `Customer:${customerId}` });
          cache.evict({ id: `Contract:${contractId}` });
          cache.gc();
        },
      });

      const editedContract = result.data?.edit_contract;
      if (!editedContract) {
        throw new UserFacingError(
          "Failed to edit contract, server sent an unexpected response",
        );
      }

      snapshot.clear();
      navigate(Contract.getRoutePath(editedContract));
      pushMessage({
        type: "success",
        content: `Contract successfully edited`,
      });
    } catch (e) {
      pushMessage({
        content: getUserFacingErrorMessage(e, "Failed to edit contract"),
        type: "error",
      });
    }
  });

  if (!canEditContract || !showEditContractUI) {
    return <NotFoundPage />;
  }
  return (
    <AppShell
      title="Edit contract"
      headerProps={{
        actions: (
          <IconButton onClick={cancel} theme="secondary" icon="xClose" />
        ),
        breadcrumbs: Breadcrumbs.from(
          {
            label: contractReq.data?.Customer_by_pk?.name ?? "Loading...",
            routePath: `/customers/${customerId}`,
          },
          {
            label: contractReq.data?.Customer_by_pk?.contract
              ? Contract.getName(contractReq.data.Customer_by_pk.contract)
              : "",
            routePath: `/customers/${customerId}/contracts/${contractId}`,
          },
        ),
      }}
    >
      {error ? (
        <ErrorEmptyState error={error} title="Failed to load the form" />
      ) : loading || !pricing || !contract ? (
        <DeprecatedParagraphSkeleton numLines={20} />
      ) : (
        <DefaultTimeframe.Provider
          startingAt={toISOString(contract.starting_at)}
          endingBefore={
            contract.ending_before
              ? toISOString(contract.ending_before)
              : undefined
          }
        >
          {flyoverElement}

          <form onSubmit={submitForm} className="h-full">
            <div className="-mx-12 flex h-full flex-col overflow-hidden">
              <div className="grow overflow-auto">
                <EditDetails ctrl={ctrl} />
                <CommitsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCommit={() =>
                    setFlyover({
                      type: "new_commit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCommit={(id) =>
                    setFlyover({
                      type: "edit_commit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <CreditsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCredit={() =>
                    setFlyover({
                      type: "new_credit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCredit={(id) =>
                    setFlyover({
                      type: "edit_credit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <RatesSection
                  ctrl={ctrl}
                  rateCardId={rateCard?.id}
                  allProducts={pricing.products}
                  baseContractOverrideDescriptions={
                    contractFromReq
                      ? contractFromReq.v2_fields?.overrides?.map((o) =>
                          describeOverride(now, o),
                        ) ?? []
                      : []
                  }
                  pricing={pricing}
                  contract={contract}
                  onAddOverride={(productId, creditType) =>
                    setFlyover({
                      type: "new_override",
                      productId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditOverride={(overrideId, creditType) =>
                    setFlyover({
                      type: "edit_override",
                      id: overrideId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onViewOverrides={() =>
                    setFlyover({
                      type: "view_overrides",
                      rateCardId: rateCard?.id,
                    })
                  }
                />
                <AdditionalTermsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  fiatCreditTypes={fiatCreditTypes}
                  onAdd={(type: Schema.Types.AdditionalTermType) => {
                    setFlyover({
                      type: `new_${type}`,
                      fiatCreditTypes,
                    });
                  }}
                  onEdit={(type: Schema.Types.AdditionalTermType, id: string) =>
                    setFlyover({ type: `edit_${type}`, id, fiatCreditTypes })
                  }
                  options={{
                    discountsEnabled:
                      nonGAContractFeaturesEnabled?.includes("DISCOUNTS"),
                    resellerRoyaltiesEnabled:
                      nonGAContractFeaturesEnabled?.includes(
                        "RESELLER_ROYALTIES",
                      ),
                    proServicesEnabled: nonGAContractFeaturesEnabled?.includes(
                      "PROFESSIONAL_SERVICES",
                    ),
                  }}
                />
              </div>
              <FooterBar
                right={
                  <>
                    <Button onClick={cancel} text="Cancel" theme="linkGray" />
                    <Button
                      disabled={
                        editContractMutationResult.loading ||
                        !ctrl.appearsValid() ||
                        deepEqual(prevCtrlFields, ctrl.state.fields)
                      }
                      onClick={submitForm}
                      text="Save"
                      theme="primary"
                    />
                  </>
                }
              />
            </div>
          </form>
        </DefaultTimeframe.Provider>
      )}
    </AppShell>
  );
};

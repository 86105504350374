import React, { useState } from "react";
import { Headline, Badge, Tooltip, Icon, Input } from "design-system";

import { useNow } from "lib/date";
import { useSearcher } from "app/lib/search/useSearcher";

import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";

import {
  useGetRateScheduleQuery,
  useGetRateCardProductsQuery,
} from "./data.graphql";
import { RateScheduleRow, RateScheduleTable } from "../RateScheduleTable";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { Override } from "@metronome-industries/schedule-utils";
import { RateScheduleSelector } from "types/generated-graphql/__types__";
import { ProductListItem } from "../../lib/ProductListItem";
import { ButtonGroup } from "components/ButtonGroup";
import { OverridesTableWithData } from "../OverridesTable";
import { useFeatureFlag } from "app/lib/launchdarkly";

const MAX_SORTABLE_SEARCHABLE_SIZE = 1000;

type State = {
  search: string;
  debouncedSearch: string;
  pagination: Pagination;
};

interface Pagination {
  size: number;
  pointer: Pointer;
  history: Array<Pointer>;
}

const DEFAULT_STATE: State = {
  search: "",
  debouncedSearch: "",
  pagination: {
    size: 50,
    pointer: { cursor: null, offset: 0 },
    history: [],
  },
};

type Pointer = { cursor: string | null; offset: number };

function pickNextPaginationState(
  state: Pagination,
  allRowCount: number,
  nextCursor: string | null,
) {
  if (state.pointer.offset + state.size < allRowCount) {
    return {
      ...state,
      pointer: {
        cursor: state.pointer.cursor,
        offset: state.pointer.offset + state.size,
      },
      history: state.history,
    };
  }

  if (nextCursor) {
    return {
      ...state,
      pointer: { cursor: nextCursor, offset: 0 },
      history: [state.pointer, ...state.history],
    };
  }

  return null;
}

function pickPrevPaginationState(state: Pagination) {
  if (state.pointer.offset > 0) {
    return {
      ...state,
      pointer: {
        cursor: state.pointer.cursor,
        offset: Math.max(0, state.pointer.offset - state.size),
      },
      history: state.history,
    };
  }

  if (state.history.length) {
    return {
      ...state,
      pointer: state.history[0],
      history: state.history.slice(1),
    };
  }

  return null;
}

type Action =
  | {
      type: "search";
      search: string;
    }
  | {
      type: "searchDebounced";
    }
  | {
      type: "newPageSize";
      newPageSize: number;
    }
  | {
      type: "paginate";
      pagination: Pagination;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "search":
      return {
        ...state,
        search: action.search,
      };
    case "searchDebounced":
      return {
        ...state,
        debouncedSearch: state.search,
        pagination: {
          ...DEFAULT_STATE.pagination,
          size: state.pagination.size,
        },
      };
    case "newPageSize":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          size: action.newPageSize,
        },
      };
    case "paginate":
      return {
        ...state,
        pagination: action.pagination,
      };
  }
}

interface Props {
  title?: string;

  design?: "default" | "noPanel";

  rateCardId: string;
  rateSelectors?: Array<RateScheduleSelector>;

  /**
   * Only show rates from some date onward
   */
  startingAt: Date | null;

  /**
   * Only show rates up to some date
   */
  endingBefore: Date | null;

  onRowClick?: (row: RateScheduleRow) => void;

  overrides?: Override.Description[] | false;
  showOverridesTable?: boolean;

  multiplierOverridePrioritization?: string;

  disableSearch?: boolean;

  emptyState?:
    | React.ReactNode
    | ((state: { search: string }) => React.ReactNode);

  /**
   * Set to true to allow segments to go beyond the startingAt and endingBefore dates.
   */
  noTruncate?: boolean;

  /**
   * Set to true to render the T10 table instead of the legacy table.
   */
  renderT10Table: boolean;
}

export const RateSchedulePanel: React.FC<Props> = (props) => {
  const now = useNow();
  const hideOverridesTable = useFeatureFlag<boolean>(
    "hide-overrides-table",
    false,
  );
  const enableOverridesTableUi =
    props.showOverridesTable && !hideOverridesTable;
  const [activeTab, setActiveTab] = useState<"RATES" | "OVERRIDES">("RATES");
  const [{ search, debouncedSearch, pagination }, dispatch] = React.useReducer(
    reducer,
    DEFAULT_STATE,
  );
  // trigger searchDebounced action after 700ms of no search changes
  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: "searchDebounced" });
    }, 700);

    return () => clearTimeout(timer);
  }, [search]);

  const memoizedOnSearchFunction = React.useCallback(
    (v: string) => dispatch({ type: "search", search: v }),
    [dispatch],
  );

  const productResp = useApolloResp(
    useGetRateCardProductsQuery({
      variables: {
        rateCardId: props.rateCardId,
      },
    }),
  );

  const searchableProducts = React.useMemo(
    () =>
      productResp.state === "success"
        ? productResp.contract_pricing.rate_card.products.map((p) => ({
            id: p.id,
            names: ProductListItem.getAllNames(p),
            name: ProductListItem.getName(p, now),
            type: ProductListItem.printType(p),
          }))
        : [],
    [now, productResp],
  );

  const searchProducts = useSearcher(searchableProducts, {
    keys: ["names"],
    uuidKeys: ["id"],
  });
  const matchedProducts = searchProducts(debouncedSearch);

  const scheduleResp = useApolloResp(
    useGetRateScheduleQuery({
      variables: {
        cursor: pagination.pointer.cursor,
        limit: String(MAX_SORTABLE_SEARCHABLE_SIZE),
        rateCardId: props.rateCardId,
        startingAt: props.startingAt ? props.startingAt.toISOString() : null,
        endingBefore: props.endingBefore
          ? props.endingBefore.toISOString()
          : null,
        truncate: !props.noTruncate,
        tryProductOrderSort: true,
        selectors:
          debouncedSearch || props.rateSelectors
            ? [
                props.rateSelectors ?? [],
                debouncedSearch
                  ? matchedProducts
                      .map((p) => ({
                        product_id: p.id,
                      }))
                      .slice(
                        0,
                        Math.min(MAX_SORTABLE_SEARCHABLE_SIZE, pagination.size),
                      )
                  : [],
              ].flat()
            : null,
      },
    }),
  );

  const allSegmentRows = React.useMemo(() => {
    const rateSchedule =
      scheduleResp.state === "success"
        ? scheduleResp.contract_pricing.rate_card.rate_schedule
        : null;

    return RateScheduleTable.rowsFromSegments(
      now,
      rateSchedule?.scalar_segments ?? [],
      rateSchedule?.products_on_segments ?? [],
      rateSchedule?.credit_types_on_segments ?? [],
    );
  }, [now, scheduleResp]);

  const rows = React.useMemo(() => {
    const slice = allSegmentRows.slice(
      pagination.pointer.offset,
      pagination.pointer.offset + pagination.size,
    );

    return props.overrides && productResp.state === "success"
      ? RateScheduleTable.applyContractOverrides(
          slice,
          productResp.contract_pricing.rate_card.products,
          props.overrides,
        )
      : slice;
  }, [
    allSegmentRows,
    pagination.pointer.offset,
    pagination.size,
    productResp,
    props.overrides,
  ]);

  const nextPageState = pickNextPaginationState(
    pagination,
    allSegmentRows.length,
    scheduleResp.state === "success"
      ? scheduleResp.contract_pricing.rate_card.rate_schedule.next_page
      : null,
  );
  const prevPageState = pickPrevPaginationState(pagination);

  if (scheduleResp.state === "error") {
    return (
      <ErrorEmptyState
        title="Unable to load the rate schedule"
        error={scheduleResp.error}
      />
    );
  }

  if (productResp.state === "error") {
    return (
      <ErrorEmptyState
        title="Unable to load products"
        error={productResp.error}
      />
    );
  }

  const searchInput = props.disableSearch ? null : (
    <div className="flex items-center gap-8">
      <Input
        type="search"
        placeholder="Search rates"
        value={search}
        onChange={(v) => dispatch({ type: "search", search: v })}
      />
    </div>
  );

  const titleBadge =
    scheduleResp.state === "success" &&
    !scheduleResp.contract_pricing.rate_card.rate_schedule
      .using_product_order ? (
      <Tooltip
        content={`We were unable to apply the product sort order to the rate schedule because more than ${MAX_SORTABLE_SEARCHABLE_SIZE} rate segments were requested`}
        position={["right"]}
      >
        <Badge
          theme="error"
          type="light"
          className="inline-flex items-center gap-4"
        >
          <Icon icon="warning" /> Product order
        </Badge>
      </Tooltip>
    ) : undefined;

  const tabs = enableOverridesTableUi ? (
    <>
      <ButtonGroup
        buttons={[
          {
            onClick: () => setActiveTab("RATES"),
            text: "Rates",
            isActive: activeTab === "RATES",
          },
          {
            onClick: () => setActiveTab("OVERRIDES"),
            text: "Overrides",
            isActive: activeTab === "OVERRIDES",
          },
        ]}
      />
    </>
  ) : undefined;

  const table = (
    <div className="flex flex-col gap-y-8">
      {activeTab === "OVERRIDES" && (
        <OverridesTableWithData
          overrides={props.overrides ? props.overrides : []}
          rateCardId={props.rateCardId}
          hidePriorityColumn={
            props.multiplierOverridePrioritization === "LOWEST_MULTIPLIER"
          }
          tabs={tabs}
        />
      )}
      {activeTab === "RATES" && (
        <RateScheduleTable
          title={props.design === "noPanel" ? undefined : props.title}
          titleBadge={props.design === "noPanel" ? undefined : titleBadge}
          tabs={tabs}
          onRowClick={props.onRowClick}
          rows={rows}
          noOverrides={props.overrides === false}
          emptyState={
            typeof props.emptyState === "function"
              ? props.emptyState({ search })
              : props.emptyState
          }
          loading={
            productResp.state === "loading" || scheduleResp.state === "loading"
          }
          controls={props.design === "noPanel" ? null : searchInput}
          onSearch={memoizedOnSearchFunction}
          basicPagination={{
            pageSize: pagination.size,
            pageSizeOptions: [10, 25, 50, 100],
            onPageSizeChange(newPageSize) {
              dispatch({
                type: "newPageSize",
                newPageSize,
              });
            },
            onNext: nextPageState
              ? () => {
                  dispatch({ type: "paginate", pagination: nextPageState });
                }
              : undefined,
            onPrev: prevPageState
              ? () => {
                  dispatch({ type: "paginate", pagination: prevPageState });
                }
              : undefined,
          }}
          renderT10Table={props.renderT10Table}
          disableSearch={props.disableSearch ?? false}
        />
      )}
    </div>
  );

  return props.design === "noPanel" ? (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex gap-12">
          {props.title ? (
            <Headline level={6} className="text-sm">
              {props.title}
            </Headline>
          ) : (
            <span />
          )}
          {titleBadge}
        </div>

        {searchInput}
      </div>
      {table}
    </>
  ) : (
    table
  );
};

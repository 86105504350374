import React from "react";

import { dayjs } from "lib/dayjs";
import { Subtitle, Caption } from "design-system";
import { renderDateTimeInUTC } from "lib/time";
import { MriInvoiceFragment } from "components/deprecated/Invoice/queries.graphql";

import classNames from "classnames";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";

type TimelineEntry = {
  title: string;
  date: Date;
  invoiceId: string;
};

export const DeprecatedInvoiceTimeline: React.FC<{
  invoice: MriInvoiceFragment;
}> = ({ invoice }) => {
  let entries: TimelineEntry[] = [];
  if (
    invoice.__typename === "AdHocPlanInvoice" ||
    invoice.__typename === "ArrearsInvoice" ||
    invoice.__typename === "ContractUsageInvoice" ||
    invoice.__typename === "AdhocContractUsageInvoice" ||
    invoice.__typename === "ParentInvoice"
  ) {
    entries = [
      {
        title: "Start",
        date: new Date(invoice.inclusive_start_date),
        invoiceId: invoice.id,
      },
      {
        title: "End",
        date: new Date(invoice.exclusive_end_date),
        invoiceId: invoice.id,
      },
      {
        title: "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else if (invoice.__typename === "CreditPurchaseInvoice") {
    entries = [
      {
        title: dayjs(invoice.issued_at).isSameOrAfter(new Date())
          ? "Scheduled"
          : "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else if (
    invoice.__typename === "AdvanceInvoice" ||
    invoice.__typename === "SeatPurchaseInvoice" ||
    invoice.__typename === "ContractScheduledInvoice" ||
    invoice.__typename === "ContractProServiceInvoice" ||
    invoice.__typename === "ContractRefundInvoice" ||
    invoice.__typename === "ContractPostpaidTrueupInvoice"
  ) {
    entries = [
      {
        title: "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else if (invoice.__typename === "CorrectionInvoice") {
    entries = [
      {
        title: "Created",
        date: new Date(invoice.created_at),
        invoiceId: invoice.id,
      },
      {
        title: "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else {
    // If this is failing to compile, you probably need to add a new case to the switch statement above
    invoice satisfies never;
  }

  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  return (
    <div className="flex">
      {entries.map((entry, i) => {
        const isActive =
          dayjs().isAfter(entry.date) &&
          (!entries[i + 1] || dayjs().isBefore(entries[i + 1].date));
        return (
          <div
            key={`${entry.invoiceId}-${
              entry.title
            }-${entry.date.toISOString()}`}
            className={classNames(
              "mr-8 border-l-2 border-deprecated-gray-100 pl-8",
              isActive && "border-deprecated-primary-600",
            )}
          >
            <Caption className={isActive ? "text-deprecated-primary-600" : ""}>
              {entry.title}
            </Caption>
            <Subtitle
              level={4}
              className={classNames(
                isActive && isEmbeddableDashboard && "text-deprecated-gray-900",
                isActive && !isEmbeddableDashboard && "text-gray-700",
                isEmbeddableDashboard ? "text-default-font" : "text-gray-700",
              )}
            >
              {renderDateTimeInUTC(entry.date, false)}
            </Subtitle>
          </div>
        );
      })}
    </div>
  );
};

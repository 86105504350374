/* dfa1f15cf639e8da07471fe126a23945e02122ea
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerBillingsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type CustomerBillingsQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, created_at: string, contracts: Array<{ __typename?: 'Contract', id: string, starting_at: string }>, plans: Array<{ __typename?: 'CustomerPlan', id: string, start_date: string }>, revenue_overview: { __typename?: 'CustomerRevenueOverview', total_billings: { __typename?: 'Billings', ending_before: string, results: Array<{ __typename?: 'Billings_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> }, top_products: { __typename?: 'TopProducts', ending_before: string, results: Array<{ __typename?: 'TopProducts_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, product: { __typename?: 'TopProducts_Result_Product', id: string, name: string | null }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> }, top_plan_charges: { __typename?: 'TopPlanCharges', ending_before: string, results: Array<{ __typename?: 'TopPlanCharges_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, plan_charge: { __typename?: 'TopPlanCharges_Result_PlanCharge', id: string, name: string | null, product_id: string }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> } } }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const CustomerBillingsDocument = gql`
    query CustomerBillings($id: uuid!) {
  Customer(where: {id: {_eq: $id}}) {
    id
    created_at
    contracts {
      id
      starting_at
    }
    plans: CustomerPlans(limit: 1) {
      id
      start_date
    }
    revenue_overview {
      total_billings {
        ending_before
        results {
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
          amount
        }
      }
      top_products {
        ending_before
        results {
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
          product {
            id
            name
          }
          amount_billed
          month_over_month_change {
            percent_change
            previous_period_total_billed
            previous_period_ending_before
          }
        }
      }
      top_plan_charges {
        ending_before
        results {
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
          plan_charge {
            id
            name
            product_id
          }
          amount_billed
          month_over_month_change {
            percent_change
            previous_period_total_billed
            previous_period_ending_before
          }
        }
      }
    }
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useCustomerBillingsQuery__
 *
 * To run a query within a React component, call `useCustomerBillingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerBillingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerBillingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerBillingsQuery(baseOptions: Apollo.QueryHookOptions<CustomerBillingsQuery, CustomerBillingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerBillingsQuery, CustomerBillingsQueryVariables>(CustomerBillingsDocument, options);
      }
export function useCustomerBillingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerBillingsQuery, CustomerBillingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerBillingsQuery, CustomerBillingsQueryVariables>(CustomerBillingsDocument, options);
        }
export type CustomerBillingsQueryHookResult = ReturnType<typeof useCustomerBillingsQuery>;
export type CustomerBillingsLazyQueryHookResult = ReturnType<typeof useCustomerBillingsLazyQuery>;
export type CustomerBillingsQueryResult = Apollo.QueryResult<CustomerBillingsQuery, CustomerBillingsQueryVariables>;
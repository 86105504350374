/* ce2933dce077acd26061c677b91eee0e30300911
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCustomersQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  query: Types.Scalars['String'];
}>;


export type SearchCustomersQuery = { __typename?: 'Query', searchCustomers: Array<{ __typename?: 'Customer', id: string, name: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }> }>, CustomerIngestAlias: Array<{ __typename?: 'CustomerIngestAlias', Customer: { __typename?: 'Customer', id: string } }> };

export type BillableMetricsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type BillableMetricsQuery = { __typename?: 'Query', billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, name: string }> };

export type SeatMetricsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SeatMetricsQuery = { __typename?: 'Query', seat_metrics: { __typename?: 'PagedSeatMetricsResult', cursor: string | null, metrics: Array<{ __typename?: 'SeatMetric', id: string, name: string }> } };

export type EventsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  transaction_ids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  duplicates?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EventsQuery = { __typename?: 'Query', mri_events: Array<{ __typename?: 'MRI_Event', id: string, transaction_id: string, customer_id: string, event_type: string, timestamp: string, properties: object, is_duplicate: boolean, processedAt: string | null }> };

export type EventCountsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type EventCountsQuery = { __typename?: 'Query', duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }>, non_duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }> };

export type CustomerByIngestAliasQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  orConditions: Array<Types.Customer_Bool_Exp> | Types.Customer_Bool_Exp;
}>;


export type CustomerByIngestAliasQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, name: string }> };

export type CustomerByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type CustomerByIdQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, name: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }> } | null };

export type EventDetailsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  transaction_ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  duplicates?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EventDetailsQuery = { __typename?: 'Query', mri_events: Array<{ __typename?: 'MRI_Event', id: string, customer_id: string, event_type: string, properties: object, timestamp: string, transaction_id: string, is_duplicate: boolean, processedAt: string | null, billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, name: string, is_draft: boolean }>, seat_metrics: Array<{ __typename?: 'SeatMetric', id: string, name: string, is_draft: boolean }>, customer: { __typename?: 'Customer', id: string, name: string } | null }> };


export const SearchCustomersDocument = gql`
    query SearchCustomers($environment_type: EnvironmentTypeEnum_enum!, $query: String!) {
  searchCustomers(environment_type: $environment_type, query: $query) {
    id
    name
    CustomerIngestAliases {
      ingest_alias
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
  CustomerIngestAlias(
    where: {environment_type: {_eq: $environment_type}, ingest_alias: {_eq: $query}}
  ) {
    Customer {
      id
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchCustomersQuery(baseOptions: Apollo.QueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
      }
export function useSearchCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
        }
export type SearchCustomersQueryHookResult = ReturnType<typeof useSearchCustomersQuery>;
export type SearchCustomersLazyQueryHookResult = ReturnType<typeof useSearchCustomersLazyQuery>;
export type SearchCustomersQueryResult = Apollo.QueryResult<SearchCustomersQuery, SearchCustomersQueryVariables>;
export const BillableMetricsDocument = gql`
    query BillableMetrics($environment_type: EnvironmentTypeEnum_enum!) {
  billable_metrics(environment_type: $environment_type, types: [ACTIVE]) {
    id
    name
    __environment_type: environment_type
  }
}
    `;

/**
 * __useBillableMetricsQuery__
 *
 * To run a query within a React component, call `useBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useBillableMetricsQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
      }
export function useBillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
        }
export type BillableMetricsQueryHookResult = ReturnType<typeof useBillableMetricsQuery>;
export type BillableMetricsLazyQueryHookResult = ReturnType<typeof useBillableMetricsLazyQuery>;
export type BillableMetricsQueryResult = Apollo.QueryResult<BillableMetricsQuery, BillableMetricsQueryVariables>;
export const SeatMetricsDocument = gql`
    query SeatMetrics {
  seat_metrics(types: [ACTIVE], limit: 100) {
    cursor
    metrics {
      id
      name
    }
  }
}
    `;

/**
 * __useSeatMetricsQuery__
 *
 * To run a query within a React component, call `useSeatMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeatMetricsQuery(baseOptions?: Apollo.QueryHookOptions<SeatMetricsQuery, SeatMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatMetricsQuery, SeatMetricsQueryVariables>(SeatMetricsDocument, options);
      }
export function useSeatMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatMetricsQuery, SeatMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatMetricsQuery, SeatMetricsQueryVariables>(SeatMetricsDocument, options);
        }
export type SeatMetricsQueryHookResult = ReturnType<typeof useSeatMetricsQuery>;
export type SeatMetricsLazyQueryHookResult = ReturnType<typeof useSeatMetricsLazyQuery>;
export type SeatMetricsQueryResult = Apollo.QueryResult<SeatMetricsQuery, SeatMetricsQueryVariables>;
export const EventsDocument = gql`
    query Events($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $limit: Int!, $ingest_aliases: [String!], $transaction_ids: [String!], $billable_metric_ids: [uuid!], $duplicates: Boolean) {
  mri_events(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    limit: $limit
    ingest_aliases: $ingest_aliases
    transaction_ids: $transaction_ids
    billable_metric_ids: $billable_metric_ids
    duplicates: $duplicates
  ) {
    id
    transaction_id
    customer_id
    event_type
    timestamp
    properties
    is_duplicate
    processedAt
    __environment_type: environment_type
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      limit: // value for 'limit'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      transaction_ids: // value for 'transaction_ids'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *      duplicates: // value for 'duplicates'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const EventCountsDocument = gql`
    query EventCounts($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $ingest_aliases: [String!], $billable_metric_ids: [uuid!]) {
  duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    ingest_aliases: $ingest_aliases
    billable_metric_ids: $billable_metric_ids
    duplicates: true
  ) {
    starting_on
    count
  }
  non_duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    ingest_aliases: $ingest_aliases
    billable_metric_ids: $billable_metric_ids
    duplicates: false
  ) {
    starting_on
    count
  }
}
    `;

/**
 * __useEventCountsQuery__
 *
 * To run a query within a React component, call `useEventCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCountsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *   },
 * });
 */
export function useEventCountsQuery(baseOptions: Apollo.QueryHookOptions<EventCountsQuery, EventCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventCountsQuery, EventCountsQueryVariables>(EventCountsDocument, options);
      }
export function useEventCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventCountsQuery, EventCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventCountsQuery, EventCountsQueryVariables>(EventCountsDocument, options);
        }
export type EventCountsQueryHookResult = ReturnType<typeof useEventCountsQuery>;
export type EventCountsLazyQueryHookResult = ReturnType<typeof useEventCountsLazyQuery>;
export type EventCountsQueryResult = Apollo.QueryResult<EventCountsQuery, EventCountsQueryVariables>;
export const CustomerByIngestAliasDocument = gql`
    query CustomerByIngestAlias($environment_type: EnvironmentTypeEnum_enum!, $orConditions: [Customer_bool_exp!]!) {
  Customer(
    where: {_and: [{_or: $orConditions}, {environment_type: {_eq: $environment_type}}]}
  ) {
    id
    name
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerByIngestAliasQuery__
 *
 * To run a query within a React component, call `useCustomerByIngestAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIngestAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIngestAliasQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      orConditions: // value for 'orConditions'
 *   },
 * });
 */
export function useCustomerByIngestAliasQuery(baseOptions: Apollo.QueryHookOptions<CustomerByIngestAliasQuery, CustomerByIngestAliasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerByIngestAliasQuery, CustomerByIngestAliasQueryVariables>(CustomerByIngestAliasDocument, options);
      }
export function useCustomerByIngestAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerByIngestAliasQuery, CustomerByIngestAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerByIngestAliasQuery, CustomerByIngestAliasQueryVariables>(CustomerByIngestAliasDocument, options);
        }
export type CustomerByIngestAliasQueryHookResult = ReturnType<typeof useCustomerByIngestAliasQuery>;
export type CustomerByIngestAliasLazyQueryHookResult = ReturnType<typeof useCustomerByIngestAliasLazyQuery>;
export type CustomerByIngestAliasQueryResult = Apollo.QueryResult<CustomerByIngestAliasQuery, CustomerByIngestAliasQueryVariables>;
export const CustomerByIdDocument = gql`
    query CustomerByID($id: uuid!) {
  Customer_by_pk(id: $id) {
    id
    name
    CustomerIngestAliases {
      ingest_alias
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerByIdQuery__
 *
 * To run a query within a React component, call `useCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, options);
      }
export function useCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, options);
        }
export type CustomerByIdQueryHookResult = ReturnType<typeof useCustomerByIdQuery>;
export type CustomerByIdLazyQueryHookResult = ReturnType<typeof useCustomerByIdLazyQuery>;
export type CustomerByIdQueryResult = Apollo.QueryResult<CustomerByIdQuery, CustomerByIdQueryVariables>;
export const EventDetailsDocument = gql`
    query EventDetails($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $transaction_ids: [String!]!, $ingest_aliases: [String!], $billable_metric_ids: [uuid!], $duplicates: Boolean) {
  mri_events(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    limit: 100
    ingest_aliases: $ingest_aliases
    transaction_ids: $transaction_ids
    duplicates: $duplicates
    billable_metric_ids: $billable_metric_ids
  ) {
    id
    customer_id
    event_type
    properties
    timestamp
    transaction_id
    is_duplicate
    processedAt
    billable_metrics(types: [ACTIVE]) {
      id
      name
      is_draft
      __environment_type: environment_type
    }
    seat_metrics(types: [ACTIVE]) {
      id
      name
      is_draft
    }
    customer {
      id
      name
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      transaction_ids: // value for 'transaction_ids'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *      duplicates: // value for 'duplicates'
 *   },
 * });
 */
export function useEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
      }
export function useEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
        }
export type EventDetailsQueryHookResult = ReturnType<typeof useEventDetailsQuery>;
export type EventDetailsLazyQueryHookResult = ReturnType<typeof useEventDetailsLazyQuery>;
export type EventDetailsQueryResult = Apollo.QueryResult<EventDetailsQuery, EventDetailsQueryVariables>;
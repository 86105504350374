import type { AddPlanToCustomerDataQuery } from "app/pages/Customer/AddPlanToCustomer/queries.graphql";
import { getUtcEndDate, getUtcStartOfDay } from "lib/time";
import { dayjs } from "lib/dayjs";
const PLAN_START_CUTOFF = dayjs.utc("2012-01-01T00:00:00Z");

// Returns undefined if either date is undefined, null if there's no error, otherwise string with an error message
export const validatePlanDates = (
  startDate: Date | undefined,
  endDate: Date | null | undefined,
  existingPlans: Pick<
    AddPlanToCustomerDataQuery["CustomerPlan"][0],
    "start_date" | "cancellation_date"
  >[],
  lastFinalizedInvoiceDate?: Date,
): string | null | undefined => {
  if (startDate === undefined || endDate === undefined) {
    return undefined;
  }

  const zeroedOutStart = dayjs.utc(getUtcStartOfDay(startDate));
  if (zeroedOutStart.isBefore(PLAN_START_CUTOFF)) {
    return "The start date cannot be that far in the past";
  }

  const zeroedOutEnd = endDate ? dayjs.utc(getUtcEndDate(endDate)) : null;
  if (zeroedOutEnd) {
    if (!zeroedOutStart.isBefore(zeroedOutEnd)) {
      return "The start date must be before the end date";
    }
    if (
      lastFinalizedInvoiceDate &&
      !zeroedOutEnd.isSameOrAfter(dayjs.utc(lastFinalizedInvoiceDate))
    ) {
      return "The end date must be greater than or equal to the end date of the latest finalized invoice";
    }
  }

  for (const existingPlan of existingPlans) {
    const existingStart = dayjs.utc(existingPlan.start_date);
    const existingEnd = existingPlan.cancellation_date
      ? dayjs.utc(existingPlan.cancellation_date)
      : null;
    if (
      existingEnd === null &&
      (zeroedOutEnd === null || !zeroedOutEnd.isSameOrBefore(existingStart))
    ) {
      return "The plan dates overlap with an existing plan"; // if two perpetual plans or existing perpetual and new plan ends after existing one starts
    } else if (
      zeroedOutEnd === null &&
      existingEnd !== null &&
      !existingEnd.isSameOrBefore(zeroedOutStart)
    ) {
      return "The plan dates overlap with an existing plan"; // if the new plan is perpetual and it starts before an existing plan ends
    } else if (
      zeroedOutEnd !== null &&
      existingEnd !== null &&
      !(
        zeroedOutEnd.isSameOrBefore(existingStart) ||
        existingEnd.isSameOrBefore(zeroedOutStart)
      )
    ) {
      return "The plan dates overlap with an existing plan"; // if both plans are fixed duration check for a regular date overlap
    }
  }
  return null;
};

import React, { useState } from "react";

import { Popover, ArrowContainer } from "react-tiny-popover";

import classnames from "classnames";

import styles from "./index.module.less";

import { Button, ButtonProps } from "components/Button";
import { Icon, IconName } from "components/Icon";

type Type = "info" | "success" | "error" | "warning";

type Props = React.PropsWithChildren<{
  position?: "left" | "right" | "top" | "bottom";
  type?: Type;
  content: React.ReactNode;
  onConfirm: () => void;
}>;

function iconForType(type: Type): IconName {
  switch (type) {
    case "success":
      return "checkCircle";
    case "error":
      return "alertCircle";
    case "warning":
      return "alertTriangle";
    case "info":
      return "infoCircle";
  }
}

function themeForType(type: Type): ButtonProps["theme"] {
  switch (type) {
    case "info":
    case "success":
      return "primary";
    case "error":
      return "linkDestructive";
    case "warning":
      return "secondary";
  }
}

export const DeprecatedPopConfirm: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState(false);

  const type = props.type || "info";
  const position = props.position || "left";

  return (
    <Popover
      isOpen={isOpen}
      positions={[position]}
      containerClassName="z-toast"
      onClickOutside={() => setOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          arrowColor="white"
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={8} // Needs to stay in sync with the less
        >
          <div className={styles.popover}>
            <div className={styles.popoverContent}>
              <Icon
                icon={iconForType(type)}
                className={classnames(styles.icon, styles[type])}
                size={16}
              />
              {props.content}
              <div className={styles.buttons}>
                <Button
                  onClick={() => setOpen(false)}
                  text="Cancel"
                  theme="tertiary"
                />
                <Button
                  onClick={props.onConfirm}
                  text="Confirm"
                  theme={themeForType(type)}
                />
              </div>
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <span>
        {React.Children.map(props.children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child as React.ReactElement, {
                onClick: () => setOpen(true),
              })
            : child,
        )}
      </span>
    </Popover>
  );
};

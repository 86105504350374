import Decimal from "decimal.js";
import { toDayjs, Dayjs } from "lib/date";
import {
  DiscountDateFragment,
  DiscountRateFragment,
  DiscountNameFragment,
  DiscountBillingScheduleFragment,
  ScheduledChargeDateFragment,
  ScheduledChargeRateFragment,
  ScheduledChargeNameFragment,
  ScheduledChargeBillingScheduleFragment,
  ProServiceNameFragment,
  ProServiceRateFragment,
} from "./fragments.graphql";
import { ProductListItem } from "../ProductListItem";
import { Contract } from "../Contract";
import { TermRateProps } from "app/pages/Contracts/components/TermRate";
import { ContractResellerRoyaltiesFragment } from "../Contract/fragments.graphql";

type DateFragment =
  | DiscountDateFragment
  | ScheduledChargeDateFragment
  | ProServiceNameFragment
  | Contract.ResellerRoyaltyState
  | ContractResellerRoyaltiesFragment["amendments"][number]["reseller_royalties"][number];
type RateFragment =
  | DiscountRateFragment
  | ScheduledChargeRateFragment
  | ProServiceRateFragment
  | Contract.ResellerRoyaltyState
  | ContractResellerRoyaltiesFragment["amendments"][number]["reseller_royalties"][number];
type NameFragment =
  | DiscountNameFragment
  | ScheduledChargeNameFragment
  | ProServiceNameFragment
  | Contract.ResellerRoyaltyState
  | ContractResellerRoyaltiesFragment["amendments"][number]["reseller_royalties"][number];
type BillingScheduleFragment =
  | DiscountBillingScheduleFragment
  | ScheduledChargeBillingScheduleFragment
  | ProServiceNameFragment
  | Contract.ResellerRoyaltyState
  | ContractResellerRoyaltiesFragment["amendments"][number]["reseller_royalties"][number];

function scheduleDatesAsc(
  term: DiscountDateFragment | ScheduledChargeDateFragment,
) {
  return term.schedule.schedule_items
    .map((item) => toDayjs(item.date))
    .sort((a, b) => a.diff(b));
}

export function getName(term: NameFragment, now: Dayjs): string {
  switch (term.__typename) {
    case "AWSRoyalty":
      return "AWS royalty fee";
    case "AWSProServiceRoyalty":
      return "AWS (professional service) royalty fee";
    case "GCPRoyalty":
      return "GCP royalty fee";
    case "GCPProServiceRoyalty":
      return "GCP (professional service) royalty fee";
    case "AWSRoyaltyUpdate":
      return "AWS royalty fee update";
    case "AWSProServiceRoyaltyUpdate":
      return "AWS (professional service) royalty fee update";
    case "GCPRoyaltyUpdate":
      return "GCP royalty fee update";
    case "GCPProServiceRoyaltyUpdate":
      return "GCP (professional service) royalty fee update";
    case "Discount":
    case "ScheduledCharge":
    case "ProService":
      // TODO: Remove conditional after making `product` required in MRI
      return term.product ? ProductListItem.getName(term.product, now) : "";
  }
}

export function getType(term: NameFragment): string {
  switch (term.__typename) {
    case "AWSRoyalty":
    case "AWSRoyaltyUpdate":
      return "AWS";
    case "AWSProServiceRoyalty":
    case "AWSProServiceRoyaltyUpdate":
      return "AWS_PRO_SERVICE";
    case "GCPRoyalty":
    case "GCPRoyaltyUpdate":
      return "GCP";
    case "GCPProServiceRoyalty":
    case "GCPProServiceRoyaltyUpdate":
      return "GCP_PRO_SERVICE";
    case "Discount":
      return "Scheduled discount";
    case "ScheduledCharge":
      return "Scheduled charge";
    case "ProService":
      return "Professional services and training";
  }
}

export function getBillingSchedule(term: BillingScheduleFragment) {
  switch (term.__typename) {
    case "AWSRoyalty":
    case "GCPRoyalty":
    case "AWSRoyaltyUpdate":
    case "GCPRoyaltyUpdate":
    case "ProService":
      return undefined;

    case "Discount":
    case "ScheduledCharge":
      return term.schedule.schedule_items.map((item) => ({
        id: item.invoice_id,
        date: toDayjs(item.date),
      }));
  }
}

export function getInvoiceCount(term: BillingScheduleFragment): number | null {
  if (term.__typename === "ProService") {
    return null;
  }

  const schedule = getBillingSchedule(term);
  return schedule ? schedule.length : null;
}

export function getTermRateProps(term: RateFragment): TermRateProps {
  switch (term.__typename) {
    case "AWSRoyalty":
    case "AWSProServiceRoyalty":
    case "GCPRoyalty":
    case "GCPProServiceRoyalty":
    case "AWSRoyaltyUpdate":
    case "AWSProServiceRoyaltyUpdate":
    case "GCPRoyaltyUpdate":
    case "GCPProServiceRoyaltyUpdate":
      return {
        type: "royalty",
        fraction:
          "updated_fraction" in term ? term.updated_fraction : term.fraction,
      };

    case "Discount":
    case "ScheduledCharge":
      const total = term.schedule.schedule_items.reduce(
        (acc, item) => acc.add(item.amount),
        new Decimal(0),
      );
      const invoiceCount = term.schedule.schedule_items.length;
      if (!term.schedule.recurring_schedule) {
        return {
          type: term.__typename === "Discount" ? "discount" : "scheduled",
          total,
          invoiceCount,
          creditType: term.schedule.credit_type,
        };
      }

      return {
        type:
          term.__typename === "Discount"
            ? "discount_recurring"
            : "scheduled_recurring",
        total,
        frequency: term.schedule.recurring_schedule.frequency,
        invoiceCount,
        creditType: term.schedule.credit_type,
      };

    case "ProService":
      return {
        type: "pro_service",
        unitPrice: new Decimal(term.unit_price),
      };
  }
}

export function getStartDate(term: DateFragment): Dayjs | null {
  switch (term.__typename) {
    case "AWSRoyalty":
    case "AWSProServiceRoyalty":
    case "GCPRoyalty":
    case "GCPProServiceRoyalty":
      return toDayjs(term.starting_at);

    case "AWSRoyaltyUpdate":
    case "AWSProServiceRoyaltyUpdate":
    case "GCPRoyaltyUpdate":
    case "GCPProServiceRoyaltyUpdate":
      return term.updated_starting_at
        ? toDayjs(term.updated_starting_at)
        : null;

    case "Discount":
    case "ScheduledCharge":
      return scheduleDatesAsc(term).at(0) ?? null;

    case "ProService":
      return null;
  }
}

export function getEndDate(term: DateFragment): Dayjs | null {
  switch (term.__typename) {
    case "AWSRoyalty":
    case "AWSProServiceRoyalty":
    case "GCPRoyalty":
    case "GCPProServiceRoyalty":
      return term.ending_before ? toDayjs(term.ending_before) : null;

    case "AWSRoyaltyUpdate":
    case "AWSProServiceRoyaltyUpdate":
    case "GCPRoyaltyUpdate":
    case "GCPProServiceRoyaltyUpdate":
      return term.updated_ending_before
        ? toDayjs(term.updated_ending_before)
        : null;

    case "Discount":
    case "ScheduledCharge":
      return scheduleDatesAsc(term).at(-1) ?? null;

    case "ProService":
      return null;
  }
}

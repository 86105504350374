import React from "react";
import { Select, Icon } from "design-system";
import { IconButton } from "components/IconButton";
import {
  DropdownIndicatorProps,
  IndicatorsContainerProps,
  SingleValueProps,
  ValueContainerProps,
  components,
} from "react-select";
import classNames from "classnames";

export type BasicPaginationOverListDefaults =
  Partial<BasicPaginationOverListState>;
type BasicPaginationOverListState = {
  current: number;
  count: number;
  size: number;
  options: number[];
};

const BASIC_PAGINATION_OVER_LIST_DEFAULTS: BasicPaginationOverListState = {
  count: 1,
  current: 1,
  size: 10,
  options: [10, 25, 50],
};

export function useBasicPaginationOverList<T>(
  list: T[],
  defaultPagination?: BasicPaginationOverListDefaults,
) {
  const defaults = React.useMemo(
    () =>
      defaultPagination
        ? {
            ...BASIC_PAGINATION_OVER_LIST_DEFAULTS,
            ...defaultPagination,
          }
        : BASIC_PAGINATION_OVER_LIST_DEFAULTS,
    [defaultPagination],
  );

  const [state, setState] =
    React.useState<BasicPaginationOverListState>(defaults);

  return React.useMemo((): { rows: T[]; props: BasicPaginationProps } => {
    return {
      rows: list.slice(
        (state.current - 1) * state.size,
        state.current * state.size,
      ),
      props: {
        pageSize: state.size,
        pageSizeOptions: state.options,
        onNext:
          state.current < state.count
            ? () => {
                setState((s) => ({
                  ...s,
                  current: Math.min(s.current + 1, s.count),
                }));
              }
            : undefined,
        onPrev:
          state.current > 1
            ? () => {
                setState((s) => ({
                  ...s,
                  current: Math.max(s.current - 1, 1),
                }));
              }
            : undefined,
        onPageSizeChange: (newPageSize) => {
          setState((s) => ({
            ...s,
            current: 1,
            size: newPageSize,
            count: Math.ceil(list.length / newPageSize),
          }));
        },
      },
    };
  }, [list, state]);
}

export interface BasicPaginationProps {
  className?: string;
  pageSize?: number;
  pageSizeOptions?: number[];
  onNext?(): void;
  onPrev?(): void;
  onPageSizeChange?(newPageSize: number): void;
}

const ValueContainer: React.FC<ValueContainerProps> = ({
  children,
  ...props
}) => (
  <components.ValueContainer {...props} className="!px-8 !py-4 !leading-[14px]">
    {children}
  </components.ValueContainer>
);

const SingleValue: React.FC<SingleValueProps> = ({ children, ...props }) => (
  <components.SingleValue
    {...props}
    className="!text-xxs font-bold !leading-[14px] !text-gray-500"
  >
    {children}
  </components.SingleValue>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({
  children,
  ...props
}) => (
  <components.DropdownIndicator {...props} className="!m-0 !p-0 !pr-[4px]">
    <Icon icon="caretDown" />
  </components.DropdownIndicator>
);

const IndicatorsContainer: React.FC<IndicatorsContainerProps> = ({
  children,
  ...props
}) => (
  <components.IndicatorsContainer {...props} className="!pr-[2px]">
    {children}
  </components.IndicatorsContainer>
);

export const DeprecatedBasicPagination: React.FC<BasicPaginationProps> = (
  props,
) => {
  return (
    <div className={classNames("flex justify-between py-4", props.className)}>
      <div className="flex items-center gap-4">
        {props.pageSize == null ||
        !props.pageSizeOptions ||
        !props.onPageSizeChange ? null : (
          <>
            <span className="text-xxs font-bold text-gray-500">
              ITEMS PER PAGE:
            </span>
            <Select
              value={String(props.pageSize)}
              placeholder="Rows per page"
              multiSelect={false}
              onChange={(size) => props.onPageSizeChange?.(Number(size))}
              className="block w-[60px]"
              __internalComponentOverrides={{
                ValueContainer,
                IndicatorsContainer,
                DropdownIndicator,
                SingleValue,
              }}
              options={props.pageSizeOptions.map((v) => ({
                label: `${v}`,
                value: String(v),
              }))}
              menuPlacement="top"
            />
          </>
        )}
      </div>
      <div className="flex gap-4">
        <IconButton
          onClick={props.onPrev}
          disabled={!props.onPrev}
          theme="secondary"
          icon="chevronLeft"
          size="sm"
        />
        <IconButton
          onClick={props.onNext}
          disabled={!props.onNext}
          theme="secondary"
          icon="chevronRight"
          size="sm"
        />
      </div>
    </div>
  );
};

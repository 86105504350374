/* c579cd9b18b0c017fcf6f99f0a8320d920b1f572
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PricingUnitsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type PricingUnitsQuery = { __typename?: 'Query', CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const PricingUnitsDocument = gql`
    query PricingUnits($environment_type: EnvironmentTypeEnum_enum!) {
  CreditType(
    where: {archived_at: {_is_null: true}, _or: [{environment_type: {_eq: $environment_type}}, {environment_type: {_is_null: true}}]}
  ) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __usePricingUnitsQuery__
 *
 * To run a query within a React component, call `usePricingUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingUnitsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function usePricingUnitsQuery(baseOptions: Apollo.QueryHookOptions<PricingUnitsQuery, PricingUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingUnitsQuery, PricingUnitsQueryVariables>(PricingUnitsDocument, options);
      }
export function usePricingUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingUnitsQuery, PricingUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingUnitsQuery, PricingUnitsQueryVariables>(PricingUnitsDocument, options);
        }
export type PricingUnitsQueryHookResult = ReturnType<typeof usePricingUnitsQuery>;
export type PricingUnitsLazyQueryHookResult = ReturnType<typeof usePricingUnitsLazyQuery>;
export type PricingUnitsQueryResult = Apollo.QueryResult<PricingUnitsQuery, PricingUnitsQueryVariables>;
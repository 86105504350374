import { EntityMapping } from "components/deprecated/EntityMapping";
import { GetVendorEntityMappingsQuery } from "app/pages/deprecated/GeneralSettings/queries.graphql";
import { ManagedVendorEnum_Enum } from "types/generated-graphql/__types__";

export const allVendors = [
  ManagedVendorEnum_Enum.Stripe,
  ManagedVendorEnum_Enum.Chargebee,
  ManagedVendorEnum_Enum.NetSuite,
];

export const mapVendorEntityData = (
  getVendorEntityMappingsData: GetVendorEntityMappingsQuery,
): Record<ManagedVendorEnum_Enum, Array<EntityMapping>> => {
  return getVendorEntityMappingsData?.vendorMappings.reduce(
    (
      acc: Record<ManagedVendorEnum_Enum, Array<EntityMapping>>,
      m: EntityMapping,
    ) => {
      acc[m.vendor as ManagedVendorEnum_Enum].push(m);
      return acc;
    },
    {
      [ManagedVendorEnum_Enum.Stripe]: [] as Array<EntityMapping>,
      [ManagedVendorEnum_Enum.Chargebee]: [] as Array<EntityMapping>,
      [ManagedVendorEnum_Enum.NetSuite]: [] as Array<EntityMapping>,
    },
  );
};

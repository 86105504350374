import React from "react";

import { DeprecatedCreditInput } from "components/deprecated/Input";
import { USD_CREDIT_TYPE } from "app/lib/credits";

import { Schema } from "../../Schema";
import { useOverrideCtrl } from "./OverrideCtrl";
import { CreditType } from "app/types/credit-types";
import { Select } from "design-system";

const useFlatRateCtrl = useOverrideCtrl.child(Schema.FlatRate, {
  read(parent, creditType: CreditType) {
    const rate = parent.get("rate");
    return rate?.type === "overwrite" && rate.newRate.type === "flat"
      ? {
          ...rate.newRate,
          creditType,
        }
      : undefined;
  },
  write: (child) => ({
    rate: {
      type: "overwrite",
      newRate: child.getUnvalidatedInputs(),
    },
  }),
});

interface Props {
  parent: ReturnType<typeof useOverrideCtrl>;
  creditType?: CreditType;
}

export const OverwriteFlatRate: React.FC<Props> = (props) => {
  const rateCreditType = props.creditType ?? USD_CREDIT_TYPE;
  const ctrl = useFlatRateCtrl(props.parent, rateCreditType);
  return (
    <>
      <DeprecatedCreditInput
        {...ctrl.props.CreditInput("unitPrice", {
          name: "Adjustment",
          tooltip: "Overwrites the price of the product",
          creditType: rateCreditType,
          placeholder: "0.00",
          allowZeroAmounts: true,
        })}
      />
      <Select
        {...ctrl.props.Select("creditType", {
          placeholder: "",
          className: "hidden",
          options: [
            {
              label: rateCreditType.name,
              value: rateCreditType,
            },
          ],
        })}
      />
    </>
  );
};

import React from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";
import { Button } from "components/Button";
import { useArchiveAlertMutation } from "../../queries.graphql";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useNavigate } from "app/lib/useNavigate";

interface ArchiveAlertPopConfirmProps {
  alertId: string;
  alertName: string;
  onClose: () => void;
}

export const ArchiveAlertModal: React.FC<ArchiveAlertPopConfirmProps> = ({
  alertName,
  alertId,
  onClose,
}) => {
  const [archiveAlert, archiveAlertResult] = useArchiveAlertMutation();
  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  return (
    <DeprecatedPopup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={archiveAlertResult.loading}
            disabled={archiveAlertResult.loading}
            onClick={async () => {
              try {
                await archiveAlert({
                  variables: {
                    alert_id: alertId,
                  },
                  update(cache) {
                    cache.evict({ fieldName: "Alert_by_pk" });
                    cache.evict({ fieldName: "alerts" });
                  },
                });
                pushMessage({
                  type: "success",
                  content: `Archived alert ${alertName}`,
                });
                navigate("/connections/alerts");
              } catch (e: any) {
                pushMessage({
                  content: `Failed to archive alert: ${e.message}`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Archive alert"
            theme="linkDestructive"
          />
        </>
      }
      isOpen
      onRequestClose={onClose}
      title="Are you sure you want to archive this alert?"
    >
      <Body level={2}>
        Are you sure you want to archive the <strong>{alertName}</strong> alert?
        Once an alert is archived it will immediately stop monitoring, and will
        no longer appear on the alerts page.
      </Body>
    </DeprecatedPopup>
  );
};

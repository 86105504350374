import { Checkbox } from "design-system";
import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useArchiveContractMutation } from "./data.graphql";
import { reportToSentry } from "app/lib/errors/sentry";

interface ArchiveContractModalProps {
  onSuccess: () => void;
  onCancel: () => void;

  contract_id: string;
  customer_id: string;
}

export const ArchiveContractModal: React.FC<ArchiveContractModalProps> = (
  props,
) => {
  const pushMessage = useSnackbar();
  const [archiveContractRequest, archiveContractResult] =
    useArchiveContractMutation();
  const [voidInvoices, setVoidInvoices] = useState(true);

  const loading = archiveContractResult.loading;
  useEffect(() => {
    if (
      archiveContractResult.data &&
      archiveContractResult.data.archive_contract.success
    ) {
      pushMessage({
        type: "success",
        content: "Contract archived successfully",
      });
      props.onSuccess();
    } else if (
      archiveContractResult.data?.archive_contract.success === false ||
      archiveContractResult.error
    ) {
      pushMessage({
        content: `Failed to archive contract`,
        type: "error",
      });
      reportToSentry(archiveContractResult.error ?? archiveContractResult.data);
      props.onCancel();
    } else if (archiveContractResult.called && !loading) {
      archiveContractResult.reset();
      props.onCancel();
    }
  }, [archiveContractResult]);

  async function onSubmit() {
    await archiveContractRequest({
      variables: {
        archived_at: new Date().toISOString(),
        contract_id: props.contract_id,
        customer_id: props.customer_id,
        void_invoices: voidInvoices,
      },
      update(cache) {
        cache.evict({ id: `Customer:${props.customer_id}` });
        cache.evict({ id: `Contract:${props.contract_id}` });
        cache.gc();
      },
    });
  }

  return (
    <DeprecatedPopup
      isOpen
      onRequestClose={props.onCancel}
      title="Archive contract"
      actions={
        <>
          <Button
            loading={false}
            onClick={props.onCancel}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            text="Archive"
            theme="linkDestructive"
            type="submit"
          />
        </>
      }
    >
      <p className="my-8">
        By archiving this contract, it will be permanently ended along with all
        its terms for this customer. Any draft invoices will be canceled, and
        all scheduled invoices will be voided. You can also choose whether to
        void all finalized invoices.
      </p>
      <p className="my-8">Are you sure you want to archive this contract?</p>
      <Checkbox
        checked={voidInvoices}
        label="Void all finalized invoices"
        onChange={(value) => setVoidInvoices(value)}
      ></Checkbox>
    </DeprecatedPopup>
  );
};

import React from "react";
import { RateCardContext } from "./RateCardContext";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { SectionHeader } from "components/SectionHeader";
import { displayCreditTypeName } from "app/lib/credits";
import { SectionLabel } from "components/SectionLabel";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { RatesTable } from "./RatesTable";

export const Step3: React.FC = () => {
  const { fiatCreditTypes, fiatCreditType, setFiatCreditType, rates } =
    RateCardContext.useContainer();

  const { usageRates, subscriptionRates, compositeRates } = rates;
  const allowFiatCurrencies = useFeatureFlag("contract-currencies", false);

  return (
    <div className="flex flex-col gap-32">
      <div className="flex flex-col gap-24">
        <SectionHeader
          title="Rate your products"
          subtitle="Products are priced on a schedule. Use the “Add a rate change” action to schedule entitlement or pricing changes."
          bottomBorder={false}
        />

        {allowFiatCurrencies && (
          <div className="flex flex-col gap-12">
            <SectionLabel
              title="Default currency"
              subtitle="A rate card is tied to one fiat currency, and can have multiple custom pricing units."
            />
            <div>
              <Dropdown
                selectedOption={displayCreditTypeName(fiatCreditType)}
                label={displayCreditTypeName(fiatCreditType)}
              >
                {fiatCreditTypes.map((creditType) => (
                  <DropdownItem
                    key={creditType.id}
                    label={displayCreditTypeName(creditType)}
                    onClick={(meta) => {
                      const creditType = fiatCreditTypes.find(
                        (c) => c.id === meta.value,
                      );

                      if (creditType) {
                        setFiatCreditType(creditType);
                      }
                    }}
                    value={creditType.id}
                    selected={creditType.id === fiatCreditType.id}
                  />
                ))}
              </Dropdown>
            </div>
          </div>
        )}
      </div>
      {usageRates.length > 0 && (
        <RatesTable
          rates={usageRates}
          title="Usage"
          type="usageRates"
          allowEditCreditConversions={true}
        />
      )}
      {compositeRates.length > 0 && (
        <RatesTable
          rates={compositeRates}
          title="Composite"
          type="compositeRates"
          allowEditCreditConversions={true}
        />
      )}
      {subscriptionRates.length > 0 && (
        <RatesTable
          rates={subscriptionRates}
          title="Subscription"
          type="subscriptionRates"
          allowEditCreditConversions={true}
        />
      )}
    </div>
  );
};

import React from "react";
import Decimal from "decimal.js";
import classNames from "classnames";
import { Headline, Subtitle, Icon } from "design-system";

import { RoundedCurrency } from "app/lib/credits";

import { useNow } from "lib/date";
import { RoundRectList } from "../RoundRectList";
import { InvoicesListItemFragment } from "./fragments.graphql";
import { Invoice } from "../../lib/Invoice";
import { StatusPills } from "app/pages/Customer/tabs/Overview/StatusPills";

interface Props {
  loading: boolean;
  invoices: InvoicesListItemFragment[];
  activeInvoiceId?: string;
  setActiveInvoiceId: (invoiceId?: string) => void;
}

export const InvoiceList: React.FC<Props> = (props) => {
  const now = useNow();

  return (
    <RoundRectList
      loading={props.loading}
      items={props.invoices.map((invoice) => {
        const id = invoice.id;
        const active = id === props.activeInvoiceId;

        return {
          id,
          active,
          onClick: () => props.setActiveInvoiceId(id),
          content: (
            <>
              <div className="mr-4 flex justify-between">
                <div className="flex flex-col">
                  <Headline
                    level={6}
                    className={classNames(
                      {
                        "text-deprecated-primary-500": active,
                      },
                      "text-[14px]",
                    )}
                  >
                    {Invoice.renderDate(invoice, now)}
                  </Headline>
                  <Subtitle level={3} className="mt-8">
                    <Icon
                      icon="cash"
                      className="mr-4 align-middle text-sm text-deprecated-gray-medium"
                    />
                    <RoundedCurrency
                      amount={new Decimal(invoice.total)}
                      creditType={invoice.credit_type}
                    />
                  </Subtitle>
                </div>
                <StatusPills
                  invoice={invoice}
                  stackVertically={true}
                  className="items-end"
                />
              </div>
            </>
          ),
        };
      })}
    />
  );
};

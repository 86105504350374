import React from "react";
import { useParams } from "react-router-dom";

import { useNavigate, NavigateOptions } from "app/lib/useNavigate";

import { useRequiredParam } from "app/lib/routes/params";
import { useCustomerFromRoute } from "../../../lib/Customer";
import { InvoicesLayout } from "../../../components/Invoices";

import {
  CustomerPlanReq,
  useCustomerPlanFromRoute,
} from "../useCustomerPlanFromRoute";
import { ContractCustomerPlanContainer } from "../Container";
import {
  useRecentPlanInvoicesQuery,
  RecentPlanInvoicesQueryHookResult,
} from "./data.graphql";
import { reportToSentry } from "app/lib/errors/sentry";

const getInvoices = (
  req: RecentPlanInvoicesQueryHookResult,
  customerPlanReq: CustomerPlanReq,
) =>
  (req.data?.Customer_by_pk?.mri_invoices.invoices ?? []).filter(
    (invoice) =>
      invoice.__typename === "SeatPurchaseInvoice" ||
      customerPlanReq.id === undefined ||
      ("customer_plan" in invoice &&
        invoice.customer_plan?.id === customerPlanReq.id) ||
      // AdHocPlanInvoice don't have a customer_plan
      (invoice.__typename === "AdHocPlanInvoice" &&
        customerPlanReq.state === "success" &&
        invoice.plan.id === customerPlanReq.customerPlan.planId) ||
      ("correction_record" in invoice &&
        "customer_plan" in invoice.correction_record.original_invoice &&
        invoice.correction_record.original_invoice.customer_plan?.id ===
          customerPlanReq.id),
  );

export const ContractCustomerPlanInvoices: React.FC = () => {
  const navigate = useNavigate();
  const customerId = useRequiredParam("customerId");
  const customerReq = useCustomerFromRoute();
  const customerPlanReq = useCustomerPlanFromRoute();
  const invoicesReq = useRecentPlanInvoicesQuery({ variables: { customerId } });

  const activeInvoiceId = useParams<{ invoiceId: string }>().invoiceId;
  function setActiveInvoiceId(invoiceId?: string, navOpts?: NavigateOptions) {
    navigate(
      `/customers/${customerId}/plans/${customerPlanReq.id}/invoices${
        invoiceId ? `/${invoiceId}` : ""
      }`,
      navOpts,
    );
  }

  React.useEffect(() => {
    const invoices = getInvoices(invoicesReq, customerPlanReq);
    if (invoices.length >= 190) {
      reportToSentry(
        new Error(
          `Plan has >= 190 invoices, consider raising MAX_PAGE on mri_invoices and limit on mri_invoices query`,
        ),
      );
    }
    if (!activeInvoiceId && invoices.length > 0) {
      setActiveInvoiceId(invoices[0].id, { replace: true });
    }
  }, [invoicesReq, activeInvoiceId]);

  return (
    <ContractCustomerPlanContainer
      customerReq={customerReq}
      customerPlanReq={customerPlanReq}
      disableContainerScroll
      content={() => (
        <InvoicesLayout
          type="plan"
          loading={invoicesReq.loading}
          error={invoicesReq.error}
          invoices={getInvoices(invoicesReq, customerPlanReq)}
          activeInvoiceId={activeInvoiceId}
          setActiveInvoiceId={setActiveInvoiceId}
          customerId={customerId}
        />
      )}
    />
  );
};

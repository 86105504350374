import React, { useState } from "react";
import { StripeSettingsToggleProps } from ".";
import { Toggle } from "components/Toggle";

export const DisplaySublineItemQuantitiesToggle: React.FC<
  StripeSettingsToggleProps
> = ({
  isChecked,
  handleToggleOnChange,
  displayLineItems,
  canSaveClientConfig,
  hasContractsEnabled,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <Toggle
      toggled={isCheckedValue}
      label="Always display quantities in sub-line item descriptions"
      onChange={(meta: { toggled: boolean }) =>
        handleToggleOnChange(
          meta.toggled,
          "stripe_invoice_quantity_always_string",
          setIsCheckedValue,
        )
      }
      disabled={
        (!displayLineItems && !hasContractsEnabled) || !canSaveClientConfig
      }
      supportingText="Use if you have non-integer items to display"
    />
  );
};

/* 0151cf57102a6d60fa1b31ea5dc2f6765462b119
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ArchiveUserMutation = { __typename?: 'Mutation', archive_user: { __typename?: 'User', id: string, deprecated_at: string | null } };


export const ArchiveUserDocument = gql`
    mutation ArchiveUser($id: String!) {
  archive_user(id: $id) {
    id
    deprecated_at
  }
}
    `;
export type ArchiveUserMutationFn = Apollo.MutationFunction<ArchiveUserMutation, ArchiveUserMutationVariables>;

/**
 * __useArchiveUserMutation__
 *
 * To run a mutation, you first call `useArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserMutation, { data, loading, error }] = useArchiveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveUserMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveUserMutation, ArchiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveUserMutation, ArchiveUserMutationVariables>(ArchiveUserDocument, options);
      }
export type ArchiveUserMutationHookResult = ReturnType<typeof useArchiveUserMutation>;
export type ArchiveUserMutationResult = Apollo.MutationResult<ArchiveUserMutation>;
export type ArchiveUserMutationOptions = Apollo.BaseMutationOptions<ArchiveUserMutation, ArchiveUserMutationVariables>;
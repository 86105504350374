import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BadgeGroup } from "components/BadgeGroup";
import { SideSheet } from "components/SideSheet";
import { useGetSalesforceCensusProvisionedStateQuery } from "./queries.graphql";
import { EmptyState } from "components/EmptyState";
import { CreateCensusWorkspaceStep } from "./CreateCensusWorkspaceStep";
import { ActivateSalesforceDestinationStep } from "./ActivateSalesforceDestinationStep";
import { DataSyncStep } from "./DataSyncStep";
import { CompleteSetup } from "./CompleteSetup";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";

const SFDC_PACKAGE_VERSION = "v0.2.0.4";
const SFDC_PACKAGE_ID = "04tRh000000acd3IAA";
export const CENSUS_WORKSPACE_URL =
  "https://docs.getcensus.com/misc/security-and-privacy/workspaces-and-access-controls#workspaces";

interface SalesforceOnboardingSideSheetProps {
  onClose: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const SalesforceOnboardingSideSheet: React.FC<
  SalesforceOnboardingSideSheetProps
> = ({ onClose, isOpen }) => {
  const metronomeSalesforcePackage = `Metronome <> Salesforce package ${SFDC_PACKAGE_VERSION}`;
  const { environmentType } = useEnvironment();
  const downloadSubdomain =
    environmentType === EnvironmentTypeEnum_Enum.Production ? "login" : "test";
  const downloadLink = `https://${downloadSubdomain}.salesforce.com/packaging/installPackage.apexp?p0=${SFDC_PACKAGE_ID}`;
  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false);
  const [isSalesforceDestinationLinked, setIsSalesforceDestinationLinked] =
    useState(false);
  const [syncOnlyLinkedAccounts, setSyncOnlyLinkedAccounts] = useState<
    undefined | boolean
  >(undefined);

  const {
    data: salesforceCensusProvisionedStateData,
    loading: salesforceCensusProvisionedStateLoading,
    error: salesforceCensusProvisionedStateQueryError,
  } = useGetSalesforceCensusProvisionedStateQuery();

  useEffect(() => {
    if (!!salesforceCensusProvisionedStateData?.get_census_provisioned_state) {
      setIsWorkspaceCreated(true);
    }
    if (
      salesforceCensusProvisionedStateData?.get_census_provisioned_state
        ?.destination_configured
    ) {
      setIsSalesforceDestinationLinked(true);
    }
  }, [salesforceCensusProvisionedStateData]);

  if (salesforceCensusProvisionedStateLoading) {
    return null;
  }

  return (
    <SideSheet
      onClose={() => onClose(false)}
      title="Connect Salesforce"
      supportingText="Automatically sync data about customer's contracts, invoice history, commits/credits, and metadata such as ingest aliases."
      isOpen={isOpen}
    >
      {salesforceCensusProvisionedStateQueryError ? (
        <EmptyState
          mainText="We ran into an issue loading this page. If the problem persists, please contact your Metronome representative for support."
          supportingText="Please try again."
          icon="data"
        />
      ) : (
        <>
          <div className="mb-3xl">
            <h4 className="text-sm font-semibold text-black">Get started</h4>
            <div className="text-sm text-gray-600">
              Setting up Salesforce will require installing a connector package
              and setting up a{" "}
              <Link to={CENSUS_WORKSPACE_URL} target="_blank">
                <u>Census workspace</u>
              </Link>{" "}
              to manage the integration.
            </div>
          </div>
          <div className="mb-3xl">
            <h4 className="mb-lg text-sm font-semibold text-black">
              1. Install the connector package
            </h4>
            <div className="mb-lg text-sm text-gray-600">
              Start by installing the{" "}
              <Link to={downloadLink} target="_blank">
                <u>Metronome &lt;&gt; Salesforce package</u>
              </Link>
              . Once installed, come back here to set up your Census workspace.
            </div>
            <BadgeGroup
              badgeLabel={metronomeSalesforcePackage}
              mainLabel="Install now"
              icon="arrowNarrowRight"
              onClick={() => window.open(downloadLink, "_blank")}
            />
          </div>
          <CreateCensusWorkspaceStep
            isWorkspaceCreated={isWorkspaceCreated}
            setIsWorkspaceCreated={setIsWorkspaceCreated}
          />
          <ActivateSalesforceDestinationStep
            isWorkspaceCreated={isWorkspaceCreated}
            isSalesforceDestinationLinked={isSalesforceDestinationLinked}
            setIsSalesforceDestinationLinked={setIsSalesforceDestinationLinked}
          />
          <DataSyncStep
            isWorkspaceCreated={isWorkspaceCreated}
            isSalesforceDestinationLinked={isSalesforceDestinationLinked}
            setSyncOnlyLinkedAccounts={setSyncOnlyLinkedAccounts}
          />
          <CompleteSetup
            isSalesforceDestinationLinked={isSalesforceDestinationLinked}
            isWorkspaceCreated={isWorkspaceCreated}
            syncOnlyLinkedAccounts={syncOnlyLinkedAccounts}
            onSuccess={() => onClose(false)}
          />
        </>
      )}
    </SideSheet>
  );
};

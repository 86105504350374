import React, { useState } from "react";
import { Popover } from "react-tiny-popover-next";
import { DropdownItem } from "components/Dropdown";
import { Icon } from "components/Icon";
import { TagsInput } from "components/Input/TagsInput";
import { TextInput } from "components/Input";
import { IconButton } from "components/IconButton";
import { Toggle } from "components/Toggle";
import { Tooltip } from "components/Tooltip";

type PropertyConditionInputDropdownProps = {
  propertyValue: string | null;
  onPropertyChange: (value: string) => void;
  value: string[];
  onChange: (value: string[]) => void;
  filterType: "in" | "not_in" | "exists" | "does_not_exist";
  onFilterTypeChange: (
    filterType: "in" | "not_in" | "exists" | "does_not_exist",
  ) => void;
  onDelete: () => void;
  placeholder?: string;
  disabled?: boolean;
  isOptional: boolean;
  onOptionalChange: (isOptional: boolean) => void;
};

export const PropertyConditionInputDropdown: React.FC<
  PropertyConditionInputDropdownProps
> = ({
  propertyValue,
  onPropertyChange,
  value,
  onChange,
  filterType,
  onFilterTypeChange,
  onDelete,
  placeholder,
  disabled,
  isOptional,
  onOptionalChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterTypeText = {
    in: "In",
    not_in: "Not in",
    exists: "Exists",
    does_not_exist: "Does not exist",
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-4 space-x-4">
        <div className="w-[300px]">
          <TextInput
            label="Property"
            value={propertyValue ?? ""}
            onChange={({ value }) => onPropertyChange(value)}
            fullWidth
          />
        </div>
        <div className="flex-1">
          <Popover
            positions={["bottom"]}
            isOpen={isOpen}
            onClickOutside={() => setIsOpen(false)}
            align="end"
            content={
              <div className="mt-[5px] max-h-[450px] min-h-[50px] overflow-y-scroll rounded-md border border-gray-200 bg-white shadow-lg">
                <div className="flex flex-col overflow-y-auto">
                  {Object.entries(filterTypeText).map(([type, label]) => (
                    <DropdownItem
                      key={type}
                      value={type}
                      label={label}
                      selected={filterType === type}
                      onClick={() => {
                        onFilterTypeChange(type as keyof typeof filterTypeText);
                        setIsOpen(false);
                      }}
                    />
                  ))}
                </div>
              </div>
            }
          >
            <div>
              <label className="mb-sm text-sm text-black">Value</label>
              <div className="relative rounded-md bg-white shadow-xs">
                <div>
                  <TagsInput
                    value={value}
                    onChange={({ value }) => onChange(value)}
                    placeholder={placeholder}
                    disabled={
                      disabled ||
                      filterType === "exists" ||
                      filterType === "does_not_exist"
                    }
                    fullWidth
                  />
                </div>
                <div
                  className="px-3 absolute inset-y-0 right-[10px] flex cursor-pointer items-center"
                  onClick={() => setIsOpen((prev) => !prev)}
                >
                  <span className="mr-8">{filterTypeText[filterType]}</span>
                  <Icon
                    icon={isOpen ? "chevronUp" : "chevronDown"}
                    size={16}
                    className="text-gray-500"
                  />
                </div>
              </div>
            </div>
          </Popover>
        </div>
        <div className="pb-2 flex items-center">
          <IconButton
            icon="trash01"
            size="md"
            theme="secondary"
            onClick={onDelete}
          />
        </div>
      </div>
      <div className="mt-12 flex justify-start">
        <Tooltip subLabel="Optional values are only allowed for IN / NOT IN filters">
          <Toggle
            label="Optional"
            toggled={isOptional}
            onChange={({ toggled }) => onOptionalChange(toggled)}
            disabled={
              filterType === "exists" || filterType === "does_not_exist"
            }
          />
        </Tooltip>
      </div>
    </div>
  );
};

PropertyConditionInputDropdown.displayName = "PropertyConditionInputDropdown";

import React from "react";
import { Tooltip } from "components/Tooltip";
import { Icon } from "components/Icon";

export const DeprecatedClientResourceTooltip: React.FC = () => {
  return (
    <div className="inline-flex items-center">
      Metronome Account
      <Tooltip label="Actions that create top level resources, such as customers, metrics, etc are recorded as actions taken on the Metronome account.">
        <Icon icon="helpCircle" size={14} className="ml-sm" />
      </Tooltip>
    </div>
  );
};

import React, { useState } from "react";
import { Button } from "components/Button";
import { Takeover } from "components/Takeover";
import { useNavigate } from "app/lib/useNavigate";
import { Checkbox } from "components/Checkbox";
import { IntroEmptyState } from "components/IntroEmptyState";

export const shouldShowPreviewPage = () =>
  localStorage.getItem("show-product-create-preview") === null;

interface ProductCreateLandingPageProps {
  setShowPreviewPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductCreateLandingPage: React.FC<
  ProductCreateLandingPageProps
> = ({ setShowPreviewPage }) => {
  const navigate = useNavigate();

  const [dontShowPreviewAgainClicked, setDontShowPreviewAgainClicked] =
    useState(false);

  const handleContinueClick = () => {
    if (dontShowPreviewAgainClicked)
      localStorage.setItem("show-product-create-preview", "false");
    setShowPreviewPage(false);
  };

  return (
    <Takeover
      maxContainerWidth="max-w-[616px]"
      isOpen={true}
      onClose={() => navigate("/offering/products")}
      title="Create a product"
      footerTrailingButtons={[
        <Checkbox
          size="sm"
          className="mr-[12px] flex flex-row items-center"
          label="Don't show again"
          checked={dontShowPreviewAgainClicked}
          onClick={(meta: { checked: boolean }) =>
            setDontShowPreviewAgainClicked(meta.checked)
          }
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleContinueClick}
          text="Continue"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            navigate("/offering/products");
          }}
          theme="secondary"
        />
      }
    >
      <IntroEmptyState
        icon="cube03"
        mainText="Welcome to the Metronome product creator"
        supportingText="A product is a line item on a customer’s invoice. After creating a product or a set of products, you can add them to a rate card to set their prices."
        bullets={[
          {
            mainText: "Select a product type",
            supportingText:
              "Products can be usage-based, subscriptions, composite, or fixed.",
          },
          {
            mainText: "Select a billable metric for usage products",
            supportingText:
              "Usage products are priced on events that match it. Event matching and aggregation is determined by the product’s underlying billable metric.",
          },
          {
            mainText: "Select applicable products for a composite product",
            supportingText:
              "Composite products are percent-based. The percent is based on the total cost of applicable usage and subscription products.",
          },
        ]}
      />
    </Takeover>
  );
};

import classNames from "classnames";
import Decimal from "decimal.js";
import React, { useState } from "react";

import { Icon } from "design-system";
import { Button } from "components/Button";
import { getRowType } from "components/deprecated/PlanPreview/row";
import { Tooltip } from "design-system";
import { Body, Subtitle } from "design-system";
import {
  BlockConfiguration,
  CompositeCharge,
  FlatFee,
  Price,
  PricedPricingFactor,
  PricedProduct,
} from "app/lib/plans/types";
import { CreditType } from "app/types/credit-types";
import {
  ChargeTypeEnum_Enum,
  CollectionScheduleEnum_Enum,
  SeatPrice,
} from "types/generated-graphql/__types__";
import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import {
  CompositeChargeRateCell,
  FlatFeeRateCell,
  SeatRateCell,
  TieredFeeRateCellV2,
  UsageFeeRateCell,
} from "../FeeRateCell";
import styles from "./index.module.less";
import {
  CompositeChargeOptionsButton,
  FlatFeeChargeOptionsButton,
  UsageChargeOptionsButton,
} from "../ChargeOptionsButton";
import { BlockPricingModal } from "../BlockPricingModal";
import { generateFeeName } from "app/lib/plans/blockConfiguration";
import { TierResetFrequencyModal } from "../TierResetFrequencyModal";

interface PriceProductTableRowProps {
  pricedProduct: PricedProduct;
  pricingFactor: CreatePlanDataQuery["products"][0]["ProductPricingFactors"][0];
  isNewProduct: boolean;
  rampStart: number;
  tierResetFrequency?: number;
  onChangeUsageTiers: (
    newTiers: Price[],
    volumePricing?: boolean,
    tierResetFrequency?: number,
  ) => void;
  onChangeSeatPrice: (newSeatPrice: SeatPrice[]) => void;
  onChangeFlatFee: (flatFee: FlatFee[]) => void;
  onEditAdvanceCharge: (existingFees: FlatFee[]) => void;
  onChangeCompositeCharge: (compositeCharge: CompositeCharge[]) => void;
  onEditCompositeChargePane: (compositeCharge: CompositeCharge[]) => void;
  creditType: CreditType | undefined;
  hasAdvancedChargeInFlight: boolean;
  hasCustomPricing?: boolean;
  blockPricing?: BlockConfiguration;
  onChangeBlockPricing: (blockPricing: BlockConfiguration | undefined) => void;
  hasVolumePricing?: boolean;
  hasRamps?: boolean;
  editing?: boolean;
}

export const PriceProductTableRow: React.FC<PriceProductTableRowProps> = (
  props,
) => {
  const newFlatFee: FlatFee = {
    collectionSchedule: CollectionScheduleEnum_Enum.Arrears,
  };

  const newSeatPrice: SeatPrice = {
    id: "",
    is_prorated: true,
    metric_minimum: "0",
    initial_quantity: "",
    value: "",
  };

  const fee: PricedPricingFactor = props.pricedProduct.pricingFactors.find(
    (pppf) =>
      pppf.pricingFactorId === props.pricingFactor.id &&
      pppf.startPeriod === props.rampStart,
  ) ?? {
    pricingFactorId: props.pricingFactor.id,
    startPeriod: props.rampStart,
    tierResetFrequency: props.tierResetFrequency,
    prices: undefined,
    chargeType: props.pricingFactor.charge_type_enum,
    seatPrices:
      props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Seat
        ? [newSeatPrice]
        : undefined,
    flatFees:
      props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Flat
        ? [newFlatFee]
        : undefined,
    skipRamp: undefined,
    ...props.pricedProduct.pricingFactors.find(
      (pppf) =>
        pppf.pricingFactorId === props.pricingFactor.id &&
        pppf.startPeriod === 0,
    ),
  };

  const usageTiers = fee.prices;
  const flatFees = fee.flatFees;
  const seatPrices = fee.seatPrices;
  const compositeCharge = fee.compositeCharge;
  const isUsageTiered =
    !!(usageTiers && usageTiers.length > 1) || !!props.hasVolumePricing;
  const isFlatTiered = !!(flatFees && flatFees.length > 1);

  const [blockPricingModalIsOpen, setBlockPricingModalIsOpen] =
    useState<boolean>(false);

  const [tierResetFrequencyModalIsOpen, setTierResetFrequencyModalIsOpen] =
    useState<boolean>(false);

  return (
    <>
      {blockPricingModalIsOpen ? (
        <BlockPricingModal
          onRequestClose={(blockConfiguration?: BlockConfiguration) => {
            setBlockPricingModalIsOpen(false);
            if (blockConfiguration) {
              props.onChangeBlockPricing(blockConfiguration);
            }
          }}
          initialBlockPricing={props.blockPricing}
        />
      ) : null}
      {tierResetFrequencyModalIsOpen ? (
        <TierResetFrequencyModal
          initialValue={fee.tierResetFrequency}
          onRequestClose={(resetFrequency) => {
            setTierResetFrequencyModalIsOpen(false);
            props.onChangeUsageTiers(usageTiers ?? [], false, resetFrequency);
          }}
        />
      ) : null}
      <div className={styles.cell}>
        <Body level={2}>
          {generateFeeName(
            props.pricingFactor.name,
            props.blockPricing,
            fee.tierResetFrequency,
          )}
        </Body>
      </div>
      <div className={classNames(styles.cell, styles.feeType)}>
        <Body level={2}>{getRowType(fee)}</Body>
      </div>
      <div className={styles.cell}>
        {(isUsageTiered || isFlatTiered) && (
          <div className={classNames(styles.cell)}>
            <Icon icon="list" />
            <span className="px-4 text-xs">Tiering:</span>
          </div>
        )}
        {fee &&
          props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Flat && (
            <FlatFeeRateCell
              creditType={
                props.creditType || {
                  id: "",
                  name: "",
                  client_id: null,
                  environment_type: null,
                }
              }
              isTiered={isFlatTiered}
              value={flatFees?.[0]?.value ?? ""}
              setValue={(v) => {
                const newValueAndMetricMinimum = {
                  value: v !== null ? v.toString() : undefined,
                  metricMinimum: 0,
                };
                const newFees = flatFees
                  ? flatFees.map((ff) => {
                      return { ...ff, ...newValueAndMetricMinimum };
                    })
                  : [{ ...newValueAndMetricMinimum }];
                props.onChangeFlatFee(newFees);
              }}
              quantity={flatFees?.[0]?.quantity}
              setQuantity={(v: Decimal | null) => {
                const newQuantity = {
                  quantity: v ? new Decimal(v) : undefined,
                };
                const newFees = flatFees
                  ? flatFees.map((ff) => {
                      return { ...ff, ...newQuantity };
                    })
                  : [{ ...newQuantity }];
                props.onChangeFlatFee(newFees);
              }}
              disabled={!props.creditType || props.hasAdvancedChargeInFlight}
              hasCustomPricing={props.hasCustomPricing}
              isNewProduct={props.isNewProduct}
              rampStart={props.rampStart}
            />
          )}
        {fee &&
          props.pricingFactor.charge_type_enum ===
            ChargeTypeEnum_Enum.Usage && (
            <UsageFeeRateCell
              creditType={
                props.creditType || {
                  id: "",
                  name: "",
                  client_id: null,
                  environment_type: null,
                }
              }
              value={usageTiers?.[0]?.value || ""}
              setValue={(v) => {
                props.onChangeUsageTiers([
                  {
                    value: v !== null ? v.toString() : undefined,
                    metricMinimum: 0,
                  },
                ]);
              }}
              canBeTiered={!props.hasCustomPricing}
              isTiered={isUsageTiered}
              disabled={!props.creditType}
            />
          )}
        {fee &&
          props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Seat && (
            <SeatRateCell
              creditType={
                props.creditType || {
                  id: "",
                  name: "",
                  client_id: null,
                  environment_type: null,
                }
              }
              value={seatPrices?.[0]?.value ?? ""}
              setValue={(v) => {
                const newValue = {
                  value: v !== null ? v.toString() : "",
                };
                const newSeatPrices = seatPrices
                  ? seatPrices.map((seatPrice) => {
                      return { ...seatPrice, ...newValue };
                    })
                  : [{ ...newSeatPrice, ...newValue }];
                props.onChangeSeatPrice(newSeatPrices);
              }}
              initialQuantity={
                seatPrices?.[0]?.initial_quantity
                  ? new Decimal(seatPrices?.[0]?.initial_quantity)
                  : undefined
              }
              setInitialQuantity={(v: Decimal | null) => {
                const newQuantity = {
                  initial_quantity: v ? new Decimal(v).toString() : "",
                };
                const newSeatPrices = seatPrices
                  ? seatPrices.map((seatPrice) => {
                      return { ...seatPrice, ...newQuantity };
                    })
                  : [{ ...newSeatPrice, ...newQuantity }];
                props.onChangeSeatPrice(newSeatPrices);
              }}
              disabled={!props.creditType || props.hasAdvancedChargeInFlight}
              hasCustomPricing={props.hasCustomPricing}
              isNewProduct={props.isNewProduct}
              rampStart={props.rampStart}
            />
          )}
        {fee &&
          props.pricingFactor.charge_type_enum ===
            ChargeTypeEnum_Enum.Composite && (
            <CompositeChargeRateCell
              value={
                compositeCharge?.[0]?.value
                  ? new Decimal(compositeCharge?.[0]?.value)
                  : undefined
              }
              setValue={(v) => {
                const newValueAndCompositeMinimum = {
                  value: v?.toString(),
                  compositeMinimum: 0,
                };
                const newCharge = compositeCharge
                  ? compositeCharge.map((cc) => ({
                      ...cc,
                      ...newValueAndCompositeMinimum,
                      chargeType: ChargeTypeEnum_Enum.Composite,
                    }))
                  : [newValueAndCompositeMinimum];
                props.onChangeCompositeCharge(newCharge);
              }}
              quantity={compositeCharge?.[0]?.quantity}
              setQuantity={(v: Decimal | null) => {
                const newQuantity = {
                  quantity: v == null ? undefined : v,
                };
                const newCharge = compositeCharge
                  ? compositeCharge.map((cc) => ({
                      ...cc,
                      ...newQuantity,
                    }))
                  : [{ ...newQuantity }];
                props.onChangeCompositeCharge(newCharge);
              }}
              hasCustomPricing={props.hasCustomPricing}
              isNewProduct={props.isNewProduct}
              disabled={!props.creditType}
              type={compositeCharge ? compositeCharge[0].type : undefined}
              creditType={props.creditType}
            />
          )}
      </div>
      <div className={classNames(styles.cell)}>
        {props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Flat && (
          <FlatFeeChargeOptionsButton
            collectionSchedule={
              flatFees
                ? flatFees?.[0]?.collectionSchedule
                : CollectionScheduleEnum_Enum.Arrears
            }
            canBeTiered={!props.hasCustomPricing}
            isTiered={isFlatTiered}
            setTiered={(tiered) => {
              props.onChangeFlatFee([
                {
                  ...flatFees?.[0],
                  metricMinimum: 0,
                },
                ...(tiered
                  ? [{ ...flatFees?.[0], value: undefined, metricMinimum: 1 }]
                  : []),
              ]);
            }}
            canBeAdvanced={!props.hasCustomPricing}
            inFlightAdvancedCharge={props.hasAdvancedChargeInFlight}
            editAdvanceCharge={() => props.onEditAdvanceCharge(flatFees ?? [])}
            isProrated={!!flatFees?.[0]?.isProrated}
            setProrated={(prorated) => {
              const newProrated = { isProrated: prorated };
              const newFees = flatFees?.map((ff) => {
                return { ...ff, ...newProrated };
              }) ?? [{ ...newFlatFee, ...newProrated }];
              props.onChangeFlatFee(newFees);
            }}
            rampStart={props.rampStart}
            disabled={!props.creditType}
          />
        )}

        {props.pricingFactor.charge_type_enum === ChargeTypeEnum_Enum.Usage && (
          <UsageChargeOptionsButton
            isTiered={isUsageTiered && !props.hasVolumePricing}
            setTiered={(tiered) =>
              props.onChangeUsageTiers(
                tiered && usageTiers?.length && usageTiers.length > 1
                  ? usageTiers
                  : [
                      {
                        value: usageTiers?.[0]?.value,
                        metricMinimum: 0,
                      },
                      ...(tiered
                        ? [{ value: undefined, metricMinimum: 1 }]
                        : []),
                    ],
                false,
              )
            }
            hasCustomPricing={props.hasCustomPricing}
            openBlockPricingModal={() => setBlockPricingModalIsOpen(true)}
            hasBlockPricing={!!props.blockPricing}
            removeBlockPricing={() => props.onChangeBlockPricing(undefined)}
            hasVolumePricing={!!props.hasVolumePricing}
            setVolumePricing={(volumePricing) =>
              props.onChangeUsageTiers(
                volumePricing && usageTiers?.length && usageTiers.length > 1
                  ? usageTiers
                  : [
                      {
                        value: usageTiers?.[0]?.value,
                        metricMinimum: 0,
                      },
                      ...(volumePricing
                        ? [{ value: undefined, metricMinimum: 1 }]
                        : []),
                    ],
                volumePricing,
              )
            }
            openTierResetFrequencyModal={() => {
              setTierResetFrequencyModalIsOpen(true);
            }}
            disabled={!props.creditType}
            hasRamps={props.hasRamps}
            editing={props.editing}
          />
        )}

        {props.pricingFactor.charge_type_enum ===
          ChargeTypeEnum_Enum.Composite && (
          <Tooltip
            disabled={!props.creditType}
            content={
              compositeCharge?.[0]?.pricingFactors?.length ? (
                <>
                  Selected charges:{" "}
                  {compositeCharge[0].pricingFactors.map((ppf) => (
                    <li className={styles.chargeListTooltip} key={ppf.id}>
                      {ppf.name}
                    </li>
                  ))}
                </>
              ) : (
                "No charges selected"
              )
            }
          >
            <CompositeChargeOptionsButton
              openCompositeChargeModal={(type) =>
                props.onEditCompositeChargePane(
                  compositeCharge?.map((cc) => ({
                    ...cc,
                    type,
                    value: type !== cc.type ? undefined : cc.value,
                  })) ?? [
                    {
                      quantity: new Decimal(0),
                      compositeMinimum: 0,
                      value: "0",
                      pricingFactors: [],
                      type,
                    },
                  ],
                )
              }
              disabled={!props.creditType}
            />
          </Tooltip>
        )}
      </div>
      <div className={classNames(styles.cell)} />
      {isFlatTiered && (
        <>
          {flatFees?.map((tier, tierIndex) => {
            const nextMetricMinimum =
              tierIndex < flatFees?.length - 1
                ? flatFees?.[tierIndex + 1].metricMinimum
                : undefined;
            return (
              <React.Fragment key={tierIndex}>
                <div className={classNames(styles.cell, styles.tierCell)}>
                  <Subtitle level={4} className={styles.tierText}>{`Tier ${
                    tierIndex + 1
                  } (> ${(
                    tier.metricMinimum ?? 0
                  ).toLocaleString()})`}</Subtitle>
                </div>
                <div className={classNames(styles.cell, styles.tierCell)} />
                <div className={classNames(styles.cell, styles.tierCell)}>
                  <TieredFeeRateCellV2
                    canEditNextMetricMinimum={!props.hasCustomPricing}
                    metricMinimum={tier.metricMinimum ?? 0}
                    nextMetricMinimum={nextMetricMinimum}
                    setNextMetricMinimum={(v) => {
                      props.onChangeFlatFee([
                        ...flatFees.slice(0, tierIndex + 1),
                        tierIndex + 1 < flatFees.length
                          ? {
                              ...flatFees[tierIndex + 1],
                              metricMinimum: v !== null ? Number(v) : undefined,
                            }
                          : {},
                        ...flatFees.slice(tierIndex + 2),
                      ]);
                    }}
                    price={tier.value ?? ""}
                    setPrice={(v) => {
                      props.onChangeFlatFee([
                        ...flatFees.slice(0, tierIndex),
                        {
                          ...tier,
                          value: v !== null ? v.toString() : undefined,
                        },
                        ...flatFees.slice(tierIndex + 1),
                      ]);
                    }}
                    creditType={
                      props.creditType ?? {
                        id: "",
                        name: "",
                        client_id: null,
                        environment_type: null,
                      }
                    }
                    disabled={!props.creditType}
                    tierIndex={tierIndex}
                    numTiers={flatFees?.length}
                    removeTier={() => {
                      props.onChangeFlatFee([
                        ...flatFees.slice(0, tierIndex),
                        ...flatFees.slice(tierIndex + 1),
                      ]);
                    }}
                  />
                  {tierIndex + 1 === flatFees?.length && (
                    <div className={styles.addTier}>
                      <Tooltip
                        content="Customers on this plan have price adjustments enabled. You may not remove from or add to tiers on this plan."
                        disabled={!props.hasCustomPricing}
                      >
                        <Button
                          disabled={props.hasCustomPricing}
                          onClick={() => {
                            props.onChangeFlatFee([
                              ...flatFees,
                              {
                                ...flatFees?.[0],
                                value: undefined,
                                metricMinimum:
                                  flatFees.length > 0
                                    ? (flatFees[flatFees.length - 1]
                                        ?.metricMinimum ?? 0) + 1
                                    : 0,
                              },
                            ]);
                          }}
                          text="Add tier"
                          theme="secondary"
                          leadingIcon="plus"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className={classNames(styles.cell, styles.tierCell)} />
                <div className={classNames(styles.cell, styles.tierCell)} />
              </React.Fragment>
            );
          })}
        </>
      )}
      {isUsageTiered && (
        <>
          {usageTiers?.map((tier, tierIndex) => {
            const nextMetricMinimum =
              tierIndex < usageTiers?.length - 1
                ? usageTiers?.[tierIndex + 1].metricMinimum
                : undefined;
            return (
              <React.Fragment
                key={tierIndex.toString() + (tier.metricMinimum ?? "unknown")}
              >
                <div className={classNames(styles.cell, styles.tierCell)}>
                  <Subtitle level={4} className={styles.tierText}>{`Tier ${
                    tierIndex + 1
                  } (> ${(
                    tier.metricMinimum ?? 0
                  ).toLocaleString()})`}</Subtitle>
                </div>
                <div className={classNames(styles.cell, styles.tierCell)} />
                <div
                  className={classNames(
                    styles.cell,
                    styles.tierCell,
                    "flex flex-col",
                  )}
                >
                  <TieredFeeRateCellV2
                    canEditNextMetricMinimum={!props.hasCustomPricing}
                    metricMinimum={tier.metricMinimum ?? 0}
                    nextMetricMinimum={nextMetricMinimum}
                    setNextMetricMinimum={(v) => {
                      props.onChangeUsageTiers([
                        ...usageTiers.slice(0, tierIndex + 1),
                        tierIndex + 1 < usageTiers.length
                          ? {
                              ...tier,
                              metricMinimum: v === null ? undefined : Number(v),
                            }
                          : {},
                        ...usageTiers.slice(tierIndex + 2),
                      ]);
                    }}
                    price={tier.value ?? ""}
                    setPrice={(v) => {
                      props.onChangeUsageTiers([
                        ...usageTiers.slice(0, tierIndex),
                        {
                          ...tier,
                          value: v !== null ? v.toString() : undefined,
                        },
                        ...usageTiers.slice(tierIndex + 1),
                      ]);
                    }}
                    creditType={
                      props.creditType ?? {
                        id: "",
                        name: "",
                        client_id: null,
                        environment_type: null,
                      }
                    }
                    disabled={!props.creditType}
                    tierIndex={tierIndex}
                    numTiers={usageTiers?.length}
                    removeTier={() => {
                      props.onChangeUsageTiers([
                        ...usageTiers.slice(0, tierIndex),
                        ...usageTiers.slice(tierIndex + 1),
                      ]);
                    }}
                  />
                  {tierIndex + 1 === usageTiers.length && (
                    <div className={styles.addTier}>
                      <Tooltip
                        content="Customers on this plan have price adjustments enabled. You may not remove from or add to tiers on this plan."
                        disabled={!props.hasCustomPricing}
                      >
                        <Button
                          disabled={props.hasCustomPricing}
                          onClick={() => {
                            props.onChangeUsageTiers([
                              ...usageTiers,
                              {
                                value: undefined,
                                metricMinimum:
                                  usageTiers.length > 0
                                    ? (usageTiers[usageTiers.length - 1]
                                        ?.metricMinimum ?? 0) + 1
                                    : 0,
                              },
                            ]);
                          }}
                          text="Add tier"
                          theme="secondary"
                          leadingIcon="plus"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className={classNames(styles.cell, styles.tierCell)} />
                <div className={classNames(styles.cell, styles.tierCell)} />
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

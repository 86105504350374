import React from "react";
import {
  DeprecatedLineItem,
  NonContractLineItem,
} from "components/deprecated/Invoice/components/LineItem";
import {
  groupLineItemsByProduct,
  extractUniqueProducts,
  extractGroupedProductChargeLineItems,
} from "components/deprecated/Invoice/lib/groupedProductChargeLineItemUtils";
import { DeprecatedGroupedProductChargeLineItems } from "components/deprecated/Invoice/components/LineItem/lineItems/GroupedProductChargeLineItems";

export const DeprecatedNonContractLineItems: React.FC<{
  lineItems: NonContractLineItem[];
  invoiceId: string;
  showChargesWithZeroUsage: boolean;
}> = (props) => {
  const { groupedProductChargeLineItems, otherLineItems } =
    extractGroupedProductChargeLineItems(props.lineItems);
  const products = extractUniqueProducts(groupedProductChargeLineItems);
  const groupedProductChargeLineItemsByProductId = groupLineItemsByProduct(
    groupedProductChargeLineItems,
  );

  return (
    <>
      {products.map((product, i) => {
        return (
          <DeprecatedGroupedProductChargeLineItems
            invoiceId={props.invoiceId}
            key={`${product.id}-${i}`}
            product={product}
            lineItems={
              groupedProductChargeLineItemsByProductId.get(product.id) ?? []
            }
            showChargesWithZeroUsage={props.showChargesWithZeroUsage}
          />
        );
      })}
      {otherLineItems.map((lineItem, i) => {
        return (
          <DeprecatedLineItem
            invoiceId={props.invoiceId}
            key={i}
            lineItem={lineItem}
            showChargesWithZeroUsage={props.showChargesWithZeroUsage}
          />
        );
      })}
    </>
  );
};

import React, { useEffect, useState } from "react";

import {
  GetBillingProviderCustomersDocument,
  MriInvoiceFragment,
  SendInvoiceToStripeDocument,
  useGetBillingProviderCustomersQuery,
  useGetInvoiceQuery,
  useSendInvoiceToStripeMutation,
} from "./queries.graphql";

import { dayjs } from "lib/dayjs";
import { useNow } from "lib/date";

import { Headline, Input, Toggle } from "design-system";
import { IconButton } from "components/IconButton";
import { Invoice as InvoiceType } from "./types";

import { useSnackbar } from "components/deprecated/Snackbar";
import { useSearcher } from "app/lib/search/useSearcher";
import { DeprecatedEmbeddableDashboardStatusPills } from "./components/StatusPills";
import { DeprecatedInvoiceTimeline } from "./components/Timeline";
import {
  DeprecatedContractLineItems,
  extractContractLineItems,
} from "./components/LineItem";
import { DeprecatedTotals } from "./components/Totals";
import { EmptyState } from "components/EmptyState";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";
import DeprecatedFinalizeInvoiceModal from "./components/FinalizeInvoiceModal";
import DeprecatedVoidInvoiceModal from "./components/VoidInvoiceModal";
import { DeprecatedAddOneTimeChargeModal } from "./components/AddOneTimeChargeModal";
import { downloadCSV } from "./lib/downloadCSV";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { VoidInvoiceDocument } from "./components/VoidInvoiceModal/voidInvoice.graphql";
import { FinalizeInvoicesDocument } from "./components/FinalizeInvoiceModal/finalizeInvoice.graphql";
import { AddOneTimeChargeDocument } from "./components/AddOneTimeChargeModal/addOneTimeCharge.graphql";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useNavigate } from "app/lib/useNavigate";
import { DeprecatedCorrectedBy } from "./components/CorrectedBy";
import { DeprecatedCorrectionOf } from "./components/CorrectionOf";
import { CreateInvoiceCorrectionDocument } from "app/pages/Customer/tabs/Invoices/CorrectInvoice/correctInvoice.graphql";
import { filterInvoiceByType } from "app/lib/invoices/typeGuard";
import { useOptionalParam } from "app/lib/routes/params";
import DeprecatedChangeIssueDateModal from "./components/ChangeIssueDateModal";
import { ChangeContractIssueDateDocument } from "./components/ChangeIssueDateModal/changeIssueDate.graphql";
import {
  BillableStatus,
  InvoiceExternalStatusEnum_Enum,
  InvoiceStatusEnum,
} from "types/generated-graphql/__types__";
import { DeprecatedInvoiceFlyover } from "./components/InvoiceFlyover";
import { Invoice as InvoiceHelper } from "app/pages/Contracts/lib/Invoice";
import { renderDate } from "lib/time";
import { DeprecatedRegenerated } from "./components/Regenerated";
import { RegenerateInvoiceDocument } from "./components/RegenerateVoidedInvoiceModal/regenInvoice.graphql";
import DeprecatedRegenerateVoidedInvoiceModal from "./components/RegenerateVoidedInvoiceModal";
import { ContractProductFlyover } from "app/pages/Contracts/Pricing/ContractProductFlyover";
import { DeprecatedNonContractLineItems } from "./components/LineItem/lineItems/NonContractLineItems";
import { StatusPills } from "app/pages/Customer/tabs/Overview/StatusPills";
import { twMerge } from "twMerge";

const SEND_TO_STRIPE_DEBOUNCE_TIME_MS = 5000;

function getInvoiceEndDate(i: MriInvoiceFragment) {
  switch (i.__typename) {
    case "AdHocPlanInvoice":
    case "ArrearsInvoice":
    case "ParentInvoice":
      return dayjs.utc(i.exclusive_end_date).subtract(1, "s").toDate();
    case "CreditPurchaseInvoice":
    case "AdvanceInvoice":
    case "CorrectionInvoice":
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "ContractUsageInvoice":
    case "ContractRefundInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "SeatPurchaseInvoice":
    case "AdhocContractUsageInvoice":
      return new Date(i.issued_at);
    default:
      // If this is failing to compile, you probably need to add a new case to the switch statement above
      i satisfies never;
      throw new Error("Unreachable");
  }
}

const headerStyles =
  "p-12 flex border-b border-solid border-deprecated-gray-100";

export const DeprecatedInvoiceSkeleton: React.FC = () => {
  return (
    <div>
      <div className={headerStyles}>
        <DeprecatedTextSkeleton />
      </div>
      <div className={headerStyles}>
        <DeprecatedTextSkeleton />
      </div>
      <div className="p-12">
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
        <DeprecatedTextSkeleton />
      </div>
    </div>
  );
};

function isVoidable(
  invoice: InvoiceType,
  canVoidInvoiceWithDeductions: boolean,
) {
  // If the invoice isn't finalized, we can't void it
  if (invoice.status !== "FINALIZED") {
    return false;
  }
  // If this invoice has credit deductions, and we aren't allowed to void invoices with deductions, we can't void it
  if (
    invoice.line_items.some((l) => l.__typename === "CreditLineItem") &&
    !canVoidInvoiceWithDeductions
  ) {
    return false;
  }

  // If this invoice is a correction, and there are corrections made after this one that aren't voided, we can't void it
  if (
    invoice.__typename === "CorrectionInvoice" &&
    !invoice.correction_record.original_invoice.corrections.every(
      (c) =>
        c.id === invoice.id || // exclude the invoice itself
        c.status === InvoiceStatusEnum.Void ||
        dayjs.utc(c.issued_at).isBefore(invoice.issued_at),
    )
  ) {
    return false;
  }

  // If this invoice has been corrected, and all corrections have not been voided, we can't void it
  if (
    "corrections" in invoice &&
    !invoice.corrections.every((c) => c.status === InvoiceStatusEnum.Void)
  ) {
    return false;
  }

  return true;
}

function useRegenerationDates(
  customer_id: string,
  regenerateFromInvoiceId?: string,
  regenerateToInvoiceId?: string,
): {
  regeneratedFromDate?: Date;
  regeneratedToDate?: Date;
} {
  const regeneratedFromInvoice = useGetInvoiceQuery({
    variables: {
      id: regenerateFromInvoiceId || "",
      customer_id: customer_id,
    },
    skip: !regenerateFromInvoiceId,
  });
  const regeneratedToInvoice = useGetInvoiceQuery({
    variables: {
      id: regenerateToInvoiceId || "",
      customer_id: customer_id,
    },
    skip: !regenerateToInvoiceId,
  });
  let regeneratedFromDate, regeneratedToDate;
  if (regeneratedFromInvoice.data?.Customer_by_pk?.mri_invoices.invoices[0]) {
    regeneratedFromDate = getInvoiceEndDate(
      regeneratedFromInvoice.data?.Customer_by_pk?.mri_invoices.invoices[0],
    );
  }
  if (regeneratedToInvoice.data?.Customer_by_pk?.mri_invoices.invoices[0]) {
    regeneratedToDate = getInvoiceEndDate(
      regeneratedToInvoice.data?.Customer_by_pk?.mri_invoices.invoices[0],
    );
  }
  return { regeneratedFromDate, regeneratedToDate };
}

export function isSendToStripeInactive(
  invoice: InvoiceType,
  isSendingToStripe: boolean,
  isSendToStripeButtonDebounced: boolean,
): boolean {
  if (!invoice) {
    return true;
  }

  return (
    invoice.status !== "FINALIZED" ||
    invoice.__typename === "CorrectionInvoice" ||
    invoice.__typename === "ParentInvoice" ||
    invoice.__typename === "AdHocPlanInvoice" ||
    invoice.__typename === "AdhocContractUsageInvoice" ||
    (invoice.__typename === "ContractUsageInvoice" &&
      invoice.billable_status === BillableStatus.Unbillable) ||
    (invoice.billing_provider_invoice &&
      invoice.billing_provider_invoice?.external_status !==
        InvoiceExternalStatusEnum_Enum.InvalidRequestError) ||
    isSendingToStripe ||
    isSendToStripeButtonDebounced
  );
}

export const DeprecatedInvoice: React.FC<{
  invoiceID: string;
  customerID: string;
  isFinalizable: boolean;
  renderInvoicePlanOrContractLink?: (
    customerId: string,
    invoice: InvoiceType,
  ) => React.ReactNode;
}> = ({
  invoiceID,
  customerID,
  isFinalizable,
  renderInvoicePlanOrContractLink,
}) => {
  const customerPlanId = useOptionalParam("customerPlanId");
  const contractId = useOptionalParam("contractId");
  const pushMessage = useSnackbar();
  const [showVoidInvoiceModal, setShowVoidInvoiceModal] = useState(false);
  const [showFinalizeInvoiceModal, setShowFinalizeInvoiceModal] =
    useState(false);
  const [showAddOneTimeChargeModal, setShowAddOneTimeChargeModal] =
    useState(false);
  const [showChangeIssueDateModal, setShowChangeIssueDateModal] =
    useState(false);
  const [showRegenInvoiceModal, setShowRegenInvoiceModal] = useState(false);
  const [isSendToStripeButtonDebounced, setIsSendToStripeButtonDebounced] =
    useState(false);

  useEffect(() => {
    if (isSendToStripeButtonDebounced) {
      const handler = setTimeout(() => {
        setIsSendToStripeButtonDebounced(false);
      }, SEND_TO_STRIPE_DEBOUNCE_TIME_MS);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [isSendToStripeButtonDebounced]);

  const { data, loading } = useGetInvoiceQuery({
    variables: {
      id: invoiceID,
      customer_id: customerID,
    },
  });

  const { allowed: billingProviderCustomersAllowed } = useAuthCheck(
    GetBillingProviderCustomersDocument,
    false,
  );

  const { data: billingProviderCustomers } =
    useGetBillingProviderCustomersQuery({
      variables: { customer_id: customerID },
      skip: !billingProviderCustomersAllowed,
    });

  const [showChargesWithZeroUsage, setShowChargesWithZeroUsage] =
    React.useState(false);
  const [sendToStripeMutation, sendToStripeMutationResult] =
    useSendInvoiceToStripeMutation();
  const navigate = useNavigate();
  const invoiceCorrectionsAllowed = useFeatureFlag(
    "invoice-corrections",
    false,
  );
  const canVoidInvoicesWithDeductions =
    useFeatureFlag<boolean>("void-invoice-with-deductions", false) ?? false;
  const canFinalizeInvoiceFromUI = useFeatureFlag(
    "finalize-invoice-from-ui",
    false,
  );

  const now = useNow();
  const canFinalizeInvoice = !!useAuthCheck(FinalizeInvoicesDocument, true)
    .allowed;
  const canVoidInvoice = !!useAuthCheck(VoidInvoiceDocument, true).allowed;
  const canSendToStripe = !!useAuthCheck(SendInvoiceToStripeDocument, true)
    .allowed;
  const canAddOneTimeCharge = !!useAuthCheck(AddOneTimeChargeDocument, true)
    .allowed;
  const canCorrectInvoice = !!useAuthCheck(
    CreateInvoiceCorrectionDocument,
    true,
  ).allowed;
  const canChangeIssueDate = !!useAuthCheck(
    ChangeContractIssueDateDocument,
    true,
  ).allowed;
  const canRegenInvoice = !!useAuthCheck(RegenerateInvoiceDocument, true)
    .allowed;

  const invoice = data?.Customer_by_pk?.mri_invoices.invoices[0];
  const customer = data?.Customer_by_pk;
  const creditTypeConversions =
    invoice?.__typename === "ContractUsageInvoice"
      ? invoice.contract.rate_card?.credit_type_conversions ?? undefined
      : undefined;

  const [lineItemFilter, setLineItemFilter] = React.useState<string>("");

  const [flyoverTarget, setFlyoverTarget] = React.useState<
    | {
        type: "invoice";
        invoiceId: string;
      }
    | { type: "product"; productId: string }
  >();

  // Sort newest to oldest
  const corrections =
    invoice && "corrections" in invoice
      ? [...invoice.corrections].sort((a, b) =>
          new Date(a.issued_at) < new Date(b.issued_at) ? 1 : -1,
        )
      : [];

  const searchableLineItems = (invoice?.line_items ?? []).map(
    (lineItem: any) => ({
      ...lineItem,
      productId: lineItem.product?.id,
    }),
  );
  const searchLineItems = useSearcher(searchableLineItems, [
    "display_name",
    "productId",
  ]);
  const linesItemsFiltered = searchLineItems(lineItemFilter);
  const { contractLineItems, nonContractLineItems } =
    extractContractLineItems(linesItemsFiltered);

  let regeneratedFromInvoiceID, regeneratedToInvoiceID;
  if (invoice && "regenerated_from" in invoice) {
    regeneratedFromInvoiceID = invoice.regenerated_from?.id;
  }
  if (invoice && "regenerated_to" in invoice) {
    regeneratedToInvoiceID = invoice.regenerated_to?.id;
  }
  const { regeneratedFromDate, regeneratedToDate } = useRegenerationDates(
    customerID,
    regeneratedFromInvoiceID,
    regeneratedToInvoiceID,
  );

  if (loading) {
    return <DeprecatedInvoiceSkeleton />;
  }
  if (!invoice || !customer) {
    return <EmptyState icon="file04" mainText="Error loading invoice" />;
  }

  return (
    <div>
      {/* Flyover */}
      {((): React.ReactElement | null => {
        if (!flyoverTarget) {
          return null;
        }

        const onRequestClose = () => setFlyoverTarget(undefined);

        switch (flyoverTarget.type) {
          case "product":
            return (
              <ContractProductFlyover
                productId={flyoverTarget.productId}
                onRequestClose={onRequestClose}
              />
            );
          case "invoice":
            return (
              <DeprecatedInvoiceFlyover
                onRequestClose={onRequestClose}
                invoiceId={flyoverTarget.invoiceId}
                invoiceName={InvoiceHelper.renderDate(invoice, now)}
              />
            );
        }
      })()}
      {showVoidInvoiceModal ? (
        <DeprecatedVoidInvoiceModal
          invoiceId={invoice.id}
          onClose={() => setShowVoidInvoiceModal(false)}
        />
      ) : null}
      {showFinalizeInvoiceModal ? (
        <DeprecatedFinalizeInvoiceModal
          invoiceId={invoice.id}
          customerId={customer.id}
          onClose={() => setShowFinalizeInvoiceModal(false)}
        />
      ) : null}
      {showAddOneTimeChargeModal &&
      invoice.status == "DRAFT" &&
      filterInvoiceByType("ArrearsInvoice")(invoice) &&
      invoice.customer_plan ? (
        <DeprecatedAddOneTimeChargeModal
          invoiceStartDate={new Date(invoice.inclusive_start_date)}
          customerId={customer.id}
          creditType={invoice.credit_type}
          customerPlanId={invoice.customer_plan.id}
          onClose={() => setShowAddOneTimeChargeModal(false)}
        />
      ) : null}
      {showChangeIssueDateModal &&
      invoice.status === "DRAFT" &&
      filterInvoiceByType("ContractScheduledInvoice")(invoice) ? (
        <DeprecatedChangeIssueDateModal
          invoiceId={invoice.id}
          currentIssueDate={new Date(invoice.issued_at)}
          onClose={() => setShowChangeIssueDateModal(false)}
        />
      ) : null}
      {showRegenInvoiceModal ? (
        <DeprecatedRegenerateVoidedInvoiceModal
          invoiceId={invoice.id}
          onClose={() => setShowRegenInvoiceModal(false)}
        />
      ) : null}
      <div className={twMerge(headerStyles, "pt-4")}>
        <Headline className="mt-4" level={5}>
          {`${customer.name} ${renderDate(getInvoiceEndDate(invoice), {
            isUtc: true,
          })}`}
        </Headline>
        <StatusPills
          stackVertically={false}
          invoice={invoice}
          className="ml-8 mt-8"
        />
        <div className="ml-auto flex gap-4">
          {renderInvoicePlanOrContractLink &&
            renderInvoicePlanOrContractLink(customerID, invoice)}
          <DeprecatedPopoverMenu
            positions={["bottom"]}
            align="end"
            options={[
              {
                content: "Download CSV",
                onClick: () =>
                  downloadCSV(
                    customer.name,
                    invoice,
                    getInvoiceEndDate(invoice),
                    showChargesWithZeroUsage,
                  ),
              },
              {
                content: "View metadata",
                onClick: () => {
                  setFlyoverTarget({ type: "invoice", invoiceId: invoice.id });
                },
              },
              {
                content: "Manage custom fields...",
                routePath: `/connections/custom-fields/invoice/${invoice.id}`,
              },

              ...(billingProviderCustomers?.BillingProviderCustomer.some(
                (bp) => bp.billing_provider === "STRIPE",
              )
                ? [
                    gatedAction(canSendToStripe, {
                      content: "Send to Stripe...",
                      disabled: isSendToStripeInactive(
                        invoice,
                        sendToStripeMutationResult.loading,
                        isSendToStripeButtonDebounced,
                      ),
                      onClick: async () => {
                        try {
                          setIsSendToStripeButtonDebounced(true);
                          await sendToStripeMutation({
                            variables: { invoice_id: invoice.id },
                          });
                          pushMessage({
                            type: "success",
                            content: "Successfully sent invoice to Stripe!",
                          });
                        } catch (e) {
                          pushMessage({
                            type: "error",
                            content: "Error sending invoice to Stripe.",
                          });
                          throw e;
                        }
                      },
                    }),
                  ]
                : []),
              gatedAction(canAddOneTimeCharge, {
                content: "Add one-time charge...",
                disabled:
                  invoice.status !== "DRAFT" ||
                  !filterInvoiceByType("ArrearsInvoice")(invoice) ||
                  !invoice.customer_plan,
                onClick: () => setShowAddOneTimeChargeModal(true),
              }),
              ...(filterInvoiceByType("ContractScheduledInvoice")(invoice)
                ? [
                    gatedAction(canChangeIssueDate, {
                      content: "Change issue date",
                      disabled: invoice.status !== "DRAFT",
                      onClick: () => setShowChangeIssueDateModal(true),
                    }),
                  ]
                : []),
              ...(invoiceCorrectionsAllowed &&
              (filterInvoiceByType("ArrearsInvoice")(invoice) ||
                filterInvoiceByType("ContractUsageInvoice")(invoice) ||
                filterInvoiceByType("ContractScheduledInvoice")(invoice)) &&
              invoice.status === InvoiceStatusEnum.Finalized
                ? [
                    gatedAction(canCorrectInvoice, {
                      content: "Correct invoice...",
                      disabled: false,
                      onClick: () => {
                        navigate(
                          `/customers/${customer.id}${
                            customerPlanId
                              ? `/plans/${customerPlanId}/`
                              : contractId
                                ? `/contracts/${contractId}/`
                                : "/"
                          }invoices/${invoice.id}/correct`,
                        );
                      },
                    }),
                  ]
                : []),
              ...(canFinalizeInvoiceFromUI &&
              invoice.status === InvoiceStatusEnum.Draft &&
              (invoice.__typename === "ContractUsageInvoice" ||
                invoice.__typename === "ArrearsInvoice")
                ? [
                    gatedAction(canFinalizeInvoice, {
                      content: "Finalize invoice...",
                      disabled: !isFinalizable,
                      onClick: () => {
                        setShowFinalizeInvoiceModal(true);
                      },
                    }),
                  ]
                : []),
              ...(invoice.__typename === "ContractUsageInvoice" ||
              invoice.__typename === "ContractScheduledInvoice" ||
              invoice.__typename === "ContractPostpaidTrueupInvoice" ||
              invoice.__typename === "ContractRefundInvoice" ||
              invoice.__typename === "ContractProServiceInvoice"
                ? [
                    gatedAction(canRegenInvoice, {
                      content: "Regenerate voided invoice...",
                      disabled:
                        invoice.status !== "VOID" || invoice.regenerated_to,
                      onClick: async () => {
                        setShowRegenInvoiceModal(true);
                      },
                    }),
                  ]
                : []),
              gatedAction(canVoidInvoice, {
                content: "Void invoice...",
                disabled: !isVoidable(invoice, canVoidInvoicesWithDeductions),
                onClick: () => {
                  setShowVoidInvoiceModal(true);
                },
              }),
            ]}
          >
            {(onClick) => (
              <div onClick={onClick}>
                <IconButton theme="secondary" icon="dotsVertical" />
              </div>
            )}
          </DeprecatedPopoverMenu>
        </div>
      </div>
      <div className="flex items-center p-12">
        <DeprecatedInvoiceTimeline invoice={invoice} />
        <span className="shrink grow" />
        <div className="mx-12">
          <Toggle
            label="Show charges with zero usage"
            checked={showChargesWithZeroUsage}
            onChange={(v) => setShowChargesWithZeroUsage(v)}
          />
        </div>
        <Input
          className="truncate"
          type="search"
          placeholder="Search charges"
          value={lineItemFilter}
          onChange={(v) => setLineItemFilter(v)}
        />
      </div>
      <div>
        {corrections.map((correction, i) => (
          <DeprecatedCorrectedBy
            customer_id={customer.id}
            correction={correction}
            key={i}
          />
        ))}
      </div>
      {invoice.__typename === "CorrectionInvoice" ? (
        <DeprecatedCorrectionOf correction_of={invoice} customer={customer} />
      ) : null}
      {invoice.__typename === "ContractRefundInvoice" ? (
        <DeprecatedCorrectionOf refund_of={invoice} customer={customer} />
      ) : null}
      {"regenerated_from" in invoice &&
      invoice.regenerated_from &&
      regeneratedFromDate ? (
        <DeprecatedRegenerated
          customer={customer}
          regeneratedType="from"
          regeneratedInvoiceId={invoice.regenerated_from.id}
          issueDate={regeneratedFromDate}
        />
      ) : null}
      {"regenerated_to" in invoice &&
      invoice.regenerated_to &&
      regeneratedToDate ? (
        <DeprecatedRegenerated
          customer={customer}
          regeneratedType="to"
          regeneratedInvoiceId={invoice.regenerated_to.id}
          issueDate={regeneratedToDate}
        />
      ) : null}
      <div>
        {contractLineItems.length ? (
          <DeprecatedContractLineItems
            onRowClick={(row) => {
              if (row && (row as any).productId) {
                setFlyoverTarget({
                  type: "product",
                  productId: (row as any).productId,
                });
              }
            }}
            invoiceType={invoice.__typename}
            lineItems={contractLineItems}
            issuedAt={new Date(invoice.issued_at)}
            exclusiveEndDate={
              "exclusive_end_date" in invoice
                ? new Date(invoice.exclusive_end_date)
                : undefined
            }
            showChargesWithZeroUsage={showChargesWithZeroUsage}
          />
        ) : null}
        {nonContractLineItems.length ? (
          <DeprecatedNonContractLineItems
            invoiceId={invoice.id}
            lineItems={nonContractLineItems}
            showChargesWithZeroUsage={showChargesWithZeroUsage}
          />
        ) : null}
      </div>
      <DeprecatedTotals
        invoice={invoice}
        creditTypeConversions={creditTypeConversions}
      />
    </div>
  );
};

export const DeprecatedEmbeddableDashboardInvoice: React.FC<{
  invoice: MriInvoiceFragment;
  customer: { id: string; name: string };
  showChargesWithZeroUsage: boolean;
}> = ({ invoice, showChargesWithZeroUsage, customer }) => {
  const creditTypeConversions =
    invoice?.__typename === "ContractUsageInvoice"
      ? invoice.contract.rate_card?.credit_type_conversions ?? undefined
      : undefined;

  // Sort newest to oldest
  const corrections =
    invoice && "corrections" in invoice
      ? [...invoice.corrections].sort((a, b) =>
          new Date(a.issued_at) < new Date(b.issued_at) ? 1 : -1,
        )
      : [];

  const { contractLineItems, nonContractLineItems } = extractContractLineItems(
    invoice.line_items,
  );

  let regeneratedFromInvoiceID, regeneratedToInvoiceID;
  if (invoice && "regenerated_from" in invoice) {
    regeneratedFromInvoiceID = invoice.regenerated_from?.id;
  }
  if (invoice && "regenerated_to" in invoice) {
    regeneratedToInvoiceID = invoice.regenerated_to?.id;
  }
  const { regeneratedFromDate, regeneratedToDate } = useRegenerationDates(
    customer.id,
    regeneratedFromInvoiceID,
    regeneratedToInvoiceID,
  );

  return (
    <div>
      <div className={headerStyles}>
        <Headline className="mt-4" level={5}>
          {`${renderDate(getInvoiceEndDate(invoice), {
            isUtc: true,
          })}`}
        </Headline>
        <DeprecatedEmbeddableDashboardStatusPills
          invoice={invoice}
          light={false}
          className="ml-8 mt-8"
        />
      </div>
      <div className="flex items-center p-12">
        <DeprecatedInvoiceTimeline invoice={invoice} />
      </div>
      <div>
        {corrections.map((correction, i) => (
          <DeprecatedCorrectedBy
            customer_id={customer.id}
            correction={correction}
            key={i}
          />
        ))}
      </div>
      {invoice.__typename === "CorrectionInvoice" ? (
        <DeprecatedCorrectionOf correction_of={invoice} customer={customer} />
      ) : null}
      {invoice.__typename === "ContractRefundInvoice" ? (
        <DeprecatedCorrectionOf refund_of={invoice} customer={customer} />
      ) : null}
      {"regenerated_from" in invoice &&
      invoice.regenerated_from &&
      regeneratedFromDate ? (
        <DeprecatedRegenerated
          customer={customer}
          regeneratedType="from"
          regeneratedInvoiceId={invoice.regenerated_from.id}
          issueDate={regeneratedFromDate}
        />
      ) : null}
      {"regenerated_to" in invoice &&
      invoice.regenerated_to &&
      regeneratedToDate ? (
        <DeprecatedRegenerated
          customer={customer}
          regeneratedType="to"
          regeneratedInvoiceId={invoice.regenerated_to.id}
          issueDate={regeneratedToDate}
        />
      ) : null}
      <div>
        {contractLineItems.length ? (
          <DeprecatedContractLineItems
            invoiceType={invoice.__typename}
            lineItems={contractLineItems}
            issuedAt={new Date(invoice.issued_at)}
            exclusiveEndDate={
              "exclusive_end_date" in invoice
                ? new Date(invoice.exclusive_end_date)
                : undefined
            }
            showChargesWithZeroUsage={showChargesWithZeroUsage}
          />
        ) : null}
        {nonContractLineItems.length ? (
          <DeprecatedNonContractLineItems
            invoiceId={invoice.id}
            lineItems={nonContractLineItems}
            showChargesWithZeroUsage={showChargesWithZeroUsage}
          />
        ) : null}
      </div>
      <DeprecatedTotals
        invoice={invoice}
        creditTypeConversions={creditTypeConversions}
      />
    </div>
  );
};

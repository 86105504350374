/* 382f9d4df03117bcddc66407f15b62a290b18f68
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillableMetricPlansQueryVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type BillableMetricPlansQuery = { __typename?: 'Query', BillableMetric: { __typename?: 'BillableMetric', id: string, products: Array<{ __typename?: 'Product', id: string, plans: Array<{ __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } }> }> } | null };

export type PlanCardDetailsFragment = { __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } };

export type SeatMetricPlansQueryVariables = Types.Exact<{
  seat_metric_id: Types.Scalars['uuid'];
}>;


export type SeatMetricPlansQuery = { __typename?: 'Query', seat_metric: { __typename?: 'SeatMetric', id: string, products: Array<{ __typename?: 'Product', id: string, plans: Array<{ __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } }> }> } };

export const PlanCardDetailsFragmentDoc = gql`
    fragment PlanCardDetails on Plan {
  id
  name
  description
  created_at
  deprecated_at
  customer_count: customer_count(status: ACTIVE)
  Actor {
    name
    id
    deprecated_at
  }
  PricedProducts {
    id
    PricedProductPricingFactors {
      id
      ProductPricingFactor {
        id
        name
        __environment_type: environment_type
      }
    }
    Product {
      id
      name
      __environment_type: environment_type
    }
  }
  PricedProducts_aggregate(distinct_on: product_id) {
    aggregate {
      count
    }
  }
  __environment_type: environment_type
}
    `;
export const BillableMetricPlansDocument = gql`
    query BillableMetricPlans($billable_metric_id: uuid!) {
  BillableMetric: BillableMetric_by_pk(id: $billable_metric_id) {
    id
    products(types: [ACTIVE]) {
      id
      plans(type: ACTIVE_ONLY) {
        id
        ...PlanCardDetails
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    ${PlanCardDetailsFragmentDoc}`;

/**
 * __useBillableMetricPlansQuery__
 *
 * To run a query within a React component, call `useBillableMetricPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricPlansQuery({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useBillableMetricPlansQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricPlansQuery, BillableMetricPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricPlansQuery, BillableMetricPlansQueryVariables>(BillableMetricPlansDocument, options);
      }
export function useBillableMetricPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricPlansQuery, BillableMetricPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricPlansQuery, BillableMetricPlansQueryVariables>(BillableMetricPlansDocument, options);
        }
export type BillableMetricPlansQueryHookResult = ReturnType<typeof useBillableMetricPlansQuery>;
export type BillableMetricPlansLazyQueryHookResult = ReturnType<typeof useBillableMetricPlansLazyQuery>;
export type BillableMetricPlansQueryResult = Apollo.QueryResult<BillableMetricPlansQuery, BillableMetricPlansQueryVariables>;
export const SeatMetricPlansDocument = gql`
    query SeatMetricPlans($seat_metric_id: uuid!) {
  seat_metric(seat_metric_id: $seat_metric_id) {
    id
    products {
      id
      plans(type: ACTIVE_ONLY) {
        id
        ...PlanCardDetails
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
  }
}
    ${PlanCardDetailsFragmentDoc}`;

/**
 * __useSeatMetricPlansQuery__
 *
 * To run a query within a React component, call `useSeatMetricPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatMetricPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatMetricPlansQuery({
 *   variables: {
 *      seat_metric_id: // value for 'seat_metric_id'
 *   },
 * });
 */
export function useSeatMetricPlansQuery(baseOptions: Apollo.QueryHookOptions<SeatMetricPlansQuery, SeatMetricPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatMetricPlansQuery, SeatMetricPlansQueryVariables>(SeatMetricPlansDocument, options);
      }
export function useSeatMetricPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatMetricPlansQuery, SeatMetricPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatMetricPlansQuery, SeatMetricPlansQueryVariables>(SeatMetricPlansDocument, options);
        }
export type SeatMetricPlansQueryHookResult = ReturnType<typeof useSeatMetricPlansQuery>;
export type SeatMetricPlansLazyQueryHookResult = ReturnType<typeof useSeatMetricPlansLazyQuery>;
export type SeatMetricPlansQueryResult = Apollo.QueryResult<SeatMetricPlansQuery, SeatMetricPlansQueryVariables>;
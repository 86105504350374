import React from "react";

import {
  ApolloClient,
  ApolloProvider as UnwrappedApolloProvider,
  from,
  HttpLink,
  RequestHandler,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client/cache";

import { useAuth } from "app/lib/auth";
import * as Sentry from "@sentry/react";
import { SentryLink } from "apollo-link-sentry";
import { EnvironmentTypeLink } from "./links/environment-type";
import { createErrorLink } from "./links/error";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { possibleTypes } from "./generated/possible-types";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

const {
  link: networkStatusLink,
  useApolloNetworkStatus: useNetworkStatusHook,
} = createNetworkStatusNotifier();

export const useApolloNetworkStatus = useNetworkStatusHook;

type AuthContext = ReturnType<typeof useAuth>;

const cachePerEnv = new Map<EnvironmentTypeEnum_Enum | null, InMemoryCache>();
function getCache(env: EnvironmentTypeEnum_Enum | null) {
  const existing = cachePerEnv.get(env);
  if (existing) {
    return existing;
  }

  const cache = new InMemoryCache({
    possibleTypes: possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          /* Allow for the list and detail queries to pull from the same cache */
          BillableMetric_by_pk: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "BillableMetric",
                id: (args as { id: string }).id,
              });
            },
          },
          // there's only one product_and_rate_card object per client/env, so we can safely
          // merge all results together in the cache
          products_and_rate_cards: {
            merge: true,
          },
          // there's only one contract_pricing object per client/env, so we can safely
          // merge all results together in the cache
          contract_pricing: {
            merge: true,
          },
        },
      },
      Commit: {
        fields: {
          // access_schedule doesn't have an id, so when we async fetch usage data from the
          // access schedule it wipes out the cache unnecessarily unless we set this
          access_schedule: { merge: true },
        },
      },
      UsageProductListItem: {
        fields: {
          initial: {
            merge: true,
          },
        },
      },
      FixedProductListItem: {
        fields: {
          initial: {
            merge: true,
          },
        },
      },
      CompositeProductListItem: {
        fields: {
          initial: {
            merge: true,
          },
        },
      },
      SubscriptionProductListItem: {
        fields: {
          initial: {
            merge: true,
          },
        },
      },
    },
    dataIdFromObject(responseObject, context) {
      // Users and Actors are two different type names, but should be treated the same in the cache, since
      // Actor is just a "view" on top of users and tokens.
      const specialCasedActorTypes = ["User", "Actor"];
      if (
        responseObject.__typename &&
        responseObject.id &&
        specialCasedActorTypes.includes(responseObject.__typename)
      ) {
        return defaultDataIdFromObject({
          ...responseObject,
          __typename: "Actor",
        });
      }

      // If we get an object back with a missing ID, we don't want to cache it.
      // Otherwise, e.g. draft invoices end up showing duplicate data in the UI
      // because they all have the same (null) ID. Returning undefined, as we do
      // here implicitly, causes Apollo to not cache the object.
      if (responseObject.id !== null) {
        return defaultDataIdFromObject(responseObject);
      }
    },
  });
  cachePerEnv.set(env, cache);
  return cache;
}

interface ApolloProviderProps extends React.PropsWithChildren {
  graphqlURI: string;
  useMetronomeSystemHeaders: boolean;
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({
  children,
  graphqlURI,
  useMetronomeSystemHeaders,
}) => {
  const auth = useAuth();
  const { environmentType } = useEnvironment();
  const client = createApolloClient(
    graphqlURI,
    auth,
    environmentType,
    useMetronomeSystemHeaders,
  );
  return (
    <UnwrappedApolloProvider client={client}>
      {children}
    </UnwrappedApolloProvider>
  );
};

export const createApolloClient = (
  graphQLURI: string,
  auth: AuthContext,
  environmentType: EnvironmentTypeEnum_Enum | null,
  useMetronomeSystemHeaders: boolean,
) => {
  const batchedHttpLink = new BatchHttpLink({
    uri: graphQLURI,
  });
  const unbatchedHttpLink = new HttpLink({
    uri: graphQLURI,
  });
  const asyncAuthLink = setContext(async (_, { headers }) => {
    const token = await auth.getAccessToken(environmentType);
    if (headers === undefined) {
      headers = {};
    }
    // TODO(acook): remove the plumbing of this value once it is on in staging/prod
    if (useMetronomeSystemHeaders) {
      headers["x-metronome-system"] = "ui";
    }
    return {
      headers: {
        ...headers,
        "x-metronome-readonly": "false",
        authorization: `Bearer ${token}`,
      },
    };
  });

  const sentryTransactionLink: RequestHandler = (operation, forward) => {
    const tx = Sentry.startTransaction({
      name: `graphql- ${operation.operationName}`,
      op: operation.operationName,
    });
    if (!tx) {
      return forward(operation);
    }
    const context = operation.getContext();
    operation.setContext({
      ...context,
      headers: {
        ...context.headers,
        "sentry-trace": tx.toTraceparent(),
      },
    });
    const result = forward(operation);
    result.subscribe({
      next: (r) => {
        tx.setStatus(r.errors && r.errors.length ? "internal_error" : "ok");
      },
      error: (e) => {
        console.log("error", e);
      },
      complete: () => {
        tx.finish();
      },
    });
    return result;
  };

  return new ApolloClient({
    cache: getCache(environmentType),
    connectToDevTools: process.env.DEPLOY_ENV
      ? ["development", "staging"].includes(process.env.DEPLOY_ENV)
      : false,
    link: networkStatusLink.concat(
      from([
        from([
          createErrorLink(auth),
          asyncAuthLink,
          new SentryLink({
            uri: graphQLURI,
            setTransaction: false,
            setFingerprint: true,
            attachBreadcrumbs: {
              includeQuery: true,
              includeVariables: false,
              includeFetchResult: false,
              includeError: true,
            },
          }),
          sentryTransactionLink,
          ...(environmentType
            ? [new EnvironmentTypeLink(environmentType)]
            : []),
        ]).split(
          (operation) => operation.operationName.includes("__doNotBatch"),
          unbatchedHttpLink,
          batchedHttpLink,
        ),
      ]),
    ),
  });
};

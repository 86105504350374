import React, { ReactElement, useState } from "react";
import type { RouteObject } from "react-router";
import { useRequiredParam } from "app/lib/routes/params";

import NotFoundPage from "app/pages/404";

import { AppShell } from "components/AppShell";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { DeprecatedPlanPreview } from "components/deprecated/PlanPreview";
import { BlockSkeleton } from "components/deprecated/Skeleton";
import { DeprecatedMinimalStatistic } from "components/deprecated/Statistic";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";
import { IconButton } from "components/IconButton";
import ArchivePlanModal from "../components/ArchivePlanModal";

import { useCopyPlan } from "app/lib/plans/hooks";

import {
  PlanAlertsInfoFragment,
  PlanInfoMinusAlertsFragment,
  usePlanAlertsQuery,
  usePlanMinusAlertsQuery,
} from "./data/plan.graphql";
import { Tooltip } from "design-system";
import { CustomerTable } from "app/pages/deprecated/Overview/components/CustomerTable";
import { ALL_BILLING_CONFIG_FILTERS } from "app/pages/deprecated/Overview/filters";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { DeprecatedAlertsTable } from "components/deprecated/AlertsTable";
import { EmptyState } from "components/EmptyState";
import { useFeatureFlag } from "app/lib/launchdarkly";
import DeprecatedCopyPlanModal from "components/deprecated/CopyAssetToEnvironmentModal/CopyPlanModal";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { InsertPlanDocument } from "components/deprecated/CopyAssetToEnvironmentModal/data/queries.graphql";
import { EditPlanDocument } from "app/pages/Offering/tabs/Plans/PlanWizards/data/queries.graphql";
import { CreateAlertDocument } from "app/pages/Connections/tabs/Alerts/NewAlert/queries.graphql";
import { DeprecatedAuditLogTable } from "components/deprecated/AuditLogTable";
import { useUIMode } from "app/lib/useUIMode";
import { GatedButton } from "components/GatedButton";
import { useNavigate } from "app/lib/useNavigate";
import { ArchivePlanDocument } from "../components/ArchivePlanModal/queries.graphql";

type Plan = PlanInfoMinusAlertsFragment;

type PlanView = React.FC<{
  plan: NonNullable<Plan>;
  alerts?: PlanAlertsInfoFragment["Alerts"];
}>;

interface PlanTabProps {
  View: PlanView;
}
export const PlanTab: React.FC<PlanTabProps> = ({ View }) => {
  const planId = useRequiredParam("id");
  const showAlertsTab = useFeatureFlag<boolean>("plan-alerts-info", false);
  const { data, loading, error } = usePlanMinusAlertsQuery({
    variables: {
      id: planId,
    },
  });
  const { data: planAlertsData } = usePlanAlertsQuery({
    variables: {
      id: planId,
    },
    skip: !showAlertsTab,
  });

  const navigate = useNavigate();
  const sandboxMode = useEnvironment();
  const { environments, environment } = sandboxMode;
  const { copyPlan } = useCopyPlan();
  const [planToArchive, setPlanToArchive] = useState<Plan | null>(null);
  const [planToCopy, setPlanToCopy] = useState<Plan | null>(null);

  const canCreatePlan = !!useAuthCheck(InsertPlanDocument, true).allowed;
  const canEditPlan = !!useAuthCheck(EditPlanDocument, true).allowed;
  const canArchivePlan = !!useAuthCheck(ArchivePlanDocument, true).allowed;

  const plan = data?.Plan_by_pk;
  const copyAssetsFF = useFeatureFlag<string[]>(
    "copy-assets-to-environment",
    [],
  );
  const copyPlanToEnvironmentEnabled = copyAssetsFF?.includes("PLAN");

  const { mode } = useUIMode();
  if (loading || error) {
    return (
      <AppShell title={{ state: "loading" }}>
        <BlockSkeleton />
      </AppShell>
    );
  }
  if (!plan) {
    return <NotFoundPage />;
  }

  const actions: {
    content: string | ReactElement;
    onClick: () => void;
    disabled?: boolean;
  }[] = [
    gatedAction(canCreatePlan, {
      content: "Duplicate plan...",
      onClick: async () => copyPlan(plan.id),
    }),
  ];

  if (copyPlanToEnvironmentEnabled && environments.length > 1) {
    const text =
      environments.length === 2
        ? `Copy to ${environments.filter((e) => e !== environment)[0].name}`
        : "Copy to another environment...";
    actions.push({
      content: text,
      onClick: () => setPlanToCopy(plan),
    });
  }

  actions.push({
    content: "Manage custom fields...",
    onClick: () => navigate(`/connections/custom-fields/plan/${plan.id}`),
  });

  actions.push(
    gatedAction(canEditPlan, {
      content: "Edit plan...",
      onClick: () => navigate(`/offering/plans/edit/${plan.id}`),
    }),
  );

  const activeAndFutureCustomers =
    plan.active_customer_count + plan.future_customer_count;

  if (plan.deprecated_at === null) {
    actions.push(
      gatedAction(canArchivePlan, {
        disabled: activeAndFutureCustomers > 0,
        content:
          activeAndFutureCustomers === 0 ? (
            "Archive plan..."
          ) : (
            <Tooltip content="Plans in use cannot be archived">
              Archive plan...
            </Tooltip>
          ),
        onClick: () => setPlanToArchive(plan),
      }),
    );
  }

  const planMenu = (
    <DeprecatedPopoverMenu positions={["bottom"]} align="end" options={actions}>
      {(onClick) => (
        <IconButton onClick={onClick} theme="secondary" icon="dotsVertical" />
      )}
    </DeprecatedPopoverMenu>
  );

  const pageContent = (
    <>
      {planToArchive && (
        <ArchivePlanModal
          onClose={() => {
            setPlanToArchive(null);
          }}
          plan={planToArchive}
        />
      )}
      {planToCopy && (
        <DeprecatedCopyPlanModal
          onClose={() => {
            setPlanToCopy(null);
          }}
          planId={planToCopy.id}
          planName={planToCopy.name}
        />
      )}
      {plan && <View plan={plan} alerts={planAlertsData?.Plan_by_pk?.Alerts} />}
    </>
  );

  return (
    <AppShell
      title={plan.name}
      headerProps={{
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Offering",
            routePath: `/offering/${mode === "plans-only" ? "plans" : "rate-cards"}`,
          },
          {
            label: "Plans",
            routePath: "/offering/plans",
          },
        ),
        actions:
          mode !== "plans-only" ? (
            planMenu
          ) : (
            <>
              <DeprecatedMinimalStatistic
                icon="people"
                value={activeAndFutureCustomers}
                label="customers"
              />
              {planMenu}
            </>
          ),
        tabs: [
          {
            name: "Plan Summary",
            path: `/offering/plans/${planId}`,
            exactMatch: true,
          },
          {
            name: "Customers",
            path: `/offering/plans/${planId}/customers`,
          },
          {
            name: "Alerts",
            path: `/offering/plans/${planId}/alerts`,
          },

          {
            name: "Audit logs",
            path: `/offering/plans/${planId}/audit-logs`,
          },
        ],
      }}
    >
      {pageContent}
    </AppShell>
  );
};

export const PlanCustomers: PlanView = ({ plan }) => (
  <CustomerTable
    planId={plan.id}
    billingConfigFilters={ALL_BILLING_CONFIG_FILTERS}
  />
);

export const PlanAlerts: PlanView = ({ plan, alerts }) => {
  return !alerts || (alerts.length ?? 0) === 0 ? (
    <EmptyState
      icon="bell03"
      mainText="This plan has no alerts."
      supportingText="Create an alert."
      actions={[
        <GatedButton
          doc={CreateAlertDocument}
          linkTo="/connections/alerts/new"
          text="Create alert"
          theme="primary"
          leadingIcon="plus"
        />,
      ]}
    />
  ) : (
    <DeprecatedAlertsTable
      alerts={
        alerts.map((alert) => ({
          ...alert,
          Plan: {
            id: plan.id,
            name: plan.name,
          },
        })) ?? []
      }
      loading={false}
    />
  );
};

export const PlanOverview: PlanView = ({ plan }) => {
  return <DeprecatedPlanPreview plan={plan} collapsible={false} />;
};

export const PlanAuditLogs: PlanView = ({ plan }) => {
  return <DeprecatedAuditLogTable resourceType="plan" resourceID={plan.id} />;
};

export const PlanRoute: RouteObject = {
  path: "plans/:id",
  children: [
    { index: true, element: <PlanTab View={PlanOverview} /> },
    { path: "customers", element: <PlanTab View={PlanCustomers} /> },
    { path: "alerts", element: <PlanTab View={PlanAlerts} /> },
    { path: "audit-logs", element: <PlanTab View={PlanAuditLogs} /> },
    { path: "*", element: <PlanTab View={NotFoundPage} /> },
  ],
};

import React, { forwardRef, useCallback, useState } from "react";

import ReactDatePicker, {
  type ReactDatePickerCustomHeaderProps,
} from "react-datepicker-next";

import { Button } from "../Button";
import { twMerge } from "../../twMerge";
import { dayjs } from "lib/dayjs";
import { IconButton, IconButtonProps } from "../IconButton";
import { DateInput } from "./DateInput";
import { TimeInput } from "./TimeInput";
import { computePosition, flip, offset } from "@floating-ui/dom";
import { Tooltip, TooltipProps } from "../Tooltip";
import { ConditionalWrapper } from "app/lib/conditionalWrapper";

type ShortcutOption = {
  /**
   * Button copy indicating a date the calendar is going to.
   * Examples: "Today" (days: 0), "Last 7 days" (days: 7), "In 30 Days" (days: 30, isFuture: true)
   * */
  label: string;
  /**
   * The number of days you want to adjust the current date to. Set to 0 for Today
   */
  days: number;
  /**
   * Setting this to true will adjust the current date to a future day.
   * Setting this to false, or leaving undefined, will adjust to a past day.
   */
  isFuture?: boolean;
};

export type DatePickerProps = {
  /** Provide an initial date value that will be initialized with the DatePicker */
  value?: Date;
  /**
   * Passing a Date prop here will automatically open the calendar to that specific date.
   * For example, if you want to show a user when an invoice end of month will be, you can
   * pass that as a Date to this to have the calendar present that Date.
   */
  openToDate?: Date;
  /**
   * Provide a minDate if the user should only select a date that happens beginning
   * on `minDate` or after
   * */
  minDate?: Date;
  /**
   * Provide a maxDate if the user should only select a date that happens beginning
   * on `maxDate` or before
   * */
  maxDate?: Date;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /**
   * Provide a callback to receive the chosen date from the user.
   * This will only happen when the user clicks Apply button.
   * */
  onDateApply?: (date?: Date) => void;
  /**
   * Render a list of shortcut buttons to the left of the DatePicker, allowing a user to quickly select
   * a day in the past, or if there is an end date then a date range in the past.
   *  */
  shortcutOptions?: ShortcutOption[];

  /**
   * Text to display within the DatePicker. Default is "Starting at".
   * */
  text?: string;

  /**
   * Disables the date picker.
   */
  disabled?: boolean;

  /**
   * Icon to trigger the minimal date picker.
   * If specified, only the icon will show up and when clicked, the full date picker will be shown.
   */
  icon?: Omit<IconButtonProps, "onClick">;

  /**
   * Shows a "clear" button instead of a "cancel" button, that allows the user to reset the date to undefined.
   */
  allowClear?: boolean;

  tooltipContent?: TooltipProps;
};

export const fromUTC = (date?: Date) =>
  date !== undefined
    ? dayjs.utc(date).subtract(dayjs(date).utcOffset(), "minute").toDate()
    : date;

const toUTC = (date: Date) =>
  dayjs.utc(date).add(dayjs(date).utcOffset(), "minute").toDate();

export const DatePicker: React.FC<DatePickerProps> = ({
  onDateApply,
  openToDate,
  minDate,
  maxDate,
  value,
  shortcutOptions,
  className,
  text = "Starting at",
  disabled,
  icon,
  allowClear,
  tooltipContent,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const [currentMonth, setCurrentMonth] = useState<Date>(
    openToDate ?? new Date(),
  );

  const CustomInput = forwardRef(() => {
    return icon ? (
      <IconButton
        {...icon}
        onClick={() => setIsOpen((prev) => !prev)}
        disabled={disabled}
      />
    ) : (
      <div className="relative inline-flex items-center">
        <ConditionalWrapper
          condition={!!tooltipContent}
          wrapper={(children) => (
            <Tooltip {...tooltipContent}>{children}</Tooltip>
          )}
        >
          <Button
            disabled={disabled}
            leadingIcon="calendar"
            onClick={() => setIsOpen((prev) => !prev)}
            text={value ? dayjs.utc(value).format("MMM DD, YYYY") : text}
            theme="secondary"
            className="w-[167px] justify-start text-gray-500"
          />
        </ConditionalWrapper>
        {value && (
          <IconButton
            disabled={disabled}
            icon="xCircle"
            size="sm"
            theme="tertiary"
            className="absolute right-none"
            onClick={() => {
              setSelectedDate(undefined);
              onDateApply?.(undefined);
            }}
          />
        )}
      </div>
    );
  });

  const CustomHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <div className="mb-xs flex flex-col gap-y-sm px-xs pt-xs">
        <div className="flex items-center justify-between">
          <IconButton
            onClick={decreaseMonth}
            icon="chevronLeft"
            theme="secondary"
          />
          <span className="text-md font-semibold text-gray-600">
            {dayjs(monthDate).format("MMMM YYYY")}
          </span>
          <IconButton
            onClick={increaseMonth}
            icon="chevronRight"
            theme="secondary"
          />
        </div>
        <div className="flex items-center justify-between gap-x-lg px-xs">
          <DateInput
            value={selectedDate}
            autofocus={true}
            onChange={(meta) => {
              setSelectedDate(meta.value);
              if (meta.value) setCurrentMonth(meta.value);
            }}
          />
          <TimeInput
            value={selectedDate}
            disabled={true}
            onChange={(meta) => {
              setSelectedDate(meta.value);
            }}
          />
        </div>
      </div>
    );
  };

  const handleShortcut = useCallback((days: number, isFuture?: boolean) => {
    let newDate;
    if (isFuture) {
      newDate = dayjs.utc().startOf("day").add(days, "days").toDate();
    } else {
      newDate = dayjs.utc().startOf("day").subtract(days, "days").toDate();
    }

    setSelectedDate(fromUTC(newDate));
    setCurrentMonth(fromUTC(newDate) ?? newDate);
  }, []);

  const CustomContainer = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <div
        className={twMerge(
          "flex rounded-xl border border-gray-200 bg-white",
          "date-picker-container",
          className,
        )}
      >
        {shortcutOptions && (
          <div className="flex flex-col gap-y-lg border-r border-gray-200 px-xl py-lg">
            {shortcutOptions.map((option, idx) => (
              <Button
                theme="tertiary"
                key={idx}
                text={option.label}
                onClick={() => handleShortcut(option.days, option.isFuture)}
              />
            ))}
          </div>
        )}
        <div className="w-[328px]">{children}</div>
      </div>
    ),
    [className, shortcutOptions],
  );

  const dayClassName = useCallback(
    (date: Date) => {
      const disabledDate =
        (maxDate && date > maxDate) || (minDate && date < minDate);
      return twMerge(
        "w-5xl flex justify-center items-center relative rounded-full",
        !disabledDate && "cursor-pointer",
        // show a dot on the current date
        dayjs(date).format("M DD YYYY") ===
          dayjs(new Date()).format("M DD YYYY") &&
          "after:content-['_'] after:h-[5px] after:w-[5px] after:rounded-full after:bg-core-dark-green after:absolute after:bottom-[3px]",
        // gray out disabled dates and days from other months
        (dayjs(date).format("M") !== dayjs(currentMonth).format("M") ||
          disabledDate) &&
          "text-gray-500 after:bg-gray-400",
        selectedDate &&
          dayjs(date).format("M DD YYYY") ===
            dayjs(selectedDate).format("M DD YYYY")
          ? "bg-core-dark-green text-white after:bg-white"
          : !disabledDate && "hover:bg-gray-50",
      );
    },
    [selectedDate, currentMonth],
  );

  const handleOnChange = useCallback(
    (date: Date | null) => {
      setSelectedDate(date ? dayjs(date).toDate() : undefined);
    },
    [text],
  );

  const transformPosition = async (state: any) => {
    const refElement = state.elements.reference.closest(
      "div.react-datepicker-wrapper",
    ) as HTMLElement;
    const floatingElement = state.elements.floating.closest(
      "div.react-datepicker-popper",
    ) as HTMLElement;
    if (refElement && floatingElement) {
      try {
        const computedState = await computePosition(
          refElement,
          floatingElement,
          {
            placement: state.placement,
            strategy: "absolute",
            middleware: [
              flip({
                fallbackAxisSideDirection: "end",
                padding: 8,
              }),
              offset(8),
            ],
          },
        );
        return computedState;
      } catch (e) {
        return state;
      }
    } else {
      return state;
    }
  };

  return (
    <ReactDatePicker
      customInput={<CustomInput />}
      selected={selectedDate ?? openToDate}
      showPopperArrow={false}
      popperPlacement="bottom-start"
      popperModifiers={[
        {
          name: "dynamicPlacement",
          async fn(state: any) {
            const computedState = await transformPosition(state);
            return computedState;
          },
        },
      ]}
      popperClassName={twMerge("z-toast")}
      openToDate={selectedDate ?? openToDate}
      open={isOpen}
      onClickOutside={() => {
        setSelectedDate(value);
        setIsOpen(false);
      }}
      onMonthChange={(date) => setCurrentMonth(date)}
      onChange={handleOnChange}
      shouldCloseOnSelect={false}
      minDate={minDate}
      maxDate={maxDate}
      fixedHeight={true}
      calendarContainer={CustomContainer}
      weekDayClassName={() =>
        "text-gray-600 text-sm font-medium w-5xl flex justify-center items-center"
      }
      dayClassName={dayClassName}
      renderCustomHeader={CustomHeader}
    >
      <div className="flex items-center justify-between gap-x-lg border-t border-gray-200 p-xs">
        <Button
          text={allowClear ? "Clear" : "Close"}
          theme="secondary"
          showFullWidth={true}
          onClick={() => {
            setSelectedDate(undefined);
            allowClear && onDateApply?.(undefined);
            setIsOpen(false);
          }}
        />
        <Button
          text="Apply"
          showFullWidth={true}
          onClick={() => {
            onDateApply?.(selectedDate ? toUTC(selectedDate) : undefined);
            setIsOpen(false);
          }}
        />
      </div>
    </ReactDatePicker>
  );
};

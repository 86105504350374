import React, { useState } from "react";
import { Dropdown, DropdownItem } from "components/Dropdown";

export interface OptionType {
  label: string;
  value: string;
  type: "single" | "multi";
}

interface FilterProps {
  /** Give the user more context on what the filter options are. */
  headerContent: string;
  /** All the options the user can select.  */
  options: OptionType[];
  /** The filters the user has selected. */
  selectedFilters: readonly OptionType[];
  /** Function to trigger when the selected filters change. */
  onChange: (filters: OptionType[]) => void;
}

/**
 * A partially-implemented simple filter component. It currently only supports a
 * single filter group (i.e. status).
 * TODO: Design and implement support for multiple filter groups, reset filters button, etc.
 */
export const Filter: React.FC<FilterProps> = ({
  headerContent,
  options,
  selectedFilters,
  onChange,
}) => {
  const [selectedItemLabel, setSelectedItemLabel] = useState<
    string | undefined
  >(undefined);
  const isSelected = (option: OptionType) =>
    selectedFilters.some((filter) => filter.value === option.value);

  const handleItemClick = (option: OptionType) => () => {
    const optionIsCurrentlySelected = isSelected(option);
    let newFilters: OptionType[];

    if (option.type === "single") {
      // if currentlySelected, handle the click by unselecting the option
      // otherwise, select the option
      newFilters = optionIsCurrentlySelected ? [] : [option];
      // Update the dropdown label to reflect the selected option
      // This will trigger a rerender, which is fine for single option filters.
      setSelectedItemLabel(option.label);
    } else {
      newFilters = optionIsCurrentlySelected
        ? selectedFilters.filter((filter) => filter.value !== option.value)
        : [...selectedFilters, option];
      // Since multiple items can be selected, don't update the selected item label here.
      // We'll update it when the dropdown is closed.
    }

    onChange(newFilters);
  };

  const onDropdownClose = () => {
    // Change the label of the dropdown to reflect the selected filters
    // Do this only on dropdown close to avoid unnecessary re-renders
    let currentlySelectedLabel = selectedFilters[0]?.label;
    if (selectedFilters.length > 1) {
      currentlySelectedLabel = `${selectedFilters.length} selected`;
    }
    setSelectedItemLabel(currentlySelectedLabel);
  };

  return (
    <Dropdown
      icon="filterLines"
      headerContent={headerContent}
      buttonTheme="secondary"
      buttonSize="md"
      label="Filter"
      selectedOption={selectedItemLabel}
      onDropdownClose={onDropdownClose}
    >
      {options.map((option) => (
        <DropdownItem
          key={option.value}
          label={option.label}
          value={option.value}
          isCheckbox={option.type === "multi"}
          selected={isSelected(option)}
          onClick={handleItemClick(option)}
        />
      ))}
    </Dropdown>
  );
};

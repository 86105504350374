import React from "react";
import classnames from "classnames";

import { Body, Caption, Icon, IconName } from "design-system";
import pluralize from "pluralize";

interface StatisticProps extends React.PropsWithChildren {
  caption: string;
  className?: string;
}
export const DeprecatedStatistic: React.FC<StatisticProps> = ({
  caption,
  className,
  children,
}) => (
  <div
    className={classnames("mr-32 flex flex-col whitespace-nowrap", className)}
  >
    <Caption>{caption}</Caption>
    {children}
  </div>
);

export const DeprecatedStatistics: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div className="flex flex-row overflow-x-auto font-default font-medium">
    {children}
  </div>
);

type MinimalStatisticValue = number | "N/A";

export interface MinimalStatisticProps {
  icon: IconName;
  value?: MinimalStatisticValue;
  label: string;
}

export const DeprecatedMinimalStatistic: React.FC<MinimalStatisticProps> = (
  props,
) => {
  return (
    <div className="inline-flex items-center text-gray-600">
      <Icon icon={props.icon} />
      <Body level={2} className="m-0 ml-[6px] p-0 text-deprecated-gray-900">
        {props.value !== undefined
          ? `${props.value.toLocaleString()} ${
              props.value === 1
                ? pluralize.singular(props.label)
                : pluralize.plural(props.label)
            }`
          : props.label}
      </Body>
    </div>
  );
};

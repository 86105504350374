import { EmptyState } from "components/EmptyState";
import { DeprecatedEntityMappingModal } from "components/deprecated/EntityMapping";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useFeatureFlag } from "app/lib/launchdarkly";
import {
  useCheckForConnectedAccountsQuery,
  useGetCustomFieldKeysQuery,
  useGetVendorEntityMappingsQuery,
} from "app/pages/deprecated/GeneralSettings/queries.graphql";
import React, { useEffect, useState } from "react";
import {
  BillingProviderDeliveryMethod,
  BillingProviderEnum_Enum,
  BillingProviderToken,
  CensusDestinationTypeEnum,
  EnvironmentTypeEnum_Enum,
  ManagedFieldKey,
  ManagedVendorEnum_Enum,
} from "types/generated-graphql/__types__";
import { TabProps } from "components/deprecated/Tabs";
import {
  useGenerateWorkatoSsoUrlMutation,
  useGetClientConfigQuery,
  useGetDeliveryMethodsQuery,
  useGetSalesforceWorkspaceQuery,
} from "./queries.graphql";
import { SectionHeader } from "components/SectionHeader";
import { Button } from "components/Button";
import { stripeRedirect } from "app/lib/stripe";
import { IntegrationRow, IntegrationsTable } from "./IntegrationsTable";
import { IntegrationDetails } from "./IntegrationsTable/IntegrationDetails";
import { allVendors, mapVendorEntityData } from "app/lib/vendorMapping";
import { AzureSideSheet } from "./AzureSideSheet";
import { StripeSettingsSideSheet } from "app/pages/Connections/tabs/Integrations/StripeInvoiceSettings/components/StripeSettingsSideSheet";
import { StripeIntegrationCard } from "./IntegrationCards/StripeIntegrationCard";
import { AzureIntegrationCard } from "./IntegrationCards/AzureIntegrationCard";
import { AwsIntegrationCard } from "./IntegrationCards/AwsIntegrationCard";
import { SalesforceIntegrationCard } from "./IntegrationCards/SalesforceIntegrationCard";
import { SalesforceOnboardingSideSheet } from "./SalesforceOnboardingSideSheet";
import { AWSSideSheet } from "./AWSSideSheet";
import { twMerge } from "twMerge";
import { useDocsLink } from "app/lib/docs-link";
import { getDeltaStreamGatingMessage } from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider";
import { useContractsEnabled } from "app/lib/contracts/useContractsEnabled";

type ManagedIntegrationEnum =
  | "CHARGEBEE"
  | BillingProviderEnum_Enum.Netsuite
  | BillingProviderEnum_Enum.Stripe
  | BillingProviderEnum_Enum.QuickbooksOnline
  | BillingProviderEnum_Enum.Workday;

interface IntegrationsTabProps {
  tabs: TabProps[];
}

function parseClientSettingsInfo<
  BPT extends Pick<
    BillingProviderToken,
    "account_id" | "token_string" | "created_at"
  >,
  BPDM extends Pick<
    BillingProviderDeliveryMethod,
    "delivery_method_configuration"
  > & { created_at?: Date },
>(data: {
  stripeTokens: Array<BPT> | undefined;
  stripeDeliveryMethods: Array<BPDM> | undefined;
  awsMarketplaceTokens: Array<BPT> | undefined;
  awsDeliveryMethods: Array<BPDM> | undefined;
  azureMarketplaceTokens: Array<BPT> | undefined;
  azureDeliveryMethods: Array<BPDM> | undefined;
}) {
  const stripeToken = data.stripeTokens?.[0] ?? null;
  const azureMarketplaceToken = data.azureMarketplaceTokens?.[0] ?? null;
  const { legacy_azure_client_id, legacy_azure_tenant_id } = {
    legacy_azure_client_id:
      azureMarketplaceToken?.token_string &&
      (
        JSON.parse(azureMarketplaceToken.token_string) as {
          azureClientId: string;
        }
      ).azureClientId,
    legacy_azure_tenant_id: azureMarketplaceToken?.account_id,
  };
  const deliveryMethodConfiguration = data.azureDeliveryMethods?.[0]
    ?.delivery_method_configuration as
    | {
        azure_client_id: string;
        azure_tenant_id: string;
      }
    | undefined;
  const azureConnectedAtString =
    data?.azureDeliveryMethods?.[0]?.created_at ||
    azureMarketplaceToken?.created_at;
  const awsMarketplaceToken = data.awsMarketplaceTokens?.[0] ?? null;
  const awsConnectedAtString =
    data?.awsDeliveryMethods?.[0]?.created_at ||
    awsMarketplaceToken?.created_at;
  const stripeConnectedAtString =
    data?.stripeDeliveryMethods?.[0]?.created_at || stripeToken?.created_at;

  return {
    stripe: {
      stripeAccountID:
        data?.stripeDeliveryMethods?.[0]?.delivery_method_configuration?.stripe_account_id?.toString() ||
        stripeToken?.account_id,
      stripeConnectedAt:
        !!stripeConnectedAtString && new Date(stripeConnectedAtString),
      stripeIsConfigured:
        !!data.stripeDeliveryMethods?.length || !!stripeConnectedAtString,
    },
    azure: {
      azureClientID:
        deliveryMethodConfiguration?.azure_client_id || legacy_azure_client_id,
      azureTenantID:
        deliveryMethodConfiguration?.azure_tenant_id ?? legacy_azure_tenant_id,
      azureMarketplaceIsConfigured:
        !!data.azureDeliveryMethods?.length || !!azureConnectedAtString,
      azureConnectedAt:
        !!azureConnectedAtString && new Date(azureConnectedAtString),
    },
    aws: {
      awsIAMRoleArn: awsMarketplaceToken?.token_string,
      awsExternalID: awsMarketplaceToken?.account_id,
      awsConnectedAt: !!awsConnectedAtString && new Date(awsConnectedAtString),
      awsMarketplaceIsConfigured:
        !!data.awsDeliveryMethods?.length || !!awsConnectedAtString,
    },
  };
}
const IntegrationsTab: React.FC<IntegrationsTabProps> = ({ tabs }) => {
  const INTEGRATION_DOCS = useDocsLink({
    contractsPath: "invoice-customers/solutions/",
    plansPath: "integrations/",
  });
  const { environmentType, environment, prefixUrl } = useEnvironment();
  const pushMessage = useSnackbar();

  const managedIntegrationsFeatureFlags = useFeatureFlag(
    "managed-integrations",
    [] as ManagedIntegrationEnum[],
  );

  const salesforceOnboardingEnabled = useFeatureFlag<boolean>(
    "salesforce-self-serve-onboarding",
    false,
  );

  const allowContractsEnabled = useContractsEnabled();

  const salesforceOnContractsEnabled =
    salesforceOnboardingEnabled && allowContractsEnabled;

  const [
    salesforceSettingsSideSheetIsOpen,
    setSalesforceSettingsSideSheetIsOpen,
  ] = useState(false);

  const [
    netsuiteEntityMappingsModalIsOpen,
    setNetsuiteEntityMappingsModalIsOpen,
  ] = useState(false);

  const [stripeEntityMappingsModalIsOpen, setStripeEntityMappingsModalIsOpen] =
    useState(false);

  const [azureMarketplaceSideSheetIsOpen, setAzureMarketplaceSideSheetIsOpen] =
    useState(false);

  const [awsMarketplaceSideSheetIsOpen, setAWSMarketplaceSideSheetIsOpen] =
    useState(false);

  const [stripeSettingsSideSheetIsOpen, setStripeSettingsSlideSheetIsOpen] =
    useState(false);

  // Queries
  const {
    data,
    loading: checkForConnectedAccountsLoading,
    error: checkForConnectedAccountsError,
  } = useCheckForConnectedAccountsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const {
    data: customFieldKeys,
    loading: customFieldKeysLoading,
    error: customFieldKeysError,
  } = useGetCustomFieldKeysQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const {
    data: getVendorEntityMappingsData,
    loading: getVendorEntityMappingsLoading,
    error: getVendorEntityMappingsError,
  } = useGetVendorEntityMappingsQuery({
    variables: {
      vendors: allVendors,
    },
  });

  const {
    data: salesforceData,
    loading: salesforceDataLoading,
    error: salesForceDataQueryError,
  } = useGetSalesforceWorkspaceQuery({
    variables: {
      destinationType: CensusDestinationTypeEnum.Salesforce,
    },
  });

  const [generateWorkatoSsoUrlMutation, { loading: workatoSsoUrlLoading }] =
    useGenerateWorkatoSsoUrlMutation();

  // Note: Even though this is a list resolver, we do not implement
  // pagination here since we do not expect this to return more than 100
  // delivery methods (the default page size). Even if all billing providers
  // configure all 3 delivery methods, there would be max ~30 entries.
  const {
    data: getDeliveryMethodsData,
    loading: getDeliveryMethodsLoading,
    error: getDeliveryMethodsError,
  } = useGetDeliveryMethodsQuery();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const salesforceOnboardingRedirect = params.get(
      "salesforce_redirect_callback",
    );

    // If the workspace is already provisioned then we should
    // not open the side sheet
    if (
      salesforceOnboardingRedirect &&
      salesforceData &&
      !salesforceData.get_census_workspace?.is_provisioned
    ) {
      setSalesforceSettingsSideSheetIsOpen(true);
    }
  }, [salesforceData]);

  const fetchWorkatoSsoUrl = async () => {
    try {
      const { data: workatoData } = await generateWorkatoSsoUrlMutation({
        variables: {},
      });
      if (!workatoData?.generate_workato_sso_url?.sso_url) {
        throw new Error("Failed to generate Workato SSO URL");
      }
      return workatoData.generate_workato_sso_url.sso_url;
    } catch (_) {
      pushMessage({
        content: "Failed to generate Workato SSO URL",
        type: "error",
      });
    }
  };

  const redirectToWorkatoUrl = async () => {
    const url = await fetchWorkatoSsoUrl();
    if (url) window.open(url, "_blank");
  };

  const stripeDeliveryMethods =
    getDeliveryMethodsData?.list_delivery_methods?.delivery_methods?.filter(
      (method) => method.billing_provider === BillingProviderEnum_Enum.Stripe,
    );
  const {
    stripe: { stripeAccountID, stripeConnectedAt, stripeIsConfigured },
    azure: {
      azureClientID,
      azureTenantID,
      azureMarketplaceIsConfigured,
      azureConnectedAt,
    },
    aws: {
      awsIAMRoleArn,
      awsExternalID,
      awsConnectedAt,
      awsMarketplaceIsConfigured,
    },
  } = parseClientSettingsInfo({
    awsMarketplaceTokens: data?.awsMarketplaceTokens,
    awsDeliveryMethods:
      getDeliveryMethodsData?.list_delivery_methods?.delivery_methods?.filter(
        (method) =>
          method.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
      ),
    azureMarketplaceTokens: data?.azureMarketplaceTokens,
    azureDeliveryMethods:
      getDeliveryMethodsData?.list_delivery_methods?.delivery_methods?.filter(
        (method) => method.billing_provider === BillingProviderEnum_Enum.Azure,
      ),
    stripeTokens: data?.stripeTokens,
    stripeDeliveryMethods,
  });

  const isSalesforceProvisioned =
    salesforceData?.get_census_workspace?.is_provisioned ?? false;
  /** 
  this variable is used to gate the Workato integration settings buttons.
  We only support Production environment for Workato integrations because we don't have separate environments in Workato.
  */
  const isProduction = environmentType === EnvironmentTypeEnum_Enum.Production;

  const { data: clientConfig } = useGetClientConfigQuery();
  const clientHasDeltaStreamEnabled = clientConfig?.is_delta_stream_enabled;

  // Integration map to be used to render the integrations table
  const integrationMap: Readonly<Record<string, IntegrationRow>> = {
    NetSuite: {
      name: BillingProviderEnum_Enum.Netsuite,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="NetSuite"
          supportingText="Accounting/Revenue"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: false,
        editMappingButtonOnClick: () =>
          setNetsuiteEntityMappingsModalIsOpen(true),
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: isProduction
          ? "Manage your integration via Workato"
          : "Workato can only be accessed from the Production environment",
      },
    },
    Workday: {
      name: BillingProviderEnum_Enum.Workday,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Workday"
          supportingText="Human resources"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: isProduction
          ? "Manage your integration via Workato"
          : "Workato can only be accessed from the Production environment",
      },
    },
    QuickbooksOnline: {
      name: BillingProviderEnum_Enum.QuickbooksOnline,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Quickbooks Online"
          supportingText="Accounting"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: isProduction
          ? "Manage your integration via Workato"
          : "Workato can only be accessed from the Production environment",
      },
    },
    Stripe: {
      name: BillingProviderEnum_Enum.Stripe,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Stripe"
          supportingText="Payment processing"
        />
      ),
      ids: [
        {
          id: stripeAccountID ?? "",
          tooltip: "Stripe ID",
        },
      ],
      dateConnected: stripeConnectedAt
        ? new Date(stripeConnectedAt)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: !stripeAccountID,
        isEditMappingDisabled: false,
        editMappingButtonOnClick: () =>
          setStripeEntityMappingsModalIsOpen(true),
        settingsButtonOnClick: () => setStripeSettingsSlideSheetIsOpen(true),
        ...(clientHasDeltaStreamEnabled && {
          isSettingsDisabled: true,
          settingsButtonOnClick: () => {},
          toolTipText: getDeltaStreamGatingMessage("Stripe"),
        }),
      },
    },
    AzureMarketplace: {
      name: BillingProviderEnum_Enum.Azure,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Azure Marketplace"
          supportingText="Marketplace"
        />
      ),
      ids: [
        !!azureTenantID && {
          id: azureTenantID ?? "",
          tooltip: "Azure Tenant ID",
        },
        !!azureClientID && {
          id: azureClientID ?? "",
          tooltip: "Azure client ID",
        },
      ].filter((val): val is { id: string; tooltip: string } => !!val),
      dateConnected: azureConnectedAt ? new Date(azureConnectedAt) : undefined,
      rowButtonOptions: {
        isSettingsDisabled: false,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => setAzureMarketplaceSideSheetIsOpen(true),
        ...(clientHasDeltaStreamEnabled && {
          isSettingsDisabled: true,
          settingsButtonOnClick: () => {},
          toolTipText: getDeltaStreamGatingMessage("Azure Marketplace"),
        }),
      },
    },
    AwsMarketplace: {
      name: BillingProviderEnum_Enum.AwsMarketplace,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="AWS Marketplace"
          supportingText="Marketplace billing provider"
        />
      ),
      ids: [
        awsExternalID && {
          id: awsExternalID ?? "",
          tooltip: "External ID",
        },
        awsIAMRoleArn && {
          id: awsIAMRoleArn ?? "",
          tooltip: "AWS role ARN",
        },
      ].filter(
        (
          val: { id: string; tooltip: string } | "" | null | undefined,
        ): val is { id: string; tooltip: string } => !!val,
      ),
      dateConnected: awsConnectedAt ? new Date(awsConnectedAt) : undefined,
      rowButtonOptions: {
        isSettingsDisabled: false,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => setAWSMarketplaceSideSheetIsOpen(true),
        ...(clientHasDeltaStreamEnabled && {
          isSettingsDisabled: true,
          settingsButtonOnClick: () => {},
          toolTipText: getDeltaStreamGatingMessage("AWS Marketplace"),
        }),
      },
    },
    Salesforce: {
      name: CensusDestinationTypeEnum.Salesforce,
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Salesforce"
          supportingText="Customer relationship management"
        />
      ),
      dateConnected: salesforceData?.get_census_workspace?.created_at
        ? new Date(salesforceData.get_census_workspace.created_at)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: true,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => setSalesforceSettingsSideSheetIsOpen(true),
        toolTipText: "Manage your integration via Salesforce",
      },
      rowRoutePath: prefixUrl("/connections/integrations/salesforce"),
    },
  };

  const getConnectedIntegrations = () => {
    if (!data) return [];

    const integrationSet = new Set<IntegrationRow>();

    managedIntegrationsFeatureFlags?.forEach((integration) => {
      switch (integration) {
        case BillingProviderEnum_Enum.Netsuite:
          integrationSet.add(integrationMap.NetSuite);
          break;
        case BillingProviderEnum_Enum.Workday:
          integrationSet.add(integrationMap.Workday);
          break;
        case BillingProviderEnum_Enum.QuickbooksOnline:
          integrationSet.add(integrationMap.QuickbooksOnline);
          break;
      }
    });

    if (stripeIsConfigured) {
      integrationSet.add(integrationMap.Stripe);
    }
    if (azureMarketplaceIsConfigured) {
      integrationSet.add(integrationMap.AzureMarketplace);
    }
    if (awsMarketplaceIsConfigured) {
      integrationSet.add(integrationMap.AwsMarketplace);
    }
    if (isSalesforceProvisioned) {
      integrationSet.add(integrationMap.Salesforce);
    }

    return [...integrationSet];
  };

  const getAvailableIntegrationCards = () => {
    const connectedIntegrationNames = getConnectedIntegrations().map(
      (integration) => integration.name,
    );

    const allIntegrationNames = [
      ...Object.values(BillingProviderEnum_Enum),
      CensusDestinationTypeEnum.Salesforce,
    ];
    const availableIntegrationNames = allIntegrationNames.filter(
      (name) => !connectedIntegrationNames.includes(name),
    );

    return availableIntegrationNames
      .map((integrationName) => {
        switch (integrationName) {
          case BillingProviderEnum_Enum.Stripe:
            return (
              <StripeIntegrationCard
                className="max-w-xl"
                buttonOptions={{
                  buttonOnClick: () =>
                    stripeRedirect(environmentType, environment),
                }}
              />
            );
          case BillingProviderEnum_Enum.Azure:
            return (
              <AzureIntegrationCard
                className="max-w-xl"
                buttonOptions={{
                  buttonOnClick: () => setAzureMarketplaceSideSheetIsOpen(true),
                }}
              />
            );
          case BillingProviderEnum_Enum.AwsMarketplace:
            return (
              <AwsIntegrationCard
                className="max-w-xl"
                buttonOptions={{
                  buttonOnClick: () => setAWSMarketplaceSideSheetIsOpen(true),
                }}
              />
            );
          case CensusDestinationTypeEnum.Salesforce:
            return salesforceOnContractsEnabled ? (
              <SalesforceIntegrationCard
                className="max-w-xl"
                buttonOptions={{
                  buttonOnClick: () =>
                    setSalesforceSettingsSideSheetIsOpen(true),
                }}
              />
            ) : null;
          default:
            return null;
        }
      })
      .filter((card): card is React.ReactElement => card !== null);
  };

  const error =
    checkForConnectedAccountsError ||
    customFieldKeysError ||
    salesForceDataQueryError ||
    getDeliveryMethodsError;
  const loading =
    checkForConnectedAccountsLoading ||
    customFieldKeysLoading ||
    salesforceDataLoading ||
    getDeliveryMethodsLoading;

  const vendorEntityMappingData =
    getVendorEntityMappingsData &&
    mapVendorEntityData(getVendorEntityMappingsData);

  const connectedIntegrations = loading ? [] : getConnectedIntegrations();
  const availableIntegrations = loading ? [] : getAvailableIntegrationCards();

  return (
    <>
      {error ? (
        <EmptyState
          icon="layersThree02"
          mainText="We ran into an issue loading this page"
          supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      ) : (
        <div className="flex flex-col gap-[20px]">
          {salesforceSettingsSideSheetIsOpen && salesforceOnboardingEnabled && (
            <SalesforceOnboardingSideSheet
              onClose={setSalesforceSettingsSideSheetIsOpen}
              isOpen={salesforceSettingsSideSheetIsOpen}
            />
          )}
          {stripeSettingsSideSheetIsOpen && stripeIsConfigured ? (
            <StripeSettingsSideSheet
              setStripeSettingsSlideSheetIsOpen={
                setStripeSettingsSlideSheetIsOpen
              }
              isOpen={stripeSettingsSideSheetIsOpen}
              connectedOn={
                !!stripeConnectedAt ? new Date(stripeConnectedAt) : undefined
              }
              tokenID={stripeAccountID ?? ""}
              hasStripeOnContractsSetUp={
                (stripeDeliveryMethods ?? []).length > 0
              }
            />
          ) : null}
          {netsuiteEntityMappingsModalIsOpen && (
            <DeprecatedEntityMappingModal
              key="NetSuite"
              onClose={() => {
                setNetsuiteEntityMappingsModalIsOpen(false);
              }}
              vendor={ManagedVendorEnum_Enum.NetSuite}
              vendorEntityMappings={vendorEntityMappingData?.NetSuite || null}
              customFieldKeys={
                (customFieldKeys?.ManagedFieldKey ?? []) as ManagedFieldKey[]
              }
              loading={getVendorEntityMappingsLoading}
              error={getVendorEntityMappingsError}
            />
          )}
          {stripeEntityMappingsModalIsOpen && (
            <DeprecatedEntityMappingModal
              key="Stripe"
              onClose={() => {
                setStripeEntityMappingsModalIsOpen(false);
              }}
              vendor={ManagedVendorEnum_Enum.Stripe}
              vendorEntityMappings={vendorEntityMappingData?.Stripe || null}
              customFieldKeys={
                (customFieldKeys?.ManagedFieldKey ?? []) as ManagedFieldKey[]
              }
              loading={getVendorEntityMappingsLoading}
              error={getVendorEntityMappingsError}
            />
          )}
          {azureMarketplaceSideSheetIsOpen ? (
            <AzureSideSheet
              onClose={setAzureMarketplaceSideSheetIsOpen}
              isOpen={azureMarketplaceSideSheetIsOpen}
              azureClientID={azureClientID ?? undefined}
              azureTenantID={azureTenantID}
              connectedOn={
                azureConnectedAt ? new Date(azureConnectedAt) : undefined
              }
              isConfigured={azureMarketplaceIsConfigured}
            />
          ) : null}
          {awsMarketplaceSideSheetIsOpen ? (
            <AWSSideSheet
              onClose={() => setAWSMarketplaceSideSheetIsOpen(false)}
              isOpen={awsMarketplaceSideSheetIsOpen}
              awsExternalID={awsExternalID || undefined}
              awsIAMRoleARN={awsIAMRoleArn || undefined}
              connectedOn={
                awsConnectedAt ? new Date(awsConnectedAt) : undefined
              }
            />
          ) : null}
          {connectedIntegrations.length > 0 && (
            <IntegrationsTable rows={connectedIntegrations} loading={loading} />
          )}
          {availableIntegrations.length > 0 && (
            <SectionHeader
              title="Available integrations"
              subtitle="Set up seamless payment processing and a variety of additional data sources to enrich your customer data."
              actions={[
                <Button
                  text="View documentation"
                  theme="secondary"
                  leadingIcon="arrowUpRight"
                  onClick={() => window.open(INTEGRATION_DOCS, "_blank")}
                />,
              ]}
              bottomBorder={false}
            />
          )}
          <div className="mb-12 grid max-w-full grid-cols-1 justify-items-stretch gap-24 md:grid-cols-2 lg:grid-cols-3">
            {availableIntegrations.map((integration, index) => {
              let alignmentClass = "";
              if ((index + 1) % 3 === 1) {
                alignmentClass = "justify-self-start";
              } else if ((index + 1) % 3 === 2) {
                alignmentClass = "justify-self-center";
              } else if ((index + 1) % 3 === 0) {
                alignmentClass = "justify-self-end";
              }
              return (
                <div key={index} className={twMerge(alignmentClass)}>
                  {integration}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default IntegrationsTab;

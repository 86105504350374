import React, { useCallback } from "react";
import { Modal } from "components/Modal";

type Props = {
  onClose: () => void;
};

const localStorageKey = "dontShowProductCreatedModal";
export function shouldShowProductCreatedModal() {
  return localStorage.getItem(localStorageKey) !== "true";
}

export function ProductCreatedModal({ onClose }: Props) {
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  const onCloseCallback = useCallback(() => {
    if (dontShowAgain) {
      localStorage.setItem(localStorageKey, "true");
    }
    onClose();
  }, [dontShowAgain, onClose]);

  return (
    <Modal
      icon="checkCircle"
      showCloseButton={true}
      title="New product created!"
      isOpen={true}
      headerLayout="horizontalLeft"
      buttonLayout="alignedRight"
      extraActions={{
        checkboxAction: {
          type: "checkbox",
          key: localStorageKey,
          props: {
            checked: dontShowAgain,
            label: "Don't show again",
            onClick: ({ checked }) => {
              setDontShowAgain(checked);
            },
          },
        },
      }}
      modalButtons={[
        {
          key: "ok",
          type: "button",
          props: {
            text: "View new product",
            theme: "primary",
            onClick: onCloseCallback,
          },
        },
      ]}
      size="lg"
      onClose={onCloseCallback}
    >
      <div className="ml-[80px] flex flex-col gap-12 text-sm text-gray-600">
        <div>
          Your product is ready to go! Products are priced on rate cards–they
          power what a customer is entitled to and their pricing.
        </div>
        <div>Your next step will be to add this product to a rate card.</div>
      </div>
    </Modal>
  );
}

import {
  BillingProviderEnum_Enum,
  Customer_Bool_Exp,
} from "types/generated-graphql/__types__";
import { BillingConfigFilters } from "app/pages/deprecated/Overview/filters";
import { dayjs } from "lib/dayjs";

export const CUSTOMER_BILLING_CONFIG_FILTER_MAP: Record<
  BillingConfigFilters,
  Customer_Bool_Exp
> = {
  none: {
    _not: {
      BillingProviderCustomers: {
        billing_provider: { _eq: BillingProviderEnum_Enum.Stripe },
      },
    },
  },
  charge_automatically: {
    BillingProviderCustomers: {
      billing_provider: { _eq: BillingProviderEnum_Enum.Stripe },
    },
    CustomerConfigs: {
      key: { _eq: "stripe_collection_method" },
      value: { _eq: "charge_automatically" },
    },
  },
  send_invoice: {
    BillingProviderCustomers: {
      billing_provider: { _eq: BillingProviderEnum_Enum.Stripe },
    },
    CustomerConfigs: {
      key: { _eq: "stripe_collection_method" },
      value: { _eq: "send_invoice" },
    },
  },
  aws_marketplace: {
    BillingProviderCustomers: {
      billing_provider: { _eq: BillingProviderEnum_Enum.AwsMarketplace },
    },
  },
};

export type CUSTOMER_PLAN_STATUS =
  | "no_plan"
  | "expired_plan"
  | "plan_expires_30d"
  | "archived";

export const CUSTOMER_PLAN_STATUS_CONFIG_FILTER_MAP: Record<
  CUSTOMER_PLAN_STATUS,
  (date: string) => Customer_Bool_Exp
> = {
  no_plan: (date: string) => ({
    _and: [
      { archived_at: { _is_null: true } },
      {
        // There is not
        _not: {
          // a plan
          CustomerPlans: {
            // that started already.
            start_date: {
              _lte: date,
            },
          },
        },
      },
    ],
  }),
  expired_plan: (date: string) => ({
    // Two conditions are true:
    _and: [
      { archived_at: { _is_null: true } },
      {
        // 1. There is not
        _not: {
          // a customer plan
          CustomerPlans: {
            // that
            _and: [
              {
                // started already
                start_date: {
                  _lte: date,
                },
              },
              // and
              {
                _or: [
                  // either never ends
                  {
                    cancellation_date: { _is_null: true },
                  },
                  // or ends in the future.
                  {
                    cancellation_date: { _gte: date },
                  },
                ],
              },
            ],
          },
        },
      },
      {
        // 2. There is a plan
        CustomerPlans: {
          // that started in the past.
          start_date: {
            _lte: date,
          },
        },
      },
    ],
  }),
  plan_expires_30d: (date: string) => {
    const latestAcceptedCancellationDate = dayjs
      .utc(date)
      .add(30, "days")
      .toISOString();
    return {
      _and: [
        { archived_at: { _is_null: true } },
        {
          CustomerPlans: {
            _and: [
              {
                start_date: {
                  _lte: date,
                },
              },
              {
                cancellation_date: {
                  _lte: latestAcceptedCancellationDate,
                },
              },
              {
                cancellation_date: {
                  _gte: date,
                },
              },
              {
                cancellation_date: {
                  _is_null: false,
                },
              },
            ],
          },
        },
      ],
    };
  },
  archived: (date: string) => {
    return { archived_at: { _is_null: false } };
  },
};

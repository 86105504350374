import React, { useEffect, useState } from "react";

import { useChangeNameMutation } from "./queries.graphql";

import { Button } from "components/Button";
import { useCurrentUser } from "app/lib/auth";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Input } from "design-system";

interface EditNameModalProps {
  onClose: () => void;
}

export const EditNameModal: React.FC<EditNameModalProps> = ({ onClose }) => {
  const { user } = useCurrentUser();

  const [change, changeResults] = useChangeNameMutation();

  const [name, setName] = useState("");
  useEffect(() => {
    setName(user?.name ?? "");
  }, [user?.name]);

  const save = async () => {
    if (!name) {
      return;
    }
    await change({
      variables: { name },
    });
    onClose();
  };

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        disabled={!name?.length || changeResults.loading}
        onClick={save}
        loading={changeResults.loading}
        text="Save"
        theme="primary"
      />
    </>
  );
  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen
      onRequestClose={onClose}
      title="Change your name"
    >
      <Input
        value={name}
        onChange={setName}
        error={!name ? "Required" : undefined}
        name="Name"
        placeholder="John Doe"
        autoFocus
      />
    </DeprecatedPopup>
  );
};

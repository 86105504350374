import React from "react";
import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { TextInput } from "components/Input";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  onChange: (rate: Schema.Types.Rate) => void;
}

export const RatePercentage: React.FC<Props> = ({
  rate,
  disabled,
  onChange,
}) => {
  const currentPrice =
    rate.price.type === "percentage" ? rate.price.fraction : undefined;
  return (
    <TextInput
      className="min-w-[200px]"
      disabled={disabled}
      value={currentPrice ? currentPrice.toString() : undefined}
      fullWidth
      disableClear
      type="text"
      variantOptions={{
        icon: "sale02",
        variant: "icon",
      }}
      onChange={({ value }) => {
        if (rate.price.type === "percentage") {
          onChange({
            ...rate,
            price: {
              ...rate.price,
              fraction: value ? Number(value) : undefined,
            },
          });
        }
      }}
    />
  );
};

import React from "react";
import { PlansTable } from "./PlansTable";
import { ProductsTable } from "./ProductsTable";
import { useNavigate } from "app/lib/useNavigate";
import { useRoutePathMatches } from "app/lib/routes/useRoutePathMatches";
import { useUIMode } from "app/lib/useUIMode";

import { PlansQuery, DraftPlansQuery } from "./queries.graphql";

export type Plan = PlansQuery["plans"][0];
export type DraftPlan = DraftPlansQuery["DraftPlan"][0];

export const PlansContainer: React.FC = () => {
  const navigate = useNavigate();
  const { mode } = useUIMode();

  const isProducts = useRoutePathMatches(["/offering/plans/products"]);

  const buttons = [
    {
      text: "Plans",
      onClick: () => navigate("/offering/plans"),
      isActive: !isProducts,
    },
    {
      text: "Products",
      onClick: () => navigate("/offering/plans/products"),
      isActive: !!isProducts,
    },
  ];

  if (mode === "plans-only") {
    return <PlansTable titleButtons={[]} />;
  }
  return isProducts ? (
    <ProductsTable titleButtons={buttons} />
  ) : (
    <PlansTable titleButtons={buttons} />
  );
};

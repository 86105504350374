import classnames from "classnames";
import { useApolloClient } from "@apollo/client";
import { useFeatureFlag } from "app/lib/launchdarkly";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { renderDateTime } from "lib/time";
import { ReactComponent as DataExportLogo } from "icons/data_export.svg";
import {
  Body,
  ButtonDropdown,
  LoadingSpinner,
  Subtitle,
  Tooltip,
} from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import {
  GeneratePrequelAuthTokenDocument,
  GeneratePrequelAuthTokenMutation,
  GeneratePrequelAuthTokenMutationVariables,
  useGetClientQuery,
} from "app/pages/Connections/tabs/DataExport/queries.graphql";
import { useNavigate } from "app/lib/useNavigate";
import { PREQUEL_API_URL } from "app/lib/dataExport";
import {
  ExistingDestination,
  useGetDestinations,
  useProductConfigs,
  useGetTransfers,
  useDeleteDestination,
  Transfer,
} from "@prequel/react";
import { EmptyState } from "components/EmptyState";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { useCurrentUser } from "app/lib/auth";
import { ButtonDropdownItem } from "design-system/components/ButtonDropdown";
import { useSnackbar } from "components/deprecated/Snackbar";
import { reportToSentry } from "app/lib/errors/sentry";
import { DocsLink } from "components/DocsLink";
import { Table } from "components/Table";
import { Badge, BADGE_VARIANTS } from "components/Badge";

interface DestinationModalContent {
  id: string;
  models: Record<string, string[]>;
}

const DEFAULT_PRODUCTS = [
  {
    name: "v1",
    label: "Billing Data",
  },
  {
    name: "events_v1",
    label: "Usage Data",
  },
  {
    name: "draft_invoices_v1",
    label: "Draft Invoice Data",
  },
];

const DataExportTab: React.FC = () => {
  const [transferErrorState, setTransferErrorState] = useState<boolean>(false);
  const [
    isGetDataExportDestinationsLoading,
    setIsGetDataExportDestinationsLoading,
  ] = useState(true);
  const [dataExportDestinations, setDataExportDestinations] = useState<
    ExistingDestination[] | undefined
  >();
  const [transferData, setTransferData] = useState<(Transfer | undefined)[]>();
  const [destinationModalContent, setDestinationModalContent] = useState<
    DestinationModalContent | undefined
  >();
  const [destinationToDelete, setDestinationToDelete] = useState<
    ExistingDestination | undefined
  >();

  const maxPrequelDestinations = useFeatureFlag<number>(
    "max-prequel-destinations",
    1,
  );

  const dataExportChangesDisabled =
    useFeatureFlag<boolean>("disable-data-export-ui-changes", false) || false;

  const navigate = useNavigate();
  const {
    data: clientData,
    loading: clientDataLoading,
    error: clientDataError,
  } = useGetClientQuery();
  const clientId = clientData?.Client[0].id || "";
  const dataExportDataLoading =
    clientDataLoading || isGetDataExportDestinationsLoading;
  const { isMetronomeAdmin } = useCurrentUser();

  const pushMessage = useSnackbar();

  const apolloClient = useApolloClient();
  const applicationOrigin = window.location.origin;

  async function fetchPrequelAuthToken(_clientId: string): Promise<string>;
  async function fetchPrequelAuthToken(
    destination?: ExistingDestination,
  ): Promise<string>;
  async function fetchPrequelAuthToken(
    arg?: string | ExistingDestination,
  ): Promise<string> {
    try {
      const data = (
        await apolloClient.mutate<
          GeneratePrequelAuthTokenMutation,
          GeneratePrequelAuthTokenMutationVariables
        >({
          mutation: GeneratePrequelAuthTokenDocument,
          variables: {
            applicationOrigin: applicationOrigin,
          },
        })
      ).data;
      if (!data) {
        throw new Error(
          "Failed to generate scoped auth token: no data returned",
        );
      }
      if (!data.generate_prequel_auth_token.token_string) {
        throw new Error(
          "Failed to generate scoped auth token: token_string was not returned",
        );
      }
      return data.generate_prequel_auth_token.token_string;
    } catch (e) {
      throw new Error(`Failed to generate scoped auth token: ${e}`);
    }
  }

  const dataExportDestinationResponse = useGetDestinations(
    fetchPrequelAuthToken,
    applicationOrigin,
    clientId,
    PREQUEL_API_URL,
  );

  const deleteDestination = useDeleteDestination(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const { product_configs: productConfigs } = useProductConfigs(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const getTransfersFromDestination = useGetTransfers(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  useEffect(() => {
    if (dataExportDestinationResponse) {
      const filteredDataExportDestinationResponse =
        dataExportDestinationResponse?.filter(
          (destination) => destination.id_in_provider_system === clientId,
        );
      setDataExportDestinations(filteredDataExportDestinationResponse);
    }
  }, [dataExportDestinationResponse]);

  const filteredDataExportDestinations: ExistingDestination[] | undefined =
    useMemo(() => {
      if (!dataExportDestinations) {
        return undefined;
      }

      if (dataExportDestinations.length === 0) {
        return [];
      }

      dataExportDestinations.sort((a, b) =>
        new Date(a.created_at || "").getTime() <
        new Date(b.created_at || "").getTime()
          ? -1
          : 1,
      );

      // UI will only allow the setup and display of max-prequel-destinations.
      // If additional destination(s) are created for client manually, they will not show up here in the UI for now
      return dataExportDestinations.slice(0, maxPrequelDestinations);
    }, [dataExportDestinations]);

  useEffect(() => {
    if (dataExportDestinations) {
      setIsGetDataExportDestinationsLoading(false);
    }
  }, [dataExportDestinations]);

  useEffect(() => {
    const fetchDataForDestinations = async () => {
      if (filteredDataExportDestinations) {
        const promises = filteredDataExportDestinations.map(async (dest) => {
          try {
            const response = await getTransfersFromDestination(dest, {
              count: 20,
            });

            if (response.status === "success") {
              return response;
            } else {
              setTransferErrorState(true);
              reportToSentry(
                new Error(
                  "Error fetching transfer objects: " + response.message ||
                    "Unknown",
                ),
              );
            }
          } catch (error: any) {
            setTransferErrorState(true);
            reportToSentry(
              new Error(
                "Error fetching transfer objects: " + error.message ||
                  "Unknown",
              ),
            );
          }
        });

        try {
          const responses = await Promise.all(promises);
          const fullTransferData = responses
            .filter((response) => response?.status === "success")
            .flatMap((t) => t?.data?.transfers);
          setTransferData(fullTransferData);
        } catch (error: any) {
          setTransferErrorState(true);
          reportToSentry(
            new Error(
              "Error fetching transfer objects: " + error.message || "Unknown",
            ),
          );
        }
      }
    };

    void fetchDataForDestinations();
  }, [filteredDataExportDestinations]);

  const onDeleteDestinationClick = useCallback(async () => {
    if (destinationToDelete) {
      const response = await deleteDestination(destinationToDelete);
      if (response.status === "success") {
        pushMessage({
          content: "Successfully deleted data export destination",
          type: "success",
        });
        navigate(0);
      } else {
        pushMessage({
          content: "Error deleting data export destination, please try again",
          type: "error",
        });
      }
    }
  }, [destinationToDelete]);

  return (
    <>
      {clientDataError || (isMetronomeAdmin && transferErrorState) ? (
        <EmptyState
          icon="layersThree02"
          mainText="We ran into an issue loading this page"
          supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      ) : dataExportDataLoading ? (
        <LoadingSpinner className="m-auto text-center" />
      ) : (
        <>
          {!filteredDataExportDestinations ||
          filteredDataExportDestinations?.length === 0 ? (
            <EmptyState
              icon="database02"
              mainText="No destinations"
              supportingText="Metronome can send your data directly
                  to your data warehouse, giving you the flexibility to build
                  reports and dashboards with your favorite data tools."
              actions={[
                <DocsLink
                  plansPath="developer-resources/export-metronome-data/"
                  contractsPath="developer-resources/data-export/"
                />,
                <Button
                  key="add-destination"
                  text="Add destination"
                  theme="primary"
                  disabled={
                    !(
                      !dataExportChangesDisabled &&
                      maxPrequelDestinations &&
                      filteredDataExportDestinations &&
                      filteredDataExportDestinations.length <
                        maxPrequelDestinations
                    )
                  }
                  leadingIcon="plus"
                  linkTo="new"
                />,
              ]}
            />
          ) : (
            <>
              <div className="mb-32 rounded-medium border border-deprecated-gray-100">
                <div className="flex bg-gray-50 p-12">
                  <span className="grow">Active destinations</span>
                  {!dataExportChangesDisabled &&
                    maxPrequelDestinations &&
                    filteredDataExportDestinations &&
                    filteredDataExportDestinations?.length <
                      maxPrequelDestinations && (
                      <Button
                        text="Add destination"
                        theme="primary"
                        leadingIcon="plus"
                        linkTo="new"
                      />
                    )}
                </div>
                <div>
                  {filteredDataExportDestinations?.map(
                    (dataExportDestination, idx) => {
                      const destinationOptions: ButtonDropdownItem[] = [
                        {
                          label: "Edit destination...",
                          routePath: `${dataExportDestination?.id}`,
                        },
                      ];
                      if (isMetronomeAdmin) {
                        destinationOptions.push({
                          label: "Delete destination...",
                          onClick: () =>
                            setDestinationToDelete(dataExportDestination),
                        });
                      }
                      return (
                        <div
                          key={idx}
                          className={classnames(
                            "flex items-center border-deprecated-gray-100 px-12 py-8",
                            {
                              "border-b":
                                idx !==
                                filteredDataExportDestinations?.length - 1,
                            },
                          )}
                        >
                          <DataExportLogo className="mr-12" />
                          <div className="flex grow flex-col">
                            <span className="text-xs text-gray-800">
                              {dataExportDestination.name}
                            </span>
                            <span className="flex items-center text-xxs uppercase text-gray-500">
                              {`${dataExportDestination.enabled_models?.length} models syncing`}
                              <Tooltip content="View models">
                                <IconButton
                                  onClick={() => {
                                    const syncedModels: Record<
                                      string,
                                      string[]
                                    > = {};

                                    dataExportDestination.enabled_models?.forEach(
                                      (m) => {
                                        const productName =
                                          productConfigs?.find(
                                            (c) =>
                                              DEFAULT_PRODUCTS.some(
                                                (p) =>
                                                  p.name === c.product_name &&
                                                  c.models.includes(m),
                                              ), // make sure only show productionized product labels
                                          )?.product_name;

                                        // In case models belong to non-productionized products, use "Other"
                                        const productLabel =
                                          DEFAULT_PRODUCTS.find(
                                            (p) => p.name === productName,
                                          )?.label || "Other";

                                        syncedModels[productLabel] = [
                                          ...(syncedModels[productLabel] || []),
                                          m,
                                        ];
                                      },
                                    );

                                    setDestinationModalContent({
                                      id: dataExportDestination.id,
                                      models: syncedModels,
                                    });
                                  }}
                                  theme="tertiary"
                                  icon="dotsVertical"
                                />
                              </Tooltip>
                            </span>
                          </div>
                          <div className="flex items-center">
                            {renderDateTime(
                              new Date(dataExportDestination?.created_at || ""),
                              true,
                              "Connected on",
                            )}
                          </div>
                          <ButtonDropdown
                            icon="settings"
                            buttonTheme="gray"
                            buttonType="fill"
                            className="ml-8 text-gray-800"
                            items={destinationOptions}
                          />
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
              {isMetronomeAdmin &&
                filteredDataExportDestinations?.length > 0 && (
                  <div>
                    <div>
                      <Table
                        title="Transfer log"
                        loading={dataExportDataLoading}
                        emptyState={
                          <EmptyState
                            icon="database02"
                            mainText="No transfer history"
                          />
                        }
                        columns={[
                          {
                            id: "destinationName",
                            header: "Destination",
                            cell: (props) => props.getValue(),
                            accessorFn: (transfer) => (
                              <div>
                                {dataExportDestinations?.find(
                                  (d) => d.id === transfer.destination_id,
                                )?.name || "oops"}
                              </div>
                            ),
                          },
                          {
                            id: "modelsTransfered",
                            header: "Models transferred",
                            cell: (props) => (
                              <div className="min-w-[400px] whitespace-normal">
                                {props.getValue()}
                              </div>
                            ),
                            accessorFn: (transfer) => (
                              <>{transfer.models.join(", ")}</>
                            ),
                            enableSorting: false,
                          },
                          {
                            id: "status",
                            header: "Status",
                            cell: (props) => props.getValue(),
                            accessorFn: (transfer) => {
                              let theme: keyof typeof BADGE_VARIANTS =
                                "success";
                              switch (transfer.status) {
                                case "PENDING":
                                case "RUNNING":
                                  theme = "gray";
                                  break;
                                case "ERROR":
                                  theme = "error";
                                  break;
                                case "PARTIAL_FAILURE":
                                  theme = "warning";
                                  break;
                                default:
                                  theme = "success";
                                  break;
                              }

                              return (
                                <Badge
                                  theme={theme}
                                  size="sm"
                                  label={transfer.status}
                                ></Badge>
                              );
                            },
                            enableSorting: false,
                          },
                          {
                            id: "transferCount",
                            header: "Rows transferred",
                            cell: (props) => props.getValue(),
                            accessorFn: (transfer) => (
                              <span style={{ textAlign: "right" }}>
                                {transfer.status === "SUCCESS"
                                  ? new Intl.NumberFormat("en-US").format(
                                      transfer.rows_transferred,
                                    )
                                  : "-"}
                              </span>
                            ),
                            enableSorting: false,
                          },
                          {
                            id: "timestamp",
                            header: "Timestamp (UTC)",
                            cell: (props) => props.getValue(),
                            accessorFn: (transfer) => {
                              return renderDateTime(
                                new Date(
                                  transfer?.ended_at ||
                                    transfer?.started_at ||
                                    transfer.submitted_at ||
                                    "",
                                ),
                                false,
                              );
                            },
                          },
                        ]}
                        paginationOptions={{
                          type: "clientSide",
                          pageSize: 10,
                        }}
                        data={
                          (transferData ?? []).filter(Boolean) as Transfer[]
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          )}
        </>
      )}
      <DeprecatedPopup
        actions={
          <>
            <Button
              text="Edit destination"
              theme="secondary"
              linkTo={`${destinationModalContent?.id}`}
            />
            <Button
              onClick={() => setDestinationModalContent(undefined)}
              text="Done"
              theme="primary"
            />
          </>
        }
        isOpen={!!destinationModalContent}
        onRequestClose={() => setDestinationModalContent(undefined)}
        title="Models Syncing"
        size="lg"
        fullContent={true}
        className="!w-[700px]" // the current setup for className plus other css module styles does not work.
      >
        {destinationModalContent && (
          <Body level={2}>
            Current models connect to this destination. Hit edit to update these
            models.
            <div className="mt-12 flex flex-wrap justify-between gap-x-8">
              {Object.keys(destinationModalContent?.models).map((k, kIdx) => (
                <div key={kIdx}>
                  <Subtitle className="text-gray-700 mb-12" level={3}>
                    {k}
                  </Subtitle>
                  {destinationModalContent?.models[k].map((m, mIdx) => (
                    <Subtitle key={mIdx} level={4} className="mb-12">
                      {m}
                    </Subtitle>
                  ))}
                </div>
              ))}
            </div>
          </Body>
        )}
      </DeprecatedPopup>
      <DeprecatedPopup
        actions={
          <>
            <Button
              onClick={() => setDestinationToDelete(undefined)}
              text="Cancel"
              theme="secondary"
            />
            <Button
              onClick={onDeleteDestinationClick}
              text="Delete"
              theme="linkDestructive"
            />
          </>
        }
        isOpen={!!destinationToDelete}
        onRequestClose={() => setDestinationToDelete(undefined)}
        title="Delete destination"
      >
        {!!destinationToDelete && (
          <Body level={2}>
            Deleting a destination will immediately stop any pending or upcoming
            transfers. To setup a new destination you will need to authenticate
            and test the new destination. Are you sure you want to delete this
            destination?
          </Body>
        )}
      </DeprecatedPopup>
    </>
  );
};

export default DataExportTab;

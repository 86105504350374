import React from "react";
import { type RouteObject } from "react-router";

import NotFoundPage from "app/pages/404";
import { TabProps } from "components/deprecated/Tabs";
import { EnvironmentRedirect } from "app/lib/environmentSwitcher/EnvironmentRedirect";

const tabs = [
  {
    link: "integrations",
    name: "Integrations",
  },
  {
    link: "data-export",
    name: "Data Export",
  },
  {
    link: "custom-fields",
    name: "Custom fields",
  },
  {
    link: "invoicing",
    name: "Invoicing",
  },
  {
    link: "pricing-units",
    name: "Pricing units",
  },
  {
    link: "audit-logs",
    name: "Audit logs",
  },
];

export const INVOICING_TAB_FEATURE_FLAG = "invoicing-settings-tab";

export class TabFilter {
  invoicingTabEnabled: boolean;
  constructor(invoicingTabEnabled: boolean) {
    this.invoicingTabEnabled = invoicingTabEnabled;
  }

  filter = (t: TabProps): boolean => {
    return !t.routePath?.endsWith("invoicing") || this.invoicingTabEnabled;
  };
}

export const GeneralSettingsRoute: RouteObject = {
  path: "settings/general",
  children: [
    { index: true, element: <EnvironmentRedirect to="integrations" /> },
    ...tabs.map((tab) => ({
      ...(tab.link ? { path: tab.link } : { index: true }),
      element: <EnvironmentRedirect to="/connections/events" />,
    })),
    { path: "*", element: <NotFoundPage /> },
  ],
};

import React from "react";

import { DeprecatedProductChargeLineItem } from "./lineItems/ProductChargeLineItem";
import { DeprecatedCreditPurchaseLineItem } from "./lineItems/CreditPurchaseLineItem";
import { NonContractLineItem } from "./types";

export const DeprecatedLineItem: React.FC<{
  invoiceId: string;
  lineItem: NonContractLineItem;
  showChargesWithZeroUsage: boolean;
}> = ({ lineItem, showChargesWithZeroUsage, invoiceId }) => {
  switch (lineItem.__typename) {
    case "ProductChargeLineItem":
    case "GroupedProductChargeLineItem":
    case "LegacyContractLineItem":
    case "LegacyLineItem":
      return (
        <DeprecatedProductChargeLineItem
          invoiceId={invoiceId}
          lineItem={lineItem}
          showChargesWithZeroUsage={showChargesWithZeroUsage}
        />
      );
    case "CreditPurchaseLineItem": {
      return <DeprecatedCreditPurchaseLineItem lineItem={lineItem} />;
    }
    // These are all rendered as part of the (sub)totals, so we don't need to render them here.
    case "CreditLineItem":
    case "OverageLineItem":
    case "MinimumLineItem":
    case "TrialDiscountLineItem": {
      return null;
    }
    // These are all sub line items and should never be top level line items
    case "ChargeLineItem":
    case "AdvanceChargeLineItem":
    case "SeatChargeLineItem":
    case "CreditPurchaseChargeLineItem":
    case "GroupedChargeLineItem":
    case "CompositeChargeLineItem":
    case "TierChargeLineItem": {
      throw new Error(
        `Unexpectedly received ${lineItem.__typename} as a top level line item.`,
      );
    }
  }
  // If this is failing to compile, it means that we've added a new line item type which is not handled above. To fix it we need to handle the new type above.
  lineItem satisfies never;
};

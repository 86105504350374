import React, { useState } from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { NumericInput, Body, Label } from "design-system";
import { Button } from "components/Button";
import pluralize from "pluralize";
import styles from "./index.module.less";

interface SetRecurrenceDurationModalProps {
  onClose: (value?: number) => void;
  initialValue?: number;
}

export const SetRecurrenceDurationModal: React.FC<
  SetRecurrenceDurationModalProps
> = (props) => {
  const [value, setValue] = useState<number | undefined>(props.initialValue);
  return (
    <DeprecatedPopup
      actions={[
        <Button
          onClick={() => props.onClose(props.initialValue)}
          key="cancel"
          text="Cancel"
          theme="linkGray"
        />,
        <Button
          disabled={!value}
          onClick={() => props.onClose(value)}
          key="save"
          text="Save"
          theme="primary"
        />,
      ]}
      title="Enter custom grant lifecycle"
      isOpen={true}
      onRequestClose={() => props.onClose(props.initialValue)}
    >
      <Body level={2}>
        Determine at most how many grants should be issued as part of this plan
      </Body>
      <div className={styles.container}>
        <NumericInput
          placeholder="Enter number of grants"
          name="Issue grants until..."
          value={value}
          onChange={(v) => setValue(v ?? undefined)}
          className={styles.input}
        />
        <Label className={styles.grantsText}>
          {pluralize("grant", value ?? 0)} {value === 1 ? "has" : "have"} been
          issued
        </Label>
      </div>
    </DeprecatedPopup>
  );
};

import { z } from "zod";

import { FormController, FormReader } from "app/lib/FormController";

import { Schema } from "../../Schema";

const ParentFields = z.object({
  billingScheduleFrequency: z
    .enum(Schema.BILLING_SCHEDULE_FREQUENCY)
    .default("one"),
  billingSchedule: Schema.BillingSchedule,
  billingScheduleCreditTypeId: z.string().uuid(),
});

type ParentShape = (typeof ParentFields)["shape"];
export type ParentCtrl = FormController<ParentShape>;

const RootSchema = z.object({
  frequency: z.enum(Schema.BILLING_SCHEDULE_FREQUENCY),
  schedule: Schema.BillingSchedule,
  creditTypeId: z.string().uuid(),
});
type RootShape = (typeof RootSchema)["shape"];

export const useRootCtrl = FormController.makeChildHook(RootSchema, {
  read(parent: FormReader<ParentShape>, _) {
    return {
      frequency: parent.get("billingScheduleFrequency"),
      schedule: parent.get("billingSchedule"),
      creditTypeId: parent.get("billingScheduleCreditTypeId"),
    };
  },
  write(child: FormReader<RootShape>, _, defaultCreditTypeId: string) {
    return {
      billingScheduleFrequency: child.get("frequency"),
      billingSchedule: child.get("schedule"),
      billingScheduleCreditTypeId:
        child.get("creditTypeId") || defaultCreditTypeId,
    };
  },
});

export type RootCtrl = ReturnType<typeof useRootCtrl>;

import React from "react";
import Decimal from "decimal.js";
import { giveUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import { Commit } from "app/pages/Contracts/lib/Commit";

import {
  useCommitUsageQuery,
  CommitUsageQueryHookResult,
  useAllCommitsUsageQuery,
  AllCommitsUsageQueryHookResult,
  CommitUsageDataFragment,
  ContractCommitUsageDataFragment,
} from "./data.graphql";
import { isInRange, toDayjs } from "lib/date";

export class AsyncCommitUsage {
  public readonly loading: boolean;
  public readonly error?: Error;
  private readonly usageById?: Map<string, CommitUsageDataFragment>;

  constructor(
    req: CommitUsageQueryHookResult | AllCommitsUsageQueryHookResult,
  ) {
    this.loading = !!(req.loading || !req.called);
    if (this.loading) {
      return;
    }

    if (req.error) {
      this.error = req.error;
      return;
    }

    const customer = req.data?.customer;
    if (!customer) {
      this.error = giveUserFacingErrorMessage(
        new Error("Missing customer data in response"),
        "Failed to find customer",
      );
      return;
    }

    let contracts: ContractCommitUsageDataFragment[];
    if ("contract" in customer) {
      const contract = customer.contract;
      if (!contract) {
        this.error = giveUserFacingErrorMessage(
          new Error("Missing contract data in response"),
          "Failed to find contract",
        );
        return;
      }
      contracts = [contract];
    } else {
      contracts = customer.contracts;
    }

    this.usageById = new Map();
    for (const contract of contracts) {
      for (const commit of contract.v2_fields?.commits_union ?? []) {
        this.usageById.set(commit.id, commit);
      }
    }
    if ("commits" in customer) {
      for (const commit of customer.commits) {
        this.usageById.set(commit.id, commit);
      }
    }
  }

  forCommit(commitId: string) {
    const commit = this.usageById?.get(commitId);
    if (commit === undefined) {
      return undefined;
    }

    const total = Commit.getTotal(commit);

    const remaining = commit.access_schedule.schedule_items.reduce(
      (acc, item) => acc.plus(item.remaining_balance),
      new Decimal(0),
    );
    const activeRemaining = commit.access_schedule.schedule_items
      .filter((segment) =>
        isInRange(
          toDayjs(new Date()),
          toDayjs(segment.date),
          toDayjs(segment.end_date),
        ),
      )
      .reduce((acc, item) => acc.plus(item.remaining_balance), new Decimal(0));

    const used = total.minus(remaining);
    return { total, remaining, activeRemaining, used };
  }

  forCommitAccessSegment(commitId: string, segmentId: string) {
    const commit = this.usageById?.get(commitId);
    if (commit === undefined) {
      throw new Error("invalid commit id");
    }

    const segment = commit.access_schedule.schedule_items.find(
      (s) => s.id === segmentId,
    );
    if (!segment) {
      throw new Error("invalid commit segment id");
    }

    const total = new Decimal(segment.amount);
    const remaining = new Decimal(segment.remaining_balance);
    const used = total.minus(remaining);
    return { total, remaining, used };
  }
}

export function useAsyncCommitUsage(opts: {
  customerId: string;
  contractId: string;
}) {
  const req = useCommitUsageQuery({
    variables: opts,
  });
  return React.useMemo(() => new AsyncCommitUsage(req), [opts.contractId, req]);
}

export function useAsyncAllCommitUsage(opts: { customerId: string }) {
  const req = useAllCommitsUsageQuery({ variables: opts });
  return React.useMemo(() => new AsyncCommitUsage(req), [opts.customerId, req]);
}

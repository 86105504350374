import React from "react";
import pluralize from "pluralize";
import Decimal from "decimal.js";
import { RecurringScheduleFrequency } from "types/generated-graphql/__types__";

import { RoundedCurrency, USD_CREDIT_TYPE } from "app/lib/credits";
import { CreditType } from "app/types/credit-types";

const FREQ_DESC: Record<RecurringScheduleFrequency, string> = {
  [RecurringScheduleFrequency.Annual]: "year",
  [RecurringScheduleFrequency.Monthly]: "month",
  [RecurringScheduleFrequency.Quarterly]: "quarter",
  [RecurringScheduleFrequency.SemiAnnual]: "6 months",
};

export type TermRateProps =
  | { type: "pro_service"; unitPrice: Decimal }
  | {
      type: "royalty";
      fraction: string | null | undefined;
    }
  | {
      type: "discount" | "scheduled";
      total: Decimal;
      invoiceCount: number;
      creditType: CreditType;
    }
  | {
      type: "scheduled_recurring" | "discount_recurring";
      total: Decimal;
      invoiceCount: number;
      frequency: RecurringScheduleFrequency;
      creditType: CreditType;
    };

export const TermRate: React.FC<TermRateProps> = (term) => {
  switch (term.type) {
    case "pro_service":
      if (!term.unitPrice) {
        return <>--</>;
      }
      return (
        <RoundedCurrency amount={term.unitPrice} creditType={USD_CREDIT_TYPE} />
      );

    case "royalty":
      if (!term.fraction) {
        return <>--</>;
      }

      const pct = new Decimal(term.fraction).mul(100).negated();
      return <>{pct.toString()}% of charge totals</>;

    case "scheduled_recurring":
    case "discount_recurring":
      const amount = term.total.mul(
        term.type === "discount_recurring" ? -1 : 1,
      );

      const amountElement = (
        <RoundedCurrency amount={amount} creditType={term.creditType} />
      );

      // special case for semi-annual frequency
      if (term.frequency === RecurringScheduleFrequency.SemiAnnual) {
        return (
          <>
            {amountElement} over {term.invoiceCount * 6} months, semi-annually
          </>
        );
      }

      return (
        <>
          {amountElement} across{" "}
          {pluralize(FREQ_DESC[term.frequency], term.invoiceCount, true)}
        </>
      );

    case "discount":
    case "scheduled":
      return (
        <>
          <RoundedCurrency
            amount={term.total.mul(term.type === "discount" ? -1 : 1)}
            creditType={term.creditType}
          />{" "}
          across {pluralize("invoice", term.invoiceCount, true)}
        </>
      );
  }
};

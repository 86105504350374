import React from "react";
import classNames from "classnames";
import { Tooltip } from "design-system";

import { IconButton } from "components/IconButton";

import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { useRoutePathMatches } from "app/lib/routes/useRoutePathMatches";

import { Breadcrumbs, Breadcrumb } from "./Breadcrumbs";

interface Props extends React.PropsWithChildren {
  breadcrumbs: Breadcrumbs;
  className?: string;
  children?: React.ReactNode;
}

function interlace(array: Breadcrumb[]): React.ReactElement[] {
  const result: React.ReactElement[] = [];

  for (let i = 0; i < array.length; i++) {
    if (i > 0 && array[i - 1]?.type !== "back") {
      result.push(
        <span
          key={`space-${result.length}`}
          className="px-8 text-deprecated-gray-light"
        >
          /
        </span>,
      );
    }

    const bc = array[i];
    result.push(<PageHeaderBreadcrumb key={bc.key} bc={bc} />);
  }

  return result;
}

const PageHeaderBreadcrumb: React.FC<{ bc: Breadcrumb }> = ({ bc }) => {
  const match = useRoutePathMatches(
    bc.type !== "breadcrumb" || !bc.routePath
      ? []
      : [`${bc.routePath}${bc.isLast ? "/*" : ""}`],
  );

  if (bc.type === "loading") {
    return (
      <DeprecatedTextSkeleton className="!my-[10px] !w-[200px] bg-black !p-4" />
    );
  }

  if (bc.type === "back") {
    return (
      <Tooltip key={bc.key} content={bc.label} position={["right"]}>
        <IconButton
          className="!-ml-[6px] mr-8 !h-auto !w-auto stroke-[currentColor] !p-4"
          theme="tertiary"
          icon="chevronLeft"
          linkTo={bc.routePath}
        />
      </Tooltip>
    );
  }

  if (!bc.routePath || match) {
    return (
      <span className="inline-flex items-center gap-12">
        <span
          key={bc.key}
          className={classNames(
            "p-4 text-lg leading-3",
            bc.isLast ? "text-black" : "text-deprecated-gray-medium",
          )}
        >
          {bc.label}
        </span>
        {bc.badge}
      </span>
    );
  }

  return (
    <DeprecatedInternalLink
      key={bc.key}
      routePath={bc.routePath}
      className={classNames(
        "rounded-medium p-4 align-middle text-lg leading-3",
        "hover:bg-deprecated-gray-lightest",
        bc.isLast ? "text-black" : "text-deprecated-gray-medium",
      )}
    >
      {bc.label}
      {bc.badge}
    </DeprecatedInternalLink>
  );
};

/**
 * Render a list of breadcrumbs, with a separator between each item in the header.
 */
export const PageHeaderBreadcrumbs: React.FC<Props> = ({
  breadcrumbs,
  className,
  children,
}) => (
  <div className={className}>
    {interlace(breadcrumbs.toArray())}
    {children}
  </div>
);

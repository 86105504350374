import React, { useState } from "react";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import styles from "./index.module.less";
import { Body, Headline } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { DeprecatedToggleButtons } from "components/deprecated/ToggleButtons";
import { Tooltip } from "design-system";
import { AdvanceChargePreview } from "./preview";
import { AdvanceChargeCollectionSchedule } from "./types";
import pluralize from "pluralize";
import { Select } from "design-system";
import {
  BillingDayOfPeriod,
  FlatFee,
  PricedProduct,
  PriceRamp,
} from "app/lib/plans/types";
import { getRampStartPeriods } from "app/lib/plans/ramps";
import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";
import { twMerge } from "design-system/twMerge";

// Returns the array [1, ..., n]
const range = (n: number) => [...Array(n)].map((_, i) => i + 1);

/**
 * Why do we do this?
 * So we can change the recurrence schedule later on. This prevents us from 100% variable collection periods
 * You can only set a fixed charge as an advanced charge in the first period (there for this avoids potential weird rampings)
 * If a ramp isn't divisible by ALL future ramp durations then we can't override the recurring periods later
 * */
function validCollectionIntervals(
  rampLength: number | "INFINITY",
  rampStart: number,
  durationOfAllRamps: number,
): Array<number> {
  const maxInterval = 18;

  const landsOnEndOfPeriod = (potentialCollectionInterval: number) => {
    const startPeriod = rampStart;
    const periodsUntilTheEnd = durationOfAllRamps - startPeriod;
    return periodsUntilTheEnd % potentialCollectionInterval === 0;
  };

  if (rampLength !== "INFINITY") {
    const under = range(Math.min(rampLength, maxInterval)).filter(
      (n) => rampLength % n === 0,
    );
    const over = range(maxInterval).filter(
      (n) => n % rampLength === 0 && landsOnEndOfPeriod(n),
    );
    return Array.from(new Set([...under, ...over]));
  } else {
    return range(maxInterval);
  }
}

interface AdvanceChargePaneProps {
  onRequestClose: () => void;
  onRequestSave: (pricingFactorId: string, newFlatFees: FlatFee[]) => void;
  pricingFactorId: string;
  ramps: PriceRamp[];
  rampIndex: number;
  rampStart: number;
  billingFrequency: BillingFrequencyEnum_Enum;
  billingDayOfPeriod: BillingDayOfPeriod;
  initialState: FlatFee[];
  pricedProduct: PricedProduct;
  planStart?: Date;
  planEnd?: Date;
}

export const AdvanceChargePane: React.FC<AdvanceChargePaneProps> = (props) => {
  const [flatFees, setFlatFees] = useState<FlatFee[]>([
    ...props.initialState.map((ff) => {
      return {
        ...ff,
        collectionSchedule: ff.isProrated ? "ADVANCE" : ff.collectionSchedule,
      };
    }),
  ]);

  const rampStartPeriods = getRampStartPeriods(props.ramps);

  const ramps = props.ramps.map((ramp, rampIndex) => ({
    durationInPeriods: ramp.durationInPeriods,
    startPeriod: rampStartPeriods[rampIndex],
    collectionInterval:
      flatFees[0].collectionSchedule === "ADVANCE" &&
      flatFees[0].collectionInterval
        ? rampIndex >= props.rampIndex // We always set collection interval for future ramps
          ? flatFees[0].collectionInterval
          : props.pricedProduct.pricingFactors.find(
              (pppf) =>
                pppf.startPeriod === rampStartPeriods[rampIndex] &&
                pppf.pricingFactorId === props.pricingFactorId,
            )?.flatFees?.[0]?.collectionInterval ??
            flatFees[0].collectionInterval
        : undefined,
  }));

  const durationOfAllRamps = ramps.reduce(
    (acc, ramp) => acc + (ramp.durationInPeriods ?? 0),
    0,
  );

  // If it's the first ramp, only allow intervals that are applicable to all ramps
  const intervalOptions =
    props.rampIndex === 0
      ? props.ramps.reduce(
          (prev, curr) => {
            const validIntervalsForRamp = new Set(
              validCollectionIntervals(
                curr.durationInPeriods ?? "INFINITY",
                props.rampStart,
                durationOfAllRamps,
              ),
            );
            return prev.filter((v) => validIntervalsForRamp.has(v));
          },
          validCollectionIntervals(
            "INFINITY",
            props.rampStart,
            durationOfAllRamps,
          ),
        )
      : validCollectionIntervals(
          props.ramps[props.rampIndex].durationInPeriods ?? "INFINITY",
          props.rampStart,
          durationOfAllRamps,
        );

  return (
    <DeprecatedRightPane
      isOpen={true}
      onRequestClose={() => props.onRequestClose()}
      className={styles.popup}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Headline level={6}>Collecting a charge in advance</Headline>
          <div>
            <Button
              disabled={props.rampIndex !== 0}
              onClick={() =>
                setFlatFees(
                  flatFees.map((ff) => {
                    return {
                      ...ff,
                      collectionSchedule: "ARREARS",
                      collectionInterval: undefined,
                    };
                  }),
                )
              }
              text="Reset"
              theme="linkGray"
              leadingIcon="refreshCw01"
              className="mr-8"
            />
            <IconButton
              onClick={() => props.onRequestClose()}
              theme="secondary"
              icon="xClose"
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div>
            <Body level={2}>
              In-advance charges allow you to invoice your customers at the
              beginning of a billing period. These charges can be recurring or
              occur just once at the start of a plan after any trials.{" "}
              {props.rampIndex === 0
                ? `Below you’ll
              see the entire plan life cycle, but you can change the behavior in
              each following pricing ramp.`
                : flatFees[0].collectionSchedule === "ADVANCE"
                  ? `You can change the frequency for this ramp, but to edit anything else, go back to the first ramp.`
                  : `Go back to the first ramp to edit this charge.`}
            </Body>
          </div>
          <div>
            <Tooltip
              content="In-advance charges can be prorated for recurring charges only. To select a one-time advance charge, go back and disable proration for this charge."
              disabled={!flatFees[0].isProrated}
            >
              <DeprecatedToggleButtons
                buttonProps={[
                  { label: "One-time charge", value: "ONE_TIME_ADVANCE" },
                  { label: "Recurring charge", value: "ADVANCE" },
                ]}
                value={flatFees?.[0]?.collectionSchedule}
                defaultButtonProps={{
                  disabled: props.rampIndex !== 0 || !!flatFees[0].isProrated,
                  useGreyBackground: true,
                  onChange: (v) => {
                    setFlatFees(
                      flatFees.map((ff) => {
                        return {
                          ...ff,
                          collectionSchedule:
                            v as AdvanceChargeCollectionSchedule,
                          collectionInterval: undefined,
                        };
                      }),
                    );
                  },
                }}
              />
            </Tooltip>
          </div>
          {flatFees?.[0]?.collectionSchedule === "ADVANCE" && (
            <div>
              <Body level={1}>
                Billing frequency. How often does this charge occur?
              </Body>
              <Select
                className={styles.dropdown}
                name="Every..."
                value={flatFees[0].collectionInterval?.toString() ?? ""}
                placeholder="Select"
                options={[
                  ...intervalOptions.map((n) => ({
                    value: n.toString(),
                    label: `${pluralize("Billing period", n, n !== 1)}`,
                  })),
                ]}
                onChange={(value) =>
                  setFlatFees(
                    flatFees.map((ff) => {
                      return { ...ff, collectionInterval: Number(value) };
                    }),
                  )
                }
              />
            </div>
          )}
          <div>
            <Body level={2}>Plan life cycle</Body>
            <AdvanceChargePreview
              billingFrequency={props.billingFrequency}
              billingDayOfPeriod={props.billingDayOfPeriod}
              collectionSchedule={
                flatFees[0].collectionSchedule === "ARREARS"
                  ? undefined
                  : flatFees[0].collectionSchedule
              }
              ramps={ramps}
              rampStart={props.rampStart}
              planStart={props.planStart}
              planEnd={props.planEnd}
            />
          </div>
        </div>
        <div className={twMerge(styles.actions, "flex gap-8")}>
          <Button
            onClick={() => props.onRequestClose()}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={() => {
              props.onRequestSave(props.pricingFactorId, flatFees);
            }}
            disabled={
              (flatFees[0].collectionSchedule === "ADVANCE" &&
                flatFees[0].collectionInterval === undefined) ||
              (props.rampIndex !== 0 &&
                flatFees[0].collectionSchedule !== "ADVANCE")
            }
            text="Save"
            theme="primary"
          />
        </div>
      </div>
    </DeprecatedRightPane>
  );
};

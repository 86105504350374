import React from "react";
import { DeprecatedFlyoverWithMetadataTable } from "components/deprecated/FlyoverWithMetadataTable";
import { ContractMetadataFragment } from "./data.graphql";
import { renderDate } from "lib/time";

interface Props {
  onRequestClose: () => void;
  contract: ContractMetadataFragment;
  contractName: string;
  amendmentId?: string;
}

export const ContractMetadataFlyover: React.FC<Props> = ({
  onRequestClose,
  contract,
  contractName,
  amendmentId,
}) => {
  const removeDuplicatesAndEmpty = (arr: (string | null)[]): string[] => {
    return [...new Set(arr.filter((item) => item !== null))] as string[];
  };

  const getNetsuiteIds = (items: any[], itemAmendmentId?: string): string[] => {
    if (!amendmentId || amendmentId === itemAmendmentId) {
      return items.map((item) => item.netsuite_sales_order_id);
    }
    return [];
  };

  const mapContractToRows = (): Map<string, string[]> => {
    const netsuite_ids = [
      ...(amendmentId ? [] : [contract.netsuite_sales_order_id]),
      ...getNetsuiteIds(contract.v2_fields?.commits_union ?? []),
      ...getNetsuiteIds(contract.v2_fields?.discounts ?? []),
      ...getNetsuiteIds(contract.v2_fields?.scheduled_charges ?? []),
    ];

    const salesforce_ids = [
      ...(amendmentId ? [] : [contract.salesforce_opportunity_id]),
      ...contract.amendments.flatMap((amendment) => [
        ...(amendmentId && amendmentId !== amendment.id
          ? []
          : [amendment.salesforce_opportunity_id]),
      ]),
    ];

    return new Map([
      ["netsuite_sales_order_id", removeDuplicatesAndEmpty(netsuite_ids)],
      ["salesforce_opportunity_id", removeDuplicatesAndEmpty(salesforce_ids)],
      [
        "billing_anchor_date",
        amendmentId
          ? []
          : [
              renderDate(
                new Date(
                  contract.usage_invoice_schedule.billing_cycle_anchor_date,
                ),
                {
                  isUtc: true,
                },
              ),
            ],
      ],
    ]);
  };

  return (
    <DeprecatedFlyoverWithMetadataTable
      onRequestClose={onRequestClose}
      title={contractName}
      instanceType={amendmentId ? "amendment" : "contract"}
      metadataRows={mapContractToRows()}
      options={{ removeEmptyValueRows: true }}
    />
  );
};

import { Icon } from "components/Icon";
import { Tooltip } from "components/Tooltip";
import Decimal from "decimal.js";
import { Percent } from "app/pages/Contracts/components/RateScheduleTable";
import React from "react";

export const DeprecatedTieredMultiplier: React.FC<{
  tiers: { size?: Decimal; multiplier: Decimal }[];
}> = ({ tiers }) => {
  let prevEnd = new Decimal(0);
  let minMultiplier: Decimal | undefined;
  let maxMultipler: Decimal | undefined;
  const tierDescriptions = tiers.map((tier, index) => {
    const startUnit = prevEnd;
    const endUnit =
      tier.size !== undefined
        ? new Decimal(tier.size).plus(startUnit)
        : undefined;
    if (endUnit) {
      prevEnd = endUnit;
    }
    const range = endUnit
      ? `${startUnit} - ${endUnit}`
      : `${startUnit} or more`;

    if (
      minMultiplier === undefined ||
      tier.multiplier.lessThan(minMultiplier)
    ) {
      minMultiplier = tier.multiplier;
    }
    if (
      maxMultipler === undefined ||
      tier.multiplier.greaterThan(maxMultipler)
    ) {
      maxMultipler = tier.multiplier;
    }

    return (
      <span>
        {range}: <Percent fraction={tier.multiplier} isNewUI />
      </span>
    );
  });

  let multiplierRangeText: React.ReactNode | undefined;
  if (minMultiplier && maxMultipler) {
    multiplierRangeText = (
      <span>
        <Percent fraction={minMultiplier} isNewUI /> -{" "}
        <Percent fraction={maxMultipler} isNewUI />
      </span>
    );
  }

  return (
    <div className="flex flex-row items-center gap-8">
      <Tooltip
        label="Tiered multiplier"
        subLabel={
          <div className="flex flex-col gap-4 ">
            {tierDescriptions.map((t) => (
              <div className="text-xs font-medium !text-gray-200">{t}</div>
            ))}
          </div>
        }
      >
        <Icon icon="infoCircle" size={20} />
      </Tooltip>
      {multiplierRangeText}
    </div>
  );
};

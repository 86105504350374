import React from "react";
import { twMerge } from "twMerge";
import { IconName, Icon } from "../Icon";
import { Link } from "react-router-dom";
import { useEnvironment__unsafe } from "app/lib/environmentSwitcher/context";

const SIZE_VARIANTS = {
  sm: "size-[36px] p-[8px]",
  md: "size-[40px] p-[10px]",
  lg: "size-[44px] p-lg",
};

const ICON_BUTTON_VARIANTS = {
  primary:
    "border-core-slate bg-core-slate active:bg-core-slate text-white hover:border-gray-800 hover:bg-gray-950 ring-core-jade-green/[.24] focus:ring-4",
  secondary:
    "border-gray-200 text-gray-600 active:text-gray-600 active:bg-white hover:bg-gray-50 hover:text-gray-800 ring-gray-400/[.14] focus:ring-4",
  tertiary:
    "border-0 text-gray-600 active:text-gray-600 active:bg-white hover:bg-gray-50 hover:text-gray-800 focus:none shadow-none",
  linkGray:
    "p-0 border-0 text-gray-600 active:text-gray-600 hover:text-gray-800 focus:none shadow-none",
};

const ICON_BUTTON_DISABLED_VARIANTS = {
  primary: "pointer-events-none border-gray-200 bg-gray-200 text-gray-400",
  secondary: "pointer-events-none text-gray-400",
  tertiary: "pointer-events-none text-gray-400",
  linkGray: "pointer-events-none text-gray-400",
};

export interface IconButtonProps {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Icon for the Button */
  icon: IconName;
  /** Default: primary - Sets the colors of the background, border, icon and text of the Button */
  theme?: keyof typeof ICON_BUTTON_VARIANTS;
  /** Callback invoked when the user clicks (press and release) on Button with the mouse or keyboard. */
  onClick?: (e: React.MouseEvent) => void;
  /** The button will becom an anchor tag. If the URI provided is to an external location, please add `isExternalLink` as well. */
  linkTo?: string;
  /** If the route passed to the `linkTo` prop is meant to open an external URI in a new tab */
  isExternalLink?: boolean;
  /** The button is disabled if set to true, preventing interaction and inactive */
  disabled?: boolean;
  /** Default: md - sm: 36px, md: 40px, lg: 44px */
  size?: "sm" | "md" | "lg";
  /** Default: button - Use "submit" if Button is used with or associated with a form */
  type?: "button" | "submit";
  /** Default: false - Use to indicate that the button is in loading state */
  loading?: boolean;
}

/**
 *  Buttons communicate actions that users can take. They can be used alone
 *  for immediate action, a form or as a trigger for another component.
 */
export const IconButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(
  (
    {
      className,
      icon,
      theme = "primary",
      onClick,
      disabled,
      size = "md",
      type = "button",
      linkTo,
      isExternalLink,
      loading,
    },
    ref,
  ) => {
    const environmentHook = useEnvironment__unsafe();
    const classnames = twMerge(
      "border rounded-md inline-flex items-center shadow-xs",
      SIZE_VARIANTS[size],
      ICON_BUTTON_VARIANTS[theme],
      disabled && ICON_BUTTON_DISABLED_VARIANTS[theme],
      className,
    );

    let element;
    const child = (
      <Icon
        className={loading ? "animate-spin" : ""}
        icon={loading ? "loading02" : icon}
        size={20}
      />
    );

    if (linkTo) {
      const commonProps = {
        className: classnames,
        onClick,
        "aria-disabled": disabled,
        children: child,
      };

      if (isExternalLink) {
        element = (
          <a
            {...commonProps}
            target="_blank"
            href={linkTo}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          />
        );
      } else {
        if (!environmentHook) {
          throw new Error("Environment is not initialized for link");
        }
        element = (
          <Link
            {...commonProps}
            to={environmentHook.prefixUrl(linkTo)}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          />
        );
      }
    } else {
      element = (
        <button
          className={classnames}
          onClick={onClick}
          disabled={disabled}
          type={type}
          ref={ref as React.ForwardedRef<HTMLButtonElement>}
        >
          {child}
        </button>
      );
    }

    return element;
  },
);

import classnames from "classnames";
import { Badge } from "design-system";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import React from "react";

export const DeprecatedGroupKeyBadge: React.FC<{ fieldName?: Set<string> }> = ({
  fieldName,
}) => {
  if (fieldName) {
    const fieldNameString = [...fieldName]
      .map((fieldNameItem) => `"${fieldNameItem}"`)
      .join(",");
    return (
      <Badge
        theme="primary"
        type="light"
        className={classnames(
          "inline-flex items-center rounded-xxs px-[6px] py-[2px] font-mono text-[11px] font-normal leading-1",
          "bg-deprecated-primary-100 text-deprecated-primary-600",
          "mr-4",
        )}
        key={fieldNameString}
      >
        {fieldName.size === 1 &&
        fieldName.has("__METRONOME_INTERNAL__EVENT_TYPE")
          ? "event_type"
          : `properties[${fieldNameString}]`}
      </Badge>
    );
  }
  return (
    <Badge
      theme="grey"
      type="light"
      className={classnames(
        "inline-flex items-center rounded-xxs px-[6px] py-[2px] font-mono text-[11px] font-normal leading-1",
        "bg-deprecated-gray-100 text-deprecated-gray-300",
      )}
    >
      GROUP_KEY
    </Badge>
  );
};

export const AggregationBadge: React.FC<{
  aggregation?: BillingMetricAggregateEnum_Enum;
  keys?: string[] | null;
}> = ({ aggregation, keys }) => {
  if (!aggregation || (!aggregation && !keys)) {
    return (
      <Badge
        theme="grey"
        type="light"
        className="inline-flex items-center rounded-xxs bg-deprecated-gray-100 px-[6px] py-[2px] font-mono text-[11px] font-normal leading-1 text-deprecated-gray-300"
      >
        AGGREGATE(properties[property])
      </Badge>
    );
  }

  let badgeClass: string | undefined;
  switch (aggregation) {
    case BillingMetricAggregateEnum_Enum.Sum:
      badgeClass = "bg-badge-1";
      break;
    case BillingMetricAggregateEnum_Enum.Max:
      badgeClass = "bg-badge-3";
      break;
    case BillingMetricAggregateEnum_Enum.Unique:
      badgeClass = "bg-badge-2";
      break;
    case BillingMetricAggregateEnum_Enum.Count:
      badgeClass = "bg-badge-4 text-gray-800";
      break;
  }

  let formattedField = "";
  if (keys?.length) {
    const fieldName = keys[0];
    if (fieldName === "__METRONOME_INTERNAL__EVENT_TYPE") {
      formattedField = "event_type";
    } else {
      formattedField = `properties["${fieldName}"]`;
    }
  }

  const aggregationName =
    aggregation === BillingMetricAggregateEnum_Enum.Latest
      ? "SEAT"
      : aggregation.toUpperCase();
  return (
    <Badge
      theme="grey"
      className={classnames(
        "inline-flex items-center rounded-xxs px-[6px] py-[2px] font-mono text-[11px] font-normal leading-1",
        badgeClass,
      )}
      type="dark"
    >
      {`${aggregationName}(${formattedField})`}
    </Badge>
  );
};

import React from "react";
import classNames from "classnames";

import { Breadcrumbs } from "app/lib/breadcrumbs";
import { NotFoundPage } from "app/pages/404";
import { AppShell } from "components/AppShell";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";
import { useRequiredParam } from "app/lib/routes/params";

import { CustomerFromRouteReq } from "../../lib/Customer";
import { CustomerPlanReq } from "./useCustomerPlanFromRoute";
import type { DetailedCustomerPlanFragmentReader } from "../../lib/CustomerPlan";

interface Props {
  customerReq: CustomerFromRouteReq;
  customerPlanReq: CustomerPlanReq;
  disableContainerScroll?: boolean;
  content: (ctx: {
    customer: (CustomerFromRouteReq & { state: "success" })["customer"];
    customerPlan: DetailedCustomerPlanFragmentReader;
  }) => React.ReactNode;
  tabsAction?: React.ReactNode;
}

export const ContractCustomerPlanContainer: React.FC<Props> = ({
  customerReq,
  customerPlanReq,
  disableContainerScroll,
  content,
  tabsAction,
}) => {
  const customerId = useRequiredParam("customerId");

  if (
    customerReq.state === "not found" ||
    customerPlanReq.state === "not found"
  ) {
    return <NotFoundPage />;
  }

  if (customerReq.state === "error") {
    return (
      <AppShell title="Failed to load Customer">
        <ErrorEmptyState
          title="We were unable to load this customer"
          error={customerReq.error}
        />
      </AppShell>
    );
  }
  if (customerPlanReq.state === "error") {
    return (
      <AppShell title="Failed to load plan">
        <ErrorEmptyState
          title="We were unable to load this plan"
          error={customerPlanReq.error}
        />
      </AppShell>
    );
  }

  return (
    <AppShell
      title={
        customerPlanReq.state === "loading"
          ? { state: "loading" }
          : customerPlanReq.customerPlan.name
      }
      headerProps={{
        actions: tabsAction,
        tabs: [
          {
            name: "Plan overview",
            path: `/customers/${customerId}/plans/${customerPlanReq.id}`,
            exactMatch: true,
          },
          {
            name: "Invoices",
            path: `/customers/${customerId}/plans/${customerPlanReq.id}/invoices`,
          },
        ],
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Customers",
            routePath: "/customers",
          },
          customerReq.state === "loading"
            ? { type: "loading", label: "" }
            : {
                label: customerReq.customer.name ?? "",
                routePath: `/customers/${customerId}`,
              },
          {
            /* TODO change this to have a route path when we have a page that shows all contracts */
            label: "Plans",
          },
        ),
      }}
    >
      <div
        className={classNames("max-w-[2400px]", {
          ["h-full overflow-hidden"]: disableContainerScroll,
        })}
      >
        {customerReq.state === "loading" ||
        customerPlanReq.state === "loading" ? (
          <DeprecatedParagraphSkeleton />
        ) : (
          content({
            customer: customerReq.customer,
            customerPlan: customerPlanReq.customerPlan,
          })
        )}
      </div>
    </AppShell>
  );
};

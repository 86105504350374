/* 10db7fa39242e394ed56818d6c781d439954ad57
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStripeBillingProviderCustomerMutationVariables = Types.Exact<{
  billing_provider_customer_id: Types.Scalars['String'];
  customer_id: Types.Scalars['uuid'];
  stripe_collection_method: Types.Scalars['String'];
}>;


export type UpdateStripeBillingProviderCustomerMutation = { __typename?: 'Mutation', insert_BillingProviderCustomer_one: { __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string, billing_provider: Types.BillingProviderEnum_Enum, created_at: string, updated_at: string } | null, upsert_customer_configs: Array<{ __typename?: 'CustomerConfig', id: string, key: string, value: string, created_at: string, updated_at: string }> };

export type InsertCustomerBillingProviderConfigurationMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  configuration: Types.Scalars['FlatKeyValueMap'];
}>;


export type InsertCustomerBillingProviderConfigurationMutation = { __typename?: 'Mutation', insert_customer_billing_provider_configuration: { __typename?: 'InsertCustomerBillingProviderConfigurationResult', id: string, configuration: { [key: string]: string | number | boolean } } };

export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationVariables = Types.Exact<{
  stripe_customer_id: Types.Scalars['String'];
  customer_id: Types.Scalars['uuid'];
  stripe_collection_method: Types.Scalars['String'];
}>;


export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation = { __typename?: 'Mutation', add_stripe_to_customer: { __typename?: 'MutationResponse', success: boolean } };

export type GetStripeCustomerQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  stripe_customer_id: Types.Scalars['String'];
}>;


export type GetStripeCustomerQuery = { __typename?: 'Query', stripeCustomer: { __typename?: 'StripeCustomer', name: string } | null };

export type IsDeltaStreamEnabledQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsDeltaStreamEnabledQuery = { __typename?: 'Query', is_delta_stream_enabled: boolean };


export const UpdateStripeBillingProviderCustomerDocument = gql`
    mutation UpdateStripeBillingProviderCustomer($billing_provider_customer_id: String!, $customer_id: uuid!, $stripe_collection_method: String!) {
  insert_BillingProviderCustomer_one(
    object: {billing_provider_customer_id: $billing_provider_customer_id, billing_provider: STRIPE, customer_id: $customer_id}
    on_conflict: {constraint: BillingProviderCustomer_pkey, update_columns: billing_provider_customer_id}
  ) {
    billing_provider_customer_id
    billing_provider
    created_at
    updated_at
  }
  upsert_customer_configs(
    customer_id: $customer_id
    inputs: [{key: "stripe_collection_method", value: $stripe_collection_method}]
  ) {
    id
    key
    value
    created_at
    updated_at
  }
}
    `;
export type UpdateStripeBillingProviderCustomerMutationFn = Apollo.MutationFunction<UpdateStripeBillingProviderCustomerMutation, UpdateStripeBillingProviderCustomerMutationVariables>;

/**
 * __useUpdateStripeBillingProviderCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateStripeBillingProviderCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeBillingProviderCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeBillingProviderCustomerMutation, { data, loading, error }] = useUpdateStripeBillingProviderCustomerMutation({
 *   variables: {
 *      billing_provider_customer_id: // value for 'billing_provider_customer_id'
 *      customer_id: // value for 'customer_id'
 *      stripe_collection_method: // value for 'stripe_collection_method'
 *   },
 * });
 */
export function useUpdateStripeBillingProviderCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStripeBillingProviderCustomerMutation, UpdateStripeBillingProviderCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStripeBillingProviderCustomerMutation, UpdateStripeBillingProviderCustomerMutationVariables>(UpdateStripeBillingProviderCustomerDocument, options);
      }
export type UpdateStripeBillingProviderCustomerMutationHookResult = ReturnType<typeof useUpdateStripeBillingProviderCustomerMutation>;
export type UpdateStripeBillingProviderCustomerMutationResult = Apollo.MutationResult<UpdateStripeBillingProviderCustomerMutation>;
export type UpdateStripeBillingProviderCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateStripeBillingProviderCustomerMutation, UpdateStripeBillingProviderCustomerMutationVariables>;
export const InsertCustomerBillingProviderConfigurationDocument = gql`
    mutation InsertCustomerBillingProviderConfiguration($customer_id: uuid!, $configuration: FlatKeyValueMap!) {
  insert_customer_billing_provider_configuration(
    customer_billing_provider_configuration_input: {billing_provider: STRIPE, customer_id: $customer_id, configuration: $configuration, delivery_method: DIRECT_TO_BILLING_PROVIDER}
  ) {
    id
    configuration
  }
}
    `;
export type InsertCustomerBillingProviderConfigurationMutationFn = Apollo.MutationFunction<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>;

/**
 * __useInsertCustomerBillingProviderConfigurationMutation__
 *
 * To run a mutation, you first call `useInsertCustomerBillingProviderConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomerBillingProviderConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomerBillingProviderConfigurationMutation, { data, loading, error }] = useInsertCustomerBillingProviderConfigurationMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useInsertCustomerBillingProviderConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>(InsertCustomerBillingProviderConfigurationDocument, options);
      }
export type InsertCustomerBillingProviderConfigurationMutationHookResult = ReturnType<typeof useInsertCustomerBillingProviderConfigurationMutation>;
export type InsertCustomerBillingProviderConfigurationMutationResult = Apollo.MutationResult<InsertCustomerBillingProviderConfigurationMutation>;
export type InsertCustomerBillingProviderConfigurationMutationOptions = Apollo.BaseMutationOptions<InsertCustomerBillingProviderConfigurationMutation, InsertCustomerBillingProviderConfigurationMutationVariables>;
export const UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationDocument = gql`
    mutation UpdateBillingProviderCustomerAndCustomerBillingProviderConfiguration($stripe_customer_id: String!, $customer_id: uuid!, $stripe_collection_method: String!) {
  add_stripe_to_customer(
    stripe_customer_id: $stripe_customer_id
    customer_id: $customer_id
    stripe_collection_method: $stripe_collection_method
  ) {
    success
  }
}
    `;
export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationFn = Apollo.MutationFunction<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation, UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationVariables>;

/**
 * __useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation, { data, loading, error }] = useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation({
 *   variables: {
 *      stripe_customer_id: // value for 'stripe_customer_id'
 *      customer_id: // value for 'customer_id'
 *      stripe_collection_method: // value for 'stripe_collection_method'
 *   },
 * });
 */
export function useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation, UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation, UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationVariables>(UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationDocument, options);
      }
export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationHookResult = ReturnType<typeof useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation>;
export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationResult = Apollo.MutationResult<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation>;
export type UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation, UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutationVariables>;
export const GetStripeCustomerDocument = gql`
    query GetStripeCustomer($environment_type: EnvironmentTypeEnum_enum!, $stripe_customer_id: String!) {
  stripeCustomer(environment_type: $environment_type, id: $stripe_customer_id) {
    name
  }
}
    `;

/**
 * __useGetStripeCustomerQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      stripe_customer_id: // value for 'stripe_customer_id'
 *   },
 * });
 */
export function useGetStripeCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
      }
export function useGetStripeCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
        }
export type GetStripeCustomerQueryHookResult = ReturnType<typeof useGetStripeCustomerQuery>;
export type GetStripeCustomerLazyQueryHookResult = ReturnType<typeof useGetStripeCustomerLazyQuery>;
export type GetStripeCustomerQueryResult = Apollo.QueryResult<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>;
export const IsDeltaStreamEnabledDocument = gql`
    query IsDeltaStreamEnabled {
  is_delta_stream_enabled
}
    `;

/**
 * __useIsDeltaStreamEnabledQuery__
 *
 * To run a query within a React component, call `useIsDeltaStreamEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDeltaStreamEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDeltaStreamEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsDeltaStreamEnabledQuery(baseOptions?: Apollo.QueryHookOptions<IsDeltaStreamEnabledQuery, IsDeltaStreamEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsDeltaStreamEnabledQuery, IsDeltaStreamEnabledQueryVariables>(IsDeltaStreamEnabledDocument, options);
      }
export function useIsDeltaStreamEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsDeltaStreamEnabledQuery, IsDeltaStreamEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsDeltaStreamEnabledQuery, IsDeltaStreamEnabledQueryVariables>(IsDeltaStreamEnabledDocument, options);
        }
export type IsDeltaStreamEnabledQueryHookResult = ReturnType<typeof useIsDeltaStreamEnabledQuery>;
export type IsDeltaStreamEnabledLazyQueryHookResult = ReturnType<typeof useIsDeltaStreamEnabledLazyQuery>;
export type IsDeltaStreamEnabledQueryResult = Apollo.QueryResult<IsDeltaStreamEnabledQuery, IsDeltaStreamEnabledQueryVariables>;
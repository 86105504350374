import React, { useEffect } from "react";

import { ImpersonationBanner } from "components/ImpersonationBanner";
import { useAuth, useCurrentUser } from "app/lib/auth";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { DocumentNode } from "graphql";
import { Sidebar as T10Sidebar } from "components/Sidebar";
import {
  PageHeaderProps,
  PageHeader as T10PageHeader,
} from "components/PageHeader";
import { DeprecatedAuthCheck } from "../deprecated/AuthCheck";

interface FullScreenProps extends React.PropsWithChildren {
  title?: string;
}

const NonProdModeHeader: React.FC<{
  environment: string;
}> = ({ environment }) => {
  return (
    <div
      className="fixed h-4 w-full bg-deprecated-warning-500 text-center"
      data-testid="sandbox-mode-header"
    >
      <div className="inline-block rounded-b-xs bg-deprecated-warning-500 px-4 py-[2px] font-default text-xxs font-semibold text-white">
        {environment}
      </div>
    </div>
  );
};

export const FullScreenPage: React.FC<FullScreenProps> = ({
  title,
  children,
}) => {
  const auth = useAuth();
  const sandboxMode = useEnvironment();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (title) {
      document.title = `${title} | Metronome`;
    } else {
      document.title = "Metronome";
    }

    return () => {
      document.title = "Metronome";
    };
  }, [title]);

  return (
    <>
      <div className="grow-0">
        {auth.impersonated && (
          <ImpersonationBanner
            email={user?.email ?? "loading"}
            expiration={auth.impersonationSessionExpires}
            role={user?.role ?? "loading"}
          />
        )}
      </div>
      <div className="grow-0">
        {sandboxMode.environmentType !== EnvironmentTypeEnum_Enum.Production &&
        sandboxMode.supported ? (
          <NonProdModeHeader environment={sandboxMode.environment.name} />
        ) : null}
      </div>
      <div className="flex shrink grow flex-col overflow-hidden bg-white">
        {children}
      </div>
    </>
  );
};

interface AppShellProps extends React.PropsWithChildren {
  title: string | { state: "loading" };
  headerProps?: Omit<PageHeaderProps, "title">;
  hideHeader?: boolean;
  // Block access to the page if the user is not authorized to access the query specified below
  authDoc?: DocumentNode;
  authVariables?: any;
}

/**
 * The AppShell is the primary layout of the application (tenaissance edition).
 */
export const AppShell: React.FC<AppShellProps> = ({
  title,
  headerProps,
  hideHeader,
  children,
  authDoc,
  authVariables,
}) => {
  return (
    <FullScreenPage title={typeof title === "string" ? title : undefined}>
      <div className="flex flex-1 overflow-hidden">
        <T10Sidebar />
        <div className="flex flex-1 flex-col overflow-hidden">
          {!hideHeader && <T10PageHeader title={title} {...headerProps} />}
          <DeprecatedAuthCheck authDoc={authDoc} authVars={authVariables}>
            <div className="flex-1 overflow-auto p-xl">{children}</div>
          </DeprecatedAuthCheck>
        </div>
      </div>
    </FullScreenPage>
  );
};

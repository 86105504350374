import classnames from "classnames";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import React from "react";
import { Caption } from "design-system";
import { HelpCircleTooltip } from "design-system";
import type { JSONSchema7 } from "json-schema";
import { Condition } from "@metronome-industries/json-schema-conditions";
import {
  AggregationBadge,
  DeprecatedGroupKeyBadge,
} from "components/deprecated/BillableMetricsBadge";
import {
  DeprecatedConditionsDisplay,
  DeprecatedJSONSchemaDisplay,
} from "components/deprecated/jsonSchema";

const DesignLabel: React.FC<{ helpText: string; captionText: string }> = ({
  helpText,
  captionText,
}) => {
  return (
    <div className="flex">
      <Caption level={1} className="mb-4">
        {captionText}
      </Caption>
      <HelpCircleTooltip content={helpText} />
    </div>
  );
};

type DefinitionDisplayProps = {
  aggregate?: BillingMetricAggregateEnum_Enum;
  aggregateKeys?: string[];
  groupKeys?: Set<string>[];
  filter?: JSONSchema7;
  conditions?: Condition[];
  vertical?: boolean;
  allowGroupKeys?: boolean;
  rawSql?: string;
};

export const DeprecatedDefinitionDisplay: React.FC<DefinitionDisplayProps> = ({
  aggregate,
  aggregateKeys,
  groupKeys,
  filter,
  conditions,
  vertical,
  allowGroupKeys,
  rawSql,
}) => {
  return (
    <div
      className={classnames(
        "mx-0 my-4 rounded-xs border border-deprecated-gray-100 bg-gray-50 px-[6px] py-[2px]",
        {
          "inline-block": rawSql,
        },
      )}
    >
      <div
        className={classnames("inline-flex items-start pt-8", {
          "flex-col": vertical,
        })}
      >
        {rawSql && (
          <span className="whitespace-pre-wrap pb-8 font-mono text-xs leading-1 text-gray-800">
            {rawSql}
          </span>
        )}
        {/* Aggregate */}
        {aggregate && (
          <div className="inline-block pb-[2px] pl-8">
            <DesignLabel
              helpText="Each metric specifies an aggregator and, with the exception of count, a single property to aggregate over. Metronome currently supports the following aggregators: count, sum, max, and unique."
              captionText="Aggregate"
            />
            <AggregationBadge aggregation={aggregate} keys={aggregateKeys} />
          </div>
        )}

        {/* Group Keys */}
        {allowGroupKeys && (
          <div className="inline-block pb-[2px] pl-8">
            <DesignLabel
              helpText="A metric can optionally specify one or more properties to group by. This functions similarly to a group by clause in a SQL query. Groups don't affect pricing, but they can be displayed on invoices."
              captionText="Group_Keys"
            />
            {groupKeys ? (
              groupKeys.map((field, index) => (
                <DeprecatedGroupKeyBadge key={index} fieldName={field} />
              ))
            ) : (
              <DeprecatedGroupKeyBadge />
            )}
          </div>
        )}
      </div>

      {/* Filters */}
      {!rawSql && (
        <div className="px-8 py-0">
          <DesignLabel
            helpText="Only usage events matching all filters will be aggregated."
            captionText="Filters"
          />
          {conditions ? (
            <DeprecatedConditionsDisplay conditions={conditions} />
          ) : (
            <DeprecatedJSONSchemaDisplay jsonSchema={filter as JSONSchema7} />
          )}
        </div>
      )}
    </div>
  );
};

import React from "react";
import { Column, SortFunctions, DeprecatedTable } from "../Table";
import { Subtitle, Badge, Tooltip } from "design-system";
import {
  generateAlertPolicyGroupKeyFilterString,
  generateAlertPolicyInvoiceTypesFilterString,
  generateAlertPolicyString,
} from "app/lib/alerts";
import { CreditType } from "app/types/credit-types";
import {
  AlertTypeEnum_Enum,
  CustomerAlertCompositeStatusEnum,
} from "types/generated-graphql/__types__";
import AlertsCustomerCell from "app/pages/deprecated/Customer/tabs/Alerts/AlertsCustomerCell";

const MAX_ALERTS = 100;

interface AlertRow {
  id: string;
  name: string;
  threshold: string;
  CreditType: CreditType;
  alert_type: AlertTypeEnum_Enum;
  Customer: { id: string; name: string } | null;
  Plan: { id: string; name: string } | null;
  BillableMetric: { id: string; name: string } | null;
  filters: object | null;
  customer_status?: CustomerAlertCompositeStatusEnum;

  // TODO: add back in when we can make this not time out in the query.
  // in_alarm_count: number;

  // evaluating: boolean;
}

interface AlertsTableProps {
  loading: boolean;
  alerts: AlertRow[];
}

export const DeprecatedAlertsTable: React.FC<AlertsTableProps> = ({
  alerts,
  loading,
}) => {
  const sortable = alerts.length < MAX_ALERTS;
  const columns: Column<AlertRow>[] = [
    {
      id: "name",
      header: "Alert name",
      textWrappable: true,
      render: (alert) => <Subtitle level={3}>{alert.name}</Subtitle>,
      sortable,
      comparator: SortFunctions.String((a) => a.name),
    },
    {
      id: "policy",
      header: "Alert policy",
      render: (alert) => {
        const groupKeyFilterString = generateAlertPolicyGroupKeyFilterString(
          alert.filters,
        );
        const invoiceTypesFilterString =
          generateAlertPolicyInvoiceTypesFilterString(alert.filters);
        return (
          <div className="flex flex-col items-start gap-4">
            <Badge
              theme="primary"
              type="light"
              className="text-primary-600 font-normal"
            >
              {generateAlertPolicyString(
                alert.threshold,
                alert.CreditType,
                alert.alert_type,
                alert.BillableMetric?.name,
              )}
            </Badge>

            {groupKeyFilterString || invoiceTypesFilterString ? (
              <Tooltip
                inline={true}
                content={
                  <>
                    {groupKeyFilterString}
                    {invoiceTypesFilterString}
                  </>
                }
              >
                <Badge
                  theme="primary"
                  type="light"
                  className="text-primary-600 inline-block max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap font-normal"
                >
                  {groupKeyFilterString}
                  {invoiceTypesFilterString}
                </Badge>
              </Tooltip>
            ) : null}
          </div>
        );
      },
      sortable,
      comparator: SortFunctions.String((a) =>
        generateAlertPolicyString(
          a.threshold,
          a.CreditType,
          a.alert_type,
          a.BillableMetric?.name,
        ),
      ),
    },
    {
      id: "customer",
      header: "Customer",
      render: (alert) => {
        return <AlertsCustomerCell alertId={alert.id} />;
      },
      sortable,
      comparator: SortFunctions.String(
        (a) => a.Customer?.name ?? a.Plan?.name ?? "All customers",
      ),
    },
  ];

  return (
    <DeprecatedTable
      data={alerts}
      loading={loading}
      columns={columns}
      rowRoutePath={(a) => `/connections/alerts/${a.id}`}
      defaultSortBy={[
        { id: "status", desc: true },
        { id: "name", desc: true },
      ]}
      maxPageSize={20}
      maxItems={MAX_ALERTS}
    />
  );
};

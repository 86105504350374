import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { FormController } from "app/lib/FormController";
import React from "react";

import { Body, DateInput, Input, Select } from "design-system";

import { Button } from "components/Button";

import { Schema } from "./Schema";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import {
  CommitDetailsFragment,
  useAddManualCommitLedgerEntryMutation,
} from "./data.graphql";
import { dayjs } from "lib/dayjs";
import { Commit } from "app/pages/Contracts/lib/Commit";

const useAddManualCommitLedgerEntryModalController = FormController.createHook(
  Schema.AddManualCommitLedgerEntryInput,
);

interface AddManualCommitLedgerEntryModalProps {
  onClose: () => void;
  commit: CommitDetailsFragment;
}

export const AddManualCommitLedgerEntryModal: React.FC<
  AddManualCommitLedgerEntryModalProps
> = ({ commit, onClose }) => {
  const commitOrCredit = Commit.isCredit(commit) ? "credit" : "commit";

  const pushMessage = useSnackbar();
  const pushSuccessMessage = (reason: string) => {
    pushMessage({
      content: `Successfully created new ${commitOrCredit} ledger entry: ${reason}`,
      type: "success",
    });
  };
  const pushErrorMessage = (e: unknown) =>
    pushMessage({
      content: `Failed to create new ${commitOrCredit} ledger entry: ${e}`,
      type: "error",
    });

  const ctrl = useAddManualCommitLedgerEntryModalController();
  const [addManualCommitLedgerEntryMutation, addManualCommitLedgerEntryResult] =
    useAddManualCommitLedgerEntryMutation();

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    const segment = commit.access_schedule.schedule_items.find(
      (segment) => segment.id === valid.segmentId,
    );
    if (!segment) {
      pushErrorMessage("Invalid segment ID");
      return;
    }
    if (
      valid.timestamp &&
      (!dayjs.utc(valid.timestamp).isSameOrAfter(segment.date) ||
        !dayjs.utc(valid.timestamp).isBefore(segment.end_date))
    ) {
      pushErrorMessage("Timestamp must be within the selected segment dates");
      return;
    }
    try {
      const manualCommitEntryResult = await addManualCommitLedgerEntryMutation({
        variables: {
          ...valid,
          amount: valid.amount.toString(),
          commitId: commit.id,
          contractId: commit.contract?.id,
          customerId: commit.customer.id,
          timestamp: valid.timestamp?.toString(),
        },
        update(cache) {
          cache.evict({
            fieldName: "Customer",
          });
          cache.evict({
            fieldName: "Customer_by_pk",
          });
          cache.gc();
        },
      });
      if (
        manualCommitEntryResult.data?.add_manual_commit_ledger_entry.success
      ) {
        pushSuccessMessage(valid.reason);
      } else {
        throw new Error(
          manualCommitEntryResult.data?.add_manual_commit_ledger_entry.error ??
            "Unknown error",
        );
      }
      onClose();
    } catch (e) {
      pushErrorMessage(e);
    }
  });

  const dateFormat = "YYYY-MM-DD";
  const segments = commit.access_schedule.schedule_items;
  const segmentOptions = segments.map((segment, index) => ({
    label: `Segment ${index + 1} of ${segments.length}: ${dayjs.utc(segment.date).format(dateFormat)} to ${dayjs.utc(segment.end_date).format(dateFormat)}`,
    value: segment.id,
  }));

  return (
    <DeprecatedPopup
      isOpen
      onRequestClose={onClose}
      title={`Add manual ${commitOrCredit} ledger entry`}
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={
              !ctrl.appearsValid() || addManualCommitLedgerEntryResult.loading
            }
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          Add a manual {commitOrCredit} ledger entry. Manual {commitOrCredit}{" "}
          ledger entries will be applied to the {commitOrCredit} before any
          other deductions.
        </Body>
        <div className="grid gap-12">
          <Select
            {...ctrl.props.Select("segmentId", {
              name: `${Commit.isCredit(commit) ? "Credit" : "Commit"} segment`,
              placeholder: `Select a ${commitOrCredit} segment`,
              options: segmentOptions,
            })}
          />
          <Input
            {...ctrl.props.Input("reason", {
              name: "Reason",
              placeholder: "Enter a reason for this ledger entry",
            })}
          />
          <DeprecatedCreditInput
            {...ctrl.props.CreditInput("amount", {
              name: "Amount",
              placeholder: "123.45",
              tooltip: `Set the amount of the ledger entry. Positive amounts will credit the ${commitOrCredit} balance, while negative amounts will debit the ${commitOrCredit} balance.`,
              creditType: commit.access_schedule.credit_type,
              allowZeroAmounts: false,
              allowNegativeAmounts: true,
            })}
          />
          <DateInput
            {...ctrl.props.DateInput("timestamp", {
              name: "Timestamp",
              tooltip: `When the ledger entry should be applied to the ${commitOrCredit}. Must be within the selected segment dates.`,
            })}
          />
        </div>
      </form>
    </DeprecatedPopup>
  );
};

import React from "react";
import { twMerge } from "twMerge";
import { IconName, Icon } from "../Icon";
import { ConditionalWrapper } from "app/lib/conditionalWrapper";
import { Tooltip } from "../Tooltip";

export const BADGE_VARIANTS = {
  gray: "border-gray-200 bg-gray-50 text-gray-600",
  error: "border-error-200 bg-error-50 text-error-800",
  warning: "border-warning-200 bg-warning-50 text-warning-800",
  success: "border-success-200 bg-success-50 text-success-800",
  midnight: "border-core-midnight/20 bg-gray-50 text-core-midnight",
  "azure-blue":
    "border-core-azure-blue/50 bg-core-cerulean/5 text-core-azure-blue",
  "deep-denim":
    "border-core-deep-denim/50 bg-core-cerulean/5 text-core-deep-denim",
  sapphire: "border-core-sapphire/50 bg-core-sapphire/5 text-core-sapphire",
  indigo: "border-core-indigo/50 bg-core-indigo/5 text-core-indigo",
  "vibrant-magenta":
    "border-core-vibrant-magenta/50 bg-core-vibrant-magenta/5 text-core-vibrant-magenta",
};

const SIZE_VARIANTS = {
  sm: "h-[22px] py-xxs px-md text-xs",
  md: "h-[24px] py-xxs px-[10px] text-sm",
  lg: "h-[28px] py-xs px-lg text-sm",
};

const PADDING_VARIANTS = {
  smleft: "pl-[3px]",
  smright: "pr-[3px]",
  mdleft: "pl-xs",
  mdright: "pr-xs",
  lgleft: "pl-sm",
  lgright: "pr-sm",
};

const CLICK_HOVER_VARIANTS = {
  gray: "rounded-full group-hover:bg-gray-200",
  error: "rounded-full group-hover:bg-error-200",
  warning: "rounded-full group-hover:bg-warning-200",
  success: "rounded-full group-hover:bg-success-200",
  midnight: "rounded-full group-hover:bg-core-midnight/25",
  "azure-blue": "rounded-full group-hover:bg-core-azure-blue/25",
  "deep-denim": "rounded-full group-hover:bg-core-deep-denim/25",
  sapphire: "rounded-full group-hover:bg-core-sapphire/25",
  indigo: "rounded-full group-hover:bg-core-indigo/25",
  "vibrant-magenta": "rounded-full group-hover:bg-core-vibrant-magenta/25",
};

export interface BadgeProps {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Add an icon from the library to sit next to the label of the Badge */
  icon?: IconName | "dot";
  /** Default: left - Used with icon prop; choose whether the icon should sit left or right of the label */
  iconPosition?: "left" | "right";
  /** Label for the Badge */
  label: React.ReactNode;
  /** Add a click handler for Badge; imposes the "button" ARIA role on the div and adds hover states */
  onClick?: (e: React.MouseEvent) => void;
  /** Default: md - sm: 22px, md: 24px, lg: 28px */
  size?: "sm" | "md" | "lg";
  /** Default: gray - Sets the colors of the background, border, icon and text of the Badge */
  theme?: keyof typeof BADGE_VARIANTS;
  /** Optional context for the Badge, this will render a Tooltip on hover with the label */
  tooltipLabel?: string;
  /** Optional context for the Badge, this will render a Tooltip on hover with the sub label */
  tooltipSubLabel?: string;
}

type IconComponentProps = Pick<BadgeProps, "iconPosition" | "size" | "icon"> & {
  classnames: string;
};

/** Render either a dot beside the label, or an icon from the Icon library */
const IconComponent: React.FC<IconComponentProps> = ({
  icon,
  size,
  iconPosition,
  classnames,
}) => {
  if (icon === "dot") {
    return (
      <div
        className={twMerge(
          "h-sm w-sm rounded-full bg-[currentcolor]",
          iconPosition === "left" && [
            size === "sm" ? "ml-xs mr-[5px]" : "ml-[5px] mr-[7px]",
          ],
          iconPosition === "right" && [
            size === "sm" ? " ml-[5px] mr-xs" : "ml-[7px] mr-[5px]",
          ],
        )}
      ></div>
    );
  } else {
    return (
      <div className={classnames}>
        <Icon icon={icon as IconName} size={12} />
      </div>
    );
  }
};

/** Badges help highlight important information, such as notifications or new and unread messages.
 *  They’re primarily used for communicating secondary or additional information to text. */
export const Badge: React.FC<BadgeProps> = ({
  className,
  label,
  icon,
  iconPosition = "left",
  onClick,
  size = "md",
  theme = "gray",
  tooltipLabel,
  tooltipSubLabel,
}) => {
  const classnames = twMerge(
    "border rounded-2xl inline-flex items-center group",
    SIZE_VARIANTS[size],
    !!icon && PADDING_VARIANTS[`${size}${iconPosition}`],
    BADGE_VARIANTS[theme],
    className,
  );
  const iconClassnames = twMerge(
    "p-[3px]",
    iconPosition === "left" ? "mr-xs" : "ml-xs",
    !!onClick && CLICK_HOVER_VARIANTS[theme],
  );

  return (
    <ConditionalWrapper
      condition={!!tooltipLabel || !!tooltipSubLabel}
      wrapper={(children) => (
        <Tooltip label={tooltipLabel} subLabel={tooltipSubLabel}>
          {children}
        </Tooltip>
      )}
    >
      <div
        className={classnames}
        onClick={onClick}
        role={!!onClick ? "button" : "status"}
      >
        {icon && iconPosition === "left" && (
          <IconComponent
            iconPosition={iconPosition}
            size={size}
            classnames={iconClassnames}
            icon={icon}
          />
        )}

        {label}
        {icon && iconPosition === "right" && (
          <IconComponent
            iconPosition={iconPosition}
            size={size}
            classnames={iconClassnames}
            icon={icon}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
};

import { FilterOptions } from "components/deprecated/Filter";

export const PRODUCT_OPTIONS: FilterOptions = {
  product_status: {
    label: "Product Status",
    options: [
      {
        label: "Active",
        value: "has_plan",
        group: "product_status",
        type: "multi",
      },
      {
        label: "Published",
        value: "no_plan",
        group: "product_status",
        type: "multi",
      },
      {
        label: "Archived",
        value: "archived",
        group: "product_status",
        type: "multi",
      },
    ],
  },
};

export const PRODUCT_OPTIONS_DEFAULT = [
  PRODUCT_OPTIONS.product_status.options[0],
  PRODUCT_OPTIONS.product_status.options[1],
]; // Both active statuses (has_plan and no_plan)

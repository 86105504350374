import React from "react";
import Decimal from "decimal.js";

import {
  RoundedCurrency,
  USD_CREDIT_TYPE,
  displayCreditsInCurrencyWithoutRounding,
} from "app/lib/credits";

import { ProductListItem } from "../ProductListItem";
import { toDayjs, Dayjs, dayjs } from "lib/date";

import {
  CommitNameFragment,
  CommitAmountFragment,
  CommitRoutePathFragment,
  CommitTypeFragment,
  CommitDatesFragment,
  CommitInvoicesFragment,
  CommitIsRolloverFragment,
} from "./fragments.graphql";
import { ExternalCommitType } from "types/generated-graphql/__types__";
import { CreditType } from "app/types/credit-types";

export type {
  CommitNameFragment as NameFragment,
  CommitRoutePathFragment as RoutePathFragment,
} from "./fragments.graphql";

export function getName(
  commit: CommitNameFragment | Omit<CommitNameFragment, "rolled_over_from">,
  now: Dayjs,
): string {
  if ("rolled_over_from" in commit && commit.rolled_over_from) {
    const originalName = getName(commit.rolled_over_from, now);
    return `${originalName} (rollover)`;
  } else {
    return commit.name ?? ProductListItem.getName(commit.product, now);
  }
}

export function getTotal(commit: CommitAmountFragment): Decimal {
  switch (commit.__typename) {
    case "PostpaidCommit":
      return new Decimal(commit.amount);
    case "PrepaidCommit":
      return commit.access_schedule.schedule_items.reduce(
        (acc, seg) => acc.add(seg.amount),
        new Decimal(0),
      );
  }
}

export function renderTotalAmount(commit: CommitAmountFragment) {
  return displayCreditsInCurrencyWithoutRounding(
    getTotal(commit),
    commit.access_schedule.credit_type,
  );
}

export function renderTotalCost(commit: CommitAmountFragment) {
  let cost: Decimal;
  let creditType: CreditType | undefined;
  if (commit.__typename === "PrepaidCommit") {
    cost = (commit.invoice_schedule?.schedule_items ?? []).reduce(
      (acc, seg) => acc.add(seg.amount),
      new Decimal(0),
    );
    creditType = commit.invoice_schedule?.credit_type;
  } else {
    cost = commit.postpaid_commit_invoice_schedule_amount.schedule_items.reduce(
      (acc, seg) => acc.add(seg.amount),
      new Decimal(0),
    );
    creditType = commit.postpaid_commit_invoice_schedule_amount.credit_type;
  }
  return (
    <RoundedCurrency creditType={creditType ?? USD_CREDIT_TYPE} amount={cost} />
  );
}

export function getInvoiceCount(commit: CommitInvoicesFragment) {
  if (commit.__typename == "PrepaidCommit") {
    return commit.invoice_schedule?.schedule_items.length ?? 0;
  } else {
    return commit.postpaid_commit_invoice_schedule_id.schedule_items.length;
  }
}

export function getRoutePath(commit: CommitRoutePathFragment): string {
  if (commit.contract) {
    return `/customers/${commit.contract.customer.id}/contracts/${commit.contract.id}/commits-and-credits/${commit.id}`;
  } else {
    return `/customers/${commit.customer.id}/commits-and-credits/${commit.id}`;
  }
}

export function printType(commit: CommitTypeFragment): string {
  switch (commit.__typename) {
    case "PostpaidCommit":
      return "Postpaid";
    case "PrepaidCommit":
      return commit.external_type === ExternalCommitType.Credit
        ? "Credit"
        : "Prepaid";
  }
}

export function printLevel(commit: CommitRoutePathFragment): string {
  if (commit.contract) {
    return "Contract";
  } else {
    return "Customer";
  }
}

export function isCredit(commit: CommitTypeFragment): boolean {
  return (
    commit.__typename === "PrepaidCommit" &&
    commit.external_type === ExternalCommitType.Credit
  );
}

export function splitCommitsAndCredits<T extends CommitTypeFragment>(
  allCmmits: T[],
): { commits: T[]; credits: T[] } {
  const commits: T[] = [];
  const credits: T[] = [];

  for (const commit of allCmmits) {
    if (isCredit(commit)) {
      credits.push(commit);
    } else {
      commits.push(commit);
    }
  }

  return { commits, credits };
}

export function getDateRange(
  commit: CommitDatesFragment,
): { start?: Dayjs; end?: Dayjs } | null {
  const datesAsc = commit.access_schedule.schedule_items
    .flatMap((item) => [toDayjs(item.date), toDayjs(item.end_date)])
    .sort((a, b) => a.diff(b));

  return {
    start: datesAsc.at(0),
    end: datesAsc.at(-1),
  };
}

export function isRollover(commit: CommitIsRolloverFragment): boolean {
  return !!commit.rolled_over_from;
}

export function isExpired(commit: CommitDatesFragment): boolean {
  const endDate = getDateRange(commit)?.end;
  if (!endDate) {
    // No end date means no access schedule so defaulting to expired
    return true;
  }
  const now = dayjs();
  return now.isAfter(endDate);
}

import Decimal from "decimal.js";
import { CreditType } from "app/types/credit-types";

export type Rate =
  | {
      __typename?: "CustomRate";
      value: object;
      credit_type: CreditType;
    }
  | {
      __typename?: "ScalarFlatRate";
      unit_price: string;
      credit_type_id: string;
    }
  | {
      __typename?: "FlatRate";
      unit_price: string;
      credit_type: CreditType;
    }
  | {
      __typename?: "PercentageRate";
      fraction: string;
      use_list_prices: boolean;
    }
  | {
      __typename?: "SubscriptionRate";
      unit_price: string;
      quantity: string;
      is_prorated: boolean;
      credit_type: CreditType;
    }
  | {
      __typename?: "TieredRate";
      tiers: Array<{
        __typename?: "Tier";
        size: string | null;
        unit_price: string;
      }>;
      credit_type: CreditType;
    };

type RateFormatted =
  | {
      type: "flat";
      unit_price: number;
    }
  | {
      type: "percentage";
      fraction: number;
      use_list_prices: boolean;
    }
  | {
      type: "subscription";
      unit_price: number;
      quantity: number;
      is_prorated: boolean;
    }
  | {
      type: "custom";
      value: unknown;
    }
  | {
      type: "tiered";
      tiers: {
        unit_price: number;
        size?: number;
      }[];
    };

export function formatRate(rate: Rate): RateFormatted {
  switch (rate.__typename) {
    case undefined:
      throw new Error("Rate type is undefined");
    case "ScalarFlatRate":
      return {
        type: "flat",
        unit_price: new Decimal(rate.unit_price).toNumber(),
      };
    case "FlatRate":
      return {
        type: "flat",
        unit_price: new Decimal(rate.unit_price).toNumber(),
      };
    case "PercentageRate":
      return {
        type: "percentage",
        fraction: new Decimal(rate.fraction).toNumber(),
        use_list_prices: rate.use_list_prices,
      };
    case "SubscriptionRate":
      return {
        type: "subscription",
        unit_price: new Decimal(rate.unit_price).toNumber(),
        quantity: new Decimal(rate.quantity).toNumber(),
        is_prorated: rate.is_prorated,
      };
    case "CustomRate":
      return {
        type: "custom",
        value: rate.value,
      };
    case "TieredRate":
      return {
        type: "tiered",
        tiers: rate.tiers.map((tier) => ({
          unit_price: new Decimal(tier.unit_price).toNumber(),
          size: tier.size ? new Decimal(tier.size).toNumber() : undefined,
        })),
      };
  }
}

export function getRateCreditTypeId(rate: Rate): string | undefined {
  switch (rate.__typename) {
    case undefined:
      throw new Error("Rate type is undefined");
    case "ScalarFlatRate":
      return rate.credit_type_id;
    case "FlatRate":
    case "SubscriptionRate":
    case "CustomRate":
    case "TieredRate":
      return rate.credit_type.id;
    case "PercentageRate":
      return undefined;
  }
}

export function stringToCsvDownload(csvContent: string, filename: string) {
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

import React from "react";
import { useSnackbar } from "components/deprecated/Snackbar";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Body } from "design-system";
import { Button } from "components/Button";
import { useRegenerateInvoiceMutation } from "./regenInvoice.graphql";

interface RegenerateVoidedInvoiceModalProps {
  onClose: () => void;
  invoiceId: string;
}

const DeprecatedRegenerateVoidedInvoiceModal: React.FC<
  RegenerateVoidedInvoiceModalProps
> = ({ onClose, invoiceId }) => {
  const [regenInvoiceMutation, regenInvoiceResult] =
    useRegenerateInvoiceMutation();
  const pushMessage = useSnackbar();

  const regenInvoice = async () => {
    await regenInvoiceMutation({
      variables: {
        invoice_id: invoiceId,
      },
      update(cache) {
        cache.evict({ fieldName: "mri_invoice" });
        cache.evict({ fieldName: "mri_invoices" });
        cache.evict({ fieldName: "Customer" });
        cache.evict({ fieldName: "Customer_by_pk" });
        cache.gc();
      },
    });
  };

  return (
    <DeprecatedPopup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={regenInvoiceResult.loading}
            onClick={async () => {
              try {
                await regenInvoice();
                pushMessage({
                  type: "success",
                  content: "Successfully regenerated invoice",
                });
              } catch (e: any) {
                pushMessage({
                  content: `Failed to regenerate invoice`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Regenerate invoice"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Regenerate voided invoice"
    >
      <Body level={2}>
        This action will regenerate this invoice with the latest version of the
        contract and the current usage values (if applicable).
      </Body>
    </DeprecatedPopup>
  );
};

export default DeprecatedRegenerateVoidedInvoiceModal;

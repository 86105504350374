import React from "react";

import {
  PlanPanelProps,
  PlanPanelContent,
  usePlanMenus,
} from "app/pages/deprecated/Customer/tabs/Plans/components/PlanPanel";

import { ContractCustomerPlanContainer } from "./Container";
import { CustomerPlanDetailsPanel } from "./CustomerPlanDetailsPanel";
import { useCustomerFromRoute } from "../../lib/Customer";
import { DetailedCustomerPlanFragmentReader } from "../../lib/CustomerPlan";
import { useCustomerPlanFromRoute } from "./useCustomerPlanFromRoute";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";

const PlanPreview: React.FC<{
  customerId: string;
  customerName: string;
  customerPlan: DetailedCustomerPlanFragmentReader;
}> = ({ customerId, customerName, customerPlan }) => {
  const planPanelProps: PlanPanelProps = {
    customerId,
    customerName,
    customerPlan: customerPlan.fragment,
  };

  return (
    <PlanPanelContent
      {...planPanelProps}
      customPlanBasics={
        <CustomerPlanDetailsPanel customerPlan={customerPlan} />
      }
    />
  );
};

import { IconButton } from "components/IconButton";

export const ContractCustomerPlanOverview: React.FC = () => {
  const customerReq = useCustomerFromRoute();
  const customerPlanReq = useCustomerPlanFromRoute();

  const { menuOptions, openMenuComponent } = usePlanMenus(
    customerReq.state === "success" && customerPlanReq.state === "success"
      ? {
          customerId: customerReq.customer.id,
          customerName: customerReq.customer.name,
          customerPlan: customerPlanReq.customerPlan.fragment,
        }
      : {
          customerId: "",
          customerName: "",
          customerPlan: null,
        },
  );

  return (
    <ContractCustomerPlanContainer
      tabsAction={
        menuOptions ? (
          <DeprecatedPopoverMenu
            positions={["bottom"]}
            align="start"
            options={menuOptions}
          >
            {(onClick) => (
              <IconButton
                onClick={onClick}
                theme="secondary"
                icon="settings01"
              />
            )}
          </DeprecatedPopoverMenu>
        ) : undefined
      }
      customerReq={customerReq}
      customerPlanReq={customerPlanReq}
      content={({ customer, customerPlan }) => (
        <>
          {openMenuComponent}
          <PlanPreview
            customerId={customer.id}
            customerName={customer.name}
            customerPlan={customerPlan}
          />
        </>
      )}
    />
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { AppShell } from "components/AppShell";
import { Button } from "components/Button";
import { useApolloClient } from "@apollo/client";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "app/lib/environmentSwitcher/context";

import { useAuthCheck } from "app/lib/useAuthCheck";
import { useSnackbar } from "components/deprecated/Snackbar";
import {
  Launch_Sox_Report_GeneratorDocument,
  Launch_Sox_Report_GeneratorMutation,
  Launch_Sox_Report_GeneratorMutationVariables,
} from "app/lib/reports/reportTypes/soxReports/queries.graphql";
import { reportToSentry } from "app/lib/errors/sentry";
import { downloadCSV, emailValidation, REPORTS } from "app/lib/reports";
import {
  ReportConfig,
  SOXReport,
} from "app/lib/reports/reportTypes/reportConfig";
import { Modal } from "components/Modal";
import { TextInput } from "components/Input";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { DatePicker } from "components/DatePicker";
import { Tooltip } from "components/Tooltip";
import NotFoundPage from "app/pages/404";
import { Checkbox } from "components/Checkbox";

const Reporting: React.FC = () => {
  const ldClient = useLDClient();
  const canGenerateSOXReport = !!useAuthCheck(
    Launch_Sox_Report_GeneratorDocument,
  ).allowed;

  const availableReports = REPORTS.filter(
    (report) =>
      report.isAllowedForUser(ldClient) &&
      (report.type !== "email" || canGenerateSOXReport),
  );

  if (availableReports.length === 0) {
    return <NotFoundPage />;
  }
  const reportContent = (
    <div className="max-w-[750px]">
      <div className="flex flex-col gap-4">
        <span className="text-gray-900">Report builder</span>
        <span className="text-gray-700 text-sm font-normal">
          Use this tool to generate reports about your customers and revenue.
          All revenue data is based on finalized invoices.
        </span>
        <ReportsBody availableReports={availableReports} />
      </div>
    </div>
  );

  return <AppShell title="Reports">{reportContent}</AppShell>;
};

const ReportsBody: React.FC<{
  availableReports: (ReportConfig<any> | SOXReport)[];
}> = ({ availableReports }) => {
  const [selectedReport, setSelectedReport] = useState<
    (ReportConfig<any> | SOXReport) | null
  >(availableReports[0]);
  const [buildingReport, setBuildingReport] = useState(false);
  const [dates, setDates] = useState<{
    inclusiveStartDate?: Date;
    exclusiveEndDate?: Date;
  } | null>({});
  const [reportEmail, setReportEmail] = useState<string | undefined>();
  const [shouldSendToWorkato, setShouldSendToWorkato] = useState<
    boolean | undefined
  >();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const client = useApolloClient();
  const pushMessage = useSnackbar();
  const { environment, prefixUrl } = useEnvironment();
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (availableReports.length && !selectedReport) {
      setSelectedReport(availableReports[0]);
    }
  }, [availableReports, selectedReport]);

  const doesAnyAvailableReportTypeSupportDates = availableReports.some(
    (r) => r.needsDates,
  );

  /* Handles csv updates and completion of CSV download */
  const datesAreValid =
    !selectedReport?.needsDates ||
    (dates?.inclusiveStartDate &&
      dates.exclusiveEndDate &&
      dayjs(dates.inclusiveStartDate).isBefore(dates.exclusiveEndDate));

  const restrictMaxDate =
    selectedReport &&
    "restrictEndDateToToday" in selectedReport &&
    selectedReport.restrictEndDateToToday;

  // Get the current date in UTC
  const today = new Date();
  const utcToday = dayjs(today)
    .utc()
    .subtract(dayjs(today).utcOffset(), "minute")
    .toDate();

  const generateReport = async () => {
    const reportConfig = selectedReport as ReportConfig<any>;
    if (!reportConfig) {
      return;
    }
    if (!datesAreValid) {
      return;
    }
    if (buildingReport) {
      return;
    }
    const { pageSize } = reportConfig;
    setBuildingReport(true);

    const query = reportConfig.queryDocument;
    const pages = [];
    let cursor = undefined;
    try {
      while (true) {
        const response = await client.query({
          query,
          variables: {
            limit: pageSize + 1,
            cursor,
            snapshot_start: dates?.inclusiveStartDate,
            snapshot_end: dates?.exclusiveEndDate,
          },
        });
        const page: any = response.data;
        pages.push(page);
        const nextCursor = reportConfig.nextCursor(page);
        if (nextCursor) {
          cursor = nextCursor;
          continue;
        }
        break;
      }
    } catch (e: any) {
      pushMessage({
        content: "Failed to generate report.",
        type: "error",
      });
      /* Enable more downloads */
      setBuildingReport(false);
      return;
    }
    let rows: string[][] = [];
    if (reportConfig.needsEnvironment) {
      rows = reportConfig.dataToCSV(pages, environment, prefixUrl);
    } else if (reportConfig.needsDates) {
      if (!dates?.inclusiveStartDate || !dates.exclusiveEndDate) {
        return;
      }
      rows = reportConfig.dataToCSV(
        pages,
        dates.inclusiveStartDate,
        dates?.exclusiveEndDate,
      );
    } else {
      rows = reportConfig.dataToCSV(pages);
    }
    const startDateString = dayjs(dates?.inclusiveStartDate)
      .utc()
      .format("YYYY-MM-DD");
    const endDateString = dayjs(dates?.exclusiveEndDate)
      .utc()
      .format("YYYY-MM-DD");
    const reportName = reportConfig.needsDates
      ? `${reportConfig.name} ${startDateString}-${endDateString}`
      : reportConfig.name;
    downloadCSV(`${reportName}.csv`, rows);
    /* Enable more downloads */
    setBuildingReport(false);
  };

  const generateSOXReport = useCallback(async () => {
    if (
      dates?.inclusiveStartDate &&
      dates?.exclusiveEndDate &&
      reportEmail &&
      selectedReport
    ) {
      try {
        const data = await apolloClient.mutate<
          Launch_Sox_Report_GeneratorMutation,
          Launch_Sox_Report_GeneratorMutationVariables
        >({
          mutation: Launch_Sox_Report_GeneratorDocument,
          variables: {
            startDate: dates?.inclusiveStartDate.toISOString(),
            endDate: dates?.exclusiveEndDate.toISOString(),
            reportEmail: reportEmail,
            customReportType: (selectedReport as SOXReport).customReportType,
            shouldSendToClient: shouldSendToWorkato,
          },
        });
        setModalOpen(false);
        if (!data) {
          const errorMessage =
            "Failed to generate sox compliance report: no data returned";
          reportToSentry(new Error(errorMessage));
          throw new Error(errorMessage);
        }
      } catch (e) {
        const errorMessage = `Failed to generate sox compliance report: ${e}`;
        reportToSentry(new Error(errorMessage));
        throw new Error(errorMessage);
      }
    }
  }, [dates, reportEmail, selectedReport, shouldSendToWorkato]);

  const handleGenerateReportClick = useCallback(async () => {
    console.log(selectedReport);
    if (selectedReport?.type === "email") {
      setModalOpen(true);
    } else {
      await generateReport();
    }
  }, [dates]);

  const isEmailInvalid = !!(reportEmail && !emailValidation.test(reportEmail));
  const shouldDisplayWorkatoCheckbox =
    selectedReport &&
    "canSendToWorkato" in selectedReport &&
    selectedReport.canSendToWorkato;

  return (
    <>
      {(selectedReport &&
        "additionalDescription" in selectedReport &&
        selectedReport?.additionalDescription) ??
        null}
      <div className="mt-8 flex justify-between gap-12">
        <div className="flex min-w-[300px] flex-col">
          <Dropdown
            label="Report"
            disabled={buildingReport}
            selectedOption={selectedReport?.name}
            fullWidth={true}
          >
            {availableReports.map((report, i) => (
              <DropdownItem
                label={report.name}
                value={report.name}
                key={`${report.name}-${i}`}
                onClick={() => setSelectedReport(availableReports[i] ?? null)}
              />
            ))}
          </Dropdown>
        </div>
        {doesAnyAvailableReportTypeSupportDates && (
          <>
            <div>
              <DatePicker
                value={dates?.inclusiveStartDate}
                tooltipContent={{
                  label: "Inclusive starting time of this report",
                }}
                onDateApply={(value) => {
                  setDates({
                    ...dates,
                    inclusiveStartDate: value
                      ? dayjs.utc(value).startOf("day").toDate()
                      : undefined,
                  });
                }}
                maxDate={restrictMaxDate ? utcToday : undefined}
                text="Starting at"
              />
            </div>
            <div>
              <DatePicker
                value={
                  dates?.exclusiveEndDate
                    ? dayjs.utc(dates.exclusiveEndDate).toDate()
                    : undefined
                }
                tooltipContent={{
                  label: "Exclusive end time of this report",
                }}
                onDateApply={(value) => {
                  setDates({
                    ...dates,
                    exclusiveEndDate: value
                      ? dayjs.utc(value).startOf("day").toDate()
                      : undefined,
                  });
                }}
                maxDate={restrictMaxDate ? utcToday : undefined}
                text="Ending before"
              />
            </div>
          </>
        )}
        <div className="self-end">
          <Tooltip
            disabled={datesAreValid}
            label="The start date must be before the end date."
          >
            <Button
              disabled={!datesAreValid}
              onClick={handleGenerateReportClick}
              loading={buildingReport}
              text="Generate report"
              theme="primary"
              leadingIcon={buildingReport ? "plusCircle" : "download02"}
            />
          </Tooltip>
        </div>
        <Modal
          modalButtons={[
            <Button
              key="primary"
              disabled={!reportEmail || isEmailInvalid}
              onClick={generateSOXReport}
              text="Email Report"
              theme="primary"
            />,
            <Button
              onClick={() => setModalOpen(false)}
              text="Cancel"
              theme="linkGray"
            />,
          ]}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title={selectedReport?.name || "SOX Compliance Invoice report"}
        >
          <div>
            <span className="text-sm text-gray-500">
              Note that the{" "}
              {selectedReport?.name || "SOX Compliance Invoice report"} report
              will be delivered via email within 12 hours of requesting. Please
              enter an email address to receive the report below:
            </span>
            <div className="mt-12 min-h-[90px]">
              <TextInput
                placeholder="email"
                type="text"
                value={reportEmail}
                onChange={({ value }) => setReportEmail(value)}
                label="Enter your email"
                isInvalid={isEmailInvalid}
                hintText={isEmailInvalid ? "Invalid Email" : undefined}
              />
            </div>
            {shouldDisplayWorkatoCheckbox && (
              <div>
                <Checkbox
                  checked={shouldSendToWorkato}
                  label="Send to Workato?"
                  size="sm"
                  onChange={(value) => setShouldSendToWorkato(value.checked)}
                ></Checkbox>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Reporting;

import classnames from "classnames";
import React from "react";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import styles from "./index.module.less";
import { Body, Caption, Headline, Subtitle } from "design-system";
import { IconButton } from "components/IconButton";
import { ProductDetailsQuery, useProductDetailsQuery } from "./queries.graphql";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { Badge, Icon } from "design-system";
import { DeprecatedCards } from "components/deprecated/Cards";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { Tooltip } from "design-system";
import { renderDate } from "lib/time";
import { AvatarWithName } from "design-system";
import { ChargeTypeEnum_Enum } from "types/generated-graphql/__types__";

interface ProductDetailsProps {
  onRequestClose: () => void;
  productId: string;
}

type Plan = RequiredNonNullable<
  RequiredNonNullable<ProductDetailsQuery>["Product_by_pk"]
>["PricedProducts"][0]["Plan"];

export const ProductDetails: React.FC<ProductDetailsProps> = (props) => {
  const { data, loading } = useProductDetailsQuery({
    variables: { productId: props.productId },
  });
  const product = data?.Product_by_pk;

  const plans = Object.values(
    (product?.PricedProducts || []).reduce<{ [id: string]: Plan }>(
      (prev, curr) => {
        const plan = curr.Plan;
        if (prev[plan.id]) {
          return prev;
        } else {
          prev[plan.id] = plan;
          return prev;
        }
      },
      {},
    ),
  );
  return (
    <DeprecatedRightPane
      onRequestClose={props.onRequestClose}
      isOpen={true}
      className={styles.productDetails}
    >
      <>
        <div className={styles.header}>
          <div>
            {loading || !product ? (
              <DeprecatedTextSkeleton />
            ) : (
              <>
                <Headline level={6}>{product.name}</Headline>
                <Body level={2}>{product.id}</Body>
              </>
            )}
          </div>
          <IconButton
            onClick={props.onRequestClose}
            theme="secondary"
            icon="xClose"
          />
        </div>
        {loading || !product ? (
          <DeprecatedTextSkeleton />
        ) : (
          <Body level={2}>{product.description}</Body>
        )}
        <Subtitle level={4} className={styles.charges}>
          Charges
        </Subtitle>
        <DeprecatedCards
          loading={loading}
          cards={(product?.ProductPricingFactors ?? []).map((ppf) => ({
            id: ppf.id,
            title: ppf.name,
            badge:
              ppf.charge_type_enum === ChargeTypeEnum_Enum.Usage ? (
                <Badge theme="primary" type="dark" className={styles.badge}>
                  Usage-based
                </Badge>
              ) : ppf.charge_type_enum === ChargeTypeEnum_Enum.Seat ? (
                <Badge theme="warning" type="dark" className={styles.badge}>
                  Seat
                </Badge>
              ) : ppf.charge_type_enum === ChargeTypeEnum_Enum.Flat ? (
                <Badge theme="grey" type="dark" className={styles.badge}>
                  Fixed
                </Badge>
              ) : (
                <Badge
                  theme="success"
                  type="dark"
                  className={classnames(styles.badge, styles.compositeBadge)}
                >
                  Composite
                </Badge>
              ),
            content: (
              <>
                <div className={styles.pricingFactorMetric}>
                  <Icon icon="analytics" className={styles.pricingFactorIcon} />
                  <Caption>Billable Metric</Caption>
                </div>
                <Subtitle level={4}>
                  {ppf.BillableMetric?.name ?? ppf.seat_metric?.name ?? "N/A"}
                </Subtitle>
              </>
            ),
          }))}
        />
        <Subtitle level={4} className={styles.plans}>
          Associated Plans
        </Subtitle>

        <DeprecatedCards
          loading={loading}
          cards={plans.map((plan) => ({
            id: plan.id,
            title: plan.name,
            subtitle: <DeprecatedCopyableID id={plan.id} label="plan ID" />,
            content: plan.description,
            statistics: [
              {
                icon: "people",
                value: plan.customer_count ?? 0,
                label: "customer",
              },
              {
                icon: "cart",
                value: plan.PricedProducts_aggregate.aggregate?.count ?? 0,
                label: "product",
              },
            ],
            footer:
              plan.Actor != null ? (
                <Tooltip
                  content={
                    <>
                      Created by {plan.Actor.name}
                      <br />
                      {renderDate(new Date(plan.created_at), {
                        isUtc: false,
                      })}
                    </>
                  }
                >
                  <AvatarWithName {...plan.Actor} />
                </Tooltip>
              ) : undefined,
          }))}
        />
      </>
    </DeprecatedRightPane>
  );
};

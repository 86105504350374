import { useAsyncAllCommitUsage } from "app/pages/Contracts/Customer/Contracts/Usage";
import React, { useMemo, useState } from "react";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { ProgressBar, ProgressBarProps } from "components/ProgressBar";
import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import { useAllCommitsAndCreditTypesQuery } from "app/pages/Contracts/Customer/Contracts/Commits/data.graphql";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { Dayjs, printDateRange, useNow } from "lib/date";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import { CreditType } from "app/types/credit-types";
import Decimal from "decimal.js";
import { RoundedCurrency } from "app/lib/credits";
import { Tooltip } from "components/Tooltip";
import { capitalizeFirstLetter } from "app/lib/utils";
import { CommitDatesFragment } from "app/pages/Contracts/lib/Commit/fragments.graphql";
import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { Badge } from "components/Badge";

type CommitsAndCreditsProps = {
  customerId: string;
};

type FilterOptions = "all" | "active" | "expired" | "upcoming";
const FilterOptions = {
  ALL: "all" as FilterOptions,
  ACTIVE: "active" as FilterOptions,
  EXPIRED: "expired" as FilterOptions,
  UPCOMING: "upcoming" as FilterOptions,
};
const filterOptionsArray: FilterOptions[] = [
  FilterOptions.ALL,
  FilterOptions.EXPIRED,
  FilterOptions.ACTIVE,
  FilterOptions.UPCOMING,
];

type BadgeInfo = {
  label: string;
  theme: "error" | "gray";
};

type Commits = Pick<
  ProgressBarProps,
  "presentationTotal" | "total" | "values"
> & {
  tooltipContent: {
    dates: string;
  };
  linkTo: {
    link: string;
    label: string;
  };
  badge?: BadgeInfo;
};

type DateRange = {
  start?: Dayjs | undefined;
  end?: Dayjs | undefined;
} | null;

export const renderCurrency = (amount: Decimal, creditType: CreditType) => {
  return <RoundedCurrency amount={amount} creditType={creditType} />;
};

type CommitDisplayState = "active" | "expired" | "upcoming" | undefined;
const getDisplayState = (
  c: CommitDatesFragment,
  dates: DateRange,
  now: Dayjs,
): CommitDisplayState => {
  if (Commit.isExpired(c)) {
    return "expired";
  }
  if (dates?.start && dates?.start <= now) {
    return "active";
  }
  if (dates?.start && dates?.start > now) {
    return "upcoming";
  }
  return undefined;
};

function getCommitBadgeInfo(
  commitState: CommitDisplayState,
): BadgeInfo | undefined {
  switch (commitState) {
    case "expired":
      return {
        label: "Expired",
        theme: "error",
      };
    case "upcoming":
      return {
        label: "Upcoming",
        theme: "gray",
      };
    case "active":
    default:
      return undefined;
  }
}

// FOR NON PLANS-ONLY
export const CommitsAndCredits: React.FC<CommitsAndCreditsProps> = ({
  customerId,
}) => {
  const now = useNow();
  const asyncUsage = useAsyncAllCommitUsage({
    customerId,
  });
  const req = useApolloResp(
    useAllCommitsAndCreditTypesQuery({
      variables: { customerId },
    }),
  );

  const loading = req.state === "loading" || asyncUsage.loading;

  const [filter, setFilter] = useState<FilterOptions[]>([
    FilterOptions.ACTIVE,
    FilterOptions.UPCOMING,
  ]);

  const rawCommits = useMemo(() => {
    if (!loading && req.state === "success") {
      return [
        ...req.customer.contracts.flatMap(
          (c) => c.v2_fields?.commits_union ?? [],
        ),
        ...req.customer.commits,
      ];
    }
    return [];
  }, [loading, req]);

  const allCommits = useMemo(() => {
    if (!loading && req.state === "success" && asyncUsage) {
      let filteredCommits;
      if (filter[0] === FilterOptions.ALL) {
        filteredCommits = rawCommits;
      } else {
        filteredCommits = rawCommits.filter((c) => {
          const dates = Commit.getDateRange(c);
          const commitExpired = Commit.isExpired(c);

          return (
            (filter.includes(FilterOptions.EXPIRED) && commitExpired) ||
            (filter.includes(FilterOptions.ACTIVE) &&
              dates?.start &&
              dates?.start <= now &&
              !commitExpired) ||
            (filter.includes(FilterOptions.UPCOMING) &&
              dates?.start &&
              dates?.start > now &&
              !commitExpired)
          );
        });
      }
      const commitsForProgressBar: Commits[] = [];

      filteredCommits.forEach((c) => {
        const dates = Commit.getDateRange(c);

        let datesString: string;
        const commitState = getDisplayState(c, dates, now);
        const label = commitState ? `(${commitState})` : "";
        if (!(dates?.start && dates.end)) {
          datesString = "Access schedule: --";
        } else {
          datesString = `${printDateRange(now, dates.start, dates.end, {
            excludeUtcLabel: true,
          })} ${label}`;
        }

        const usage = asyncUsage.forCommit(c.id);
        if (!usage) {
          return;
        }

        commitsForProgressBar.push({
          tooltipContent: {
            dates: datesString,
          },
          linkTo: {
            label: Commit.getName(c, now),
            link: Commit.getRoutePath(c),
          },
          total: usage.total.toNumber(),
          presentationTotal: Commit.renderTotalAmount(c),
          values: {
            label: "Consumed",
            value: usage.used.toNumber(),
            presentationValue: renderCurrency(
              usage.used,
              c.access_schedule.credit_type,
            ),
          },
          badge: getCommitBadgeInfo(commitState),
        });
      });

      return commitsForProgressBar;
    }
    return [];
  }, [req, asyncUsage, loading, filter]);

  if (!loading && req.state === "success" && rawCommits.length === 0) {
    return null;
  }

  if (req.state === "error") {
    return (
      <ErrorEmptyState
        title="We ran into an error loading this customer's commits"
        error={req.error}
      />
    );
  }

  if (asyncUsage.error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading this customer's commit usage"
        error={getUserFacingErrorMessage(asyncUsage.error)}
      />
    );
  }

  const renderFilter = () => (
    <Dropdown
      key="credit grant dropdown"
      icon="filterLines"
      hideChevron={true}
      label="Status"
      children={filterOptionsArray.map((o, idx) => (
        <DropdownItem
          key={`${idx}-${o}`}
          label={capitalizeFirstLetter(o)}
          value={o}
          selected={filter.includes(o)}
          onClick={(meta) => {
            const val = meta.value as FilterOptions;
            if (filter.includes(val)) {
              setFilter(filter.filter((f) => f !== val));
            } else if (val === FilterOptions.ALL) {
              setFilter([FilterOptions.ALL]);
            } else {
              setFilter([
                ...filter.filter((f) => f !== FilterOptions.ALL),
                val,
              ]);
            }
          }}
          isCheckbox={true}
        />
      ))}
    />
  );

  return (
    <Card
      title="Commits & credits"
      loading={loading}
      headerActions={[
        <Button
          text="Commits & credits"
          leadingIcon="arrowNarrowUpRight"
          theme="secondary"
          linkTo={`/customers/${customerId}/commits-and-credits`}
        />,
        renderFilter(),
      ]}
    >
      {allCommits.length === 0 ? (
        <div className="my-3xl flex w-full flex-col items-center text-gray-600">
          <h3 className="text-md font-semibold text-black">No credits</h3>
          {filter.length > 0 && (
            <div>This customer has no credits for the given filter</div>
          )}
        </div>
      ) : (
        <div className="flex max-h-[280px] flex-col gap-y-xl overflow-y-auto overflow-x-hidden">
          {allCommits.map((commit, idx) => (
            <ProgressBar
              key={idx}
              total={commit.total}
              presentationTotal={commit.presentationTotal}
              headerContent={[
                <div className="flex items-center gap-x-xs">
                  <DeprecatedInternalLink
                    routePath={commit.linkTo.link}
                    className="flex items-center"
                  >
                    <Icon
                      icon="arrowNarrowUpRight"
                      size={20}
                      className="mr-xs"
                    />
                    <h4 className="text-sm font-medium text-gray-600">
                      {commit.linkTo.label}
                    </h4>
                  </DeprecatedInternalLink>
                  <Tooltip label={commit.tooltipContent.dates}>
                    <Icon icon="infoCircle" size={15} />
                  </Tooltip>
                  {commit.badge && (
                    <Badge
                      label={commit.badge.label}
                      theme={commit.badge.theme}
                      size="sm"
                    />
                  )}
                </div>,
              ]}
              values={commit.values}
            />
          ))}
        </div>
      )}
    </Card>
  );
};

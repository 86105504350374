import React, { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { FullScreenPage } from "components/AppShell";
import { Subtitle } from "design-system";
import { IconButton } from "components/IconButton";
import {
  DeprecatedWizardSidebar,
  CurrentStep,
  WizardSection,
} from "./components/Sidebar";
import { DeprecatedWizardFooter } from "./components/WizardFooter";
import { DeprecatedPageHeader } from "components/deprecated/PageHeader";
import { LoadingSpinner } from "design-system";
import { DeprecatedNavigationPrompt } from "components/deprecated/Popup";

export {
  DeprecatedWizardFullPage,
  DeprecatedWizardSplitPage,
} from "./components/WizardPage";
export type { WizardSection, WizardSubStep } from "./components/Sidebar";
export { DeprecatedInputSection } from "./components/InputSection";
export { DeprecatedWizardFooter } from "./components/WizardFooter";
export { getActiveStep } from "./helpers";
export { useWizardContext } from "./context";

import styles from "./index.module.less";
import { Context } from "./context";
import { findSubStepByNavigationKey } from "./helpers";

interface WizardProps {
  sections: WizardSection[];
  save?: () => Promise<void>;
  saveInProgress?: boolean;
  onClose: () => void;
  onDone: () => void;
  doneButtonTitle?: string;
  closeWarning?: string;
  title: string;
  subtitle: React.ReactNode;
  startAt?: CurrentStep;
  loading: boolean;
}

const LoadingText: React.FC = () => {
  return (
    <div className={styles.loadingText}>
      <LoadingSpinner />
      <Subtitle level={4} className={styles.text}>
        Saving your progress
      </Subtitle>
    </div>
  );
};

export const DeprecatedWizard: React.FC<WizardProps> = ({
  sections,
  save,
  saveInProgress,
  onClose,
  onDone,
  doneButtonTitle,
  closeWarning,
  title,
  subtitle,
  startAt,
  loading,
}) => {
  const [currStep, setCurrStep] = useState<CurrentStep>({
    section: startAt?.section ?? 0,
    group: startAt?.group ?? 0,
    subStep: startAt?.subStep ?? 0,
  });
  const [isClosing, setIsClosing] = useState(false);

  const isMounted = useRef<boolean>(true);
  useEffect(() => {
    // The empty dependency list means that cleanup will only be run when the
    // component is unmounted. We use a ref to avoid closure issues: everything
    // needs to be able to fetch the current value, not one they captured.
    // Technique taken from https://stackoverflow.com/a/59956926.
    return () => {
      isMounted.current = false;
    };
  }, []);

  const stepContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scrolls to the top of the step container when the step changes
    if (stepContainerRef.current) {
      stepContainerRef.current.scrollTop = 0;
    }
  }, [currStep]);

  const activeStep =
    sections[currStep.section].subStepGroups[currStep.group].subSteps[
      currStep.subStep
    ];
  const isLastStep =
    currStep.section === sections.length - 1 &&
    currStep.group == sections.slice(-1)[0].subStepGroups.length - 1 &&
    currStep.subStep ===
      sections.slice(-1)[0].subStepGroups.slice(-1)[0].subSteps.length - 1;

  const getPrevStep = (step: CurrentStep) => {
    if (step.subStep > 0) {
      return {
        section: step.section,
        group: step.group,
        subStep: step.subStep - 1,
      };
    } else if (step.group > 0) {
      const prevGroupSubSteps =
        sections[step.section].subStepGroups[step.group - 1].subSteps;
      return {
        section: step.section,
        group: step.group - 1,
        subStep: prevGroupSubSteps.length - 1,
      };
    } else if (step.section > 0) {
      const prevSectionGroups = sections[step.section - 1].subStepGroups;
      const prevGroupSubSteps =
        prevSectionGroups[prevSectionGroups.length - 1].subSteps;
      return {
        section: step.section - 1,
        group: prevSectionGroups.length - 1,
        subStep: prevGroupSubSteps.length - 1,
      };
    }
    return null;
  };

  const getNextStep = (step: CurrentStep) => {
    const currGroups = sections[step.section].subStepGroups;
    const currSubSteps = currGroups[step.group].subSteps;
    if (step.subStep < currSubSteps.length - 1) {
      // There are more sub-steps
      return {
        section: step.section,
        group: step.group,
        subStep: step.subStep + 1,
      };
    } else if (step.group < currGroups.length - 1) {
      // there are no more sub steps in this group, but there's a next group
      return {
        section: step.section,
        group: step.group + 1,
        subStep: 0,
      };
    } else if (step.section < sections.length - 1) {
      // there are no more sub steps or groups in this section, go to next section
      return { section: step.section + 1, group: 0, subStep: 0 };
    }
    return null;
  };

  const onClickBack = () => {
    let prevStep = undefined;
    while (true) {
      prevStep = getPrevStep(prevStep ?? currStep);
      if (prevStep === null) {
        return;
      }
      const prevSubStep =
        sections[prevStep.section].subStepGroups[prevStep.group].subSteps[
          prevStep.subStep
        ];
      if (
        !(prevSubStep.isDone && prevSubStep.skipIfDone) ||
        prevStep.group !== currStep.group ||
        prevStep.section !== currStep.section
      ) {
        setCurrStep(prevStep);
        return;
      }
    }
  };

  const onClickContinue = async () => {
    if (!activeStep.isDone) {
      return;
    }
    if (!isLastStep && save) {
      await save();
    }

    if (!saveInProgress && isMounted.current) {
      let nextStep = undefined;
      // search for next step until we get to:
      // the end OR a step which is not (done and marked as skip if done) OR a step which is in a different group/section
      while (true) {
        nextStep = getNextStep(nextStep ?? currStep);
        if (nextStep === null) {
          // this is the last step!
          flushSync(() => {
            setIsClosing(true);
          });
          onDone();
          return;
        }
        const nextSubStep =
          sections[nextStep.section].subStepGroups[nextStep.group].subSteps[
            nextStep.subStep
          ];
        if (
          !(nextSubStep.isDone && nextSubStep.skipIfDone) ||
          nextStep.group !== currStep.group ||
          nextStep.section !== currStep.section
        ) {
          setCurrStep(nextStep);
          return;
        }
      }
    }
  };

  const onClickClose = async () => {
    flushSync(() => {
      setIsClosing(true);
    });
    if (save && !saveInProgress) {
      await save();
    }
    onClose();
    if (isMounted.current) {
      flushSync(() => {
        setIsClosing(false);
      });
    }
  };

  const goToStep = (navigationKey: string) => {
    const step = findSubStepByNavigationKey(sections, navigationKey);
    if (step === undefined) {
      throw new Error("Invalid navigation key");
    }
    setCurrStep(step);
  };

  return (
    <>
      {closeWarning && (
        <DeprecatedNavigationPrompt disabled={isClosing} title={title}>
          {closeWarning}
        </DeprecatedNavigationPrompt>
      )}
      <FullScreenPage title={title}>
        <div className={styles.container}>
          <DeprecatedWizardSidebar
            sections={sections}
            currentStep={currStep}
            setCurrentStep={setCurrStep}
          />
          <div className={styles.main}>
            <DeprecatedPageHeader
              title={activeStep.header}
              subtitle={saveInProgress ? <LoadingText /> : undefined}
              type="primary"
              action={
                <div className={styles.headerActions}>
                  {subtitle}
                  <IconButton
                    onClick={onClickClose}
                    className={styles.closeButton}
                    loading={isClosing}
                    theme="secondary"
                    icon="xClose"
                  />
                </div>
              }
            />
            <div className={styles.stepContainer} ref={stepContainerRef}>
              <div className={styles.stepContent}>
                <Context.Provider value={{ goToStep }}>
                  {activeStep.component}
                </Context.Provider>
              </div>
            </div>
            <DeprecatedWizardFooter
              onClickBack={onClickBack}
              backDisabled={
                (currStep.section === 0 && currStep.subStep === 0) ||
                (currStep.subStep === 0 &&
                  sections[currStep.section - 1].subStepGroups.length === 0)
              }
              backLabel="Back"
              onClickContinue={onClickContinue}
              continueDisabled={!activeStep.isDone || saveInProgress || loading}
              continueLabel={
                saveInProgress
                  ? "Saving"
                  : isLastStep
                    ? doneButtonTitle ?? "Done"
                    : "Continue"
              }
              continueLoading={saveInProgress || loading}
            />
          </div>
        </div>
      </FullScreenPage>
    </>
  );
};

import { dayjs } from "lib/dayjs";
import { Decimal } from "decimal.js";
import { DateInput } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { DeprecatedTrialSpecSpendingCapInputSection } from "components/deprecated/TrialSpecInput";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { Body, Headline, Label } from "design-system";
import { CustomerPlansQuery } from "../../data/plans.graphql";
import { getUtcEndDate, renderDate } from "lib/time";
import { CreditTypeConversion } from "app/lib/plans/types";
import React, { useState } from "react";
import styles from "../../index.module.less";
import { useUpdateCustomerPlanTrialMutation } from "./queries.graphql";
import { useSnackbar } from "components/deprecated/Snackbar";
import { CreditAmount, CreditType } from "app/types/credit-types";

type TrialSpec = CustomerPlansQuery["CustomerPlan"][0]["trial_spec"];
interface SetTrialPaneProps {
  trialStartDate: Date;
  trialLengthInDays: number;
  trialSpendingCap: NonNullable<TrialSpec>["TrialSpecSpendingCaps"][0] | null;
  trialRemainingSpend: CreditAmount | undefined;
  customerPlanId: string;
  customerName: string;
  creditTypes: CreditType[];
  conversions: CreditTypeConversion[];
  onClose: () => void;
}

export const SetTrialPane: React.FC<SetTrialPaneProps> = ({
  trialStartDate,
  trialLengthInDays,
  trialSpendingCap,
  trialRemainingSpend,
  customerPlanId,
  customerName,
  creditTypes,
  conversions,
  onClose,
}) => {
  const pushMessage = useSnackbar();
  const [newTrialLengthInDays, setNewTrialLengthInDays] =
    useState(trialLengthInDays);
  const trialStartDayjs = dayjs.utc(trialStartDate);
  const trialEndDayjs = trialStartDayjs.add(newTrialLengthInDays, "day");

  const [hasCap, setHasCap] = useState(!!trialSpendingCap);
  const [newCapAmount, setNewCapAmount] = useState<Decimal | undefined>(
    trialSpendingCap ? new Decimal(trialSpendingCap.amount) : undefined,
  );
  const [newCapCreditTypeId, setNewCapCreditTypeId] = useState<
    string | undefined
  >(trialSpendingCap ? trialSpendingCap.CreditType.id : undefined);
  const [updateCustomerPlanTrialMutation, { loading, error }] =
    useUpdateCustomerPlanTrialMutation();

  const spentAmount =
    trialRemainingSpend &&
    trialSpendingCap &&
    trialSpendingCap.CreditType.id === trialRemainingSpend.credit_type.id
      ? {
          amount: new Decimal(trialSpendingCap.amount).sub(
            trialRemainingSpend.amount,
          ),
          credit_type: trialRemainingSpend.credit_type,
        }
      : undefined;

  return (
    <DeprecatedRightPane isOpen onRequestClose={onClose}>
      <div className={styles.rightPaneHeader}>
        <Headline level={5}>Edit trial</Headline>
        <IconButton onClick={onClose} theme="secondary" icon="xClose" />
      </div>
      <Body level={2}>
        Edit the length and pricing cap of the active trial. Any changes will be
        applied immediately.
      </Body>
      <div className={styles.trialSpecSection}>
        <Headline level={6}>Trial end date</Headline>
        <Body level={2}>
          Choose a new trial end date. The trial will end at midnight UTC on the
          selected date.
        </Body>
        <DateInput
          name="End date"
          value={newTrialLengthInDays > 0 ? trialEndDayjs.toDate() : undefined}
          minDate={dayjs
            .max([
              dayjs.utc(getUtcEndDate(new Date())),
              dayjs.utc(trialStartDate),
            ])
            .toDate()}
          isUTC
          onChange={(d) =>
            setNewTrialLengthInDays(dayjs.utc(d).diff(trialStartDayjs, "day"))
          }
        />
        {newTrialLengthInDays ? (
          <Label>{`${newTrialLengthInDays} day trial. Trial ends effective on ${renderDate(
            trialEndDayjs.toDate(),
            { isUtc: true, excludeYear: false, excludeUtcLabel: false },
          )}`}</Label>
        ) : (
          <Label>No trial</Label>
        )}
      </div>
      <div className={styles.trialSpecSection}>
        <DeprecatedTrialSpecSpendingCapInputSection
          trialSpendingCap={
            hasCap
              ? { amount: newCapAmount, creditTypeId: newCapCreditTypeId }
              : undefined
          }
          creditTypes={creditTypes}
          conversions={conversions}
          addTrialCap={() => {
            setHasCap(true);
            setNewCapAmount(undefined);
            setNewCapCreditTypeId(undefined);
          }}
          removeTrialSpendingCap={() => {
            setHasCap(false);
            setNewCapAmount(undefined);
            setNewCapCreditTypeId(undefined);
          }}
          setTrialSpecSpendingCaps={(caps) => {
            if (caps?.length) {
              setNewCapAmount(caps[0].amount);
              setNewCapCreditTypeId(caps[0].creditTypeId);
            }
          }}
          spentAmount={spentAmount}
        />
      </div>
      <div className={styles.rightPaneActions}>
        <Button onClick={onClose} text="Cancel" theme="linkGray" />
        <Button
          loading={loading}
          disabled={hasCap && !newTrialLengthInDays}
          onClick={async () => {
            await updateCustomerPlanTrialMutation({
              variables: {
                customer_plan_id: customerPlanId,
                trial_spec: {
                  length_in_days: newTrialLengthInDays,
                  spending_caps:
                    hasCap &&
                    newCapAmount &&
                    newCapCreditTypeId &&
                    newTrialLengthInDays
                      ? [
                          {
                            amount: newCapAmount.toString(),
                            credit_type_id: newCapCreditTypeId,
                          },
                        ]
                      : [],
                },
              },
            });

            if (error) {
              pushMessage({
                content: "Could not update customer's trial",
                durationMS: 1000,
                type: "error",
              });
            } else {
              if (!loading) {
                pushMessage({
                  content: `Trial updated for ${customerName}`,
                  type: "success",
                });
                onClose();
              }
            }
          }}
          text="Save"
          theme="primary"
        />
      </div>
    </DeprecatedRightPane>
  );
};

import React, { useEffect, useState } from "react";

import { Body } from "design-system";
import { Button } from "components/Button";

import { useChangeEmailMutation } from "./queries.graphql";
import { useCurrentUser } from "app/lib/auth";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Input } from "design-system";

interface EditEmailModalProps {
  onClose: () => void;
}

export const EditEmailModal: React.FC<EditEmailModalProps> = ({ onClose }) => {
  const { user } = useCurrentUser();

  const [change, changeResults] = useChangeEmailMutation();

  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(user?.email ?? "");
  }, [user?.name]);

  // Dead simple regex - Auth0 will validate this for us.
  const emailValid = /^.+@.+\..+$/.test(email);

  const save = async () => {
    if (!emailValid) {
      return;
    }
    await change({
      variables: { email },
    });
  };

  let emailError: string | undefined = undefined;
  if (!emailValid) {
    emailError = "Invalid email";
  }
  if (
    changeResults.called &&
    changeResults.error &&
    changeResults.error.graphQLErrors?.[0].extensions?.code ==
      "BAD_USER_INPUT" &&
    changeResults.error.graphQLErrors[0].extensions.argument === "email"
  ) {
    emailError = "This email is taken";
  }

  if (changeResults.called && !changeResults.loading && !changeResults.error) {
    return (
      <DeprecatedPopup
        actions={<Button onClick={onClose} text="Done" theme="primary" />}
        isOpen
        onRequestClose={onClose}
        title="Check your email"
      >
        <Body level={2}>
          An email has been sent to {email}. Click the link in the email to
          verify your email.
        </Body>
      </DeprecatedPopup>
    );
  }

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        disabled={!emailValid || changeResults.loading}
        onClick={save}
        loading={changeResults.loading}
        text="Save"
        theme="primary"
      />
    </>
  );
  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen
      onRequestClose={onClose}
      title="Change your email"
    >
      <Body level={2}>
        Enter new email address, then check your email to verify your new email.
      </Body>
      <Input
        value={email}
        onChange={setEmail}
        error={emailError}
        name="Enter new email"
        placeholder="example@example.com"
        autoFocus
      />
    </DeprecatedPopup>
  );
};

import React from "react";
import { NotFoundPage } from "app/pages/404";
import { UI_MODE, useUIMode } from "app/lib/useUIMode";

interface Props {
  element?: React.ReactNode;
  enabledModes?: UI_MODE[];
}

/** Render the element based on whether it exists for the user's UI mode */
export const NewUIElementSwitch: React.FC<Props> = (props) => {
  const { mode } = useUIMode();
  if (props.element && props.enabledModes) {
    return (
      <>
        {props.enabledModes.includes(mode) ? props.element : <NotFoundPage />}
      </>
    );
  } else if (props.element) {
    return <>{props.element}</>;
  } else {
    return <NotFoundPage />;
  }
};

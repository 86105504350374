import React from "react";

import { DeprecatedInternalLink } from "components/deprecated/Typography";

import { useNow } from "lib/date";
import { Commit } from "../lib/Commit";

export const CommitReference: React.FC<
  Commit.NameFragment & Commit.RoutePathFragment
> = (commit) => {
  const now = useNow();
  return (
    <DeprecatedInternalLink routePath={Commit.getRoutePath(commit)}>
      {Commit.getName(commit, now)}
    </DeprecatedInternalLink>
  );
};

import React, { useState } from "react";
import { DeprecatedPopup } from "components/deprecated/Popup";

import { useTestWebhooksMutation } from "../../data/webhooks.graphql";
import { Body } from "design-system";

import { Button } from "components/Button";

import styles from "./index.module.less";
import { useSnackbar } from "components/deprecated/Snackbar";

function randomNonSecureString(length: number) {
  const characters = "0123456789abcdef";
  return [...new Array(length)]
    .map(() => characters[Math.floor(Math.random() * characters.length)])
    .join("");
}

type TestWebhookModalProps = {
  onClose: () => void;
};

export const TestWebhookModal: React.FC<TestWebhookModalProps> = ({
  onClose,
}) => {
  const [message] = useState<string>(
    `test-message-${randomNonSecureString(16)}`,
  );
  const [testWebhook, testWebhookResult] = useTestWebhooksMutation({});
  const pushMessage = useSnackbar();

  const examplePayload = JSON.stringify(
    {
      id: "<random uuid>",
      type: "webhooks.test",
      properties: {
        message,
      },
    },
    null,
    2,
  );

  const actionButton = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        onClick={async () => {
          try {
            await testWebhook({ variables: { message } });
            pushMessage({
              type: "success",
              content: "Test notification sent!",
            });
          } catch (e) {
            pushMessage({
              type: "error",
              content: "Unknown error sending test notification",
            });
          }
          onClose();
        }}
        disabled={testWebhookResult.loading || testWebhookResult.called}
        text="Send test notification"
        theme="primary"
      />
    </>
  );
  return (
    <DeprecatedPopup
      title="Send test notification"
      isOpen
      className={styles.webhookPopup}
      onRequestClose={onClose}
      actions={actionButton}
    >
      <Body level={2}>
        This will send a test notification like the following to all your
        configured webhooks:
      </Body>
      <Body level={2}>
        <pre className={styles.payloadBlock}>
          <code>{examplePayload}</code>
        </pre>
      </Body>
    </DeprecatedPopup>
  );
};

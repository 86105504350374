import React from "react";
import DeprecatedCopyAssetToEnvironmentModal from "./index";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useApolloClient } from "@apollo/client";
import { createApolloClient } from "lib/apollo";
import { useAuth } from "app/lib/auth";
import {
  PlanDocument,
  PlanQuery,
  PlanQueryVariables,
  BillableMetricDetailsDocument,
  BillableMetricDetailsQuery,
  BillableMetricDetailsQueryVariables,
  InsertBillableMetricsDocument,
  InsertBillableMetricsMutation,
  InsertBillableMetricsMutationVariables,
  InsertProductDocument,
  InsertProductMutation,
  InsertProductMutationVariables,
  InsertPlanDocument,
  InsertPlanMutation,
  InsertPlanMutationVariables,
  CreditTypesDocument,
  CreditTypesQuery,
} from "./data/queries.graphql";
import {
  InsertCreditTypeDocument,
  InsertCreditTypeMutation,
  InsertCreditTypeMutationVariables,
} from "components/deprecated/PricingUnitSelector/queries.graphql";
import {
  ChargeTypeEnum_Enum,
  PricedProductPricingFactorInput,
} from "types/generated-graphql/__types__";

interface CopyPlanModalProps {
  planId: string;
  planName: string;
  onClose: () => void;
}

const DeprecatedCopyPlanModal: React.FC<CopyPlanModalProps> = ({
  planId,
  planName,
  onClose,
}) => {
  const client = useApolloClient();
  const auth = useAuth();
  const { prefixEnvironmentUrl } = useEnvironment();

  return (
    <DeprecatedCopyAssetToEnvironmentModal
      onClose={onClose}
      messageSuffix="This will also copy all associated products and billable metrics."
      copyAsset={async (targetEnv) => {
        /*
          First read all plan metadata, products, ppf, and metrics.

          Create non-fiat currencies

          Then read all the metrics in a separate query and create metrics (we will need to map old ID to new).

          Then create the products with the new metrics / charges and map old IDs to new.

          Then create the Plan and the pricings.
        */
        const {
          data: { Plan_by_pk },
        } = await client.query<PlanQuery, PlanQueryVariables>({
          query: PlanDocument,
          variables: {
            id: planId,
          },
        });

        if (!Plan_by_pk) {
          throw new Error("Plan not found!");
        }

        const metricIds = Plan_by_pk.PricedProducts.flatMap((pp) => pp.Product)
          .flatMap((p) => p.ProductPricingFactors)
          .flatMap((ppf) => ppf.BillableMetric?.id)
          .filter((f) => !!f) as string[];

        const billableMetricData = await client.query<
          BillableMetricDetailsQuery,
          BillableMetricDetailsQueryVariables
        >({
          query: BillableMetricDetailsDocument,
          variables: {
            billable_metric_ids: metricIds,
          },
        });

        const originalBms = billableMetricData.data.BillableMetric;

        const originalProducts =
          Plan_by_pk.PricedProducts.map((pp) => pp.Product) ?? [];
        for (const product of originalProducts) {
          const ppfNames = new Set(
            product.ProductPricingFactors.map((ppf) => ppf.name),
          );

          if (ppfNames.size !== product.ProductPricingFactors.length) {
            throw new Error(
              `Cannot copy plans with duplicate product pricing factor names within a product: ${product.name}`,
            );
          }
        }

        const targetEnvClient = createApolloClient(
          process.env.GRAPHQL_URL as string,
          auth,
          targetEnv.type,
          process.env.USE_METRONOME_SYSTEM_HEADERS === "1",
        );

        // get credit types from the target environment
        const targetCreditTypes = await targetEnvClient.query<CreditTypesQuery>(
          {
            query: CreditTypesDocument,
          },
        );

        // load this with fiat conversions first
        const creditTypeIdMap = new Map(
          targetCreditTypes.data.CreditType.filter(
            (ct) => ct.environment_type === null,
          ).map((ct) => [ct.id, ct.id]),
        );

        const targetCreditTypeNameMap = new Map(
          targetCreditTypes.data.CreditType.filter(
            (ct) => ct.environment_type !== null,
          ).map((ct) => [ct.name, ct.id]),
        );

        // create custom credit types
        const originalCustomCreditTypes = Plan_by_pk.CreditTypeConversions.map(
          (ct) => ct.CustomCreditType,
        );
        await Promise.all(
          originalCustomCreditTypes?.map(async (ct) => {
            if (targetCreditTypeNameMap.has(ct.name)) {
              creditTypeIdMap.set(
                ct.id,
                targetCreditTypeNameMap.get(ct.name) as string,
              );
            } else {
              // insert the credit type
              const clonedCreditType = await targetEnvClient.mutate<
                InsertCreditTypeMutation,
                InsertCreditTypeMutationVariables
              >({
                mutation: InsertCreditTypeDocument,
                variables: {
                  name: ct.name ?? "--",
                },
              });

              if (!clonedCreditType.data?.insert_CreditType_one?.id) {
                throw new Error(
                  `Failed to create credit type in target environment: ${ct.name}`,
                );
              }

              creditTypeIdMap.set(
                ct.id,
                clonedCreditType.data.insert_CreditType_one.id,
              );
            }
          }),
        );

        const clonedMetricsData = await targetEnvClient.mutate<
          InsertBillableMetricsMutation,
          InsertBillableMetricsMutationVariables
        >({
          mutation: InsertBillableMetricsDocument,
          variables: {
            inputs: originalBms.map((originalMetric) => ({
              name: originalMetric.name,
              aggregate_key: (
                originalMetric.aggregate_keys as string[] | null
              )?.[0],
              aggregate: originalMetric.aggregate,
              group_keys: originalMetric.group_keys as string[] | null,
              filter: originalMetric.filter,
              is_draft: false,
            })),
          },
          update(cache) {
            cache.evict({
              fieldName: "BillableMetric",
            });
            cache.evict({
              fieldName: "billable_metrics",
            });
          },
        });

        const clonedMetrics =
          clonedMetricsData.data?.create_billable_metrics ?? [];

        if (originalBms.length !== clonedMetrics.length) {
          throw new Error(
            `Original and cloned metric array lengths do not match.`,
          );
        }
        const metricIdMap = new Map<string, string>();
        for (let i = 0; i < clonedMetrics.length; i++) {
          const originalId = originalBms[i].id;
          const newId = clonedMetrics[i].id;
          metricIdMap.set(originalId, newId);
        }

        // now insert the products
        // map the product and ppf ids
        const productIdMap = new Map<string, string>();
        const ppfIdMap = new Map<string, string>();
        await Promise.all(
          originalProducts.map(async (originalProduct) => {
            const clonedProduct = await targetEnvClient.mutate<
              InsertProductMutation,
              InsertProductMutationVariables
            >({
              mutation: InsertProductDocument,
              variables: {
                description: originalProduct.description,
                name: originalProduct.name,
                group_key: originalProduct.group_key,
                pricing_factors: {
                  data: originalProduct.ProductPricingFactors.map((ppf) => ({
                    name: ppf.name,
                    charge_type_enum: ppf.charge_type_enum,
                    ordering: ppf.ordering,
                    billable_metric_id: ppf.BillableMetric
                      ? metricIdMap.get(ppf.BillableMetric.id)
                      : null,
                  })),
                },
              },
              update(cache) {
                cache.evict({ fieldName: "products" });
              },
            });

            // We validated that there are no dupe PPF names above
            const originalPPFsNamesToIds = Object.fromEntries(
              originalProduct.ProductPricingFactors.map((ppf) => [
                ppf.name,
                ppf.id,
              ]),
            );

            const newProductId = clonedProduct.data?.insert_Product_one?.id;
            const newProductPPFs =
              clonedProduct.data?.insert_Product_one?.ProductPricingFactors;
            if (!!newProductId && !!newProductPPFs) {
              productIdMap.set(originalProduct.id, newProductId);

              for (const newPPF of newProductPPFs) {
                const originalId = originalPPFsNamesToIds[newPPF.name];
                const newId = newPPF.id;
                if (!originalId) {
                  throw new Error(
                    `Cloned a product pricing factor with name ${newPPF.name} but could not find the original ID`,
                  );
                }
                ppfIdMap.set(originalId, newId);
              }
            }
          }),
        );

        // now create the pricing and plan
        const recurringGrant = Plan_by_pk.RecurringCreditGrants?.at(0);
        const clonedPlan = await targetEnvClient.mutate<
          InsertPlanMutation,
          InsertPlanMutationVariables
        >({
          mutation: InsertPlanDocument,
          variables: {
            plan: {
              billingFrequency: Plan_by_pk.billing_frequency,
              description: Plan_by_pk.description,
              minimums: Plan_by_pk.Minimums.map((min) => ({
                credit_type_id: creditTypeIdMap.get(
                  min.CreditType.id,
                ) as string,
                start_period: min.start_period,
                value: min.value,
                name: min.name,
              })),
              name: Plan_by_pk.name,
              servicePeriodStartType: Plan_by_pk.service_period_start_type,
              defaultLength: Plan_by_pk.default_length_months,
              recurringCreditGrant: recurringGrant
                ? {
                    amount_granted: recurringGrant.amount_granted,
                    amount_granted_credit_type_id: creditTypeIdMap.get(
                      recurringGrant.AmountGrantedCreditType.id,
                    ) as string,
                    amount_paid: recurringGrant.amount_paid,
                    amount_paid_credit_type_id: creditTypeIdMap.get(
                      recurringGrant.AmountPaidCreditType.id,
                    ) as string,
                    effective_duration: recurringGrant.effective_duration,
                    name: recurringGrant.name,
                    priority: recurringGrant.priority,
                    send_invoice: recurringGrant.send_invoice,
                    product_ids: recurringGrant.product_ids?.map(
                      (currentId) => productIdMap.get(currentId) as string,
                    ),
                    reason: recurringGrant.reason,
                    recurrence_duration: recurringGrant.recurrence_duration,
                    recurrence_interval: recurringGrant.recurrence_interval,
                  }
                : undefined,
              trialSpec: Plan_by_pk.TrialSpec
                ? {
                    length_in_days: new Number(
                      Plan_by_pk.TrialSpec.length_in_days,
                    ).valueOf(),
                    spending_caps:
                      Plan_by_pk.TrialSpec.TrialSpecSpendingCaps.map((sc) => ({
                        amount: sc.amount,
                        credit_type_id: creditTypeIdMap.get(
                          sc.CreditType.id,
                        ) as string,
                      })),
                  }
                : undefined,
              creditTypeConversions: Plan_by_pk.CreditTypeConversions.map(
                (ctc) => ({
                  custom_credit_type_id: creditTypeIdMap.get(
                    ctc.CustomCreditType.id,
                  ) as string,
                  fiat_currency_credit_type_id: creditTypeIdMap.get(
                    ctc.FiatCreditType.id,
                  ) as string,
                  to_fiat_conversion_factor: ctc.to_fiat_conversion_factor,
                  start_period: ctc.start_period,
                }),
              ),
              pricedProducts: Plan_by_pk.PricedProducts.map((pp) => ({
                credit_type_id: creditTypeIdMap.get(pp.CreditType.id) as string,
                product_id: productIdMap.get(pp.Product.id) as string,
                ordering: pp.ordering,
                pricing_factors: pp.PricedProductPricingFactors.map(
                  ({
                    FlatFees,
                    CompositeCharges,
                    Prices,
                    tiering_mode,
                    tier_reset_frequency,
                    start_period,
                    skip_ramp,
                    ProductPricingFactor: { id: ppfId },
                  }) =>
                    ({
                      start_period,
                      skip_ramp,
                      tiering_mode,
                      tier_reset_frequency,
                      product_pricing_factor_id: ppfIdMap.get(ppfId) as string,
                      ...(!!FlatFees?.length
                        ? {
                            charge_type_enum: ChargeTypeEnum_Enum.Flat,
                            prices: FlatFees.map(
                              ({
                                metric_minimum,
                                value,
                                quantity,
                                collection_interval,
                                collection_schedule,
                                is_prorated,
                              }) => ({
                                metric_minimum,
                                value,
                                quantity,
                                collection_interval,
                                collection_schedule,
                                is_prorated,
                              }),
                            ),
                          }
                        : !!Prices.length
                          ? {
                              charge_type_enum: ChargeTypeEnum_Enum.Usage,
                              prices: Prices.map(
                                ({
                                  block_rounding_behavior,
                                  block_size,
                                  metric_minimum,
                                  value,
                                }) => ({
                                  value,
                                  metric_minimum,
                                  ...(block_rounding_behavior !== null
                                    ? {
                                        blocks: {
                                          rounding_behavior:
                                            block_rounding_behavior,
                                          size: block_size,
                                        },
                                      }
                                    : {}),
                                }),
                              ),
                            }
                          : {
                              charge_type_enum: ChargeTypeEnum_Enum.Composite,
                              prices: CompositeCharges.map(
                                ({
                                  quantity,
                                  type,
                                  CompositeChargeTiers,
                                  CompositeChargePricingFactors,
                                }) => ({
                                  quantity,
                                  composite_charge_type: type,
                                  product_pricing_factor_ids:
                                    CompositeChargePricingFactors.map(
                                      (ccpf) =>
                                        ppfIdMap.get(
                                          ccpf.ProductPricingFactor.id,
                                        ) as string,
                                    ),
                                  composite_minimum:
                                    CompositeChargeTiers.at(0)
                                      ?.composite_minimum,
                                  value: CompositeChargeTiers.at(0)?.value,
                                }),
                              ),
                            }),
                    }) as PricedProductPricingFactorInput,
                ),
              })),
            },
          },
          update(cache) {
            /* Evict plans so plan customer counts get updated */
            cache.evict({
              fieldName: "plans",
            });
          },
        });

        return prefixEnvironmentUrl(
          targetEnv,
          `/plans/${clonedPlan.data?.create_plan.id}`,
        );
      }}
      assetName={planName}
      assetType="Plan"
    />
  );
};

export default DeprecatedCopyPlanModal;
